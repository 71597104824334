<div id="rcyc-page-header" *ngIf="!mainHeaderStatus" >

	<!-- Desktop Navigation -->
	<div class="brand-nav-header__wrapper brand-nav-header__wrapper--secondary" (mouseleave)="hideSubMenu()">
	  <header class="header brand-header brand-header--secondary" tabindex="-1">
  
		<!-- Mobile Navigation -->
		<section class="section-1">
  
		  <div class="n05_A_01  n06_A ">
			<nav class="brand-menu-wrapper" role="navigation">
			  <ul class="brand-menu">
				<li class="reservations-li">
				  <a id="hd_cruise_mobile" (click)="routeGoTo('request-call')" class="reservations-link button accent-cta">
					Request a Call
				  </a>
				</li>
				<li *ngFor="let menu of menuObj">
				  <a id="hd_mob_{{menu.id}}" class="mobMenuName" (click)="routeGoTo(menu)" [innerHtml]="menu.name"></a>
				</li>
			  </ul>
			</nav>
			<div class="shadow" (click)="hideMenu()"></div>
		  </div>
  
				  <!-- Desktop Navigation -->
				  <div class="brand-nav-header__wrapper brand-nav-header__wrapper--secondary">
					  <nav [className]="initialHeaderMenu">
  
						  <!-- Destop Ritz Text on scroll -->
						  <div class="brand-logo logo-id-1" tabindex="-1" routerLink="/home">
							  <svg id="Layer_1" x="0px" y="0px" width="1023px" height="47px" viewBox="-400.5 0.5 1023 47" enable-background="new -400.5 0.5 1023 47"
								  xml:space="preserve">
								  <g id="Group_5" transform="translate(-1000.091 54.9)">
									  <g id="Group_3" transform="translate(3.863 104.369)">
										  <path id="Path_1" fill="#1C1C1C" d="M1142.093-132.561l-9.546-12.531c-0.994-1.593-2.786-2.19-4.575-1.991v-1.388h15.31v1.388
												  c-1.792,0-2.781,0-2.781,0.796c0,0.597,0.198,0.796,1.194,1.991l6.758,8.948l6.759-9.346c0.399-0.398,0.598-0.996,0.797-1.394
												  c0-0.796-1.391-0.995-2.983-0.995v-1.388h9.344v1.388c-3.179,0-3.574,0.199-5.962,3.777l-7.356,9.949v9.146
												  c0,1.987,0.599,2.183,3.775,2.183v1.391h-14.715v-1.391c3.187,0,3.776-0.195,3.776-2.183v-8.351H1142.093z"></path>
										  <path id="Path_2" fill="#1C1C1C" d="M1169.129-141.913c0.199-0.398,0.397-0.796,0.397-1.394c0-0.592-0.397-0.99-0.796-1.195
												  c1.792-1.388,3.386-2.781,4.771-4.573c0.2,0,0.398,0.204,0.996,1.194l11.931,22.874c1.387,2.58,1.99,2.979,4.573,2.979v1.391
												  h-15.313v-1.586c2.986,0,3.774-0.203,3.774-1.19c-0.391-1.593-0.986-3.186-1.982-4.576h-12.725
												  c-0.796,1.195-1.39,2.38-1.592,3.779c0,0.988,0.999,1.784,3.774,1.784v1.392h-10.135v-1.392c2.783,0,3.775-1.193,5.567-5.164
												  L1169.129-141.913z M1170.523-141.315l-5.566,11.734h11.333L1170.523-141.315z"></path>
										  <path id="Path_3" fill="#1C1C1C" d="M1219.629-130.182v10.341h-0.996c-0.589-1.193-1.189-1.991-2.183-1.991
												  c-1.194,0-4.969,1.991-10.139,1.991c-9.144,0-15.511-6.566-15.511-14.313c0-8.753,6.162-15.316,16.304-15.316
												  c4.177,0,7.559,1.397,9.346,1.397c0.798,0,1.594-0.398,2.183-1.195h0.996v8.749h-1.187c-2.19-4.175-5.372-7.554-10.542-7.554
												  c-5.368,0-9.346,4.175-9.346,13.318c0,10.146,4.774,13.322,8.948,13.322c4.572,0,8.352-3.779,10.736-8.945L1219.629-130.182z"></path>
										  <path id="Path_4" fill="#1C1C1C" d="M1248.464-134.36h-14.316v10.149c0,1.987,0.598,2.183,3.577,2.183v1.391h-14.117v-1.391
												  c2.979,0,3.583-0.195,3.583-2.183v-20.688c0-1.985-0.604-2.184-3.583-2.184v-1.388h14.117v1.388c-2.979,0-3.577,0.199-3.577,2.184
												  v8.559h14.316v-8.559c0-1.985-0.601-2.184-3.583-2.184v-1.388h14.117v1.388c-2.979,0-3.58,0.199-3.58,2.184v20.688
												  c0,1.987,0.601,2.183,3.58,2.183v1.391h-14.117v-1.391c2.982,0,3.583-0.195,3.583-2.183V-134.36L1248.464-134.36z"></path>
										  <path id="Path_5" fill="#1C1C1C" d="M1271.924-145.889c0.199-0.796-0.398-1.393-0.994-1.393c-0.2,0-0.2,0-0.399,0
												  c-3.778,0.597-7.156,2.986-8.948,6.559l-1.194-0.393l1.593-8.152h1.195c0.397,0.194,0.796,0.398,1.39,0.592
												  c0.395,0.205,0.998,0.205,1.39,0.205h18.493c0.993,0,1.991-0.205,2.785-0.797h1.193l1.585,8.152l-0.988,0.393
												  c-1.592-3.573-4.974-5.962-8.948-6.559c-0.796-0.199-1.394,0.398-1.394,0.995c0,0.199,0,0.199,0,0.398v21.679
												  c0,1.987,0.598,2.183,3.584,2.183v1.391h-14.121v-1.391c2.983,0,3.581-0.195,3.581-2.183L1271.924-145.889L1271.924-145.889z"></path>
										  <path id="Path_6" fill="#1C1C1C" d="M1333.562-130.182v10.341h-0.998c-0.594-1.193-1.191-1.991-2.186-1.991
												  c-1.196,0-4.971,1.991-10.137,1.991c-9.147,0-15.515-6.566-15.515-14.313c0-8.753,6.161-15.316,16.304-15.316
												  c4.177,0,7.563,1.397,9.348,1.397c0.796,0,1.592-0.398,2.186-1.195h0.995v8.749h-1.191c-2.189-4.175-5.368-7.554-10.541-7.554
												  c-5.364,0-9.346,4.175-9.346,13.318c0,10.146,4.774,13.322,8.947,13.322c4.575,0,8.352-3.779,10.737-8.945L1333.562-130.182z"></path>
										  <path id="Path_7" fill="#1C1C1C" d="M1352.646-149.471c7.555,0,15.309,4.97,15.309,14.715c0,9.749-7.754,14.719-15.309,14.719
												  c-7.558,0-15.308-4.97-15.308-14.719C1337.338-144.501,1345.088-149.471,1352.646-149.471z M1352.646-121.035
												  c5.174,0,7.555-4.769,7.555-13.518c0-8.754-2.381-13.521-7.555-13.521c-5.168,0-7.558,4.767-7.558,13.521
												  C1345.088-125.803,1347.478-121.035,1352.646-121.035z"></path>
										  <path id="Path_8" fill="#1C1C1C" d="M1373.525-144.899c0-1.985-0.598-2.184-3.579-2.184v-1.388h14.113v1.388
												  c-2.979,0-3.576,0.199-3.576,2.184v20.881c0,1.396,0.597,1.99,3.774,1.99c6.763,0,9.347-0.987,13.123-6.758h1.394l-2.19,8.148
												  h-26.638v-1.391c2.981,0,3.579-0.195,3.579-2.183V-144.899L1373.525-144.899z"></path>
										  <path id="Path_9" fill="#1C1C1C" d="M1404.138-144.899c0-1.985-0.591-2.184-3.572-2.184v-1.388h14.113v1.388
												  c-2.98,0-3.576,0.199-3.576,2.184v20.881c0,1.396,0.596,1.99,3.774,1.99c6.759,0,9.347-0.987,13.125-6.758h1.392l-2.188,8.148
												  h-26.641v-1.391c2.981,0,3.572-0.195,3.572-2.183V-144.899L1404.138-144.899z"></path>
										  <path id="Path_10" fill="#1C1C1C" d="M1434.96-144.899c0-1.985-0.594-2.184-3.579-2.184v-1.388h24.854l2.786,7.748h-1.194
												  c-3.978-4.574-6.965-6.161-13.325-6.161c-1.99,0-2.58,0.199-2.58,1.792v9.548h1.784c5.173,0,7.156-1.592,8.159-5.771h1.187v12.53
												  h-1.187c-0.796-3.982-3.186-5.369-8.159-5.369h-1.784v10.741c0,1.19,0.59,1.581,4.174,1.581c5.77,0,8.943-1.39,11.932-6.758h1.392
												  l-2.189,8.156h-25.848v-1.398c2.985,0,3.579-0.195,3.579-2.186V-144.899z"></path>
										  <path id="Path_11" fill="#1C1C1C" d="M1491.028-130.182v10.341h-0.996c-0.589-1.193-1.194-1.991-2.183-1.991
												  c-1.194,0-4.974,1.991-10.143,1.991c-9.143,0-15.507-6.566-15.507-14.313c0-8.753,6.161-15.316,16.304-15.316
												  c4.174,0,7.554,1.397,9.346,1.397c0.797,0,1.594-0.398,2.183-1.195h0.996v8.749h-1.188c-2.189-4.175-5.371-7.554-10.541-7.554
												  c-5.369,0-9.347,4.175-9.347,13.318c0,10.146,4.775,13.322,8.948,13.322c4.572,0,8.352-3.779,10.732-8.945L1491.028-130.182z"></path>
										  <path id="Path_12" fill="#1C1C1C" d="M1506.144-145.889c0.191-0.796-0.398-1.393-1.003-1.393c-0.191,0-0.191,0-0.391,0
												  c-3.784,0.597-7.163,2.986-8.948,6.559l-1.195-0.393l1.594-8.152h1.193c0.398,0.194,0.797,0.398,1.387,0.592
												  c0.398,0.205,0.996,0.205,1.395,0.205h18.493c0.991,0,1.987-0.205,2.779-0.797h1.191l1.593,8.152l-1.195,0.393
												  c-1.589-3.573-4.969-5.962-8.94-6.559c-0.796-0.199-1.394,0.398-1.394,0.995c0,0.199,0,0.199,0,0.398v21.679
												  c0,1.987,0.598,2.183,3.576,2.183v1.391h-14.116v-1.391c2.985,0,3.583-0.195,3.583-2.183L1506.144-145.889L1506.144-145.889z"></path>
										  <path id="Path_13" fill="#1C1C1C" d="M1530.991-144.899c0-1.985-0.594-2.184-3.574-2.184v-1.388h14.114v1.388
												  c-2.982,0-3.58,0.199-3.58,2.184v20.688c0,1.987,0.598,2.183,3.58,2.183v1.391h-14.114v-1.391c2.98,0,3.574-0.195,3.574-2.183
												  V-144.899z"></path>
										  <path id="Path_14" fill="#1C1C1C" d="M1559.625-149.471c7.552,0,15.306,4.97,15.306,14.715c0,9.749-7.754,14.719-15.306,14.719
												  c-7.554,0-15.314-4.97-15.314-14.719C1544.311-144.501,1552.072-149.471,1559.625-149.471z M1559.625-121.035
												  c5.166,0,7.552-4.769,7.552-13.518c0-8.754-2.386-13.521-7.552-13.521c-5.171,0-7.554,4.767-7.554,13.521
												  C1552.072-125.803,1554.455-121.035,1559.625-121.035z"></path>
										  <path id="Path_15" fill="#1C1C1C" d="M1604.165-143.306c0-3.379-1.99-3.777-4.575-3.777v-1.388h10.139v1.388
												  c-1.787,0-3.775,0.398-3.775,3.777v23.465h-1.593l-21.868-24.853v18.494c0,3.377,0.989,3.978,4.175,3.978v1.391h-10.144v-1.391
												  c2.785,0,3.979-0.601,3.979-6.165v-15.714c0-2.782-0.598-3.18-3.778-3.18v-1.588h10.935l16.304,18.89L1604.165-143.306
												  L1604.165-143.306z"></path>
									  </g>
									  <g id="Group_4" transform="translate(0 82.348)">
										  <path id="Path_17" fill="#1C1C1C" d="M1103.642-107.966l-16.506-18.883h-10.934v1.389c3.38,0,3.778,0.398,3.778,3.185v15.706
												  c0,5.563-1.195,6.157-3.977,6.157v1.398h10.143v-1.398c-3.185,0-4.175-0.789-4.175-3.975v-18.486l22.069,24.846h1.588v-23.453
												  c0-3.583,1.792-3.981,3.779-3.981v-1.389h-10.143v1.389c2.585,0,4.573,0.602,4.573,3.777L1103.642-107.966L1103.642-107.966z"></path>
										  <path id="Path_18" fill="#1C1C1C" d="M1057.907-127.645c-7.553,0-15.306,4.972-15.306,14.911c0,9.941,7.753,14.914,15.306,14.914
												  c7.555,0,15.313-4.973,15.313-14.914C1073.221-122.673,1065.667-127.645,1057.907-127.645z M1057.907-99.217
												  c-5.368,0-7.751-4.771-7.751-13.518c0-8.745,2.384-13.522,7.751-13.522c5.371,0,7.759,4.777,7.759,13.522
												  C1065.667-103.988,1063.278-99.217,1057.907-99.217z"></path>
										  <path id="Path_19" fill="#1C1C1C" d="M1021.525-102.395c0,1.983-0.598,2.186-3.582,2.186v1.387h14.117v-1.387
												  c-2.981,0-3.578-0.202-3.578-2.186v-21.672c-0.199-0.791,0.398-1.393,0.996-1.393c0.199,0,0.199,0,0.398,0
												  c3.777,0.602,7.156,2.986,8.943,6.564l1.194-0.398l-1.786-8.351h-1.195c-0.796,0.398-1.786,0.796-2.782,0.796h-18.692
												  c-0.595,0-0.994,0-1.392-0.199c-0.398-0.199-0.992-0.398-1.39-0.597h-1.193l-1.788,8.351l1.194,0.398
												  c1.787-3.578,4.967-5.962,8.943-6.564c0.796-0.194,1.394,0.398,1.394,1c0,0.194,0,0.194,0,0.393L1021.525-102.395
												  L1021.525-102.395z"></path>
										  <path id="Path_20" fill="#1C1C1C" d="M987.527-102.395c0,1.983-0.598,2.186-3.579,2.186v1.387h26.644l2.185-8.145h-1.389
												  c-3.979,5.767-6.365,6.758-13.127,6.758c-3.18,0-3.777-0.601-3.777-1.989v-21.071c0-1.986,0.597-2.19,3.583-2.19v-1.389h-14.12
												  v1.389c2.981,0,3.579,0.204,3.579,2.19V-102.395L987.527-102.395z"></path>
										  <path id="Path_21" fill="#1C1C1C" d="M976.792-102.996l-4.574-7.157c-0.601-1.39-1.991-2.388-3.582-2.581v-0.205
												  c4.571-0.194,7.359-2.583,7.359-6.156c0-3.578-3.186-7.754-12.332-7.754H949.55v1.389c2.981,0,3.578,0.204,3.578,2.19v20.673
												  c0,1.99-0.597,2.186-3.578,2.186v1.398h14.114v-1.398c-2.981,0-3.574-0.195-3.574-2.186v-9.943c2.981,0,3.972,0.397,4.769,1.794
												  l6.563,11.733h9.739v-1.398C979.375-100.21,978.379-100.412,976.792-102.996L976.792-102.996z M962.276-113.531h-2.389v-11.725
												  h1.593c5.766,0,7.156,3.379,7.156,5.962C968.636-115.124,965.654-113.531,962.276-113.531L962.276-113.531z"></path>
										  <path id="Path_22" fill="#1C1C1C" d="M941.399-103.191l-11.932-22.861c-0.594-0.797-0.792-1.195-0.992-1.195
												  c-1.393,1.787-2.98,3.18-4.772,4.574c0.597,0.199,0.796,0.796,0.796,1.194c0,0.399-0.199,0.99-0.398,1.389l-7.161,14.908
												  c-1.786,3.779-2.981,5.169-5.563,5.169v1.397h10.137v-1.397c-2.782,0-3.777-0.797-3.777-1.788c0.203-1.39,0.796-2.787,1.593-3.97
												  h12.724c0.995,1.385,1.792,2.775,1.985,4.57c0,0.991-0.796,1.188-3.777,1.188v1.397h15.313v-1.397
												  C943.584-100.21,942.787-100.607,941.399-103.191L941.399-103.191z M920.323-107.568l5.569-11.726l5.763,11.726H920.323z"></path>
										  <path id="Path_23" fill="#1C1C1C" d="M906.405-108.364c-2.389,5.173-6.167,8.952-10.934,8.952c-4.176,0-9.147-3.186-9.147-13.322
												  c0-9.347,3.778-13.318,9.342-13.318c5.176,0,8.351,3.379,10.541,7.753h1.194v-8.749h-0.995c-0.398,0.792-1.195,1.189-2.185,1.189
												  c-1.593,0-5.171-1.388-9.346-1.388c-9.944,0-16.308,6.564-16.308,15.307c0,7.749,6.364,14.313,15.511,14.313
												  c5.171,0,8.75-1.987,10.143-1.987c0.99,0,1.593,0.601,2.185,1.987h0.995v-10.534L906.405-108.364z"></path>
										  <path id="Path_24" fill="#1C1C1C" d="M819.517-98.616h24.457l2.981-8.554h-1.19c-4.176,5.368-6.563,7.156-10.739,7.156h-6.959
												  l17.896-26.039v-0.797h-24.258l-2.586,7.953h0.995c3.18-4.175,6.563-6.36,10.34-6.36h6.56l-17.696,25.642L819.517-98.616
												  L819.517-98.616z"></path>
										  <path id="Path_25" fill="#1C1C1C" d="M797.845-102.395c0,1.983-0.595,2.186-3.578,2.186v1.387h14.116v-1.387
												  c-2.981,0-3.579-0.202-3.579-2.186v-21.672c-0.199-0.791,0.399-1.393,0.996-1.393c0.197,0,0.197,0,0.397,0
												  c3.778,0.602,7.157,2.986,8.946,6.564l0.996-0.398l-1.788-8.351h-1.195c-0.796,0.398-1.79,0.796-2.784,0.796H791.68
												  c-0.595,0-0.992,0-1.39-0.199c-0.398-0.199-0.996-0.398-1.392-0.597h-1.193l-1.79,8.351l0.994,0.398
												  c1.791-3.578,4.971-5.962,8.947-6.564c0.796-0.194,1.394,0.398,1.394,1c0,0.194,0,0.194,0,0.393L797.845-102.395L797.845-102.395z
												  "></path>
										  <path id="Path_26" fill="#1C1C1C" d="M772.199-102.395c0,1.983-0.597,2.186-3.582,2.186v1.387h14.117v-1.387
												  c-2.981,0-3.579-0.202-3.579-2.186v-20.676c0-1.991,0.598-2.185,3.579-2.185v-1.394h-14.117v1.394
												  c2.984,0,3.582,0.194,3.582,2.185V-102.395L772.199-102.395z"></path>
										  <path id="Path_27" fill="#1C1C1C" d="M760.865-102.996l-4.573-7.157c-0.598-1.39-1.989-2.388-3.579-2.581v-0.205
												  c4.574-0.194,7.356-2.583,7.356-6.156c0-3.578-3.18-7.754-12.327-7.754h-14.116v1.389c2.981,0,3.578,0.204,3.578,2.19v20.673
												  c0,1.99-0.597,2.186-3.578,2.186v1.398h14.116v-1.398c-2.983,0-3.579-0.195-3.579-2.186v-9.943c2.981,0,3.977,0.397,4.769,1.794
												  l6.563,11.733h9.744v-1.398C763.251-100.21,762.454-100.412,760.865-102.996L760.865-102.996z M746.349-113.531h-2.385v-11.725
												  h1.589c5.768,0,7.16,3.379,7.16,5.962C752.713-115.124,749.729-113.531,746.349-113.531L746.349-113.531z"></path>
										  <path id="Path_28" fill="#1C1C1C" d="M688.491-102.395c0,1.983-0.597,2.186-3.578,2.186v1.387h26.045l2.188-8.145h-1.392
												  c-3.18,5.369-6.362,6.758-12.128,6.758c-3.778,0-4.173-0.601-4.173-1.787v-10.737h1.787c5.172,0,7.358,1.387,8.152,5.369h1.195
												  v-12.327h-1.195c-1.192,4.17-2.98,5.763-8.152,5.763h-1.787v-9.739c0-1.587,0.595-1.792,2.584-1.792
												  c6.361,0,9.342,1.792,13.319,6.166h1.194l-2.783-7.754h-24.854v1.394c2.981,0,3.578,0.194,3.578,2.184V-102.395z"></path>
										  <path id="Path_29" fill="#1C1C1C" d="M670.001-102.395c0,1.983-0.597,2.186-3.579,2.186v1.387h14.116v-1.387
												  c-2.982,0-3.579-0.202-3.579-2.186v-20.676c0-1.991,0.597-2.185,3.579-2.185v-1.394h-14.116v1.394
												  c2.982,0,3.579,0.194,3.579,2.185v8.55h-14.314v-8.55c0-1.991,0.596-2.185,3.578-2.185v-1.394H645.15v1.394
												  c2.981,0,3.578,0.194,3.578,2.185v20.676c0,1.983-0.597,2.186-3.578,2.186v1.387h14.115v-1.387c-2.982,0-3.578-0.202-3.578-2.186
												  v-10.146h14.314V-102.395z"></path>
										  <path id="Path_30" fill="#1C1C1C" d="M623.874-102.395c0,1.983-0.597,2.186-3.58,2.186v1.387h14.117v-1.387
												  c-2.982,0-3.58-0.202-3.58-2.186v-21.672c-0.199-0.791,0.398-1.393,0.996-1.393c0.199,0,0.199,0,0.398,0
												  c3.777,0.602,7.156,2.986,8.948,6.564l0.993-0.398l-1.789-8.351h-1.195c-0.794,0.398-1.79,0.796-2.782,0.796h-18.689
												  c-0.597,0-0.995,0-1.393-0.199c-0.399-0.199-0.994-0.398-1.393-0.597h-1.193l-1.79,8.351l0.995,0.398
												  c1.589-3.578,4.968-5.962,8.949-6.564c0.792-0.194,1.39,0.398,1.39,1c0,0.194,0,0.194,0,0.393v21.672H623.874z"></path>
										  <path id="Path_31" fill="#1C1C1C" d="M856.102-110.747h9.346l3.977-5.765h-9.347L856.102-110.747z"></path>
									  </g>
								  </g>
							  </svg>
						  </div>
  
						  <!-- Desktop - MENUS -->
						  <ul class="menu l-container brand-nav">
							  <li class="brand-nav__item parentMenuLi" *ngFor="let menu of menuObj;let i=index">
								  <a id="hd_{{menu.id}}" [routerLink]="menu.url" class="brand-nav__link" tabindex="{{i+2}}" title="{{menu.name}}" (focus)="hideSubMenu()" (click)="clearVoyageDetail(menu.url)" (keyup.enter)="clearVoyageDetail(menu.url)">
									  <span class="link-wrapper" [innerHtml]="menu.name"></span>
								  </a>
							  </li>
						  </ul>
  
						  <!-- Desktop Left button -->
						  <p class="brand-nav__item brand-nav__item-reservations">
							  <a id="hd_cruise" title="Request a call" href="javascript:void(0)"
							  (mouseenter)="requestCall('enter')" (mouseleave)="requestCall('leave')" (click)="requestCall('click')"
							  class="brand-nav__link brand-nav__link--reservations"
								  data-takeover="true" data-takeover-text="Close" tabindex="1">
								  Request  Call
							  </a>
						  </p>
						  <div (click)="skipNavigationClick()">
							  <a tabindex="1" [href]="skipNavPath" id="skipNavigation" class="brand-nav__item brand-nav__item-reservations">SKIP NAVIGATION</a>
						  </div>
  
						  <div class="brand-nav__item brand-nav__item-rewards rightMostSection">
							  <!-- Desktop Right signIn signOut -->
							  <div class="signinJoin">
								  <div>
									  <!-- <a class="userImageOuter" href="javascript:void(0)" tabindex="-1">
											  <i class="fa fa-user-circle-o"></i>
										  </a> -->
									  <div *ngIf="currentUserName == 'currentUserName' " class="beforeOuter">
										  <a id="desk_signinJoinId" href="javascript:void(0)" (click)="loginOpenClick()" class="brand-nav__link reservation signinJoinLink">
											  <span class="userIcon">
												  <i class="fa fa-user-circle-o"></i>
											  </span>
											  <span class="signInJoinText">
												  Sign in or join
											  </span>
										  </a>
									  </div>
									  <div *ngIf="currentUserName != 'currentUserName'" class="afterOuter">
										  <a id="currentUserNameId" href="javascript:void(0)" class="brand-nav__link reservation signinJoinLink dr_list" (mouseenter)="showSubmenu()"
											  (focus)="showSubmenu()" (click)="userIconClick()">
											  <span class="userIcon">
												  <i class="fa fa-user-circle-o"></i>
											  </span>
											  <span class="userName">
												  {{currentUserName | limitTo: 12}}
											  </span>
										  </a>
										  <div class="outer_dropdown" *ngIf="isOpen" (mouseleave)="hideSubMenu()">
											  <ul>
												  <li *ngFor="let item of userImgSubMenus;let i=index">
													  <a id="desk_{{item.id}}" tabindex="0" [routerLink]="item.url" (focus)="showSubmenu()">{{item.name}}</a>
													  <!-- <a *ngIf="item.name != 'Logout'" id="desk_{{item.id}}" href="javascript:void(0)" tabindex="0" [routerLink]="item.url" (focus)="showSubmenu()">{{item.name}}</a>
													  <a *ngIf="item.name == 'Logout'" id="desk_{{item.id}}" href="javascript:void(0)" tabindex="0" (click)="logoutClick();" (focusout)="hideSubMenu()">{{item.name}}</a> -->
												  </li>
											  </ul>
										  </div>
  
									  </div>
								  </div>
  
							  </div>
  
							  <!-- Desktop Right phone -->
							  <p [ngStyle]="{'visibility':countryNo ? 'visible':'hidden'}" style="min-width: 120px;">
								  <a id="hd_reservations" [routerLink]="['/request-call']" class="brand-nav__link reservation reservationNumber" (focus)="hideSubMenu()">
									  <span class="icon-phone">
										  {{countryNo}}
									  </span>
								  </a>
							  </p>
  
						  </div>
  
					  </nav>
				  </div>
		</section>
  
		<section class="section-2">
		  <div class="l-container {{initalmobilebanner}}">
  
			<a (click)="toggleMobileMenu()" class="brand-header__menu-toggle h-ML menuIconOuter">
			  <span class="icon-menu"></span>
			</a>
			<a *ngIf="countryNo" href="tel:{{countryNo}}" class="brand-header__rewards-link h-ML phoneIconOuter">
			  <span class="icon-phone"></span>
			</a>
			<a [routerLink]="['/find-a-cruise']" class="brand-header__rewards-link h-ML search_xs searchIconOuter">
			  <span class="icon-search"></span>
					  </a>
  
			<div class="signinJoin">
						  <div *ngIf="currentUserName == 'currentUserName'" class="beforeOuter">
							  <a class="brand-header__rewards-link h-ML userImageOuter" (click)="loginOpenClick()" href="javascript:void(0)">
								  <!-- <img class="userImage" src="assets/components/images/user.png" alt="" title=""> -->
								  <span class="userIcon">
									  <i class="fa fa-user-circle-o"></i>
								</span>
								  <!-- <span class="userName">
									  {{currentUserName | limitTo: 12}}
								  </span> -->
							  </a>
						  </div>
						  <div *ngIf="currentUserName != 'currentUserName'" class="afterOuter">
							  <a class="brand-header__rewards-link h-ML userImageOuter" href="javascript:void(0)">
								  <!-- <img class="userImage" src="assets/components/images/user.png" alt="" title=""> -->
								  <span class="userIcon" (click)="mobSubmenuShow()">
									  <i class="fa fa-user-circle-o"></i>
								  </span>
								  <!-- <span class="userName">
									  {{currentUserName | limitTo: 12}}
								  </span> -->
							  </a>
							  <div class="outer_dropdown" (click)="mobileUserSubMenuOuterClick()">
							  <!-- <div class="outer_dropdown"> -->
								  <ul *ngIf="isMobOpen">
									  <li *ngFor="let item of userImgSubMenus;let i=index">
										  <a id="desk_{{item.id}}" tabindex="0" [routerLink]="item.url" (focus)="showSubmenu()">{{item.name}}</a>
										  <!-- <a *ngIf="item.name != 'Logout'" id="desk_{{item.id}}" href="javascript:void(0)" tabindex="0" [routerLink]="item.url" (focus)="showSubmenu()">{{item.name}}</a>
										  <a *ngIf="item.name == 'Logout'" id="desk_{{item.id}}" href="javascript:void(0)" tabindex="0" (click)="logoutClick();" (focusout)="hideSubMenu()">{{item.name}}</a> -->
									  </li>
								  </ul>
							  </div>
						  </div>
			</div>
  
			<h1 class="brand-lockup">
			  <a id="hd_brandIcon" routerLink="/home" style="" class="brand-lockup__link" title="Home Page" alt="The Ritz-Carlton Yacht Collection" id="forSkipNav">
  
				<span class="visuallyhidden">
				  The Ritz-Carlton Yacht Collection
				</span>
				<svg class="brand-lockup__logo brand-lockup__logo--fixed-only h-ML logo-id-2 mobile_logo" id="Layer_1" x="0px" y="0px" viewBox="0 0 250 119.2"
				  style="enable-background:new 0 0 250 119.2;" xml:space="preserve">
				  <style type="text/css">
					.st0 {
					  fill: #1C1C1C;
					}
				  </style>
				  <g id="Group_5" transform="translate(-1000.091 54.9)">
					<g id="Group_3" transform="translate(3.863 104.369)">
					  <path id="Path_1" class="st0" d="M1007.1-46.5l-4.8-6.3c-0.5-0.8-1.4-1.1-2.3-1v-0.7h7.7v0.7c-0.9,0-1.4,0-1.4,0.4
										  c0,0.3,0.1,0.4,0.6,1l3.4,4.5l3.4-4.7c0.2-0.2,0.3-0.5,0.4-0.7c0-0.4-0.7-0.5-1.5-0.5v-0.7h4.7v0.7c-1.6,0-1.8,0.1-3,1.9l-3.7,5
										  v4.6c0,1,0.3,1.1,1.9,1.1v0.7h-7.4v-0.7c1.6,0,1.9-0.1,1.9-1.1V-46.5z"></path>
					  <path id="Path_2" class="st0" d="M1020.7-51.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.2-0.5-0.4-0.6c0.9-0.7,1.7-1.4,2.4-2.3
										  c0.1,0,0.2,0.1,0.5,0.6l6,11.5c0.7,1.3,1,1.5,2.3,1.5v0.7h-7.7v-0.8c1.5,0,1.9-0.1,1.9-0.6c-0.2-0.8-0.5-1.6-1-2.3h-6.4
										  c-0.4,0.6-0.7,1.2-0.8,1.9c0,0.5,0.5,0.9,1.9,0.9v0.7h-5.1v-0.7c1.4,0,1.9-0.6,2.8-2.6L1020.7-51.2z M1021.4-50.9l-2.8,5.9h5.7
										  L1021.4-50.9z"></path>
					  <path id="Path_3" class="st0" d="M1046.1-45.3v5.2h-0.5c-0.3-0.6-0.6-1-1.1-1c-0.6,0-2.5,1-5.1,1c-4.6,0-7.8-3.3-7.8-7.2
										  c0-4.4,3.1-7.7,8.2-7.7c2.1,0,3.8,0.7,4.7,0.7c0.4,0,0.8-0.2,1.1-0.6h0.5v4.4h-0.6c-1.1-2.1-2.7-3.8-5.3-3.8
										  c-2.7,0-4.7,2.1-4.7,6.7c0,5.1,2.4,6.7,4.5,6.7c2.3,0,4.2-1.9,5.4-4.5L1046.1-45.3z"></path>
					  <path id="Path_4" class="st0" d="M1060.6-47.4h-7.2v5.1c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7c1.5,0,1.8-0.1,1.8-1.1v-10.4
										  c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1v4.3h7.2v-4.3c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1
										  v10.4c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7c1.5,0,1.8-0.1,1.8-1.1L1060.6-47.4L1060.6-47.4z"></path>
					  <path id="Path_5" class="st0" d="M1072.4-53.2c0.1-0.4-0.2-0.7-0.5-0.7c-0.1,0-0.1,0-0.2,0c-1.9,0.3-3.6,1.5-4.5,3.3l-0.6-0.2
										  l0.8-4.1h0.6c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.5,0.1,0.7,0.1h9.3c0.5,0,1-0.1,1.4-0.4h0.6l0.8,4.1l-0.5,0.2
										  c-0.8-1.8-2.5-3-4.5-3.3c-0.4-0.1-0.7,0.2-0.7,0.5c0,0.1,0,0.1,0,0.2v10.9c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7
										  c1.5,0,1.8-0.1,1.8-1.1L1072.4-53.2L1072.4-53.2z"></path>
					  <path id="Path_6" class="st0" d="M1103.4-45.3v5.2h-0.5c-0.3-0.6-0.6-1-1.1-1c-0.6,0-2.5,1-5.1,1c-4.6,0-7.8-3.3-7.8-7.2
										  c0-4.4,3.1-7.7,8.2-7.7c2.1,0,3.8,0.7,4.7,0.7c0.4,0,0.8-0.2,1.1-0.6h0.5v4.4h-0.6c-1.1-2.1-2.7-3.8-5.3-3.8
										  c-2.7,0-4.7,2.1-4.7,6.7c0,5.1,2.4,6.7,4.5,6.7c2.3,0,4.2-1.9,5.4-4.5L1103.4-45.3z"></path>
					  <path id="Path_7" class="st0" d="M1113-55c3.8,0,7.7,2.5,7.7,7.4s-3.9,7.4-7.7,7.4c-3.8,0-7.7-2.5-7.7-7.4S1109.2-55,1113-55z
											  M1113-40.7c2.6,0,3.8-2.4,3.8-6.8s-1.2-6.8-3.8-6.8c-2.6,0-3.8,2.4-3.8,6.8S1110.4-40.7,1113-40.7z"></path>
					  <path id="Path_8" class="st0" d="M1123.5-52.7c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1v10.5c0,0.7,0.3,1,1.9,1
										  c3.4,0,4.7-0.5,6.6-3.4h0.7l-1.1,4.1h-13.4v-0.7c1.5,0,1.8-0.1,1.8-1.1V-52.7z"></path>
					  <path id="Path_9" class="st0" d="M1138.9-52.7c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1v10.5c0,0.7,0.3,1,1.9,1
										  c3.4,0,4.7-0.5,6.6-3.4h0.7l-1.1,4.1h-13.4v-0.7c1.5,0,1.8-0.1,1.8-1.1V-52.7z"></path>
  
  
  
					  <path id="Path_10" class="st0" d="M1154.4-52.7c0-1-0.3-1.1-1.8-1.1v-0.7h12.5l1.4,3.9h-0.6c-2-2.3-3.5-3.1-6.7-3.1
										  c-1,0-1.3,0.1-1.3,0.9v4.8h0.9c2.6,0,3.6-0.8,4.1-2.9h0.6v6.3h-0.6c-0.4-2-1.6-2.7-4.1-2.7h-0.9v5.4c0,0.6,0.3,0.8,2.1,0.8
										  c2.9,0,4.5-0.7,6-3.4h0.7l-1.1,4.1h-13v-0.7c1.5,0,1.8-0.1,1.8-1.1V-52.7z"></path>
  
  
					  <path id="Path_11" class="st0" d="M1182.6-45.3v5.2h-0.5c-0.3-0.6-0.6-1-1.1-1c-0.6,0-2.5,1-5.1,1c-4.6,0-7.8-3.3-7.8-7.2
										  c0-4.4,3.1-7.7,8.2-7.7c2.1,0,3.8,0.7,4.7,0.7c0.4,0,0.8-0.2,1.1-0.6h0.5v4.4h-0.6c-1.1-2.1-2.7-3.8-5.3-3.8
										  c-2.7,0-4.7,2.1-4.7,6.7c0,5.1,2.4,6.7,4.5,6.7c2.3,0,4.2-1.9,5.4-4.5L1182.6-45.3z"></path>
					  <path id="Path_12" class="st0" d="M1190.2-53.2c0.1-0.4-0.2-0.7-0.5-0.7c-0.1,0-0.1,0-0.2,0c-1.9,0.3-3.6,1.5-4.5,3.3l-0.6-0.2
										  l0.8-4.1h0.6c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.5,0.1,0.7,0.1h9.3c0.5,0,1-0.1,1.4-0.4h0.6l0.8,4.1l-0.6,0.2
										  c-0.8-1.8-2.5-3-4.5-3.3c-0.4-0.1-0.7,0.2-0.7,0.5c0,0.1,0,0.1,0,0.2v10.9c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7
										  c1.5,0,1.8-0.1,1.8-1.1L1190.2-53.2L1190.2-53.2z"></path>
					  <path id="Path_13" class="st0" d="M1202.7-52.7c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1v10.4
										  c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7c1.5,0,1.8-0.1,1.8-1.1L1202.7-52.7z"></path>
					  <path id="Path_14" class="st0" d="M1217.1-55c3.8,0,7.7,2.5,7.7,7.4s-3.9,7.4-7.7,7.4s-7.7-2.5-7.7-7.4S1213.3-55,1217.1-55z
											  M1217.1-40.7c2.6,0,3.8-2.4,3.8-6.8s-1.2-6.8-3.8-6.8s-3.8,2.4-3.8,6.8S1214.5-40.7,1217.1-40.7z"></path>
					  <path id="Path_15" class="st0" d="M1239.5-51.9c0-1.7-1-1.9-2.3-1.9v-0.7h5.1v0.7c-0.9,0-1.9,0.2-1.9,1.9v11.8h-0.8l-11-12.5v9.3
										  c0,1.7,0.5,2,2.1,2v0.7h-5.1v-0.7c1.4,0,2-0.3,2-3.1v-7.9c0-1.4-0.3-1.6-1.9-1.6v-0.8h5.5l8.2,9.5L1239.5-51.9L1239.5-51.9z"></path>
					</g>
					<path id="Path_16" class="st0" d="M1116.7,1.2c0-0.3,0.8-1.1,1-1.1c0.1,0,0.9,0.8,0.9,1.1c-0.2,0.4-0.5,0.7-0.9,1
									  C1117.5,2.2,1116.7,1.3,1116.7,1.2z M1132,1.2c0-0.3,0.8-1.1,1-1.1c0.1,0,0.9,0.8,0.9,1.1c-0.2,0.4-0.5,0.7-0.9,1
									  C1132.8,2.2,1132,1.3,1132,1.2z M1124.3,1.2c0-0.3,0.8-1.1,1-1.1c0.1,0,0.9,0.8,0.9,1.1c-0.2,0.4-0.5,0.7-0.9,1
									  C1125.1,2.2,1124.3,1.3,1124.3,1.2z M1115.7,2.7h3.8c0.3,0,0.5-0.2,0.5-0.4c0,0,0,0,0-0.1V0.1c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0-0.1,0
									  c-0.8,0-2.7,0.1-3.8,0.2c-0.2,0-0.4,0.1-0.4,0.3c0,0,0,0,0,0.1v2.2C1115.3,2.6,1115.4,2.7,1115.7,2.7
									  C1115.6,2.8,1115.7,2.8,1115.7,2.7L1115.7,2.7z M1113.1,1.4c0-0.2,0.6-0.8,0.7-0.8s0.7,0.6,0.7,0.8c0.1,0.4-0.2,0.7-0.6,0.8
									  c-0.4,0.1-0.7-0.2-0.8-0.6C1113.1,1.5,1113.1,1.4,1113.1,1.4L1113.1,1.4z M1120.7,1.2c0-0.2,0.6-0.8,0.7-0.8s0.7,0.6,0.7,0.8
									  c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6C1120.7,1.3,1120.7,1.2,1120.7,1.2L1120.7,1.2z M1127.1,2.8
									  c0.3,0,0.5-0.2,0.5-0.4c0,0,0,0,0-0.1V0c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0-0.1,0h-3.8c-0.3,0-0.5,0.2-0.5,0.4c0,0,0,0,0,0.1v2.4
									  c0,0.3,0.2,0.5,0.4,0.5c0,0,0,0,0.1,0L1127.1,2.8z M1134.9,2.7c0.2,0,0.4-0.1,0.4-0.3c0,0,0,0,0-0.1V0.2c0-0.2-0.1-0.4-0.3-0.4
									  c0,0,0,0-0.1,0c-1.2-0.1-3.1-0.2-3.8-0.2c-0.3,0-0.5,0.2-0.5,0.4c0,0,0,0,0,0.1v2.2c0,0.3,0.2,0.5,0.4,0.5c0,0,0,0,0.1,0H1134.9
									  L1134.9,2.7z M1137.4,1.4c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6c0-0.1,0-0.1,0-0.2c0-0.2,0.6-0.8,0.7-0.8
									  S1137.4,1.2,1137.4,1.4L1137.4,1.4z M1129.8,1.2c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6c0-0.1,0-0.1,0-0.2
									  c0-0.2,0.6-0.8,0.7-0.8S1129.8,1,1129.8,1.2L1129.8,1.2z M1119.7-44.7L1119.7-44.7c0.6,1.9,2.8,2.1,3.3,4.2l-0.1,0.1
									  c-1.1-1.6-2.6-1.8-3.2-2.6C1119.4-43.3,1119.4-43.8,1119.7-44.7L1119.7-44.7z M1123.4-19.4c0,3.1-2.6,4.4-5.2,4.4
									  c3.5-3.8,0.8-9.2,4.1-12.7C1122-24.5,1123.4-21.6,1123.4-19.4L1123.4-19.4z M1139.8-11c3.7,0,5.5-1.9,5.5-4.7
									  c0-0.6-0.1-1.1-0.3-1.7c-0.8,0.6-1.8,1-2.9,1c-1.8,0.1-3.3-1.2-3.4-3c0-0.1,0-0.2,0-0.2c0-3.1,3.3-4.6,3.3-8.1c0-0.5-0.1-1-0.4-1.4
									  c1.2-1.5,1.9-3.4,2-5.3c0-1.6-0.5-3.2-1.5-4.5l1.6,0.8c-0.4-1.6-1.2-3.1-2.2-4.4c0,0,0.8,0.4,1.2,0.2c-0.8-0.4-2-1.9-2-4.5
									  s-0.6-4.1-5.7-5c-0.7-1.1-1.6-2.1-2.7-2.9c-1.2,0.5-2.4,1.1-3.6,1.8c0,0-1.5,0.1-2.1,0.1c-0.7-0.1-1.3-0.2-2-0.3
									  c-0.8,0.1-1.5,0.5-2,1.2c-1.5,0.1-3,0.2-4.5,0.5c-1,0.2-1.6,1-2.3,2.7l-4.8,2.7c-0.9,0.5-1.5,0.8-1.5,1.6c0.1,0.7,0.4,1.4,0.9,2
									  c0.5,3,1,3.5,1.7,3.5c0.2,0,0.4,0,0.6-0.1c0.3,0.4,0.6,0.8,0.8,1.3c-0.8,0.4-1.8-0.5-3-0.5c-0.9-0.1-1.7,0.6-1.8,1.5c0,0,0,0,0,0.1
									  c0,0.4,0.2,0.5,0.4,0.5c0.3,0,0.6-0.1,1.3-0.1c0.9,0,1.6,0.8,3,0.8c0.5,0,0.8-0.1,1,0c-0.7,0.5-1.1,0.8-0.9,1.5
									  c0.3,1.3,1.5,2.8,2.1,2.8c0.5,0,0.9-0.1,1.4-0.3l-0.6,0.5c-3.9,3.4-3.1,7.1-3.1,10.9c0,2.9-0.8,4.6-3.1,4.6c-2-0.2-3.9-1.2-5.1-2.9
									  c-0.2,0.7-0.3,1.5-0.3,2.3c0,3.5,3.2,5.7,6.3,5.7c2.5,0.1,4.8-0.8,6.7-2.4c5.6-0.1,7.4-3.8,7.4-3.8c2.7,3.8,7.6,3.6,8.4,3.6
									  C1135.3-11.9,1137.5-11,1139.8-11L1139.8-11z M1140.2-31.9c0,4.1-5.3,4.6-5.3,8.7c0,1.6,0.5,2.7,0.2,3.7c0.6-0.6,1-1.3,1.2-2.1
									  c0,3.1,1.6,6.6,5.6,6.6c0.8,0,1.6-0.2,2.3-0.6c-0.2,1.7-1.7,3-3.5,2.9c-4.1,0-7.9-3.5-7.9-9.9c-0.1-1.2,0.3-2.5,0.9-3.6
									  c0,0-2,1.1-2.2,4.5c-0.5-0.8-0.8-1.7-0.8-2.7C1130.7-29.2,1136.7-27.5,1140.2-31.9L1140.2-31.9z M1135.5-50.6
									  c3.7,0.8,3.2,3.2,3.8,4.8c-1-1.3-2.3-2.4-3.7-3.3C1135.9-49.5,1135.8-50.1,1135.5-50.6L1135.5-50.6z M1113.4-40.7
									  c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0.5,0.2,1.1,0.1,1.7c-0.6,0-1.1-0.5-1.1-1.1C1113.4-40.5,1113.4-40.6,1113.4-40.7L1113.4-40.7z
										  M1115.5-37.7c-0.4,0.6-0.7,1.4-1.9,1.4c-1.2,0-2.2-0.9-3-0.9c-0.4,0-0.6,0.1-0.8,0.1s-0.3-0.1-0.3-0.2c0-0.3,0.4-0.6,1-0.6
									  c0.7,0.1,1.3,0.3,2,0.5c1.3,0.4,2.1-0.7,2.6-1.1c0.4-0.4,1-0.6,1.6-0.6c0.6,0,1.2,0.2,1.7,0.5c-0.8,0.2-1.7,0.5-2.4,1
									  C1115.7-37.7,1115.6-37.8,1115.5-37.7L1115.5-37.7z M1116.6-36.4c-0.3,0.3-0.6,0.4-1,0.4c-0.1-0.4-0.1-0.8,0-1.2
									  C1116-37.2,1116.6-36.9,1116.6-36.4z M1110.3-14.7c4.6,0,4.5-5.3,4.5-7.1c0-8,2.4-10,7.9-12.5c4.1-2,7.9-2.8,7.9-6.7
									  c0-1.4-0.4-2.9-2-2.9c0.4,0.4,0.6,1,0.6,1.6c0,0.4-0.1,0.9-0.3,1.3c0-0.5-0.1-0.9-0.4-1.3c-0.5,0.8-0.4,2-0.7,2.5
									  c0,0-0.2,0.5-1.6,1.2c0,0-0.2,0.2,0.5,0.2c0,0,1.7,0,2.3-0.7c-0.5,1.4-1.1,1.9-2,1.9c-0.5,0-1.3-0.1-2-0.1c-1.2,0-3.9,0.6-5,2.5
									  c-0.9,1.3-2.3,2.1-3.8,2.5c-0.7,0-1.7-1.6-1.7-2.3c0-0.8,1.4-0.3,2.5-1.4c1.8-1.8,2.5-2.2,4.3-2.2c0,0,1.1,0,0.1-1l-3.1-2.9
									  c-0.3-0.3-0.7-0.6-1.1-0.6c-0.2,0-0.6,0.1-1.5,0.9c-1,0-2,0.3-3,0.8c-0.3,0.2-0.5,0.4-0.7,0.4c-0.3,0-0.7-1.6-0.7-1.9
									  c0-0.8,0.9-1.3,0.9-1.8c0-0.2-0.1-0.3-0.3-0.4l-1.3,0.6c0,0-0.7-0.8,0.1-1.2c0,0,2-1.1,5.7-2.9c0.2,1.1,0.5,1.3,1.4,1.2l-0.4,0.1
									  l-0.2,0.3c0.5,0.1,1,0,1.5-0.2c0.5-0.2,1.1-0.2,1.2-0.3c0.1-0.1,0.4-0.4,0.4-0.5c-0.4,0-0.8,0.1-1.2,0.2c0.6-0.4,0.9-0.7,0.9-0.9
									  c0-0.1,0-0.1-0.1-0.2c-0.3,0-0.5,0.2-0.8,0.1c-0.2-0.1-0.4-0.3-0.4-0.6c0-0.2,0.2-0.4,0.9-0.4h0.5c0-0.4-1-0.6-1.4-0.6
									  c-0.6,0-1.2,0.2-1.8,0.5c0.1-0.5,0.5-1,1-1.2c1.8-0.4,3.7-0.5,5.5-0.5c3.6,0,6.2,3.1,6.7,3.1c0.2,0,0.4-0.2,0.1-0.4
									  c-1.3-0.9-2.1-1.6-2.1-2.5c0-0.3,0-2.2,4-2.9c1.8,1.5,2.4,2.6,2.4,3.6c0,1.7-1.9,1.9-2.1,2.6c4.6,0.3,8.1,6.4,8.1,9.5
									  c0,5.5-5.2,7.8-8.5,8.5c4.7-3.6,6.5-7.1,6.5-9.6c0-4-3.7-6.4-4.5-6.9c1.6,1.7,2.6,4,2.6,6.4c0.1,1.9-0.7,3.8-2.2,5.1
									  c0.3-0.6,0.5-1.4,0.5-2.1c0-0.3,0-0.6-0.1-0.9c-1.9,4.4-6.2,7.5-6.5,11.3c-0.3-0.8-0.4-1.7-0.4-2.6c0-1.9,0.7-3.7,2-5.1
									  c-3.8,2.7-4.7,5.7-4.7,8.1c0,1.4,0.3,2.9,1,4.1c-0.2-1.2-0.1-2.4,0.1-3.6c0.4,4.3,3,8.1,6.7,10.2c-8.1,0-11.4-9.7-8.1-15.8
									  c-5.6,1.5-6.3,7.3-6.4,10.7c-0.3-1.4-0.5-2.8-0.5-4.2c0-6,5-7.6,8.8-9.3c5.5-2.5,7.8-4.4,7.8-8c0-3.8-4.4-6.1-7.7-5.4l1.2,1.2
									  c0.2,0.1,0.3,0.3,0.5,0.4c3.5,1.8,3.9,3.8,3.9,4.9c0,3.2-3.6,4.8-6.1,5.9c-8.3,3.6-9.8,5.1-9.8,10.3c0,3.8,1.1,9.7-2.9,10.2
									  c0.3,0.5,1.7,0.7,1.7,0.7c-1.1,0.9-2.5,1.4-3.9,1.3c-1.4,0-4.1-0.4-4.9-3.4C1107.8-15.1,1109-14.7,1110.3-14.7L1110.3-14.7z
										  M1131.9-48.8l0.3,0.2c0,0,1.8-0.8,1.8-2.1c0-0.4-0.1-0.8-0.4-1.1c-0.2,0-0.2,0.4-0.2,0.6c0,0.4-0.3,0.7-0.7,0.7c0,0-0.1,0-0.1,0
									  c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.1-0.8,0.3-1.2c0-0.1,0-0.1-0.1-0.1c-1.6,0.6-1.7,1.3-1.7,1.8C1130-50.4,1130.3-49.7,1131.9-48.8
									  L1131.9-48.8z M1112.8-44.8c0.1,0.2,0.3,0.4,0.6,0.4c0.4-0.3,0.7-0.8,0.8-1.4C1113.7-45.6,1112.8-45.4,1112.8-44.8L1112.8-44.8z
										  M1144.9-11.9l-0.3-0.1c-0.9,1.2-2.3,1.9-3.8,1.9l-0.1,0.2c0.9,0.9,0.7,2.9-0.4,2.9c-0.7,0-1-0.6-1.1-1.1l-0.2-0.1
									  c-0.4,0.3-0.9,0.6-1.3,1c0,0.6,0.1,1.3,0.2,1.9h0.2c0.2-0.4,0.8-0.6,1.2-0.4c0.3,0.2,0.5,0.5,0.5,0.9c0,1-0.8,1.9-1.9,1.9
									  c-0.1,0-0.1,0-0.2,0c-1.5,0-2.8-0.7-2.8-2.3c0-1,0.6-1.8,1.5-2.2v-0.2c-0.7-0.6-1.3-1.3-1.7-2.1h-0.2c-0.6,0.7-1.3,1.2-2.2,1.5V-8
									  c0.6,0.5,0.9,1.3,0.9,2.1c0,1.3-1.1,2.1-3.4,2.1c-1.7,0-3.4-0.5-3.4-2.2c-0.1-0.7,0.5-1.3,1.2-1.4c0,0,0.1,0,0.1,0
									  c0.6,0,1.2,0.3,1.5,0.8h0.2c0.4-0.5,0.8-1,1.3-1.4v-0.2c-0.5-0.4-0.9-0.9-1.3-1.5h-0.2c-0.4,0.6-1.1,0.9-1.8,0.9
									  c-0.5,0-0.9-0.3-1-0.8c0,0,0-0.1,0-0.1c0-0.9,1.1-1.3,2-1.6v-0.2c-1.4-0.6-2.5-1.7-3.2-3.2h-0.2c-0.6,1.4-1.7,2.5-3.2,3.2v0.2
									  c0.9,0.3,2,0.7,2,1.6c0,0.5-0.4,0.9-0.9,0.9c0,0-0.1,0-0.1,0c-0.7,0-1.4-0.3-1.8-0.9h-0.2c-0.4,0.6-0.8,1-1.3,1.5V-8
									  c0.5,0.4,0.9,0.9,1.3,1.4h0.2c0.3-0.5,0.9-0.8,1.5-0.8c0.7,0,1.3,0.6,1.3,1.3c0,0,0,0.1,0,0.1c0,1.7-1.7,2.2-3.4,2.2
									  c-2.3,0-3.4-0.8-3.4-2.1c0-0.8,0.3-1.5,0.9-2.1v-0.2c-0.9-0.3-1.6-0.8-2.2-1.5h-0.2c-0.4,0.8-1,1.5-1.7,2.1v0.2
									  c0.9,0.4,1.5,1.2,1.5,2.2c0,1.6-1.3,2.3-2.8,2.3c-1,0.1-2-0.6-2.1-1.7c0-0.1,0-0.1,0-0.2c-0.1-0.5,0.3-0.9,0.8-1
									  c0.4,0,0.7,0.2,0.9,0.5h0.2c0.1-0.6,0.2-1.2,0.2-1.9c-0.4-0.4-0.8-0.7-1.3-1l-0.2,0.1c-0.1,0.5-0.4,1.1-1.1,1.1
									  c-1.1,0-1.3-2-0.4-2.9l-0.1-0.2c-1.5,0-2.9-0.7-3.8-1.9l-0.3,0.1l4.1,9.5c0,0-0.9,0.4-0.7,1.1c0.4,1.4,1.6,3.2,1.6,5.3
									  c0,0.9-0.1,1-0.1,1.4s0.3,0.6,0.7,0.6h28c0.4,0,0.7-0.2,0.7-0.6s-0.1-0.5-0.1-1.4c0-2.2,1.2-3.9,1.6-5.3c0.2-0.7-0.7-1.1-0.7-1.1
									  L1144.9-11.9L1144.9-11.9z M1125.3,3.7c-7.7,0-12.8-0.1-12.8-0.1l-1.1-4.1c9.2-1.3,18.6-1.3,27.8,0l-1.1,4.1
									  C1138.1,3.6,1133,3.7,1125.3,3.7L1125.3,3.7z"></path>
					<g id="Group_4" transform="translate(0 82.348)">
					  <path id="Path_17" class="st0" d="M1247.2-45.1l-8.3-9.5h-5.5v0.7c1.7,0,1.9,0.2,1.9,1.6v7.9c0,2.8-0.6,3.1-2,3.1v0.7h5.1v-0.7
										  c-1.6,0-2.1-0.4-2.1-2v-9.3l11.1,12.5h0.8v-11.8c0-1.8,0.9-2,1.9-2v-0.7h-5.1v0.7c1.3,0,2.3,0.3,2.3,1.9L1247.2-45.1L1247.2-45.1z
										  "></path>
					  <path id="Path_18" class="st0" d="M1224.2-55c-3.8,0-7.7,2.5-7.7,7.5s3.9,7.5,7.7,7.5s7.7-2.5,7.7-7.5S1228.1-55,1224.2-55z
											  M1224.2-40.7c-2.7,0-3.9-2.4-3.9-6.8s1.2-6.8,3.9-6.8s3.9,2.4,3.9,6.8S1226.9-40.7,1224.2-40.7z"></path>
					  <path id="Path_19" class="st0" d="M1205.9-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.9
										  c-0.1-0.4,0.2-0.7,0.5-0.7c0.1,0,0.1,0,0.2,0c1.9,0.3,3.6,1.5,4.5,3.3l0.6-0.2l-0.9-4.2h-0.6c-0.4,0.2-0.9,0.4-1.4,0.4h-9.4
										  c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.5-0.2-0.7-0.3h-0.6l-0.9,4.2l0.6,0.2c0.9-1.8,2.5-3,4.5-3.3c0.4-0.1,0.7,0.2,0.7,0.5
										  c0,0.1,0,0.1,0,0.2L1205.9-42.3L1205.9-42.3z"></path>
					  <path id="Path_20" class="st0" d="M1188.8-42.3c0,1-0.3,1.1-1.8,1.1v0.7h13.4l1.1-4.1h-0.7c-2,2.9-3.2,3.4-6.6,3.4
										  c-1.6,0-1.9-0.3-1.9-1v-10.6c0-1,0.3-1.1,1.8-1.1v-0.7h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1L1188.8-42.3L1188.8-42.3z"></path>
					  <path id="Path_21" class="st0" d="M1183.4-42.6l-2.3-3.6c-0.3-0.7-1-1.2-1.8-1.3v-0.1c2.3-0.1,3.7-1.3,3.7-3.1
										  c0-1.8-1.6-3.9-6.2-3.9h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1v10.4c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-5
										  c1.5,0,2,0.2,2.4,0.9l3.3,5.9h4.9v-0.7C1184.7-41.2,1184.2-41.3,1183.4-42.6L1183.4-42.6z M1176.1-47.9h-1.2v-5.9h0.8
										  c2.9,0,3.6,1.7,3.6,3C1179.3-48.7,1177.8-47.9,1176.1-47.9L1176.1-47.9z"></path>
					  <path id="Path_22" class="st0" d="M1165.6-42.7l-6-11.5c-0.3-0.4-0.4-0.6-0.5-0.6c-0.7,0.9-1.5,1.6-2.4,2.3
										  c0.3,0.1,0.4,0.4,0.4,0.6c0,0.2-0.1,0.5-0.2,0.7l-3.6,7.5c-0.9,1.9-1.5,2.6-2.8,2.6v0.7h5.1v-0.7c-1.4,0-1.9-0.4-1.9-0.9
										  c0.1-0.7,0.4-1.4,0.8-2h6.4c0.5,0.7,0.9,1.4,1,2.3c0,0.5-0.4,0.6-1.9,0.6v0.7h7.7v-0.7C1166.7-41.2,1166.3-41.4,1165.6-42.7
										  L1165.6-42.7z M1155-44.9l2.8-5.9l2.9,5.9H1155z"></path>
					  <path id="Path_23" class="st0" d="M1148-45.3c-1.2,2.6-3.1,4.5-5.5,4.5c-2.1,0-4.6-1.6-4.6-6.7c0-4.7,1.9-6.7,4.7-6.7
										  c2.6,0,4.2,1.7,5.3,3.9h0.6v-4.4h-0.5c-0.2,0.4-0.6,0.6-1.1,0.6c-0.8,0-2.6-0.7-4.7-0.7c-5,0-8.2,3.3-8.2,7.7
										  c0,3.9,3.2,7.2,7.8,7.2c2.6,0,4.4-1,5.1-1c0.5,0,0.8,0.3,1.1,1h0.5v-5.3L1148-45.3z"></path>
					  <path id="Path_24" class="st0" d="M1104.3-40.4h12.3l1.5-4.3h-0.6c-2.1,2.7-3.3,3.6-5.4,3.6h-3.5l9-13.1v-0.4h-12.2l-1.3,4h0.5
										  c1.6-2.1,3.3-3.2,5.2-3.2h3.3l-8.9,12.9L1104.3-40.4L1104.3-40.4z"></path>
					  <path id="Path_25" class="st0" d="M1093.4-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.9
										  c-0.1-0.4,0.2-0.7,0.5-0.7c0.1,0,0.1,0,0.2,0c1.9,0.3,3.6,1.5,4.5,3.3l0.5-0.2l-0.9-4.2h-0.6c-0.4,0.2-0.9,0.4-1.4,0.4h-9.4
										  c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.5-0.2-0.7-0.3h-0.6l-0.9,4.2l0.5,0.2c0.9-1.8,2.5-3,4.5-3.3c0.4-0.1,0.7,0.2,0.7,0.5
										  c0,0.1,0,0.1,0,0.2L1093.4-42.3L1093.4-42.3z"></path>
					  <path id="Path_26" class="st0" d="M1080.5-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.4
										  c0-1,0.3-1.1,1.8-1.1v-0.7h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1L1080.5-42.3L1080.5-42.3z"></path>
					  <path id="Path_27" class="st0" d="M1074.8-42.6l-2.3-3.6c-0.3-0.7-1-1.2-1.8-1.3v-0.1c2.3-0.1,3.7-1.3,3.7-3.1
										  c0-1.8-1.6-3.9-6.2-3.9h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1v10.4c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-5
										  c1.5,0,2,0.2,2.4,0.9l3.3,5.9h4.9v-0.7C1076-41.2,1075.6-41.3,1074.8-42.6L1074.8-42.6z M1067.5-47.9h-1.2v-5.9h0.8
										  c2.9,0,3.6,1.7,3.6,3C1070.7-48.7,1069.2-47.9,1067.5-47.9L1067.5-47.9z"></path>
					  <path id="Path_28" class="st0" d="M1038.4-42.3c0,1-0.3,1.1-1.8,1.1v0.7h13.1l1.1-4.1h-0.7c-1.6,2.7-3.2,3.4-6.1,3.4
										  c-1.9,0-2.1-0.3-2.1-0.9v-5.4h0.9c2.6,0,3.7,0.7,4.1,2.7h0.6V-51h-0.6c-0.6,2.1-1.5,2.9-4.1,2.9h-0.9V-53c0-0.8,0.3-0.9,1.3-0.9
										  c3.2,0,4.7,0.9,6.7,3.1h0.6l-1.4-3.9h-12.5v0.7c1.5,0,1.8,0.1,1.8,1.1V-42.3z"></path>
					  <path id="Path_29" class="st0" d="M1029.1-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.4
										  c0-1,0.3-1.1,1.8-1.1v-0.7h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1v4.3h-7.2v-4.3c0-1,0.3-1.1,1.8-1.1v-0.7h-7.1v0.7
										  c1.5,0,1.8,0.1,1.8,1.1v10.4c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-5.1h7.2V-42.3z"></path>
					  <path id="Path_30" class="st0" d="M1005.9-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.9
										  c-0.1-0.4,0.2-0.7,0.5-0.7c0.1,0,0.1,0,0.2,0c1.9,0.3,3.6,1.5,4.5,3.3l0.5-0.2l-0.9-4.2h-0.6c-0.4,0.2-0.9,0.4-1.4,0.4h-9.4
										  c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.5-0.2-0.7-0.3h-0.6l-0.9,4.2l0.5,0.2c0.8-1.8,2.5-3,4.5-3.3c0.4-0.1,0.7,0.2,0.7,0.5
										  c0,0.1,0,0.1,0,0.2V-42.3L1005.9-42.3z"></path>
					  <path id="Path_31" class="st0" d="M1122.7-46.5h4.7l2-2.9h-4.7L1122.7-46.5z"></path>
					</g>
				  </g>
				</svg>
  
  
				<div class="logo_size">
				  <img src="assets/components/images/mobile_stickey.png" />
				</div>
  
  
				<svg class="brand-lockup__logo v-ML logo-id-4" [ngStyle]="{'display': stickyHeader ? 'none': ''}" id="Layer_1" x="0px" y="0px" viewBox="0 0 250 119.2" style="enable-background:new 0 0 250 119.2;"
				  xml:space="preserve">
				  <style type="text/css">
					.st0 {
					  fill: #1C1C1C;
					}
				  </style>
				  <g id="Group_5" transform="translate(-1000.091 54.9)">
					<g id="Group_3" transform="translate(3.863 104.369)">
					  <path id="Path_1" class="st0" d="M1007.1-46.5l-4.8-6.3c-0.5-0.8-1.4-1.1-2.3-1v-0.7h7.7v0.7c-0.9,0-1.4,0-1.4,0.4
													  c0,0.3,0.1,0.4,0.6,1l3.4,4.5l3.4-4.7c0.2-0.2,0.3-0.5,0.4-0.7c0-0.4-0.7-0.5-1.5-0.5v-0.7h4.7v0.7c-1.6,0-1.8,0.1-3,1.9l-3.7,5
													  v4.6c0,1,0.3,1.1,1.9,1.1v0.7h-7.4v-0.7c1.6,0,1.9-0.1,1.9-1.1V-46.5z" />
					  <path id="Path_2" class="st0" d="M1020.7-51.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.2-0.5-0.4-0.6c0.9-0.7,1.7-1.4,2.4-2.3
													  c0.1,0,0.2,0.1,0.5,0.6l6,11.5c0.7,1.3,1,1.5,2.3,1.5v0.7h-7.7v-0.8c1.5,0,1.9-0.1,1.9-0.6c-0.2-0.8-0.5-1.6-1-2.3h-6.4
													  c-0.4,0.6-0.7,1.2-0.8,1.9c0,0.5,0.5,0.9,1.9,0.9v0.7h-5.1v-0.7c1.4,0,1.9-0.6,2.8-2.6L1020.7-51.2z M1021.4-50.9l-2.8,5.9h5.7
													  L1021.4-50.9z" />
					  <path id="Path_3" class="st0" d="M1046.1-45.3v5.2h-0.5c-0.3-0.6-0.6-1-1.1-1c-0.6,0-2.5,1-5.1,1c-4.6,0-7.8-3.3-7.8-7.2
													  c0-4.4,3.1-7.7,8.2-7.7c2.1,0,3.8,0.7,4.7,0.7c0.4,0,0.8-0.2,1.1-0.6h0.5v4.4h-0.6c-1.1-2.1-2.7-3.8-5.3-3.8
													  c-2.7,0-4.7,2.1-4.7,6.7c0,5.1,2.4,6.7,4.5,6.7c2.3,0,4.2-1.9,5.4-4.5L1046.1-45.3z" />
					  <path id="Path_4" class="st0" d="M1060.6-47.4h-7.2v5.1c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7c1.5,0,1.8-0.1,1.8-1.1v-10.4
													  c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1v4.3h7.2v-4.3c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1
													  v10.4c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7c1.5,0,1.8-0.1,1.8-1.1L1060.6-47.4L1060.6-47.4z" />
					  <path id="Path_5" class="st0" d="M1072.4-53.2c0.1-0.4-0.2-0.7-0.5-0.7c-0.1,0-0.1,0-0.2,0c-1.9,0.3-3.6,1.5-4.5,3.3l-0.6-0.2
													  l0.8-4.1h0.6c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.5,0.1,0.7,0.1h9.3c0.5,0,1-0.1,1.4-0.4h0.6l0.8,4.1l-0.5,0.2
													  c-0.8-1.8-2.5-3-4.5-3.3c-0.4-0.1-0.7,0.2-0.7,0.5c0,0.1,0,0.1,0,0.2v10.9c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7
													  c1.5,0,1.8-0.1,1.8-1.1L1072.4-53.2L1072.4-53.2z" />
					  <path id="Path_6" class="st0" d="M1103.4-45.3v5.2h-0.5c-0.3-0.6-0.6-1-1.1-1c-0.6,0-2.5,1-5.1,1c-4.6,0-7.8-3.3-7.8-7.2
													  c0-4.4,3.1-7.7,8.2-7.7c2.1,0,3.8,0.7,4.7,0.7c0.4,0,0.8-0.2,1.1-0.6h0.5v4.4h-0.6c-1.1-2.1-2.7-3.8-5.3-3.8
													  c-2.7,0-4.7,2.1-4.7,6.7c0,5.1,2.4,6.7,4.5,6.7c2.3,0,4.2-1.9,5.4-4.5L1103.4-45.3z" />
					  <path id="Path_7" class="st0" d="M1113-55c3.8,0,7.7,2.5,7.7,7.4s-3.9,7.4-7.7,7.4c-3.8,0-7.7-2.5-7.7-7.4S1109.2-55,1113-55z
													  M1113-40.7c2.6,0,3.8-2.4,3.8-6.8s-1.2-6.8-3.8-6.8c-2.6,0-3.8,2.4-3.8,6.8S1110.4-40.7,1113-40.7z" />
					  <path id="Path_8" class="st0" d="M1123.5-52.7c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1v10.5c0,0.7,0.3,1,1.9,1
													  c3.4,0,4.7-0.5,6.6-3.4h0.7l-1.1,4.1h-13.4v-0.7c1.5,0,1.8-0.1,1.8-1.1V-52.7z" />
					  <path id="Path_9" class="st0" d="M1138.9-52.7c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1v10.5c0,0.7,0.3,1,1.9,1
													  c3.4,0,4.7-0.5,6.6-3.4h0.7l-1.1,4.1h-13.4v-0.7c1.5,0,1.8-0.1,1.8-1.1V-52.7z" />
					  <path id="Path_10" class="st0" d="M1154.4-52.7c0-1-0.3-1.1-1.8-1.1v-0.7h12.5l1.4,3.9h-0.6c-2-2.3-3.5-3.1-6.7-3.1
													  c-1,0-1.3,0.1-1.3,0.9v4.8h0.9c2.6,0,3.6-0.8,4.1-2.9h0.6v6.3h-0.6c-0.4-2-1.6-2.7-4.1-2.7h-0.9v5.4c0,0.6,0.3,0.8,2.1,0.8
													  c2.9,0,4.5-0.7,6-3.4h0.7l-1.1,4.1h-13v-0.7c1.5,0,1.8-0.1,1.8-1.1V-52.7z" />
					  <path id="Path_11" class="st0" d="M1182.6-45.3v5.2h-0.5c-0.3-0.6-0.6-1-1.1-1c-0.6,0-2.5,1-5.1,1c-4.6,0-7.8-3.3-7.8-7.2
													  c0-4.4,3.1-7.7,8.2-7.7c2.1,0,3.8,0.7,4.7,0.7c0.4,0,0.8-0.2,1.1-0.6h0.5v4.4h-0.6c-1.1-2.1-2.7-3.8-5.3-3.8
													  c-2.7,0-4.7,2.1-4.7,6.7c0,5.1,2.4,6.7,4.5,6.7c2.3,0,4.2-1.9,5.4-4.5L1182.6-45.3z" />
					  <path id="Path_12" class="st0" d="M1190.2-53.2c0.1-0.4-0.2-0.7-0.5-0.7c-0.1,0-0.1,0-0.2,0c-1.9,0.3-3.6,1.5-4.5,3.3l-0.6-0.2
													  l0.8-4.1h0.6c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.5,0.1,0.7,0.1h9.3c0.5,0,1-0.1,1.4-0.4h0.6l0.8,4.1l-0.6,0.2
													  c-0.8-1.8-2.5-3-4.5-3.3c-0.4-0.1-0.7,0.2-0.7,0.5c0,0.1,0,0.1,0,0.2v10.9c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7
													  c1.5,0,1.8-0.1,1.8-1.1L1190.2-53.2L1190.2-53.2z" />
					  <path id="Path_13" class="st0" d="M1202.7-52.7c0-1-0.3-1.1-1.8-1.1v-0.7h7.1v0.7c-1.5,0-1.8,0.1-1.8,1.1v10.4
													  c0,1,0.3,1.1,1.8,1.1v0.7h-7.1v-0.7c1.5,0,1.8-0.1,1.8-1.1L1202.7-52.7z" />
					  <path id="Path_14" class="st0" d="M1217.1-55c3.8,0,7.7,2.5,7.7,7.4s-3.9,7.4-7.7,7.4s-7.7-2.5-7.7-7.4S1213.3-55,1217.1-55z
													  M1217.1-40.7c2.6,0,3.8-2.4,3.8-6.8s-1.2-6.8-3.8-6.8s-3.8,2.4-3.8,6.8S1214.5-40.7,1217.1-40.7z" />
					  <path id="Path_15" class="st0" d="M1239.5-51.9c0-1.7-1-1.9-2.3-1.9v-0.7h5.1v0.7c-0.9,0-1.9,0.2-1.9,1.9v11.8h-0.8l-11-12.5v9.3
													  c0,1.7,0.5,2,2.1,2v0.7h-5.1v-0.7c1.4,0,2-0.3,2-3.1v-7.9c0-1.4-0.3-1.6-1.9-1.6v-0.8h5.5l8.2,9.5L1239.5-51.9L1239.5-51.9z"
					  />
					</g>
					<path id="Path_16" class="st0" d="M1116.7,1.2c0-0.3,0.8-1.1,1-1.1c0.1,0,0.9,0.8,0.9,1.1c-0.2,0.4-0.5,0.7-0.9,1
											  C1117.5,2.2,1116.7,1.3,1116.7,1.2z M1132,1.2c0-0.3,0.8-1.1,1-1.1c0.1,0,0.9,0.8,0.9,1.1c-0.2,0.4-0.5,0.7-0.9,1
											  C1132.8,2.2,1132,1.3,1132,1.2z M1124.3,1.2c0-0.3,0.8-1.1,1-1.1c0.1,0,0.9,0.8,0.9,1.1c-0.2,0.4-0.5,0.7-0.9,1
											  C1125.1,2.2,1124.3,1.3,1124.3,1.2z M1115.7,2.7h3.8c0.3,0,0.5-0.2,0.5-0.4c0,0,0,0,0-0.1V0.1c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0-0.1,0
											  c-0.8,0-2.7,0.1-3.8,0.2c-0.2,0-0.4,0.1-0.4,0.3c0,0,0,0,0,0.1v2.2C1115.3,2.6,1115.4,2.7,1115.7,2.7
											  C1115.6,2.8,1115.7,2.8,1115.7,2.7L1115.7,2.7z M1113.1,1.4c0-0.2,0.6-0.8,0.7-0.8s0.7,0.6,0.7,0.8c0.1,0.4-0.2,0.7-0.6,0.8
											  c-0.4,0.1-0.7-0.2-0.8-0.6C1113.1,1.5,1113.1,1.4,1113.1,1.4L1113.1,1.4z M1120.7,1.2c0-0.2,0.6-0.8,0.7-0.8s0.7,0.6,0.7,0.8
											  c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6C1120.7,1.3,1120.7,1.2,1120.7,1.2L1120.7,1.2z M1127.1,2.8
											  c0.3,0,0.5-0.2,0.5-0.4c0,0,0,0,0-0.1V0c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0-0.1,0h-3.8c-0.3,0-0.5,0.2-0.5,0.4c0,0,0,0,0,0.1v2.4
											  c0,0.3,0.2,0.5,0.4,0.5c0,0,0,0,0.1,0L1127.1,2.8z M1134.9,2.7c0.2,0,0.4-0.1,0.4-0.3c0,0,0,0,0-0.1V0.2c0-0.2-0.1-0.4-0.3-0.4
											  c0,0,0,0-0.1,0c-1.2-0.1-3.1-0.2-3.8-0.2c-0.3,0-0.5,0.2-0.5,0.4c0,0,0,0,0,0.1v2.2c0,0.3,0.2,0.5,0.4,0.5c0,0,0,0,0.1,0H1134.9
											  L1134.9,2.7z M1137.4,1.4c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6c0-0.1,0-0.1,0-0.2c0-0.2,0.6-0.8,0.7-0.8
											  S1137.4,1.2,1137.4,1.4L1137.4,1.4z M1129.8,1.2c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6c0-0.1,0-0.1,0-0.2
											  c0-0.2,0.6-0.8,0.7-0.8S1129.8,1,1129.8,1.2L1129.8,1.2z M1119.7-44.7L1119.7-44.7c0.6,1.9,2.8,2.1,3.3,4.2l-0.1,0.1
											  c-1.1-1.6-2.6-1.8-3.2-2.6C1119.4-43.3,1119.4-43.8,1119.7-44.7L1119.7-44.7z M1123.4-19.4c0,3.1-2.6,4.4-5.2,4.4
											  c3.5-3.8,0.8-9.2,4.1-12.7C1122-24.5,1123.4-21.6,1123.4-19.4L1123.4-19.4z M1139.8-11c3.7,0,5.5-1.9,5.5-4.7
											  c0-0.6-0.1-1.1-0.3-1.7c-0.8,0.6-1.8,1-2.9,1c-1.8,0.1-3.3-1.2-3.4-3c0-0.1,0-0.2,0-0.2c0-3.1,3.3-4.6,3.3-8.1c0-0.5-0.1-1-0.4-1.4
											  c1.2-1.5,1.9-3.4,2-5.3c0-1.6-0.5-3.2-1.5-4.5l1.6,0.8c-0.4-1.6-1.2-3.1-2.2-4.4c0,0,0.8,0.4,1.2,0.2c-0.8-0.4-2-1.9-2-4.5
											  s-0.6-4.1-5.7-5c-0.7-1.1-1.6-2.1-2.7-2.9c-1.2,0.5-2.4,1.1-3.6,1.8c0,0-1.5,0.1-2.1,0.1c-0.7-0.1-1.3-0.2-2-0.3
											  c-0.8,0.1-1.5,0.5-2,1.2c-1.5,0.1-3,0.2-4.5,0.5c-1,0.2-1.6,1-2.3,2.7l-4.8,2.7c-0.9,0.5-1.5,0.8-1.5,1.6c0.1,0.7,0.4,1.4,0.9,2
											  c0.5,3,1,3.5,1.7,3.5c0.2,0,0.4,0,0.6-0.1c0.3,0.4,0.6,0.8,0.8,1.3c-0.8,0.4-1.8-0.5-3-0.5c-0.9-0.1-1.7,0.6-1.8,1.5c0,0,0,0,0,0.1
											  c0,0.4,0.2,0.5,0.4,0.5c0.3,0,0.6-0.1,1.3-0.1c0.9,0,1.6,0.8,3,0.8c0.5,0,0.8-0.1,1,0c-0.7,0.5-1.1,0.8-0.9,1.5
											  c0.3,1.3,1.5,2.8,2.1,2.8c0.5,0,0.9-0.1,1.4-0.3l-0.6,0.5c-3.9,3.4-3.1,7.1-3.1,10.9c0,2.9-0.8,4.6-3.1,4.6c-2-0.2-3.9-1.2-5.1-2.9
											  c-0.2,0.7-0.3,1.5-0.3,2.3c0,3.5,3.2,5.7,6.3,5.7c2.5,0.1,4.8-0.8,6.7-2.4c5.6-0.1,7.4-3.8,7.4-3.8c2.7,3.8,7.6,3.6,8.4,3.6
											  C1135.3-11.9,1137.5-11,1139.8-11L1139.8-11z M1140.2-31.9c0,4.1-5.3,4.6-5.3,8.7c0,1.6,0.5,2.7,0.2,3.7c0.6-0.6,1-1.3,1.2-2.1
											  c0,3.1,1.6,6.6,5.6,6.6c0.8,0,1.6-0.2,2.3-0.6c-0.2,1.7-1.7,3-3.5,2.9c-4.1,0-7.9-3.5-7.9-9.9c-0.1-1.2,0.3-2.5,0.9-3.6
											  c0,0-2,1.1-2.2,4.5c-0.5-0.8-0.8-1.7-0.8-2.7C1130.7-29.2,1136.7-27.5,1140.2-31.9L1140.2-31.9z M1135.5-50.6
											  c3.7,0.8,3.2,3.2,3.8,4.8c-1-1.3-2.3-2.4-3.7-3.3C1135.9-49.5,1135.8-50.1,1135.5-50.6L1135.5-50.6z M1113.4-40.7
											  c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0.5,0.2,1.1,0.1,1.7c-0.6,0-1.1-0.5-1.1-1.1C1113.4-40.5,1113.4-40.6,1113.4-40.7L1113.4-40.7z
											  M1115.5-37.7c-0.4,0.6-0.7,1.4-1.9,1.4c-1.2,0-2.2-0.9-3-0.9c-0.4,0-0.6,0.1-0.8,0.1s-0.3-0.1-0.3-0.2c0-0.3,0.4-0.6,1-0.6
											  c0.7,0.1,1.3,0.3,2,0.5c1.3,0.4,2.1-0.7,2.6-1.1c0.4-0.4,1-0.6,1.6-0.6c0.6,0,1.2,0.2,1.7,0.5c-0.8,0.2-1.7,0.5-2.4,1
											  C1115.7-37.7,1115.6-37.8,1115.5-37.7L1115.5-37.7z M1116.6-36.4c-0.3,0.3-0.6,0.4-1,0.4c-0.1-0.4-0.1-0.8,0-1.2
											  C1116-37.2,1116.6-36.9,1116.6-36.4z M1110.3-14.7c4.6,0,4.5-5.3,4.5-7.1c0-8,2.4-10,7.9-12.5c4.1-2,7.9-2.8,7.9-6.7
											  c0-1.4-0.4-2.9-2-2.9c0.4,0.4,0.6,1,0.6,1.6c0,0.4-0.1,0.9-0.3,1.3c0-0.5-0.1-0.9-0.4-1.3c-0.5,0.8-0.4,2-0.7,2.5
											  c0,0-0.2,0.5-1.6,1.2c0,0-0.2,0.2,0.5,0.2c0,0,1.7,0,2.3-0.7c-0.5,1.4-1.1,1.9-2,1.9c-0.5,0-1.3-0.1-2-0.1c-1.2,0-3.9,0.6-5,2.5
											  c-0.9,1.3-2.3,2.1-3.8,2.5c-0.7,0-1.7-1.6-1.7-2.3c0-0.8,1.4-0.3,2.5-1.4c1.8-1.8,2.5-2.2,4.3-2.2c0,0,1.1,0,0.1-1l-3.1-2.9
											  c-0.3-0.3-0.7-0.6-1.1-0.6c-0.2,0-0.6,0.1-1.5,0.9c-1,0-2,0.3-3,0.8c-0.3,0.2-0.5,0.4-0.7,0.4c-0.3,0-0.7-1.6-0.7-1.9
											  c0-0.8,0.9-1.3,0.9-1.8c0-0.2-0.1-0.3-0.3-0.4l-1.3,0.6c0,0-0.7-0.8,0.1-1.2c0,0,2-1.1,5.7-2.9c0.2,1.1,0.5,1.3,1.4,1.2l-0.4,0.1
											  l-0.2,0.3c0.5,0.1,1,0,1.5-0.2c0.5-0.2,1.1-0.2,1.2-0.3c0.1-0.1,0.4-0.4,0.4-0.5c-0.4,0-0.8,0.1-1.2,0.2c0.6-0.4,0.9-0.7,0.9-0.9
											  c0-0.1,0-0.1-0.1-0.2c-0.3,0-0.5,0.2-0.8,0.1c-0.2-0.1-0.4-0.3-0.4-0.6c0-0.2,0.2-0.4,0.9-0.4h0.5c0-0.4-1-0.6-1.4-0.6
											  c-0.6,0-1.2,0.2-1.8,0.5c0.1-0.5,0.5-1,1-1.2c1.8-0.4,3.7-0.5,5.5-0.5c3.6,0,6.2,3.1,6.7,3.1c0.2,0,0.4-0.2,0.1-0.4
											  c-1.3-0.9-2.1-1.6-2.1-2.5c0-0.3,0-2.2,4-2.9c1.8,1.5,2.4,2.6,2.4,3.6c0,1.7-1.9,1.9-2.1,2.6c4.6,0.3,8.1,6.4,8.1,9.5
											  c0,5.5-5.2,7.8-8.5,8.5c4.7-3.6,6.5-7.1,6.5-9.6c0-4-3.7-6.4-4.5-6.9c1.6,1.7,2.6,4,2.6,6.4c0.1,1.9-0.7,3.8-2.2,5.1
											  c0.3-0.6,0.5-1.4,0.5-2.1c0-0.3,0-0.6-0.1-0.9c-1.9,4.4-6.2,7.5-6.5,11.3c-0.3-0.8-0.4-1.7-0.4-2.6c0-1.9,0.7-3.7,2-5.1
											  c-3.8,2.7-4.7,5.7-4.7,8.1c0,1.4,0.3,2.9,1,4.1c-0.2-1.2-0.1-2.4,0.1-3.6c0.4,4.3,3,8.1,6.7,10.2c-8.1,0-11.4-9.7-8.1-15.8
											  c-5.6,1.5-6.3,7.3-6.4,10.7c-0.3-1.4-0.5-2.8-0.5-4.2c0-6,5-7.6,8.8-9.3c5.5-2.5,7.8-4.4,7.8-8c0-3.8-4.4-6.1-7.7-5.4l1.2,1.2
											  c0.2,0.1,0.3,0.3,0.5,0.4c3.5,1.8,3.9,3.8,3.9,4.9c0,3.2-3.6,4.8-6.1,5.9c-8.3,3.6-9.8,5.1-9.8,10.3c0,3.8,1.1,9.7-2.9,10.2
											  c0.3,0.5,1.7,0.7,1.7,0.7c-1.1,0.9-2.5,1.4-3.9,1.3c-1.4,0-4.1-0.4-4.9-3.4C1107.8-15.1,1109-14.7,1110.3-14.7L1110.3-14.7z
											  M1131.9-48.8l0.3,0.2c0,0,1.8-0.8,1.8-2.1c0-0.4-0.1-0.8-0.4-1.1c-0.2,0-0.2,0.4-0.2,0.6c0,0.4-0.3,0.7-0.7,0.7c0,0-0.1,0-0.1,0
											  c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.1-0.8,0.3-1.2c0-0.1,0-0.1-0.1-0.1c-1.6,0.6-1.7,1.3-1.7,1.8C1130-50.4,1130.3-49.7,1131.9-48.8
											  L1131.9-48.8z M1112.8-44.8c0.1,0.2,0.3,0.4,0.6,0.4c0.4-0.3,0.7-0.8,0.8-1.4C1113.7-45.6,1112.8-45.4,1112.8-44.8L1112.8-44.8z
											  M1144.9-11.9l-0.3-0.1c-0.9,1.2-2.3,1.9-3.8,1.9l-0.1,0.2c0.9,0.9,0.7,2.9-0.4,2.9c-0.7,0-1-0.6-1.1-1.1l-0.2-0.1
											  c-0.4,0.3-0.9,0.6-1.3,1c0,0.6,0.1,1.3,0.2,1.9h0.2c0.2-0.4,0.8-0.6,1.2-0.4c0.3,0.2,0.5,0.5,0.5,0.9c0,1-0.8,1.9-1.9,1.9
											  c-0.1,0-0.1,0-0.2,0c-1.5,0-2.8-0.7-2.8-2.3c0-1,0.6-1.8,1.5-2.2v-0.2c-0.7-0.6-1.3-1.3-1.7-2.1h-0.2c-0.6,0.7-1.3,1.2-2.2,1.5V-8
											  c0.6,0.5,0.9,1.3,0.9,2.1c0,1.3-1.1,2.1-3.4,2.1c-1.7,0-3.4-0.5-3.4-2.2c-0.1-0.7,0.5-1.3,1.2-1.4c0,0,0.1,0,0.1,0
											  c0.6,0,1.2,0.3,1.5,0.8h0.2c0.4-0.5,0.8-1,1.3-1.4v-0.2c-0.5-0.4-0.9-0.9-1.3-1.5h-0.2c-0.4,0.6-1.1,0.9-1.8,0.9
											  c-0.5,0-0.9-0.3-1-0.8c0,0,0-0.1,0-0.1c0-0.9,1.1-1.3,2-1.6v-0.2c-1.4-0.6-2.5-1.7-3.2-3.2h-0.2c-0.6,1.4-1.7,2.5-3.2,3.2v0.2
											  c0.9,0.3,2,0.7,2,1.6c0,0.5-0.4,0.9-0.9,0.9c0,0-0.1,0-0.1,0c-0.7,0-1.4-0.3-1.8-0.9h-0.2c-0.4,0.6-0.8,1-1.3,1.5V-8
											  c0.5,0.4,0.9,0.9,1.3,1.4h0.2c0.3-0.5,0.9-0.8,1.5-0.8c0.7,0,1.3,0.6,1.3,1.3c0,0,0,0.1,0,0.1c0,1.7-1.7,2.2-3.4,2.2
											  c-2.3,0-3.4-0.8-3.4-2.1c0-0.8,0.3-1.5,0.9-2.1v-0.2c-0.9-0.3-1.6-0.8-2.2-1.5h-0.2c-0.4,0.8-1,1.5-1.7,2.1v0.2
											  c0.9,0.4,1.5,1.2,1.5,2.2c0,1.6-1.3,2.3-2.8,2.3c-1,0.1-2-0.6-2.1-1.7c0-0.1,0-0.1,0-0.2c-0.1-0.5,0.3-0.9,0.8-1
											  c0.4,0,0.7,0.2,0.9,0.5h0.2c0.1-0.6,0.2-1.2,0.2-1.9c-0.4-0.4-0.8-0.7-1.3-1l-0.2,0.1c-0.1,0.5-0.4,1.1-1.1,1.1
											  c-1.1,0-1.3-2-0.4-2.9l-0.1-0.2c-1.5,0-2.9-0.7-3.8-1.9l-0.3,0.1l4.1,9.5c0,0-0.9,0.4-0.7,1.1c0.4,1.4,1.6,3.2,1.6,5.3
											  c0,0.9-0.1,1-0.1,1.4s0.3,0.6,0.7,0.6h28c0.4,0,0.7-0.2,0.7-0.6s-0.1-0.5-0.1-1.4c0-2.2,1.2-3.9,1.6-5.3c0.2-0.7-0.7-1.1-0.7-1.1
											  L1144.9-11.9L1144.9-11.9z M1125.3,3.7c-7.7,0-12.8-0.1-12.8-0.1l-1.1-4.1c9.2-1.3,18.6-1.3,27.8,0l-1.1,4.1
											  C1138.1,3.6,1133,3.7,1125.3,3.7L1125.3,3.7z" />
					<g id="Group_4" transform="translate(0 82.348)">
					  <path id="Path_17" class="st0" d="M1247.2-45.1l-8.3-9.5h-5.5v0.7c1.7,0,1.9,0.2,1.9,1.6v7.9c0,2.8-0.6,3.1-2,3.1v0.7h5.1v-0.7
													  c-1.6,0-2.1-0.4-2.1-2v-9.3l11.1,12.5h0.8v-11.8c0-1.8,0.9-2,1.9-2v-0.7h-5.1v0.7c1.3,0,2.3,0.3,2.3,1.9L1247.2-45.1L1247.2-45.1z
													  " />
					  <path id="Path_18" class="st0" d="M1224.2-55c-3.8,0-7.7,2.5-7.7,7.5s3.9,7.5,7.7,7.5s7.7-2.5,7.7-7.5S1228.1-55,1224.2-55z
													  M1224.2-40.7c-2.7,0-3.9-2.4-3.9-6.8s1.2-6.8,3.9-6.8s3.9,2.4,3.9,6.8S1226.9-40.7,1224.2-40.7z" />
					  <path id="Path_19" class="st0" d="M1205.9-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.9
													  c-0.1-0.4,0.2-0.7,0.5-0.7c0.1,0,0.1,0,0.2,0c1.9,0.3,3.6,1.5,4.5,3.3l0.6-0.2l-0.9-4.2h-0.6c-0.4,0.2-0.9,0.4-1.4,0.4h-9.4
													  c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.5-0.2-0.7-0.3h-0.6l-0.9,4.2l0.6,0.2c0.9-1.8,2.5-3,4.5-3.3c0.4-0.1,0.7,0.2,0.7,0.5
													  c0,0.1,0,0.1,0,0.2L1205.9-42.3L1205.9-42.3z" />
					  <path id="Path_20" class="st0" d="M1188.8-42.3c0,1-0.3,1.1-1.8,1.1v0.7h13.4l1.1-4.1h-0.7c-2,2.9-3.2,3.4-6.6,3.4
													  c-1.6,0-1.9-0.3-1.9-1v-10.6c0-1,0.3-1.1,1.8-1.1v-0.7h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1L1188.8-42.3L1188.8-42.3z"
					  />
					  <path id="Path_21" class="st0" d="M1183.4-42.6l-2.3-3.6c-0.3-0.7-1-1.2-1.8-1.3v-0.1c2.3-0.1,3.7-1.3,3.7-3.1
													  c0-1.8-1.6-3.9-6.2-3.9h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1v10.4c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-5
													  c1.5,0,2,0.2,2.4,0.9l3.3,5.9h4.9v-0.7C1184.7-41.2,1184.2-41.3,1183.4-42.6L1183.4-42.6z M1176.1-47.9h-1.2v-5.9h0.8
													  c2.9,0,3.6,1.7,3.6,3C1179.3-48.7,1177.8-47.9,1176.1-47.9L1176.1-47.9z" />
					  <path id="Path_22" class="st0" d="M1165.6-42.7l-6-11.5c-0.3-0.4-0.4-0.6-0.5-0.6c-0.7,0.9-1.5,1.6-2.4,2.3
													  c0.3,0.1,0.4,0.4,0.4,0.6c0,0.2-0.1,0.5-0.2,0.7l-3.6,7.5c-0.9,1.9-1.5,2.6-2.8,2.6v0.7h5.1v-0.7c-1.4,0-1.9-0.4-1.9-0.9
													  c0.1-0.7,0.4-1.4,0.8-2h6.4c0.5,0.7,0.9,1.4,1,2.3c0,0.5-0.4,0.6-1.9,0.6v0.7h7.7v-0.7C1166.7-41.2,1166.3-41.4,1165.6-42.7
													  L1165.6-42.7z M1155-44.9l2.8-5.9l2.9,5.9H1155z" />
					  <path id="Path_23" class="st0" d="M1148-45.3c-1.2,2.6-3.1,4.5-5.5,4.5c-2.1,0-4.6-1.6-4.6-6.7c0-4.7,1.9-6.7,4.7-6.7
													  c2.6,0,4.2,1.7,5.3,3.9h0.6v-4.4h-0.5c-0.2,0.4-0.6,0.6-1.1,0.6c-0.8,0-2.6-0.7-4.7-0.7c-5,0-8.2,3.3-8.2,7.7
													  c0,3.9,3.2,7.2,7.8,7.2c2.6,0,4.4-1,5.1-1c0.5,0,0.8,0.3,1.1,1h0.5v-5.3L1148-45.3z" />
					  <path id="Path_24" class="st0" d="M1104.3-40.4h12.3l1.5-4.3h-0.6c-2.1,2.7-3.3,3.6-5.4,3.6h-3.5l9-13.1v-0.4h-12.2l-1.3,4h0.5
													  c1.6-2.1,3.3-3.2,5.2-3.2h3.3l-8.9,12.9L1104.3-40.4L1104.3-40.4z" />
					  <path id="Path_25" class="st0" d="M1093.4-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.9
													  c-0.1-0.4,0.2-0.7,0.5-0.7c0.1,0,0.1,0,0.2,0c1.9,0.3,3.6,1.5,4.5,3.3l0.5-0.2l-0.9-4.2h-0.6c-0.4,0.2-0.9,0.4-1.4,0.4h-9.4
													  c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.5-0.2-0.7-0.3h-0.6l-0.9,4.2l0.5,0.2c0.9-1.8,2.5-3,4.5-3.3c0.4-0.1,0.7,0.2,0.7,0.5
													  c0,0.1,0,0.1,0,0.2L1093.4-42.3L1093.4-42.3z" />
					  <path id="Path_26" class="st0" d="M1080.5-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.4
													  c0-1,0.3-1.1,1.8-1.1v-0.7h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1L1080.5-42.3L1080.5-42.3z" />
					  <path id="Path_27" class="st0" d="M1074.8-42.6l-2.3-3.6c-0.3-0.7-1-1.2-1.8-1.3v-0.1c2.3-0.1,3.7-1.3,3.7-3.1
													  c0-1.8-1.6-3.9-6.2-3.9h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1v10.4c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-5
													  c1.5,0,2,0.2,2.4,0.9l3.3,5.9h4.9v-0.7C1076-41.2,1075.6-41.3,1074.8-42.6L1074.8-42.6z M1067.5-47.9h-1.2v-5.9h0.8
													  c2.9,0,3.6,1.7,3.6,3C1070.7-48.7,1069.2-47.9,1067.5-47.9L1067.5-47.9z" />
					  <path id="Path_28" class="st0" d="M1038.4-42.3c0,1-0.3,1.1-1.8,1.1v0.7h13.1l1.1-4.1h-0.7c-1.6,2.7-3.2,3.4-6.1,3.4
													  c-1.9,0-2.1-0.3-2.1-0.9v-5.4h0.9c2.6,0,3.7,0.7,4.1,2.7h0.6V-51h-0.6c-0.6,2.1-1.5,2.9-4.1,2.9h-0.9V-53c0-0.8,0.3-0.9,1.3-0.9
													  c3.2,0,4.7,0.9,6.7,3.1h0.6l-1.4-3.9h-12.5v0.7c1.5,0,1.8,0.1,1.8,1.1V-42.3z" />
					  <path id="Path_29" class="st0" d="M1029.1-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.4
													  c0-1,0.3-1.1,1.8-1.1v-0.7h-7.1v0.7c1.5,0,1.8,0.1,1.8,1.1v4.3h-7.2v-4.3c0-1,0.3-1.1,1.8-1.1v-0.7h-7.1v0.7
													  c1.5,0,1.8,0.1,1.8,1.1v10.4c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-5.1h7.2V-42.3z" />
					  <path id="Path_30" class="st0" d="M1005.9-42.3c0,1-0.3,1.1-1.8,1.1v0.7h7.1v-0.7c-1.5,0-1.8-0.1-1.8-1.1v-10.9
													  c-0.1-0.4,0.2-0.7,0.5-0.7c0.1,0,0.1,0,0.2,0c1.9,0.3,3.6,1.5,4.5,3.3l0.5-0.2l-0.9-4.2h-0.6c-0.4,0.2-0.9,0.4-1.4,0.4h-9.4
													  c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.5-0.2-0.7-0.3h-0.6l-0.9,4.2l0.5,0.2c0.8-1.8,2.5-3,4.5-3.3c0.4-0.1,0.7,0.2,0.7,0.5
													  c0,0.1,0,0.1,0,0.2V-42.3L1005.9-42.3z" />
					  <path id="Path_31" class="st0" d="M1122.7-46.5h4.7l2-2.9h-4.7L1122.7-46.5z" />
					</g>
				  </g>
				</svg>
  
			  </a>
			</h1>
		  </div>
		</section>
		<section *ngIf="subHeaderStatus" class="section-3 subheader_menu" [ngClass]="{ 'subHeaderMenuSection3' : stickyHeader, 'stickyHeader': stickyHeader}">
		  <div class="n10_A">
			<nav class="brand-local-nav">
			  <ul>
				<li>
				  <a id="subheader_titleId" class="subheader_title" data-option="landing">
					<span *ngIf="subHeaderDataArray_A?.title" [innerHtml]="subHeaderDataArray_A?.title"></span>
				  </a>
				</li>
				<li>
				  <a data-option="map" *ngIf="subHeaderDataArray_A?.field_sub_menu" (keyup.enter)="commonPointScrollDown()" (click)="commonPointScrollDown()" tabindex="0">
					<span [innerHtml]="subHeaderDataArray_A?.field_sub_menu"></span>
				  </a>
				</li>
  
			  </ul>
			  <div *ngIf="subHeaderDataArray_A?.field_sub_menu" class="brand-local-nav__expand-toggle h-ML">
				<a (click)="commonPointScrollDown()" class="activate-more">
				  <span class="icon-arrow-left"></span>
				</a>
			  </div>
			</nav>
		  </div>
		</section>
  
	  </header>
  
  
	</div>
  
  </div>
  <app-rcyc-login #guestLoginModal></app-rcyc-login>