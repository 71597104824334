<div id="rcyc-page-editorialhub-article" class="row bodyContentOuter">

  <!-- Section 1 - Banner Section with Heading -->
  <section class="bannerSection">
      <!-- Banner Image/vedio -->
      <app-rcyc-hero-module *ngIf="apiResponseBannerStatus  && apiResponseBanner.length>0" [responseArray]="apiResponseBanner" ></app-rcyc-hero-module>
  </section>

  <!-- Section 2 - Heading Section -->
  <section class="row section-2">
    <div class="l-container-wrapper">
      <div class="l-container">
    
        <div class="row is-8-4">
          <div class="column is-8">
            <!-- Page Title -->
            <h1 *ngIf="apiResponseABCD?.title" id="mainTitleId" class="page-title"
            [title]="apiResponseABCD?.title | convertHtml" [innerHtml]="apiResponseABCD?.title"></h1>
          </div>
          <div class="column is-4  headingIs-4">
            <!-- Dummy section for proper alignment -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Section 3 - Article Section -->
  <section class="row section-3">
    <div class="l-container-wrapper">
      <div class="l-container">
        <div class="row is-8-4">
          <!-- Left section -->
          <div class="column is-8">
            <!-- Paragraph section-A -->
            <p *ngIf="apiResponseABCD?.field_para1" [innerHtml]="apiResponseABCD?.field_para1"></p>
          
            <!-- Featured Trip Section for mobile view -->
            <div class="featuredTripMobilePosition"></div>

            <!-- Article Image Block Section -->
            <div *ngIf="apiResponseABCD?.field_article_image_block" class="articleContentDisplay" [ngClass]="{'paddingBottom': !apiResponseABCD?.field_article_image_caption}">
              <div class="p04_frame changeWidth">
                <ul class="p04_slide_list">
                  <li class="p04_slide">
                    <img id="articleImg1Id" [title]="apiResponseABCD?.field_image_title_content | convertHtml" [alt]="apiResponseABCD?.field_image_alt_content | convertHtml"
                         [src]="apiResponseABCD?.field_article_image_block"
                         class="p04_img">
                  </li>
                </ul>
              </div>
              <p  class='articleContentCaption' *ngIf="apiResponseABCD?.field_article_image_caption" [innerHtml]="apiResponseABCD?.field_article_image_caption"></p>
            </div>
          
            <!-- Paragraph section-B -->
            <p *ngIf="apiResponseABCD?.field_para2" [innerHtml]="apiResponseABCD?.field_para2"></p>

            <!-- Article Video Block Section -->
            <div *ngIf="videoBlockAvailable" class="articleContentDisplay" [ngClass]="{'paddingBottom': !apiResponseABCD?.field_article_image_caption}">
              <app-rcyc-ngu-carousel *ngIf="videoBlockAvailable" [portData]='videoArray'></app-rcyc-ngu-carousel>
              <p class='articleContentCaption' *ngIf="apiResponseABCD?.field_article_video_caption" [innerHtml]="apiResponseABCD?.field_article_video_caption"></p>
            </div>

            <!-- Paragraph section-C -->
            <p *ngIf="apiResponseABCD?.field_para3" [innerHtml]="apiResponseABCD?.field_para3"></p>
            
            <!-- Article Carousel Section -->
            <div *ngIf="carouselImagesAvailable" class="articleContentDisplay" [ngClass]="{'paddingBottom': !apiResponseABCD?.field_article_image_caption}">
              <app-rcyc-ngu-carousel *ngIf="carouselImagesAvailable" [portData]='carouselImagesArray'></app-rcyc-ngu-carousel>
              <p class='articleContentCaption' *ngIf="apiResponseABCD?.field_article_carousel_caption" [innerHtml]="apiResponseABCD?.field_article_carousel_caption"></p>
            </div>
            
            <!-- Paragraph section-D -->
            <p *ngIf="apiResponseABCD?.field_para3" [innerHtml]="apiResponseABCD?.field_para4"></p>

          </div>
          <!-- Right Section -->
          <div class="column is-4 outerDiv featuredTripOriginalPosition">
            <!-- Featured Trip Section-->
            <div class="row section-4 innerDiv">
            <!-- Heading - Featured Trip -->
            <h1 *ngIf="voyageDetailsStatus" class="slider-hdr1" [innerHTML]="apiResponseABCD?.field_subheading_featured_trips"></h1>
              <!-- GRID View Starts -->
              <div *ngIf="voyageDetailsStatus">
                <div class="gridView gridView1 eachOuterMainList">
                  <div *ngFor="let item of featuredVoyageDetails; let i=index;" id="eachGridItemId_{{i+1}}"
                      class="column is-3 eachOuter eachOuter1 eachOuter-grid-{{item.voyageId}}"
                      [ngClass]="{'noMarginRight': (i+1)%4 == 0}"
                      [ngStyle]="{height: (maxEachOuterHeight)? maxEachOuterHeight+'px':''}">
          
                    <div class="eachRow-1">
                      <!-- Image section -->
                      <a class="clickable-image" id="gridViewItineraryId_img{{i+1}}" [routerLink]="item?.itineraryUrl"
                        (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)"
                        (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)">
                        <img *ngIf="item?.voyageImagePath" id="gridVoyageImgId_{{i+1}}" [src]="item?.voyageImagePath"
                          class="p04_img" />
                      </a>
                      <!-- Image caption -->
                      <div class="seeAll" *ngIf="item.embarkationCountry !==  item.disEmbarkationCountry">
                        <div class="seeAllTitleSec">
                          {{ item.embarkationCountry}} TO {{ item.disEmbarkationCountry}}
                        </div>
                      </div>
                      <div class="seeAll" *ngIf="item.embarkationCountry ===  item.disEmbarkationCountry">
                        <div class="seeAllTitleSec">
                          ROUNDTRIP {{ item.embarkationCountry}}
                        </div>
                      </div>
                    </div>
        
                    <!-- Voyage name section -->
                    <div class="row eachRow-2">
                      <div class="voyageName" [innerHTML]="item?.voyageName"
                        [ngStyle]="{height: maxEachVoyagenameHeight? maxEachVoyagenameHeight+'px':''}"></div>
                    </div>
        
                    <!-- Voyage details section -->
                    <div class="row eachRow-3" [ngStyle]="{height: maxEachDDFHeight? maxEachDDFHeight+'px':''}">
                      <div class="durationColumn splitColumn splitColumn1">
                        <div><span>DURATION</span></div>
                        <div><span class="splitRowMiddle" [innerHTML]="item.nights"></span></div>
                        <div>Nights</div>
                      </div>
                      <div class="departsColumn splitColumn splitColumn1">
                        <div><span>DEPARTS</span></div>
                        <div class="splitRowMiddle" [innerHTML]="item.voyageStartDate | date: 'MMM dd':'UTC'"></div>
                        <div [innerHTML]="item.voyageStartDate | date: 'y':'UTC'"></div>
                      </div>
                      <div class="faresFromColumn splitColumn splitColumn1">
                        <div><span>FARES FROM</span></div>
                        <div *ngIf="!item?.pcfiToggleStatus && item?.suiteAvailability && item?.suiteAvailability >= 1 &&
                                                  item?.startingPriceMap && item?.portFeeMap && item?.feeCurrency &&
                                                  item?.startingPriceMap[item?.feeCurrency]">
                          <div *ngIf="item?.startingPriceMap[item?.feeCurrency] && item?.feeCurrency!='USM'">
                            <div class="splitRowMiddle">
                              {{item?.startingPriceMap[item?.feeCurrency] | currency:item?.feeCurrency:'symbol':'1.0-0'}}*
                            </div>
                            <div>{{item?.feeCurrency}}</div>
                          </div>
                          <div *ngIf="item?.startingPriceMap[item?.feeCurrency] && item?.feeCurrency=='USM'">
                            <div class="splitRowMiddle">
                              {{item?.startingPriceMap[item?.feeCurrency] | currency:USD:'symbol':'1.0-0'}}*
                            </div>
                            <div>USD</div>
                          </div>
                        </div>
                        <div *ngIf="item?.pcfiToggleStatus || !item?.suiteAvailability || item?.suiteAvailability < 1 ||
                                                  !item?.startingPriceMap || !item?.portFeeMap ||  !item?.feeCurrency ||
                                                  !item?.startingPriceMap[item?.feeCurrency]">
                          <div class="pcfiText" [innerHTML]="PCFIToggleInfo?.field_toggle_text"></div>
                        </div>
                      </div>
                    </div>
        
                    <!-- Section Yacht name & buttons -->
                    <div class="row eachRow-4">
                      <!-- Section Yacht name -->
                      <div class="compareCheckBox yacht-name">
                        <span>{{item?.yachtName}}</span>
                      </div>
                      <!-- More info button -->
                      <div *ngIf="item?.itineraryUrl">
                        <a class="button request_more_info" id="gridViewItineraryId_{{i+1}}" [routerLink]="item?.itineraryUrl"
                          title="More Information"
                          (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)"
                          (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)">
                          MORE INFORMATION
                        </a>
                      </div>
                      <!-- RAQ Button -->
                      <div class="row">
                        <a class="button requestQuote" id="btn_grid_quote_{{i+1}}" routerLink="/request-quote/{{item.voyageId}}"
                          title="Request a Quote" (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')"
                          (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')">
                          REQUEST A QUOTE
                        </a>
                      </div>
        
                      <!-- compare section -->
                      <div class="row compareCheckBox">
                        <input name="region" (keyup.enter)="compareCheckboxClick($event, item, i+1, 'grid')"
                          (click)="compareCheckboxClick($event, item, i+1, 'list')" type="checkbox" id="gridCheckbox_{{i+1}}"
                          value="compare" class="compare_checkbox compareCheckBoxPort">
                        <span class="heading com-size">COMPARE</span>
                      </div>
        
                      <!-- Drupal controlled Language based on currency code -->
                      <div class="row extra_fee" [ngStyle]="{height: (maxExtrafeeHeight)? maxExtrafeeHeight+'px':''}">
                        <span [innerHTML]="infoCommonMsg?.[currencyFieldMap[item?.feeCurrency]]"></span>
                      </div>
                      <!-- Removing the following old harcoded language - Updating this to drupal controlled as above -->
                      <div class="row extra_fee" [ngStyle]="{height: (maxExtrafeeHeight)? maxExtrafeeHeight+'px':''}" *ngIf="false">
                        <span *ngIf="item?.feeCurrency=='USD'">
                          *Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] |
                          currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are additional. Fares are per person
                          based on
                          double occupancy.
                        </span>
                        <span *ngIf="item?.feeCurrency=='USM' ">
                          *Fares are per person based on double occupancy. Taxes, fees and port expenses of
                          {{item.portFeeMap[item.feeCurrency] | currency:'USD':'symbol':'1.0-0'}} per passenger are included.
                        </span>
                        <span
                          *ngIf="item?.portFeeMap[item?.feeCurrency] && item?.feeCurrency!='USD' && item?.feeCurrency!='USM'">
                          *Fares are per person based on double occupancy. Taxes, fees and port expenses of
                          {{item.portFeeMap[item.feeCurrency] | currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are
                          included.
                        </span>
                        <span *ngIf="!item?.portFeeMap[item?.feeCurrency]">
                          *Taxes, fees and port expenses of {{item.portFeeMap['USD'] | currency:USD:'symbol':'1.0-0'}} per
                          passenger
                          are additional. Fares are per person based on double occupancy.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Section 4 - Related Trips Section -->
  <section class="row section-4">
    <div class="l-container-wrapper">
      <div class="l-container cruiseListLContainer">
  
        <!-- GRID View Starts -->
        <div *ngIf="voyageDetailsStatus">
          <!-- Heading - Related Trips -->
          <h1 class="slider-hdr" [innerHTML]="apiResponseABCD?.field_subheading_related_trips"></h1>
          <div class="row is-12 gridView">
  
            <div class="row is-3-3-3-3 eachOuterMainList">
              <div *ngFor="let item of loadedItems?.slice(0, storyDisplayCount); let i=index;" id="eachGridItemId__{{i+1}}"
                class="column is-3 eachOuter eachOuter-grid-{{item.voyageId}}" [ngClass]="{'noMarginRight': (i+1)%4 == 0}"
                [ngStyle]="{height: (maxEachOuterHeight)? maxEachOuterHeight+'px':''}">
  
                <div class="eachRow-1">
                  <!-- Image section -->
                  <a class="clickable-image" id="gridViewItineraryId_img{{i+1}}" [routerLink]="item?.itineraryUrl"
                    (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)"
                    (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)">
                    <img *ngIf="item?.voyageImagePath" id="gridVoyageImgId_{{i+1}}" [src]="item?.voyageImagePath"
                      class="p04_img" />
                  </a>
                  <!-- Image caption -->
                  <div class="seeAll" *ngIf="item.embarkationCountry !==  item.disEmbarkationCountry">
                    <div class="seeAllTitleSec">
                      {{ item.embarkationCountry}} TO {{ item.disEmbarkationCountry}}
                    </div>
                  </div>
                  <div class="seeAll" *ngIf="item.embarkationCountry ===  item.disEmbarkationCountry">
                    <div class="seeAllTitleSec">
                      ROUNDTRIP {{ item.embarkationCountry}}
                    </div>
                  </div>
                </div>
  
                <!-- Voyage name section -->
                <div class="row eachRow-2">
                  <div class="voyageName" [innerHTML]="item?.voyageName"
                    [ngStyle]="{height: maxEachVoyagenameHeight? maxEachVoyagenameHeight+'px':''}"></div>
                </div>
  
                <!-- Voyage details section -->
                <div class="row eachRow-3" [ngStyle]="{height: maxEachDDFHeight? maxEachDDFHeight+'px':''}">
                  <div class="durationColumn splitColumn">
                    <div><span>DURATION</span></div>
                    <div><span class="splitRowMiddle" [innerHTML]="item.nights"></span></div>
                    <div>Nights</div>
                  </div>
                  <div class="departsColumn splitColumn">
                    <div><span>DEPARTS</span></div>
                    <div class="splitRowMiddle" [innerHTML]="item.voyageStartDate | date: 'MMM dd':'UTC'"></div>
                    <div [innerHTML]="item.voyageStartDate | date: 'y':'UTC'"></div>
                  </div>
                  <div class="faresFromColumn splitColumn">
                    <div><span>FARES FROM</span></div>
                    <div *ngIf="!item?.pcfiToggleStatus && item?.suiteAvailability && item?.suiteAvailability >= 1 &&
                                item?.startingPriceMap && item?.portFeeMap && item?.feeCurrency &&
                                item?.startingPriceMap[item?.feeCurrency]">
                      <div *ngIf="item?.startingPriceMap[item?.feeCurrency] && item?.feeCurrency!='USM'">
                        <div class="splitRowMiddle">
                          {{item?.startingPriceMap[item?.feeCurrency] | currency:item?.feeCurrency:'symbol':'1.0-0'}}*
                        </div>
                        <div>{{item?.feeCurrency}}</div>
                      </div>
                      <div *ngIf="item?.startingPriceMap[item?.feeCurrency] && item?.feeCurrency=='USM'">
                        <div class="splitRowMiddle">
                          {{item?.startingPriceMap[item?.feeCurrency] | currency:USD:'symbol':'1.0-0'}}*
                        </div>
                        <div>USD</div>
                      </div>
                    </div>
                    <div *ngIf="item?.pcfiToggleStatus || !item?.suiteAvailability || item?.suiteAvailability < 1 ||
                                !item?.startingPriceMap || !item?.portFeeMap ||  !item?.feeCurrency ||
                                !item?.startingPriceMap[item?.feeCurrency]">
                      <div class="pcfiText" [innerHTML]="PCFIToggleInfo?.field_toggle_text"></div>
                    </div>
                  </div>
                </div>
  
                <!-- Section Yacht name & buttons -->
                <div class="row eachRow-4">
                  <!-- Section Yacht name -->
                  <div class="compareCheckBox yacht-name">
                    <span>{{item?.yachtName}}</span>
                  </div>
                  <!-- More info button -->
                  <div *ngIf="item?.itineraryUrl">
                    <a class="button request_more_info" id="gridViewItineraryId_{{i+1}}" [routerLink]="item?.itineraryUrl"
                      title="More Information"
                      (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)"
                      (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)">
                      MORE INFORMATION
                    </a>
                  </div>
                  <!-- RAQ Button -->
                  <div class="row">
                    <a class="button requestQuote" id="btn_grid_quote_{{i+1}}"
                      routerLink="/request-quote/{{item.voyageId}}" title="Request a Quote"
                      (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')"
                      (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')">
                      REQUEST A QUOTE
                    </a>
                  </div>
  
                  <!-- compare section -->
                  <div class="row compareCheckBox">
                    <input name="region" (keyup.enter)="compareCheckboxClick($event, item, i+1, 'grid')"
                      (click)="compareCheckboxClick($event, item, i+1, 'grid')" type="checkbox" id="gridCheckbox_{{i+1}}"
                      value="compare" class="compare_checkbox compareCheckBoxPort">
                    <span class="heading com-size">COMPARE</span>
                  </div>
  
                  <!-- Drupal controlled Language based on currency code -->
                  <div class="row extra_fee" [ngStyle]="{height: (maxExtrafeeHeight)? maxExtrafeeHeight+'px':''}">
                    <span [innerHTML]="infoCommonMsg?.[currencyFieldMap[item?.feeCurrency]]"></span>
                  </div>
                  <!-- Removing the following old harcoded language - Updating this to drupal controlled as above -->
                  <div class="row extra_fee" [ngStyle]="{height: (maxExtrafeeHeight)? maxExtrafeeHeight+'px':''}" *ngIf="false">
                    <span *ngIf="item?.feeCurrency=='USD'">
                      *Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] |
                      currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are additional. Fares are per person
                      based on
                      double occupancy.
                    </span>
                    <span *ngIf="item?.feeCurrency=='USM' ">
                      *Fares are per person based on double occupancy. Taxes, fees and port expenses of
                      {{item.portFeeMap[item.feeCurrency] | currency:'USD':'symbol':'1.0-0'}} per passenger are included.
                    </span>
                    <span
                      *ngIf="item?.portFeeMap[item?.feeCurrency] && item?.feeCurrency!='USD' && item?.feeCurrency!='USM'">
                      *Fares are per person based on double occupancy. Taxes, fees and port expenses of
                      {{item.portFeeMap[item.feeCurrency] | currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are
                      included.
                    </span>
                    <span *ngIf="!item?.portFeeMap[item?.feeCurrency]">
                      *Taxes, fees and port expenses of {{item.portFeeMap['USD'] | currency:USD:'symbol':'1.0-0'}} per
                      passenger
                      are additional. Fares are per person based on double occupancy.
                    </span>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <!-- Display the "Load More" button conditionally. -->
          <div class="loadMoreContainer">
            <button class="loadMoreButton" *ngIf="storyDisplayCount < storyTotalCount" (click)="loadMore()">Load More</button>
          </div>
        </div>

      </div>
    </div>
  </section>

</div>