<!--
  Variant:
    shore_excursion
-->

<div class="bg-light-blue-2 lg:flex " *ngIf="data" [ngClass]="{
  'flex-col h-full': variant === 'grid' || variant === 'grid-small'
}">
  <div class="relative">
    <div class="aspect-[5/4]" [ngClass]="{
        'lg:w-[344px]': variant === 'shore_excursion',
      }">
      <img *ngIf="data.field_thumbnail" [src]="data.field_thumbnail" class="fit-image" alt="">
      <div *ngIf="data.highlight"
        class="absolute left-0 top-0 uppercase text-sm px-2 py-[3px] bg-white/40 backdrop-blur-xl">{{data.highlight}}
      </div>
    </div>
  </div>

  <div
    [class]="styleClasses ? 'lg:flex lg:flex-grow justify-between ' + styleClasses : 'lg:flex lg:flex-grow justify-between '">
    <div class="mr-2">
      <div class="text-sm uppercase mb-1" [ngClass]="{
          'italic mb-3': variant === 'grid-small',
        }">
        {{data.portName}}
      </div>
      <h2 class="text-[23px] leading-[26px] mt-[12px]" [ngClass]="{
        'lg:text-[23px] lg:leading-6 font-normal mb-2 xl:mb-6': variant === 'list',
        'lg:text-[32px] lg:leading-6 font-normal !mb-2': variant === 'shore_excursion',
        'lg:min-h-16 lg:text-[23px] lg:leading-6 font-normal ': variant === 'grid',
        '': variant === 'grid-small',
        }">
        {{data.tourName}}
      </h2>
      <div class="text-content flex uppercase" [ngClass]="{
        'mb-6': variant !== 'shore_excursion',
        'mb-0': variant === 'shore_excursion',
       }">    
      </div>
      <ng-content></ng-content>
      <div class="text-[16px]" *ngIf="variant === 'shore_excursion'" [innerHTML]="data.summaryDesc"></div>     
    </div>
    <div *ngIf="variant === 'shore_excursion'" class="min-w-48 flex flex-col gap-2 justify-start uppercase">
      <span class="text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="data.tourCatCd" [innerHTML]="data.tourCatCd"> </span>
      <span class="splitLasth4 text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="data?.activityLvl == 'Easy'">
        Mild Activity
      </span>
      <span class="splitLasth4 text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="data?.activityLvl == 'Medium'">
        Moderate Activity
      </span>
      <span class="splitLasth4 text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="data?.activityLvl == 'Hard'">
        Strenuous Activity
      </span>
      <span class="text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="data.durationHrs" [innerHTML]="data.durationHrs + ' hours'"> </span>
    </div>
  </div>

</div>