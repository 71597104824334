<div class="relative ">
<div class="mt-4 lg:mb-0">
  <div class="overflow-hidden"> 
      <img *ngIf="image" [src]="image" class="fit-image" alt="">
  </div>
</div>
<div class="absolute top-0">
  <div class="px-[29px] text-[26px] font-caslon leading-[26.45px] lg:leading-[44.85px] text-center mt-9 lg:mt-12 md:text-xl lg:text-[39px] title uppercase">{{data.field_tile_title}}</div>
  <div class="text-[16px] font-proxima-nova text-center subtitle">{{data.field_tile_subtitle}}</div>
</div>
<div class="uppercase absolute bottom-0 left-[24%] md:left-[35%] lg:left-[30%] bottom-[4%]">
  <a class="link-cta button-secondary !px-[32px] uppercase whitespace-nowrap !display-none lg:!flex" (click)="shoreExcursionsClick(data.field_tile_title)">
    VIEW DETAILS
  </a>
  <a class="link-cta button-secondary !px-[32px] uppercase whitespace-nowrap lg:!display-none excursion-button" (click)="shoreExcursionsClick(data.field_tile_title)">
    {{data.field_tile_cta_title}}
  </a>
</div>
</div>
<app-rcyc-shore-excursions  #shoreExcursionsModal [pageName]="pageName" [portCode]="portCode" [excursionDetail]="data"></app-rcyc-shore-excursions>
<app-rcyc-onboard-exp-modal #onboardExpModal></app-rcyc-onboard-exp-modal>
