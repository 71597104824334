import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RcycRequestCallService } from '../rcyc-request-call/rcyc-request-call.service';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import * as EmailValidator from 'email-validator';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';


@Component({
    selector: 'app-rcyc-travel-professionals-new',
    templateUrl: './rcyc-travel-professionals-new.component.html',
    styleUrls: ['./rcyc-travel-professionals-new.component.css'],
    encapsulation: ViewEncapsulation.None,
})

export class RcycTravelProfessionalsNewComponent implements OnInit {

    apiResponseA: any;
    apiResponseC: any;
    apiResponseB: any;
    formSubmitFailStatus: any;
    loadApiStatus = false;
    loginApiResponse: any;
    nameOfCurrentUser: any;
    formSubmitErrorMsg: any;
    apiResponseGError: any;
    rememberMeCB: any;
    apiResponseGErrorStatus = false;

    login_travel_professional: any;

    passwordValidationMsg: any;
    pwdValidStatus = false;
    pwdErr = false;

    emailValidationMsg: any;
    emailErr = false;
    emailValidStatus = false;
    emailVerificationError = false;
    apiURLCupdated: any;
    apiResponseCupdated: any;
    currentComponentName: string;
    


    constructor(
        private rcycRequestCallService: RcycRequestCallService,
        private rcycHomeService: RcycHomeService,
        private rcycInternalApisService: RcycInternalApisService,
        private rcycCommonService: RcycCommonService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private _SharedServiceService: SharedServiceService,
    ) {

    }

    ngOnInit() {
        this.getBannerImage();
        this.get5050Data();
        this.getCreateAccountData();
        this.checkRememberMe();
        this.travelProfessionalContent();
        this.loginFailedDrupalApi();
        this.formSubmitValidation();
        let currentSessionStatus:boolean;

        this.rcycCommonService.changeSubHeaderStatus(true);
        this.rcycCommonService.currentSessionStatus.subscribe(status => currentSessionStatus = status);
    
        this.currentComponentName = 'RcycTravelProfessionalsComponent';
        // change changeCurrentComponent
        this.rcycCommonService.changeCurrentComponent(this.currentComponentName);
    

    }


    get email() {
        return this.login_travel_professional.get('email');
      }
    
      get password() {
        return this.login_travel_professional.get('password');
      }

    getBannerImage() {
        let apiUrlA = '/api/v1/travelprofessional/header/a?_format=json';
        this.rcycRequestCallService.requestACallDrupal(apiUrlA).subscribe(
            (response: any) => {
                if (response && response.length) {
                    this.apiResponseA = response[0];

                }
            },
            err => {
                console.log('Section B Request Call Api A error');
                console.log(err);
            }
        );
    }

    getCreateAccountData() {
        const apiUrlD = '/api/v1/travelprofessional/content/b?_format=json';
        this.rcycInternalApisService.getDrupal(apiUrlD).subscribe(
            response => {

                const apiResponseBTemp: any = response;
                if (apiResponseBTemp && apiResponseBTemp.length > 0) {
                    const res = Object.keys(response).map(function (key) {
                        return response[key];
                    });
                    if (res.length) {
                        this.apiResponseB = response[0];
                    }
                }
            },
            err => {
                console.log('Login Api B err');
                console.log(err);
            }
        );
    }

    get5050Data() {
        const apiUrlC = '/api/v1/50-50/travelprofessional/c?_format=json';
        this.rcycHomeService.home(apiUrlC).subscribe(
            response => {
                const apiResponseCTemp: any = response;
                if (apiResponseCTemp && apiResponseCTemp.length > 0) {
                    const res = Object.keys(response).map(function (key) {
                        return response[key];

                    });
                    if (res.length) {
                        this.apiResponseC = res[0];
                    }
                }
            },
            err => { }

        );
    }

    // remove starting whitespace from form fields
    RemoveStartSpace(str) {
        return str.replace(/^\s+/g, '');
    }

    // remove extra whitespace from form fields
    RemoveExtraSpace(value) {
        return value.replace(/\s+/g, ' ');
    }

    loginApi() {

        this.loadApiStatus = true;
        this.formSubmitFailStatus = false;
        this.login_travel_professional.value.email = this.RemoveStartSpace(this.login_travel_professional.value.email);
        this.login_travel_professional.value.email = this.RemoveExtraSpace(this.login_travel_professional.value.email);
        this.login_travel_professional.value.password = this.RemoveStartSpace(this.login_travel_professional.value.password);
        this.login_travel_professional.value.password = this.RemoveExtraSpace(this.login_travel_professional.value.password);
        const apiUrl = '/api/travelAgentLogin';
        const param = {
            'userName': this.login_travel_professional.value.email,
            'password': this.login_travel_professional.value.password
        };
        this.rcycInternalApisService.postReservationForm(apiUrl, param).subscribe(
            response => {

                this.loadApiStatus = false;
                this.loginApiResponse = response;
                const res = this.loginApiResponse;
                if (res && res.code === 200) {
                    this.formSubmitFailStatus = false;
                    this.nameOfCurrentUser = res.data.forename;
                    const loginDatas = {
                        'TAName': this.nameOfCurrentUser,
                        'guestSurName': res.data.surname,
                        'guestName': res.data.forename,
                        'type': 'TA',
                        'jwtToken': res.data.token,
                        'dateTime': new Date().toISOString(),
                        'email': this.login_travel_professional.value.email
                    };
                    const stringifiedLoginData = JSON.stringify(loginDatas);
                    localStorage.setItem('loggedUserDetails', stringifiedLoginData);
                    this.rcycCommonService.setLoggedInStatus(true);

                    if (localStorage.getItem('rememberMeTAData')) {
                        const rememberData = {
                            'userName': this.login_travel_professional.value.email
                        };
                        localStorage.setItem('rememberMeTAData', JSON.stringify(rememberData));
                    }
                    this.rcycCommonService.setSessionExpiredStatus(false);

                    // change changeCurrentUserName
                    this.rcycCommonService.changeCurrentUser(this.nameOfCurrentUser);
                    this.router.navigate(['/travel-agent/dashboard']);
                } else {
                    if (res) {
                        switch (res.errcode) {
                            case 401:
                                if (this.apiResponseGErrorStatus) {
                                    // this.formSubmitErrorMsg = this.apiResponseGError.field_guest_incorrect_login_erro;
                                    this.formSubmitErrorMsg = this.apiResponseGError.field_ta_incorrect_login_error;
                                }
                                this.formSubmitFailStatus = true;
                                this.rcycCommonService.setSessionExpiredStatus(false);
                                break;
                            case 1002:
                                if (this.apiResponseGErrorStatus) {
                                    this.formSubmitErrorMsg = this.apiResponseGError.field_account_lock_error;
                                }
                                this.formSubmitFailStatus = true;
                                this.rcycCommonService.setSessionExpiredStatus(false);
                                break;
                            default:
                                if (this.apiResponseGErrorStatus) {
                                    this.formSubmitErrorMsg = this.apiResponseGError.field_login_submission_other_err;
                                }
                                this.formSubmitFailStatus = true;
                                this.rcycCommonService.setSessionExpiredStatus(false);
                                break;
                        }
                    } else {
                        if (this.apiResponseGErrorStatus) {
                            this.formSubmitErrorMsg = this.apiResponseGError.field_login_submission_other_err;
                        }
                        this.formSubmitFailStatus = true;
                        this.rcycCommonService.setSessionExpiredStatus(false);
                    }

                }

            },
            err => {
                console.log('err Travel Agent Login Service');
                console.log(err);
                this.formSubmitFailStatus = true;
            }
        );
    }
    // Function to get Login Failed Message form Drupal

    loginFailedDrupalApi() {
        const apiUrl = '/api/v1/signin/header/b?_format=json';
        this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
            res => {
                const temp: any = res;
                if (temp && temp[0]) {
                    this.formSubmitErrorMsg = temp[0].field_sub_header;
                }
            },
            err => {
            }
        );
    }

    checkPassword(val, position) {
        let err;
        let errStatus;
        let errMsg;
        let validItem;
        switch (position) {
            case 0:
                validItem = this.login_travel_professional.controls.password.valid;
                break;
        }
        if (!val) {
            errMsg = this.apiResponseGError.field_password_required;
            err = true;
            errStatus = false;
        } else if (!val.trim().length || !validItem) {
            errMsg = this.apiResponseGError.field_password_invalid_1;
            err = true;
            errStatus = false;
        } else {
            err = false;
            errStatus = true;
        }
        switch (position) {
            case 0:
                this.pwdErr = err;
                this.pwdValidStatus = errStatus;
                this.passwordValidationMsg = errMsg;
                break;
        }
    }

    /* =====================================================
     Function to validate form
    ======================================================== */
    formSubmitValidation() {

        this.login_travel_professional = this.formBuilder.group({
          email: ['', Validators.required],
          password: ['', [Validators.required]],
          rememberMe: ['']
        });
  
        // emailAddress
        this.login_travel_professional.get('email').valueChanges.subscribe((val) => {
            let emailDomainCharacters;
            if (val) {
              emailDomainCharacters = val.slice(val.lastIndexOf('.') + 1);
            }
            if (!this.login_travel_professional.controls.email.valid) {
  
                if (this.apiResponseGErrorStatus) {
                  this.emailValidationMsg = this.apiResponseGError.field_email_required;
                  this.emailErr = true;
                  this.emailValidStatus = false;
                }
  
            } else if (!EmailValidator.validate(val)) {
              if (this.apiResponseGErrorStatus) {
                this.emailValidationMsg = this.apiResponseGError.field_email_invalid;
                this.emailErr = true;
                this.emailValidStatus = false;
              }
            } else {
              const guestEmail = val.trim();
              this.emailErr = false;
              this.emailValidStatus = true;
            }
        });
  
  
          // password
      this.login_travel_professional.get('password').valueChanges.subscribe((val) => {
        this.checkPassword(val, 0);
      });
      }
  

      // Function to get Credential for remember me

  checkRememberMe() {
    const rememberMeTAData = localStorage.getItem('rememberMeTAData');
    if (rememberMeTAData) {
      this.rememberMeCB = true;
      const parsedRememberMeTAData = JSON.parse(rememberMeTAData);

      if (parsedRememberMeTAData.userName) {
        this.login_travel_professional.patchValue({ 'email': parsedRememberMeTAData.userName });
      }
    }

  }


   // Function to Store and remove data when remember me

   rememberMe(event) {

    this.rememberMeCB = !this.rememberMeCB;
    if (this.rememberMeCB) {
      const rememberMeTAData = {
        'userName': this.login_travel_professional.value.email,
        // 'password': this.login_travel_professional.value.password
      };
      const stringifiedLoginData = JSON.stringify(rememberMeTAData);
      localStorage.setItem('rememberMeTAData', stringifiedLoginData);

    } else {
      const rememberMeTAData = localStorage.getItem('rememberMeTAData');
      if (rememberMeTAData) {
        localStorage.removeItem('rememberMeTAData');
      }
    }

  }

  submitLogin() {
    if (!this.login_travel_professional.invalid) {
      this.loginApi();
    }

  }

  navigateToAbout(){


    this.router.navigate(['/travel-agent/registration']);
  }

   /* ================================================================================================================
     Function to display Full Apis in travelProfessional page
  ============================================================================================================ */
  travelProfessionalContent() {

    const apiURLCupdated= '/api/v1/travelprofessional/content/b?_format=json';
    this.rcycInternalApisService.getDrupal(apiURLCupdated).subscribe(
      response => {

        const apiResponseCupdatedTemp :any= response;
        if (apiResponseCupdatedTemp && apiResponseCupdatedTemp.length>0) {

          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.apiResponseCupdated = response[0];
          }
        }
      },
      err => {
        console.log('Login page  C Api err');
        console.log(err);
      }
    );
      this.getErrorCommonMsgs();

  }

   /****************** Section Global error show function ********************/

   getErrorCommonMsgs() {
    this._SharedServiceService.getErrorCommonMsg().subscribe(res=>{
      this.apiResponseGError=res;
      if(this.apiResponseGError){
        this.apiResponseGErrorStatus=true
      }
    });
  }


}
