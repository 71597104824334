<div [className]="initialMenu">
  <!-- Toaster messages! -->
  <app-rcyc-toaster-messages></app-rcyc-toaster-messages>
    <!-- Header! -->
    <app-rcyc-header-new *ngIf="headerVisible" (toggleMenu)="toggleMenu($event)" [countryNo]="country_no"></app-rcyc-header-new>

    <!-- Content -->
    <div class="row appComponentBody">
      <router-outlet (activate)="onActivate($event, outlet)" (deactivate)="onDeactivate()" #outlet></router-outlet>
    </div>
    <!-- Footer -->

      <app-footer *ngIf="footerVisible" (toggleFooterLanguage)="toggleFooterLanguage($event)" [countryNo]="country_no"></app-footer>
      <!-- Uncomment to Add gdpr Cookie Consent -->
      <!-- <app-rcyc-gdpr-modal [gdprRegion]="gdprRegion"></app-rcyc-gdpr-modal> -->
</div>
