<swal #diningSwal (beforeOpen)="beforeOpen($event)" (open)="onOpen($event)" [showConfirmButton]="false"
  [showCancelButton]="false" [showCloseButton]="true" customClass="diningSwtOuter" [allowOutsideClick]="false"
  [animation]="false" (close)="onClose($event)" [reverseButtons]="true" [width]="'75%'">
  <ng-container *swalPartial>
    <div id="rcyc-experience-modal">
      <div class="p-6 lg:p-12">
        <span class="text-[14px] uppercase" [innerHTML]="onboardExpData?.eyebrow"></span>
        <div class="flex lg:flex-row flex-col gap-3 lg:mb-[64px] mb-[40px] mt-[24px]">
          <div class="lg:w-1/2 flex flex-col">
            <h2 class="text-[29px] lg:text-[49px] leading-[3rem] lg:leading-normal" [innerHTML]="onboardExpData?.title">
            </h2>
          </div>
          <div class="lg:w-1/2 flex flex-col">
            <div class="text-content text-base" [innerHTML]="onboardExpData?.body"></div>
          </div>
        </div>
        <app-rcyc-slider *ngIf="onboardExpData?.imageSliderData" variant="barSmall" pageName="voyage" id="exp-modal-content-slider" [data]="{
            items: onboardExpData?.imageSliderData
          }"></app-rcyc-slider>
      </div>
    </div>
  </ng-container>
</swal>