/** *
 *  Custom function to create custom phone number masks for phone numbers around the world of different format
 */

export function getMaskedCode (item) {

  let index = 0;
  let defaultCode : any;
  let defaultForm :any;
  let mask = [];

  defaultForm = item.defaultFormat;
  defaultCode = item.code;
  for (index = 0; index < defaultForm.length; index++){

    if(defaultForm[index] == 'x'){
      mask.push(/\d/);
    }
    if(defaultForm[index] == "-"){
      mask.push('-');
    }
    if(defaultForm[index] == " "){
      mask.push(' ');
    }
  }

  mask.push(/\d/,/\d/,/\d/);
  if(defaultCode == 'US' || defaultCode =='CA'){
    mask.splice(0,0,'(');
    mask.splice(4,0,')');
  }

  return mask;

}
