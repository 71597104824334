import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycVoyagesService } from '../../rcyc-voyages/rcyc-voyages.service';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { DomSanitizer } from '@angular/platform-browser';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

const { detect } = require('detect-browser');
const browser: any = detect();

@Component({
  selector: 'app-rcyc-suites-modal',
  templateUrl: './rcyc-suites-modal.component.html',
  styleUrls: ['./rcyc-suites-modal.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class RcycSuitesModalComponent implements OnInit {
  @ViewChild('suiteSwal', { static: true }) suiteSwal: SwalComponent;

  isMobile: any;
  suitemoreInfo: any;
  suitemoreInfo_slider: any;
  selectedSuiteId: any;
  suitetitle: any;
  @Input() suiteData: any;
  @Input() isPricing: boolean;
  @Input() isHeading: boolean;
  // @Input() id: any;
  @Input() variant: any;
  yachtSuite: boolean;
  @ViewChild('pageNguCarousel') pageNguCarousel: NguCarousel<any>;
  @ViewChild('nguPageCarouselControls') nguPageCarouselControls: any;
  slideConfig: any;
  imageScrollPosition: any;
  page: number;
  nrOfPages: number;
  pageNumber: number;
  opened: any;
  suiteSize: any;
  terraceSize: any;
  public pageCarousel: any;
  currentSlide = 0;
  sliderIndex = 0;
  nguTotalPage: any;
  nguPage: any;
  openedTabs: number[] = [];
  slideValue: number = 0;
  calltext: any;
  isTab: boolean;
  headerContent: any;
  yacht: string;
  suiteCode: string;
  video: any;
  eyebrow: any;
  isVideo: boolean;
  isSuiteInfoVideo: boolean = false;
  isSuiteInfoFloorplan: boolean = false;
  isSuiteInfoPhoto: boolean = false;
  isSuiteInfoView: boolean;
  buttons: any;
  buttonsId: any[];
  view: any;

  constructor(
    private rcycCommonService: RcycCommonService,
    private rcycVoyagesService: RcycVoyagesService,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.pageCarousel = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 500,
      interval: 0,
      point: {
        visible: false
      },
      // load: 2,
      touch: true,
      loop: true,
      // custom: 'banner'
    };

    this.screenSize();
  }

  @HostListener('window:resize', ['$event'])
  screenSize() {
    this.isMobile = window.innerWidth <= 769;
    this.isTab = window.innerWidth <= 1024;
  }
  // method to call while opening the modal
  onOpen(event) {
  }
  // method to open modal
  modalOpen(suiteData: any) {
    let id;
    this.yacht = suiteData.yacht;
    this.suiteCode = suiteData.suite.field_suite_code;
    this.suitetitle = suiteData.suite.title
    this.eyebrow = suiteData.suite.field_com_eyebrow;
    this.rcycVoyagesService.getButtons().subscribe((response: any) => {
      if(response){
        this.buttons = response.sort((a,b)=>a.field_sort_order-b.field_sort_order);
      }
    })
    this.getSuitesDetails(this.suiteCode)
    this.suiteSwal.show();
  }
  onClose(event) {
  }
  // method to call just before opening the modal
  beforeOpen(event) {
    event.modalElement.parentElement.setAttribute('id', 'onboardSuiteSwalId');
  }
  requestAQuoteClick() {
    this.rcycCommonService.setSessionData('requestAQuoteClick', true);
  }

  getSuitesDetails(item) {
    // cruiseCode
    // currency
    this.suitemoreInfo = false;
    this.isSuiteInfoPhoto = false;
    this.isSuiteInfoVideo =false;
    this.isSuiteInfoFloorplan = false;
    this.isSuiteInfoView = false;
    this.suitemoreInfo_slider = '';
    this.suiteSize = '';
    this.terraceSize = ''
    this.nguPage = '';
    this.nguTotalPage = '';
    this.currentSlide = 0;
    this.rcycVoyagesService.DrupalSuitesSlider_API(this.yacht, item).subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          this.suitemoreInfo_slider = response;
          this.suitemoreInfo_slider.sort((a, b) => a.field_sort_order - b.field_sort_order);
          if (this.suitemoreInfo_slider[0]?.field_video && this.suitemoreInfo_slider[0]?.field_video.length) {
            this.getSafeVideoUrl(this.suitemoreInfo_slider[0]?.field_video, false);
          }
          let slidesToShow = 1;
          this.nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
          this.nguTotalPage = Math.ceil(this.suitemoreInfo_slider.length / slidesToShow);
          this.setCrousalFirstSlide();
        }
      }, err => {
        console.error(err);
      });

    this.rcycVoyagesService.DrupalSuitesDetails_API(this.yacht, item).subscribe(
      (response: any) => {
        if (response) {
          this.suitemoreInfo = true;
          this.suitemoreInfo = response;
          this.suiteSize = this.suitemoreInfo[2].field_suite_size;
          this.terraceSize = this.suitemoreInfo[2].field_terrace_size;
          this.suitemoreInfo.sort((a, b) => a.field_sort_order - b.field_sort_order)
        }
      }, err => {
        console.error(err);
      });
    this.selectedSuiteId = 0;
  }

  setCrousalFirstSlide() {
    this.buttonsId = []
    this.pageNguCarousel?.moveTo(0);
    this.suitemoreInfo_slider.forEach((element,i) => {
      if(i==0){
        if (element.title.toLowerCase().includes('video')) {
          this.imageScrollPosition = 'tour'
          this.buttonsId.push({id:'tour', order:1})
        }else if(element.title.toLowerCase().includes('floorplan')){
          this.imageScrollPosition = 'map'
          this.buttonsId.push({id:'map', order:1})
        }else if(element.title.toLowerCase().includes('photo')){
          this.imageScrollPosition = 'photo'
          this.buttonsId.push({id:'photo', order:1})
        }else if(element.title.toLowerCase().includes('360 View')){
          this.getSafeVideoUrl(element?.field_video, true);
          this.imageScrollPosition = 'view'
          this.buttonsId.push({id:'view', order:1})
        }
      }
      if (element.title.toLowerCase().includes('video')) {
        this.isSuiteInfoVideo = true;
      }
      if (element.title.toLowerCase().includes('floorplan')) {
        this.isSuiteInfoFloorplan = true;
        if(this.buttonsId.some((b)=>b.order === 1 && b.id !== 'map')){
          this.buttonsId.push({id:'map', order:2})
        }
      }
      if (element.title.toLowerCase().includes('photo')) {
        this.isSuiteInfoPhoto = true;
        if(this.buttonsId.some((b)=>b.order === 1 && b.id !== 'photo')){
          if(this.buttonsId.some((b)=>b.order === 2 && b.id !== 'photo')){
            this.buttonsId.push({id:'photo', order:3})
          }else{
            this.buttonsId.push({id:'photo', order:2})
          }
        }
      }
      if(element.title.toLowerCase().includes('360 view')){
        this.isSuiteInfoView = true;
        this.getSafeVideoUrl(element?.field_video, true);
        if(this.buttonsId.some((b)=>b.order === 1 && b.id !== 'view')){
          if(this.buttonsId.some((b)=>b.order === 2 && b.id !== 'view')){
            if(this.buttonsId.some((b)=>b.order === 3 && b.id !== 'view')){
              this.buttonsId.push({id:'view', order:4})
            }else{
              this.buttonsId.push({id:'view', order:3})
            }
          }else{
            this.buttonsId.push({id:'view', order:2})
          }
        }
      }
    });
  }

  getSafeVideoUrl(url, is360: boolean) {
    let safeUrl = url.split('?')[0];
    if(is360){
      this.view = this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl + '?autoplay=0&autopause=0&loop=1&texttrack=null&muted=1&controls=1')
    }else{
      this.video = this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl + '?autoplay=0&autopause=0&loop=1&texttrack=null&muted=1&controls=1')
    }
  }

  setImageMode(position) {
    const buttonId: any = this.buttonsId?.filter(item=>item?.id=== position);
    if (position == 'tour') {
      this.pageNguCarousel.moveTo(buttonId[0]?.order-1);
      this.imageScrollPosition = 'tour'
    } else if (position == 'map') {
      this.pageNguCarousel.moveTo(buttonId[0]?.order-1);
      this.imageScrollPosition = 'map'
    } else if (position == 'photo') {
      this.pageNguCarousel.moveTo(buttonId[0]?.order-1);
      this.imageScrollPosition = 'photo'
    }
    else if (position == 'view') {
      this.pageNguCarousel.moveTo(buttonId[0]?.order-1);
      this.imageScrollPosition = 'view'
    }
  }

  toogleTab(id: number) {
    const index = this.openedTabs.indexOf(id);
    if (index === -1) {
      this.openedTabs.push(id);
    } else {
      this.openedTabs.splice(index, 1);
    }
  }

  isOpened(id: number): boolean {
    return this.openedTabs.includes(id);
  }

  onmoveFn(event: NguCarouselStore) {
    this.currentSlide = event.currentSlide;
    let slidesToShow = 1;
    this.nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
    this.nguTotalPage = Math.ceil(this.suitemoreInfo_slider.length / slidesToShow);
    const buttonText = this.buttonsId.filter(item=> item.order===this.nguPage)
    this.imageScrollPosition = buttonText[0]?.id
  }

  prevSlide() {
    const prevIndex = this.pageNguCarousel.currentSlide - 1;
    if (prevIndex < 0) {
      this.pageNguCarousel.moveTo(this.suitemoreInfo_slider.length - 1);
    } else {
      this.pageNguCarousel.moveTo(prevIndex);
    }
  }

  nextSlide() {
    const nextIndex = this.pageNguCarousel.currentSlide + 1;
    if (nextIndex >= this.suitemoreInfo_slider.length) {
      this.pageNguCarousel.moveTo(0);
    } else {
      this.pageNguCarousel.moveTo(nextIndex)
    }
  }
}