import { Injectable } from '@angular/core';
import { SalesForceFormData } from './salesforce-form-data.interface';
import { AppConfig } from '../../rcyc-config/config';

@Injectable({
  providedIn: 'root'
})
export class RcycHiddenSalesforceFormService {
  SALESFORCE_WEB_TO_LEAD_API_URL = AppConfig.SALESFORCE_WEB_TO_LEAD_API_URL;
  SALESFORCE_WEB_TO_CASE_API_URL = AppConfig.sALESFORCE_WEB_TO_CASE_API_URL;
  SALESFORCE_LEADGENFORM_API_OID = AppConfig.SALESFORCE_LEADGENFORM_API_OID;
  SALESFORCE_LEADGENFORM_RET_URL = AppConfig.SALESFORCE_LEADGENFORM_RET_URL;

  constructor() { }

  /**
   * Calling function to generate Web to lead Salesforce Hidden form
   * @param formData Data from LeadGenForms
   * @param queryParam Query param for the return Url
   */
  generateFormW2L(formData: Partial<SalesForceFormData>, queryParam: string, isAsiaSpecificBrochure?: boolean): void {
    let modifiedRetURL = this.SALESFORCE_LEADGENFORM_RET_URL;
    if (formData.Lead_Type__c === "Request eBrochure") {
      modifiedRetURL = formData.Preferred_Voyage_Region__c === "Asia" && isAsiaSpecificBrochure
        ? this.SALESFORCE_LEADGENFORM_RET_URL.replace("complete","asia-pacific-brochure")
        : this.SALESFORCE_LEADGENFORM_RET_URL.replace("complete","ebrochure");
    }
    const finalURL = this.generateFinalURL(modifiedRetURL, queryParam);
    this.generateForm('SFWeb2Lead', this.SALESFORCE_WEB_TO_LEAD_API_URL, 'oid', finalURL, formData);
  }

  /**
   * Calling function to generate Web to case Salesforce Hidden form
   * @param formData Data from LeadGenForms
   * @param queryParam Query param for the return Url
   */
  generateFormW2C(formData: Partial<SalesForceFormData>, queryParam: string): void {
    const finalURL = this.generateFinalURL(this.SALESFORCE_LEADGENFORM_RET_URL, queryParam);
    this.generateForm('SFWeb2Case', this.SALESFORCE_WEB_TO_CASE_API_URL, 'orgid', finalURL, formData);
  }

  /**
   * Generate the final URL with query parameters
   * @param baseURL Base URL to which query parameters will be appended
   * @param queryParam Value of the query parameter
   * @returns Final URL with query parameters
   */
  generateFinalURL(baseURL: string, queryParam: string): string {  
    const searchParams = new URLSearchParams();
    searchParams.set('success', queryParam);
    const finalRetURL = `${baseURL}?${searchParams.toString()}`;
    return finalRetURL;
  }

  /**
   * Function to generate Salesforce Hidden form using formData from respective LeadGenForm
   * @param formId FormId based on the type of Saleforce - Lead/Case
   * @param actionUrl ActionUrl based on the type of Saleforce - Lead/Case
   * @param organisationId OrgId based on the type of Saleforce - Lead/Case
   * @param formData Data from Respective LeadGenForms
   */
  private generateForm(formId: string, actionUrl: string, organisationId: string, retURL: string, formData: Partial<SalesForceFormData>): void {
    const form = document.createElement('form');
    form.setAttribute('id', formId);
    form.setAttribute('action', actionUrl);
    form.setAttribute('method', 'POST');
    form.setAttribute('style', 'display: none;');

    // Adding the orgid/oid hidden input based on environment
    const orgIdInput = document.createElement('input');
    orgIdInput.setAttribute('type', 'hidden');
    orgIdInput.setAttribute('name', organisationId);
    orgIdInput.setAttribute('id', organisationId);
    orgIdInput.setAttribute('value', this.SALESFORCE_LEADGENFORM_API_OID);
    form.appendChild(orgIdInput);

    // Adding the retURL hidden input based on environment
    const retURLInput = document.createElement('input');
    retURLInput.setAttribute('type', 'hidden');
    retURLInput.setAttribute('name', 'retURL');
    retURLInput.setAttribute('id', 'retURL');
    retURLInput.setAttribute('value', retURL);
    form.appendChild(retURLInput);

    for (const [key, value] of Object.entries(formData)) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.setAttribute('value', value?.toString() || ''); // Handle null or undefined values
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  }
}
