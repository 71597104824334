import { Component, Input, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { Router } from '@angular/router';
import { RcycNewFindACruiseService } from '../../rcyc-new-find-a-cruise/rcyc-new-find-a-cruise.service';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';

@Component({
  selector: 'app-rcyc-slider',
  templateUrl: './rcyc-slider.component.html',
  styleUrls: ['./rcyc-slider.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class RcycSliderComponent implements OnInit {
  @Input() id: any;
  @Input() data: any;
  @Input() variant: any;
  @Input() additionalCostInfo: any;
  @Input() pageName: any;
  @Input() isMobile: boolean;
  @Input() currencyCode: any;
  showSliderControl: boolean = null;
  @Output() openModal = new EventEmitter<any>();

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  carouselId: string
  sliderConfig: any;
  nrOfPages: number = null;
  page: number = null;
  staticDrupalTexts: any;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private rcycFindACruiseService: RcycNewFindACruiseService,
    private rcycCommonService: RcycCommonService,
    private router: Router
  ) {
    this.getSliderPosition({ slick: { currentSlide: 0 } });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
    if (!(this.page && this.nrOfPages)) {
      this.getSliderPosition({ slick: { currentSlide: 0 } });
    }
  }

  clearCompareValue(url: string) {
    if (this.pageName === 'destinations') {
      this.router.navigate(['/' + url]);
    }
  }

  getSlideData(item, index) {
    return {
      id: index,
      image: {
        src: item.field_image,
        title: item.field_article_title,
        alt: item.field_article_title,
      },
      eyebrow: item.field_tile_eyebrow,
      title: item.field_tile_title,
      text: item.field_tile_details,
      cta: {
        title: item.field_tile_cta_title,
        url: item.field_tile_cta_url,
      }
    }
  }

  getEditorialData(item, index) {
    return {
      ...item,
      heading: item.heading ? item.heading : `${index < 9 ? '0' : ''}${index + 1}`,
      image: {
        src: item.image?.src ? item.image?.src : item.field_image,
        title: item.field_article_title,
        alt: item.field_article_title,
      },
      eyebrow: item.field_tab_section,
      title: item.field_article_title,
      url: item.field_url_key
    }
  }

  experienceData(item, index) {
    if (item.field_deck_content_category) {
      return {
        ...item,
        image: {
          src: item.image?.src ? item.image?.src : item.field_image,
          title: item.field_image_title_content,
          alt: item.field_image_alt_content,
        },
        eyebrow: item.field_com_eyebrow,
        title: item.title,
        url: item.field_url_key
      }
    } else {
      return {
        ...item,
        heading: null,
        image: {
          src: item.image?.src ? item.image?.src : item.field_image,
          title: item.field_article_title,
          alt: item.field_article_title,
        },
        eyebrow: item.field_tab_section,
        title: item.field_article_title,
        url: item.field_url_key
      }
    }
  }

  hubData(item, index) {
    return {
      ...item,
      heading: null,
      image: {
        src: item.image?.src ? item.image?.src : item.field_image,
        title: item.title,
        alt: item.title,
      },
      eyebrow: item.field_tab_section,
      title: item.title,
      url: item.field_url_key,
      description: item.field_summery
    }
  }

  ngOnInit(): void {
    this.nrOfPages = null;
    setTimeout(() => {
      this.getSliderPosition({ slick: { currentSlide: 0 } });
    }, 1000);

    this.carouselId = 'carousel-' + this.id + '-' + this.variant;

    const bar = {
      dots: true,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      focusOnSelect: true,
      mobileFirst: true,
      infinite: true,
      speed: 1000,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: true,
            variableWidth: true,
            infinite: false,
          }
        }
      ]
    }

    const barSmall = {
      dots: true,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      focusOnSelect: true,
      mobileFirst: true,
      infinite: true,
      speed: 1000,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: true,
            variableWidth: true,
            infinite: true,
          }
        }
      ]
    }

    const experience = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      adaptiveHeight: true,
      respondTo: 'slider',
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 4,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          variableWidth: true,
          settings: {
            slidesToScroll: 4,
            slidesToShow: 4,
            infinite: false
          }
        },
        {
          breakpoint: 600,
          variableWidth: true,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 340,
          variableWidth: true,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    }

    const editorial = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      slidesToScroll: 2,
      slidesToShow: 2,
      infinite: true,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          variableWidth: true,
          settings: {
            infinite: false,
            slidesToScroll: 4,
            slidesToShow: 4,
          }
        }
      ]
    }

    const full_image = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            infinite: true,
            slidesToScroll: 3,
            slidesToShow: 3
          }
        }
      ]
    }

    const hub = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      adaptiveHeight: true,
      respondTo: 'slider',
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          variableWidth: true,
          settings: {
            infinite: true,
            slidesToScroll: 4,
            slidesToShow: 4,
          }
        }
      ]
    }

    const cardSlider = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          variableWidth: true,
          settings: {
            infinite: true,
            slidesToScroll: 2,
            slidesToShow: 2,
          }
        }
      ]
    }

    // Map by variant
    this.sliderConfig = {
      barSmall: barSmall,
      bar: bar,
      thumbnail: bar,
      featured: bar,
      editorial: editorial,
      experience: experience,
      full_image: full_image,
      shore_excursion: bar,
      hub: hub,
      cardSlider: cardSlider
    }

    this.showSliderControl = this.nrOfPages > 1;

    if (this.variant === 'featured') {
      this.getStaticDrupalData();
    }
  }

  getSliderPosition(e) {
    // Workaround for the case with multiple slides per 'page'
    let slidesToShow = 1;
    switch (this.variant) {
      case 'editorial':
        if (window.innerWidth < 769) {
          slidesToShow = 2;
        } else {
          slidesToShow = 4;
        }
        break;
      case 'experience':
        if (window.innerWidth < 769) {
          slidesToShow = 1;
        } else {
          slidesToShow = 4;
        }
        break;
      case 'full_image':
        if (window.innerWidth < 1025) {
          slidesToShow = 1;
        } else {
          slidesToShow = 3
        }
        break;
      case 'cardSlider':
        if (window.innerWidth < 1025) {
          slidesToShow = 1;
        } else {
          slidesToShow = 2
        }
        break;
      case 'hub':
        if (window.innerWidth < 1025) {
          slidesToShow = 1;
        } else {
          slidesToShow = 4;
        }
        break;
    }

    this.page = Math.ceil((e?.slick?.currentSlide ? e?.slick?.currentSlide : 0) / slidesToShow + 1)
    this.nrOfPages = Math.ceil(this?.data?.items?.length / slidesToShow);
    this.showSliderControl = this.nrOfPages > 1;
  }

  navigateTo(page: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([page])
    });
  }

  onSlideClick(item: any) {
    if (this.data?.topContent?.isModal) {
      this.openModal.emit(item);
    } else {
      this.navigateTo(item.field_url_key);
    }
  }

  getStaticDrupalData() {
    const Api_Url = '/api/v1/dynamic-title-eyebrow/find-a-cruise-voyage-tile?_format=json';
    this.rcycFindACruiseService.getCruiseImageDrupal(Api_Url).subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          this.staticDrupalTexts = response.sort(this.rcycCommonService.orderByProp('field_sort_order'));
        }
      }, (err) => {
        console.error(err);
      });
  }
}
