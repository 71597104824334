<div *ngIf="isRequestCallStatus" id="rcyc-request-call" class="bodyContentOuter">
	<!-- <app-rcyc-error #errorModal></app-rcyc-error> -->
	<!-- Section - 1 -->
	<section class="content_section">
		<div class="l-container-wrapper">
			<div class="l-container intro-header leadinto">
				<div class="l-container row">

					<div *ngIf="apiResponseB" class="body-copy">
						<div class="headline-block c01_D intro-header leadinto">
							<!-- <h4 *ngIf="apiResponseB?.field_sub_header" [title]="apiresponseB?.field_sub_header | convertHtml" [innerHtml]="apiResponseB?.field_sub_header"></h4> -->
							<h1 *ngIf="apiResponseB?.title" [title]="apiresponseB?.title | convertHtml" class="headline"
								[innerHtml]="apiResponseB?.title"></h1>
						</div>
						<p *ngIf="apiResponseB?.field_summery" [innerHtml]="apiResponseB?.field_summery"></p>
					</div>
					<div>
						<p id="requiredField"><span>*</span> Indicates required field</p>
					</div>
					<div class="row is-9-3">
						<!-- left side -->
						<!--  <div class="column is-3">
							<div class="headline-block">
								<h2 class="headline lead_form_left_head">VOYAGES</h2>
							</div>

							<div class="feature-block flex-item">
								<img class="feature-block__image" src="assets\components\images\case_1.png">
								<h3 class="media-object__headline media-object__headline--large">LOREM IPSUM DOLER TREMAN</h3>
								<p class="feature-block__body">Lorem ipsum dolor sit amet.</p>
							</div>

							<div class="feature-block flex-item">
								<img class="feature-block__image" src="assets\components\images\case_1.png">
								<h3 class="media-object__headline media-object__headline--large">LOREM IPSUM DOLER TREMAN</h3>
								<p class="feature-block__body">Lorem ipsum dolor sit amet.</p>
							</div>
						</div> -->

						<!-- right side -->
						<div class="column is-9 lead_form_right">
							<div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
								<span class="error">{{sessionExpiredMessages}}</span>
							</div>
							<div id="formSubmitErrorId" *ngIf="requestCallFormStatus">
								<span class="error">{{sessionExpiredMessages}}</span>						
							</div>
							<form id="requestCallFormId" name="requestCallForm" [formGroup]="lead_gen_form" novalidate
								autocomplete="false">
								<fieldset>
									<div class="basic-form_group lead_form_field formFirstRow">
										<!-- name_sec -->
										<div class="row is-6-6">
											<div class="column is-6 form_fields rcyc-hide-honey">
												<label
													class="basic-form_label form_label basic-form_label-required">Third
													Name</label>
												<input id="thirdNameId" class="basic-form_input basic-form_input-half"
													type="text" name="honey"  placeholder="Third Name" autocomplete="off"
													maxlength="30">
											</div>
											<div class="column is-6 form_fields">
												<label
													class="basic-form_label form_label basic-form_label-required">First
													Name</label>
												<input id="firstNameId" class="basic-form_input basic-form_input-half"
													type="text" formControlName="firstname" placeholder="First Name"
													maxlength="30">
												<!-- <span *ngIf="!lead_gen_form.controls.firstname.valid && !fnameWhitespaceError && lead_gen_form.controls.firstname.touched" class="error">This field is required</span> -->
												<p class="errorptag"
													*ngIf="lead_gen_form.controls.firstname.invalid && (lead_gen_form.controls.firstname.dirty || lead_gen_form.controls.firstname.touched)">
													<span *ngIf="lead_gen_form.controls.firstname.errors.required"
														class="emailError error">This field is required</span>
													<span
														*ngIf="!lead_gen_form.controls.firstname.errors.required && lead_gen_form.controls.firstname.errors.pattern"
														class="phoneNoError error"> Please check the name format </span>
												</p>
											</div>
											<div class="column is-6 form_fields">
												<label
													class="basic-form_label form_label basic-form_label-required">Last
													Name</label>
												<input id="lastNameId" class="basic-form_input basic-form_input-half"
													type="text" formControlName="lastname" placeholder="Last Name"
													maxlength="60">
												<!-- <span *ngIf="!lead_gen_form.controls.lastname.valid && !lnameWhitespaceError && lead_gen_form.controls.lastname.touched" class="error">This field is required</span> -->
												<!-- <span *ngIf="lnameWhitespaceError" class="error" [innerHtml]="lnameValidationMsg"></span> -->
												<p class="errorptag"
													*ngIf="lead_gen_form.controls.lastname.invalid && (lead_gen_form.controls.lastname.dirty || lead_gen_form.controls.lastname.touched)">
													<span *ngIf="lead_gen_form.controls.lastname.errors.required"
														class="emailError error">This field is required</span>
													<span
														*ngIf="!lead_gen_form.controls.lastname.errors.required && lead_gen_form.controls.lastname.errors.pattern"
														class="phoneNoError error">Please check the name format </span>
												</p>
											</div>
										</div>
										<!-- name_sec -->
									</div>

									<div class="basic-form_group lead_form_field">
										<div class="row is-6-6">
											<div class="column is-6 form_fields">
												<label
													class="basic-form_label form_label basic-form_label-required">Country/Region
													of
													Residence</label>
												<select id="selectCountryId" class="basic-form_input basic-form_select"
													formControlName="country">
													<option id="selectCountryId_0" value="">Select Country/Region of
														Residence</option>
													<option *ngFor="let county of countryListArray;let i=index"
														id="countryOptionId_{{i+1}}" value="{{county.code}}">
														{{county.name}}</option>
												</select>
												<span
													*ngIf="lead_gen_form.controls.country.invalid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) && lead_gen_form.controls.country.errors.required"
													class="error">This field is required</span>
											</div>

											<div class="column is-6 form_fields">
												<label class="basic-form_label form_label" [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
													ZIP/Postal Code (Required for USA/Canada)</label>
												<input id="zipCodeId" class="basic-form_input basic-form_input-half" type="text"
													formControlName="zipcode" placeholder="ZIP/Postal Code" [maxlength]="zipcodeMaxLength" (input)="checkZipcodeFormat($event)">
												<p class="errorptag"
													*ngIf="lead_gen_form.controls.zipcode.invalid && (lead_gen_form.controls.zipcode.dirty || lead_gen_form.controls.zipcode.touched) ">
													<span *ngIf="lead_gen_form.controls.zipcode.errors.required" class="error">This field is
														required</span>
													<span *ngIf="!lead_gen_form.controls.zipcode.errors.required&&lead_gen_form.controls.zipcode.errors.pattern"
														class="error">ZIP/Postal Code is not valid </span>
												</p>
											</div>
										</div>
									</div>

									<div class="basic-form_group lead_form_field">
										<div class="row is-6-6">
											<div class="column is-6 form_fields">
												<label
													class="basic-form_label form_label basic-form_label-required">Email</label>
												<input id="emailId" class="basic-form_input basic-form_input-half"
													type="email" maxlength="60" formControlName="email"
													placeholder="Email">
												<p class="errorptag"
													*ngIf="lead_gen_form.controls.email.invalid && (lead_gen_form.controls.email.dirty || lead_gen_form.controls.email.touched)">
													<span *ngIf="lead_gen_form.controls.email.errors.required" class="error">This
														field is
														required</span>
													<span
														*ngIf="!lead_gen_form.controls.email.errors.required&&lead_gen_form.controls.email.errors.pattern"
														class="error">
														<!-- Please enter a valid email address (Example: johndoe@example.com)  -->
														Enter a valid email address (e.g. name@example.com)
													</span>
												</p>
											</div>
											<div class="column is-6 form_fields">

													<div id="phoneFlagContainerId" class="column is-6 form_fields phoneFlagContainer"
													(clickOutside)="onClickedOutside($event)">
													<label class="basic-form_label form_label" [ngClass]="{'basic-form_label-required': phNumReqStatusDynamic}">Phone</label>
													<div class="row is-3-9">
														<div class="column is-3 phoneFlagOuter"                                                                                              
															(keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
															(click)="flagCodeClickStatus = !flagCodeClickStatus" tabindex='0'>
															<span class="flagOuter"><img [src]="userCountryDetails?.flagUrl"
																	class="flagImg" /></span>
															<span class="countryCodeOuter"> {{userCountryDetails?.dial_code}}</span>
														</div>

														<!-- Country List -->
														<div *ngIf="flagCodeClickStatus" class="countryResultOuter">
															<div *ngIf="flagCodeClickStatus" class="countryResultInner">
																<!-- <input type="text" [(ngModel)]="countrySearch" (ngModelChange)="countrySearchChanged($event)" (ngModelChange)="searchCountryChange()"> -->
																<div class="countrySearchOuter">
																	<input type="text" [(ngModel)]="countrySearch"
																		[ngModelOptions]="{standalone: true}"/>
																</div>
																<ul *ngIf="phoneDataArray.length" class="countrySearchListOuter">
																	<li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
																		(keyup.enter)="selectCountryClick(item)" (click)="selectCountryClick(item)"
																		[ngStyle]="{'border-bottom': 
																		!countrySearch && 
																		( 
																			( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
																			( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )                                        
																		)
																		? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
																		(focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
																		<span [innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
																		({{item?.dial_code}})                              
																	</li>
																</ul>
																<p class="noResultsFound letterSpacing"
																	*ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length"> No results
																	match "{{countrySearch}}"</p>
															</div>
														</div>
														<!-- Country List -->

														<div class="column is-9 phoneNumberInputOuter">
															<!-- <input type="text" [(ngModel)]="phoneNumber" class="phoneNumberInput" /> -->
															<!-- //[textMask]="{mask: mask}" (focusout)="phoneTBFocusout()" -->
															<input id="phoneId" class="basic-form_input basic-form_input-half phoneNumberInput"
																*ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone"
																[(placeholder)]="userCountryDetails.defaultFormat"
																[maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
																[textMask]="{mask: mask, guide:false}" 
																(input) = "phoneValueChangeFn()"/>																
														</div>
													</div>	
													<p class="errorptag"
														*ngIf="lead_gen_form.controls.phone.invalid && (lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched)">
														<span *ngIf="lead_gen_form.controls.phone.errors?.required" class="phoneNoError error">This field is
															required</span>
														<span *ngIf="!lead_gen_form.controls.phone.errors?.required&&lead_gen_form.controls.phone.errors?.phoneIncorrect"
															class="phoneNoError error">Enter a valid phone number (e.g. {{ userCountryDetails?.defaultPhoneNumber }} ) </span>
													</p>												
												</div>


											</div>
										</div>
									</div>	
									<div *ngIf="apiResponseC && !showTermUS" class="termsSection">
										<div *ngIf="apiResponseC?.field_term2 && showPhoneopt" class="eachTermSection">
											<input id="phoneOptId" type="checkbox" value="true"
												formControlName="isAgreeToContact" 
												(change)="phoneOptValueChanged()"
												aria-labelledby="phoneMarketing">
											<p class="termContent" id="phoneMarketing" *ngIf="!showPhoneOptRequiredText"
												[innerHtml]="apiResponseC?.field_term2"></p>
											<p class="termContent" id="phoneMarketing" *ngIf="showPhoneOptRequiredText"
												[innerHtml]="apiResponseC?.field_term2_required"></p>
											<p class="errorptag marketingError" *ngIf="showPhoneOptRequiredText">
												<span class="emailError error" [innerHtml] = "drupalErrorMessages?.field_phone_opt_in_required"></span>
											</p>
										</div>
										<div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
											<input id="emailOptId" type="checkbox" value="true"
												formControlName="isAgreeToReceiveMail" [checked]="emailReceiveCB"
												(change)="emailReceiveCB=!emailReceiveCB;emailCBManual=!checkBoxManual"
												aria-labelledby="emailMarketing">
											<p class="termContent" id="emailMarketing"
												[innerHtml]="apiResponseC?.field_term1"></p>
										</div>
									</div>
									<div class="basic-form_group">
										<div class="row is-12">
											<div class="column is-12 form_fields">
												<label class="basic-form_label form_label">Comments</label>
												<textarea id="commentId" class="basic-form_input" rows="4"
													formControlName="comment" maxlength="200"></textarea>
											</div>
										</div>
									</div>

									<!-- Remove *ngIf to show sms opt option -->
									<div class="basic-form_group" *ngIf="false">
										<label class="basic-form_label form_label">Is this a mobile phone number? (Optional)</label><br>
										<div class= "form_radio_buttons">
											<input type="radio" id="mobileRadioYes" class="mobileRadio" formControlName="isMobile" value= "true">
											<label class="radio_label" for="mobileRadioYes">Yes</label>
											<input type="radio" id="mobileRadioNo" class="mobileRadio"  formControlName="isMobile" value="false" [checked]="true">
											<label class="radio_label" for="mobileRadioNo">No</label><br>
										</div>
									</div>

									<div *ngIf="apiResponseC && showTermUS" class="termsSection">
										<div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
											<input id="emailOptId" type="checkbox" value="true"
												formControlName="isAgreeToReceiveMail" [checked]="true"
												(change)="emailReceiveCB=!emailReceiveCB;emailCBManual=!checkBoxManual"
												aria-labelledby="emailMarketing">
											<p class="termContent" id="emailMarketing"
												[innerHtml]="apiResponseC?.field_term1"></p>
										</div>
									</div>

								</fieldset>
							</form>
							<div id="rac-terms" *ngIf="apiResponseC && !showTermUS" [innerHtml]="apiResponseC.field_summery"></div>
							<div id="rac-terms" *ngIf="apiResponseC && showTermUS" [innerHtml]="apiResponseC.field_summary_us"></div>
							<div class="row is-6-6">
								<!-- <p class="succ" *ngIf="succmsg">THANK YOU FOR REGISTERING</p> -->
								<div class="column is-6">
									<div class="row is-6-6">
										<div *ngIf = "!submitLoading" class="column is-6 submit_btn">
											<!-- <input type="submit" id="submitId" (click)="leadGenSubmit()"
												[disabled]="lead_gen_form.invalid" value="Submit"> -->
												<input type="submit" id="submitId" (click)="leadGenSubmit()"
													[disabled]="lead_gen_form.controls.firstname.invalid ||
													lead_gen_form.controls.lastname.invalid ||													
													lead_gen_form.controls.country.invalid ||
													lead_gen_form.controls.zipcode.invalid ||
													lead_gen_form.controls.email.invalid ||
													lead_gen_form?.controls?.phone?.errors?.required ||
													( lead_gen_form.value.phone && lead_gen_form.controls.phone.errors?.phoneIncorrect!=false ) ||
													!userArea || 
													showPhoneOptRequiredText" 
													value="Submit">
										</div>
										<div *ngIf = "submitLoading" class="row is-12 btnLoaderOuter">
											<div class="btnLoader">
												<img src="assets/components/images/loading.gif" class="formLoader">
											</div> 
										  </div>
					  
										<div class="column is-6 clear_btn">
											<input type="reset" id="resetId" (click)="ngOnInit()" value="Clear" [ngClass]="{'disabledCursor': submitLoading}" [disabled]="submitLoading">
										</div>
									</div>
								</div>
								<div class="column is-6"></div>
							</div>


						</div>
						<div class="column is-3"></div>
					</div>

				</div>
			</div>
		</div>
	</section>

</div>

<!-- REQUEST Call in Offers & Programs STARTS -->
<div *ngIf="!isRequestCallStatus" id="rcyc-request-call-offerPrograms">
	<!-- <app-rcyc-error #errorModal></app-rcyc-error> -->
	<!-- Section - 1 -->
	<section class="content_section new_content">
		<div class="l-container-wrapper">
			<div class="l-container intro-header leadinto new_pad">
				<div class="row">					
					<div *ngIf="apiResponseB" class="row headline-block">							
						<h1 [title]="apiresponseB?.title | convertHtml" class="headline pageTitle" [innerHtml]="apiResponseB?.title"></h1>					
						<p id="requiredField"><span>*</span> Indicates required field</p>		
					</div>								
					<div class="row">                                                
						<div class="row lead_form_right">
							<div class="mb-4 formErrorSec">													
								<div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
									<span class="error">{{sessionExpiredMessages}}</span>
								</div>
								<!-- <div *ngIf="specialOfferFormErrorMsg" id="formSubmitUIErrorId">
									<span class="error" [innerHTML]="specialOfferFormErrorMsg"></span>
								</div> -->
							</div>
							
							<form id="requestCallFormId" name="requestCallForm" [formGroup]="lead_gen_form" novalidate autocomplete="false">
								<fieldset>
									<div class="basic-form_group lead_form_field formFirstRow">
										<div class="row is-4-4-4">
											<div class="column is-4 form_fields mb-30" >
												<!-- <label
													class="basic-form_label form_label basic-form_label-required">First
													Name</label> -->
													<input id="firstNameId" class="input-1 mb-4" type="text"
													formControlName="firstname" maxlength="30"
													[ngClass]="{
														'input-focused': isFirstNameInputFocused,
														'input-valid': (lead_gen_form.get('firstname').valid || lead_gen_form.controls.firstname.errors?.pattern )
													}"
													(focus)="isFirstNameInputFocused = true"
													(blur)="isFirstNameInputFocused = false"
													(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
												<p class="errorptag"
													*ngIf="lead_gen_form.controls.firstname.invalid && 
													(lead_gen_form.controls.firstname.dirty || lead_gen_form.controls.firstname.touched || specialOfferFormErrorMsg)">
													<span *ngIf="lead_gen_form.controls.firstname.errors.required"
														class="emailError error">This field is required</span>
													<span
														*ngIf="!lead_gen_form.controls.firstname.errors.required && lead_gen_form.controls.firstname.errors.pattern"
														class="phoneNoError error"> Please check the name format </span>
												</p>
												<label for="firstNameId" class="label-1 label_space">First Name</label>													
											</div>
											<div class="column is-4 form_fields mb-30">
												<!-- <label
													class="basic-form_label form_label basic-form_label-required">Last
													Name</label> -->
													<input id="lastNameId" class="input-1 mb-4" type="text"
													formControlName="lastname"  maxlength="60" 
													[ngClass]="{
														'input-focused': isLastNameInputFocused,
														'input-valid': (lead_gen_form.get('lastname').valid || lead_gen_form.controls.lastname.errors?.pattern )
													}"
													(focus)="isLastNameInputFocused = true"
													(blur)="isLastNameInputFocused = false"
													(click)="specialCountryInputCloseBtnStatus=false; specialCountryInputClickStatus=false" />
												<p class="errorptag"
													*ngIf="lead_gen_form.controls.lastname.invalid &&
													(lead_gen_form.controls.lastname.dirty || lead_gen_form.controls.lastname.touched || specialOfferFormErrorMsg)">
													<span *ngIf="lead_gen_form.controls.lastname.errors?.required"
														class="emailError error">This field is required</span>
													<span
														*ngIf="!lead_gen_form.controls.lastname.errors?.required && lead_gen_form.controls.lastname.errors?.pattern"
														class="phoneNoError error">Please check the name format </span>
												</p>
												<label class="label-1 label_space">Last Name</label>
											</div>
											<div class="column is-4 form_fields mb-30" >												
												<!-- <label
													class="basic-form_label form_label basic-form_label-required">Country/Region
													of
													Residence</label> -->
												<!-- <div class="countrySelectInner">
													<select id="selectCountryId" class="basic-form_input basic-form_select" formControlName="country"
														[ngClass]="{'error-borderBtm': (lead_gen_form.controls.country.invalid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) && lead_gen_form.controls.country.errors.required) }">
														<option id="selectCountryId_0" value="" disabled>Select Country/Region of
															Residence</option>
														<option *ngFor="let county of countryListArray;let i=index"
															id="countryOptionId_{{i+1}}" value="{{county.code}}">
															{{county.name}}</option>
													</select>
													<span class="icon-arrow-down"></span>
												</div> 
												<div
													*ngIf="lead_gen_form.controls.country.invalid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) && lead_gen_form.controls.country.errors.required"
													class="error">This field is required</div>-->
										

												<div class="specialCountryOuter">
													<div class="specialCountryInputOuter">
														<div class="inputCloseOuter">
															<!-- <input id="specialCountryInputId" class="basic-form_input basic-form_input-half" type="text"
															placeholder="Select Country/Region of Residence" maxlength="60" [(ngModel)]="specialCountry"
															[ngModelOptions]="{standalone: true}" (click)="specialCountryInputClickStatus=true"
															[ngClass]="{'error-borderBtm': lnameWhitespaceError}" /> -->
															<input id="specialCountryInputId" class="input-1 mb-4" type="text"
															maxlength="60" formControlName="country"
															[ngClass]="{
																'input-focused': isSpecialCountryInputFocused,
																'input-valid': (lead_gen_form.get('country').valid || lead_gen_form.controls.country.errors?.pattern )
															}" 
															(focus)="isSpecialCountryInputFocused = true"
															(blur)="isSpecialCountryInputFocused = false"
															(click)="specialCountryInputClickStatus=true;specialCountryInputCloseBtnStatus=true"/>
															<span *ngIf="specialCountryInputCloseBtnStatus" class="icon-close" (click)="specialCountryInputCloseClick()"
															[ngClass]="{'error-specialCountryCloseBtn': ( (lead_gen_form.controls.country.invalid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched || specialOfferFormErrorMsg ))&&
															(lead_gen_form.controls.country.errors.required ) )
														||( ( lead_gen_form.controls.country.valid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) ) &&
														!isSpecialCountrySelect)}"></span>
															
															<p class="errorptag"
																*ngIf="lead_gen_form.controls.country.invalid &&
																(lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched || specialOfferFormErrorMsg)">
																<span *ngIf="lead_gen_form.controls.country.errors.required" class="error">This field is
																	required</span>													
															</p>
															<p class="errorptag"
																*ngIf="lead_gen_form.controls.country.valid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched)">													
																<span *ngIf="!isSpecialCountrySelect" class="error">Please select a country</span>														
															</p>
															<label class="label-1 label_space" for="specialCountryInputId" >Country/Region of Residence
															</label>
														</div>
														<div *ngIf="specialCountryInputClickStatus" class="specialCountryListOuter">
															<ul *ngIf="countryListArray?.length" class="specialCountryUl">
																<li class="specialCountryLi" *ngFor="let item of countryListArray | specialCountrynameFilter: lead_gen_form.controls.country.value;let i=index"
																	id="specialCountryListItem_{{i}}"
																	[innerHTML]="item?.name | SpecialCountryHighlight: lead_gen_form.controls.country.value"
																	(click)="specialCountryLiClick(item);">
																</li>
															</ul>
														</div>
													</div>
												</div>

											</div>
										</div> 
									</div>                                  
									<div class="basic-form_group lead_form_field">
										<div class="row is-4-4-4">											
											<div class="column is-4 form_fields mb-30">
												<!-- <label class="basic-form_label form_label" [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
													ZIP/Postal Code (Required for USA/Canada)</label> -->
													<input id="zipCodeId" class="input-1 mb-4" type="text"
													formControlName="zipcode" [maxlength]="zipcodeMaxLength"
													[ngClass]="{
														'input-focused': isZipCodeInputFocused,
														'input-valid': (lead_gen_form.get('zipcode').value && (lead_gen_form.get('zipcode').valid || lead_gen_form.controls.zipcode.errors?.pattern ))
													}"
													(focus)="isZipCodeInputFocused = true"
													(blur)="isZipCodeInputFocused = false"
													(input)="checkZipcodeFormat($event)"
													(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
												<p class="errorptag"
													*ngIf="lead_gen_form.controls.zipcode.invalid &&
													(lead_gen_form.controls.zipcode.dirty || lead_gen_form.controls.zipcode.touched || specialOfferFormErrorMsg) ">
													<span *ngIf="lead_gen_form.controls.zipcode.errors.required" class="error">This field is
														required</span>
													<span *ngIf="!lead_gen_form.controls.zipcode.errors.required&&lead_gen_form.controls.zipcode.errors.pattern"
														class="error">ZIP/Postal Code is not valid </span>
												</p>
												<label class="label-1 label_space" for="zipCodeId" [ngClass]="{'label-2': !zipcodeReqStatusDynamic}">
													ZIP/Postal Code (Required for USA/Canada)</label>
											</div>
											<div class="column is-4 form_fields mb-40" >
												<!-- <label
													class="basic-form_label form_label basic-form_label-required">Email</label> -->
													<input id="emailId" class="input-1 mb-4" 
													type="text" maxlength="60" formControlName="email" 
													[ngClass]="{
														'input-focused': isEmailInputFocused,
														'input-valid': (lead_gen_form.get('email').valid || lead_gen_form.controls.email.errors?.pattern )
													}"
													(focus)="isEmailInputFocused = true"
													(blur)="isEmailInputFocused = false"
													(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
												<p class="errorptag"
													*ngIf="lead_gen_form.controls.email.invalid &&
													(lead_gen_form.controls.email.dirty || lead_gen_form.controls.email.touched || specialOfferFormErrorMsg)">
													<span *ngIf="lead_gen_form.controls.email.errors.required" class="error">This
														field is
														required</span>
													<span
														*ngIf="!lead_gen_form.controls.email.errors.required&&lead_gen_form.controls.email.errors.pattern"
														class="error">
														<!-- Please enter a valid email address (Example: johndoe@example.com) -->
														Enter a valid email address (e.g. name@example.com)
													</span>
												</p>
												<label class="label-1 label_space" for="emailId">
													Email</label>
											</div>										
											<div class="column is-4 form_fields mb-18">											
												
												<div id="phoneFlagContainerId" class="column is-6 phoneFlagContainer"
													(clickOutside)="onClickedOutside($event)">
													<!-- <label class="basic-form_label form_label" [ngClass]="{'basic-form_label-required': phNumReqStatusDynamic}">Phone</label> -->
													<div class="row is-3-9">
														<div class="column is-3 phoneFlagOuter new_flag"                                                                                                    
															(keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
															(click)="flagCodeClickStatus = !flagCodeClickStatus" tabindex='0'
															[ngClass]="{'error-borderBtm': (lead_gen_form.controls.phone.invalid &&
															(lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched || specialOfferFormErrorMsg))&&
															 (lead_gen_form.controls.phone.errors?.required || (!lead_gen_form.controls.phone.errors?.required&&lead_gen_form.controls.phone.errors?.phoneIncorrect) ) }"
															 >
															<span class="flagOuter"><img [src]="userCountryDetails?.flagUrl"
																	class="flagImg" /></span>
															<span class="countryCodeOuter countryCodeOuterTop"> {{userCountryDetails?.dial_code}}</span>
														</div>

														<!-- Country List -->
														<div *ngIf="flagCodeClickStatus" class="countryResultOuter">
															<div *ngIf="flagCodeClickStatus" class="countryResultInner">
																<!-- <input type="text" [(ngModel)]="countrySearch" (ngModelChange)="countrySearchChanged($event)" (ngModelChange)="searchCountryChange()"> -->
																<div class="countrySearchOuter">
																	<input type="text" [(ngModel)]="countrySearch"
																	[ngModelOptions]="{standalone: true}" style="border-style: solid;
																	border-width: thin;border-color: black;"/>
																</div>
																<ul *ngIf="phoneDataArray.length" class="countrySearchListOuter">
																	<li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
																		(keyup.enter)="selectCountryClick(item)" (click)="selectCountryClick(item)"
																		[ngStyle]="{'border-bottom': 
																		!countrySearch && 
																		( 
																			( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
																			( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )                                        
																		)
																		? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
																		(focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
																		<span [innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
																		({{item?.dial_code}})                              
																	</li>
																</ul>
																<p class="noResultsFound letterSpacing"
																	*ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length"> No results
																	match "{{countrySearch}}"</p>
															</div>
														</div>
														<!-- Country List -->

														<div class="column is-9 phoneNumberInputOuter">
															<!-- <input type="text" [(ngModel)]="phoneNumber" class="phoneNumberInput" /> -->
															<!-- //[textMask]="{mask: mask}" (focusout)="phoneTBFocusout()" -->
															<input id="phoneId" class="input-1 mb-4" style="border-left-color: white"
															*ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone"
															[maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
															[ngClass]="{
																'input-focused': isPhoneInputFocused,
																'input-valid': lead_gen_form.get('phone').value
															}"
															(focus)="isPhoneInputFocused = true"
															(blur)="isPhoneInputFocused = false"
															(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
															[textMask]="{mask: mask, guide:false}"
															(input) = "phoneValueChangeFn()"/>
															<label class="label-1 label_space" for="phoneId" style="left: 98px;" [ngClass]="{'label-2': !phNumReqStatusDynamic}">
																Phone</label> 															
														</div>
														
													</div>
													<p class="errorptag"
														*ngIf="lead_gen_form.controls.phone.invalid &&
														(lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched || specialOfferFormErrorMsg)">
														<span *ngIf="lead_gen_form.controls.phone.errors?.required" class="phoneNoError error">This field is
															required</span>
														<span *ngIf="!lead_gen_form.controls.phone.errors?.required&&lead_gen_form.controls.phone.errors?.phoneIncorrect"
															class="phoneNoError error">Enter a valid phone number (e.g. {{ userCountryDetails?.defaultPhoneNumber }} ) </span>
													</p>													
												</div>

											</div>
										</div>
									</div>
									<div *ngIf="apiResponseC" class="termsSection">
				  <div *ngIf="isMobilebutton=='true'" class="eachTermSection">
					<input id="smsOptId" type="checkbox" value="true"
						   formControlName="isAgreeToSms"
						   aria-labelledby="mobileMarketing">
					<p class="termContent mobiletext" id="mobileMarketing" [innerHtml]="apiResponseC?.field_term3"></p>
				  </div>
										<div *ngIf="apiResponseC?.field_term2 && showPhoneopt" class="eachTermSection">
											<input id="phoneOptId" type="checkbox" value="true" formControlName="isAgreeToContact" [checked]="phoneReceiveCB"
											(change)="phoneOptValueChanged();phoneReceiveCB=!phoneReceiveCB;phoneCBManual=!phoneCBManual;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
											aria-labelledby="phoneMarketing">
											<p class="termContent" *ngIf="!showPhoneOptRequiredText" id="phoneMarketing" [innerHtml]="apiResponseC?.field_term2"></p>
											<p class="termContent" *ngIf="showPhoneOptRequiredText" id="phoneMarketing" [innerHtml]="apiResponseC?.field_term2_required"></p>
											<p class="errorptag marketingError" *ngIf="showPhoneOptRequiredText">
												<span class="emailError error" [innerHtml] = "drupalErrorMessages?.field_phone_opt_in_required"></span>
											</p>
										</div>
										<div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
											<input id="emailOptId" type="checkbox" value="true" formControlName="isAgreeToReceiveMail" [checked]="emailReceiveCB"
											(change)="emailReceiveCB=!emailReceiveCB;emailCBManual=!checkBoxManual;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
											aria-labelledby="emailMarketing">
											<p class="termContent" id="emailMarketing" [innerHtml]="apiResponseC?.field_term1"></p>
										</div>
									</div>
								</fieldset>
							</form>
							<div *ngIf="apiResponseC && !showTermUS" class="declaration" [innerHtml]="apiResponseC.field_summery"></div>
							<div *ngIf="apiResponseC && showTermUS" class="declaration" [innerHtml]="apiResponseC.field_summary_us"></div>
							<div class="row">    								                           																	
								<div *ngIf = "!submitLoading"class="submit_btn specialRACSubmitBtn">
									<!-- <input type="submit" id="submitId" (click)="leadGenSubmit()"
													[disabled]="lead_gen_form.invalid
													||!isSpecialCountrySelect" value="Submit"> -->
									<!-- <input type="submit" id="submitId" (click)="leadGenSubmit()"
													[disabled]="lead_gen_form.controls.firstname.invalid ||
													lead_gen_form.controls.lastname.invalid ||													
													lead_gen_form.controls.country.invalid ||
													lead_gen_form.controls.zipcode.invalid ||
													lead_gen_form.controls.email.invalid ||
													lead_gen_form.controls.phone.errors.phoneIncorrect!=false ||
													!isSpecialCountrySelect||!userArea" value="Submit">	 -->
												<input type="submit" id="submitId" (click)="leadGenSubmit()"
													value="Submit">	
								</div>																			
								<div *ngIf = "submitLoading" class="specialRACSubmitBtn">
									<div class="submitLoader">
										<img src="assets/components/images/loading.gif">
									</div> 
								</div>	
										
							</div>
						</div>						
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
<!-- REQUEST Call in Offers & Programs ENDS -->