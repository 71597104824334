<section id="banneimg_section" class="section-1" *ngIf='showBannerCarousel' (scroll)="scrollHandler($event)">
  <div class="p04_frame  banner_fix current-slide-has-caption">
    <ul class="p04_slide_list">
      <!-- Section for Hero Banner Images  -->
      <li *ngIf="heroVideo&&deviceView=='mobileView'||!heroVideo" class="p04_slide banner_fix_ul">
        <div class="heroImageBanner" (mouseenter)="enter()" (mouseleave)="leave()">

          <!-- Section for Load Images   -->
          <!-- <slideshow #imgSlider id="slideIndex"  [arrowSize]="arrowSize" 
            role="link" [attr.aria-label]="[imageContentArray[imgSlider.slideIndex]?.field_image_alt_content]"
            [imageUrls]="carouselImageArray"  [showDots]="false" [showArrows]="false" tabindex="0" (mouseenter)="sliderImgTitleChange()">
          </slideshow> -->

          <slideshow #imgSlider id="slideIndex"  [arrowSize]="arrowSize" 
            role="link" [attr.aria-label]="[imageContentArray[imgSlider.slideIndex]?.field_image_alt_content]"
            [imageUrls]="carouselImageArray" [showDots]="false" [showArrows]="false" tabindex="0"
            (mouseenter)="sliderImgTitleChange(); addCurrentHref();" (click)="sliderImgClick()">
          </slideshow>

          <!-- <div class="titlecontainer"  [attr.title]="imageContentArray[imgSlider.slideIndex]?.field_image_title_content | convertHtml"></div> -->

          <!-- Section for Left Arrow  -->          
          <a class="btn_carousel leftRs" *ngIf="showArrow" href="javascript:void(0);" tabindex="0" (click)="leftArrowClick();" (focus)="enter()"
            (focusout)="leave()">
            <img class="sliderLeftnav" src="assets/img/arrow_left.svg" title="Scroll to the previous image" alt="Scroll to the previous image">
          </a>

          <!-- Section for Play and Pause  -->
          <!-- <div class="l-container-wrapper">
            <div class="l-container">
              <div *ngIf="showArrow && isCorrectOrientationAndSize && carouselImageArray" class="loadplaypauseimage" tabindex="0" [ngClass]="{'changeBackgroundPlay': showArrow && clickedPause,'changeBackgroundPause':showArrow && !clickedPause}"
                (keyup)="keyUpControl($event,clickedPause)" (focus)="enter()">
                <a title="Pause" *ngIf="showArrow && !clickedPause" (click)="clickedPauseButton()" class="icon-pause icon pause"> </a>
                <a title="Play" *ngIf="showArrow && clickedPause" (click)="clickedPlayButton()" class="icon-play icon play "> </a>
              </div>
            </div>
          </div> -->

          <!-- Section for Right Arrow  -->
          <a class="btn_carousel rightRs" *ngIf="showArrow" href="javascript:void(0);" tabindex="0" (focus)="enter()" (focusout)="leave()"
            (click)="rightArrowClick();">
            <img class="sliderRightnav" src="assets/img/arrow_right.svg" title="Scroll to the next image" alt="Scroll to the next image">
          </a>

        </div>
      </li>

      <!-- Section for Hero Video id="tpm-plugin-5wvjor6f-273872957" -->
      <!-- // FPR TEST <iframe (load)="enablePlay()" [src]="heroVideo" *ngIf="heroVideo" id="tpm-plugin-5mgdacc1-313209156" -->
          <!-- <iframe (load)="enablePlay()" [src]="heroVideo" *ngIf="heroVideo" id="tpm-plugin-5wvjor6f-273872957" -->              
      <li *ngIf="heroVideo&&deviceView=='desktopView'" id="heroVideoOuterId" class="herovideo align_left desktopView">
        <iframe (load)="enablePlay()" [src]="heroVideo" *ngIf="heroVideo" id='{{current3PlayPuginId}}'
        tabindex="-1" class="videoIframe " allow="autoplay"
          frameborder="0 " webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>          
        <div class="l-container-wrapper">
          <div class="l-container">            
            <div *ngIf="current3PlayPuginId" class="btnOverHero videoplaypauseimage" id="threePlayMediaPlayPause"
            [ngClass]="{'changeBackgroundPause':isVideoPlaying,'changeBackgroundPlay':!isVideoPlaying}"
              tabindex="0" (keyup)="keyUpVideoControl($event,isVideoPlaying)"  (click)="isVideoPlaying?clickedVideoPause():clickedVideoPlay()">              
              <a [title]="isVideoPlaying? 'Pause': 'Play'"  class="icon"
              [ngClass]="{'icon-pause pause': isVideoPlaying, 'icon-play play': !isVideoPlaying}"> </a>
            </div>   

            <div *ngIf="current3PlayPuginId" id="threePlayMediaPlayCc" class="btnOverHero ccVideoSubTitle"
            [ngClass]="{'disable3play':!isVideoPlaying, 'ccDisabled3Play':!isCCEnabled,'ccEnabled3Play':isCCEnabled}"
            [tabindex]="isVideoPlaying?'0':'-1'" (click)="togleClosedCaption()" (keyup.enter)="togleClosedCaption()">              
              <a [title]="!isCCEnabled ? 'Enable cc': 'Disable cc'"  class="icon" 
              [ngClass]="{'play': !isCCEnabled, 'pause': isCCEnabled }">
                <img [src]="isCCEnabled ? '../../../../assets/components/images/Closed_captioning_symbol-reverse.gif' : '../../../../assets/components/images/Closed_captioning_symbol.svg'"/>
              </a>
            </div>

            <div id="outerAD" class="disable3play">
              <div *ngIf="current3PlayPuginId" id="threePlayMediaAD" class="btnOverHero adOuter"
                   (click)="ADClick()"
                   [ngClass]="{'audioEnable3Play': ADClickStatus, 'audioDisable3Play': !ADClickStatus, 'disable3play': !isVideoPlaying }">
                <div *ngIf="loaded3PlayTargetPluginId" id="{{loaded3PlayTargetPluginId}}"
                     class='p3sdk-target' [title]="!ADClickStatus ? 'Enable AD': 'Disable AD'"></div>
                <!-- <div id='3p-plugin-target-3513582-5wvjor6f' class='p3sdk-target'></div>                -->
                <!-- <img [src]="ADClickStatus ? '../../../../assets/components/images/audio_description_reverse.gif' : '../../../../assets/components/images/audio_description.gif'"/> -->
              </div>
            </div>

            <div *ngIf="current3PlayPuginId" id="threePlayMediaMute" class="btnOverHero vimeoMute"
            (keyup.enter)="vimeoMuteClickNew()" (click)="vimeoMuteClickNew()" [tabindex]="isVideoPlaying?'0':'-1'"
            [ngClass]="{'vimeoMute3Play': muteStatus, 'vimeoUnmute3Play': !muteStatus, 'disable3play': !isVideoPlaying }"> 
                <a [title]="!muteStatus ? 'Mute': 'Unmute'"  class="icon" 
                  [ngClass]="{'play': muteStatus, 'pause': !muteStatus }">
                  <img [src]="muteStatus ? '../../../../assets/components/images/mute.png' : '../../../../assets/components/images/unmute.png'"/>
                </a>                            
            </div>  
            
            <div *ngIf="current3PlayPuginId && !muteStatus" id="threePlayMediaVolumecontrol" class="vimeoVolume">
              <input #vimeoVolumeRef id="vimeoVolcontrolId" type="range" min="0" max="10" step="1"
              (change)="setVimeoVolume(vimeoVolumeRef.value)"/>
            </div>
            

          </div>
        </div>
      </li>
    </ul>
  </div>
</section>