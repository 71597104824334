import { Component, Input, OnInit } from '@angular/core';
import { RcycDestinationLandingService } from '../rcyc-destination-landing/rcyc-destination-landing.service';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { AppConfig } from '../../rcyc-config/config';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycVoyagesService } from '../rcyc-voyages/rcyc-voyages.service';

@Component({
    selector: 'app-rcyc-shore-excursions-details',
    templateUrl: './rcyc-shore-excursions-details.component.html',
})

export class RcycShoreExcursionsDetailsComponent implements OnInit {
    IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
    bannerData: any;
    apiResponseB: any;
    articlesData: any;
    bookingBenefits: any;
    faqData: any;
    excursionsData: any;
    openedTabs: number[] = [];

    constructor(
        private rcycDestinationLandingService: RcycDestinationLandingService,
        private rcycCommonService: RcycCommonService,
        private rcycInternalApisService: RcycInternalApisService,
        private rcycVoyagesService: RcycVoyagesService,
    ) { }

    ngOnInit(): void {
        this.bannerData = null;
        this.apiResponseB = null;
        this.articlesData = null;
        this.bookingBenefits = null;
        this.faqData = null;
        this.excursionsData = null;
        this.getBannerHeroDetails();
        this.get5050Data();
        this.getShoreExcursions();
        this.getBookingBenefitsData();
        this.getFaqData();
        this.getFeaturedArticlesData();
    }

    /****************** Hero Banner Component *******************/
    getBannerHeroDetails() {
        let apiResponseBTemp: any = [];
        const apiUrl = '/api/v1/banners/experience/shore-excursions/b?_format=json';
        this.rcycDestinationLandingService.destinationLanding(apiUrl).subscribe(
            response => {
                apiResponseBTemp = response;
                if (apiResponseBTemp && apiResponseBTemp.length) {
                    if (apiResponseBTemp.length) {
                        const resArray = [];
                        for (const index of Object.keys(apiResponseBTemp)) {
                            const factor = apiResponseBTemp[index];
                            let imgTitle;
                            if (factor.field_images) {
                                factor.field_images = this.IMG_BASE_URL + factor.field_image_375px;
                                imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_images);
                                setTimeout(() => {
                                    factor.field_images = this.IMG_BASE_URL + factor.field_images;
                                }, (2000)
                                );
                            }
                            factor.altval = factor.title + ' ' + imgTitle;
                            factor.titleval = factor.title + ' ' + imgTitle;
                            resArray.push(factor);
                            if (parseInt(index, 10) + 1 === apiResponseBTemp.length) {
                                this.bannerData = resArray;
                            }
                        }
                    }
                }
            },
            err => {
                console.error(err);
            }
        );
    }

    /****************** 50-50 component *******************/
    get5050Data() {
        const apiUrlB = `/api/v1/50-50/shore-excursions/b?_format=json`;
        this.rcycInternalApisService.getDrupal(apiUrlB).subscribe(
            response => {
                const apiResponseCTemp: any = response;
                if (apiResponseCTemp && apiResponseCTemp.length) {
                    this.apiResponseB = apiResponseCTemp[0];
                }
            },
            err => {
                console.error(err);
            }
        );
    }

    /****************** Excursions component *******************/
    getShoreExcursions() {
        const apiUrl = '/api/v1/exquisites-shore-experiences/shore-excursions?_format=json';
        this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
            response => {
                const shoreExHeaderTemp: any = response;
                if (shoreExHeaderTemp && shoreExHeaderTemp.length) {
                    this.excursionsData = shoreExHeaderTemp;
                }
            },
            err => { }
        );
    }

    /******************  Benefits of Booking component *******************/
    getBookingBenefitsData() {
        const apiUrl = `/api/v1/tiles/shore-excursions/d?_format=json`;
        this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
            response => {
                const bookingBenefitsTemp: any = response;
                if (bookingBenefitsTemp && bookingBenefitsTemp.length > 0) {
                    this.bookingBenefits = { items: null, component: { title: bookingBenefitsTemp[0].title, eyebrow: bookingBenefitsTemp[0].field_com_eyebrow } };
                    this.bookingBenefits.items = bookingBenefitsTemp.map(item => {
                        return { ...item, ...{ field_sub_header: item.field_tile_eyebrow, title: item.field_tile_title } };
                    });
                }
            },
            err => { }
        );
    }

    /****************** FAQ component *******************/
    getFaqData() {
        const apiUrl = `/api/v1/accordion/shore-excursions/FREQUENTLY%20ASKED%20QUESTIONS?_format=json `;
        this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
            response => {
                const faqDataTemp: any = response;
                if (faqDataTemp && faqDataTemp.length) {
                    this.faqData = faqDataTemp.map((item, i) => { return { ...item, ...{ field_sort_order: (++i + '') } } });
                }
            },
            err => { }
        );
    }

    /****************** Article component *******************/
    getFeaturedArticlesData() {
        const apiUrl = '/api/v1/featured-articles/shore-excursions?_format=json';
        this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
            response => {
                const apiResponseETemp: any = response;
                if (apiResponseETemp && apiResponseETemp.length) {
                    this.articlesData = apiResponseETemp;
                }
            },
            err => {
                console.error(err);
            }
        );
    }

    toogleTab(id: number) {
        const index = this.openedTabs.indexOf(id);
        if (index === -1) {
            this.openedTabs.push(id);
        } else {
            this.openedTabs.splice(index, 1);
        }
    }

    isOpened(id: number): boolean {
        return this.openedTabs.includes(id);
    }
}
