<div [class]="variant === 'light' ? 'text-white' : 'text-primary'">
 
  <ng-container *ngIf="data.voyageRegion">
    <div class="text-sm uppercase mb-3 lg:mb-[24px] brochure-content" *ngIf="data.eyebrow">
      {{data.eyebrow}}
    </div>
    <h2 *ngIf="heading === 'h2'" class="text-[23px] lg:text-[31px] mb-5 lg:mb-4 leading-[33px] lg:leading-[36px] lg:[&+.field-content]:-mt-2" [class]="variant === 'light' ? 'text-white' : 'text-primary'">
      {{data.title | convertHtml}}
    </h2>
  </ng-container>
  <ng-container *ngIf="!data.voyageRegion">
    <ng-container *ngIf="data?.header_variant == 'full_image'" >
      <span class="text-sm/[14px]">{{data.subHeader}}</span>
      <div class="mt-6 lg:flex">
      <h2 *ngIf="heading === 'h2'" class="lg:w-1/2 text-[29px] lg:text-[49px] mb-9 lg:mb-10 leading-[33px] lg:leading-[56px] lg:[&+.field-content]:-mt-2" [class]="variant === 'light' ? 'text-white' : 'text-primary'">
        {{data.title | convertHtml}}
      </h2>
      <span class="leading-5 lg:w-1/2">
        {{data.description}}
      </span>
    </div>
    </ng-container>
    <ng-container *ngIf="data?.header_variant != 'full_image'" >
      <div class="text-sm uppercase mb-3 lg:mb-[18px]" *ngIf="data.eyebrow">
        {{data.eyebrow}}
      </div>
      <h2 *ngIf="heading === 'h2'" class="text-[29px] lg:text-[49px] mb-5 lg:mb-[48px] leading-[33px] lg:leading-[56px] lg:[&+.field-content]:-mt-2" [class]="variant === 'light' ? 'text-white' : 'text-primary'"
      [ngClass]="isChartersPage || isFAQ? 'mt-6': '' ">
        {{data.title | convertHtml}}
      </h2>
      <h3 *ngIf="heading === 'h3'" class="text-[26px] lg:text-[39px] mb-5 lg:mb-6 mt-2 lg:bt-4 uppercase" [class]="variant === 'light' ? 'text-white' : 'text-primary'">
        {{data.title | convertHtml}}
      </h3>
    </ng-container>
</ng-container>
  <div *ngIf="data.text" class="mb-6 lg:mb-8 text-content leading-[26px]"
  [ngClass]="{'text-content--light' : variant === 'light', 'sm:min-h-full min-h-36' : sliderVariant === 'barSmall'}"
       [innerHTML]="data.text"></div>

  <div *ngIf="data.cta" class="leading-6 uppercase" [class]="variant === 'light' ? '[&>a]:text-white' : '[&>a]:text-primary'">
    <a [href]="actionUrl" *ngIf="actionUrl" class="link-cta">
      {{data.cta.title}}
    </a>
    <a [routerLink]="data.cta.route" *ngIf="data.cta.route" class="link-cta">
      {{data.cta.title}}
    </a>
  </div>
</div>
