import { Injectable } from '@angular/core';

declare let gtag;

@Injectable({
    providedIn: 'root'
})

export class GoogleAnalyticsService {

    //Pricing Modal Popup
    trackBCModalOpen(): void {
        gtag('event', 'get_pricing_modal_view', {});  
    }

    trackBCModalClose(timeSpent: number): void {
        gtag('event', 'get_pricing_abandon', {
            time_spent: timeSpent
        }); 
    }

    //Plan Voyage Page
    planVoyageGroupClick(url: string): void{
        gtag('event', 'plan_voyage_group_cta_click', {
            destination_url: url
        })
    }

    //Select Suite
    selectSuiteNextClick(suite: string): void{
        gtag('event', 'select_suite_next_click', {
            selected_suite: suite
        })
    }
    
    //Summary Page
    summarySubmitClick(total: number): void{
        gtag('event', 'summary_submit_click', {
            total_booking_value: total
        })
    }

    trackView(event: string, timeSpent: number): void{
        gtag('event', event, {
            time_on_page: timeSpent
        })
    }

    trackExit(event: string, timeSpent: number, intent: string): void{
        gtag('event', event, {
            time_spent: timeSpent,
            exit_intent: intent
        })
    }

    trackClick(event: string, timeSpent: number): void{
        gtag('event', event, {
            time_spent_before_click: timeSpent
        })
    }

    trackContinue(event: string): void {
        gtag('event', event, {}); 
    }
}