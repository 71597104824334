<div class="toasterMessageOuter">
  <div *ngFor="let item of MessageArray" class="{{item?.divId}} animationFirstClass parentToaserMessageClass" id="{{item?.divId}}" [ngClass]="{'closeClickClass': item.closeClick}" >

    <div class="toasterMessageInner" (click)='item.closeClick = true;closeToasterMessage(item?.divId)'
      [ngClass]="{'errorTypeMessage': item.messageType=='error','successTypeMessage': item.messageType=='success'}">      
      <div class="toasterMessageIcon">
        <div class="messageInfoIconOuter">
          <span class="messageInfoIcon">
          </span>
        </div>
      </div>
      <div class="toasterMessageDescription">
        <span [innerHTML]='item?.messageToShow'>

        </span>
      </div>
    </div>
  </div>

</div>

