import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable({
  providedIn: 'root'
})
export class RcycEditorialHubArticleService {

  API_BASE_URL: any =   AppConfig.API_BASE_URL;
  ELASTIC_API_BASE_URL: any =   AppConfig.ELASTIC_API_BASE_URL;

  constructor(
    private http: HttpClient
  ) { }
  
  /**
  *Funtion to call All Elastic Apis for Special Offers Landing
  */
  multipleVoyageElasticApi(params) {
    const elasticParams = {
      countryCode: params.countryCode,
      voyageIds: params.voyageIds,
    };
    const url = this.ELASTIC_API_BASE_URL + params.apiUrl;
    return this.http.post(url, elasticParams);
  }
  
}
