<div *ngIf="isRequestEBrochureStatus" id="rcyc-page-request-brochure" class="bodyContentOuter">
    <!-- <app-rcyc-error #errorModal></app-rcyc-error> -->
    <!-- Section - 1 -->
  
    <!-- Section - 2 -->
    <section class="content_section">
      <div class="l-container-wrapper">
        <div class="l-container intro-header leadinto">
          <div class="l-container row is-8-4">
            <div class="body-copy" *ngIf="apiresponseB">
              <div class="headline-block c01_D intro-header leadinto">
                <h4 *ngIf="apiresponseB?.field_sub_header" [title]="apiresponseB?.field_sub_header | convertHtml"
                  [innerHTML]="apiresponseB?.field_sub_header"></h4>
                <h1 *ngIf="apiresponseB?.title" class="headline" [title]="apiresponseB?.title | convertHtml"
                  [innerHTML]="apiresponseB?.title"></h1>
              </div>
              <div *ngIf="apiresponseB?.field_summery" [innerHTML]="apiresponseB?.field_summery"></div>
            </div>
            <!-- left side -->
            <div class="column is-3">
              <div class="headline-block" *ngIf="apiresponseC">
                <h2 *ngIf="apiresponseC?.title" class="headline lead_form_left_head"
                  [title]="apiresponseC?.title | convertHtml" [innerHTML]="apiresponseC?.title"></h2>
              </div>
  
              <div *ngIf="apiresponseD">
                <div _ngcontent-c3="" class="feature-block flex-item" *ngFor="let item of apiresponseD">
                  <img _ngcontent-c3="" [title]="item?.field_image_title_content | convertHtml"
                    [alt]="item?.field_image_alt_content | convertHtml" class="feature-block__image"
                    [src]="item?.field_image"
                    src-fallback='assets/components/images/rcyc-request-brochure/reuqestbrochure.jpg'>
                  <h3 *ngIf="item?.title" _ngcontent-c3="" class="media-object__headline media-object__headline--large"
                    [title]="item?.title | convertHtml" [innerHTML]="item?.title"></h3>
                </div>
              </div>
            </div>
  
            <!-- right side -->
            <div class="column is-9 lead_form_right">
              <div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
                <span class="error">{{tokenErrorMsg}}</span>
              </div>
              <form id="requestEbrochureFormId" name="requestEBrochureForm" [formGroup]="lead_gen_form" novalidate
                autocomplete="off">
                <fieldset>
                  <div class="basic-form_group lead_form_field formFirstRow">
                    <!-- name_sec -->
                    <div class="row is-6-6">
                      <div class="column is-6 form_fields">
                        <label class="basic-form_label form_label basic-form_label-required">First Name</label>
                        <input id="firstNameId" class="basic-form_input basic-form_input-half" type="text"
                          formControlName="firstname" placeholder="First Name" maxlength="30">
                        <p class="errorptag"
                          *ngIf="lead_gen_form?.controls?.firstname?.invalid && (lead_gen_form?.controls?.firstname?.dirty || lead_gen_form?.controls?.firstname?.touched)">
                          <span *ngIf="lead_gen_form?.controls?.firstname?.errors?.required" class="error">This field is
                            required</span>
                          <span *ngIf="!lead_gen_form?.controls?.firstname?.errors?.required&&lead_gen_form?.controls?.firstname?.errors?.pattern"
                            class="error">Please check the name format </span>
                        </p>
                      </div>
                      <div class="column is-6 form_fields">
                        <label class="basic-form_label form_label basic-form_label-required">Last Name</label>
                        <input id="lastNameId" class="basic-form_input basic-form_input-half" type="text"
                          formControlName="lastname" placeholder="Last Name" maxlength="60">
                        <p class="errorptag"
                          *ngIf="lead_gen_form?.controls?.lastname?.invalid && (lead_gen_form?.controls?.lastname?.dirty || lead_gen_form?.controls?.lastname?.touched)">
                          <span *ngIf="lead_gen_form?.controls?.lastname?.errors?.required" class="error">This field is
                            required</span>
                          <span *ngIf="!lead_gen_form?.controls?.lastname?.errors?.required&&lead_gen_form?.controls?.lastname?.errors?.pattern"
                            class="error">Please check the name format </span>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="basic-form_group lead_form_field">
  
                    <div class="row is-6-6">
                      <div class="column is-6 form_fields">
                        <label class="basic-form_label form_label basic-form_label-required">Country/Region of
                          Residence</label>
                        <select id="selectCountryId" class="basic-form_input basic-form_select" formControlName="country">
                          <option id="selectCountryId_0" value="">Select Country/Region of Residence</option>
                          <option *ngFor="let county of countryListArray;let i=index" id="countryOptionId_{{i+1}}"
                            value="{{county.code}}">{{county.name}}</option>
                        </select>
                        <span
                          *ngIf="lead_gen_form?.controls?.country?.invalid && (lead_gen_form?.controls?.country?.dirty || lead_gen_form?.controls?.country?.touched) && lead_gen_form?.controls?.country?.errors?.required"
                          class="error">This field is required</span>
                      </div>
                      <div class="column is-6 form_fields">
                        <label class="basic-form_label form_label" [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
                          ZIP/Postal Code (Required for USA/Canada)</label>
                        <input id="zipCodeId" class="basic-form_input basic-form_input-half" type="text"
                          formControlName="zipcode" placeholder="ZIP/Postal Code" [maxlength]="zipcodeMaxLength" (input)="checkZipcodeFormat($event)">
                        <p class="errorptag"
                          *ngIf="lead_gen_form?.controls?.zipcode?.invalid && (lead_gen_form?.controls?.zipcode?.dirty || lead_gen_form?.controls?.zipcode?.touched) ">
                          <span *ngIf="lead_gen_form?.controls?.zipcode?.errors?.required" class="error">This field is
                            required</span>
                          <span *ngIf="!lead_gen_form?.controls?.zipcode?.errors?.required&&lead_gen_form?.controls?.zipcode?.errors?.pattern"
                            class="error">ZIP/Postal Code is not valid </span>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="basic-form_group lead_form_field">
                    <div class="row is-6-6">
                      <div class="column is-6 form_fields">
                        <label class="basic-form_label form_label basic-form_label-required">Email</label>
                        <input id="emailId" class="basic-form_input basic-form_input-half" type="email" maxlength="60"
                          formControlName="email" placeholder="Email">
                        <p class="errorptag"
                          *ngIf="lead_gen_form?.controls?.email?.invalid && (lead_gen_form?.controls?.email?.dirty || lead_gen_form?.controls?.email?.touched)">
                          <span *ngIf="lead_gen_form?.controls?.email?.errors?.required" class="error">This field is
                            required</span>
                          <span *ngIf="!lead_gen_form?.controls?.email?.errors?.required&&lead_gen_form?.controls?.email?.errors?.pattern" class="error">
                              <!-- Please enter a valid email address (Example: johndoe@example.com)  -->
                              Enter a valid email address (e.g. name@example.com)
                            </span>
                        </p>
                      </div>
                      <div class="column is-6 form_fields">
                        <div class="row">
                          <div class="column">
  
                              <div id="phoneFlagContainerId" class="phoneFlagContainer"
                              (clickOutside)="onClickedOutside($event)">
                              <label class="basic-form_label form_label"
                                [ngClass]="{'basic-form_label-required': phNumReqStatusDynamic}">Phone</label>
                              <div class="row is-3-9">
                                <div class="column is-3 phoneFlagOuter"                                                                                              
                                  (keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
                                  (click)="flagCodeClickStatus = !flagCodeClickStatus" tabindex='0'>
                                  <span class="flagOuter"><img [src]="userCountryDetails?.flagUrl"
                                      class="flagImg" /></span>
                                  <span class="countryCodeOuter"> {{userCountryDetails?.dial_code}}</span>
                                </div>
  
                                <!-- Country List -->
                                <div *ngIf="flagCodeClickStatus" class="countryResultOuter">
                                  <div *ngIf="flagCodeClickStatus" class="countryResultInner">
                                    <!-- <input type="text" [(ngModel)]="countrySearch" (ngModelChange)="countrySearchChanged($event)" (ngModelChange)="searchCountryChange()"> -->
                                    <div class="countrySearchOuter">
                                      <input type="text" [(ngModel)]="countrySearch"
                                        [ngModelOptions]="{standalone: true}"/>
                                    </div>
                                    <ul *ngIf="phoneDataArray.length" class="countrySearchListOuter">
                                      <li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
                                        (keyup.enter)="selectCountryClick(item)" (click)="selectCountryClick(item)"
                                        [ngStyle]="{'border-bottom': 
                                        !countrySearch && 
                                        ( 
                                          ( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
                                          ( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )                                        
                                        )
                                        ? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
                                        (focusout)="(i == (phoneDataArray?.length - 1) ) ? flagCodeClickStatus = false: '' ">
                                        <span [innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
                                        ({{item?.dial_code}})                              
                                      </li>
                                    </ul>
                                    <p class="noResultsFound letterSpacing"
                                      *ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length"> No results
                                      match "{{countrySearch}}"</p>
                                  </div>
                                </div>
                                <!-- Country List -->
  
                                <div class="column is-9 phoneNumberInputOuter">
                                  <!-- <input type="text" [(ngModel)]="phoneNumber" class="phoneNumberInput" /> -->
                                  <!-- //[textMask]="{mask: mask}" (focusout)="phoneTBFocusout()" -->
                                  <input id="phoneId" class="basic-form_input basic-form_input-half phoneNumberInput"
                                    *ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone"
                                    [(placeholder)]="userCountryDetails.defaultFormat"
                                    [maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
                                    [textMask]="{mask: mask, guide:false}"                                   
                                    />                                    
                                    <!-- [maxlength]="userCountryDetails?.ph_length + 6" #phoneNum> -->
                                  <!-- <p class="errorptag"
                                    *ngIf="lead_gen_form?.controls?.phone.invalid && (lead_gen_form?.controls?.phone.dirty || lead_gen_form?.controls?.phone.touched)">
                                    <span *ngIf="lead_gen_form?.controls?.phone.errors.required" class="phoneNoError error">This field is
                                      required</span>
                                    <span *ngIf="!lead_gen_form?.controls?.phone.errors.required&&lead_gen_form?.controls?.phone.errors.phoneIncorrect"
                                      class="phoneNoError error">Enter a valid phone number (e.g. 123 456 7890) </span>                                      
                                  </p> -->
                                </div>
                              </div>  
                              <p class="errorptag"
                                *ngIf="lead_gen_form?.controls?.phone?.invalid && (lead_gen_form?.controls?.phone?.dirty || lead_gen_form?.controls?.phone?.touched)">
                                <span *ngIf="lead_gen_form?.controls?.phone?.errors?.required" class="phoneNoError error">This field is
                                  required</span>
                                <span *ngIf="!lead_gen_form?.controls?.phone?.errors?.required&&lead_gen_form?.controls?.phone?.errors?.phoneIncorrect"
                                  class="phoneNoError error">Enter a valid phone number (e.g. {{ userCountryDetails?.defaultPhoneNumber }}) </span>                                      
                              </p>                          
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="apiresponseE" class="termsSection">
                    <div *ngIf="apiresponseE?.field_term1" class="eachTermSection">
                      <input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail">
                      <p class="termContent" [innerHTML]="apiresponseE?.field_term1"></p>
                    </div>
                    <div *ngIf="apiresponseE?.field_term2" class="eachTermSection">
                      <input id="phoneOptId" type="checkbox" value="true" formControlName="isAgreeToContact">
                      <!-- (change)="phoneReceiveCB=!phoneReceiveCB;phoneCBManual=!phoneCBManual" -->
                      <div class="termContent" [innerHtml]="apiresponseE?.field_term2"></div>
                    </div>
  
                    <div *ngIf="apiresponseE?.field_summery" class="basic-form_condition"
                      [innerHTML]="apiresponseE?.field_summery"></div>                     
                  </div>
                </fieldset>
              </form>
  
              <div class="row is-6-6">
                <!--<p class="succ" *ngIf="succmsg">THANK YOU FOR REGISTERING</p>-->
                <div class="column is-6">
                  <div class="row is-6-6">
                    <div class="column is-6 submit_btn">
                      <!-- <input type="submit" id="submitId" title="Submit" (click)="leadGenSubmit()"
                        [disabled]="lead_gen_form.invalid" value="Submit"> -->
                        <!-- <input type="submit" id="submitId" title="Submit" (click)="leadGenSubmit()"
                        [disabled]="lead_gen_form?.controls?.firstname.invalid||
                        lead_gen_form?.controls?.lastname.invalid||
                        lead_gen_form?.controls?.country.invalid||
                        lead_gen_form?.controls?.email.invalid||
                        ( (phNumReqStatusDynamic&&lead_gen_form?.controls?.phone.errors.phoneIncorrect)&& (lead_gen_form?.controls?.country.value=='US'||lead_gen_form?.controls?.isAgreeToContact.value) )||
                        (lead_gen_form?.controls?.zipcode.invalid&&zipcodeReqStatusDynamic)" value="Submit"> -->
                        <input type="submit" id="submitId" title="Submit" (click)="leadGenSubmit()"
                        [disabled]="lead_gen_form?.controls?.firstname?.invalid||
                        lead_gen_form?.controls?.lastname?.invalid||
                        lead_gen_form?.controls?.country?.invalid||
                        lead_gen_form?.controls?.email?.invalid||
                        lead_gen_form.controls?.phone?.errors?.phoneIncorrect==true ||
										    (lead_gen_form.controls?.phone?.errors?.phoneIncorrect!=false&&phNumReqStatusDynamic) ||
                        (lead_gen_form?.controls?.zipcode?.invalid&&zipcodeReqStatusDynamic)||
                        !userArea" value="Submit">

                    </div>                   
                    <div class="column is-6 clear_btn">
                      <input type="reset" id="resetId" title="Clear" (click)="ngOnInit()" value="Clear">
                    </div>
                  </div>
                </div>
                <div class="column is-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- REQUEST EBrochure in Offers & Programs STARTS -->
<div *ngIf="!isRequestEBrochureStatus" id="rcyc-request-brochure-offerPrograms">
  <section class="content_section new_content">
    <div class="l-container-wrapper">
      <div class="l-container intro-header leadinto new_pad">
        <div class="row">            
          <div class="lead_form_right">   
            <div class="formErrorSec">           
              <div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
                <span class="error">{{tokenErrorMsg}}</span>						
              </div>
              <!-- <div *ngIf="specialOfferFormErrorMsg" id="formSubmitUIErrorId">
                <span class="error" [innerHTML]="specialOfferFormErrorMsg"></span>
              </div> -->
            </div>
            <form id="requestEbrochureFormId" name="requestEBrochureForm" [formGroup]="lead_gen_form" novalidate autocomplete="off">
              <div class="row">
                <div class="headline-block" *ngIf="apiresponseC">
                  <h1 *ngIf="apiresponseC?.title" class="headline lead_form_left_head pageTitle" [title]="apiresponseC?.title | convertHtml" [innerHTML]="apiresponseC?.title"></h1>
                </div>
                <p id="requiredField"><span>*</span> Indicates required field</p>
              </div>
              <fieldset>
                <div class="row is-3-9">
                  <div class="column is-3">                                  
                    <div *ngIf="apiresponseD">
                      <div class="feature-block flex-item" *ngFor="let item of apiresponseD">
                        <img [title]="item?.field_image_title_content | convertHtml" [alt]="item?.field_image_alt_content | convertHtml"
                            class="feature-block__image ebrochureImage" [src]="item?.field_image" />                          
                      </div>
                    </div>
                  </div>
                  <div class="column is-9 inputFieldFormSec">
                      <div class="basic-form_group lead_form_field formFirstRow">
                        <div class="row is-6-6">
                          <div class="column is-6 form_fields mb-30">
                            <!-- <label class="basic-form_label form_label basic-form_label-required">First Name</label> -->
                            <input id="firstNameId" class="input-1 mb-4" type="text" required
                              formControlName="firstname"  maxlength="30" 
                              [ngClass]="{'error-borderBtm': ( lead_gen_form?.controls?.firstname?.invalid &&
                              (lead_gen_form?.controls?.firstname?.dirty || lead_gen_form?.controls?.firstname?.touched || specialOfferFormErrorMsg) )&&
                            (lead_gen_form?.controls?.firstname?.errors?.required || (!lead_gen_form?.controls?.firstname?.errors?.required&&lead_gen_form?.controls?.firstname?.errors?.pattern) )}"
                            (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
                            <p class="errorptag"
                              *ngIf="lead_gen_form?.controls?.firstname?.invalid &&
                              (lead_gen_form?.controls?.firstname?.dirty || lead_gen_form?.controls?.firstname?.touched || specialOfferFormErrorMsg)">
                              <span *ngIf="lead_gen_form?.controls?.firstname?.errors?.required" class="error">This field is
                                required</span>
                              <span *ngIf="!lead_gen_form?.controls?.firstname?.errors?.required&&lead_gen_form?.controls?.firstname?.errors?.pattern"
                                class="error">Please check the name format </span>
                            </p>
                            <label for="firstNameId" class="label-1 label_space">First Name</label>
                          </div>
                          <div class="column is-6 form_fields mb-30">
                            <!-- <label class="basic-form_label form_label basic-form_label-required">Last Name</label> -->
                            <input id="lastNameId" class="input-1 mb-4" required type="text"
                              formControlName="lastname"  maxlength="60" 
                              [ngClass]="{'error-borderBtm': ( lead_gen_form?.controls?.lastname?.invalid &&
                              (lead_gen_form?.controls?.lastname?.dirty || lead_gen_form?.controls?.lastname?.touched || specialOfferFormErrorMsg) )&&
                            ( lead_gen_form?.controls?.lastname?.errors?.required || (!lead_gen_form?.controls?.lastname?.errors?.required&&lead_gen_form?.controls?.lastname?.errors?.pattern) ) }"
                            (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
                            <p class="errorptag"
                              *ngIf="lead_gen_form?.controls?.lastname?.invalid &&
                              (lead_gen_form?.controls?.lastname?.dirty || lead_gen_form?.controls?.lastname?.touched || specialOfferFormErrorMsg)">
                              <span *ngIf="lead_gen_form?.controls?.lastname?.errors?.required" class="error">This field is
                                required</span>
                              <span *ngIf="!lead_gen_form?.controls?.lastname?.errors?.required&&lead_gen_form?.controls?.lastname?.errors?.pattern"
                                class="error">Please check the name format </span>
                            </p>
                            <label for="lastNameId" class="label-1 label_space">Last Name</label>
                          </div>
                        </div>
                      </div>
                      <div class="basic-form_group lead_form_field">          
                        <div class="row is-6-6">
                          <div class="column is-6 form_fields mb-30">                                                    
                            <!-- <label class="basic-form_label form_label basic-form_label-required">Country/Region of
                              Residence</label> -->
                            <!-- <div class="countrySelectInner"> 
                              <select id="selectCountryId" class="basic-form_input basic-form_select" formControlName="country"
                              [ngClass]="{'error-borderBtm': (lead_gen_form?.controls?.country.invalid && (lead_gen_form?.controls?.country.dirty || lead_gen_form?.controls?.country.touched) && lead_gen_form?.controls?.country.errors.required) }">
                                <option id="selectCountryId_0" value="" disabled>Select Country/Region of Residence</option>
                                <option *ngFor="let county of countryListArray;let i=index" id="countryOptionId_{{i+1}}"
                                  value="{{county.code}}">{{county.name}}</option>
                              </select>
                              <span class="icon-arrow-down"></span>
                            </div>
                            <div
                              *ngIf="lead_gen_form?.controls?.country.invalid && (lead_gen_form?.controls?.country.dirty || lead_gen_form?.controls?.country.touched) && lead_gen_form?.controls?.country.errors.required"
                              class="error">This field is required</div> -->

                              <div class="specialCountryOuter">
                                <div class="specialCountryInputOuter">
                                  <div class="inputCloseOuter">
                                    <!-- <input id="specialCountryInputId" class="basic-form_input basic-form_input-half" type="text"
                                    placeholder="Select Country/Region of Residence" maxlength="60" [(ngModel)]="specialCountry"
                                    [ngModelOptions]="{standalone: true}" (click)="specialCountryInputClickStatus=true"
                                    [ngClass]="{'error-borderBtm': lnameWhitespaceError}" /> -->
                                    <input id="specialCountryInputId" class="input-1 mb-4" required type="text"
                                     maxlength="60" formControlName="country" 
                                    (click)="specialCountryInputClickStatus=true;specialCountryInputCloseBtnStatus=true"
                                    [ngClass]="{'error-borderBtm': ( (lead_gen_form?.controls?.country?.invalid &&
                                    (lead_gen_form?.controls?.country?.dirty || lead_gen_form?.controls?.country?.touched || specialOfferFormErrorMsg))&&
                                      (lead_gen_form?.controls?.country?.errors?.required ) )
                                    ||( ( lead_gen_form?.controls?.country?.valid && (lead_gen_form?.controls?.country?.dirty || lead_gen_form?.controls?.country?.touched) ) &&
                                    !isSpecialCountrySelect)}" />
                                    <span *ngIf="specialCountryInputCloseBtnStatus" class="icon-close" (click)="specialCountryInputCloseClick()"
                                    [ngClass]="{'error-specialCountryCloseBtn': ( (lead_gen_form?.controls?.country?.invalid &&
                                    (lead_gen_form?.controls?.country?.dirty || lead_gen_form?.controls?.country?.touched || specialOfferFormErrorMsg))&&
                                    (lead_gen_form?.controls?.country?.errors?.required ) )
                                  ||( ( lead_gen_form?.controls?.country?.valid && (lead_gen_form?.controls?.country?.dirty || lead_gen_form?.controls?.country?.touched) ) &&
                                  !isSpecialCountrySelect)}"></span>
                                    
                                    <p class="errorptag"
                                      *ngIf="lead_gen_form?.controls?.country?.invalid &&
                                      (lead_gen_form?.controls?.country?.dirty || lead_gen_form?.controls?.country?.touched || specialOfferFormErrorMsg)">
                                      <span *ngIf="lead_gen_form?.controls?.country?.errors?.required" class="error">This field is
                                        required</span>													
                                    </p>
                                    <p class="errorptag"
                                      *ngIf="lead_gen_form?.controls?.country?.valid && (lead_gen_form?.controls?.country?.dirty || lead_gen_form?.controls?.country?.touched)">													
                                      <span *ngIf="!isSpecialCountrySelect" class="error">Please select a country</span>														
                                    </p>
                                    <label class="label-1 label_space" for="specialCountryInputId">Country/Region of Residence
                                    </label>
                                  </div>
                                  <div *ngIf="specialCountryInputClickStatus" class="specialCountryListOuter">
                                    <ul *ngIf="countryListArray?.length" class="specialCountryUl">
                                      <li class="specialCountryLi" *ngFor="let item of countryListArray | specialCountrynameFilter: lead_gen_form?.controls?.country?.value;let i=index"
                                        id="specialCountryListItem_{{i}}"
                                        [innerHTML]="item?.name | SpecialCountryHighlight: lead_gen_form?.controls?.country?.value"
                                        (click)="specialCountryLiClick(item);">
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                          </div>
                          <div class="column is-6 form_fields mb-30">
                            <!-- <label class="basic-form_label form_label" [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
                              ZIP/Postal Code (Required for USA/Canada)</label> -->
                            <input id="zipCodeId" class="input-1 mb-4" required type="text"
														formControlName="zipcode"  [maxlength]="zipcodeMaxLength"
                              (input)="checkZipcodeFormat($event)"
                              [ngClass]="{'error-borderBtm': ( lead_gen_form?.controls?.zipcode?.invalid &&
                              (lead_gen_form?.controls?.zipcode?.dirty || lead_gen_form?.controls?.zipcode?.touched || specialOfferFormErrorMsg) )&&
                             ( lead_gen_form?.controls?.zipcode?.errors?.required || !lead_gen_form?.controls?.zipcode?.errors?.required&&lead_gen_form?.controls?.zipcode?.errors?.pattern) }"
                             (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
                            <p class="errorptag"
                              *ngIf="lead_gen_form?.controls?.zipcode?.invalid &&
                              (lead_gen_form?.controls?.zipcode?.dirty || lead_gen_form?.controls?.zipcode?.touched || specialOfferFormErrorMsg ) ">
                              <span *ngIf="lead_gen_form?.controls?.zipcode?.errors?.required" class="error">This field is
                                required</span>
                              <span *ngIf="!lead_gen_form?.controls?.zipcode?.errors?.required&&lead_gen_form?.controls?.zipcode?.errors?.pattern"
                                class="error">ZIP/Postal Code is not valid </span>
                            </p>
                            <label class="label-1 label_space" for="zipCodeId" [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
                              ZIP/Postal Code (Required for USA/Canada)</label>
                          </div>
                        </div>
                      </div>
                      <div class="basic-form_group lead_form_field">
                        <div class="row is-6-6">
                          <div class="column is-6 form_fields mb-40" >
                            <!-- <label class="basic-form_label form_label basic-form_label-required">Email</label> -->
                            <input id="emailId" class="input-1 mb-4" required 
														type="text" maxlength="60" formControlName="email" 
                              [ngClass]="{'error-borderBtm': ( lead_gen_form?.controls?.email?.invalid &&
                              (lead_gen_form?.controls?.email?.dirty || lead_gen_form?.controls?.email?.touched || specialOfferFormErrorMsg) )&&
                             (lead_gen_form?.controls?.email?.errors?.required || (!lead_gen_form?.controls?.email?.errors?.required&&lead_gen_form?.controls?.email?.errors?.pattern)) }"
                             (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
                            <p class="errorptag"
                              *ngIf="lead_gen_form?.controls?.email?.invalid &&
                              (lead_gen_form?.controls?.email?.dirty || lead_gen_form?.controls?.email?.touched || specialOfferFormErrorMsg)">
                              <span *ngIf="lead_gen_form?.controls?.email?.errors?.required" class="error">This field is
                                required</span>
                              <span *ngIf="!lead_gen_form?.controls?.email?.errors?.required&&lead_gen_form?.controls?.email?.errors?.pattern" class="error">
                                  <!-- Please enter a valid email address (Example: johndoe@example.com) -->
                                  Enter a valid email address (e.g. name@example.com)
                              </span>
                            </p>
                            <label class="label-1 label_space" for="emailId" [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
                              Email</label>
                          </div>
                          <div class="column is-6 form_fields mb-18" >                                                     
                            
                            <div id="phoneFlagContainerId" class="column is-6 phoneFlagContainer "
                              (clickOutside)="onClickedOutside($event)">
                              <!-- <label class="basic-form_label form_label"
                                [ngClass]="{'basic-form_label-required': phNumReqStatusDynamic}">Phone</label> -->
                              <div class="row is-3-9">
                                <div class="column is-3 new_flag phoneFlagOuter"                                                                                             
                                  (keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
                                  (click)="flagCodeClickStatus = !flagCodeClickStatus;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" tabindex='0'
                                  [ngClass]="{'error-borderBtm': ( lead_gen_form?.controls?.phone?.invalid &&
                                  (lead_gen_form?.controls?.phone?.dirty || lead_gen_form?.controls?.phone?.touched || specialOfferFormErrorMsg) )&&
                                   (lead_gen_form?.controls?.phone?.errors?.required || (!lead_gen_form?.controls?.phone?.errors?.required&&lead_gen_form?.controls?.phone?.errors?.phoneIncorrect))}"
                                   >
                                  <span class="flagOuter"><img [src]="userCountryDetails?.flagUrl"
                                      class="flagImg" /></span>
                                  <span class="countryCodeOuter"> {{userCountryDetails?.dial_code}}</span>
                                </div>

                                <!-- Country List -->
                                <div *ngIf="flagCodeClickStatus" class="countryResultOuter">
                                  <div *ngIf="flagCodeClickStatus" class="countryResultInner">
                                    <!-- <input type="text" [(ngModel)]="countrySearch" (ngModelChange)="countrySearchChanged($event)" (ngModelChange)="searchCountryChange()"> -->
                                    <div class="countrySearchOuter">
                                      <input type="text" [(ngModel)]="countrySearch"
                                        [ngModelOptions]="{standalone: true}" style="border-style: solid;
                                        border-width: thin;border-color: black;"/>
                                    </div>
                                    <ul *ngIf="phoneDataArray.length" class="countrySearchListOuter">
                                      <li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
                                        (keyup.enter)="selectCountryClick(item)" (click)="selectCountryClick(item)"
                                        [ngStyle]="{'border-bottom': 
                                        !countrySearch && 
                                        ( 
                                          ( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
                                          ( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )                                        
                                        )
                                        ? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
                                        (focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
                                        <span [innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
                                        ({{item?.dial_code}})                              
                                      </li>
                                    </ul>
                                    <p class="noResultsFound letterSpacing"
                                      *ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length"> No results
                                      match "{{countrySearch}}"</p>
                                  </div>
                                </div>
                                <!-- Country List -->

                                <div class="column is-9 phoneNumberInputOuter">
                                  <!-- <input type="text" [(ngModel)]="phoneNumber" class="phoneNumberInput" /> -->
                                  <!-- //[textMask]="{mask: mask}" (focusout)="phoneTBFocusout()" -->
                                  <!-- <input id="phoneId" class="input-1"   required style="border-left-color: white;margin-bottom: 4px;"
                                    *ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone"
                                    [(placeholder)]="userCountryDetails.defaultFormat"
                                    [maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
                                    [ngClass]="{'error-borderBtm': ( lead_gen_form?.controls?.phone?.invalid &&
                                    (lead_gen_form?.controls?.phone?.dirty || lead_gen_form?.controls?.phone?.touched || specialOfferFormErrorMsg) )&&
                                   (lead_gen_form?.controls?.phone?.errors?.required || (!lead_gen_form?.controls?.phone?.errors?.required&&lead_gen_form?.controls?.phone?.errors?.phoneIncorrect))}"
                                   (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
                                   (input) = "phoneValueChangeFn()"
                                   [textMask]="{mask: mask, guide:false}"/>  -->
                                   <input id="phoneId" class="input-1 mb-4"   required style="border-left-color: white;"
                                   *ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone"
                                   [maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
                                   [ngClass]="{'error-borderBtm': ( lead_gen_form?.controls?.phone?.invalid &&
                                   (lead_gen_form?.controls?.phone?.dirty || lead_gen_form?.controls?.phone?.touched || specialOfferFormErrorMsg) )&&
                                  (lead_gen_form?.controls?.phone?.errors?.required || (!lead_gen_form?.controls?.phone?.errors?.required&&lead_gen_form?.controls?.phone?.errors?.phoneIncorrect))}"
                                  (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
                                  (input) = "phoneValueChangeFn()"
                                  [textMask]="{mask: mask, guide:false}"/>      
                                   <label class="label-1 label_space" for="phoneId" style="left: 98px;" [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
                                    Phone</label>                              
                                </div>
                                
                              </div>   
                              <p class="errorptag"
                                *ngIf="lead_gen_form?.controls?.phone?.invalid &&
                                (lead_gen_form?.controls?.phone?.dirty || lead_gen_form?.controls?.phone?.touched || specialOfferFormErrorMsg)">
                                <span *ngIf="lead_gen_form?.controls?.phone?.errors?.required" class="phoneNoError error">This field is
                                  required</span>
                                <span *ngIf="!lead_gen_form?.controls?.phone?.errors?.required&&lead_gen_form?.controls?.phone?.errors?.phoneIncorrect"
                                  class="phoneNoError error">Enter a valid phone number (e.g. {{ userCountryDetails?.defaultPhoneNumber }}) </span>
                              </p>                         
                            </div>
                            
                          </div>
                        </div>
                      </div>
                  </div>
                </div>                  
                <div *ngIf="apiresponseE" class="termsSection">
                  <div *ngIf="apiresponseE?.field_term2 && showPhoneopt" class="eachTermSection phoneTermSection"> 
                    <input id="phoneOptId" type="checkbox"  value="true" formControlName="isAgreeToContact"
                    (change)="phoneOptValueChanged();specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false">
                    <div class="termContent" *ngIf="!showPhoneOptRequiredText" [innerHtml]="apiresponseE?.field_term2"></div>
                    <div class="termContent" *ngIf="showPhoneOptRequiredText" [innerHtml]="apiresponseE?.field_term2_required"></div>
                  </div>
                  <p class="errorptag marketingError" *ngIf="showPhoneOptRequiredText">
                    <span class="emailError error" [innerHtml] = "drupalErrorMessages?.field_phone_opt_in_required"></span>
                  </p>
                  <div *ngIf="apiresponseE?.field_term1" class="eachTermSection">                     
                    <input id="emailOptId" type="checkbox"  [checked]="emailReceiveCB"  formControlName="isAgreeToReceiveMail"
                    (change)="emailReceiveCB=!emailReceiveCB;emailCBManual=!emailCBManual;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false">
                    <p class="termContent" [innerHTML]="apiresponseE?.field_term1"></p>
                  </div>                    
                  <div *ngIf="apiresponseE?.field_summery && !showTermUS" class="basic-form_condition declaration" [innerHTML]="apiresponseE?.field_summery"></div>
                  <div *ngIf="apiresponseE?.field_summary_us && showTermUS" class="basic-form_condition declaration" [innerHTML]="apiresponseE?.field_summary_us"></div>
                </div>
  
              </fieldset>
            </form>              
            <div class="row">
              <div *ngIf = "!submitLoading" class="submit_btn specialREBSubmitBtn">
                <!-- <input type="submit" id="submitId" title="Submit" (click)="leadGenSubmit()"
                [disabled]="lead_gen_form.invalid||
                !isSpecialCountrySelect" value="Submit"> -->
                <!-- <input type="submit" id="requestBrochureSubmitId" (click)="leadGenSubmit()"
											 [disabled]="lead_gen_form?.controls?.firstname?.invalid ||
											 lead_gen_form?.controls?.lastname?.invalid ||
											 lead_gen_form?.controls?.country?.invalid || 
											 lead_gen_form?.controls?.email?.invalid ||
											 lead_gen_form?.controls?.zipcode?.invalid ||
											 lead_gen_form.controls?.phone?.errors?.phoneIncorrect==true ||
										 (lead_gen_form.controls?.phone?.errors?.phoneIncorrect!=false&&phNumReqStatusDynamic) ||
                       !userArea || !isSpecialCountrySelect" value="Submit"> -->
                    <input type="submit" id="requestBrochureSubmitId" (click)="leadGenSubmit()"
											 value="Submit">
              </div>                         
              <div *ngIf = "submitLoading" class="specialREBSubmitBtn">
                <div class="submitLoader">
                  <img src="assets/components/images/loading.gif">
                </div> 
              </div>	
     
            </div>                
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- REQUEST EBrochure in Offers & Programs ENDS -->