import {Inject, Injectable} from '@angular/core';

import { BehaviorSubject ,  Subject ,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';
import { Title, Meta } from '@angular/platform-browser';

import {guestConfiguration} from '../../rcyc-share-data/guest-page-configuration'
import {DOCUMENT} from '@angular/common';
import { map } from 'rxjs/operators';

declare let ga: any;
declare let gtag: any;

@Injectable()
export class RcycCommonService {

  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  gaApiUrl: any;
  API_BASE_URL: any = AppConfig.API_BASE_URL;
  GOOGLE_API_KEY: any = AppConfig.GOOGLE_API_KEY;
  IPDATA_API_KEY: any = AppConfig.IPDATA_API_KEY;

  metaTagApiResponseTemp: any;
  googleAnalyticsResponseTemp: any;

  private subHeader = new BehaviorSubject<boolean>(false);
  subHeaderStatus = this.subHeader.asObservable();

  private mainHeader = new BehaviorSubject<boolean>(false);
  mainHeaderStatus = this.mainHeader.asObservable();

  private channelPassTid = new BehaviorSubject<number>(0);
  channelsTid = this.channelPassTid.asObservable();

  private subHeaderDataPassArray_A = new BehaviorSubject<Array<string[]>>([]);
  subHeaderDataArray_A = this.subHeaderDataPassArray_A.asObservable();

  private currentComponentPass = new BehaviorSubject<string>('componentName');
  currentComponent = this.currentComponentPass.asObservable();

  private currentUsernamePass = new BehaviorSubject<string>('currentUserName');
  currentUserName = this.currentUsernamePass.asObservable();

  private countryPhoneNoPass = new BehaviorSubject<string>('countryPhoneNo');
  countryPhoneNo = this.countryPhoneNoPass.asObservable();

  // private clickToChatUrlPass = new BehaviorSubject<string>('clickToChat');
  // clickToChatUrl = this.clickToChatUrlPass.asObservable();

  private userLocationDetailsPassArray = new BehaviorSubject<Array<string[]>>([]);
  userLocationDetails = this.userLocationDetailsPassArray.asObservable();

  public cookieConsent = new Subject<string>();
  public countyCode = new Subject<string>();
  public loginGuest = new Subject<string>();
  public loginEmail = new Subject<string>();
  public gdprStatus = new BehaviorSubject<boolean>(false);
  public currentGdprStatus = this.gdprStatus.asObservable();
  public loginStatus = new Subject<boolean>();
  public currentloginStatus = this.loginStatus.asObservable();
  public sessionStatus = new BehaviorSubject<boolean>(false);
  public currentSessionStatus = this.sessionStatus.asObservable();

  public acknowledgePreContractPass = new BehaviorSubject<boolean>(false);
  acknowledgePreContract = this.acknowledgePreContractPass.asObservable();

  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable(); }


  /*private currentUrlPathPassArray=	new BehaviorSubject<Array<string[]>>([]);
  currentUrlPathDataArray=	this.currentUrlPathPassArray.asObservable();*/
  location: any;
  callOUtTemp: any;
  mappedArray: any;
  apicalled: any;
  removedIdforVoyage: any;
  nid: any;
  apiResponseImageData;
  canonicalLink: HTMLLinkElement;

  public backFunctionalityFlag = new BehaviorSubject<boolean>(false);
  backFunctionalityFlagDetails = this.backFunctionalityFlag.asObservable();

  public areaCode = new BehaviorSubject<string>('userAreaCode');
  public userAreaCode = this.areaCode.asObservable();

  private returnToReservationPassArray = new BehaviorSubject<Array<string[]>>([]);
  returnToReservationArray = this.returnToReservationPassArray.asObservable();

  private gobackReturnToReservationPassArray = new BehaviorSubject<Array<string[]>>([]);
  gobackReturnToReservationArray = this.gobackReturnToReservationPassArray.asObservable();

  private dirtyFormIndocumnetflow = new BehaviorSubject<Array<string[]>>([]);
  dirtyFormIndocumnetflowData = this.dirtyFormIndocumnetflow.asObservable();

  private gobackToAnyPassArray = new BehaviorSubject<Array<string[]>>([]);
  gobackToAnyArray = this.gobackToAnyPassArray.asObservable();

  private headerMenuPassArray = new BehaviorSubject<Array<string[]>>([]);
  headerMenuArray = this.headerMenuPassArray.asObservable();

  private routedComponentPassArray = new BehaviorSubject<Array<string[]>>([]);
  routedComponentArray = this.routedComponentPassArray.asObservable();

  private infoMessageSource$ = new BehaviorSubject<any>(null);
  currentInfoMessageSource$ = this.infoMessageSource$.asObservable();

  private errorMessageSource$ = new BehaviorSubject<any>(null);
  currentErrorMessageSource$ = this.errorMessageSource$.asObservable();

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc
    // private rcycDecodeHtmlPipe: RcycDecodeHtmlPipe,
    // private _renderer2: Renderer2,
    // private _document: DOCUMENT,
    // private gtag: Gtag
  ) { }

  setErrorMessageSource(value) {
    this.errorMessageSource$.next(value);
  }

  getErrorMessages() {
    return this.currentErrorMessageSource$;
  }

  notifyUserAction() {
    this._userActionOccured.next();
  }
  
  setInfoMessageSource(value) {
    this.infoMessageSource$.next(value);
  }

  getInfoMessages() {
    return this.currentInfoMessageSource$;
  }

  // Function to Change subHeader status
  changeSubHeaderStatus(status) {
    this.subHeader.next(status);
  }

  // Function to Change mainHeader status
  changeMainHeaderStatus(status) {
    this.mainHeader.next(status);
  }

  // Function to Change channels tid
  changeChannelsTid(tid) {
    this.channelPassTid.next(tid);
  }

  // Function to Change changeSubHeaderDataArray tid
  changeSubHeaderDataArray_A(params) {
    this.subHeaderDataPassArray_A.next(params);
  }
  setcookieConsent(value: string) {
    this.cookieConsent.next(value);
  }
  setGDPRStatus(value: boolean) {
    this.gdprStatus.next(value);
  }

  setLoggedInStatus(value: boolean) {
    this.loginStatus.next(value);
  }

  setSessionExpiredStatus(value: boolean) {
    this.sessionStatus.next(value);
  }

  setcountryCode(value: string) {
    this.countyCode.next(value);
  }

  GLoginStatus(value: string) {
    this.loginGuest.next(value);
  }

  GLoginEmail(value: string) {
    this.loginEmail.next(value);
  }

  // Function to Change  currentComponent
  changeCurrentComponent(params) {
    this.currentComponentPass.next(params);
  }
  // Function to Change  currentUserName
  changeCurrentUser(params) {
    this.currentUsernamePass.next(params);
  }
  // Function to Change  countryPhoneNo
  changeCountryPhoneNo(params) {
    this.countryPhoneNoPass.next(params);
  }

  // Function to Change  clickToChatUrl
  // changeClickToChat(params) {
  //   this.clickToChatUrlPass.next(params);
  // }

  // Function to Change  clickToChatUrl
  changeUserLocationDetails(params) {
    this.userLocationDetailsPassArray.next(params);
  }

  // EU-preContract Back
  setAcknowledgePreContract(value: boolean) {
    this.acknowledgePreContractPass.next(value);
  }

  // Function to Change return to reservation
  changeReturnToReservationArray(params) {
    this.returnToReservationPassArray.next(params);
  }

  // Function to Change return to reservation
  changeGobackReturnToReservationArray(params) {
    this.gobackReturnToReservationPassArray.next(params);
  }

  // Function to Change return to reservation
  setDirtyFormIndocumnetflow(params) {
    this.dirtyFormIndocumnetflow.next(params);
  }

  // Function to Change return to reservation
  changeGobackToAnyArray(params) {
    this.gobackToAnyPassArray.next(params);
  }

  // Function to Change header menus according to login
  changeHeaderMenuArray(params) {
    this.headerMenuPassArray.next(params);
  }

  // Function to Change header menus according to login
  changeroutedComponentArray(params) {
    this.routedComponentPassArray.next(params);
  }

  public setData(key: string, data: any) {
    if (key === 'UserDetails') {
      data.expirefrom = new Date();
    }

    localStorage.setItem(key, JSON.stringify(data));
  }

  public getData(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public setSessionData(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  public getSessionData(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  public removeSessionData(key: string) {
    sessionStorage.removeItem(key);
  }

  public expireLocation() {
    const UserDetails = this.getData('UserDetails');
    const endDate = new Date();

    const startDate = new Date(UserDetails.expirefrom);
    const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds > 7200) {

      localStorage.removeItem('UserDetails');
    }
  }

  getPagignationArray(arry, perPage, page) {
    const PagesArry = [];
    for (let i = 1; i <= Math.ceil(arry / perPage); i++) {
      PagesArry.push(i);
    }
    const returnObj = {
      pages: PagesArry,
      totalpageno: Math.ceil(arry / perPage)
    };
    return returnObj;
  }
  /* =======================================================================================================================================
    Function Service For pagination
 ======================================================================================================================================== */

  getPager(totalItems: number, currentPage: number = 1, pageSize: number) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 3) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        // first 3 pages
        startPage = 1;
        // endPage = 10;
        endPage = 4;
      } else if (currentPage + 2 >= totalPages) {
        // middle  pages
        startPage = totalPages - 3;
        endPage = totalPages;
      } else {
        // last 3 pages
        startPage = currentPage;
        endPage = currentPage + 3;
        // console.log(startPage);
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = this.getRange(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  /**
   * @description function to replace _.range()
   * @param start 
   * @param end 
   * @returns Array of integer starting from variable 'start' and ends with variable 'end'
   */
  getRange(start: number, end: number) {
    return [...Array(end - start + 1).keys()].map((x) => x + start);
  }

  /* =======================================================================================================================================
    Function to call Meta Tags
 ======================================================================================================================================== */
  getMetaTags(apiUrl) {

    if (!apiUrl) {

      // Get default meta key values
      this.defaultMetaKeyValues();

    } else {

      /****************** Meta tags ********************/
      this.drupalApisGet(apiUrl).subscribe(
        response => {

          this.metaTagApiResponseTemp = response;
          if (this.metaTagApiResponseTemp && this.metaTagApiResponseTemp.length) {

            const res = Object.keys(response).map(function (key) {
              return response[key];
            });

            if (res.length) {

              const item = res[0];
              this.nid = item.nid;
              let metaTitle = this.decodeHtml(item.field_meta_title);
              if (metaTitle) {
                this.titleService.setTitle(metaTitle);
                this.meta.updateTag({ property: 'og:title', content: metaTitle });
                this.meta.updateTag({ property: 'twitter:title', content: metaTitle });
              }

              const metaDescription = this.decodeHtml(item.field_meta_description);
              if (metaDescription) {
                this.meta.updateTag({ name: 'description', content: metaDescription });
                this.meta.updateTag({ property: 'og:description', content: metaDescription });
                this.meta.updateTag({ property: 'twitter:description', content: metaDescription });
              }

              const metaImage = item.field_meta_image;
              if (metaImage) {
                this.meta.updateTag({ property: 'og:image', content: this.IMG_BASE_URL + metaImage });
                this.meta.updateTag({ property: 'twitter:image', content: this.IMG_BASE_URL + metaImage });
              }

              const metaKeywords = this.decodeHtml(item.field_meta_keywords);
              if (metaKeywords) {
                this.meta.updateTag({ name: 'keywords', content: metaKeywords });
              }

              this.meta.updateTag({ property: 'og:url', content: window.location.href });
              this.meta.updateTag({ property: 'twitter:site', content: window.location.href });

              const canonicalUrl = item.field_canonical;
              if (this.canonicalLink === undefined) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.doc.head.appendChild(this.canonicalLink);
              }
              if (canonicalUrl) {
                this.canonicalLink.setAttribute('href', canonicalUrl);
              } else {
                this.canonicalLink.setAttribute('href', this.doc.URL.split('?')[0]);
              }

            } else {

              // Get default meta key values
              this.defaultMetaKeyValues();

            }
          } else {

            // Get default meta key values
            this.defaultMetaKeyValues();
          }
        },
        err => {

          console.log('Metatag Api error');
          console.log(err);
        }
      );
    }
  }

  getNid() {
    return this.nid;
  }


  /* ======================================================================================================================================
    Funtion for default metaKey values
  * ======================================================================================================================================*/

  defaultMetaKeyValues() {

    this.meta.updateTag({ property: 'twitter:site', content: window.location.href });
    this.meta.updateTag({ property: 'og:url', content: window.location.href });
  }

  /* ======================================================================================================================================
    Funtion to call All Apis from Drupal
  * ======================================================================================================================================*/

  drupalApisGet(apiUrl: string) {
    const url = this.API_BASE_URL + apiUrl;
    return this.http.get(url);
  }

  /* =======================================================================================================================================
   Function to grouping elements in Json array by multiple properties
 ======================================================================================================================================== */
  groupByMultipleProp(array, f) {
    const groups = {};
    array.forEach(function (o) {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });

    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  /* =======================================================================================================================================
   Function to Order elements in Json array
 ======================================================================================================================================== */
  orderByProp(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }


  /* =======================================================================================================================================
   Function to check whether Vimeo is available
  ==================================================================================================================== */

  getUser_Details() {
    const url = 'https://api.ipregistry.co/';
    const key = 'che3irul9zgxryky';
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `ApiKey ${key}`,
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: any) => {
        return {
          ...res,
          country_code: res.location.country.code,
          continent_code: res.location.continent.code,
        };
      })
    );
  }

  //  Function to check whether Herovideo is available or Vimeo api is broken

  checkHeroVideoUrl(url) {
    const hostedUrl = 'https://player.vimeo.com/video/' + url.match(/.*\/([^,?]+)/)[1];
    return this.http.get('https://vimeo.com/api/oembed.json?url=' + encodeURIComponent(hostedUrl), { observe: 'response' });
  }

  /**
   * Get user country details from google API
   */

  getUser_G_Details() {

    // 	let url="https://maps.googleapis.com/maps/api/geocode/json?key="+this.GOOGLE_API_KEY+"&latlng="+lat+","+log+"&sensor=false";
    const url = 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + this.GOOGLE_API_KEY;

    let body: any;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, body, { headers: headers });


    // return address;

  }

  getUser_L_Details(lat, log) {

    const url = 'https://maps.googleapis.com/maps/api/geocode/json?key=' + this.GOOGLE_API_KEY + '&latlng=' +
      lat + ',' + log + '&sensor=false';
    // let url="https://www.googleapis.com/geolocation/v1/geolocate?key="+this.GOOGLE_API_KEY;
    return this.http.get(url);

  }

  // getCareerJobData(url) {
  //   return this.http.get(url);
  // }
  /* =======================================================================================================================================
    Function to get country by passing lat and long
 ======================================================================================================================================== */

  getUserLocation(params) {
    const lat = params.latitude;
    const long = params.longitude;
    const url =
      'https://maps.googleapis.com/maps/api/geocode/json?key=' +
      this.GOOGLE_API_KEY +
      '&latlng=' +
      lat +
      ',' +
      long +
      '&sensor=false';
    return this.http.get(url);
    // return address;
  }

  // to set cookies
  setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

//get a cookies
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

// Parse the URL
  getParameterByName(name) {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  /* =======================================================================================================================================
    Function to call Google Analytics Api
 ======================================================================================================================================== */

 gaApiCall() {
  this.gaApiUrl = '/api/v1/googleanalytics?_format=json';

  if (document.getElementById('rcycGtag')) {
    // Remove if rcycGtag already exists
    const element = document.getElementById('rcycGtag');
    element.parentNode.removeChild(element);
  }

  if (document.getElementById('rcycGtagAsync')) {
    // Remove if rcycGtagAsync already exists
    const element = document.getElementById('rcycGtagAsync');
    element.parentNode.removeChild(element);
  }

  if (document.getElementById('rcycGtmId')) {
    // Remove if rcycGtmId already exists
    const element = document.getElementById('rcycGtmId');
    element.parentNode.removeChild(element);
  }

  if (document.getElementById('rcycGtmIda')) {
    // Remove if rcycGtmIda already exists
    const element = document.getElementById('rcycGtmIda');
    element.parentNode.removeChild(element);
  }


 // setTimeout(() => {
    /******************Google Analytics********************/

    this.drupalApisGet(this.gaApiUrl).subscribe(
      response => {

        this.googleAnalyticsResponseTemp = response;
        if (this.googleAnalyticsResponseTemp && this.googleAnalyticsResponseTemp.length) {

          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            const factor = res[0];

            // GA
            if (factor.field_enable_analytics === '1') {

              // const gaToken = factor.name;
              // ga('create', gaToken, 'auto');
              // ga('create', 'UA-117373511-1', 'auto');
              // ga('set', 'page', event.urlAfterRedirects);
              // ga('send', 'pageview');
              const gaTokenId = factor.field_google_analytics_code;
              if (document.getElementById('rcycGtag')) {
                // Remove if rcycGtag already exists
                const element = document.getElementById('rcycGtag');
                element.parentNode.removeChild(element);
              }

              if (document.getElementById('rcycGtagAsync')) {
                // Remove if rcycGtag already exists
                const element = document.getElementById('rcycGtagAsync');
                element.parentNode.removeChild(element);


                const goptId = factor.field_google_optimize_code;
                const scriptGtagFirst = document.createElement('script');
                scriptGtagFirst.async = true;
                scriptGtagFirst.id = 'rcycGtagAsync';
                scriptGtagFirst.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTokenId + '';

                const scriptGtagSecond = document.createElement('script');
                if (factor.field_enable_google_optimize === '1') {
                  scriptGtagSecond.innerHTML =
                  `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('require', '` + goptId + `')
                  gtag('config', '` + gaTokenId + `', { 'optimize_id': '` + goptId + `', 'send_page_view': false })`;
                } else if (factor.field_enable_google_optimize === '0') {
                  scriptGtagSecond.innerHTML =
                  `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '` + gaTokenId + `', { 'send_page_view': false })`;
                }
                scriptGtagSecond.id = 'rcycGtag';
                document.getElementsByTagName('head')[0].appendChild(scriptGtagFirst);
                document.getElementsByTagName('head')[0].appendChild(scriptGtagSecond);
                // this.gtag.event('testAction', {'event_category': 'testCategory', 'event_label': 'testLabel', 'value' : 10 });
                const title = document.getElementsByTagName('title')[0].innerHTML;
                // this.gtag.pageview({
                //   'page_title': title,
                //   'page_path': window.location.pathname,
                //   'page_location': window.location.href
                // });
                gtag('config', gaTokenId, {
                  'page_title': title,
                  'page_path': window.location.pathname,
                  'page_location': window.location.href
                });
              } else {
                const goptId = factor.field_google_optimize_code;
                const scriptGtagFirst = document.createElement('script');
                scriptGtagFirst.async = true;
                scriptGtagFirst.id = 'rcycGtagAsync';
                scriptGtagFirst.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTokenId + '';

                const scriptGtagSecond = document.createElement('script');
                if (factor.field_enable_google_optimize === '1') {
                  scriptGtagSecond.innerHTML =
                  `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('require', '` + goptId + `')
                  gtag('config', '` + gaTokenId + `', { 'optimize_id': '` + goptId + `', 'send_page_view': false })`;
                } else if (factor.field_enable_google_optimize === '0') {
                  scriptGtagSecond.innerHTML =
                  `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '` + gaTokenId + `', { 'send_page_view': false })`;
                }
                scriptGtagSecond.id = 'rcycGtag';
                document.getElementsByTagName('head')[0].appendChild(scriptGtagFirst);
                document.getElementsByTagName('head')[0].appendChild(scriptGtagSecond);
                // this.gtag.event('testAction', {'event_category': 'testCategory', 'event_label': 'testLabel', 'value' : 10 });
                const title = document.getElementsByTagName('title')[0].innerHTML;
                // this.gtag.pageview({
                //   'page_title': title,
                //   'page_path': window.location.pathname,
                //   'page_location': window.location.href
                // });
                gtag('config', gaTokenId, {
                  'page_title': title,
                  'page_path': window.location.pathname,
                  'page_location': window.location.href
                });
              }

            }

            // GTM
            if (factor.field_enable_google_tag_manager === '1') {

              const gtmToken = factor.field_google_tag_manager_code;

              // document.addEventListener("DOMContentLoaded", function() {

              if (document.getElementById('rcycGtmId')) {
                // Remove if rcycGtmId already exists
                const element = document.getElementById('rcycGtmId');
                element.parentNode.removeChild(element);
              }
              if (document.getElementById('rcycGtmIda')) {
                // Remove if rcycGtmId already exists
                const element = document.getElementById('rcycGtmIda');
                element.parentNode.removeChild(element);
              }

              // document.head.appendChild(script);
              const script = document.createElement('script');
              script.id = 'rcycGtmIda';
              script.innerHTML =
              `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.id='rcycGtmId';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '` +
                gtmToken +
                `')`;
              document.getElementsByTagName('head')[0].appendChild(script);
              // console.log('head', document.getElementsByTagName('head')[0]);
              // });

            }
          }
        }
        /**********************************************************
         //GA Cookies for Campaign
         ********************************************************/
        // Give the URL parameters variable names
        let utm_source = this.getParameterByName('utm_source');
        let utm_medium = this.getParameterByName('utm_medium');
        let utm_campaign = this.getParameterByName('utm_campaign');
        let int_source = this.getParameterByName('int_source');
        let int_campaign = this.getParameterByName('int_campaign');
        let fol_num = this.getParameterByName('fol_num');
        let gclid = this.getParameterByName('gclid');
        let fbcild = this.getParameterByName('fbcild');

        //logic
        if (!this.getCookie('gclid')) {
          gclid ? this.setCookie('gclid',gclid,730) : '';
        }
        if (!this.getCookie('fbcild')) {
          fbcild ? this.setCookie('fbcild',fbcild,730) : '';
        }

        let campaignType = ''
        if ((utm_source || utm_medium || utm_campaign) && (int_source || int_campaign || fol_num)) {
          campaignType = 'bothIntAndExt'
        } else if (utm_source || utm_medium || utm_campaign) {
          campaignType = 'external'
        } else if (int_source || int_campaign || fol_num) {
          campaignType = 'internal'
        }

        if (campaignType == 'external' || campaignType == 'bothIntAndExt') {
          utm_source ? this.setCookie('utm_source',utm_source,730) : '';
          utm_medium ? this.setCookie('utm_medium',utm_medium,730) : '';
          utm_campaign ? this.setCookie('utm_campaign',utm_campaign,730) : '';
          int_source ? this.setCookie('int_source', int_source, 730) : '';
          int_campaign ? this.setCookie('int_campaign', int_campaign, 730) : '';
          fol_num ? this.setCookie('fol_num', fol_num, 730) : '';
        } else if (campaignType == 'internal') {
            int_source ? this.setCookie('int_source', int_source, 730) : '';
            int_campaign ? this.setCookie('int_campaign', int_campaign, 730) : '';
            fol_num ? this.setCookie('fol_num', fol_num, 730) : '';
        } else {
          if (this.getCookie('utm_source') || this.getCookie('utm_medium') || this.getCookie('utm_campaign')) {
            if (this.getCookie('utm_medium') == 'referral' || this.getCookie('utm_medium') == 'direct') {
              if (document.referrer) {
                this.setCookie('utm_source', document.referrer, 730);
                this.setCookie('utm_medium', 'referral', 730);
              } else {
                this.setCookie('utm_source', '(direct)', 730);
                this.setCookie('utm_medium', '(direct)', 730);
              }
            }
          } else {
            if (document.referrer) {
              this.setCookie('utm_source', document.referrer, 730);
              this.setCookie('utm_medium', 'referral', 730);
            } else {
              this.setCookie('utm_source', '(direct)', 730);
              this.setCookie('utm_medium', '(direct)', 730);
            }
          }
        }

      },
      err => {
        console.log('Google analytics Drupal Api Error');
        console.log(err);
      }
    );
  // }, 5000);
}

  /* ======================================================================================================================================
    Funtion to call image name split
  * ======================================================================================================================================*/

  imageNameAltSplit(imageName) {
    let imgTitle = imageName.split('/');
    imgTitle = imgTitle[imgTitle.length - 1];
    imgTitle = imgTitle.replace(/.([a-zA-Z0-9]+)$/, ''); // Avoid Last .
    imgTitle = imgTitle.replace(/_([a-zA-Z0-9]+)$/, ''); // Avoid last _
    imgTitle = imgTitle.replace(/[^A-Z0-9]+/gi, ' '); // Replace all special characters with " "
    return imgTitle;
  }

  /* ======================================================================================================================================
   Funtion for html decoding
 * ======================================================================================================================================*/
  decodeHtml(value) {
    if (value) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = value;
      return tempElement.textContent;
    } else {
      return ' ';
    }
  }

  /* ======================================================================================================================================
  Funtion for Getting Call Out Text from Drupal
* ======================================================================================================================================*/

  getAllCallOut(api, id) {
    this.apicalled = api;
    switch (api) {
      case 'special': this.gaApiUrl = '/api/v1/callout/offers/' + id + '?_format=json';
        break;
      case 'all': this.gaApiUrl = '/api/v1/callout/all?_format=json';
        break;
      case 'voyages': this.gaApiUrl = '/api/v1/callout/voyage/' + id + '?_format=json';
        break;
    }
    this.drupalApisGet(this.gaApiUrl).subscribe(
      response => {
        const temp: any = response;
        if (temp) {
          const originalVoyageId = [];
          this.callOUtTemp = temp;
          temp.map((key) => {
            if (api === 'voyages') {
              originalVoyageId.push(key.field_special_offer_reference.split(','));
            } else {
              originalVoyageId.push(key.field_voyages.split(','));
            }
          });
          const voyageIds = [].concat.apply([], originalVoyageId);
          const removeDuplicatedId = voyageIds.filter(this.onlyUnique);
          const voyageIdValues = [];
          removeDuplicatedId.map((key) => {
            voyageIdValues.push(key.trim());
          });
          this.removedIdforVoyage = voyageIdValues;
          this.mappedArray = new Map();
          for (let i = 0; i < voyageIdValues.length; i++) {
            this.mappedArray.set(voyageIdValues[i], this.getFilteredArray(voyageIdValues[i]));
          }
        }
      },
      err => {
        console.log('Call Out Drupal Api Error');
        console.log(err);
      });
  }

  getIdValues() {
    return this.removedIdforVoyage;
  }


  getOffersVoyageSortedOutField(voyageId) {
    if (!this.mappedArray) {
      return null;
    }
    const temp = this.mappedArray.get(voyageId);
    let max = 0;
    let keyval;
    const arr = [];
    if (temp) {
      temp.map((v, k) => {
        let checkparam = '';
        if (this.apicalled === 'voyages') {
          checkparam = v.field_sort_order_1;
        } else {
          checkparam = v.field_sort_order;
        }
        if (max < +checkparam && v.field_appear_on_voyage_listing === '1') {
          max = +checkparam;
          keyval = k;
        }
      });
      arr.push(temp[keyval]);
    }
    return arr;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getFilteredArray(id) {
    const filteredArray = this.callOUtTemp.map((key) => {
      let paramCheck: any;
      if (this.apicalled === 'voyages') {
        paramCheck = key.field_special_offer_reference.indexOf(id);
      } else {
        paramCheck = key.field_voyages.indexOf(id);
      }
      if (paramCheck !== -1) {
        return key;
      }
    }).filter(function (el) {
      return el != null;
    });
    return filteredArray;
  }

  // Set Flag for Back Functionality - Voyage List
  setBackFunctionalityFlag(params) {
    this.backFunctionalityFlag.asObservable();
  }
  /* ====================================================================
    Function to remove duplicates from a json array
 ======================================================================== */
  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  // set  thumbnail image in shore ex
  setImages(data, portData, isShoreExcursionsComp?) {
    if (data && data.length && portData && portData.length) {
      this.apiResponseImageData = portData;
      data.map((imageData) => {
        this.apiResponseImageData.map((contentData) => {
          const imageField: string = isShoreExcursionsComp ? 'field_image' : 'field_thumbnail';
          if ( contentData['tourCd'].slice(0, 8) === imageData['field_excursion_code'] && imageData[imageField]) {
            contentData['field_thumbnail'] = imageData[imageField];
          }
        });
      });
      return this.apiResponseImageData;
    }
  }

/* ====================================================================
    Function to remove an item from json array
 ======================================================================== */
  removeNodeItem(array, key, value) {
    value = value.toString();
    return array.filter(function(emp) {
      // switch (key) {

        // case 'folioId':
        //   if (emp.folioId == value) {
        //     return false;
        //   }
        //   return true;
        // break;

        // case 'rescoItemId':
        //   if (emp.rescoItemId == value) {
        //     return false;
        //   }
        //   return true;
        // break;

        // default:
        //   if (emp.id == value) {
        //     return false;
        //   }
        //   return true;
        // break;
        if (key === 'folioId') {
          if (emp.folioId == value) {
            return false;
          }
          return true;
        } else if (key === 'rescoItemId') {
          if (emp.rescoItemId == value) {
            return false;
          }
          return true;
        } else {
          if (emp.id == value) {
            return false;
          }
          return true;
        }

      // }
    });
  }
  // Check whether the string contain number
  hasNumber(myString) {
    return /\d/.test(myString);
  }

    /*===================================================================
      Function on  Focus Grid/List
      ===================================================================*/
      focusGridListView(event, viewType, position) {
        const gridTopLink = document.getElementById('gridViewLinkId_upper');
        const listTopLink = document.getElementById('listViewLinkId_upper');
        const gridBottomLink = document.getElementById('gridViewLink_lower');
        const listBottomLink = document.getElementById('listViewLink_lower');
        if (position === 'top') {
          if (viewType === 'grid') {
            if (gridTopLink) {
              gridTopLink.style.textDecoration = 'none';
              gridTopLink.style.color = '#3b68b3';

            }
          } else if (viewType === 'list') {
            if (listTopLink) {
              listTopLink.style.textDecoration = 'none';
              listTopLink.style.color = '#3b68b3';

            }
          }
        } else {
          if (viewType === 'grid') {
            if (gridBottomLink) {
              gridBottomLink.style.textDecoration = 'none';
              gridBottomLink.style.color = '#3b68b3';
            }
          } else if (viewType === 'list') {
            if (listBottomLink) {
              listBottomLink.style.textDecoration = 'none';
              listBottomLink.style.color = '#3b68b3';
            }
          }
        }
      }
    /*===================================================================
    Function on  Focus Out Grid/List
    ===================================================================*/
      focusOutGridListView(viewType, position, listClickStatus, gridClickStatus) {
        const gridTopLink = document.getElementById('gridViewLinkId_upper');
        const listTopLink = document.getElementById('listViewLinkId_upper');
        const gridBottomLink = document.getElementById('gridViewLink_lower');
        const listBottomLink = document.getElementById('listViewLink_lower');
        if (position === 'top') {
          if (viewType === 'grid') {
            if (gridTopLink) {
              if (gridClickStatus) {
                gridTopLink.style.textDecoration = 'underline';
                listTopLink.style.textDecoration = 'none';
                listTopLink.style.color = 'black';
              } else {
                gridTopLink.style.textDecoration = 'none';
                gridTopLink.style.color = 'black';
              }
            }
          } else if (viewType === 'list') {
            if (listTopLink) {
              if (listClickStatus) {
                listTopLink.style.textDecoration = 'underline';
                gridTopLink.style.textDecoration = 'none';
                gridTopLink.style.color = 'black';
              } else {
                listTopLink.style.textDecoration = 'none';
                listTopLink.style.color = 'black';
              }
            }
          }
        } else {
          if (viewType === 'grid') {
            if (gridBottomLink) {
              if (gridClickStatus) {
                gridBottomLink.style.textDecoration = 'underline';
                listBottomLink.style.textDecoration = 'none';
                listBottomLink.style.color = 'black';
              } else {
                gridBottomLink.style.textDecoration = 'none';
                gridBottomLink.style.color = 'black';
              }
            }
          } else if (viewType === 'list') {
            if (listBottomLink) {
              if (listClickStatus) {
                listBottomLink.style.textDecoration = 'underline';
                gridBottomLink.style.textDecoration = 'none';
                gridBottomLink.style.color = 'black';
              } else {
                listBottomLink.style.textDecoration = 'none';
                listBottomLink.style.color = 'black';
              }
            }
          }
        }
      }
    /*===================================================================
      Function to  Share User Area Details
    ===================================================================*/
    userAreaDetails(params) {
      this.areaCode.next(params);
    }

    /*===================================================================
      Function to return complete guest page configuration
    ===================================================================*/
    getGuestPageConfiguration(differenceObj,completeObj=guestConfiguration) {
      let finalObj = completeObj;
	    let differenceObjOuterKeys = Object.keys(differenceObj);
	    differenceObjOuterKeys.forEach((outerKey, index) => {
		  let innerKeys = Object.keys(differenceObj[outerKey]);
		    innerKeys.forEach((innerKey) => {
			    finalObj[outerKey][innerKey] = {...completeObj[outerKey][innerKey], ...differenceObj[outerKey][innerKey]}
		    })
	    } );
	    return finalObj;
    }
    // calculate time differenct of 30 min
    getDateDifference(expireDate) {
      let exDate:any = new Date(expireDate);
      let today:any = new Date();
      let diff = (today - exDate);
      diff = Math.round((diff / 1000) / 60);
      return ( isNaN(diff) ? true : diff < 30);
    }
  
  }
