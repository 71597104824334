<div *ngIf="apiResponseA && apiResponseA?.field_image">
    <div class="h-[400px] -mt-[51px] lg:-mt-[84px] relative">
      <img [src]="apiResponseA?.field_image" class="fit-image" alt="">
      <div class="absolute top-[35%] lg:top-[43%] sm:top-[42%] left-0 -translate-y-1/2 text-white w-full">
        <div class="container">
          <div *ngIf="apiResponseA?.field_com_eyebrow"
               class="text-white uppercase mb-[18px] tracking-wide"
              [innerHtml]="apiResponseA?.field_com_eyebrow"></div>

          <h1 *ngIf="apiResponseA?.title"
              class="h1 !font-big-caslon mb-[18px]" [innerHtml]="apiResponseA?.title"></h1>
          <div *ngIf="apiResponseA?.field_com_cta_title" class="text-white uppercase mb-[18px] tracking-wide"
              ><a [routerLink]="apiResponseA?.field_com_cta_link" [innerHtml]="apiResponseA?.field_com_cta_title" class="link-cta text-white"></a></div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="allBrochuredata">
    <ng-container *ngFor="let eachBrochureData of allBrochuredata">
      <app-rcyc-brochure-50-50 [data]="eachBrochureData"
        [voyageRegion]="eachBrochureData"></app-rcyc-brochure-50-50>
    </ng-container>
  </ng-container>