import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RcycShoreExcursionsComponent } from './rcyc-shore-excursions/rcyc-shore-excursions.component';
import { RcycOnboardExpModalComponent } from '../rcyc-onboard-exp-modal/rcyc-onboard-exp-modal.component';

@Component({
  selector: 'app-rcyc-full-img-tile',
  templateUrl: './rcyc-full-img-tile.component.html',
  styleUrls: ['./rcyc-full-img-tile.component.css']
})
export class RcycFullImgTileComponent implements OnInit {
  @ViewChild('shoreExcursionsModal', { static: false }) shoreExcursionsModal: RcycShoreExcursionsComponent;
  @ViewChild('onboardExpModal', { static: false }) onboardExpModal: RcycOnboardExpModalComponent;
  @Input() data: any
  @Input() pageName: string;
  @Input() portCode: any;
  image: any;

  constructor(
    private sanitizer: DomSanitizer,) { }

  ngOnInit() {
    this.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.field_tile_image);
  }
  shoreExcursionsClick(title: any) {
    if (this.pageName !== 'shore-ex') {
      this.shoreExcursionsModal.modalOpen(title);
    }
    else {
      this.onboardExpModal.modalOpen({ title: title, pageName: this.pageName });
    }
  }
}
