
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild, HostListener
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
//import { CookiesService } from '@ngx-utils/cookies';
import { CookieService } from 'ngx-cookie-service';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { environment } from '../../../environments/environment';
import { RcycPortsService } from '../rcyc-ports/rcyc-ports.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycLoginComponent } from "../rcyc-guest/rcyc-login/rcyc-login.component";

@Component({
  selector: 'app-footer',
  templateUrl: './rcyc-footer.component.html',
  styleUrls: ['./rcyc-footer.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit, AfterViewInit {

  mainHeaderStatus: any;
  gdpr_cookie: any;
  timer: any;
  ENVIRONMENT: any;
  userLocationDetailsStatus = false;
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.openedSubmenu = undefined
    }
  }
  @Input() countryNo: any;
  @Output() toggleFooterLanguage: EventEmitter<any> = new EventEmitter<any>();
  mobileDropdown: any = {
    one: true,
    two: true,
    three: true,
    four: true
  };
  current_country: any;

  languageVisible = false;

  languageObj: any;
  subscription: any;
  country_code: any;
  cntrycode: any;
  country_no: any = '';
  UserDetails: any = {};
  continent_code: any;
  showContactUs = false;
  clickToChatUrl: any;
  userLocationDetails: any;
  userChatLocation: any;

  apiUrlC: any;
  apiResponseCTemp: any;
  apiResponseC: any;
  apiResponseCStatus = false;
  voyageSitemap: any;
  currentYear: number;
  call_centre_region: any;
  footerMenu: any[];
  openedSubmenu: number;


  constructor(
    private http: HttpClient,
    private rcycCommonService: RcycCommonService,
    private cookies: CookieService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rcycHomeService: RcycHomeService,
    private scrollToService: ScrollToService,
    private rcycPortsService: RcycPortsService,
    private rcycInternalApiService: RcycInternalApisService

  ) {

    this.ENVIRONMENT = environment;
    this.rcycCommonService.currentloginStatus.subscribe(status => {
      // this.showContactUs = true;
      /*if (localStorage.getItem('loggedUserDetails')) {
        this.showContactUs = true;
      } else {
        this.showContactUs = false;
      }*/
    });
    this.router.events.subscribe((val: any) => {

      // Dynamic scrollTo function
      this.scrollToBinding();

      // Apply min height
      // this.contentBodyHeightApply();

    });
    // this.userTypeSubmenuHeight();
  }




  collapseFooterLink(key) {
    /* if(key === 1) {
      this.mobileDropdown.one = !this.mobileDropdown.one;
    }else if(key === 2){
      this.mobileDropdown.two = !this.mobileDropdown.two;
    }else if(key === 3){
      this.mobileDropdown.three = !this.mobileDropdown.three;
    }else if(key === 4){
      this.mobileDropdown.four = !this.mobileDropdown.four;
    } */
  }

  toggleLanguage() {
    this.languageVisible = !this.languageVisible;
    this.toggleFooterLanguage.emit(this.languageVisible);
  }

  setLanguage(lang) {

    /*  let selectedLanguageArrayIndex = this.languageObj.indexOf(this.languageObj.filter(l=> l.selected === true)[0]);
     this.languageObj[selectedLanguageArrayIndex].selected = false;
     let letSelectLanguageArrayIndex = this.languageObj.indexOf(this.languageObj.filter(l=> l.name === lang.name)[0]);
     this.languageObj[letSelectLanguageArrayIndex].selected = true; */
    setTimeout(() => {
      this.toggleLanguage();
    }, 500);
  }


  ngOnInit() {
    this.rcycFooterOnInitFn();
    this.rcycFooterContent();
    this.getUserChatLocation();
    this.getFooterMenu();
  }

  /* ========================================
    OnInit Function
============================================== */
  rcycFooterOnInitFn() {
    let currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.subscription = this.rcycCommonService.cookieConsent.subscribe(
      (message) => {
        this.gdpr_cookie = message;
      }
    );

    /*  this.rcycCommonService.countyCode.subscribe(
        (message) => {
                  this.country_code=message;
                  this.county_callno();
        }
    ); */
    // this.userDetails();

    this.gdpr_cookie = this.cookies.get('GDPR');
    /*  this.languageObj = [
          {name: '日本語', selected: false},
          {name: '中文', selected: false},
          {name: 'Deutsch', selected: false},
          {name: 'English', selected: false},
          {name: 'Español', selected: false},
          {name: 'Português', selected: true},
          {name: 'Pусский', selected: false},
          {name: 'العربية', selected: false}
      ]; */

    this.http.get('./assets/countrylist.json').subscribe(countrydata => {
      this.languageObj = countrydata;
    });
    // this.http.get("./assets/phonelist.json").map(data => data).subscribe(phonelist => {
    // this.languageObj = phonelist;
    // console.log(phonelist);
    // });


    /*   var json = 'http://ipinfo.io/json';
        this.http.get(json).map(data => data).subscribe(result => {
            console.log(result);
            this.current_country=result.country;
            let letSelectLanguageArrayIndex = this.languageObj.indexOf(this.languageObj.filter(l
              => l.code === this.current_country)[0].country);
            this.current_country=this.languageObj[letSelectLanguageArrayIndex];

    },  function(e) {

    }); */
    this.getUserLocationDetails();
    // this.clickToChatFn();
    // if (!localStorage.getItem('UserDetails')) {

    //   this.UserLocationDetails = this.rcycCommonService.getData('UserDetails');
    //   console.log(this.UserLocationDetails);
    // }
  }

  /* ================================================================
    Footer content Function
  ================================================================== */
  rcycFooterContent() {

    /****************** Section C********************/
    // this.apiUrlC = '/api/v1/footer' + this.DRUPAL_MVC_URL_PARAM + this.DRUPAL_COMMON_URL_END_PARAM;
    this.apiUrlC = '/api/v1/footer?_format=json';
    this.rcycPortsService.ports(this.apiUrlC).subscribe(
      response => {
        this.apiResponseCTemp = response;
        if (this.apiResponseCTemp && this.apiResponseCTemp.length) {

          const res = Object.keys(response).map(function (key) {
            return response[key];
          });

          if (res.length) {
            this.apiResponseCStatus = true;
            this.apiResponseC = res[0];
          }
        }

      },
      err => {

        console.log('Section C Api error');
        console.log(err);
      }
    );
  }


  /* ====================================================================================================================================
    Height for contentPart
======================================================================================================================================= */
  contentBodyHeightApply() {
    // document.addEventListener("DOMContentLoaded", function() {
    if (document.querySelector('.appComponentBody')) {

      let totalHeight;
      const screenHeight = window.innerHeight;
      if (document.getElementById('rcyc-page-header') && document.querySelector('nav.menu-wrapper.brand-nav-wrapper')) {
        totalHeight = (document.getElementById('rcyc-page-header') as HTMLElement).offsetHeight +
          (document.querySelector('nav.menu-wrapper.brand-nav-wrapper') as HTMLElement).offsetHeight;
        const contentBodyHeight = screenHeight - totalHeight;
        document.querySelector('.appComponentBody').setAttribute('style', 'min-height: ' + contentBodyHeight + 'px;');
      }
      // (document.querySelector(".bodyContentOuter") as HTMLElement).style.height = contentBodyHeight+"px";

    }
    // });


  }

  accept_cookie_Consent(msg) {

    this.cookies.set('GDPR', msg);
    this.rcycCommonService.setcookieConsent(msg);
    this.gdpr_cookie = msg;
  }

  getFooterMenu() {
    this.footerMenu = [
      [
        {
          id: '0',
          title: 'Explore',
          links: [
            {
              title: 'Yachts',
              route: '/yachts',
            },
            {
              title: 'Destinations',
              route: '/cruise-ports',
            },
            {
              title: 'Find a Cruise',
              route: '/find-a-cruise',
            },
            {
              title: 'The Compass',
              route: '/the-compass',
            },
            {
              title: 'Offers & Programs',
              route: '/specialoffers',
            },
            {
              title: 'Request E-Brochure',
              route: '/request-brochure',
            },
            {
              title: 'Groups',
              route: '/groups'
            }
          ]
        },
        {
          id: '1',
          title: 'Yachts',
          links: [
            {
              title: 'Evrima',
              route: '/yachts/evrima',
            },
            {
              title: 'Ilma',
              route: '/yachts/ilma',
            },
            {
              title: 'Luminara',
              route: '/yachts/luminara',
            },
          ]
        },
      ],
      [
        {
          id: '2',
          title: 'Travel Partners',
          links: [
            {
              title: 'Travel Professionals',
              route: '/travelprofessionals',
            },
            {
              title: 'Charters, Meetings & Incentives',
              route: '/charters',
            },
          ]
        },
        {
        id:'3',
        title: 'Corporate',
        links: [
          {
            title: 'About Us',
            route: '/about',
          },
          {
            title: 'Careers',
            url: 'https://careers.ritzcarltonyachtcollection.com',
          },
          {
            title: 'Contact Us',
            route: '/contactus',
          },
          {
            title: 'Code of Ethics',
            route: '/code-of-ethics'
          },
        ]
      },
      ],
      [
        {
          id: '4',
          title: 'Legal',
          links: [
            {
              title: 'Document Acknowledgment',
              route: '/legal/documents',
            },
            {
              title: 'Privacy Policy (Global)',
              route: '/legal/privacy-policy',
            },
            {
              title: 'Terms of Use',
              route: '/legal/terms-conditions',
            },
            {
              title: 'Terms of Use (EU)',
              route: '/legal/eu-terms-conditions',
            },
            {
              title: 'Ticket Contract',
              route: '/legal/ticket-contract',
            },
            {
              title: 'Ticket Contract (EU)',
              route: '/legal/eu-ticket-contract',
            },
            {
              title: 'Booking Terms & Conditions',
              route: '/legal/booking-terms-conditions',
            },
            {
              title: 'Booking Terms & Conditions (EU)',
              route: '/legal/booking-terms-conditions-eu',
            },
            {
              title: 'Guest Code of Conduct',
              route: '/guest-conduct-policy',
            },
            {
              title: 'Nonperformance of Cruises',
              route: '/nonperformance-of-cruises',
            }
          ]
        },
      ],
      [
        {
          id: '5',
          title: 'Resources',
          links: [
            {
              title: 'Request a Quote',
              route: '/request-quote',
            },
            {
              title: 'Frequently Asked Questions',
              route: '/presailing/FAQ',
            },
            // PSW-2139 Removing “Sitemap” link temporarily from footer due to raw HTML data issue.
            // {
            //   title: 'Sitemap',
            //   url: '/sitemap.xml',
            // },
            {
              title: 'Video Gallery',
              route: '/videos',
            },
            {
              title: 'Travel Insurance (US Residents)',
              route: '/insurance',
            },
          ]
        },
        {
          id: '6',
          title: 'Press',
          links: [
            {
              title: 'Press Room',
              route: '/press',
            },
          ]
        }
      ]
    ];
  }

  openMobileSubmenu(key) {
    if (this.openedSubmenu === key) {
      this.openedSubmenu = undefined;
    } else {
      this.openedSubmenu = key;
    }
  }


  // userDetails() {
  //   if (!this.rcycCommonService.getData('UserDetails')) {

  //     this.rcycCommonService.getUser_Details().subscribe(
  //       response => {

  //         // const temp_data;
  //         this.UserDetails = response;

  //         this.rcycCommonService.setData('UserDetails', this.UserDetails);
  //         this.continent_code = this.UserDetails.continent_code;
  //         this.rcycCommonService.setcountryCode(this.UserDetails.country_code);
  //         // this.county_callno();
  //       },
  //       err => {

  //         this.UserDetails.continent_code = 'NA';
  //         this.continent_code = 'NA';
  //         this.UserDetails.country_code = 'US';
  //         this.rcycCommonService.setData('UserDetails', this.UserDetails);
  //         this.rcycCommonService.setcountryCode(this.UserDetails.country_code);
  //         // this.county_callno();
  //       });
  //   } else {
  //     this.UserDetails = this.rcycCommonService.getData('UserDetails');
  //     this.continent_code = this.UserDetails.continent_code;
  //     this.rcycCommonService.setcountryCode(this.UserDetails.country_code);
  //     // this.county_callno();
  //     this.rcycCommonService.expireLocation();

  //   }



  // }

  /* =====================================================================================================================================
     Function to get dynamic scroll
======================================================================================================================================= */
  scrollToBinding() {

    if (!document.querySelector('[rcyc-scroll-to]')) {

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {

        const elem = Array.from(document.querySelectorAll('[rcyc-scroll-to]'));
        elem.forEach(res => {

          res.addEventListener('click', this.scrollToClick.bind(this, res));
        });
      }, 2000);
    } else {

      const elem = Array.from(document.querySelectorAll('[rcyc-scroll-to]'));
      elem.forEach(res => {

        res.addEventListener('click', this.scrollToClick.bind(this, res));
      });
    }
  }

  scrollToClick(res) {

    const targetElementId = res.getAttribute('rcyc-scroll-to');
    const config: ScrollToConfigOptions = {
      target: targetElementId
    };
    this.scrollToService.scrollTo(config);
  }

  /* =======================================================================================
       Function to get dynamic scroll
  ======================================================================================= */
  userTypeSubmenuHeight() {

  }
  /* =======================================================================================
       Function to change session Error message, guest to travel agent
  ======================================================================================= */
  onTravelProfessionClick() {
    this.rcycCommonService.setSessionExpiredStatus(false);
  }

  /* =======================================================================================
       Function to get click to chat url
  ======================================================================================= */
  // clickToChatFn() {

  //   this.rcycCommonService.clickToChatUrl.subscribe(clickToChatUrl => {
  //     this.clickToChatUrl = clickToChatUrl;
  //   });
  // }

  /* =======================================================================================
     Chat button click
======================================================================================= */
  clickToChatClick() {

    // const windowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
    // const windowFeatures = 'toolbar=0,location=0,menubar=0';
    // window.open(this.clickToChatUrl, 'Ritzcarlton', windowFeatures);
  }

  /* =======================================================================================
     Function to get click to chat url
======================================================================================= */
  getUserLocationDetails() {

    this.rcycCommonService.userLocationDetails.subscribe(userLocDetails => {
      this.userLocationDetails = userLocDetails;
      this.userLocationDetailsStatus = true;
    });
  }

  /* =======================================================================================
       Function to get voyageInfo
  ======================================================================================= */
  voyageInfo() {
    const apiUrl = '/api/genericvoyage';
    window.open(this.router.url, '_blank');
    const xml = this.rcycInternalApiService.getVoyageInfo(apiUrl);
    xml.subscribe((data) => {
      if (data) {
        this.voyageSitemap = data;
        if (this.voyageSitemap) {
          this.voyageSitemap = `<?xml version = "1.0" encoding = "UTF-8"?>` + this.voyageSitemap;
          const aFileParts = [this.voyageSitemap];
          const oMyBlob = new Blob(aFileParts, { type: 'text/xml' }); // the blob
          // window.open(URL.createObjectURL(oMyBlob));
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(oMyBlob);
          a.href = url;
          a.download = 'voyageInfo.xml'; // gives it a name via an a tag
          // const win = window.open(window.location, '_blank');
          // win.focus();
          a.click();
        }
      }
    },
      err => {
        console.log('Error in getting Voyage Info', err);
      }
    );
  }

  getUserChatLocation() {
    this.rcycCommonService.getUser_Details().subscribe(
      response => {
        this.userChatLocation = response;
        let apiLink = '/api/v1/countryphone/' + this.userChatLocation.country_code + '?_format=json';
        this.rcycInternalApiService.getDrupal(apiLink).subscribe(
          response => {
            if (response && response[0].field_call_center_region) {
              this.call_centre_region = response[0].field_call_center_region;
              console.log('call location-', this.call_centre_region)
            }
          },
          error1 => {
            console.log('location error from drupal', error1)
          })
      },
      err => {
        console.log('ipdata-error', err)
      });
  }

  ngAfterViewInit() {
    if ($(document).on('click', '.voyage-data a', () => {
      this.voyageInfo();
    })) {

    }

  }

  navigateTo(page: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([page])
    });
  }



}
