import { createSelector } from '@ngrx/store';
import * as fromReservationReducer from '../reducers/rcyc-reservation.reducer';
import * as fromReducer from '../reducers'

export const getDashboardState = <any>createSelector(
    fromReducer.getUserPortalDetails,
    (state: fromReducer.UserLoggedState) =>{

        return state}
);

export const selectBookingEntities = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingEntities
);

export const selectBookingDetailsEntities = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingDetailsEnitity
);

export const getbookingTotalCount = createSelector(
  getDashboardState,
  fromReservationReducer.getbookingTotalCount
);

export const selectBookingList = createSelector(selectBookingEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getTotalBookingCount = createSelector(
    getDashboardState,
    fromReservationReducer.getTotalBookingCount
);

export const getBookingErrorMsg = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingErrorMsg
);
export const getBookingErrorMsgCode = createSelector(
  getDashboardState,
  fromReservationReducer.getBookingErrorMsgCode
);
export const getBookingDetailsErrorMsgCode = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingDetailsErrorMsgCode
);

export const getBookingIsLoading = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingIsLoading
);

export const getBookingIsLoaded = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingIsLoaded
);

export const getBookingId = createSelector(
    getDashboardState,
    fromReservationReducer.getCurrentBookingId
);

export const getBookingDetailsByID = createSelector(
    selectBookingDetailsEntities,
    (getBookingDetails)=>{
        if(getBookingDetails){
            const voyages=Object.keys(getBookingDetails.voyages).map(id => getBookingDetails.voyages[id])
            return {...getBookingDetails,voyages:voyages};
        }
        return getBookingDetails;
    }
);
export const getGuestDetailsByVoyageCode =(code:string)=> createSelector(
  selectBookingDetailsEntities,
  (getGuestDetails)=>{
      if(getGuestDetails && getGuestDetails.voyages[code]){
          let guestEntities:any = {};
          getGuestDetails.voyages[code].suites.forEach((guestBySuit=>{
              (guestBySuit.guests).forEach(
                guest=>{
                    if(!guestEntities[guest.folioId]){
                      guestEntities[guest.folioId]=guest;
                    }
                }
              )
            }));
           return Object.keys(guestEntities).map(id => guestEntities[id]);
      }
      else{
          return null
      }
  }
);
export const getGuestDetailsByBookingId = createSelector(
    selectBookingDetailsEntities,
    (getGuestDetails)=>{
        if(getGuestDetails && getGuestDetails.voyages){
            let guestEntities:any = {};
            for(let voyage in  getGuestDetails.voyages){
                getGuestDetails.voyages[voyage].suites.forEach((guestBySuit=>{
                    (guestBySuit.guests).forEach(
                      guest=>{
                          if(!guestEntities[guest.folioId]){
                            guestEntities[guest.folioId]=guest;
                          }
                      }
                    )
                }));
            }
            return Object.keys(guestEntities).map(id => guestEntities[id]);
        }
        else{
            return null
        }
    }
  );
export const getGuestDetailsByVoyageCodeOld =(code:string) =>createSelector(
  selectBookingDetailsEntities,
  (getBookingDetails)=>{
      if(getBookingDetails&&getBookingDetails.voyages[code]){
          return getBookingDetails.voyages[code];
      }
      return null;
  }
);

export const getBookingDetailsErrorMsg = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingDetailsErrorMsg
);

export const getBookingDetailsIsLoaded = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingDetailsIsLoaded
);

export const getBookingDetailsIsLoading = createSelector(
    getDashboardState,
    fromReservationReducer.getBookingDetailsIsLoading
);



//Reservation Details by port

export const getShoreExBookingDetails =createSelector(
    getDashboardState,
    fromReservationReducer.getShoreExDetailsByPort
)
export const getMarqueeBookingDetails =createSelector(
    getDashboardState,
    fromReservationReducer.getMarqueeDetailsByPort
)
export const getDiningBookingDetails =createSelector(
    getDashboardState,
    fromReservationReducer.getDiningDetailsByPort
)
export const getSpaBookingDetails =createSelector(
    getDashboardState,
    fromReservationReducer.getSpaDetailsByPort
)
export const getReservationErrorMessages =createSelector(
    getDashboardState,
    fromReservationReducer.getreservationErrorMessages
)
export const getReservationCounter =createSelector(
    getDashboardState,
    fromReservationReducer.getReservationCounter
)
