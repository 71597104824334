import { Component, Inject, OnInit, OnDestroy, PLATFORM_ID, HostListener, ɵConsole, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
// import { GoogleAnalyticsService } from 'angular-ga';
// import { RcycApiCallService } from "./rcyc-services/rcyc-apiCall/rcyc-api-call.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from './rcyc-config/config';
import { RcycCommonService } from './rcyc-services/rcyc-common/rcyc-common.service';
// import { CookieService } from 'ngx-cookie-service';
//import { CookiesService } from '@ngx-utils/cookies';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { RcycGoogleAnalyticsEventsService } from './rcyc-services/rcyc-google-analytics-events/rcyc-google-analytics-events.service';
import { RcycHomeService } from './rcyc-components/rcyc-home/rcyc-home.service';
import { Subject, Subscription, timer } from 'rxjs';
import { Location } from '@angular/common';

import { takeUntil, take, timestamp, filter } from 'rxjs/operators';
import { RcycCommonLogoutService } from './rcyc-services/rcyc-logout/rcyc-logout.service';
import { environment } from '../environments/environment';
import { SharedServiceService } from './rcyc-services/rcyc-shared/shared-service.service';
import { AutoLogoutService } from './rcyc-services/rcyc-autologout/auto-logout.service';
import { RcycAutologoutSessionModalComponent } from './rcyc-modals/rcyc-autologout-session-modal/rcyc-autologout-session-modal.component';
declare const ga: Function;
declare var gtag

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('RcycAutologoutSessionModalComponent') RcycAutologoutSessionModalComponent: RcycAutologoutSessionModalComponent;

  title = 'app';
  initialMenu = '';
  gaApiUrl: any;
  User_GDetails: any = {};
  API_BASE_URL: any = AppConfig.API_BASE_URL;

  // ------------------ Header & Footer Visible Initial value ------------------->
  footerVisible = true;
  headerVisible = true;
  notHeaderObj: any = [
    { name: 'planVoyage', url: '/plan-your-voyage' },
    { name: 'selectSuite', url: '/select-suite' },
    { name: 'yourQuote', url: '/your-quote' },
    { name: 'detailPref', url: '/details-and-pref' },
    { name: 'thankyou', url: '/thank-you' },
  ];
  notFooterObj: any = [
    { name: 'planVoyage', url: '/plan-your-voyage' },
    { name: 'selectSuite', url: '/select-suite' },
    { name: 'yourQuote', url: '/your-quote' },
    { name: 'detailPref', url: '/details-and-pref' },
    { name: 'thankyou', url: '/thank-you' },
  ];
  // ------------------ Header & Footer Visible Initial value ------------------->
  // UserDetails:any={};

  gdpr_cookie: any;
  cookieSubscription: any;
  UserDetails: any = {};
  continent_code: any;
  country_no: any = '';
  region: any;

  googleCodeSendAlready: any = false;
  currentComponent: any;

  apiUrlB: any;
  apiResponseBTemp: any;
  cntrycode: any;

  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime = 15; // Session Time (15 minutes)
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  clickToChatUrl: any;
  userLocDetails: any;
  sessionPopUpData: any;
  gdprRegion: string;

  urlSegments: any;

  resolver: any;


  constructor(
    private router: Router,
    // private gaService: GoogleAnalyticsService,
    // rcycApiCallService: RcycApiCallService,
    // private cookieService: CookieService,
    private cookies: CookieService,
    private http: HttpClient,
    private rcycCommonService: RcycCommonService,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    public googleAnalyticsEventsService: RcycGoogleAnalyticsEventsService,
    private rcycHomeService: RcycHomeService,
    private rcycCommonLogoutService: RcycCommonLogoutService,
    private _SharedServiceService: SharedServiceService,
    private _AutoLogoutService: AutoLogoutService,//This service is self initialized
    private location: Location,
  ) {


    this.rcycCommonService.currentComponent.subscribe(componentName => this.currentComponent = componentName);
    this.router.events.subscribe((val: any) => {

      if (this.router.url.split('/').length > 2) {
        this.urlSegments = this.router.url.split('/');
      }


      // For Default meta tags
      this.rcycCommonService.getMetaTags('');
      // this.rcycCommonService.changeSubHeaderStatus(false);

      this.notFooterObj.filter(nF => nF.url === this.router.url).length !== 0 ? this.footerVisible = false : this.footerVisible = true;
      this.notHeaderObj.filter(nH => nH.url === this.router.url).length !== 0 ? this.headerVisible = false : this.headerVisible = true;


    });
    this.rcycCommonService.loginStatus.subscribe(status => {
      // if(status){
      // this.startSession();
      if (localStorage.getItem('loggedUserDetails')) {
        // console.log('timerstart',new Date(Date.now()),true);
      } else {
        // this.RcycAutologoutSessionModalComponent.openModal(
        //   {
        //     heading:"SESSION TIMEOUT",
        //     messageToShow:"<b>Your session has timed out after 15 minutes </b>",
        //     buttonText:"OK"
        //   }
        // )

        // this.currentUser=false; //not necessary
        // console.log('timerstart',new Date(Date.now()),false);

      }
      // }

    });

    const apiUrl = '/api/v1/signout/content/e?_format=json';
    this.rcycCommonService.drupalApisGet(apiUrl).subscribe(
      response => {

        this.sessionPopUpData = response[0];
      });
    this._AutoLogoutService.autoLoginStatus.subscribe(status => {
      // if(status){
      // this.startSession();
      // console.log('============'+status);
      if (status) {

        this.RcycAutologoutSessionModalComponent?.openModal(
          {
            heading: this.sessionPopUpData.field_sub_header,
            messageToShow: this.sessionPopUpData.field_summery,
            buttonText: "OK"
          }
        );
      }

      // this.currentUser=false; //not necessary

      // }

    });


    /* 	if(!this.rcycCommonService.getData("UserDetails")) {

            this.rcycCommonService.getUser_Details().subscribe(
                response => {

                    var temp_data;
                    this.UserDetails = response;

                    this.rcycCommonService.setData("UserDetails", this.UserDetails);
                },
                err=>{

                    this.UserDetails.country_code="US";
                    this.rcycCommonService.setData("UserDetails", this.UserDetails);
                })
        }else {

            this.rcycCommonService.expireLocation();
        } */

    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'UA-114428670-1', { 'page_path': e.urlAfterRedirects });
      gtag('config', 'G-ZHRKCTDD2L', { 'page_path': e.urlAfterRedirects });
    });

    // Determine the correct Adobe Launch script URL based on the environment
    const adobeLaunchScriptUrl = this.getAdobeLaunchScriptUrl(environment);
    // Load the Adobe Launch script dynamically
    this.loadAdobeLaunchScript(adobeLaunchScriptUrl);

    this.loadEvergageScript();
  }
  // Function for Session Timeout
  // @HostListener('document:keyup', ['$event'])
  // @HostListener('document:click', ['$event'])
  // @HostListener('document:contextmenu', ['$event'])
  // @HostListener('document:wheel', ['$event'])
  // @HostListener('mousemove', ['$event'])
  // @HostListener('touchstart', ['$event'])
  resetTimer() {
    if (localStorage.getItem('loggedUserDetails')) {
      this.rcycCommonService.notifyUserAction();
    }
  }

  ngOnInit() {
    this.appendRoboticsNoIndex();
    //Verify existing local token is valid
    this.rcycCommonLogoutService.checkExpiry();

  }

  startSession() {
    if (localStorage.getItem('loggedUserDetails')) {
      this.resetTimers();
      this.rcycCommonService.userActionOccured.pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
        }
        this.resetTimers();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  resetTimers(endTime: number = this.endTime) {
    // console.log('changeDetected',new Date(Date.now()));
    if (localStorage.getItem('loggedUserDetails')) {
      const userType = JSON.parse(localStorage.getItem('loggedUserDetails')).type;
      const interval = 1000;
      const duration = endTime * 60;
      this.timerSubscription = timer(duration * 1000).subscribe((value) => {
        if (localStorage.getItem('loggedUserDetails')) {
          // console.log('logoutTimer ',new Date(Date.now()));
          // this.rcycCommonLogoutService.autoLogout();
        }
      },
        (err) => { },
      );
    }
  }
  // resetTimers(endTime: number = this.endTime) {
  //   console.log('changeDetected',new Date(Date.now()));
  //   if (localStorage.getItem('loggedUserDetails')) {
  //     const userType = JSON.parse(localStorage.getItem('loggedUserDetails')).type;
  //     const interval = 1000;
  //     const duration = endTime * 60;
  //     this.timerSubscription = timer(0, interval).pipe(
  //       take(duration)
  //     ).subscribe(value =>
  //       this.render((duration - +value) * interval),
  //       err => {  },
  //       () => {
  //           if (localStorage.getItem('loggedUserDetails')) {
  //             console.log('logoutTimer ',new Date(Date.now()));
  //             this.rcycCommonLogoutService.autoLogout();
  //           }
  //       }
  //     );
  //   }
  // }

  private render(count) {
    if (localStorage.getItem('loggedUserDetails')) {
      this.secondsDisplay = this.getSeconds(count);
      this.minutesDisplay = this.getMinutes(count);
    }
  }

  private getSeconds(ticks: number) {
    if (localStorage.getItem('loggedUserDetails')) {
      const seconds = ((ticks % 60000) / 1000).toFixed(0);
      return this.pad(seconds);
    }
  }

  private getMinutes(ticks: number) {
    if (localStorage.getItem('loggedUserDetails')) {
      const minutes = Math.floor(ticks / 60000);
      return this.pad(minutes);
    }
  }

  private pad(digit: any) {
    if (localStorage.getItem('loggedUserDetails')) {
      return digit <= 9 ? '0' + digit : digit;
    }
  }

  // end Session Timeout

  // Mobile menu toggle
  toggleMenu(ev) {
    ev === true ? this.initialMenu = 'show-menu' : this.initialMenu = '';
  }

  // Footer laguage drop down
  toggleFooterLanguage(ev) {
    ev === true ? this.initialMenu = 'g03_menu-is-open' : this.initialMenu = '';
  }

  /* =====================================================================================================================================
          OnActivate
      ================================================================================================================================= */
  onActivate(event, outlet) {

    // outlet.scrollTop = 0;
    if (isPlatformBrowser(this.platformId)) {

      window.scrollTo(0, 0);
    }

    // Remove all scripts with a specific attribute
    const ebrochureScript = document.querySelectorAll('script[eBrochure-attr]');
    if (ebrochureScript.length) {
      Array.from(ebrochureScript).forEach((elem) => {
        elem.parentNode.removeChild(elem);
      });

    }
    this.gaCookieCountryCode();
    // console.log(event, 'app....');
    this.rcycCommonService.changeroutedComponentArray(
      {
        'routedComponentDetails': event
      });
  }

  /* ====================================================================================================================================-
          onDeactivate
      ================================================================================================================================= */
  onDeactivate() {

    // document.body.scrollTop = 0;

  }



  /* =====================================================================================================================================
          Function to get User's Location details
      ================================================================================================================================= */
  userDetails() {

    if (!localStorage.getItem('UserDetails')) {

      this.rcycCommonService.getUser_Details().subscribe(
        response => {

          // let temp_data;
          this.UserDetails = response;

          this.rcycCommonService.setData('UserDetails', this.UserDetails);
          this.continent_code = this.UserDetails.continent_code;
          this.rcycCommonService.setcountryCode(this.UserDetails.country_code);
          this.county_callno();
          this.gdprDisplayFn()
        },
        err => {

          this.UserDetails.continent_code = 'NA';
          this.continent_code = 'NA';
          this.UserDetails.country_code = 'US';
          this.rcycCommonService.setData('UserDetails', this.UserDetails);
          this.rcycCommonService.setcountryCode(this.UserDetails.country_code);
          this.county_callno();
          this.gdprDisplayFn();
        });
    } else {

      this.UserDetails = this.rcycCommonService.getData('UserDetails');
      this.continent_code = this.UserDetails.continent_code;
      this.rcycCommonService.setcountryCode(this.UserDetails.country_code);
      this.county_callno();
      this.gdprDisplayFn();
      this.rcycCommonService.expireLocation();

    }
  }


  /* =====================================================================================================================================
      Function to get county_callno
      ================================================================================================================================= */


  county_callno() {

    if (this.UserDetails.country_code) {
      this.cntrycode = this.UserDetails.country_code;
    }
    this._SharedServiceService.getCountryphone(this.cntrycode).subscribe(res => {
      this.country_no = res.title;
      this.region = res.field_call_center_region;
      this.gdprRegion = res.field_call_center_region;
      this.rcycCommonService.changeCountryPhoneNo(this.country_no);
    });

    // this.apiUrlB = '/api/v1/countryphone/' + this.cntrycode + '?_format=json';
    // this.rcycHomeService.home(this.apiUrlB).subscribe(
    //   response => {

    //     this.apiResponseBTemp = response;
    //     this.rcycCommonService.userAreaDetails(response);
    //     if (this.apiResponseBTemp && this.apiResponseBTemp.length) {

    //       const res = Object.keys(response).map(function (key) {
    //         return response[key];
    //       });
    //       if (res.length) {
    //         this.country_no = res[0].title;
    //         this.region = res[0].field_call_center_region;
    //       } else {
    //         this.country_no = '+356 2778 0076';
    //       }
    //     } else {
    //       this.country_no = '+356 2778 0076';
    //     }
    //   },
    //   err => {

    //     this.country_no = '+356 2778 0076';
    //   }
    // );

    // this.country=
    /* 	if(this.UserDetails.country_code=='US' || this.UserDetails.country_code=='CA' ){
            this.country_no= "(833) 999-7292";
        }
        else if(this.UserDetails.country_code=='MT'){
            this.country_no="+356 2778 0076";
        }else{
            this.country_no="+1 (305) 907-7099"
        } */
  }

  /* =================================================================================================================================
      Function to run google analytics and cookie disclaimer text
  ================================================================================================================================= */
  gaCookieCountryCode() {

    this.userDetails();
    // this.gdpr_cookie= this.cookieService.get( 'GDPR');
    this.cookieSubscription = this.rcycCommonService.cookieConsent.subscribe(
      (message) => {
        this.gdpr_cookie = message;
      }
    );
    this.gdpr_cookie = this.cookies.get('GDPR');

    this.googleCodeSendAlready = false;
    // Google Analytics
    this.router.events.subscribe(event => {
      // if (typeof ga === 'function') {

      if (event instanceof NavigationEnd) {

        // ga('create', 'UA-117373511-1', 'auto');
        // ga('set', 'page', event.urlAfterRedirects);
        setTimeout(() => {
          // ga('send', 'pageview');
          if (this.continent_code === 'EU' || this.gdprRegion === 'malta' || this.gdprRegion === 'california') {
            if (this.gdpr_cookie === 'ACCEPT') {

              if (!this.googleCodeSendAlready) {

                this.rcycCommonService.gaApiCall();
                // this.googleAnalyticsEventsService.emitEvent('testCategory', 'testAction', 'testLabel', 10);
                this.googleCodeSendAlready = true;
                this.getUserLocationDetails();
              } else {
                this.rcycCommonService.gaApiCall();
                this.getUserLocationDetails();
              }
            } else {
              this.rcycCommonService.gaApiCall();
              this.getUserLocationDetails();
            }
          } else if (this.continent_code && this.continent_code !== 'EU' && this.gdprRegion !== 'malta' || this.gdprRegion === 'california') {

            if (!this.googleCodeSendAlready) {

              this.rcycCommonService.gaApiCall();
              // this.googleAnalyticsEventsService.emitEvent('testCategory', 'testAction', 'testLabel', 10);
              this.googleCodeSendAlready = true;
              this.getUserLocationDetails();
            }
          }
        }, 1000);
      }
      // }
    });
  }

  /* ========================================================================
     Function to run google analytics and cookie disclaimer text
 ============================================================================= */
  appendRoboticsNoIndex() {

    if (environment.noIndexRobots) {
      const removeItem = document.getElementById('rcyc-noindex-id');
      if (removeItem) {
        removeItem.parentNode.removeChild(removeItem);
      }
      const noIndexScript = document.createElement('meta');
      noIndexScript.setAttribute('name', 'robots');
      noIndexScript.setAttribute('content', 'noindex, nofollow');
      noIndexScript.id = 'rcyc-noindex-id';
      document.head.appendChild(noIndexScript);
    }
  }

  /* ========================================================================
      Function for Click To Chat
  ============================================================================= */
  getUserLocationDetails() {
    if (localStorage.getItem('UserDetails') && this.region) {
      const userLocDetails = JSON.parse(localStorage.getItem('UserDetails'));
      userLocDetails.localizationRegionType = this.region;
      this.rcycCommonService.changeUserLocationDetails(userLocDetails);
    }
  }

  /* ========================================================================
      Function for GDPR display
  ============================================================================= */
  gdprDisplayFn() {

    if (this.UserDetails.country_code === 'US' && this.UserDetails.region_code === 'CA') {
      let gdprCountryCode = this.UserDetails.country_code;
      let gdprRegionCode = this.UserDetails.region_code;
      this._SharedServiceService.getGdprRegionCode(gdprCountryCode, gdprRegionCode).subscribe(res => {
        if (res.field_call_center_region) {
          this.gdprRegion = (res.field_call_center_region).toLowerCase();
        } else {
          this.gdprRegion = res.field_call_center_region;
        }

        // this.gdprRegion = res.field_call_center_region;   

      });
    } else {
      if (this.gdprRegion) {
        this.gdprRegion = (this.region).toLowerCase();
      } else {
        this.gdprRegion = this.region;
      }

      // this.gdprRegion = this.region;

    }

  }

  // Adding adobe script based on environments
  private getAdobeLaunchScriptUrl(env: any): string {
    const baseHref = this.location.prepareExternalUrl('/');
    if (env.production) {
      return env.adobeLaunchScript;
    } else {
      return `${baseHref}${env.adobeLaunchScript}`;
    }
  }

  // Define adobe script
  private loadAdobeLaunchScript(scriptUrl: string): void {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.async = true;
    script.defer = true;
    document.getElementById('adobe-launch-script').appendChild(script);
  }

  /**
   * Implementation of evergage beacon sript
   */
  loadEvergageScript(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = environment.beaconScriptSrc; // Use the script source from environment file
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

}