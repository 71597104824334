import { Component, OnInit, ViewChild, Input, ElementRef, Renderer2, HostListener, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { Rcyc3playVimeoService } from '../../rcyc-services/rcyc-3play-vimeo/rcyc-3play-vimeo.service';
import { ActivatedRoute } from '@angular/router';
declare let $f: any;
declare let $: any;
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
@Component({
  selector: 'app-rcyc-ngu-carousel',
  templateUrl: './rcyc-ngu-carousel.component.html',
  styleUrls: ['./rcyc-ngu-carousel.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RcycNguCarouselComponent implements OnInit, OnDestroy , OnChanges {

  @Input() portData: any;
  response_F: any = [];
  currentSlide = 0;
  sliderIndex = 0;

  currentCarouselSlideIndex = 0;
  current3PlayPuginId:string;
  loaded3PlayMFId:string;
  loadedVimeoId:string;
  loaded3PlayRandomHashId:string;
  loaded3PlayTargetPluginId:string;
  currentUrlPathAsArray: any;
  currentUrlKey: any;
  snapshotUrl: any;
  isVideoPlaying=false;
  isCCEnabled=false;
  ADClickStatus=false;
  vimeoMuteClickStatus=false;  
  initialControlClickStatus=false;
  muteStatus:any = true;
  volumeLevel:number = 0.5;

  @ViewChild('pageNguCarousel', { static: true }) pageNguCarousel: any;
  public pageCarousel: any;
  // @ViewChild('div') fancyBoxIframe: ElementRef;

  // @HostListener('window:click', ['$event'])
  // onClick(event) {
  //   this.clickKeyupEnterFn(event, 'click');
  // }
  // @HostListener('window:keyup.enter', ['$event'])
  // onKeydownHandler(event: KeyboardEvent) {
  //   this.clickKeyupEnterFn(event, 'keyupEnter');
  // }
  // @HostListener('window:etouchstart', ['$event'])
  // onStart(event) {
  //   if (event.touches) {
  //     this.clickKeyupEnterFn(event, 'touchstart');
  //   }
  // }
  constructor(
    // private nguCarouselService: NguCarouselService,
    private rcycInternalApisService: RcycInternalApisService,
    private rcyc3playVimeoService: Rcyc3playVimeoService,
    private activatedRoute: ActivatedRoute,
    private renderer2: Renderer2,
    // private elementRef: ElementRef
    private sanitizer: DomSanitizer,
    private rcycCommonService: RcycCommonService,
  ) {
    this.constructorFn();
    this.clickKeyupEnterFn = this.clickKeyupEnterFn.bind(this);
   }

  ngOnInit() {

    this.pageCarousel = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 500,
      interval: {timing: 4000, initialDelay: 1000},
      point: {
        visible: false
      },
      // load: 2,
      touch: true,
      loop: true,
      // custom: 'banner'
    };
    this.onInitFn();
  }
  ngOnChanges(changes: SimpleChanges): void {
    let change=changes['portData'];
    if(changes['portData'] && window.location.pathname == '/experience/building-milestones'){
      this.onInitFn();
    }
  }

  constructorFn() {
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    if(this.currentUrlPathAsArray.length) {
      if(this.currentUrlPathAsArray.length>1){
        this.currentUrlKey = this.currentUrlPathAsArray[1].path;
      }      
    }
    
  }
  /* ======================================================
  Function to call OnInit functions
  ========================================================= */
  onInitFn() {
    if (this.portData) {
      this.response_F = this.portData;
      if ( this.response_F.length ) {

        // Function to get Vimeo Preview       
        this.response_F.map((key, index) => {  
          if (key.field_video) {
            key.field_video = key.field_video.split('?')[0] + '?autoplay=1&texttrack=null&muted=1&controls=0';
            if(!key.field_image) {             
              this.getPreviewImage(key.field_video, index);
            }            
            // if(this.currentUrlPathAsArray[0].path=='experience'&&this.currentUrlKey=='suites') {
            //   this.rcycCommonService.checkHeroVideoUrl(key.field_video).subscribe(value => {
            //     if (value.status === 200) {
            //         //key.field_video=key.field_video.match(/.*\/([^,?]+)/)[0]+'?autoplay=1&texttrack=null&muted=1&controls=0';
            //           key.field_video=this.sanitizer.bypassSecurityTrustResourceUrl(key.field_video);
            //         /* === 3play media Ends== */
            //     }
            //
            //   }, error => { console.log(error) });
            // }
            this.current3PlayPuginId=key.field_3_play_id;
            this.loaded3PlayMFId=key.field_3_play_mf_id;
            if(this.current3PlayPuginId && this.loaded3PlayMFId) {
              let splittedVimeoId: any = this.current3PlayPuginId.split('-');
              this.loadedVimeoId = splittedVimeoId[splittedVimeoId.length - 1];
              this.loaded3PlayRandomHashId = splittedVimeoId[splittedVimeoId.length - 2];
              this.loaded3PlayTargetPluginId = '3p-plugin-target-' + this.loaded3PlayMFId + '-' + this.loaded3PlayRandomHashId;
            }

          } else {
             this.setCarouselUrl(index);
          }
        });        
      }
    }

  }

   /* ==============================================================================
     Function on carousel move
  =================================================================================== */
  onmoveFn(data: NguCarouselStore) {
    this.currentSlide = data.currentSlide;
  }

  /* ==============================================================================
     Function on carousel left , right click
  =================================================================================== */
  // Function to change the index of the carousel
  changeSliderContentIndex(position) {

    if (this.response_F.length) {
      if (position === 'left') {
        if (this.sliderIndex > 0) {
          this.sliderIndex = this.sliderIndex - 1;
        } else if (this.sliderIndex === 0) {
          this.sliderIndex = this.response_F.length - 1;
        }
      } else if (position === 'right') {
        if (this.sliderIndex === (this.response_F.length - 1)) {

          this.sliderIndex = 0;

        } else {

          this.sliderIndex = this.sliderIndex + 1;
        }
      }
    }
  }

  /* ==============================================================================
   // Function for nextCarouselload
  =================================================================================== */
  nextCarouselload(i) {
    this.currentCarouselSlideIndex = i;
  }

/* ==============================================================================
   // Function for thumbnail view
  =================================================================================== */
  getPreviewImage(videoUrl: any, index: any) {
    if (videoUrl) {     
      let newvideoUrl=videoUrl.match(/.*\/([^,?]+)/)[0].split('/');
      const videoIndex=newvideoUrl[newvideoUrl.length-1];
      const vimeoUrl: any =  'https://vimeo.com/api/v2/video/' + videoIndex + '.json';
      this.rcycInternalApisService.getVimeoApi(vimeoUrl).subscribe(
        (response) => {
          if (response[0].thumbnail_large) {
            this.response_F[index].field_image = response[0].thumbnail_large;
          }
        },
        (err) => {          
          console.log(err);
        },
      );      
    }

  }

 /* ==============================================================================
     // Function to Set Url for Carousel
  =================================================================================== */
  setCarouselUrl(index: any) {

    if ((this.response_F[index] && this.response_F[index].field_video) &&
       (this.response_F[index] && this.response_F[index].field_image)) {
       this.response_F[index].field_video = this.response_F[index].field_video.changingThisBreaksApplicationSecurity;
      
      this.response_F[index].field_image = this.response_F[index].field_image;

    } else {

      // this.carouselVideoUrl = '';
      // this.showCarouselPlayBtn = false;
      if (this.response_F[index] && this.response_F[index].field_image) {
        this.response_F[index].field_image = this.response_F[index].field_image;
      }

    }
  }

  // Function occur when we click on vimeo lightbox play
  vimeoOpenClick() {
    // $.fancybox.open(
    //   {
    //     "allow": 'autoplay'
    //   }
    // );
    this.isVideoPlaying=false;
    this.isCCEnabled=false;
    this.ADClickStatus=false;
    this.vimeoMuteClickStatus=false;  
    this.initialControlClickStatus=false;
    this.muteStatus = true;
    let vimeoOpenTimeout;
    clearTimeout(vimeoOpenTimeout);      
    vimeoOpenTimeout = setTimeout(() => {
      const fancyVimeoIframe:any=document.querySelector('.fancybox-iframe');
      // console.log(1);
      if(fancyVimeoIframe) {
        if(fancyVimeoIframe.src && (this.current3PlayPuginId && this.loaded3PlayMFId)) {
          fancyVimeoIframe.src=fancyVimeoIframe.src.split('?')[0] + '?autoplay=1&texttrack=null&muted=1&controls=0';
        }
        if(fancyVimeoIframe.src && !(this.current3PlayPuginId && this.loaded3PlayMFId)) {
          fancyVimeoIframe.src=fancyVimeoIframe.src.split('?')[0] + '?autoplay=1&texttrack=null&muted=1&controls=1';
        }
        fancyVimeoIframe.id=this.current3PlayPuginId;        
        fancyVimeoIframe.setAttribute("allow", "autoplay"); 
        fancyVimeoIframe.setAttribute("frameborder", "0"); 
        fancyVimeoIframe.setAttribute("allowfullscreen", "false"); 
        fancyVimeoIframe.classList.add("suite3playIframe");        
        // fancyVimeoIframe.setAttribute("allowfullscreen", "allowfullscreen"); 
        // fancyVimeoIframe.setAttribute("mozallowfullscreen", "mozallowfullscreen"); 
        // fancyVimeoIframe.setAttribute("webkitallowfullscreen", "webkitallowfullscreen");            
        
        const addVimeoControlsParam={
          loaded3PlayMFId: this.loaded3PlayMFId,
          loaded3PlayTargetPluginId: this.loaded3PlayTargetPluginId,
          loadedVimeoId: this.loadedVimeoId,
          current3PlayPuginId:this.current3PlayPuginId,
        } 
        // this.rcyc3playVimeoService.addVimeoControls(addVimeoControlsParam); 
        this.addVimeoControls(addVimeoControlsParam); 
        this.isVideoPlaying=true;       
      }
    }, 1000);
  }

  clickedVideoPause() {

    const playPauseElement:any = document.querySelector('.appendFancy3PlayVimeo .videoplaypauseimage');
    const playPauseElement_A:any = document.querySelector('.appendFancy3PlayVimeo .videoplaypauseimage a');
   
    // this.pauseElement
    if(playPauseElement) {
      playPauseElement.classList.remove("changeBackgroundPause");
      playPauseElement.classList.add("changeBackgroundPlay");

      playPauseElement.title='Play';
      playPauseElement.id='threePlayMediaPlayId';
    } 
   
    if(playPauseElement_A) {
      playPauseElement_A.classList.remove("icon-pause");
      playPauseElement_A.classList.remove("pause");

      playPauseElement_A.classList.add("icon-play");
      playPauseElement_A.classList.add("play");

      // playPauseElement_A.title='Play';
    }   
    
    // CC disable
    const CCElement:any = document.querySelector('.appendFancy3PlayVimeo .ccVideoSubTitle');
    const CCElement_IMG:any = document.querySelector('.appendFancy3PlayVimeo .ccVideoSubTitle img');
    if(CCElement) {
      CCElement.classList.add("disable3play");

      CCElement.tabIndex="-1";
    }
    // if(CCElement_IMG) {
    //   CCElement_IMG.tabIndex="-1";
    // }    
    
    // AD disable
    const ADElement = document.querySelector('.appendFancy3PlayVimeo .adOuter');
    const ADElement_A:any = document.querySelector('.appendFancy3PlayVimeo .adOuter button.mycroft-audio-description-snippet-toggle');
    if(ADElement) {
      ADElement.classList.add("disable3play");
    }
    if(ADElement_A) {
      ADElement_A.tabIndex="-1";
    }    
    // Mute disable
    const muteElement:any = document.querySelector('.appendFancy3PlayVimeo .vimeoMute');
    const muteElement_IMG:any = document.querySelector('.appendFancy3PlayVimeo .vimeoMute img');
    if(muteElement) {
      muteElement.classList.add("disable3play");

      muteElement.tabIndex="-1";
    } 
    // if(muteElement_IMG) {
    //   muteElement_IMG.tabIndex="-1";
    // }   
      
    this.rcyc3playVimeoService.pauseVimeo();
    this.isVideoPlaying = false;    

    this.initialControlClickStatus=true;
  }

  clickedVideoPlay() {

    const playPauseElement:any = document.querySelector('.appendFancy3PlayVimeo .videoplaypauseimage');
    const playPauseElement_A:any = document.querySelector('.appendFancy3PlayVimeo .videoplaypauseimage a');
    if(playPauseElement) {      
      playPauseElement.classList.remove("changeBackgroundPlay");
      playPauseElement.classList.add("changeBackgroundPause");

      playPauseElement.title='Pause';
      playPauseElement.id='threePlayMediaPauseId';
    }
    if(playPauseElement_A) {
      playPauseElement_A.classList.remove("icon-play");
      playPauseElement_A.classList.remove("play");

      playPauseElement_A.classList.add("icon-pause");
      playPauseElement_A.classList.add("pause");

      // playPauseElement_A.title='Pause';
    }   

    // CC enable
    const CCElement:any = document.querySelector('.appendFancy3PlayVimeo .ccVideoSubTitle');
    const CCElement_IMG:any = document.querySelector('.appendFancy3PlayVimeo .ccVideoSubTitle img');
    if(CCElement) {
      CCElement.classList.remove("disable3play");

      CCElement.tabIndex="0";
    }
    
    // AD enable
    const ADElement = document.querySelector('.appendFancy3PlayVimeo .adOuter');
    const ADElement_A:any = document.querySelector('.appendFancy3PlayVimeo .adOuter .mycroft-audio-description-snippet-toggle');
    if(ADElement) {
      ADElement.classList.remove("disable3play");
    }
    if(ADElement_A) {
      ADElement_A.tabIndex="0";
    }    
    // Mute enable
    const muteElement:any = document.querySelector('.appendFancy3PlayVimeo .vimeoMute');
    const muteElement_IMG:any = document.querySelector('.appendFancy3PlayVimeo .vimeoMute img');
    if(muteElement) {
      muteElement.classList.remove("disable3play");

      muteElement.tabIndex="0";
    }
    if (this.rcyc3playVimeoService.current3playVimeoPlayer) {
      this.rcyc3playVimeoService.playVimeo();
      this.isVideoPlaying = true;
    }
    this.initialControlClickStatus=true;
  }

  togleClosedCaption() {
    this.isCCEnabled = !this.isCCEnabled;    
    const CCElement:any = document.querySelector('.appendFancy3PlayVimeo .ccVideoSubTitle');
    const CCElement_A:any = document.querySelector('.appendFancy3PlayVimeo .ccVideoSubTitle a');
    const CCElement_IMG:any = document.querySelector('.appendFancy3PlayVimeo .ccVideoSubTitle img');

    if(!this.isVideoPlaying) {
      CCElement.classList.add("disable3play");
      // CCElement_IMG.tabIndex="-1";
      CCElement.tabIndex="-1";
    } else {
      CCElement.classList.remove("disable3play");
      // CCElement_IMG.tabIndex="0";
      CCElement.tabIndex="0";
    }

    if(this.isVideoPlaying) {
        // For Enable CC
        if (this.isCCEnabled) { 
          
          if(CCElement) {        
            CCElement.classList.remove("ccDisabled3Play");
            CCElement.classList.add("ccEnabled3Play");

            CCElement.title='Disable cc';
          } 
          if(CCElement_A) {
            CCElement_A.classList.remove("play");
            CCElement_A.classList.add("pause");

            // CCElement_A.title='Disable cc';            
          }   

          if(CCElement_IMG) {
            CCElement_IMG.src="../../assets/components/images/Closed_captioning_symbol-reverse.gif";
          }
          this.rcyc3playVimeoService.enableCcVimeo();
        } else {  // For disable CC
          
          if(CCElement) {        
            CCElement.classList.remove("ccEnabled3Play");
            CCElement.classList.add("ccDisabled3Play");

            CCElement.title='Enable cc';
          } 
          if(CCElement_A) {
            CCElement_A.classList.remove("pause");
            CCElement_A.classList.add("play");

            // CCElement_A.title='Enable cc';            
          }   
          if(CCElement_IMG) {
            CCElement_IMG.src="../../assets/components/images/Closed_captioning_symbol.svg";
          }
          this.rcyc3playVimeoService.disableCcVimeo();
        }
    }

    this.initialControlClickStatus=true;
    
  }

    ADClick() {
    this.rcyc3playVimeoService.setMuteStatus(this.muteStatus).then(
      (status)=>{
        this.muteStatus = status;
      }
    )
    this.ADClickStatus = !this.ADClickStatus;
    const ADElement = document.querySelector('.appendFancy3PlayVimeo .adOuter');
    // const ADElement_Title:any = document.querySelector('.appendFancy3PlayVimeo i.p3sdk-fa-audio-description');    
    const ADElement_Title:any = document.querySelector('.appendFancy3PlayVimeo .adOuter button.mycroft-audio-description-snippet-toggle');
    const ADElement_A:any = document.querySelector('.appendFancy3PlayVimeo .adOuter .mycroft-audio-description-snippet-toggle');

    if(this.isVideoPlaying) {
      // For Enable AD
      if (this.ADClickStatus) { 
        
        if(ADElement) {        
          ADElement.classList.remove("audioDisable3Play");
          ADElement.classList.add("audioEnable3Play");
        } 
        if(ADElement_Title) {
          ADElement_Title.title="Disable AD";
        }
      } else {  // For disable AD
        
        if(ADElement) {        
          ADElement.classList.remove("audioEnable3Play");
          ADElement.classList.add("audioDisable3Play");
        } 
        if(ADElement_Title) {
          ADElement_Title.title="Enable AD";
        }
      }
    }
    
  }

  vimeoVolumeControlClick(value) {
    this.volumeLevel = value;
    this.rcyc3playVimeoService.setVolume(value);
  }

  vimeoMuteClick() {
    this.rcyc3playVimeoService.setMuteStatus(!this.muteStatus).then(
      (status)=>{
        this.muteStatus = status;
        if(!this.muteStatus){
          this.rcyc3playVimeoService.setVolume(this.volumeLevel);
        } else {
          this.rcyc3playVimeoService.setVolume0();
        }
      }
    )
    this.vimeoMuteClickStatus=!this.vimeoMuteClickStatus;    
    const muteElement:any = document.querySelector('.appendFancy3PlayVimeo .vimeoMute');
    const muteElement_A:any = document.querySelector('.appendFancy3PlayVimeo .vimeoMute a');
    const muteElement_IMG:any = document.querySelector('.appendFancy3PlayVimeo .vimeoMute img');
    const volumeControl:any = document.querySelector('.appendFancy3PlayVimeo .vimeoControl');

    if(!this.isVideoPlaying) {
      if(muteElement) {
        muteElement.classList.add("disable3play");
        // muteElement_IMG.tabIndex="-1";
        muteElement.tabIndex="-1";
      }
    } else {
      if(muteElement) {
        muteElement.classList.remove("disable3play");
        // muteElement_IMG.tabIndex="0";
        muteElement.tabIndex="0";
      }
    }

    if(this.isVideoPlaying) {
        // For Mute
        if (this.vimeoMuteClickStatus) { 
          // console.log('Mute click');         
          if(muteElement) {        
            muteElement.classList.remove("vimeoMute3Play");
            muteElement.classList.add("vimeoUnmute3Play");

            muteElement.title='Mute';
          } 
          if(muteElement_A) {
            muteElement_A.classList.remove("play");
            muteElement_A.classList.add("pause");

            // muteElement_A.title='Unmute';
          }   

          if(muteElement_IMG) {
            muteElement_IMG.src="../../assets/components/images/unmute.png";
          }
          if (volumeControl) {
            volumeControl.classList.remove("vimeoVolumeControlHide");
          }
        } else {  // For Unmute
          // console.log('Unmute click');     
          if(muteElement) {        
            muteElement.classList.remove("vimeoUnmute3Play");
            muteElement.classList.add("vimeoMute3Play");

            muteElement.title='Unmute';
          } 
          if(muteElement_A) {
            muteElement_A.classList.remove("pause");
            muteElement_A.classList.add("play");
            
          }   
          if(muteElement_IMG) {
            muteElement_IMG.src="../../assets/components/images/mute.png";
          }
          if (volumeControl) {
            volumeControl.classList.add("vimeoVolumeControlHide");
          }
        }
    }

    this.initialControlClickStatus=true;
  }

  mouseOver(){
    if (document.querySelector(".vimeoUnmute3Play")) {
      const volumeControl:any = document.querySelector('.appendFancy3PlayVimeo .vimeoControl');
      volumeControl.classList.remove("vimeoVolumeControlHide");
    }

  }

  mouseOut(){
    if (document.querySelector(".vimeoUnmute3Play")) {
      const volumeControl:any = document.querySelector('.appendFancy3PlayVimeo .vimeoControl');
      volumeControl.classList.add("vimeoVolumeControlHide");
    }
  }

  addVimeoControls(item) {   
    // console.log(3); 
    const fancyVimeoTarget=document.querySelector('.fancybox-content');
    fancyVimeoTarget.addEventListener("mouseover", this.mouseOver);
    fancyVimeoTarget.addEventListener("mouseout", this.mouseOut);
    const playPauseDiv:any = '<div id="threePlayMediaPauseId" class="btnOverHero videoplaypauseimage changeBackgroundPause" tabindex="0" title="Pause">'+
                                '<a class="icon icon-pause pause"> </a>'+
                              '</div>';
    const CCDiv:any = '<div id="threePlayMediaCCId" class="btnOverHero ccVideoSubTitle ccDisabled3Play" tabindex="0" title="Enable cc">'+             
                        '<a class="icon play">'+
                          '<img src="../../assets/components/images/Closed_captioning_symbol.svg" />'+
                        '</a>'+
                      '</div>';
    const ADDiv:any = '<div id="threePlayMediaADId" class="btnOverHero adOuter audioDisable3Play">'+
                        '<div id="'+this.loaded3PlayTargetPluginId+'" class="p3sdk-target"></div>'+                     
                      '</div>';
    const muteUnmuteDiv:any = '<div id="threePlayMediaMuteId" class="btnOverHero vimeoMute vimeoMute3Play" tabindex="0" title="Unmute">'+
                                '<a class="icon play">'+
                                  '<img src="../../assets/components/images/mute.png" />'+
                                '</a> '+                           
                              '</div>';
    const volumeRef:any = '<div id="threePlayMediaVolumecontrol" class="btnOverHero vimeoControl vimeoVolumeControlHide"  tabindex="0" title="Control">'+
                              '<input #vimeoVolumeRef id="vimeoVolcontrolId" type="range" min="0" max="10" step="1"/>'+
                          '</div>';
    let totalDiv;
    // console.log(this.loaded3PlayTargetPluginId, 'this.loaded3PlayTargetPluginId');
    if(this.loaded3PlayTargetPluginId) {
      // console.log(31); 
      totalDiv = playPauseDiv+CCDiv+ADDiv+muteUnmuteDiv+volumeRef;
          
        const newappendDiv=this.renderer2.createElement('div');        
        fancyVimeoTarget.appendChild(newappendDiv);
        newappendDiv.className="appendFancy3PlayVimeo";
        const newTarget=document.querySelector('.appendFancy3PlayVimeo') as HTMLElement;
        newTarget.innerHTML=totalDiv;

        let add3playUrlTimeout;
        clearTimeout(add3playUrlTimeout);   
        add3playUrlTimeout = setTimeout(() => { 
          const add3PlayScriptParam={
            loaded3PlayMFId: this.loaded3PlayMFId,
            loaded3PlayTargetPluginId: this.loaded3PlayTargetPluginId,
            loadedVimeoId: this.loadedVimeoId,
            current3PlayPuginId:this.current3PlayPuginId,
          }
          this.rcyc3playVimeoService.add3PlayScript(add3PlayScriptParam); 

          this.playerInit();
                
        }, 500);
      
        let ADTitleTimeout;
        clearTimeout(ADTitleTimeout);   
        ADTitleTimeout =setTimeout(() => {           
            const ADElement_Title:any = document.querySelector('.appendFancy3PlayVimeo .adOuter button.mycroft-audio-description-snippet-toggle');
            if(ADElement_Title) {
              ADElement_Title.title='Enable AD';         
            }
            
            const ADElement_TabIndex:any=document.querySelector('.appendFancy3PlayVimeo .adOuter button.mycroft-audio-description-snippet-toggle');
            if(ADElement_TabIndex) {                  
              ADElement_TabIndex.tabIndex='0';
            }
            const iframeVimeo_TabIndex:any=document.getElementById(this.current3PlayPuginId);
            if(iframeVimeo_TabIndex) {                 
              iframeVimeo_TabIndex.tabIndex='-1';
            }
        }, 10000);
    }
    document.getElementById("threePlayMediaPauseId").addEventListener("touchstart", this.clickKeyupEnterFn);
    document.getElementById("threePlayMediaCCId").addEventListener("touchstart", this.clickKeyupEnterFn);
    document.getElementById("threePlayMediaMuteId").addEventListener("touchstart", this.clickKeyupEnterFn);
    document.getElementById("vimeoVolcontrolId").addEventListener("touchstart", this.clickKeyupEnterFn);

    document.getElementById("threePlayMediaPauseId").addEventListener("click", this.clickKeyupEnterFn);
    document.getElementById("threePlayMediaCCId").addEventListener("click", this.clickKeyupEnterFn);
    document.getElementById("threePlayMediaADId").addEventListener("click", this.clickKeyupEnterFn);
    document.getElementById("threePlayMediaMuteId").addEventListener("click", this.clickKeyupEnterFn);
    document.getElementById("vimeoVolcontrolId").addEventListener("click", this.clickKeyupEnterFn);

  }

  playerInit() {    
    setTimeout(
      () => {
        this.rcyc3playVimeoService.setPlayerInstance(this.current3PlayPuginId);
        if (this.current3PlayPuginId) {
          this.rcyc3playVimeoService.toggleLoop(false);
          this.rcyc3playVimeoService.setVolume0();
          
          setTimeout(()=>{
            this.isCCEnabled = !this.isCCEnabled;
            this.togleClosedCaption();
            this.rcyc3playVimeoService.setMuteStatus(this.muteStatus).then((status) => {
              if(!this.muteStatus){
                this.rcyc3playVimeoService.setVolume(this.volumeLevel);
              }
              this.muteStatus = status              
              this.getEndedAndReCallfuntions();
            });
            $('.appendFancy3PlayVimeo').css({'display': 'block'}); 
                                       
          },2500)
        }
      }, 100
    )
  }

  getEndedAndReCallfuntions() {   
    this.rcyc3playVimeoService.getEnded().then(
      (response) => {               
        let videoId = Number(this.current3PlayPuginId.split("-").pop());
        this.rcyc3playVimeoService.loadVideo(videoId).then(
          (response) => {            
            this.rcyc3playVimeoService.pauseVimeo(); 
            this.rcyc3playVimeoService.playVimeo();
            this.playerInit();// call recursively to loop manually               
          }
        ).catch(
          (error) => {
            console.log(error);
          }
        );
      }
    );
    if (this.rcyc3playVimeoService.current3playVimeoPlayer) {
      this.rcyc3playVimeoService.pauseVimeo();
      this.rcyc3playVimeoService.playVimeo();
    }
  }

  // Function addevenListener
  clickKeyupEnterFn(event) {

    if (event.currentTarget.attributes.id.value == 'threePlayMediaADId'){
      this.ADClick();
    }
    
    if(event.target&& event.target.attributes&& event.target.attributes.id&& event.target.attributes.id.value) {      
      if(event.target.attributes.id.value == 'threePlayMediaPauseId') {

        this.clickedVideoPause();      
        return false;
      }
      if(event.target.attributes.id.value == 'threePlayMediaPlayId') {

        this.clickedVideoPlay();       
        return false;
      }

      if(event.target.attributes.id.value == 'threePlayMediaCCId') {

        this.togleClosedCaption();
        return false;
      }
      if(event.target.attributes.id.value == 'threePlayMediaMuteId') {
        // console.log(4);
        this.vimeoMuteClick();
        return false;
      }
      if(event.target.attributes.id.value == 'vimeoVolcontrolId') {
        const volumeLevel = (event.target.value)/10;
        this.vimeoVolumeControlClick(volumeLevel);
        return false;
      }

    }

    /* == AD Area == */
    // if(currentEvent != 'touchstart') {
    //   if(
    //     event.target as HTMLElement ==
    //     document.querySelector('.appendFancy3PlayVimeo .adOuter .mycroft-audio-description-snippet-toggle') as HTMLElement
    //   ) {
    //       this.ADClick();
    //       return false;
    //   }
    // }
  }

  ngOnDestroy() {
    if(this.current3PlayPuginId) {
      if(this.isCCEnabled) { //If ccEnable, ccDisable it
        this.isCCEnabled=false;
        this.rcyc3playVimeoService.disableCcVimeo();
      }
      
      if(!this.muteStatus) { //If unmute, mute it
        this.muteStatus=true;
        this.vimeoMuteClick();
      }
      if(!this.isVideoPlaying) { //If pause, play it
        this.isVideoPlaying=true;
        this.clickedVideoPlay();
      }
      if(this.ADClickStatus) { //If pause, play it
        this.ADClickStatus=false;
        this.rcyc3playVimeoService.setMuteStatus(true).then(
          (status)=>{
            this.muteStatus = status;
          }
        )
      }
    }    
  }

}
