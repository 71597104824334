import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RcycCommonService } from './../../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycHomeService } from '../../rcyc-home/rcyc-home.service';
import { RcycVoyagesService } from '../../rcyc-voyages/rcyc-voyages.service';

@Component({
  selector: 'app-rcyc-card-featured',
  templateUrl: './rcyc-featured.component.html',
  providers: [RcycHomeService, RcycVoyagesService]
})

export class RcycFeaturedComponent implements OnInit {
  @Input() data: any;
  @Input() variant: any;
  @Input() imageScrollPosition: string;
  @Input() hideButtons: boolean = false;
  @Input() hideInfo: boolean = false;
  @Input() backButton: any = false;
  @Input() parent: string = 'none';
  @Input() showPorts: Boolean = false;
  @Input() currencyCode: any;
  @Input() additionalCostInfo: any;
  @Input() PCFIToggleInfo: any;
  @Output() setPreviousUrlForVoyagePageBack = new EventEmitter<string>();

  detailsUrl: any;
  highlight: any;
  itineraries: any;
  ports: any = [];
  textSectionCSSClasses: string = '';
  voyageDetailsCSSClasses: string = '';

  constructor(
    private rcycHomeService: RcycHomeService,
    private rcycCommonService: RcycCommonService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private rcycVoyagesService: RcycVoyagesService,
  ) {
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
    this.calcStyles();
  }

  ngOnInit(): void {
    this.imageScrollPosition = this.imageScrollPosition ? this.imageScrollPosition : 'map';
    this.data.slider = this.data.slider && this.data.slider.length ? this.data.slider : [];
    if ((this.variant === 'grid' || this.variant === 'list')) {
      this.getVoyageMapImage();
    }
    this.currencyCode = this.currencyCode ? this.currencyCode : this.data?.feeCurrency;
    this.calcStyles();
    this.setAnchorData();
  }

  setAnchorData() {
    let voyageEmbarkPort_new;
    let voyageDisembarkPort_new;
    if (this.data?.voyageEmbarkPort) {
      voyageEmbarkPort_new = this.data?.voyageEmbarkPort
        .replace(/[^a-zA-Z-]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
    }
    if (this.data?.voyageDisembarkPort) {
      voyageDisembarkPort_new = this.data?.voyageDisembarkPort
        .replace(/[^a-zA-Z-]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
    }

    this.data?.portCodes?.map(portId => {
      const port = this.data?.portData.filter(item => item.portCode === portId && item.portName !== 'Sailing Time')[0];
      if (port) {
        this.ports.push({
          name: port.portName,
          code: portId,
        })
      }
    });
    this.detailsUrl = '/' + this.data?.voyageUrlPath + '/' + voyageEmbarkPort_new + '-to-' + voyageDisembarkPort_new + '-' + this.data?.voyageId;
  }

  calcStyles() {
    let classNames: string = '';
    switch (this.variant) {
      case 'list':
        classNames = 'xl:p-8 p-6';
        break;
      case 'excursion':
        classNames = 'xl:p-8 lg:p-6 p-4 gap-8';
        break;
      case 'featured':
        classNames = 'xl:p-10 p-6 lg:flex-col';
        break;
      case 'grid':
        classNames = 'flex-col 2xl:p-8 p-4 grow-0';
        break;
      case 'grid-small':
        classNames = 'flex-col lg:p-8 p-4';
        break;
    }
    this.textSectionCSSClasses = classNames;

    classNames = '';
    switch (this.variant) {
      case 'list':
        classNames = 'sm:flex-row flex-col gap-3 lg:gap-12 mb-0';
        break;
      case 'excursion':
        classNames = 'flex-col gap-3 mb-0';
        break;
      case 'featured':
        classNames = 'flex-col sm:flex-row gap-3 lg:gap-12 mb-6';
        break;
      case 'grid':
      case 'grid-small':
        classNames = 'flex-col gap-3 mb-6 ';
        break;
    }
    this.voyageDetailsCSSClasses = classNames;
  }

  setPrevPage() {
    if (this.backButton) {
      this.rcycCommonService.setSessionData('backToResultVoyageListPrevUrl', this.router.url);
      this.setPreviousUrlForVoyagePageBack.emit(this.router.url);
    }
  }

  getVoyageMapImage() {
    const drupalImageObjectNot = this.rcycCommonService.getSessionData('drupalImageObjectNot');
    this.rcycCommonService.drupalApisGet('/api/v1/voyages/' + this.data?.voyageId + '/tab/itineraryoverview?_format=json').subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          const res = response && response.length > 0 && Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.data.slider.push(res[0].field_voyage_image);
            if (drupalImageObjectNot) {
              this.data.slider.push([drupalImageObjectNot[this.data?.voyageId].field_voyage_thumbnail]);
            }
          }
        } else {
          drupalImageObjectNot[this.data?.voyageId]?.field_voyage_thumbnail ?? this.data.slider.push([drupalImageObjectNot[this.data?.voyageId]?.field_voyage_thumbnail]);
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
