import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY as empty } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as reservationActions from '../actions/rcyc-reservation.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RcycCommonLogoutService } from '../../rcyc-services/rcyc-logout/rcyc-logout.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';

@Injectable()
export class ReservationEffects {
    successMessages: any;
    errorMessages: any;
    infoMessages: any;
    countryPhoneNo:any;

    
    ListBookings = createEffect(() => (this.actions).pipe(
        ofType(reservationActions.ReservationActionTypes.LIST_BOOKING),
        map((action: reservationActions.BookingList) => action.payload),
        switchMap((payload) => {
            return this._RcycInternalApisService.postReservationFormWithAuth(payload.apiUrl, payload.param).pipe(
                map((data: any) => {
                    if (data && data.code === 200) {
                        return new reservationActions.BookingListSuccess(data);
                    } else {
                      data['DrupalMessage'] = this.setErrorFunction(data);
                        return new reservationActions.BookingListFailedMsg(data);
                    }
                }), catchError((error) => {
                    console.log(error);
                    this.store.dispatch(new reservationActions.BookingListFailedStatus(true));
                    this.store.dispatch(new reservationActions.BookingListFailedMsg(this.setErrorFunction(error)));
                    return empty;
                })
            )
        }
        )
    ))

    
    GetBookingDetailsById = createEffect(() => (this.actions).pipe(
        ofType(reservationActions.ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS),
        map((action: reservationActions.BookingDetails) => action.payload),
        switchMap((payload: any) => {
            return this._RcycInternalApisService.postReservationFormWithAuth(payload.apiUrl, payload.params).pipe(
                map((data: any) => {
                    if ( (data && data.code === 200) ) {
                        return new reservationActions.BookingDetailsSuccess(data);                    
                    }
                    else {
                        data['DrupalMessage']=this.setErrorFunction(data);
                        return new reservationActions.BookingDetailsFailedMsg(data);
                    }
                }), catchError((error) => {
                    console.log(error);
                    this.store.dispatch(new reservationActions.BookingDetailsFailedMsg(this.setErrorFunction(error)));
                    this.store.dispatch(new reservationActions.BookingDetailsFailed(true));
                    return empty
                })
            )
        }
        )
    ))

    //Shore Ex get Reservation list
    
    getShoreExDetailsByPort = createEffect(() => (this.actions).pipe(
        ofType(reservationActions.ReservationActionTypes.MY_RESERVATION_SHORE_EX_BOOKING),
        map((action: reservationActions.BookingShoreExDetails) => action.payload),
        switchMap((payload: any) => {
            return this._RcycInternalApisService.postReservationFormWithAuth(payload.apiUrl, payload.params).pipe(
                map((data: any) => {
                    return new reservationActions.BookingShoreExDetailsSuccess(data);
                }), catchError((error) => {
                    console.log(error);
                    this.store.dispatch(new reservationActions.BookingShoreExDetailsFailed(error));
                    return empty
                })
            )
        }
        )
    ))

    //Marquee get reservation List
    
    getMarqueeDetailsByPort = createEffect(() => (this.actions).pipe(
        ofType(reservationActions.ReservationActionTypes.MY_RESERVATION_MARQUEE_BOOKING),
        map((action: reservationActions.BookingMarqueeDetails) => action.payload),
        switchMap((payload: any) => {
            return this._RcycInternalApisService.postReservationFormWithAuth(payload.apiUrl, payload.params).pipe(
                map((data: any) => {
                    return new reservationActions.BookingMarqueeDetailsSuccess(data);
                }), catchError((error) => {
                    console.log(error);
                    this.store.dispatch(new reservationActions.BookingMarqueeDetailsFailed(error));
                    return empty
                })
            )
        }
        )
    ))

    //Dining get reservation List
    
    getDiningDetailsByPort = createEffect(() => (this.actions).pipe(
        ofType(reservationActions.ReservationActionTypes.MY_RESERVATION_DINING_BOOKING),
        map((action: reservationActions.BookingDiningDetails) => action.payload),
        switchMap((payload: any) => {
            return this._RcycInternalApisService.postReservationFormWithAuth(payload.apiUrl, payload.params).pipe(
                map((data: any) => {
                    return new reservationActions.BookingDiningDetailsSuccess(data);
                }), catchError((error) => {
                    console.log(error);
                    this.store.dispatch(new reservationActions.BookingDiningDetailsFailed(error));
                    return empty
                })
            )
        }
        )
    ))

    //SPA get reservation List
    
    getSpaDetailsByPort = createEffect(() => (this.actions).pipe(
        ofType(reservationActions.ReservationActionTypes.MY_RESERVATION_SPA_BOOKING),
        map((action: reservationActions.BookingSPADetails) => action.payload),
        switchMap((payload: any) => {
            return this._RcycInternalApisService.postReservationFormWithAuth(payload.apiUrl, payload.params).pipe(
                map((data: any) => {
                    return new reservationActions.BookingSPADetailsSuccess(data);
                }), catchError((error) => {
                    console.log(error);
                    this.store.dispatch(new reservationActions.BookingSPADetailsFailed(true));
                    return empty
                })
            )
        }
        )
    ))
    setErrorFunction(data: any) {
        try {
            // console.log(data,'err');
            if (data) {
                this.errorMessages.field_no_reservations_found = this.errorMessages.field_no_reservations_found.replace(/{office_phone_number}/g, this.countryPhoneNo);
                this.errorMessages.field_guest_auth_error = this.errorMessages.field_guest_auth_error.replace(/{office_phone_number}/g, this.countryPhoneNo);
                this.errorMessages.field_invalid_booking_id = this.errorMessages.field_invalid_booking_id.replace(/{office_phone_number}/g, this.countryPhoneNo);
                this.errorMessages.field_travel_agent_auth_error = this.errorMessages.field_travel_agent_auth_error.replace(/{office_phone_number}/g, this.countryPhoneNo);
                this.errorMessages.field_dashboard_loading_error = this.errorMessages.field_dashboard_loading_error.replace(/{office_phone_number}/g, this.countryPhoneNo);
                switch (data.errcode) {
                    case 407:
                        return this._RcycCommonLogoutService.autoLogout();
                    case 467:
                        return this.errorMessages.field_no_reservations_found;
                    case 478:
                        return this.errorMessages.field_guest_auth_error;
                    case 479:
                    return this.errorMessages.field_invalid_booking_id;

                    case 482:
                    return this.errorMessages.field_travel_agent_auth_error;
                    case 485:
                    return this.errorMessages.field_expired_voyage;
                    default:
                        return this.errorMessages.field_dashboard_loading_error;
                }
            } else {
                return this.errorMessages.field_dashboard_loading_error;
            }
        }
        catch (err) {
            console.log(err);
            console.log('Err Dashboard Guest get Booking details');
            return this.errorMessages.field_dashboard_loading_error;
        }
    }
    constructor(private actions: Actions, public store: Store<AppState>,
        private _RcycCommonLogoutService: RcycCommonLogoutService,
        private _RcycInternalApisService: RcycInternalApisService,
        private _RcycCommonService: RcycCommonService,
        private _RcycSharedServiceService: SharedServiceService
    ) {
        
        this._RcycCommonService.countryPhoneNo.subscribe(countryPhoneNo => {
            this.countryPhoneNo = countryPhoneNo;
          });
        this._RcycCommonService.currentErrorMessageSource$.subscribe((value) => {
            if (!value) {
                this._RcycSharedServiceService.getErrorCommonMsg().subscribe((response) => {
                    this.errorMessages = response;
                    this._RcycCommonService.setErrorMessageSource(response);
                })
            } else
                this.errorMessages = value;
            });

        this._RcycSharedServiceService.getSuccessCommonMsg().subscribe(
            (res) => {
                this.successMessages = res;
            })
            this._RcycCommonService.currentInfoMessageSource$.subscribe((value) => {
                if (!value) {
                    this._RcycSharedServiceService.getInfoCommonMsg().subscribe((response) => {
                        this.infoMessages = response;
                        this._RcycCommonService.setInfoMessageSource(response);
                    })
                } else {
                    this.infoMessages = value;
                }
            })
    }
}
