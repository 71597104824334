<swal #diningSwal (beforeOpen)="beforeOpen($event)" (open)="onOpen($event)" [showConfirmButton]="false"
  [showCancelButton]="false" [showCloseButton]="true" customClass="diningSwtOuter" [allowOutsideClick]="false"
  [animation]="false" (close)="onClose($event)" [reverseButtons]="true" [width]="'75%'">
  <ng-container *swalPartial>
    <div id="rcyc-itinerary-verview-modal">
      <div class="py-8 px-0">
        <div
          [class]="portExcursionsData && portExcursionsData.length > 0 ? 'flex lg:flex-row flex-col gap-8 lg:gap-12 mx-0 lg:px-4 pb-12 shadow-md' : 'flex lg:flex-row flex-col gap-8 lg:px-4 lg:gap-12 mx-0 pb-12'">
          <div class="lg:w-1/2 lg:px-0 pr-[1px]">
            <div class="aspect-[16/9] px-0">
              <div class="w-full h-auto block visible lg:hidden px-4">
                <div
                  class="text-sm uppercase mb-6 font-normal text-[14px] leading-[23.1px] tracking-[1.2px] text-[#1C1C1C] font-['proxima-nova']"
                  *ngIf="itineraryOverviewData?.dayStop">
                  {{itineraryOverviewData?.dayStop}}
                </div>
                <h2
                  class="font-normal mb-8 lg:mb-10 text-[26px] leading-[29.9px] tracking-[1.2px] text-[#1C1C1C] mt-[12px] font-['adobe-caslon-pro']">
                  {{itineraryOverviewData?.field_sub_header}}
                </h2>
              </div>
              <!-- Content Slider -->
              <div class="">
                <app-rcyc-slider *ngIf="itineraryOverviewData?.imageSliderData" pageName="voyage" variant="barSmall"
                  id="dining-modal-content-slider" [data]="{
                  items: itineraryOverviewData?.imageSliderData,
                  noGradient: true
                }"></app-rcyc-slider>
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 flex flex-col gap-4 mt-1 px-4">
            <div class="'lg:flex flex-col">
              <div class="mb-8 lg:mb-10">
                <div class="w-full h-auto hidden lg:block lg:visible lg:static">
                  <div
                    class="text-sm uppercase mb-6 font-normal text-[14px] leading-[23.1px] tracking-[1.2px] text-[#1C1C1C] font-['proxima-nova']"
                    *ngIf="itineraryOverviewData?.dayStop">
                    {{itineraryOverviewData?.dayStop}}
                  </div>
                  <h2
                    class="font-normal mb-8 lg:mb-10 text-[39px] leading-[48.85px] tracking-[1.2px] text-[#1C1C1C] mt-[12px] font-['adobe-caslon-pro']">
                    {{itineraryOverviewData?.field_sub_header}}
                  </h2>
                </div>
                <div
                  class="flex flex-col gap-6 uppercase text-[16px] font-normal leading-[23.1px] tracking-[1.2px] text-[#1C1C1C] font-['proxima-nova']">
                  <div class="">
                    <div>{{itineraryOverviewData?.date | date:"fullDate":"UTC"}}</div>
                  </div>
                  <div *ngIf="itineraryOverviewData?.arrivalTime" class="flex flex-row gap-3">
                    <div>Arrival:</div>
                    <span class="">{{itineraryOverviewData?.arrivalTime | date:"HH:mm":"UTC" }}</span>
                  </div>
                  <div *ngIf="itineraryOverviewData?.departureTime" class="flex flex-row gap-3">
                    <div>Departure:</div>
                    <span class="">{{itineraryOverviewData?.departureTime | date:"HH:mm":"UTC" }}</span>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col gap-2 lg:gap-6 text-[16px] font-normal leading-[23.1px] tracking-[1.2px] text-[#1C1C1C] font-['proxima-nova']"
                *ngIf="!hideButtons" [innerHTML]="itineraryOverviewData?.body">
              </div>
            </div>
          </div>
        </div>
        <h2 *ngIf="portExcursionsData && portExcursionsData.length > 0"
          class="w-full h-full py-12 px-6 mx-6 mb-0 hidden lg:block lg:visible lg:static">
          Excursions</h2>
        <h2 *ngIf="itineraryOverviewData?.title && portExcursionsData && portExcursionsData.length > 0" class="w-full py-12 px-2 mx-2 mb-0 block visible lg:hidden">
          {{itineraryOverviewData?.title}}</h2>
        <div class="flex flex-col overflow-auto gap-8 px-2 mx-2 lg:px-6 lg:mx-6">
          <div *ngFor="let item of portExcursionsData; let i=index;">
            <app-rcyc-card-featured *ngIf="item" class="h-full" [variant]="'excursion'" [staticDrupalTexts]="staticDrupalTexts"
              [data]="item"></app-rcyc-card-featured>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</swal>