import { Component, OnInit } from '@angular/core';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from '../../rcyc-config/config';
import { RcycFormTokenService } from '../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { ValidatePhoneNumber , PhoneDataFormat, formatPhoneList, deepPhoneConfiguration} from '../../rcyc-share-components/rcyc-phonenumber-validator/rcyc-phonenumber-validator';
// import { CookiesService} from "@ngx-utils/cookies";
import {CookieService} from 'ngx-cookie-service';
import { getMaskedCode } from '../../../utils/phone-number-validation';
import { PreviewVoyageList, RcycNewFindACruiseService } from '../rcyc-new-find-a-cruise/rcyc-new-find-a-cruise.service';
declare let ga: Function;

@Component({
  selector: 'app-rcyc-request-quote-next-gen',
  templateUrl: './rcyc-request-quote-next-gen.component.html',
  styleUrls: ['./rcyc-request-quote-next-gen.component.css'],
})
export class RcycRequestQuoteComponentNextGen implements OnInit {

  IMG_BASE_URL: any =   AppConfig.IMG_BASE_URL;
  IMG_CDNHOST_URL: any =   AppConfig.IMG_CDNHOST_URL;

  leadGenForm: any;

  apiResponseA: any;
  apiResponseB: any;
  apiResponseC: any;
  apiResponseElasticA: any;
  apiResponseComboId: any;
  apiResponseVoyage: any = {};
  requestQuoteFormResponse: any = {};

  apiResponseAStatus: any = false;
  apiResponseBStatus: any = false;
  apiResponseCStatus: any = false;
  apiResponseVoyageStatus: any = false;

  apiUrlA: string;
  apiUrlB: string;
  apiUrlC: string;
  apiUrlMetatag: string;
  apiUrlVoyage: string;
  apiUrlRequestQuoteSubmit: string;
  apiUrlComboId: string;

  voyageId: any;
  voyageImagePath: any;
  geoApiResponse: any = {};

  requestQuoteFormToken: any;

  formSubmitFailStatus = false;
  tokenErrorMsg: any = this.rcycFormTokenService.tokenErrorMsg;
  userAreaDetails: any;
  userArea = '';
  apiUrlD: string;
  rescoVoyageCode = '';
  previousUrlStatus = false;
  apiUrlE: string;
  voyagePageUrlStatus = false;
  isMobilebutton: boolean;
  
  flagCodeClickStatus = false;
  countrySearch: string;
  userDetails: any = [];
  formttedPhoneList: any = {};
  phNumReqStatusDynamic = false;
  zipcodeReqStatusDynamic = false;
  phoneDataArray: PhoneDataFormat[];
  userCountryDetails: PhoneDataFormat;
  permanentGeoLocation: any;
  countryListArray: any;
  tokenMessage:any;
  quoteFormStatus = false;
  voyageListArray: any = [];
  voyageApiResponse:any = {};
  selectedVoyageId: any;
  isRaqGeneric: boolean = false;
  isRaqSpecific: boolean = false;
  isIlmaVoyage: boolean = false;

  phoneFieldTouchStatus = false;
  zipcodeMaxLength: number=10;
  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlPath: any;
  isRaqGenericOrSpecific=false;
  specialCountryInputClickStatus=false;
  specialCountryInputCloseBtnStatus=false;
  isSpecialCountrySelect=false;
  selectCountryCode: string;
  specialSelectedVoyageClickStatus = false;
  specialSelectedVoyageCloseBtnStatus = false;
  isSpecialVoyageSelect = false;
  mask = [];
  showPhoneopt = false;
  showTermUS = false;
  showPhoneOptRequiredText = false;
  drupalErrorMessages: any;
  yachtId: any ;


  specialOfferFormErrorMsg:string;

  constructor(

    private formBuilder: UntypedFormBuilder,
    private rcycCommonService: RcycCommonService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rcycFormTokenService: RcycFormTokenService,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycHomeService: RcycHomeService,
    private cookies: CookieService,
    private rcycNewFindACruiseService: RcycNewFindACruiseService,
  ) {
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlPath = this.currentUrlPathAsArray[0].path;
    if (this.currentUrlPath == "next-gen-request-quote") {
      this.isRaqGenericOrSpecific = true;
    }
    this.activatedRoute.params.subscribe((params) => {
      this.voyageId = params.voyageId;
      if (this.voyageId) {
        this.isRaqSpecific = true;
      } else {
        this.isRaqSpecific = false;
      }
      this.checkForRedirection();
    });
    if (this.voyageId) {
      // regex for only 1 letter and ending with that letter
      const voyageIdPregMAtch = /^[^a-z]*([a-z])$/i;
      if (!isNaN(this.voyageId) && this.voyageId.length === 8) {
        this.voyageDetailsApiCall();
      } else if (
        this.voyageId.length === 9 &&
        voyageIdPregMAtch.test(this.voyageId)
      ) {
        this.voyageDetailsApiCall();
      } else {
        this.router.navigate(["/request-quote"]);
      }
    } else {
      this.constructorDataFn();
    }

    if (
      this.rcycCommonService.getSessionData("backToResultVoyageListPrevUrl")
    ) {
      const currentUrl = this.rcycCommonService.getSessionData(
        "backToResultVoyageListPrevUrl"
      );
      if (this.voyageId) {
        this.previousUrlStatus = true;
        this.voyagePageUrlStatus = false;
      }
      if (this.rcycCommonService.getSessionData("requestAQuoteClick")) {
        this.previousUrlStatus = false;
        this.voyagePageUrlStatus = true;
        this.rcycCommonService.removeSessionData("requestAQuoteClick");
      }
    }

  }
  
  ngOnInit() {

    this.getYachtId()
    this.leadGenForm = this.formBuilder.group({
      firstname: [
        "",
        [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ-.'_ ]*")],
      ],
      lastname: [
        "",
        [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ-.'_ ]*")],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$"
          ),
        ],
      ],
      country: ["", Validators.required],
      countryCode: ["US", Validators.required],
      zipcode: [""],
      phone: ["", Validators.required],
      preferredVoyage: [""],
      comment: [""],
      isMobile: [false],
      isAgreeToReceiveMail: [false],
      isAgreeToContact: [false],
      isAgreeToSms: [false],
      dialCode: [""],
    });
    this.http.get("./assets/select_country.json").subscribe((data) => {
      this.countryListArray = data;
    });
    this.http
      .get("./assets/rescoNumberFormatted.json")
      .subscribe((phoneDataArray: PhoneDataFormat[]) => {
        this.phoneDataArray = phoneDataArray;
        this.formttedPhoneList = formatPhoneList(phoneDataArray);
        this.deepPhoneConfigurations();
      });
    this.formValueChangeCapture();
    const userData = JSON.parse(localStorage.getItem("loggedUserDetails"));
    if (userData && userData.jwtToken) {
      this.getMyProfileDetails();
    }
    this.getDrupalErrorMessages();
  }

  deepPhoneConfigurations() {
    let res: {
      formttedPhoneList: any;
      userCountryDetails: PhoneDataFormat;
      phoneDataArray: PhoneDataFormat[];
    } = deepPhoneConfiguration(this.phoneDataArray, this.formttedPhoneList);
    this.formttedPhoneList = res.formttedPhoneList;
    this.userCountryDetails = res.userCountryDetails;
    this.phoneDataArray = res.phoneDataArray;
    this.permanentGeoLocation = Object.assign({}, this.userCountryDetails);
    this.leadGenForm.get("countryCode").setValue(this.userCountryDetails.code);
    this.leadGenForm
      .get("dialCode")
      .setValue(this.userCountryDetails.dial_code);
    this.mask = getMaskedCode(res.userCountryDetails);
  }
  
  /**
   * Function for form value changes
   */
  formValueChangeCapture() {
    this.leadGenForm.get("phone").setValidators([ValidatePhoneNumber]);
    this.leadGenForm.get("isMobile").valueChanges.subscribe((newValue) => {
      if (newValue === false) {
        this.leadGenForm.patchValue({ isAgreeToSms: false });
      }
      this.isMobilebutton = newValue;
    });
    if (!this.isRaqGenericOrSpecific) {
      this.leadGenForm.valueChanges.subscribe((val) => {
        this.specialOfferFormErrorMsg = "";
        this.formSubmitFailStatus = false;
      });
      this.leadGenForm.get("country").valueChanges.subscribe((val) => {
        // Editing country field
        this.selectCountryCode = "";
        this.specialCountryInputClickStatus = true;
        this.specialCountryInputCloseBtnStatus = true;
        this.isSpecialCountrySelect = false;
      });
      this.leadGenForm.get("preferredVoyage").valueChanges.subscribe((val) => {
        this.specialSelectedVoyageClickStatus = true;
        this.specialSelectedVoyageCloseBtnStatus = true;
        this.isSpecialVoyageSelect = false;
      });
    } else {
      this.leadGenForm.valueChanges.subscribe((val) => {
        this.formSubmitFailStatus = false;
      });
      this.leadGenForm.get("country").valueChanges.subscribe((val) => {
        this.countryValueChangeFn(val);
      });
      this.leadGenForm.get("preferredVoyage").valueChanges.subscribe((val) => {
        this.selectedVoyageId = val;
        if (this.selectedVoyageId && this.selectedVoyageId != "") {
          this.voyageId = this.selectedVoyageId;
          this.isIlmaVoyage = true;
          this.constructorDataFn();
          this.voyageDetailsApiCall();
        } else {
          this.voyageId = null;
          this.isIlmaVoyage = false;
          this.constructorDataFn();
        }
      });
    }   

  }
  
  /**
   * To get all functions loading in constructor
   */
  constructorDataFn() {
    if (this.isRaqGenericOrSpecific) {
      // Changing subheader A value
      this.rcycCommonService.changeSubHeaderDataArray_A({});
      this.rcycCommonService.changeSubHeaderStatus(true);
    }
    // Function for Quote form token generation
    this.requestQuoteFormTokenFn();

    if (!this.voyageId) {
      // Api urls for Generic Quote
      // Call get Metatag function
      this.apiUrlMetatag = "/api/v1/meta/requestquotegeneric?_format=json";

      this.apiUrlA = "/api/v1/requestquotegeneric/header/a?_format=json";
      this.apiUrlB = "/api/v1/requestquotegeneric/content/b?_format=json";
      this.apiUrlC = "/api/v1/requestquotegeneric/terms?_format=json";
    } else {
      // Api urls for Specific Quote
      // Call get Metatag function
      this.apiUrlMetatag = "/api/v1/meta/requestquotevoyages?_format=json";

      this.apiUrlA = "/api/v1/requestquotevoyages/header/a?_format=json";
      this.apiUrlB = "/api/v1/requestquotevoyages/content/b?_format=json";
      this.apiUrlC = "/api/v1/requestquotevoyages/terms?_format=json";
      this.apiUrlComboId =
        "/api/v1/voyagecombo/" + this.voyageId + "?_format=json";

      // Function call to get comboId
      this.getComboIdApiCall();
    }

    if (!this.rcycCommonService.getData("UserDetails")) {
      this.rcycCommonService.getUser_Details().subscribe(
        (response) => {
          this.geoApiResponse = response;
          this.rcycRequestAQuoteContent();
        },
        (err) => {
          console.log("Geo Port APi err");
          this.rcycRequestAQuoteContent();
        }
      );
    } else {
      this.geoApiResponse = this.rcycCommonService.getData("UserDetails");
      this.rcycRequestAQuoteContent();
    }

    // Subscriber to get User Area
    this.rcycCommonService.userAreaCode.subscribe((userAreaDetails) => {
      if (userAreaDetails.length) {
        this.userAreaDetails = userAreaDetails[0];
        this.userArea = this.userAreaDetails.field_area_code;
      }
    });
  }

  /**
   * Get the ilma yacht code from session storage or drupal to get elastic ilma voyages
   */
  getYachtId() {
    if (sessionStorage.getItem("yachtcode")) {
      const yachtCodeFromSession: any = JSON.parse(
        sessionStorage.getItem("yachtcode")
      );
      if (yachtCodeFromSession) {
        this.yachtId = yachtCodeFromSession.ilma;
      }
      this.getVoyageData();
    } else {
      this.getYachtIdFromDrupal();
    }    
  }

  /**
   * To get the yacht code from drupal
   */
  getYachtIdFromDrupal() {
    const apiUrl = "/api/v1/yachtcode?_format=json";
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (res: any = []) => {
        if (res.length) {
          let yachtcode = {
            evrima: res[0].field_evrima_code,
            ilma: res[0].field_ilma_code,
            luminara: res[0].field_luminara_code,
          };
          sessionStorage.setItem("yachtcode", JSON.stringify(yachtcode));
          this.yachtId = yachtcode.ilma;
          this.getVoyageData();
        }
      },
      (err) => {
      }
    );
  }
  
  /**
   *  Get Iventory from Elatic 
   */
  getVoyageData() {
    let geoCountryCode;
    if (!this.geoApiResponse.country_code) {
      geoCountryCode = "US";
    } else {
      geoCountryCode = this.geoApiResponse.country_code;
    }
    /****************** Section C********************/
    this.apiUrlC = "/api/voyageListWithBasicData";
    const param1 = {
      countryCode: geoCountryCode,
      yachtIds: [Number(this.yachtId)]
    };
    this.rcycInternalApisService
      .postElastic(param1, this.apiUrlC)
      .subscribe((response) => {
        this.voyageApiResponse = response;
        if (this.voyageApiResponse) {
          this.voyageListArray = this.voyageApiResponse.data.voyages;
        }
      });
  }

  /**
   * Function to submit Request Quote
   */
  leadGenSubmitApiCall() {
    let dial_code: string = this.userCountryDetails.dial_code;
    this.quoteFormStatus = false;

    let phoneNumber;
    if (!this.leadGenForm.value.phone.replace(/ /g, "")) {
      phoneNumber = "";
    } else {
      phoneNumber = dial_code + this.leadGenForm.value.phone.replace(/ /g, "");
    }
    let requestData: any = {};
    requestData = {
      firstname: this.leadGenForm.value.firstname.trim(),
      lastname: this.leadGenForm.value.lastname.trim(),
      email: this.leadGenForm.value.email.trim(),
      phone: phoneNumber,
      zipcode: this.leadGenForm.value.zipcode.trim(),
      emailOpt: this.leadGenForm.value.isAgreeToReceiveMail,
      phoneOpt: this.leadGenForm.value.isAgreeToContact,
      smsOpt: this.leadGenForm.value.isAgreeToSms && this.isMobilebutton,
      info: this.voyageId,
      area: this.userArea,
      utmSource: this.cookies.get("utm_source"),
      utmMedium: this.cookies.get("utm_medium"),
      utmCampaign: this.cookies.get("utm_campaign"),
      internalSource: this.cookies.get("int_source"),
      internalCampaign: this.cookies.get("int_campaign"),
      folId: this.cookies.get("fol_num"),
      priorityStatus: true,
    };
    // requestData = {
    //   ...this.leadGenForm.value,
    //   'info': this.voyageId,
    //   'area': this.userArea,
    //   'phone': phoneNumber,
    // }

    if (!this.isRaqGenericOrSpecific) {
      requestData["comment"] = "";
      requestData["country"] = this.selectCountryCode;
    } else {
      requestData["comment"] = this.leadGenForm.value.comment
        ? this.leadGenForm.value.comment
        : "";
      requestData["country"] = this.leadGenForm.value.country
        ? this.leadGenForm.value.country
        : "";
    }

    if (requestData["country"] == "US") {
      if (this.leadGenForm.value.phone) {
        requestData["phoneOpt"] = true;
      } else {
        requestData["phoneOpt"] = false;
      }
    }

    if (this.voyageId && this.apiResponseVoyage) {
      if (this.rescoVoyageCode) {
        this.rescoVoyageCode = this.rescoVoyageCode;
      }
      //todo: Uncomment when priority status is needed for preffered voyages
      // if (this.isIlmaVoyage) {
        // requestData["priorityStatus"] = this.isIlmaVoyage;
      // }
      requestData["type"] = "specific";
      requestData["value"] = this.rescoVoyageCode;
      this.apiUrlRequestQuoteSubmit = "/secureleadapi/req-quote-specific-ga";
    } else {
      requestData["type"] = "generic";
      this.apiUrlRequestQuoteSubmit = "/secureleadapi/req-quote-generic-ga";
    }

    /****************** Section RequestQuote Submission********************/

    this.rcycInternalApisService
      .postSecureEbrochureForm(
        this.apiUrlRequestQuoteSubmit,
        requestData,
        this.requestQuoteFormToken
      )
      .subscribe(
        (response) => {
          this.requestQuoteFormResponse = response;
          const res = this.requestQuoteFormResponse;

          if (res && res.code === 200) {
            let leadId = res.data && res.data.leadId ? res.data.leadId : "";
            this.formSubmitFailStatus = false;
            if (this.voyageId) {
              this.router.navigate(["/complete"], {
                queryParams: { success: "raqvoyage", lead_id: leadId },
              });
              ga("create", "UA-114428670-1", "auto");
              ga("require", "ecommerce", "ecommerce.js");
              ga("ecommerce:addItem", {
                id: leadId,
                name: "raqvoyage",
              });
              ga("ecommerce:send");
              ga("send", "pageview");
            } else {
              this.router.navigate(["/complete"], {
                queryParams: { success: "raqnovoyage", lead_id: leadId },
              });
              ga("create", "UA-114428670-1", "auto");
              ga("require", "ecommerce", "ecommerce.js");
              ga("ecommerce:addItem", {
                id: leadId,
                name: "raqnovoyage",
              });
              ga("ecommerce:send");
              ga("send", "pageview");
            }
          } else {
            this.formSubmitFailStatus = true;
            if (res && res.code === 500 && res.status == 407) {
              this.quoteFormStatus = true;
              this.formSubmitFailStatus = false;
              this.tokenMessage = this.rcycFormTokenService.tokenErrorMsg;
            }
            document.querySelector(".page-title").scrollIntoView();
            this.requestQuoteFormTokenFn();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  /**
   * Function to call all  Request a Quote APIs
   */
  rcycRequestAQuoteContent() {
    if (this.isRaqGenericOrSpecific) {
      /****************** Section Metatag********************/
      this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

      /****************** Section A********************/
      this.rcycInternalApisService.getDrupal(this.apiUrlA).subscribe(
        (res: any = []) => {
          if (res.length) {
            this.apiResponseAStatus = true;
            this.apiResponseA = res[0];
            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A(
              this.apiResponseA
            );
          } else {
            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A([]);
          }
        },
        (err) => {
          console.log("Section A Request Quote Api error");
          console.log(err);
        }
      );
    }

    /****************** Section B********************/
    this.rcycInternalApisService.getDrupal(this.apiUrlB).subscribe(
      (res: any = []) => {
        if (res.length) {
          this.apiResponseB = res[0];
          this.apiResponseBStatus = true;
        }
      },
      (err) => {
        console.log("Section B Request Quote Api error");
        console.log(err);
      }
    );

    /****************** Section C********************/
    this.rcycInternalApisService.getDrupal(this.apiUrlC).subscribe(
      (res: any = []) => {
        if (res.length) {
          this.apiResponseC = res[0];
          this.apiResponseCStatus = true;
          let message = this.apiResponseC.field_term2
            .replace("<p>", "")
            .replace("</p>", "");
          let newMessage = `<p> ${message}<span>*</span></p>`;
          this.apiResponseC["field_term2_required"] = newMessage;
        }
      },
      (err) => {
        console.log("Section C Request Quote Api error");
        console.log(err);
      }
    );
  }

  /**
   * Function to call specific Voyage Details
   */
  voyageDetailsApiCall() {
    /****************** Section Voyages ********************/
    this.voyageImagePath =
      this.IMG_CDNHOST_URL + "/thumbnails/" + this.voyageId + ".jpg";
    let countryCode;
    if (this.geoApiResponse.country_code) {
      countryCode = this.geoApiResponse.country_code;
    } else {
      countryCode = "US";
    }
    this.apiUrlVoyage =
      "/api/voyage?voyageId=" + this.voyageId + "&countryCode=" + countryCode;
    this.rcycInternalApisService.getElastic(this.apiUrlVoyage).subscribe(
      (response) => {
        this.apiResponseElasticA = response;
        const res = this.apiResponseElasticA;
        if (res && res.code === 200 && res.data) {
          this.apiResponseVoyageStatus = true;
          this.apiResponseVoyage = res.data;
          this.rescoVoyageCode = res.data.id;
          if (this.apiResponseVoyage && this.apiResponseVoyage.voyageId) {
            this.getCruiseImageDrupal();
          }
          // Calling constructor Functions
          this.constructorDataFn();
        } else {
          this.router.navigate(["/request-quote"]);
        }
      },
      (err) => {
        console.log(
          "response Elastic Voyage Request Quote specific ERROR+++++"
        );
        console.log(err);
      }
    );
  }

  /**
   * Function to call ComboId by passing voyageId
   */
  getComboIdApiCall() {
    /****************** Section Combo********************/
    this.rcycInternalApisService.getDrupal(this.apiUrlComboId).subscribe(
      (res: any = []) => {
        if (res.length) {
          this.apiResponseComboId = res[0];
        }
      },
      (err) => {
        console.log("Section ComboId Request Quote Api error");
        console.log(err);
      }
    );
  }

  /**
   * Funtion for creating token for request Quote (Generic / Specific)
   */
  requestQuoteFormTokenFn() {
    this.rcycFormTokenService.formTokenService().subscribe(
      (res: any) => {
        if (res && res.code === 200 && res.data.logInfo) {
          this.requestQuoteFormToken = res.data.logInfo;
        }
      },
      (err) => {
        console.log("err  formTokenService Request Quote");
        console.log(err);
      }
    );
  }

  /**
   * Function to get Drupal API
   */
  getCruiseImageDrupal() {
    const Api_Url = "/api/v1/voyages/thumbnails?_format=json";
    this.rcycInternalApisService.getDrupal(Api_Url).subscribe(
      (res: any = []) => {
        if (res.length) {
          this.filterByVoyageId(res);
        }
      },
      (err) => {
        console.log("Error Occured on Getting drupal images");
        console.log(err);
      }
    );
  }

  /**
   * Function to filter based on voyageId
   * @param drupalImageArray 
   */
  filterByVoyageId(drupalImageArray) {
     if (
       this.voyageId &&
       drupalImageArray.length &&
       this.apiResponseVoyage &&
       this.apiResponseVoyage.voyageId
     ) {
       drupalImageArray.filter((data, index2) => {
         if (this.apiResponseVoyage.voyageId === data.field_voyages) {
           // Set drupal image url
           this.voyageImagePath = data.field_voyage_thumbnail;
         }
       });
     }
  }


  /**
   * Function to go back url
   */
  backToPrevPage() {
    let previousUrl = this.rcycCommonService.getSessionData(
      "backToResultVoyageListPrevUrl"
    );
    this.rcycCommonService.setSessionData("voyageBackButtonPressed", true);
    if (previousUrl) {
      previousUrl = previousUrl.replace(/['"]+/g, "");
      if (
        previousUrl.includes("region=") ||
        previousUrl.includes("port=") ||
        previousUrl.includes("date=")
      ) {
        if (previousUrl.includes("region=")) {
        }
        if (previousUrl.includes("port=")) {
        }
        if (previousUrl.includes("date=")) {
        }
        const splittedBySlash = previousUrl.split("/")[1];
        if (splittedBySlash) {
          const splittedByQuestionMark = splittedBySlash.split("?")[0];
          if (splittedByQuestionMark) {
            previousUrl = splittedByQuestionMark;
          }
        }
        this.previousUrlStatus = false;
      }
      setTimeout(() => {
        this.router.navigate([previousUrl]);
      }, 500);
    }
  }

  /**
   * Function to get Area code on Country Select
   * @param cntrycode 
   */
  getAreaCodeOnCountrySelect(cntrycode) {
    if (cntrycode) {
      this.apiUrlE = "/api/v1/countryphone/" + cntrycode + "?_format=json";
      this.rcycHomeService.home(this.apiUrlE).subscribe(
        (res: any = []) => {
          this.rcycCommonService.userAreaDetails(res);
        },
        (err) => {
          console.log("Error in Getting Area", err);
        }
      );
    }
  }

  /**
   * Function for select country click
   * @param item 
   */
  selectCountryClick(item) {
    this.leadGenForm.get('phone').setValue('');
    this.leadGenForm.get('countryCode').setValue(item.code);
    this.leadGenForm.get('dialCode').setValue(item.dial_code);
    this.userCountryDetails.dial_code = item.dial_code;
    this.userCountryDetails.code = item.code;
    this.userCountryDetails.flagUrl = item.flagUrl;
    this.userCountryDetails.ph_length = Number(item.ph_length);
    this.userCountryDetails.defaultFormat = item.defaultFormat;
    this.userCountryDetails.defaultPhoneNumber = item.defaultPhoneNumber;
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
    this.mask = getMaskedCode(item);
  }

  /**
   * Function for get outside click of country list
   * @param e 
   */
  onClickedOutside(e: Event) {
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
  }
  phoneList: any;
  downloadImageArray: any = [];
  
  download() {
    this.http
      .get("./assets/rescoNumberFormatted.json")
      .subscribe((phonelist) => {
        this.phoneList = phonelist;
        this.phoneList.map((item, index) => {
          item.flagUrl =
            "https://www.countryflags.io/" +
            item.code.toLowerCase() +
            "/flat/64.png";
          this.downloadImageArray.push(
            "https://www.countryflags.io/" +
              item.code.toLowerCase() +
              "/flat/64.png"
          );
        });
      });
  }

  /**
   * Function for zipcode format
   * @param input 
   */
  checkZipcodeFormat(input) {   
    let countryCode = this.leadGenForm.get("country").value;
    if (!this.isRaqGenericOrSpecific) {
      const countryName = this.leadGenForm.get("country").value;
      countryCode = this.countryListArray.filter(
        (p) => p.name === countryName
      )[0].code;
    }
    if (countryCode && countryCode.toUpperCase() === "US") {
      if (isNaN(input.data)) {
        input.srcElement.value = input.srcElement.value.replace(input.data, "");
      }
      const position = input.target.selectionStart;
      let value = input.srcElement.value;
      value = value.replace(/-/g, "");
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), "-", value.slice(5)].join(
          ""
        );
      }
      this.leadGenForm.get("zipcode").setValue(input.srcElement.value);
      input.target.selectionStart = position > 5 ? position + 1 : position;
      input.target.selectionEnd = position > 5 ? position + 1 : position;
    } else if (countryCode && countryCode.toUpperCase() === "CA") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 3) {
        input.srcElement.value = [value.slice(0, 3), " ", value.slice(3)].join(
          ""
        );
        this.leadGenForm.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.leadGenForm.get("zipcode").value.length > 7) {
        this.leadGenForm
          .get("zipcode")
          .setValue(this.leadGenForm.get("zipcode").value.substring(0, 7));
      }
    } else {
      let value = input.srcElement.value.replace(/  /g, " ");
      this.leadGenForm.get("zipcode").setValue(value.replace(/--/g, "-"));
    }
  }

  specialCountryLiClick(item) {
    this.selectCountryClick(item);
    this.leadGenForm.get('country').setValue(item.name);
    this.specialCountryInputClickStatus=false;
    this.specialCountryInputCloseBtnStatus=false;
    this.isSpecialCountrySelect=true;
    this.countryValueChangeFn(this.leadGenForm.get('country').value);
  }

  specialCountryInputCloseClick() {
    this.leadGenForm.get('country').setValue('');
    this.specialSelectedVoyageCloseBtnStatus=false;
  }
 
  specialPreferredVoyageLiClick(item) {
    this.selectCountryClick(item);
    this.leadGenForm.get('preferredVoyage').setValue(item.name);
    this.specialSelectedVoyageClickStatus=false;
    this.specialSelectedVoyageCloseBtnStatus=false;
    this.isSpecialVoyageSelect=true;
  }

  specialSelectedVoyageCloseClick() {
    this.leadGenForm.get('preferredVoyage').setValue('');
    this.specialCountryInputCloseBtnStatus=false;
  }

  countryValueChangeFn(val) {
    if (!this.isRaqGenericOrSpecific) {
      if (val) {
        val = this.countryListArray.filter((p) => p.name === val)[0].code;
        this.selectCountryCode = val;
      }
    }
    let zipCodeValidation: any;
    this.zipcodeReqStatusDynamic = false;
    this.zipcodeMaxLength = 10;
    if (val) {
      this.getAreaCodeOnCountrySelect(val);
      this.userCountryDetails = Object.assign({}, this.formttedPhoneList[val]);
      this.userCountryDetails.ph_length = Number(
        this.formttedPhoneList[val].ph_length
      );
      this.leadGenForm.get("phone").setValue("");
      this.leadGenForm.get("countryCode").setValue(val);
      this.leadGenForm
        .get("dialCode")
        .setValue(this.formttedPhoneList[val].dial_code);
    } else {
      this.getAreaCodeOnCountrySelect(this.permanentGeoLocation.code);
      this.userCountryDetails = Object.assign({}, this.permanentGeoLocation);
      this.userCountryDetails.ph_length = Number(
        this.permanentGeoLocation.ph_length
      );
    }
    if (val === "US") {
      zipCodeValidation = /^[0-9]{5}(?:-[0-9]{4})?$/;
      this.leadGenForm.get("isAgreeToContact").setValue(false);
      this.leadGenForm.get("isAgreeToReceiveMail").setValue(true);
      this.zipcodeReqStatusDynamic = true;
      this.showPhoneopt = false;
      this.showTermUS = true;
      this.leadGenForm.get("phone").clearValidators();
      this.leadGenForm.get("phone").setValidators([ValidatePhoneNumber]);
      this.leadGenForm.get("phone").updateValueAndValidity();
      this.phNumReqStatusDynamic = false;
    } else {
      this.showPhoneopt = true;
      this.showTermUS = false;
      if (val === "CA") {
        zipCodeValidation = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        this.zipcodeReqStatusDynamic = true;
        this.zipcodeMaxLength = 7;
        this.leadGenForm.get("phone").setValidators([ValidatePhoneNumber]);
        this.leadGenForm.get("phone").updateValueAndValidity();
        this.phNumReqStatusDynamic = false;
      } else {
        this.leadGenForm.get("phone").setValidators([ValidatePhoneNumber]);
        this.leadGenForm.get("phone").updateValueAndValidity();
        this.phNumReqStatusDynamic = false;
      }
      this.leadGenForm.get("isAgreeToContact").setValue(false);
      this.leadGenForm.get("isAgreeToReceiveMail").setValue(false);
    }
    if (zipCodeValidation) {
      this.leadGenForm
        .get("zipcode")
        .setValidators([
          Validators.required,
          Validators.pattern(zipCodeValidation),
        ]);
      this.leadGenForm.get("zipcode").updateValueAndValidity();
    } else {
      this.leadGenForm.get("zipcode").setValidators(null);
      this.leadGenForm.get("zipcode").updateValueAndValidity();
    }
    this.phoneValueChangeFn();     
  }

  leadGenSubmit() {
    this.specialOfferFormErrorMsg = "";
    if (this.isRaqGenericOrSpecific) {
      this.leadGenSubmitApiCall();
    } else {
      if (
        this.leadGenForm.controls.firstname.invalid ||
        this.leadGenForm.controls.lastname.invalid ||
        this.leadGenForm.controls.country.invalid ||
        this.leadGenForm.controls.email.invalid ||
        this.leadGenForm.controls.zipcode.invalid ||
        this.leadGenForm.controls.preferredVoyage.invalid ||
        (this.leadGenForm.controls.phone &&
          this.leadGenForm.controls.phone.errors &&
          (this.leadGenForm.controls.phone.errors.phoneIncorrect == true ||
            (this.leadGenForm.controls.phone.errors.phoneIncorrect != false &&
              this.phNumReqStatusDynamic))) ||
        !this.userArea ||
        !this.isSpecialCountrySelect
      ) {
        this.specialOfferFormErrorMsg = "Please fill all mandatory fields";
        document.querySelector(".page-title").scrollIntoView();
      } else {
        this.leadGenSubmitApiCall();
      }
    }
  }

  /**
   * Function to get profile details of logined Customers
   */
  getMyProfileDetails() {
    const param = {};
    const apiUrl = "/secureapi/getMyProfileDetails";
    this.rcycInternalApisService
      .postReservationFormWithAuth(apiUrl, param)
      .subscribe(
        (res) => {
          const data: any = res;
          this.leadGenForm.patchValue({
            firstname: data.data.forename,
            lastname: data.data.surname,
            country: data.data.country,
            zipcode: data.data.postalCode,
            email: data.data.email1,
          });
          if (data.data.phone1) {
            let phoneWOdialcode = data.data.phone1.replace(
              this.userCountryDetails.dial_code,
              ""
            );
            this.leadGenForm.patchValue({ phone: phoneWOdialcode });
            if (this.leadGenForm.controls.phone.errors.getPhoneCountryCode) {
              let getPhoneCountryCode =
                this.leadGenForm.controls.phone.errors.getPhoneCountryCode;
              this.leadGenForm.get("countryCode").setValue(getPhoneCountryCode);
              this.leadGenForm
                .get("dialCode")
                .setValue(
                  this.formttedPhoneList[getPhoneCountryCode].dial_code
                );
              this.userCountryDetails.dial_code =
                this.formttedPhoneList[getPhoneCountryCode].dial_code;
              this.userCountryDetails.code = getPhoneCountryCode;
              this.userCountryDetails.flagUrl =
                this.formttedPhoneList[getPhoneCountryCode].flagUrl;
              this.userCountryDetails.defaultFormat =
                this.formttedPhoneList[getPhoneCountryCode].defaultFormat;
              this.userCountryDetails.defaultPhoneNumber =
                this.formttedPhoneList[getPhoneCountryCode].defaultPhoneNumber;
              let phoneWOdialcodeSecond = data.data.phone1.replace(
                this.formttedPhoneList[getPhoneCountryCode].dial_code,
                ""
              );
              this.leadGenForm.patchValue({ phone: phoneWOdialcodeSecond });
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  phoneValueChangeFn() {
    if (this.leadGenForm.value.phone) {
      if (
        this.leadGenForm.value.country !== "US" &&
        !this.leadGenForm.value.isAgreeToContact
      ) {
        this.showPhoneOptRequiredText = true;
      }
    } else {
      this.showPhoneOptRequiredText = false;
    }
  }
  
  phoneOptValueChanged() {
    if (this.leadGenForm.value.isAgreeToContact) {
      this.leadGenForm
        .get("phone")
        .setValidators([Validators.required, ValidatePhoneNumber]);
      this.phNumReqStatusDynamic = true;
      this.showPhoneOptRequiredText = false;
    } else {
      this.leadGenForm.get("phone").setValidators([ValidatePhoneNumber]);
      this.phNumReqStatusDynamic = false;
      if (this.leadGenForm.value.phone) {
        this.showPhoneOptRequiredText = true;
      }
    }
    this.leadGenForm.get("phone").updateValueAndValidity();
  }

  getDrupalErrorMessages() {
    const apiUrl = "/api/v1/errormessages?_format=json";
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (response: any) => {
        if (response && response.length) {
          this.drupalErrorMessages = response[0];
        }
      },
      (err) => {
        console.log("Drupal Call Api error");
        console.log(err);
      }
    );
  }

  checkForRedirection() {
    this.rcycNewFindACruiseService
      .getPreviewVoyages()
      .subscribe((value: PreviewVoyageList) => {
        if (
          value.field_voyages.find(
            (previewVoyageCode) => previewVoyageCode == this.voyageId
          )
        ) {
          this.router.navigate(["/request-quote"]);
        }
      });
  }
}
