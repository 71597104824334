import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable()
export class RcycMilestonesService {

    API_BASE_URL: any =   AppConfig.API_BASE_URL;

  constructor(
        private http: HttpClient
    ) {}

/* ======================================================================================================================================
    Funtion to call All Apis for Milestones
 * ======================================================================================================================================*/

    milestones(apiUrl: string) {

        const url = this.API_BASE_URL + apiUrl;
        return this.http.get(url);
    }

/* ======================================================================================================================================
    Funtion to call All Apis for Milestones
 * ======================================================================================================================================*/
    getVimeoApi(videoUrl: string) {
        return this.http.get(videoUrl);
    }

}
