import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class RCYCFilterRedirectionInterceptor implements HttpInterceptor {
   constructor(private router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(tap(event => {
       if(event instanceof HttpResponse && this.isGetBookingsUrl(event.url)) {
           const data = event.body.data;

           if(data.hasOwnProperty("isVoyageCancelled") && data.isVoyageCancelled){
                this.router.navigate([this.isTA(event.url) ? '/travel-agent/booking-summary' : '/guest/my-reservations']);
           }

           return event;
       }

       return event;
    }))
  }

  private isGetBookingsUrl(url: string): boolean {
      return /getMyBookingDetailsByBookingId/g.test(url);
  }

  private isTA(url: string): boolean {
      return /secureapita/g.test(url);
  }
}