import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
// import { BrowserCookiesModule } from '@ngx-utils/cookies/browser';
import { FormsModule } from '@angular/forms';
import {
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common';
import { environment } from '../environments/environment';
import 'hammerjs';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

// Map
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SlideshowModule } from 'ng-simple-slideshow';
import { RcycLimitPipePipeModule } from './rcyc-pipes/rcyc-limit/rcyc-limit.pipe.module';
// Services
import { RcycGoogleAnalyticsEventsService } from './rcyc-services/rcyc-google-analytics-events/rcyc-google-analytics-events.service';
import { RcycCommonService } from './rcyc-services/rcyc-common/rcyc-common.service';
import { RcycFormTokenService } from './rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { RcycGlobalMessagesService } from './rcyc-services/rcyc-global-messages/rcyc-global-messages.service';
import { RcycCommonLogoutService } from './rcyc-services/rcyc-logout/rcyc-logout.service';
import { RcycChannelsService } from './rcyc-components/rcyc-channels/rcyc-channels.service';
import { RcycPortsService } from './rcyc-components/rcyc-ports/rcyc-ports.service';
import { RcycHomeService } from './rcyc-components/rcyc-home/rcyc-home.service';
import { RcycSpecialOffersLandingService } from './rcyc-components/rcyc-special-offers-landing/rcyc-special-offers-landing.service';
import { RcycRequestBrochureService } from './rcyc-components/rcyc-request-brochure/rcyc-request-brochure.service';
import { RcycInternalApisService } from './rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycToasterMessagesService } from './rcyc-share-components/rcyc-toaster-messages/rcyc-toaster-messages.service'
// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './rcyc-components/rcyc-header/rcyc-header.component';
import { FooterComponent } from './rcyc-components/rcyc-footer/rcyc-footer.component';

import { RcycGdprModalComponent } from './rcyc-modals/rcyc-gdpr-modal/rcyc-gdpr-modal.component';

// routing modules
import { AppRoutingModule } from './app.routing';
import { SharedServiceService } from './rcyc-services/rcyc-shared/shared-service.service';
import { AutoLogoutService } from './rcyc-services/rcyc-autologout/auto-logout.service';

//Store
import { StoreModule } from '@ngrx/store';
import { reducers,effects, clearState } from './store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RcycHeaderNewComponent } from './rcyc-components/rcyc-header-new/rcyc-header-new.component';
import { RcycToasterMessagesComponent } from './rcyc-share-components/rcyc-toaster-messages/rcyc-toaster-messages.component';
import { RcycHomeHeroModuleComponent } from "./rcyc-components/rcyc-common-components/rcyc-home-hero-module/rcyc-home-hero-module.component";
import { RcycSharedModuleModule } from "./rcyc-shared-module/rcyc-shared-module.module";
import { HomeComponent } from './rcyc-components/rcyc-home/rcyc-home.component';
import { NguCarouselModule } from '@ngu/carousel';
import { RcycStarProgramComponent } from './rcyc-components/rcyc-star-program/rcyc-star-program.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Rcyc3playVimeoService} from './rcyc-services/rcyc-3play-vimeo/rcyc-3play-vimeo.service';

import { RcycInterceptorProviders } from './rcyc-interceptors';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({appId: 'rcyc'}),
    StoreModule.forRoot(reducers, {metaReducers: [clearState]}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot(effects),
    // BrowserCookiesModule.forRoot(),
    FormsModule,
    RcycLimitPipePipeModule,
    ScrollToModule.forRoot(),
    SweetAlert2Module.forRoot(),
    SlideshowModule,
    RcycSharedModuleModule,
    HttpClientModule,
    AppRoutingModule,
    NguCarouselModule,
    BrowserAnimationsModule
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RcycGdprModalComponent,
    RcycHomeHeroModuleComponent,
    RcycHeaderNewComponent,
    RcycToasterMessagesComponent,
    HomeComponent,
    RcycStarProgramComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    RcycCommonService,
    RcycHomeService,
    RcycGoogleAnalyticsEventsService,
    RcycFormTokenService,
    AutoLogoutService,
    RcycPortsService,
    RcycChannelsService,
    RcycCommonLogoutService,
    RcycInternalApisService,
    SharedServiceService,
    RcycToasterMessagesService,
    RcycSpecialOffersLandingService,
    RcycGlobalMessagesService,
    RcycRequestBrochureService,
    Rcyc3playVimeoService,
    RcycInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
