<ngu-carousel #imageSlider *ngIf="data?.length" [inputs]="carouselSettings" [dataSource]="data"
  [class]="variant==='auto' ? 'lg:!h-full [&>div>div]:lg:!h-full' : ''" class="image-slider w-full">
  <ngu-item *nguCarouselDef="let item; let j=index" class="p-0" [class]="variant==='auto'?'':'h-full'">
    <div class="relative overflow-hidden gradient-background-image h-full aspect-[5/4]"
      [style]="{background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0.4))'}">
      <div class="absolute top-0 left-0 w-full h-full bg-cover bg-center" [style]="{backgroundImage: 'url('+item+')'}"></div>
    </div>
  </ngu-item>

  <ul #nguHeroCarouselControls id="carouselControls" *ngIf="imageSlider?.pointNumbers.length > 1"
    class="w-60 justify-center list-none absolute bottom-0 left-0 right-0 mx-auto -translate-x-1/2 flex items-center gap-4 mb-0 md:mb-2"
    NguCarouselPoint>
    <li class="m-0 flex items-center">
      <button id="ngu_prev" NguCarouselPrev class="flex m-0 bg-transparent border-0 hover:bg-transparent p-0 -mr-2"
        tabindex="-1">
        <img src="assets/img/arrow_left_white.svg" alt="Scroll to the previous image"
          title="Scroll to the previous image">
      </button>
    </li>
    <li class="rounded-full w-[6px] h-[6px] cursor-pointer m-0"
      [class]="j===imageSlider?.activePoint ? 'bg-white w-3 h-3' : ' bg-white/40'"
      *ngFor="let j of imageSlider?.pointNumbers" (click)="imageSlider?.moveTo(j)"></li>
    <li class="m-0 flex items-center">
      <button id="ngu_next" NguCarouselNext class="flex m-0 bg-transparent border-0 hover:bg-transparent p-0 -ml-2"
        tabindex="-1">
        <img src="assets/img/arrow_right_white.svg" alt="Scroll to the next image" title="Scroll to the next image">
      </button>
    </li>
  </ul>
</ngu-carousel>