<swal #openConfiguratorSwal (beforeOpen)="beforeOpen($event)" (open)="onOpen($event)" [showConfirmButton]="false"
	[showCancelButton]="false" [showCloseButton]="true" customClass="viewBrocSwtOuter" [allowOutsideClick]="false"
	[animation]="false" [reverseButtons]="true" (close)="onClose($event)">
	<ng-container *swalPartial>
		<div class="w-full flex lg:flex-row flex-col lg:flex-row-reverse gap-8 lg:px-12 px-0 lg:pt-12 lg:pb-4 py-0">
			<div class="lg:w-1/3 w-full">
				<!-- Showing heading on mobile view -->
				<div *ngIf="isMobileScreen" class="mt-12 mx-4">
					<h1 class="font-[400] !mb-0 text-[26px] lg:text-[39px] leading-[32px] lg:leading-[48px] !font-caslon uppercase"
						[innerHtml]="bookingConfigHeader?.title"></h1>
					<div class="text-[16px] text-[#1C1C1C] pb-4" [innerHtml]="bookingConfigHeader?.field_sub_header">
					</div>
				</div>
				<!-- voyage banner -->
				<div class="bg-white p-0 lg:p-0 lg:m-0 mx-4" id="voyagebanner">
					<div class="flex flex-col gap-[21px] relative bg-light-blue-2" *ngIf="voyageBasicData">
						<div>
							<app-rcyc-image-slider
								class="voyages-image-slider lg:h-full lg:w-[50vw] max-h-full overflow-hidden"
								variant="auto" *ngIf="voyageThumbImage" [data]="[voyageThumbImage]"
								[imageScrollPosition]="'map'"></app-rcyc-image-slider>
						</div>
						<div class="mx-4 mb-4">
							<div class="text-primary">
								<div class="mb-2 uppercase italic text-[14px] font-normal tracking-[0.168px] leading-[171.429%] font-['Proxima Nova']"
									*ngIf="voyageBasicData?.yachtName">
									{{voyageBasicData?.yachtName}}
								</div>
								<h2
									class="mb-1 text-[20px] lg:text-[20px] font-normal tracking-[1.2px] lg:tracking-[1.2px] leading-[32px] lg:leading-[32px] font-['Adobe Caslon Pro']">
									{{voyageBasicData?.voyageName | convertHtml}}
								</h2>

								<div class="text-content flex flex-col gap-1 uppercase">
									<div class="flex flex-row justify-between gap-2 flex-wrap">
										<div class="whitespace-nowrap text-[16px] font-normal tracking-[0.192px] leading-[150%] font-['Proxima Nova']"
											*ngIf="voyageBasicData?.voyageStartDate">
											{{voyageBasicData?.voyageStartDate
											|
											date: 'fullDate':'UTC'}}
										</div>
										<div class="whitespace-nowrap text-[16px] font-normal tracking-[0.192px] leading-[150%] font-['Proxima Nova']"
											*ngIf="voyageBasicData?.nights && !isMobileScreen">{{voyageBasicData?.nights}} Nights
										</div>
									</div>
									<div class="flex flex-row justify-between gap-2 flex-wrap">
										<div class="whitespace-nowrap text-[16px] font-normal tracking-[0.192px] leading-[150%] font-['Proxima Nova']"
											*ngIf="currencyCode && voyageBasicData?.startingPriceMap[currencyCode]">
											Fares from:
											<app-rcyc-common-voyage-price [currencyCode]="currencyCode"
												[voyagePrice]="voyageBasicData?.startingPriceMap[currencyCode]">
											</app-rcyc-common-voyage-price>
										</div>
										<div class="flex gap-1 whitespace-nowrap text-[16px] font-normal tracking-[0.192px] leading-[150%] font-['Proxima Nova']"
											*ngIf="ports && !isMobileScreen">
											{{ports.length}} Ports
										</div>
									</div>
								</div>
								<div *ngIf="voyageMapCarouselFieldSummery"
									class="text-[16px] font-normal tracking-[0.192px] leading-[150%] font-['Proxima Nova']"
									[innerHTML]="voyageMapCarouselFieldSummery"></div>
							</div>
						</div>
					</div>
				</div>
				<button type="button" (click)="onClose($event)"
					class="swal2-close flex lg:text-[#c1c1c1] lg:text-[#fff] text-[#000]"
					aria-label="Close this dialog">
					×
				</button>
			</div>
			<div class="lg:w-2/3 lg:px-0 px-4 lg:py-0 pb-6">
				<!-- Showing heading on mobile view -->
				<div *ngIf="!isMobileScreen">
					<h1 class="font-[400] !mb-0 text-[26px] lg:text-[39px] leading-[32px] lg:leading-[48px] !font-caslon uppercase"
						[innerHtml]="bookingConfigHeader?.title"></h1>
					<div class="text-[16px] text-[#1C1C1C] pb-4" [innerHtml]="bookingConfigHeader?.field_sub_header">
					</div>
				</div>
				<div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
					<span class="error">{{sessionExpiredMessages}}</span>
				</div>
				<form id="planVoyageFormId" class="mt-4" name="planVoyageForm" [formGroup]="lead_gen_form" novalidate
					autocomplete="false">
					<fieldset>
						<div
							class="basic-form_group !mb-0 lead_form_field w-full grid lg:grid-cols-2 grid-cols-1 lg:gap-x-12 gap-y-8 lg:gap-y-16">
							<!-- first name -->
							<div class="max-w-full relative">
								<input id="firstNameId" class="basic-form_input w-full !mb-0 px-2 py-3" type="text"
									formControlName="firstname" placeholder="First Name">
								<label for="firstNameId"
									class="form-label absolute left-0 w-full !text-[#000] !font-[16px] !-top-4 !lg:top-8 font-normal">First
									Name <span class="text-[#ce4844] !font-[16px]">*</span></label>
								<p class="errorptag"
									*ngIf="lead_gen_form.controls.firstname.invalid && (lead_gen_form.controls.firstname.dirty || lead_gen_form.controls.firstname.touched)">
									<span *ngIf="lead_gen_form.controls.firstname.errors.required" class="error">This
										field is
										required</span>
									<span
										*ngIf="!lead_gen_form.controls.firstname.errors.required&&lead_gen_form.controls.firstname.errors.pattern"
										class="error">Please enter a valid first name </span>
								</p>
							</div>
							<!-- last name -->
							<div class="max-w-full relative">
								<input id="lastNameId" class="basic-form_input w-full !mb-0 px-2 py-3" type="text"
									formControlName="lastname" placeholder="Last Name">
								<label for="lastNameId"
									class="form-label absolute left-0 !text-[#000] !font-[16px] !-top-4 !lg:top-8 font-normal">Last
									Name <span class="text-[#ce4844] !font-[16px]">*</span></label>
								<p class="errorptag"
									*ngIf="lead_gen_form.controls.lastname.invalid && (lead_gen_form.controls.lastname.dirty || lead_gen_form.controls.lastname.touched)">
									<span *ngIf="lead_gen_form.controls.lastname.errors.required" class="error">This
										field is
										required</span>
									<span
										*ngIf="!lead_gen_form.controls.lastname.errors.required&&lead_gen_form.controls.lastname.errors.pattern"
										class="error">Please enter a valid last name </span>
								</p>
							</div>
							<!-- county -->
							<div class="max-w-full relative">
								<select id="selectCountryId" class="basic-form_input w-full !mb-0 px-2 py-3 h-min"
									formControlName="country" (change)="onChange($event)">
									<option id="selectCountryId_0" value="">Country / Region</option>
									<option *ngFor="let county of countryListArray;let i=index"
										id="countryOptionId_{{i+1}}" value="{{county.code}}">{{county.name}}
									</option>
								</select>
								<ng-container>
									<label for="selectCountryId"
										class="form-label absolute left-0 !text-[#000] !-top-4 !lg:top-8 !font-[16px] font-normal">Country
										/ Region <span class="text-[#ce4844] !font-[16px]">*</span></label>
								</ng-container>
								<span
									*ngIf="lead_gen_form.controls.country.invalid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) && lead_gen_form.controls.country.errors.required"
									class="error">This field is required</span>
							</div>
							<!-- zip -->
							<div class="max-w-full relative">
								<ng-container *ngIf="isMobileScreen === false">
									<input id="zipCodeId" class="basic-form_input w-full !mb-0 px-2 py-3" type="text"
										formControlName="zipcode"
										placeholder="ZIP/Postal Code (Required for US & Canada)"
										[maxlength]="zipcodeMaxLength" (input)="checkZipcodeFormat($event)">
								</ng-container>
								<ng-container *ngIf="isMobileScreen === true">
									<textarea id="zipCodeId"
										class="basic-form_input w-full !mb-0 zip-textarea border-t-0 border-r-0 border-l-0 border-b-[1px] border-solid border-[#1c1c1c]"
										formControlName="zipcode"
										placeholder="ZIP/Postal Code (Required for US & &#10;Canada)"
										[maxlength]="zipcodeMaxLength" (input)="checkZipcodeFormat($event)"></textarea>
								</ng-container>
								<ng-container>
									<label for="zipCodeId"
										class="form-label absolute !left-0 !text-[#000]  !-top-4 !lg:top-8 !font-[16px] font-normal">
										ZIP/Postal Code (Required for US & &#10;Canada)
										<span *ngIf="zipcodeReqStatusDynamic"
											class="text-[#ce4844] !font-[16px] !pl-1">*</span>
									</label>
								</ng-container>
								<p class="errorptag"
									*ngIf="lead_gen_form.controls.zipcode.invalid && (lead_gen_form.controls.zipcode.dirty || lead_gen_form.controls.zipcode.touched) ">
									<span *ngIf="lead_gen_form.controls.zipcode.errors.required" class="error">
										This field is required
									</span>
									<span
										*ngIf="!lead_gen_form.controls.zipcode.errors.required&&lead_gen_form.controls.zipcode.errors.pattern"
										class="error">
										ZIP/Postal Code is not valid
									</span>
								</p>
							</div>
							<!-- email -->
							<div class="max-w-full relative">
								<input id="emailId" class="basic-form_input w-full !mb-0 px-2 py-3" type="email"
									formControlName="email" placeholder="Email Address">
								<ng-container>
									<label for="emailId"
										class="form-label absolute !text-[#000] !font-[16px] !-top-4 !lg:top-8 font-normal">Email
										Address <span class="text-[#ce4844] !font-[16px]">*</span></label>
								</ng-container>
								<p class="errorptag"
									*ngIf="lead_gen_form.controls.email.invalid && (lead_gen_form.controls.email.dirty || lead_gen_form.controls.email.touched)">
									<span *ngIf="lead_gen_form.controls.email.errors.required" class="error">This field
										is required</span>
									<span
										*ngIf="!lead_gen_form.controls.email.errors.required&&lead_gen_form.controls.email.errors.pattern"
										class="error">
										Enter a valid email address (e.g. name@example.com)
									</span>
								</p>
							</div>
							<!-- phone -->
							<div class="max-w-full relative">
								<div class="row">
									<div class="column">
										<div id="phoneFlagContainerId" class="phoneFlagContainer !mb-0"
											(clickOutside)="onClickedOutside($event)">
											<label for="phoneId"
												class="form-label absolute !text-[#000] !-top-4 !lg:top-8 !font-[16px] font-normal">
												Phone Number
												<span *ngIf="phNumReqStatusDynamic"
													class="text-[#ce4844] !font-[16px]">*</span>
											</label>
											<div class="row is-3-9">
												<div class="column is-3 phoneFlagOuter px-2 py-3"
													(keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
													(click)="flagCodeClickStatus = !flagCodeClickStatus" tabindex='0'>
													<span class="flagOuter"><img [src]="userCountryDetails?.flagUrl"
															class="flagImg"
															*ngIf="userCountryDetails?.flagUrl" /></span>
													<span class="countryCodeOuter">
														{{userCountryDetails?.dial_code}}</span>
												</div>

												<!-- Country List -->
												<div *ngIf="flagCodeClickStatus" class="countryResultOuter">
													<div *ngIf="flagCodeClickStatus" class="countryResultInner">
														<div class="countrySearchOuter">
															<input type="text" [(ngModel)]="countrySearch"
																[ngModelOptions]="{standalone: true}" />
														</div>
														<ul *ngIf="phoneDataArray.length"
															class="countrySearchListOuter">
															<li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
																(keyup.enter)="selectCountryClick(item)"
																(click)="selectCountryClick(item)" [ngStyle]="{'border-bottom':
													  !countrySearch &&
													  (
														( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
														( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )
													  )
													  ? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
																(focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
																<span
																	[innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
																({{item?.dial_code}})
															</li>
														</ul>
														<p class="noResultsFound letterSpacing"
															*ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length">
															No results
															match "{{countrySearch}}"</p>
													</div>
												</div>
												<!-- Country List -->
												<div class="column is-9 phoneNumberInputOuter">
													<input id="phoneId"
														class="basic-form_input phoneNumberInput w-full !mb-0 px-2 py-3"
														*ngIf="userCountryDetails?.defaultFormat" type="tel"
														formControlName="phone" placeholder="Phone Number"
														[maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
														[textMask]="{mask: mask, guide:false}"
														(input)="phoneValueChangeFn()" />
												</div>

											</div>
											<p class="errorptag"
												*ngIf="lead_gen_form.controls.phone.errors?.phoneIncorrect && (lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched)">
												<span *ngIf="lead_gen_form.controls.phone.errors?.required"
													class="phoneNoError error">This field is
													required</span>
												<span
													*ngIf="!lead_gen_form.controls.phone.errors?.required&&lead_gen_form.controls.phone.errors?.phoneIncorrect"
													class="phoneNoError error">Enter a valid phone number (e.g. {{
													userCountryDetails?.defaultPhoneNumber }}) </span>
											</p>
										</div>

									</div>
								</div>
							</div>
						</div>
						<ng-container *ngIf="showPhoneopt">
							<div *ngIf="bookingConfigTerms && !showTermUS"
								class="termsSection grid lg:grid-cols-2 grid-cols-1 lg:gap-x-12 lg:gap-y-0 gap-y-6 w-full mt-6">
								<div *ngIf="bookingConfigTerms?.field_term1" class="eachTermSection flex">
									<input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail"
										class="input-align min-w-6 min-h-6 mr-2" aria-labelledby="emailMarketing">
									<p class="termContent" id="emailMarketing"
										[innerHtml]="bookingConfigTerms?.field_term1">
									</p>
								</div>
								<div *ngIf="bookingConfigTerms?.field_term2" class="eachTermSection flex-col">
									<div class="flex">
										<input id="phoneOptId" type="checkbox" value="true"
											formControlName="isAgreeToContact" class="input-align min-w-6 min-h-6 mr-2"
											aria-labelledby="phoneMarketing" (change)="phoneOptValueChanged()">
										<p class="termContent" *ngIf="!showPhoneOptRequiredText" id="phoneMarketing"
											[innerHtml]="bookingConfigTerms?.field_term2"></p>
										<p class="termContent" *ngIf="showPhoneOptRequiredText" id="phoneMarketing"
											[innerHtml]="bookingConfigTerms?.field_term2_required"></p>
									</div>
									<p class="errorptag marketingError ml-9" *ngIf="showPhoneOptRequiredText">
										<span class="emailError error"
											[innerHtml]="drupalErrorMessages?.field_phone_opt_in_required"></span>
									</p>
								</div>
							</div>
						</ng-container>
					</fieldset>
				</form>
			</div>
		</div>
		<div class="text-[13px] mb-6 lg:px-12 px-4">
			<span class="text-[#ce4844]">*</span> Indicates required field
		</div>
		<div class="mb-8 lg:px-12 px-4">
			<div id="terms" class="text-[13px]"
				*ngIf="bookingConfigTerms && bookingConfigTerms?.field_summery && !showTermUS"
				[innerHtml]="bookingConfigTerms?.field_summery"></div>
			<div id="terms" class="text-[13px]"
				*ngIf="bookingConfigTerms && bookingConfigTerms?.field_summery && showTermUS"
				[innerHtml]="bookingConfigTerms?.field_summary_us"></div>
		</div>
		<div class="lg:flex lg:justify-start items-center gap-8 lg:px-12 px-4 lg:mb-12 mb-4">
			<input type="submit" class="button-primary text-[14px] mb-2" (click)="configStart()" [disabled]="lead_gen_form.controls.firstname.invalid ||
				lead_gen_form.controls.lastname.invalid ||                                                  
				lead_gen_form.controls.country.invalid ||
				lead_gen_form.controls.zipcode.invalid ||
				lead_gen_form.controls.email.invalid ||
				lead_gen_form?.controls?.phone?.errors?.required ||
				lead_gen_form?.controls?.phone?.errors?.phoneIncorrect==true ||
				!userArea ||
				showPhoneOptRequiredText" [value]="bookingConfigHeader?.field_com_cta_title">
		</div>
	</ng-container>
</swal>