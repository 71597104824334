import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-rcyc-destinations',
  templateUrl: './rcyc-destinations.component.html',
  styleUrls: ['./rcyc-destinations.component.css'],
})
export class RcycDestinationsComponent implements OnInit {
  @Input() data: any;
  opened: string;

  ngOnInit(): void {
    this.opened = this.data[1]?.id;
  }

  openTab(id) {
    this.opened = id;
  }
  getData(item) {
    return {
        id: item.id,
        destination: item.field_tile_title,
        image: {
          src: item.field_tile_image
        },
        eyebrow: item.field_tile_eyebrow,
        title: item.field_tile_title,
        text: item.field_tile_details,
        cta: {
          title: item.field_tile_cta_title,
          url: item.field_tile_cta_url,
      }
    }
  }
}
