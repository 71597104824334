<swal #guestLoginSwal (beforeOpen)="beforeOpen($event)" (open)="onOpen($event)" [showConfirmButton]="false"
  [showCancelButton]="false" [showCloseButton]="true" customClass="guestLoginSwtOuter" [allowOutsideClick]="true" 
  [animation]="false" (close)="onClose($event)" [reverseButtons]="true">
  <ng-container *swalPartial>

    <div id="rcyc-page-guest-login">

      <section class="section-1">
        <div class="l-container-wrapper">
          <div class="l-container">

            <div class="row is-6-6 mainOuter">

              <div class="column is-6 leftSection">
                <h2 class="heading" [innerHTML]="apiresponseC?.field_sub_header">
                  <!-- Sign in <span class="mobile">to your account</span> -->
                </h2>
                <div class="formOuter">   

                  <div *ngIf="formSubmitFailStatus" class="errorSection">
                    <div id="formSubmitErrorId">
                      <span class="error" [innerHTML]="formSubmitErrorMsg"></span>
                    </div>
                    <!-- <div *ngIf="currentSessionStatus" id="sessionExpireId">
                      <strong>Session Expired ! </strong> Please login to continue.
                    </div>    -->
                  </div>

                  <form id="guestLoginFormId" name="guestLoginForm" [formGroup]="guestLogin" novalidate autocomplete="off">
                    <div class="basic-form_group lead_form_field">

                      <!-- Username -->
                      <div class="row is-12 usernameSection">
                        <div class="column is-12 form_fields">
                          <label class="basic-form_label form_label">Login <span>email</span>
                        </label>
                        </div>
                        <div class="column is-12 form_fields">
                          <input id="loginModalEmailId" class="basic-form_input basic-form_input-half" type="email"
                            formControlName="email" maxlength="60" readonly onmouseover="this.removeAttribute('readonly');" onfocus="this.removeAttribute('readonly');">
                          <span *ngIf="!guestLogin.controls.email.valid &&
                          guestLogin.controls.email.touched &&
                          !emailErr"
                          class="error" [innerHTML]="apiResponseGError?.field_email_required"></span>
                          <span *ngIf="emailErr" class="error" [innerHtml]="emailValidationMsg"></span>
                        </div>
                      </div>

                      <!-- Password -->
                      <div class="row is-12">
                        <div class="column is-12 form_fields">
                          <label class="basic-form_label form_label">Password</label>
                        </div>
                        <div class="column is-12 form_fields">
                          <input id="loginModalPwdId" class="basic-form_input basic-form_input-half" type="password"
                            formControlName="password" minlength="1" maxlength="15" readonly onmouseover="this.removeAttribute('readonly');" onfocus="this.removeAttribute('readonly');"> 
                          <span *ngIf="!guestLogin.controls.password.valid && guestLogin.controls.password.touched && !pwdErr"
                            class="error" [innerHTML]="apiResponseGError?.field_password_required"></span>
                          <span *ngIf="pwdErr" class="error" [innerHtml]="passwordValidationMsg"></span>
                        </div>
                      </div>

                      <!-- Remember me -->
                      <div class="row is-12 rememberForgotSection">

                        <div class="row is-6-6">

                          <div class="column is-6 rememberMeOuter">
                            <input id="loginModalRememberMeId" type="checkbox" formControlName="rememberMe" [checked]="rememberMeCB"
                            (keypress)="rememberMe($event)" (click)="rememberMe($event)">
                            <span class="checkBoxText">Remember Me</span>
                          </div>
                          <div class="column is-6 forgotPwdOuter">
                            <a [routerLink]="['/reset-password']" (click)="forgotPwdClick()">Forgot Password?</a>
                          </div>
                        </div>
                      </div>



                    </div>
                    <div class="row is-12 submitOuter">
                      
                      
                      <div class="column is-6 form_fields submitInner">
                        <input *ngIf="!loadApiStatus" class="button signInBtn" type="submit" id="loginModalSigninId" (click)="guestLoginSubmit()" 
                        [disabled]="emailErr || !emailValidStatus || pwdErr || !pwdValidStatus"
                          value="Sign in">                          
                            <div  *ngIf="loadApiStatus" class="row is-12 btnLoader">
                              <img  src="assets/components/images/loading.gif" class="formLoader">
                            </div>                            
                      </div>
                    </div>
                    
                  </form>

                </div>
              </div>

              <h2 *ngIf="!emailVerifiedStatus" class="or"> OR </h2>

              <div *ngIf="!emailVerifiedStatus" class="column is-6 rightSection">
                <h2 class="heading" [innerHTML]="apiresponseC?.field_port_list_sub_header"></h2>
                <div *ngIf="apiresponseCStatus">
                  <div class="row is-12">
                    <div class="column is-12">
                      <img [src]="apiresponseC?.field_image" />
                    </div>
                  </div>
                  <div class="row is-12">
                    <div class="column is-12">
                      <div class="rightContent" [innerHtml]="apiresponseC?.field_summery"></div>
                      <!-- <ul>
                        <li>Review booked itineraries</li>
                        <li>Review past itineraries</li>
                        <li>Explore and book shore excursions</li>
                        <li>Check in online and prepare for boarding</li>
                      </ul> -->
                    </div>
                  </div>
                </div>

                <!-- Join button -->
                <div class="row is-12 submitOuter">
                  <div class="column is-6 form_fields">
                    <input class="button joinBtn" type="submit" id="joinBtnId"  [routerLink]="['/registration']" (click)="guestJoinSubmit()"
                      value="Join">
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>

    </div>

  </ng-container>
</swal>