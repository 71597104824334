<a *ngIf="variant!='hub'" (click)="onSlideClick(data.url)" (mouseout)="onBlur()" (mouseover)="onHover()"
  [class]="cssImageScale ? 'group hover:text-medium-blue pb-1 hover:no-underline':'group hover:text-medium-blue pb-1 !no-underline'"
  (click)="clearCompareValue(data)" (keyup.enter)="clearCompareValue(data?.url)">
  <div class="font-caslon lg:mb-1 text-primary group-hover:text-medium-blue flex items-center" *ngIf="data.heading"
    [ngClass]="{
        'text-[29px] lg:text-[39px]': variant === 'experience',
        'text-[26px] lg:text-[39px]': variant === 'hub' || variant === 'editorial',
      }">
    {{data.heading}}
    <img *ngIf="data.depPosition === 'Anchored'" class="ml-3 -mt-[8px] lg:-mt-[18px]" src="/assets/img/anchor.svg"
      alt="">
  </div>
  <div [ngClass]="{
    'flex flex-col': isMobile && variant !== 'booking-config' && !isItinerary, 
    'flex flex-row': isMobile && (variant === 'booking-config' || isItinerary), 
    'lg:block': variant === 'experience' && !isMobile,
    'gap-3': variant === 'booking-config'
    }">
    <div class="relative overflow-hidden aspect-square"
     *ngIf="data.image?.src"
     [ngClass]="{
       'w-[94px] lg:w-full mb-4 lg:mb-7 mr-4 lg:mr-0': variant === 'experience',
       'w-[80px] h-[80px] min-w-[80px]': variant === 'booking-config',
       '!aspect-[1/1] mb-7': variant === 'hub' || variant === 'editorial'
      }">
      <img [src]="data.image.src"
        [class]="cssImageScale ? '!w-auto transition h-full object-cover transition-all duration-[500ms] fill-image-hover' : '!w-auto transition h-full object-cover transition-all duration-[500ms]'"
        [title]="data.image.title | convertHtml" [alt]="data.image.alt | convertHtml">
    </div>
    <div>
      <div *ngIf="data.eyebrow && (topcontent && topcontent !== 'voyage itinerary' || variant === 'booking-config')"
        [class]="variant === 'booking-config' ? 'text-[13px] leading-[123.077%] font-normal tracking-[0.156px] text-[#1C1C1C] uppercase' : 'uppercase text-sm mb-2 lg:mb-4 text-primary group-hover:text-medium-blue'">
        {{data.eyebrow}}
      </div>
      <div *ngIf="data.title"
        [class]="cssImageScale ? 'group-hover:text-medium-blue group-hover:underline' : 'text-primary'" [ngClass]="{
           'h5 text-primary -mt-[6px] lg:mt-0 lg:mb-4 uppercase': variant === 'experience',
           'text-[18px] leading-[133.333%] tracking-[0.216px] lg:text-[20px] lg:leading-[160%] lg:tracking-[0.24px] font-normal text-[#1C1C1C] uppercase': variant === 'booking-config',
           'h5 text-primary mb-4': variant === 'hub' || variant === 'editorial',
           'group-hover:text-[30px] transition-all duration-[500ms]':!isMobile,
         }">
        {{ data.title | convertHtml }}
        <img *ngIf="variant === 'booking-config' && data.depPosition === 'Anchored'" class="ml-3 -mt-[8px] lg:-mt-[18px]" src="/assets/img/anchor.svg"
        alt="">
      </div>
      <div *ngIf="data.text" [innerHTML]="data.text"
        class="flex flex-col gap-1 lg:gap-2 mb-4 lg:mb-7 group-hover:text-medium-blue group-hover:[&>p]:text-medium-blue">
      </div>
      <div *ngIf="data.date || data.departure || data.arival" [ngClass]="{
        'pt-0': variant === 'booking-config',
        'pt-3': variant !== 'booking-config',
      }">
        <div *ngIf='data.date' class="uppercase text-primary" [ngClass]="{
               'text-base lg:text-sm mb-2 lg:mb-4 group-hover:text-medium-blue': variant === 'experience',
               'text-[16px] leading-[150%] font-normal tracking-[0.192px] text-[#1C1C1C]': variant === 'booking-config',
               'text-[16px] mb-2 lg:mb-4 group-hover:text-medium-blue': variant === 'hub' || variant === 'editorial',
             }">
          <span *ngIf="data?.arrivalTime">
            {{ data?.arrivalTime | date:"fullDate":"UTC" }}
          </span>
          <span *ngIf="!data?.arrivalTime">
            {{ data?.departureTime | date:"fullDate":"UTC" }}
          </span>
        </div>
        <div *ngIf='data.arrivalTime && ( (data?.arrivalTime | date:"HH:mm":"UTC") !== "00:00")'
          class="uppercase text-primary" [ngClass]="{
               'text-base lg:text-sm mb-2 lg:mb-4 group-hover:text-medium-blue': variant === 'experience',
               'text-[16px] leading-[150%] font-normal tracking-[0.192px] text-[#1C1C1C]': variant === 'booking-config',
               'text-[16px] mb-2 lg:mb-4 group-hover:text-medium-blue': variant === 'hub' || variant === 'editorial',
             }">
          ARRIVAL: {{ data?.arrivalTime | date:"HH:mm":"UTC" }}
        </div>
        <div *ngIf='data.departureTime && ( (data?.departureTime | date:"HH:mm":"UTC") !== "00:00")'
          class="uppercase text-primary" [ngClass]="{
               'text-base lg:text-sm mb-2 lg:mb-4 group-hover:text-medium-blue': variant === 'experience',
               'text-[16px] leading-[150%] font-normal tracking-[0.192px] text-[#1C1C1C]': variant === 'booking-config',
               'text-[16px] mb-2 lg:mb-4 group-hover:text-medium-blue': variant === 'hub' || variant === 'editorial',
             }">
          DEPARTURE: {{ data?.departureTime | date:"HH:mm":"UTC" }}
        </div>
      </div>
    </div>
  </div>
</a>

<div (mouseout)="onBlur()" (mouseover)="onHover()" *ngIf="variant=='hub'" class="group text-[#1C1C1C]  pb-1" [ngClass]="{
         'hover:text-medium-blue ':!isMobile,
         '!no-underline':isMobile
       }">
  <div>
    <div class="relative overflow-hidden" *ngIf="data?.image?.src" [ngClass]="{
           'aspect-square w-[94px] lg:w-full mb-auto lg:mb-7 mr-4 lg:mr-0': variant === 'experience',
           'aspect-[1/1] mb-7': variant === 'hub' || variant === 'editorial',
         }">
      <img [src]="data.image.src"
        [class]="cssImageScale ? '!w-auto transition h-full object-cover transition-all duration-[500ms] fill-image-hover' : '!w-auto transition h-full object-cover transition-all duration-[500ms]'"
        [title]="data.image.title | convertHtml" [alt]="data.image.alt | convertHtml">
    </div>

    <div>
      <div *ngIf="data.title"
        class="font-caslon text-[20px] lg:text-[25px] leading-[23px] lg:leading-[28px] uppercase  mb-2 lg:mb-4"
        [ngClass]="{
         'group-hover:text-[30px] group-hover:text-medium-blue group-hover:underline transition-all duration-[500ms]':!isMobile,
       }">
        {{ data.title | convertHtml }}
      </div>
      <div *ngIf="data.description" [innerHTML]="data.description">
      </div>
    </div>
  </div>
</div>