import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import 'hammerjs';
import { RcycCountrynameSearchPipe } from "../rcyc-pipes/rcyc-countryname-search/rcyc-countryname-search.pipe";
import { RcycCounrtyHighlightPipe } from "../rcyc-pipes/rcyc-country-highlight-pipe/rcyc-country-highlight-pipe.pipe";
import { RcycLoginHeaderNewComponent } from "../rcyc-share-components/rcyc-login-reservation/rcyc-login-header-new/rcyc-login-header-new.component";
import { ChannelsComponent } from "../rcyc-components/rcyc-channels/rcyc-channels.component";
import { RcycLimitPipePipeModule } from "../rcyc-pipes/rcyc-limit/rcyc-limit.pipe.module";
import { RcycSafeHtmlPipeModule } from "../rcyc-pipes/rcyc-safe-html/rcyc-safe-html.pipe.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TextMaskModule } from "angular2-text-mask";
import { RcycDecodeInsideHtmlPipe } from "../rcyc-pipes/rcyc-decode-html/rcyc-decode-inside-html.pipe";
import { RcycRequestQuoteComponentNextGen } from "../rcyc-components/rcyc-request-quote/rcyc-request-quote-next-gen.component";
import { RcycRequestQuoteComponentBform } from "../rcyc-components/rcyc-request-quote/rcyc-request-quote-bform.component";
import { RcycRequestQuoteComponent } from "../rcyc-components/rcyc-request-quote/rcyc-request-quote.component";
import { RcycSpecialCountrynameFilterPipe } from "../rcyc-pipes/rcyc-special-countryname-filter/rcyc-special-countryname-filter.pipe";
import { RcycSpecialCountryHighlightPipe } from "../rcyc-pipes/rcyc-special-country-highlight/rcyc-special-country-highlight.pipe";
import { RcycRequestBrochureNewComponent } from "../rcyc-components/rcyc-request-brochure-new/rcyc-request-brochure-new.component";
import { RcycRequestBrochureNewComponentBform } from "../rcyc-components/rcyc-request-brochure-new/rcyc-request-brochure-bform.component";
import { RcycRequestCallComponentBform } from "../rcyc-components/rcyc-request-call/rcyc-request-call-bform.component";
import { RcycRequestCallComponent } from "../rcyc-components/rcyc-request-call/rcyc-request-call.component";
import { RcycSimplePageComponent } from "../rcyc-components/rcyc-simple-page/rcyc-simple-page.component";
import { RcycSpecialOffersLandingComponent } from "../rcyc-components/rcyc-special-offers-landing/rcyc-special-offers-landing.component";
import { RcycHeroModuleComponent } from "../rcyc-components/rcyc-common-components/rcyc-hero-module/rcyc-hero-module.component";
import { RcycSpecialOffersComponent } from "../rcyc-components/rcyc-special-offers/rcyc-special-offers.component";
import { SlideshowModule } from "ng-simple-slideshow";
import { HttpClientModule } from "@angular/common/http";
import { RcycCheckOfferProgramComponent } from "../rcyc-components/rcyc-check-offer-program/rcyc-check-offer-program.component";
import { MilestonesComponent } from "../rcyc-components/rcyc-milestones/rcyc-milestones.component";
import { NguCarouselModule } from "@ngu/carousel";
import { RcycHomeErrorSrcDirective } from "../rcyc-directive/rcyc-home-error-src.directive";
import { RcycAccountExistMessageComponent } from "../rcyc-components/rcyc-guest/rcyc-account-exist-message/rcyc-account-exist-message.component";
import { RcycLoginComponent } from "../rcyc-components/rcyc-guest/rcyc-login/rcyc-login.component";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { RcycPortalClosedInfoPopupComponent } from "../rcyc-modals/rcyc-portal-closed-info-popup/rcyc-portal-closed-info-popup.component";
import { RouterModule, UrlSerializer } from "@angular/router";
import { RcycNguCarouselComponent } from '../rcyc-share-components/rcyc-ngu-carousel/rcyc-ngu-carousel.component';
import { RcycErrorSrcDirective } from './../rcyc-directive/rcyc-error-src.directive';
import { AuthGuard } from '../rcyc-authguard/rcyc-authguard';
import { LoginCheckGuard } from '../rcyc-authguard/rcyc-loginCheckGuard';
import { RcycCountryPhonenoService } from '../rcyc-services/rcyc-country-phoneno/rcyc-country-phoneno.service';
import { RcycPrecontractModalComponent } from '../rcyc-modals/rcyc-precontract-modal/rcyc-precontract-modal.component';
import { RcycPublicMarqueeEventsComponent } from '../rcyc-share-components/rcyc-public-marquee-events/rcyc-public-marquee-events.component';
import { RcycNewFindACruiseService } from '../rcyc-components/rcyc-new-find-a-cruise/rcyc-new-find-a-cruise.service';
import { RcycEditorialHubArticleComponent } from '../rcyc-components/rcyc-editorial-hub-article/rcyc-editorial-hub-article.component';
import { RcycSectionContentComponent } from "../rcyc-components/rcyc-common-components/rcyc-section-content/rcyc-section-content.component";
import { RcycSliderComponent } from "../rcyc-components/rcyc-common-components/rcyc-slider/rcyc-slider.component";
import { RcycImageSliderComponent } from "../rcyc-components/rcyc-common-components/rcyc-image-slider/rcyc-image-slider.component";
import { RcycDestinationsComponent } from "../rcyc-components/rcyc-destinations/rcyc-destinations.component";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RcycReqQuoteComponent } from '../rcyc-components/rcyc-request-quote/rcyc-req-quote/rcyc-req-quote.component';
import { RcycReqBrochureRedesignComponent } from '../rcyc-components/rcyc-req-brochure-redesign/rcyc-req-brochure-redesign.component';
import { RcycViewBrochureComponent } from '../rcyc-components/rcyc-req-brochure-redesign/rcyc-view-brochure/rcyc-view-brochure.component';
import { RcycBrochure5050Component } from '../rcyc-components/rcyc-req-brochure-redesign/rcyc-brochure-50-50/rcyc-brochure-50-50.component';
import { RcycSuitesListComponent } from '../rcyc-components/rcyc-suites-List/rcyc-suites-list.component';
import { RcycOnboardDiningModalComponent } from '../rcyc-components/rcyc-onboard-dining-modal/rcyc-onboard-dining-modal.component';
import { RcycOnboardExpModalComponent } from '../rcyc-components/rcyc-onboard-exp-modal/rcyc-onboard-exp-modal.component';
import { RcycFullImgTileComponent } from '../rcyc-components/rcyc-full-img-tile/rcyc-full-img-tile.component';
import { RcycOffersProgramsLandingComponent } from "../rcyc-components/rcyc-offers-programs-landing/rcyc-offers-programs-landing.component";
import { RcycPaginationComponent } from "../rcyc-components/rcyc-common-components/rcyc-pagination/rcyc-pagination.component";
import { RcycItineraryOverviewModalComponent } from '../rcyc-components/rcyc-itinerary-overview-modal/rcyc-itinerary-overview-modal.component';
import { RcycOffersAndProgramsDetailsComponent } from '../rcyc-components/rcyc-offers-and-programs-details/rcyc-offers-and-programs-details.component';
import { RcycTravelProfessionalsNewComponent } from '../rcyc-components/rcyc-travel-professionals-new/rcyc-travel-professionals-new.component';
import { RcycShoreExcursionsDetailsComponent } from '../rcyc-components/rcyc-shore-escursions-details/rcyc-shore-excursions-details.component';
import { CommonVoyagePriceComponent } from '../rcyc-components/rcyc-booking-configurator/rcyc-common-voyage-price/rcyc-common-voyage-price.component';

// cards
import { Rcyc5050Component } from "../rcyc-components/rcyc-cards/rcyc-50-50/rcyc-50-50.component";
import { RcycExperienceComponent } from "../rcyc-components/rcyc-cards/rcyc-experience/rcyc-experience.component";
import { RcycFeaturedComponent } from "../rcyc-components/rcyc-cards/rcyc-featured/rcyc-featured.component";
import { RcycRequestBrochureAsiaComponent } from '../rcyc-components/rcyc-request-brochure-asia/rcyc-request-brochure-asia.component';
import { RcycOfferComponent } from "../rcyc-components/rcyc-cards/rcyc-offer/rcyc-offer.component";
import { RcycShoreExcursionsComponent } from "../rcyc-components/rcyc-full-img-tile/rcyc-shore-excursions/rcyc-shore-excursions.component";
import { RcycDestinationLandingComponent } from '../rcyc-components/rcyc-destination-landing/rcyc-destination-landing.component';
import { RcycFeaturedExcursionsComponent } from "../rcyc-components/rcyc-full-img-tile/rcyc-featured-excursions/rcyc-featured-excursions.component";
import { RcycNewFindACruiseComponent } from './../rcyc-components/rcyc-new-find-a-cruise/rcyc-new-find-a-cruise.component';
import { DeckPlanRedesignComponent } from "../rcyc-components/rcyc-deck-plan-redesign/rcyc-deck-plan-redesign.component";
import { RcycSuitesModalComponent } from '../rcyc-components/rcyc-deck-plan-redesign/rcyc-suites-modal/rcyc-suites-modal.component';
import { RcycPressComponent } from '../rcyc-components/rcyc-press/rcyc-press.component';
import { RcycPlanYourVoyageComponent } from '../rcyc-components/rcyc-booking-configurator/rcyc-plan-your-voyage/rcyc-plan-your-voyage.component';
import { RcycPlanYourVoyageService } from '../rcyc-components/rcyc-booking-configurator/rcyc-plan-your-voyage/rcyc-plan-your-voyage.service';

@NgModule({
  imports: [
    CommonModule,
    RcycLimitPipePipeModule,
    SlideshowModule,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    RcycSafeHtmlPipeModule,
    HttpClientModule,
    NguCarouselModule,
    SweetAlert2Module,
    RouterModule,
    SlickCarouselModule
  ],
  declarations: [
    RcycCountrynameSearchPipe,
    RcycCounrtyHighlightPipe,
    RcycLoginHeaderNewComponent,
    RcycDecodeInsideHtmlPipe,
    RcycRequestQuoteComponentNextGen,
    RcycRequestQuoteComponentBform,
    RcycRequestQuoteComponent,
    RcycSpecialCountrynameFilterPipe,
    RcycSpecialCountryHighlightPipe,
    RcycRequestBrochureNewComponentBform,
    RcycRequestBrochureNewComponent,
    RcycRequestCallComponentBform,
    RcycRequestCallComponent,
    RcycSimplePageComponent,
    RcycSpecialOffersLandingComponent,
    RcycHeroModuleComponent,
    RcycSpecialOffersComponent,
    RcycCheckOfferProgramComponent,
    ChannelsComponent,
    MilestonesComponent,
    RcycHomeErrorSrcDirective,
    RcycAccountExistMessageComponent,
    RcycLoginComponent,
    RcycPortalClosedInfoPopupComponent,
    RcycNguCarouselComponent,
    RcycPrecontractModalComponent,
    RcycErrorSrcDirective,
    RcycPublicMarqueeEventsComponent,
    RcycEditorialHubArticleComponent,
    RcycRequestBrochureAsiaComponent,
    RcycSectionContentComponent,
    RcycSliderComponent,
    RcycImageSliderComponent,
    RcycDestinationsComponent,
    Rcyc5050Component,
    RcycExperienceComponent,
    RcycFeaturedComponent,
    RcycReqQuoteComponent,
    RcycReqBrochureRedesignComponent,
    RcycViewBrochureComponent,
    RcycBrochure5050Component,
    RcycSuitesListComponent,
    RcycOnboardDiningModalComponent,
    RcycOnboardExpModalComponent,
    RcycFullImgTileComponent,
    RcycOffersProgramsLandingComponent,
    RcycOfferComponent,
    RcycShoreExcursionsComponent,
    RcycPaginationComponent,
    RcycDestinationLandingComponent,
    RcycFeaturedExcursionsComponent,
    RcycItineraryOverviewModalComponent,
    RcycOffersAndProgramsDetailsComponent,
    RcycTravelProfessionalsNewComponent,
    RcycShoreExcursionsDetailsComponent,
    RcycNewFindACruiseComponent,
    DeckPlanRedesignComponent,
    RcycSuitesModalComponent,
    RcycPressComponent,
    RcycPlanYourVoyageComponent,
    CommonVoyagePriceComponent,
  ],
  exports: [
    RcycLoginComponent,
    RcycAccountExistMessageComponent,
    RcycHomeErrorSrcDirective,
    MilestonesComponent,
    RcycCheckOfferProgramComponent,
    ChannelsComponent,
    RcycCountrynameSearchPipe,
    RcycCounrtyHighlightPipe,
    RcycLoginHeaderNewComponent,
    RcycDecodeInsideHtmlPipe,
    RcycRequestQuoteComponentNextGen,
    RcycRequestQuoteComponentBform,
    RcycRequestQuoteComponent,
    RcycSpecialCountrynameFilterPipe,
    RcycSpecialCountryHighlightPipe,
    RcycRequestBrochureNewComponentBform,
    RcycRequestBrochureNewComponent,
    RcycRequestCallComponentBform,
    RcycRequestCallComponent,
    RcycSimplePageComponent,
    RcycSpecialOffersLandingComponent,
    RcycHeroModuleComponent,
    RcycSpecialOffersComponent,
    RcycPortalClosedInfoPopupComponent,
    RcycNguCarouselComponent,
    RcycErrorSrcDirective,
    RcycPrecontractModalComponent,
    RcycPublicMarqueeEventsComponent,
    RcycEditorialHubArticleComponent,
    RcycSectionContentComponent,
    RcycSliderComponent,
    RcycImageSliderComponent,
    RcycDestinationsComponent,
    Rcyc5050Component,
    RcycExperienceComponent,
    RcycFeaturedComponent,
    RcycReqQuoteComponent,
    RcycReqBrochureRedesignComponent,
    RcycViewBrochureComponent,
    RcycBrochure5050Component,
    RcycSuitesListComponent,
    RcycOnboardDiningModalComponent,
    RcycOnboardExpModalComponent,
    RcycFullImgTileComponent,
    RcycOffersProgramsLandingComponent,
    RcycOfferComponent,
    RcycShoreExcursionsComponent,
    RcycPaginationComponent,
    RcycDestinationLandingComponent,
    RcycItineraryOverviewModalComponent,
    RcycOffersAndProgramsDetailsComponent,
    RcycTravelProfessionalsNewComponent,
    RcycShoreExcursionsDetailsComponent,
    RcycNewFindACruiseComponent,
    RcycFeaturedExcursionsComponent,
    DeckPlanRedesignComponent,
    RcycSuitesModalComponent,
    RcycPressComponent,
    RcycPlanYourVoyageComponent,
    CommonVoyagePriceComponent,
  ],
  providers: [
    AuthGuard,
    LoginCheckGuard,
    RcycCountryPhonenoService,
    RcycNewFindACruiseService,
    RcycPlanYourVoyageService,
  ]
})
export class RcycSharedModuleModule { }
