import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rcyc-section-content',
  templateUrl: './rcyc-section-content.component.html',
  styleUrls: ['./rcyc-section-content.component.css'],
})
export class RcycSectionContentComponent implements OnInit {
  @Input() data: any;
  @Input() variant: any;
  @Input() sliderVariant: any;
  @Input() heading: any;
  @Input() isChartersPage: any;
  @Input() isFAQ: any;

  actionUrl: string = '';

  ngOnInit(): void {
    let tempCtaUrl = this.data?.cta?.url;
    if (tempCtaUrl && tempCtaUrl?.indexOf('internal:') !== -1) {
      tempCtaUrl = tempCtaUrl?.split(':')[1];
    }
    this.actionUrl = tempCtaUrl;
  }
}
