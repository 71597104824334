import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RcycChannelsService } from './rcyc-channels.service';
import { AppConfig } from '../../rcyc-config/config';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { DomSanitizer } from '@angular/platform-browser';
import { RcycCommonLogoutService } from '../../rcyc-services/rcyc-logout/rcyc-logout.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';


@Component({
  selector: 'app-channels',
  templateUrl: './rcyc-channels.component.html',
  styleUrls: ['./rcyc-channels.component.css'],
  // encapsulation: ViewEncapsulation.None,
  providers: [RcycChannelsService]
})
export class ChannelsComponent implements OnInit {

  public carouselOne: any;
  deckTabObject: any;

  API_BASE_URL: any = AppConfig.API_BASE_URL;
  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  ELASTIC_API_BASE_URL: any = AppConfig.ELASTIC_API_BASE_URL;

  tabcontent: any;
  basepath: any;
  apiResponseA: any;
  apiResponseB: any;
  apiResponseC: any;
  apiResponseD: any;
  apiResponseE: any;

  apiResponseATemp: any = [];
  apiResponseBTemp: any = [];
  apiResponseCTemp: any = [];
  apiResponseDTemp: any = [];
  apiResponseETemp: any = [];

  apiResponseAStatus: any = false;
  apiResponseBStatus: any = false;
  apiResponseCStatus: any = false;
  apiResponseDStatus: any = false;
  apiResponseEStatus: any = false;

  apiUrlA: any;
  apiUrlB: any;
  apiUrlC: any;
  apiUrlD: any;
  apiUrlE: any;
  apiUrlMetatag: any;

  currentUrlPath: any;
  currentUrlPathAsArray: any;
  currentUrlKey: any;
  currentComponentName: any;
  currentSlide = 0;
  completeSuccessQueryString1: string;
  snapshotUrl: any;
  mobileView: boolean = false;

  responseArray: any;
  apiResponseDNew:any;
  carouselData: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const fancyElem:any = document.querySelector('.fancybox-container');
    if(fancyElem) {
      this.mobileView = false;
      if (event.target.innerWidth <= 769) {
        this.mobileView = true;
        fancyElem.classList.remove("fancybox-container-hide");
      } else {
        this.mobileView = false;
        fancyElem.classList.add("fancybox-container-hide");
      }
    }
  }

  constructor(

    private router: Router,
    private rcycChannelsService: RcycChannelsService,
    private rcycCommonService: RcycCommonService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private rcycCommonLogoutService: RcycCommonLogoutService,
    private rcycInternalApisService: RcycInternalApisService,
  ) {

    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlKey = this.currentUrlPathAsArray[0].path;

    // this.currentComponentName = 	this.activatedRoute.routeConfig.component.name;
    this.currentComponentName = 'RcycChannelsComponent';
    // Current subHeader Status
    this.rcycCommonService.changeSubHeaderStatus(true);

    // this.currentUrlPathAsArray= this.activatedRoute.snapshot.url;
    // this.currentUrlPath = this.router.url;
    // this.currentUrlPathAsArray = this.currentUrlPath.split('/');
    // this.currentUrlPathAsArray.shift();

    // this.currentUrlKey = this.currentUrlPathAsArray[0];

    if (this.currentUrlKey ==='yachts') {
      this.currentUrlKey =
        this.currentUrlPathAsArray[0].path +
        "/" +
        this.currentUrlPathAsArray[1].path;
    }
    const switchKey = this.currentUrlKey;
    switch (switchKey) {

      case 'cruise-ports':

        // change changeCurrentComponent
        this.rcycCommonService.changeCurrentComponent(this.currentComponentName);

        // Call get Metatag function
        this.apiUrlMetatag = '/api/v1/meta/destinations?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycDestinationsContent();
        break;

      case 'yachts/evrima':
      // case 'experience':

        // Call get Metatag function
        this.apiUrlMetatag = '/api/v1/meta/yachts?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycEvrimaExperienceContent();
        break;

      case 'yachts/ilma':
        this.apiUrlMetatag = '/api/v1/meta/yachts?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycIlmaExperienceContent();
        break;

      case 'yachts/luminara':
        this.apiUrlMetatag = '/api/v1/meta/yachts?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycLuminaraExperienceContent();
        break;

      case 'presailing':

        // Call get Metatag function
        this.apiUrlMetatag = '/api/v1/meta/presailing?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycPresailingContent();
        break;

      case 'complete':

        this.apiUrlMetatag = '/api/v1/meta/complete?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycCompleteContent();
        break;

      case 'signout':

        this.apiUrlMetatag = '/api/v1/meta/signout?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycSignoutContent();
        break;

      case 'complete2':

        this.apiUrlMetatag = '/api/v1/meta/complete2?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycComplete2Content();
        break;

      case 'videos':

        this.apiUrlMetatag = '/api/v1/meta/videos?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycVideosContent();
      break;

      case '404':

        this.apiUrlMetatag = '/api/v1/meta/notfoundpage?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        this.rcycNotFoundPageContent();
        break;

      default:

        /*const splittedValue= switchKey.replace(/^complete/, '');
        if(splittedValue || splittedValue === "") {

          // Call get Metatag function
          this.apiUrlMetatag = '/api/v1/meta/complete?_format=json';
          this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

          this.rcycCompleteContent();
        }*/

        break;

    }
  }

  ngOnInit() {

    if (window.innerWidth <= 769){
      this.mobileView = true;
    }else this.mobileView = false;

    // Carousel settings
    this.carouselOne = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 500,
      interval: 300000,
      point: {
        visible: false
      },
      load: 3,
      touch: true,
      loop: true,
      custom: 'banner'
    };

    // Tab options
    this.deckTabObject = [
      {
        name: 'Onboard',
        class: 'tab-link tab-link_suites is-active',
        contentClass: 'tab-pane is-visible',
        active: true
      },
      {
        name: 'Ashore',
        class: 'tab-link',
        contentClass: 'tab-pane',
        active: false
      }
    ];

  }

  /* ========================================================================================================================
      Function to display Full Api Urls of Destination
   ========================================================================================================================== */
  rcycDestinationsContent() {

    this.apiUrlA = '/api/v1/destinations/header/a?_format=json';
    // this.apiUrlB = "/api/v1/destinations/content/b?_format=json";
    this.apiUrlB = '/api/v1/banners/destinations/b?_format=json';
    this.apiUrlC = '/api/v1/destinations/content/c?_format=json';
    this.apiUrlD = '/api/v1/regionlist?_format=json';

    this.rcycChannelContent();

  }

  /* ==========================================================================================================================
      Function to display Full Api Urls of Experience
   =========================================================================================================================== */
  rcycEvrimaExperienceContent() {

    this.apiUrlA = '/api/v1/evrima_experience/header/a?_format=json';
    // this.apiUrlB = "/api/v1/experience/content/b?_format=json";
    this.apiUrlB = '/api/v1/banners/evrima_experience/b?_format=json';
    this.apiUrlC = '/api/v1/evrima_experience/content/c?_format=json';
    this.apiUrlD = '/api/v1/evrima_experience/content/d?_format=json';

    this.rcycChannelContent();

  }

  
  rcycIlmaExperienceContent() {
    this.apiUrlA = '/api/v1/ilma_experience/header/a?_format=json';
    this.apiUrlB = '/api/v1/banners/ilma_experience/b?_format=json';
    this.apiUrlC = '/api/v1/ilma_experience/content/c?_format=json';
    this.apiUrlD = '/api/v1/ilma_experience/content/d?_format=json';

    this.rcycChannelContent();
  }

  rcycLuminaraExperienceContent() {
    this.apiUrlA = '/api/v1/luminara_experience/header/a?_format=json';
    this.apiUrlB = '/api/v1/banners/luminara_experience/b?_format=json';
    this.apiUrlC = '/api/v1/luminara_experience/content/c?_format=json';
    this.apiUrlD = '/api/v1/luminara_experience/content/d?_format=json';

    this.rcycChannelContent();
  }

  /* ===========================================================================================================================
      Function to display Full Api Urls of Presailing
   =============================================================================================================================*/
  rcycPresailingContent() {

    this.apiUrlA = '/api/v1/presailing/header/a?_format=json';
    // this.apiUrlB = '/api/v1/presailing/content/b?_format=json';
    this.apiUrlB = '/api/v1/banners/presailing/b?_format=json';
    this.apiUrlC = '/api/v1/presailing/content/c?_format=json';
    this.apiUrlD = '/api/v1/presailing/content/d?_format=json';

    this.rcycChannelContent();

  }

  /* ==============================================================================================================================
      Function to display Full Api Urls of Complete
   ================================================================================================================================ */
  rcycCompleteContent() {

    this.apiUrlA = '/api/v1/complete/header/a?_format=json';
    this.apiUrlB = '/api/v1/complete/content/b?_format=json';
    this.apiUrlC = '/api/v1/complete/content/c?_format=json';
    this.apiUrlD = '/api/v1/complete/content/d?_format=json';

    this.rcycChannelContent();

  }

  /* ==============================================================================================================================
      Function to display Full Api Urls of Signout
   ================================================================================================================================ */
  rcycSignoutContent() {

    this.apiUrlA = '/api/v1/signout/header/a?_format=json';
    this.apiUrlB = '/api/v1/signout/content/b?_format=json';
    this.apiUrlC = '/api/v1/signout/content/c?_format=json';
    this.apiUrlD = '/api/v1/signout/content/d?_format=json';

    this.rcycCommonLogoutService.logout();
    this.rcycChannelContent();
    
  }

  /* ==============================================================================================================================
      Function to display Full Api Urls of Complete
   ================================================================================================================================ */
  rcycComplete2Content() {

    this.apiUrlA = '/api/v1/complete2/header/a?_format=json';
    this.apiUrlB = '/api/v1/complete2/content/b?_format=json';
    this.apiUrlC = '/api/v1/complete2/content/c?_format=json';
    this.apiUrlD = '/api/v1/complete2/content/d?_format=json';

    this.rcycChannelContent();

  }
  /* ==============================================================================================================================
      Function to display Full Api Urls of Videos
   ================================================================================================================================ */
  rcycVideosContent() {

    this.apiUrlA = '/api/v1/videos/header/a?_format=json';
    this.apiUrlB = '/api/v1/banners/videos/b?_format=json';
    this.apiUrlC = '/api/v1/videos/content/c?_format=json';
    this.apiUrlD = '/api/v1/videos/content/d?_format=json';

    this.rcycChannelContent();

  }


  /* ==============================================================================================================================
      Function to display Full Api Urls of Not Found Page
   ================================================================================================================================ */
  rcycNotFoundPageContent() {

    this.apiUrlA = '/api/v1/notfoundpage/header/a?_format=json';
    this.apiUrlB = '/api/v1/notfoundpage/video/b?_format=json';
    this.apiUrlC = '/api/v1/notfoundpage/content/c?_format=json';
    this.apiUrlD = '/api/v1/notfoundpage/content/d?_format=json';

    this.rcycChannelContent();

  }
  
  /* ==============================================================================================================================
      Function to display Full Apis Channels
   ================================================================================================================================ */
  rcycChannelContent() {

    /****************** Section A********************/

    this.rcycChannelsService.channels(this.apiUrlA).subscribe(
      response => {

        this.apiResponseATemp = response;
        if (this.apiResponseATemp && this.apiResponseATemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {

            this.apiResponseAStatus = true;
            this.apiResponseA = response[0];

            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A(this.apiResponseA);

          } else {

            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A([]);
          }
        } else {

          // Changing subheader A value
          this.rcycCommonService.changeSubHeaderDataArray_A([]);
        }

      },
      err => {
        console.log('Channels A Api err');
        console.log(err);
      }
    );


    /******************Header  Section B********************/

    this.rcycChannelsService.channels(this.apiUrlB).subscribe(
      response => {

        this.apiResponseBTemp = response;
        if (this.apiResponseBTemp && this.apiResponseBTemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.responseArray = res;
            this.carouselData = res;
            if (this.carouselData[0].field_image_375px) {
              this.carouselData[0].field_image = this.carouselData[0].field_image_375px;
            }
            const resArray = [];
            for (const index in res) {

              const factor = res[index];
              if (factor.field_image) {

                factor.field_images_srcset = this.IMG_BASE_URL + factor.field_image + ' 1600w, ' + this.IMG_BASE_URL +
                  factor.field_image_1440px + ' 1000w, ' + this.IMG_BASE_URL
                  + factor.field_image_768px + ' 800w, ' +
                  this.IMG_BASE_URL + factor.field_image_375px + ' 500w';
                factor.field_images_src1 = this.IMG_BASE_URL + factor.field_image;
                factor.field_images_src2 = this.IMG_BASE_URL + factor.field_image_1440px;
                factor.field_images_src3 = this.IMG_BASE_URL + factor.field_image_768px;
                factor.field_images_src4 = this.IMG_BASE_URL + factor.field_image_375px;
                factor.field_image = this.IMG_BASE_URL + factor.field_image;

                const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_image);

                factor.altTextBannerImg = factor.title + ' ' + imgTitle;
                factor.titleTextBannerImg = factor.title + ' ' + imgTitle;
              } else {

                factor.altTextBannerImg = factor.title;
                factor.titleTextBannerImg = factor.title;
              }
              resArray.push(factor);
              if (parseInt(index, 10) + 1 === res.length) {

                this.apiResponseBStatus = true;
                this.apiResponseB = resArray;

              }
            }
          }
        }
      },
      err => {
        console.log('Channels B Api err');
        console.log(err);
      }
    );

    /****************** Section C********************/

    this.rcycChannelsService.channels(this.apiUrlC).subscribe(
      response => {

        this.apiResponseCTemp = response;
        if (this.apiResponseCTemp && this.apiResponseCTemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {

            this.apiResponseCStatus = true;
            this.apiResponseC = response[0];
          }
        }

      },
      err => {
        console.log('Channels C Api err');
        console.log(err);
      }
    );

    /****************** Section D********************/

    this.rcycChannelsService.channels(this.apiUrlD).subscribe(
      response => {

        this.apiResponseDTemp = response;
        if (this.apiResponseDTemp && this.apiResponseDTemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {

            const resArray = [];
            for (const index in res) {

              const factor = res[index];

              if (factor.field_image) {

                factor.field_image = this.IMG_BASE_URL + factor.field_image;

                const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_image);

                factor.altTextOnBoardImg = factor.title + ' ' + imgTitle;
                factor.titleTextOnBoardImg = factor.title + ' ' + imgTitle;
              } else {

                factor.altTextOnBoardImg = factor.title;
                factor.titleTextOnBoardImg = factor.title;
              }
              let field_url_keyNew;
              switch (this.currentUrlKey) {

                case 'cruise-ports':

                  const field_group_url_keyNew = factor.field_group_url_key.toLowerCase();
                  factor.channelUrl = '/luxury-' + field_group_url_keyNew + '-cruises';
                  break;

                // case 'experience':

                //   field_url_keyNew = factor.field_url_key.toLowerCase();
                //   if(factor.field_url_key && factor.field_url_key.includes('/')) {
                //     factor.channelUrl = '/experience/' + field_url_keyNew;
                //   } else {
                //     factor.channelUrl = '/' + field_url_keyNew;
                //   }
                //   break;

                case 'yachts/evrima':

                  field_url_keyNew = factor.field_url_key.toLowerCase();
                  if (
                    factor.field_url_key &&
                    factor.field_url_key.charAt(0) === "/"
                  ) {
                    factor.channelUrl = "/yachts/evrima/" + field_url_keyNew;
                  } else {
                    factor.channelUrl = "/" + field_url_keyNew;
                  }
                  break;

                case 'yachts/ilma':
                  field_url_keyNew = factor.field_url_key.toLowerCase();
                  if (
                    factor.field_url_key &&
                    factor.field_url_key.charAt(0) === "/"
                  ) {
                    factor.channelUrl = "/yachts/ilma/" + field_url_keyNew;
                  } else {
                    factor.channelUrl = "/" + field_url_keyNew;
                  }
                  break;

                case 'yachts/luminara':
                    field_url_keyNew = factor.field_url_key.toLowerCase();
                    if (
                      factor.field_url_key &&
                      factor.field_url_key.charAt(0) === "/"
                    ) {
                      factor.channelUrl = "/yachts/luminara/" + field_url_keyNew;
                    } else {
                      factor.channelUrl = "/" + field_url_keyNew;
                    }
                    break;

                case 'presailing':

                  // let field_url_keyNew= factor.field_url_key.toLowerCase();
                  field_url_keyNew = factor.field_url_key;
                  factor.channelUrl = '/' + field_url_keyNew;
                  break;

                // complete
                default:

                  // let field_url_keyNew= factor.field_url_key.toLowerCase();
                  field_url_keyNew = factor.field_url_key;
                  factor.channelUrl = '/' + field_url_keyNew;
                  break;
              }

              resArray.push(factor);
              if (parseInt(index, 10) + 1 === res.length) {

                this.apiResponseDStatus = true;
                this.apiResponseD = resArray;
                /** new code for video Starts */
                this.apiResponseD.map((key, index) => {
                  if (key.field_video) {
                    this.getPreviewImage(key.field_video, index);
                  }
                });
                /** new code for video Ends */
                const newSplittedResArray=JSON.parse(JSON.stringify(resArray));
                let chunkArray=[];
                this.apiResponseDNew=[];            
                for(let i:number=0;i=newSplittedResArray.length;i++) {  
                  // console.log(i);               
                  chunkArray=newSplittedResArray.splice(0,3);
                  this.apiResponseDNew.push(chunkArray);                  
                }

                // console.log(this.apiResponseDNew, 'this.apiResponseDNew ===>>>');
              }
            }

          }
        }

      },
      err => {
        console.log('Channels D Api err');
        console.log(err);
      }
    );

  }

  // Function on Learn more click
  learnMoreClick(event, item) {
    /****************** Section E********************/

    // console.log("click --- item");
    // console.log(item);
    // console.log(item.tid);
    /*this.apiUrlE= "/api/v1/getGroupUrl/"+item.tid+"?_format=json";

    this.rcycChannelsService.channels(this.apiUrlE).subscribe(
      response => {
        this.apiResponseEStatus = true;
        //this.apiResponseE = response;

        // pass channels tid
        this.rcycCommonService.changeChannelsTid(response[0].tid);

        this.router.navigate(['/luxury-'+response[0].field_group_url_key+'-cruises']);
      },
      err => {
        console.log(err);
      }
    );*/

    // this.rcycCommonService.changeChannelsTid(item.tid);

    // this.router.navigate(['/luxury-'+item.field_group_url_key+'-cruises']);
  }


  nextCarouselload(ev) {

    // console.log(ev);
  }

  onmoveFn(data: NguCarouselStore) {

    this.currentSlide = data.currentSlide;
  }

  clickFn(data: NguCarouselStore) {

    this.currentSlide = data.currentSlide - 1;
  }

  // Clear Voyage Details
  clearVoyageDetails() {
    this.rcycCommonService.removeSessionData('specialOfferPageDetails');
  }

  getPreviewImage(videoUrl: any, index: any) {
    let i = Math.floor(index/3);
    let j = index%3;
    if (videoUrl) {
      const splittedArray: any = videoUrl.split('/');
      const splittedArrayContent = splittedArray[splittedArray.length - 1];
      const substring = '?';
      let videoIndex = '';
      if (splittedArrayContent.indexOf(substring) !== -1) {
        videoIndex = splittedArrayContent.split('?')[0];
      } else {
        videoIndex = splittedArray[splittedArray.length - 1];
      }

      const vimeoUrl: any = 'https://vimeo.com/api/v2/video/' + videoIndex + '.json';
      this.rcycInternalApisService.getVimeoApi(vimeoUrl).subscribe(
        (response) => {
          if (response[0].thumbnail_large) {
            this.apiResponseD[index].field_image = response[0].thumbnail_large;
            this.apiResponseDNew[i][j].field_image = response[0].thumbnail_large;
          }
        },
        (err) => {

          console.log('vimeo err ====');
          console.log(err);
        },
      );
    }

  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.setVideoThumbnailImgaHeight();
  // }
  // ngAfterViewChecked()
  // {
  //   this.setVideoThumbnailImgaHeight();
  // }
  // setVideoThumbnailImgaHeight()
  // {

  //   var imgData = document.getElementsByClassName('imageTypeImg');
  //   if(imgData&&imgData.length>0)
  //   {
  //     document.getElementById("mainEachImgIdVideoThumb_0").style.height = imgData[0]['clientHeight']+"px";
  //     var elms = document.querySelectorAll("[id='mainEachImgIdVideoThumb']");
  //     for(var i = 0; i < elms.length; i++)
  //     document.getElementById("mainEachImgIdVideoThumb_"+i+1).style.height = imgData[0]['clientHeight']+"px";
  //   }

  // }
}
