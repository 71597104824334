import { Component, OnInit, Input } from '@angular/core';
// import { CookieService } from "ngx-cookie-service";
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
// import { CookiesService } from '@ngx-utils/cookies';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-rcyc-gdpr-modal',
  templateUrl: './rcyc-gdpr-modal.component.html',
  styleUrls: ['./rcyc-gdpr-modal.component.css']
})
export class RcycGdprModalComponent implements OnInit {
  @Input() gdprRegion: any;
  constructor(
    // private cookieService: CookieService,
    private rcycCommonService: RcycCommonService,
    private cookies: CookieService
  ) { }
  UserDetails: any = {};
  ngOnInit() {
    setTimeout(() => {
      this.cookie_Consent();
    }, 6000);
  }
  accept_gdpr() {
    const modal = document.getElementById('gdpr_modal');
    // this.cookieService.set("GDPR", "ACCEPT");
    this.cookies.set('GDPR', 'ACCEPT');
    this.rcycCommonService.setcookieConsent('ACCEPT');
    modal.style.display = 'none';
  }

  decline_gdpr() {
    const modal = document.getElementById('gdpr_modal');
    // this.cookieService.set("GDPR", "DECLINE");
    this.cookies.set('GDPR', 'DECLINE');
    this.rcycCommonService.setcookieConsent('DECLINE');
    modal.style.display = 'none';
  }

  cookie_Consent() {


    if (!localStorage.getItem('UserDetails')) {
      this.rcycCommonService.getUser_Details().subscribe(
        response => {
          // let temp_data;
          this.UserDetails = response;

          this.rcycCommonService.setData('UserDetails', this.UserDetails);
          // console.log(this.cookies.get("GDPR"));
          if (this.UserDetails.continent_code === 'EU' || this.gdprRegion === 'malta' || this.gdprRegion === 'california') {
            // if (!this.cookieService.get("GDPR")) {
            //   modal.style.display = "block";
            // }

            if (!this.cookies.get('GDPR')) {
              const modal = document.getElementById('gdpr_modal');
              if (modal) {
                modal.style.display = 'block';
                this.rcycCommonService.setGDPRStatus(true);
              }
            }
          }
        },
        err => {
          this.UserDetails.country_code = 'US';
          this.UserDetails.continent_code = 'NA';
          this.rcycCommonService.setData('UserDetails', this.UserDetails);
        }
      );
    } else {
      this.UserDetails = this.rcycCommonService.getData('UserDetails');
      if (this.UserDetails.continent_code === 'EU' || this.gdprRegion === 'malta' || this.gdprRegion === 'california') {
        // if (!this.cookieService.get("GDPR")) {
        //   modal.style.display = "block";
        // }

        if (!this.cookies.get('GDPR')) {
          const modal = document.getElementById('gdpr_modal');
          if (modal) {
            modal.style.display = 'block';
            this.rcycCommonService.setGDPRStatus(true);
          }
        }
      }
      this.rcycCommonService.expireLocation();
    }
  }
}
