import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RcycCommonService } from '../rcyc-services/rcyc-common/rcyc-common.service';

@Injectable()
export class LoginCheckGuard implements CanActivate {
  userDetail: any;

  constructor(
    private router: Router,
    private rcycCommonService: RcycCommonService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (localStorage.getItem('loggedUserDetails')) {

      const userDetail = JSON.parse(localStorage.getItem('loggedUserDetails'));
      if (userDetail) {

        switch (userDetail.type) {
          case 'TA' :

            // this.router.navigate(['/travel-agent/dashboard']);
            const guestMatch = state.url.match('/guest');
            if (guestMatch && guestMatch.length) {

              const guestRegMatch = state.url.match('/registration');
              const guestLoginMatch = state.url.match('/login');
              if (guestRegMatch || guestLoginMatch) {

                return true;
              } else {

                this.router.navigate(['/travel-agent/dashboard']);
              }
            } else {

              // return true;
              const TARegMatch = state.url.match('/travel-agent/registration');
              if (TARegMatch) {

                return true;
              } else {

                this.router.navigate(['/travel-agent/dashboard']);
              }
            }
          break;
          case 'RA' :

          // this.router.navigate(['/travel-agent/dashboard']);
          const RAMatch = state.url.match('/reservation-agent');
          if (RAMatch && RAMatch.length) {

            // const guestRegMatch = state.url.match('/registration');
            const RALoginMatch = state.url.match('/login');
            if (RALoginMatch) {

              return true;
            } else {

              this.router.navigate(['/reservation-agent/guest-search']);
            }
          } else {

            // return true;
            // const TARegMatch = state.url.match('/travel-agent/registration');
            // if (TARegMatch) {

            //   return true;
            // } else {

            //   this.router.navigate(['/travel-agent/dashboard']);
            // }
          }
        break;
          default :

            // this.router.navigate(['/guest/dashboard']);
            const TAMatch = state.url.match('/travel-agent');
            if (TAMatch && TAMatch.length) {

              const TARegMatch = state.url.match('/travel-agent/registration');
              const TALoginMatch = state.url.match('/travel-agent/login');
              if (TARegMatch || TALoginMatch) {

                return true;
              } else {

                this.router.navigate(['/guest/dashboard']);
              }
            } else {

              // return true;
              const guestRegMatch = state.url.match('/registration');
              if (guestRegMatch) {

                return true;
              } else {

                this.router.navigate(['/guest/dashboard']);
              }
            }
          break;

        }
      } else {

        if (localStorage.getItem('loggedUserDetails')) {
          localStorage.removeItem('loggedUserDetails');
          this.rcycCommonService.setLoggedInStatus(true);
        }

        // change changeCurrentUserName
        this.rcycCommonService.changeCurrentUser('currentUserName');
        this.rcycCommonService.setSessionExpiredStatus(false);
        return true;
      }
    } else {

      if (localStorage.getItem('loggedUserDetails')) {
        localStorage.removeItem('loggedUserDetails');
        this.rcycCommonService.setLoggedInStatus(true);
      }

      // change changeCurrentUserName
      this.rcycCommonService.changeCurrentUser('currentUserName');
      this.rcycCommonService.setSessionExpiredStatus(false);
      return true;
    }


  }

}
