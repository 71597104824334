import { Action } from '@ngrx/store';
import  {All, ReservationActionTypes}  from '../actions/rcyc-reservation.action';
import * as UserModel from '../models/user.model';

// Section 1
export interface State{
    entity:{ [id:number]: UserModel.UserBookings },
    bookingTotalCount:number,
    loaded:boolean,
    loading:boolean,
    errorMessage:string,
    errorMessageCode:number,
    currentBookingId:string,
    bookingDetails:any,
    bookingDetailsErrorMsg:string,
    bookingDetailsErrorMsgCode:number,
    bookingDetailsloaded:boolean,
    bookingDetailsloading:boolean,
    shoreExReservationDetails:any,
    marqueeReservationDetails:any,
    diningReservationDetails:any,
    spaReservationDetails:any,
    reservationLoading:boolean,
    reservationLoaded:boolean,
    reservationErrorMessages:string,
    reservationCounter:number,
    totalcount: number
}

export const initialiState: State = {
    entity:{},
    bookingTotalCount:null,
    loaded:false,
    loading:false,
    errorMessage:null,
    errorMessageCode:null,
    currentBookingId:null,
    bookingDetails:<any>null,
    bookingDetailsErrorMsg:null,
    bookingDetailsErrorMsgCode:null,
    bookingDetailsloaded:false,
    bookingDetailsloading:false,
    shoreExReservationDetails:{},
    marqueeReservationDetails:{},
    diningReservationDetails:{},
    spaReservationDetails:{},
    reservationLoading:false,
    reservationLoaded:false,
    reservationErrorMessages:null,
    reservationCounter:0,
    totalcount:0
}

// Section 2
export function reducer(state = initialiState, action: All):State {

    // Section 3
    let reservationCounter;
    switch(action.type) {
        case ReservationActionTypes.LIST_BOOKING:
            return {...state,loaded:false,loading:true,errorMessage:null};

        case ReservationActionTypes.LIST_BOOKING_SUCCESS:
            const stat={};
            let bookingTotalCount;
            action.payload.data.bookingList.booking.forEach((asa)=>{stat[asa.bookingId]=asa;});
            try{
                bookingTotalCount  = action.payload.data.bookingList.totalcount;
            }catch(err){
                bookingTotalCount=null;
            }
            return {...state,entity:stat,loaded:true,loading:false,errorMessage:null,totalcount:bookingTotalCount};

        case ReservationActionTypes.LIST_BOOKING_FAILED_ERRORMSG:

            return {...state,errorMessage:action.payload.DrupalMessage,errorMessageCode: action.payload.errcode, loaded:false,loading:false};

        case ReservationActionTypes.MY_RESERVATION_BOOKINGID:
            return {...state,currentBookingId:action.payload,bookingDetails:null};

        case ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS:
            return {...state,bookingDetailsloading:true,bookingDetailsloaded:false,bookingDetailsErrorMsg:null};

        case ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS_SUCCESS:
            let voyag={};
            let bookingDetails = {...action.payload.data}
            if(action.payload.data.voyages){
                action.payload.data.voyages.forEach(
                    (voyage)=>{voyag[voyage.voyageCode]=voyage;}
                )
            }
            bookingDetails.voyages=voyag;
            return {...state,bookingDetails:bookingDetails,bookingDetailsloaded:true,bookingDetailsloading:false,bookingDetailsErrorMsg:null};

        case ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS_FAILED:
            return {...state,bookingDetailsloaded:false,bookingDetailsloading:false};

        case ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS_FAILED_ERRORMSG:
       
            return {...state,bookingDetailsErrorMsg:action.payload.DrupalMessage,bookingDetailsErrorMsgCode:action.payload.errcode,bookingDetailsloaded:false,bookingDetailsloading:false};

        case ReservationActionTypes.MY_RESERVATION_SHORE_EX_BOOKING:
            reservationCounter =state.reservationCounter-1;
            return {...state,reservationLoaded:false,reservationLoading:true,reservationCounter};

        case ReservationActionTypes.MY_RESERVATION_SHORE_EX_BOOKING_SUCCESS:
            reservationCounter =state.reservationCounter+1;
            return {...state,shoreExReservationDetails:action.payload,reservationLoaded:true,reservationLoading:false,reservationCounter}; //reservationErrorMessages:null,

        case ReservationActionTypes.MY_RESERVATION_SHORE_EX_BOOKING_FAILED:
            return {...state,reservationLoaded:false,reservationLoading:false,reservationErrorMessages:action.payload};

        case ReservationActionTypes.MY_RESERVATION_MARQUEE_BOOKING:
            reservationCounter =state.reservationCounter-1;
            return {...state,reservationLoaded:false,reservationLoading:true,reservationCounter};

        case ReservationActionTypes.MY_RESERVATION_MARQUEE_BOOKING_SUCCESS:
            reservationCounter =state.reservationCounter+1;
            return {...state,marqueeReservationDetails:action.payload,reservationLoaded:true,reservationLoading:false,reservationCounter}; //reservationErrorMessages:null,

        case ReservationActionTypes.MY_RESERVATION_MARQUEE_BOOKING_FAILED:
            return {...state,reservationLoaded:false,reservationLoading:false,reservationErrorMessages:action.payload};

        case ReservationActionTypes.MY_RESERVATION_DINING_BOOKING:
            reservationCounter =state.reservationCounter-1;
            return {...state,reservationLoaded:false,reservationLoading:true,reservationCounter};

        case ReservationActionTypes.MY_RESERVATION_DINING_BOOKING_SUCCESS:
            reservationCounter =state.reservationCounter+1;
            return {...state,diningReservationDetails:action.payload,reservationLoaded:true,reservationLoading:false,reservationCounter};  //reservationErrorMessages:null,

        case ReservationActionTypes.MY_RESERVATION_DINING_BOOKING_FAILED:
            return {...state,reservationLoaded:false,reservationLoading:false,reservationErrorMessages:action.payload};

        case ReservationActionTypes.MY_RESERVATION_SPA_BOOKING:
            reservationCounter =state.reservationCounter-1;
            return {...state,reservationLoaded:false,reservationLoading:true,reservationCounter};

        case ReservationActionTypes.MY_RESERVATION_SPA_BOOKING_SUCCESS:
            reservationCounter =state.reservationCounter+1;
            return {...state,spaReservationDetails:action.payload,reservationLoaded:true,reservationLoading:false,reservationCounter};  //reservationErrorMessages:null,

        case ReservationActionTypes.MY_RESERVATION_SPA_BOOKING_FAILED:
            return {...state,reservationLoaded:false,reservationLoading:false,reservationErrorMessages:action.payload};

        case ReservationActionTypes.MY_RESERVATION_COMMON_ERR_MSG:
            return {...state,reservationErrorMessages:action.payload};

        case ReservationActionTypes.MY_RESERVATION_RESET_ITINERARY_RESV:
            return {...state,shoreExReservationDetails:{},marqueeReservationDetails:{},diningReservationDetails:{},spaReservationDetails:{},reservationErrorMessages:null,reservationCounter:0,reservationLoaded:false,reservationLoading:false};

        case ReservationActionTypes.RESET_STORE:
            state=initialiState;
            return state;

        default:
            return state;
    }
}
export const getBookingEntities = (state: State) => state.entity;
export const getBookingErrorMsg = (state: State) => state.errorMessage;
export const getBookingErrorMsgCode = (state: State) => state.errorMessageCode;
export const getBookingIsLoaded = (state: State) => state.loaded;
export const getBookingIsLoading = (state: State) => state.loading;

export const getCurrentBookingId = (state: State) => state.currentBookingId;

export const getBookingDetailsEnitity = (state: State) => state.bookingDetails;
export const getbookingTotalCount = (state: State) => state.bookingTotalCount;
export const getBookingDetails = (state: State) => state.bookingDetails;
export const getBookingDetailsErrorMsg = (state: State) => state.bookingDetailsErrorMsg;
export const getBookingDetailsErrorMsgCode = (state: State) => state.bookingDetailsErrorMsgCode;
export const getBookingDetailsIsLoaded = (state: State) => state.bookingDetailsloaded;
export const getBookingDetailsIsLoading = (state: State) => state.bookingDetailsloading;

export const getGuestDetailsByVoyage = (state: State) => state.bookingDetails;

export const getShoreExDetailsByPort = (state: State) => state.shoreExReservationDetails;
export const getMarqueeDetailsByPort = (state: State) => state.marqueeReservationDetails;
export const getDiningDetailsByPort = (state: State) => state.diningReservationDetails;
export const getSpaDetailsByPort = (state: State) => state.spaReservationDetails;
export const getreservationErrorMessages = (state: State) => state.reservationErrorMessages;
export const getReservationCounter = (state: State) => state.reservationCounter;
export const getTotalBookingCount = (state: State) => state.totalcount;

