<div id="rcyc-page-channels" class="bodyContentOuter">
  <div class="content-wrapper">

    <!-- Section - 1 -->
    <app-rcyc-hero-module *ngIf="!mobileView && apiResponseBStatus  && responseArray.length>0" [responseArray]="responseArray">
    </app-rcyc-hero-module>
      <app-rcyc-ngu-carousel *ngIf=" mobileView && apiResponseBStatus  && responseArray.length>0"  [portData]='carouselData'></app-rcyc-ngu-carousel>
    <!-- Section - 2 -->
    <section class="section-2 mainRowSection">
      <div class="l-container-wrapper" *ngIf="apiResponseCStatus">
        <div class="l-container">
          <div class="headline-block">            
            <div *ngIf="apiResponseC?.field_sub_header" class="headline pageSubTitle" id="mainTitleId"
              [title]="apiResponseC?.field_sub_header | convertHtml"
              [innerHtml]="apiResponseC?.field_sub_header | limitTo:50"></div>
            <h1 *ngIf="apiResponseC?.title" id="mainSubtitleId" [title]="apiResponseC?.title | convertHtml"
            class="pageTitle" [innerHtml]="apiResponseC?.title | limitTo:50"></h1>
          </div>
          <div class="body-copy chanel_Descript">
            <p *ngIf="apiResponseC?.field_summery" id="mainDescriptionId" [innerHTML]="apiResponseC?.field_summery"></p>
          </div>
        </div>
      </div>
    </section>

    <!-- Section - 3 -->
    <section class="section-3 mainRowSection">
      <div class="content-wrapper">

        <!-- <div data-component="c04.tabs" data-variation="C" class="c04_C_2  c04_tabs c04_C" data-content="properties.half-moon-bay" data-blocks-uuid="c2d455a5-6355-4076-b6d2-1f06aa1c29e7">
					<div class="l-container-wrapper tabs-wrapper tabs-wrapper--full-page">
						<div class="l-container row is-12">
							Tab options for mobile
							<div class="tab-select-wrapper h-ML"><label class="h-ML" for="tab-select">You are viewing:</label>

									<select name="tab" #tabselect class="tab-select h-ML" id="mobtabselect" (change)="changedeckTabClass(tabselect.value, 'index')" >
											<option *ngFor="let deckObj of deckTabObject ; let i = index" value="{{ i }}" id="mobtabsel_{{i}}">{{ deckObj.name }}</option>

									</select>
								<span class="icon-arrow-down"></span>
							</div>
							 tab options for wen
							<ul class="tabs v-ML">
									<li *ngFor="let deckObj of deckTabObject ; let i = index" class="tab tabind{{i}}" id="tabindex_{{i}}">
											<a (click)="changedeckTabClass(deckObj, null)" class="{{ deckObj.class }}" id="taba_{{i}}" >{{ deckObj.name }}</a>
								 </li>
							</ul>
						</div>
					</div>
				</div> -->

        <div class="l-container-wrapper switcher tab-page">
          <div class="l-container row is-12">

            <!--  Onboard -->
            <div id="rcyc-tab-onboard" class="{{ deckTabObject[0].contentClass }}" *ngIf="apiResponseDStatus">
              <div class="l-container row is-12 -narrow-content-well">
                <div class="c00">
                  <!-- <h1 class="page-title">Discover A Better Stay On The Club Level</h1> -->
                  <div class="c07_media-object c07_D">
                    <div *ngFor="let itemOuter of apiResponseDNew;let iOuter =index" class="feature-blocks has-3 has-gutters flex-grid">

                        <div class="feature-block flex-item eachOuter" *ngFor="let item of itemOuter ; let i =index"
                          [ngClass]="{'lastRowItem': (i+1)%3 == 0, 'clearRow': (i+1)%3 == 1, 'evenRow': (i+1)%2 == 0}">

                          <app-rcyc-ngu-carousel class="videotypeParent feature-block__image p04_img videoTypeImg" *ngIf="item?.field_video"  [portData]='[item]'></app-rcyc-ngu-carousel>
                          
                          <div *ngIf="!item?.field_video">
                            <a *ngIf="item?.field_url_key || item?.field_group_url_key" id="learnMoreIdImg_{{i+1}}"
                              routerLink="{{ item?.channelUrl }}" title="Learn more" class="feature-block__cta "
                              (click)="clearVoyageDetails()" (keyup.enter)="clearVoyageDetails()">
                              <img id="mainEachImgId_{{i+1}}" [title]="item?.field_image_title_content | convertHtml"
                                [alt]="item?.field_image_alt_content | convertHtml"
                                [src]="item?.field_image"
                                class="feature-block__image p04_img imageTypeImg">
                            </a>
                          </div>
                          <h3 id="mainEachTitleId_{{i+1}}" class="media-object__headline media-object__headline--large"
                            [title]="item?.title | convertHtml" [innerHtml]="item?.title| limitTo:30"></h3>
                          <div>
                            <p *ngIf="currentUrlKey == 'cruise-ports'" id="mainEachDescriptionId_{{i+1}}"
                              class="feature-block__body" [innerHTML]="item?.body"></p>
                            <p *ngIf="currentUrlKey == 'yachts/evrima' ||
                                      currentUrlKey == 'yachts/ilma' ||
                                      currentUrlKey == 'yachts/luminara' ||
                                      currentUrlKey == 'presailing' ||
                                      currentUrlKey == 'videos'" id="mainEachDescriptionId_{{i+1}}"
                              class="feature-block__body" [innerHTML]="item?.field_summery"></p>
                            <!-- <p *ngIf="currentUrlKey == 'presailing'" id="mainEachDescriptionId_{{i+1}}"
                              class="feature-block__body" [innerHTML]="item?.field_summery"></p>
                            <p *ngIf="currentUrlKey == 'videos'" id="mainEachDescriptionId_{{i+1}}"
                              class="feature-block__body" [innerHTML]="item?.field_summery"></p> -->
                          </div>
                          <p class="feature-block__cta-wrapper">
                            <a *ngIf="item?.field_url_key || item?.field_group_url_key" id="learnMoreId_{{i+1}}"
                              routerLink="{{ item?.channelUrl }}" title="Learn more"
                              class="feature-block__cta secondary-cta id" (click)="clearVoyageDetails()"
                              (keyup.enter)="clearVoyageDetails()">
                              Learn More
                            </a>
                          </p>                         
                        </div>
                        <div *ngIf="iOuter==0&&apiResponseC?.field_summary_2" class="row extraBlock">
                          <p id="mainDescriptionId_{{iOuter}}" [innerHTML]="apiResponseC?.field_summary_2"></p>
                        </div>
                        <div *ngIf="iOuter==1&&apiResponseC?.field_summary_3" class="row extraBlock">
                          <p id="mainDescriptionId_{{iOuter}}" [innerHTML]="apiResponseC?.field_summary_3"></p>
                        </div>
                        <!-- <div *ngIf="iOuter==2" class="row extraBlock">
                          <p *ngIf="apiResponseC?.field_summery" id="mainDescriptionId_{{iOuter}}" [innerHTML]="apiResponseC?.field_summery"></p>
                        </div> -->

                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- end Onboard -->

            <!-- Ashore -->
            <!-- <div class="{{ deckTabObject[1].contentClass }}" >
								<div class="l-container row is-12 -narrow-content-well">
										<div class="c00">
											<div class="c07_media-object c07_D">
												<div class="feature-blocks has-3 image_content_section has-gutters flex-grid">

														<div class="feature-block flex-item" *ngFor="let tabcontE of apiResponseE ; let i =index">
																<img id="img_{{deckTabObject[1].name}}_{{i}}" src="{{basepath}}{{tabcontE.field_image}}" class="feature-block__image  tabimg_{{deckTabObject[1].name}}_{{i}}">
																<h3 class="media-object__headline media-object__headline--large tabHead{{deckTabObject[1].name}}_{{i}}" id="head_{{deckTabObject[1].name}}_{{i}}">{{tabcontE.title | limitTo:30}}</h3>
																<p class="feature-block__body descrpt_{{deckTabObject[1].name}}_{{i}}" id="descrpt_{{deckTabObject[1].name}}_{{i}}" [innerHTML]="tabcontE.field_summery | limitTo:130"></p>
																<p class="feature-block__cta-wrapper"><a id="more_{{deckTabObject[1].name}}_{{i}}" class="feature-block__cta secondary-cta id more_{{deckTabObject[1].name}}_{{i}}">Learn More</a></p>
															</div>


												</div>
											</div>

										</div>
									</div>
						</div> -->
            <!-- end Ashore -->

          </div>
        </div>
      </div>
    </section>

  </div>
</div>
