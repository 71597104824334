
import { UrlSegment, UrlSegmentGroup, UrlMatchResult, Route } from '@angular/router';

let urlMatchArray: any[] = [];

 /* ========================================================================================================================================
        1st
 ======================================================================================================================================== */
/*export function ComplexUrlMatchers1(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {
    var paramNames = 'luxury-cruises/x-to-x-x';
    var regexs= new RegExp('^luxury-cruises\/[a-zA-Z-]+-to-[a-zA-Z-]+-[0-9-]+$');
    if(urlMatchArray.length) {
        urlMatchArray=[];
    }
    urlMatchArray.push({paramName:paramNames,regex:regexs,segment:segments,segmentGroups:segmentGroup,routes:route});
    return urlMatch(urlMatchArray);

}*/


/* =========================================================================================================================================
        2
 ======================================================================================================================================== */
/*export function ComplexUrlMatchers2(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {

    var paramNames = 'luxury-cruises/x-to-x-x/x';
    var regexs= new RegExp('^luxury-cruises\/[a-zA-Z-]+-to-[a-zA-Z-]+-[0-9-]+\/[a-zA-Z-]+$');
    if(urlMatchArray.length) {
        urlMatchArray=[];
    }
    urlMatchArray.push({paramName:paramNames,regex:regexs,segment:segments,segmentGroups:segmentGroup,routes:route});
    return urlMatch(urlMatchArray);
}*/


 /* ========================================================================================================================================
        1
 ======================================================================================================================================== */
export function ComplexUrlMatchers1(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {

    const paramNames = 'luxury-x-cruises/x-to-x-x';
    const regexs = new RegExp('^luxury-[a-z-]+-cruises\/[a-zA-Z-]+-to-[a-zA-Z-]+-[0-9a-zA-Z-]+$');
    if (urlMatchArray.length) {
        urlMatchArray = [];
    }
    urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
    return urlMatch(urlMatchArray);

}


 /* ========================================================================================================================================
        2
 ======================================================================================================================================== */
export function ComplexUrlMatchers2(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,

                           route: Route): UrlMatchResult {

    const paramNames = 'luxury-x-cruises/x-to-x-x/x';
    const regexs = new RegExp('^luxury-[a-z-]+-cruises\/[a-zA-Z-]+-to-[a-zA-Z-]+-[0-9a-zA-Z-]+\/[a-zA-Z-]+$');
    if (urlMatchArray.length) {
        urlMatchArray = [];
    }
    urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
    return urlMatch(urlMatchArray);

}


/* =========================================================================================================================================
        3
 ======================================================================================================================================== */
export function ComplexUrlMatchers3(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {

    const paramNames = 'luxury-x-cruises';
    const regexs = new RegExp('^luxury-[a-z-]+-cruises$');
    if (urlMatchArray.length) {
        urlMatchArray = [];
    }
    urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
    return urlMatch(urlMatchArray);
}


/* =========================================================================================================================================
        4
 ======================================================================================================================================== */
export function ComplexUrlMatchers4(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {

    const paramNames = 'luxury-x-cruises/cruises-from-x-x';
    const regexs = new RegExp('^luxury-[a-z-]+-cruises\/cruises-from-[a-z-]+-[a-z-]+$');
    if (urlMatchArray.length) {
        urlMatchArray = [];
    }
    urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
    return urlMatch(urlMatchArray);
}


/* =========================================================================================================================================
        5
 ======================================================================================================================================== */
export function ComplexUrlMatchers5(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {

    const paramNames = 'luxury-x-cruises/cruises-from-x-x/x';
    const regexs = new RegExp('^luxury-[a-z-]+-cruises\/cruises-from-[a-z-]+-[a-zA-Z-]+\/[a-z-]+$');
    if (urlMatchArray.length) {
        urlMatchArray = [];
    }
    urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
    return urlMatch(urlMatchArray);
}


/* =========================================================================================================================================
        6
 ======================================================================================================================================== */
export function ComplexUrlMatchers6(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {

    const paramNames = 'yachts/evrima/suites/porttabs';
    const regexs = new RegExp('yachts\/evrima\/suites\/[a-z-]+$');
    if (urlMatchArray.length) {
        urlMatchArray = [];
    }
    urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
    return urlMatch(urlMatchArray);
}


/* =========================================================================================================================================
        7
 ======================================================================================================================================== */
export function ComplexUrlMatchers7(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {

    const paramNames = 'offer-x-program';
    const regexs = new RegExp('^offer-[a-z0-9-]+-program$');
    if (urlMatchArray.length) {
        urlMatchArray = [];
    }
    urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
    return urlMatch(urlMatchArray);
}

/* =========================================================================================================================================
        8
 ======================================================================================================================================== */
export function ComplexUrlMatchers8(segments: Array<UrlSegment>,
                           segmentGroup: UrlSegmentGroup,
                           route: Route): UrlMatchResult {

    const paramNames = 'ebrochure-x';
    const regexs = new RegExp('^ebrochure-[a-z0-9-]+$');
    if (urlMatchArray.length) {
        urlMatchArray = [];
    }
    urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
    return urlMatch(urlMatchArray);
}

/* =========================================================================================================================================
        9
 ======================================================================================================================================== */
//  export function ComplexUrlMatchers9(segments: Array<UrlSegment>,
//     segmentGroup: UrlSegmentGroup,
//     route: Route): UrlMatchResult {

// 		const paramNames = 'zmag-x';
// 		const regexs = new RegExp('^zmag-[a-z0-9-]+$');
// 		if (urlMatchArray.length) {
// 			urlMatchArray = [];
// 		}
// 		urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
// 		return urlMatch(urlMatchArray);
// }
 
/* =========================================================================================================================================
        10
 ======================================================================================================================================== */
 	export function ComplexUrlMatchers10(segments: Array<UrlSegment>,
		segmentGroup: UrlSegmentGroup,
		route: Route): UrlMatchResult {

			const paramNames = 'luxury-x-cruises/:tabletype';
			const regexs = new RegExp('^luxury-[a-z-]+-cruises+\/[a-z]+$');
			if (urlMatchArray.length) {
			urlMatchArray = [];
			}
			urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
			return urlMatch(urlMatchArray);
	}
/* =========================================================================================================================================
        11
 ======================================================================================================================================== */
 	export function ComplexUrlMatchers11(segments: Array<UrlSegment>,
		segmentGroup: UrlSegmentGroup,
		route: Route): UrlMatchResult {

		const paramNames = 'offer-x-program/:tabletype';
		const regexs = new RegExp('^offer-[a-z0-9-]+-program+\/[a-z]+$');
		if (urlMatchArray.length) {
		urlMatchArray = [];
		}
		urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
		return urlMatch(urlMatchArray);
	}

	/* =========================================================================================================================================
        12
 ======================================================================================================================================== */
export function ComplexUrlMatchers_Port3(segments: Array<UrlSegment>,
	segmentGroup: UrlSegmentGroup,
	route: Route): UrlMatchResult {

	const paramNames = 'luxury-x-cruises/cruises-from-x-x/x/x';
	const regexs = new RegExp('^luxury-[a-z-]+-cruises\/cruises-from-[a-z-]+-[a-zA-Z-]+\/[a-z-]+\/[a-z-]+$');
	if (urlMatchArray.length) {
	urlMatchArray = [];
	}
	urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
	return urlMatch(urlMatchArray);
}
/* ===========================================================
        13
 ============================================================= */
// export function xEbrochureUrlMatcher(segments: Array<UrlSegment>,
//     segmentGroup: UrlSegmentGroup,
//     route: Route): UrlMatchResult {

//         console.log(route, 'AA');
//         console.log(segmentGroup, 'BB');
//         console.log(segments, 'CC');
// const newSegments=segments[segments.length-1];
// const paramNames = 'x-brochure';
// const regexs = new RegExp('^[a-z0-9-]+-brochure$');
// if (urlMatchArray.length) {
// urlMatchArray = [];
// }
// urlMatchArray.push({paramName: paramNames, regex: regexs, segment: segments, segmentGroups: segmentGroup, routes: route});
// return urlMatch(urlMatchArray);
// }


/* =========================================================================================================================================
    Function to Match
 ======================================================================================================================================== */
function urlMatch(urlArray) {

    const paramName = urlArray[0].paramName;
    const regex = urlArray[0].regex;
    const segmentss = urlArray[0].segment;
    const segmentGroups = urlArray[0].segmentGroups;
    const routes = urlArray[0].routes;
    const parts = []; let url = '';
    parts[0] = regex;
    const posParams: { [key: string]: UrlSegment } = {};
    const consumed = [];
    let currentIndex = 0;

    for (let i = 0; i < parts.length; ++i) {

        if (currentIndex >= segmentss.length) {

            return null;
        }
        const current = segmentss[currentIndex];
        if (segmentss.length > 1) {

            for (let j = 0; j < segmentss.length; j++) {
                if (url) {

                    url = url + '/' + segmentss[j].path;
                } else {

                    url = url + segmentss[j].path;
                }
                if (j + 1 === segmentss.length) {

                    const part = parts[i];

                    if (!part.test(url)) {

                        return null;
                    }

                    const segmentsArray = [];
                    for (let k = 0; k < segmentss.length; k++) {

                        segmentsArray.push({ path: segmentss[k].path, parameters: {} });
                        posParams[paramName] = segmentsArray[k];
                        consumed.push(segmentsArray[k]);
                    }
                }
            }
        } else {

            const part = parts[i];
            if (!part.test(current.path)) {
                return null;
            }
            posParams[paramName] = current;
            consumed.push(current);
            currentIndex++;
        }
    }

    if (routes.pathMatch === 'full' && (segmentGroups.hasChildren() || currentIndex < segmentss.length)) {

        return null;
    }

    return { consumed, posParams };
}
