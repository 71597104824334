<div class="lg:flex flex-col max-w-[450px] m-auto p-auto px-0 lg:px-12" *ngIf="data">
  <div class="">
    <div class="flex flex-col [5/4] w-auto lg:max-w-[450px] mb-6 lg:mb-0">
      <img alt="" class="" [src]="data?.field_image">
    </div>
  </div>

  <div class="lg:text-center lg:items-center lg:pt-8 lg:flex lg:flex-grow lg:flex-col">
    <div class="text-sm uppercase mb-2" [innerHTML]="data?.field_sub_header">
    </div>
    <h2 class="text-[23px] lg:text-[31px] leading-[26px] lg:leading-normal"
      [ngClass]="{'lg:min-h-[130px] lg:h-[130px] lg:overflow-hidden lg:mb-1': variant === 'offers-programs-landing', 'lg:mb-2 xl:mb-6': variant !== 'offers-programs-landing'}"
      [innerHTML]="data?.title">
    </h2>
    <div class="text-content lg:mb-9 flex flex-col xl:flex-row gap-3 xl:gap-11 lg:mb-10"
      [innerHTML]="data?.field_summery">
    </div>
    <div *ngIf="data?.field_com_cta_title && data?.field_url_key" class="">
      <a (click)="clearCompareValue(data?.field_url_key)" (keyup.enter)="clearCompareValue(data?.field_url_key)"
        class="link-cta button-secondary uppercase" [innerHTML]="data?.field_com_cta_title">
      </a>
    </div>
  </div>
</div>