<swal #viewBrocSwal (beforeOpen)="beforeOpen($event)" (open)="onOpen($event)" [showConfirmButton]="false"
  [showCancelButton]="false" [showCloseButton]="true" customClass="viewBrocSwtOuter" [allowOutsideClick]="false"
  [animation]="false" (close)="onClose($event)" [reverseButtons]="true">
  <ng-container *swalPartial>
    <div>
      <!-- left side -->
      <div>
        <span class="text-[14px] uppercase">View Brochure</span>
        <div class="lg:flex justify-between mb-5 mt-6">
          <h1 class="font-[400] text-[29px] lg:text-[49px] !font-caslon lg:w-[600px] lg:w-1/2"
            [innerHTML]="voyageRegion.title"></h1>
          <div class="lg:w-[504px] lg:w-1/2" [innerHTML]="drupalHeaderData?.body"> </div>
        </div>
        <div id="formSubmitErrorId" *ngIf="quoteFormStatus">
          <span class="error">{{sessionExpiredMessages}}</span>
        </div>
        <form id="requestCallFormId" name="requestCallForm" [formGroup]="lead_gen_form" novalidate autocomplete="false">
          <fieldset>
            <div class="field-wrapper relative mb-[24px] rcyc-hide-honey">
              <input id="thirdNameId" class="basic-form_input w-full !mb-0" type="text" name="honey"
                placeholder="Third Name" maxlength="60" autocomplete='off'>
              <label for="thirdNameId"
                class="form-label absolute left-0 w-full !text-[#000] !font-[16px] !-top-[12px] !font-bold">Third Name
                <span class="text-[#ce4844] !font-[16px]">*</span></label>
            </div>
            <div class="basic-form_group !mb-0 lead_form_field lg:grid grid-cols-2 lg:gap-x-12">
              <div class="field-wrapper relative mb-[24px]">
                <input id="firstNameId" class="basic-form_input w-full !mb-0" type="text" formControlName="firstname"
                  placeholder="First Name">
                <ng-container>
                  <label for="firstNameId"
                    class="form-label absolute left-0 w-full !text-[#000] !font-[16px] !-top-[15px] !font-bold">First
                    Name <span class="text-[#ce4844] !font-[16px]">*</span></label>
                </ng-container>
                <p class="errorptag"
                  *ngIf="lead_gen_form.controls.firstname.invalid && (lead_gen_form.controls.firstname.dirty || lead_gen_form.controls.firstname.touched)">
                  <span *ngIf="lead_gen_form.controls.firstname.errors.required" class="error">This field is
                    required</span>
                  <span
                    *ngIf="!lead_gen_form.controls.firstname.errors.required&&lead_gen_form.controls.firstname.errors.pattern"
                    class="error">Please enter a valid first name </span>
                </p>
              </div>
              <div class="field-wrapper relative mb-[24px]">
                <input id="lastNameId" class="basic-form_input w-full !mb-0" type="text" formControlName="lastname"
                  placeholder="Last Name">
                <ng-container>
                  <label for="lastNameId"
                    class="form-label absolute left-0 !text-[#000] !font-[16px] !-top-[15px] !font-bold">Last Name <span
                      class="text-[#ce4844] !font-[16px]">*</span></label>
                </ng-container>
                <p class="errorptag"
                  *ngIf="lead_gen_form.controls.lastname.invalid && (lead_gen_form.controls.lastname.dirty || lead_gen_form.controls.lastname.touched)">
                  <span *ngIf="lead_gen_form.controls.lastname.errors.required" class="error">This field is
                    required</span>
                  <span
                    *ngIf="!lead_gen_form.controls.lastname.errors.required&&lead_gen_form.controls.lastname.errors.pattern"
                    class="error">Please enter a valid last name </span>
                </p>
              </div>
              <ng-container *ngIf="pageName === 'charters'">
                <div class="field-wrapper relative mb-[24px]">
                  <input id="company" class="basic-form_input w-full !mb-0" type="text" formControlName="companyname"
                    placeholder="Company Name" size="20">
                  <label for="company"
                    class="form-label absolute left-0 !text-[#000] !font-[16px] !-top-[15px] !font-bold">Company Name
                    <span class="text-[#ce4844] !font-[16px]">*</span></label>
                  <p class="errorptag"
                    *ngIf="lead_gen_form.controls.companyname.invalid && (lead_gen_form.controls.companyname.dirty || lead_gen_form.controls.companyname.touched)">
                    <span *ngIf="lead_gen_form.controls.companyname.errors.required" class="error">This field is
                      required</span>
                    <span
                      *ngIf="!lead_gen_form.controls.companyname.errors.required&&lead_gen_form.controls.companyname.errors.pattern"
                      class="error">Please enter a valid company name </span>
                  </p>
                </div>
              </ng-container>
              <div class="field-wrapper relative mb-[24px]">
                <select id="selectCountryId" class="basic-form_input w-full !mb-0" formControlName="country"
                  (change)="isChanged(); onChange($event)">
                  <option id="selectCountryId_0" value="">Country / Region</option>
                  <option *ngFor="let county of countryListArray;let i=index" id="countryOptionId_{{i+1}}"
                    value="{{county.code}}">{{county.name}}</option>
                </select>
                <ng-container>
                  <label for="selectCountryId"
                    class="form-label absolute left-0 !text-[#000] !font-[16px] !-top-[15px] !font-bold">Country /
                    Region <span class="text-[#ce4844] !font-[16px]">*</span></label>
                </ng-container>
                <span
                  *ngIf="lead_gen_form.controls.country.invalid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) && lead_gen_form.controls.country.errors.required"
                  class="error">This field is required</span>
              </div>
              <div class="field-wrapper relative mb-[24px]">
                <ng-container *ngIf="isMobileScreen === false">
                  <input id="zipCodeId" class="basic-form_input w-full !mb-0" type="text" formControlName="zipcode"
                    placeholder="ZIP/Postal Code (Required for US & Canada)" [maxlength]="zipcodeMaxLength"
                    (input)="checkZipcodeFormat($event)">
                </ng-container>
                <ng-container *ngIf="isMobileScreen === true">
                  <textarea id="zipCodeId" class="basic-form_input w-full !mb-0 zip-textarea" formControlName="zipcode"
                    placeholder="ZIP/Postal Code (Required for US & &#10;Canada)" [maxlength]="zipcodeMaxLength"
                    (input)="checkZipcodeFormat($event)"></textarea>
                </ng-container>
                <ng-container *ngIf="isSmallMob === false">
                  <label for="zipCodeId"
                    class="form-label absolute !text-[#000] !font-[16px] !-top-[15px] !font-bold">ZIP/Postal Code
                    (Required for US & Canada)<span *ngIf="zipcodeReqStatusDynamic"
                      class="text-[#ce4844] !font-[16px] !pl-1">*</span></label>
                </ng-container>
                <ng-container *ngIf="isSmallMob === true">
                  <label for="zipCodeId"
                    class="form-label absolute !text-[#000] !font-[16px] !-top-[25px] !font-bold">ZIP/Postal Code
                    (Required for US & Canada)<span *ngIf="zipcodeReqStatusDynamic"
                      class="text-[#ce4844] !font-[16px] !pl-1">*</span></label>
                </ng-container>
                <p class="errorptag"
                  *ngIf="lead_gen_form.controls.zipcode.invalid && (lead_gen_form.controls.zipcode.dirty || lead_gen_form.controls.zipcode.touched) ">
                  <span *ngIf="lead_gen_form.controls.zipcode.errors.required" class="error">This field is
                    required</span>
                  <span
                    *ngIf="!lead_gen_form.controls.zipcode.errors.required&&lead_gen_form.controls.zipcode.errors.pattern"
                    class="error">ZIP/Postal Code is not valid </span>
                </p>
              </div>
              <div class="field-wrapper relative mb-[24px]">
                <input id="emailId" class="basic-form_input w-full !mb-0" type="email" formControlName="email"
                  placeholder="Email Address">
                <ng-container>
                  <label for="emailId"
                    class="form-label absolute !text-[#000] !font-[16px] !-top-[15px] !font-bold">Email Address <span
                      class="text-[#ce4844] !font-[16px]">*</span></label>
                </ng-container>
                <p class="errorptag"
                  *ngIf="lead_gen_form.controls.email.invalid && (lead_gen_form.controls.email.dirty || lead_gen_form.controls.email.touched)">
                  <span *ngIf="lead_gen_form.controls.email.errors.required" class="error">This field is required</span>
                  <span
                    *ngIf="!lead_gen_form.controls.email.errors.required&&lead_gen_form.controls.email.errors.pattern"
                    class="error">
                    <!-- Please enter a valid email address (Example: johndoe@example.com) -->
                    Enter a valid email address (e.g. name@example.com)
                  </span>
                </p>
              </div>
              <div class="field-wrapper relative mb-[24px]">
                <div class="row">
                  <div class="column">

                    <div id="phoneFlagContainerId" class="phoneFlagContainer !mb-0"
                      (clickOutside)="onClickedOutside($event)">
                      <ng-container>
                        <label for="phoneId"
                          class="form-label absolute !text-[#000] !font-[16px] !-top-[15px] !font-bold">Phone Number
                          <span *ngIf="phNumReqStatusDynamic" class="text-[#ce4844] !font-[16px]">*</span></label>
                      </ng-container>
                      <div class="row is-3-9">
                        <div class="column is-3 phoneFlagOuter"
                          (keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
                          (click)="flagCodeClickStatus = !flagCodeClickStatus" tabindex='0'>
                          <span class="flagOuter"><img [src]="userCountryDetails?.flagUrl" class="flagImg" /></span>
                          <span class="countryCodeOuter"> {{userCountryDetails?.dial_code}}</span>
                        </div>

                        <!-- Country List -->
                        <div *ngIf="flagCodeClickStatus" class="countryResultOuter">
                          <div *ngIf="flagCodeClickStatus" class="countryResultInner">
                            <!-- <input type="text" [(ngModel)]="countrySearch" (ngModelChange)="countrySearchChanged($event)" (ngModelChange)="searchCountryChange()"> -->
                            <div class="countrySearchOuter">
                              <input type="text" [(ngModel)]="countrySearch" [ngModelOptions]="{standalone: true}" />
                            </div>
                            <ul *ngIf="phoneDataArray.length" class="countrySearchListOuter">
                              <li
                                *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
                                (keyup.enter)="selectCountryClick(item)" (click)="selectCountryClick(item)" [ngStyle]="{'border-bottom':
                                  !countrySearch &&
                                  (
                                    ( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
                                    ( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )
                                  )
                                  ? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
                                (focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
                                <span [innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
                                ({{item?.dial_code}})
                              </li>
                            </ul>
                            <p class="noResultsFound letterSpacing"
                              *ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length"> No results
                              match "{{countrySearch}}"</p>
                          </div>
                        </div>
                        <!-- Country List -->

                        <div class="column is-9 phoneNumberInputOuter">
                          <!-- <input type="text" [(ngModel)]="phoneNumber" class="phoneNumberInput" /> -->
                          <!-- //[textMask]="{mask: mask}" (focusout)="phoneTBFocusout()" -->
                          <input id="phoneId" class="basic-form_input phoneNumberInput w-full !mb-0"
                            *ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone"
                            placeholder="Phone Number"
                            [maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
                            [textMask]="{mask: mask, guide:false}" (input)="phoneValueChangeFn()" />
                        </div>

                      </div>
                      <p class="errorptag"
                        *ngIf="lead_gen_form.controls.phone.invalid && (lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched)">
                        <span *ngIf="lead_gen_form.controls.phone.errors?.required" class="phoneNoError error">This
                          field
                          is
                          required</span>
                        <span
                          *ngIf="!lead_gen_form.controls.phone.errors?.required&&lead_gen_form.controls.phone.errors?.phoneIncorrect"
                          class="phoneNoError error">Enter a valid phone number (e.g. {{
                          userCountryDetails?.defaultPhoneNumber }}) </span>
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="showPhoneopt">
              <div *ngIf="apiResponseCStatus && !showTermUS"
                class="termsSection lg:grid grid-cols-2 lg:gap-x-12 w-full">
                <div *ngIf="apiResponseC?.field_term1" class="eachTermSection flex">
                  <input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail" class="input-align"
                    aria-labelledby="emailMarketing">
                  <p class="termContent" id="emailMarketing" [innerHtml]="apiResponseC?.field_term1"></p>
                </div>
                <div *ngIf="apiResponseC?.field_term2" class="eachTermSection">
                  <div class="flex">
                    <input id="phoneOptId" type="checkbox" value="true" formControlName="isAgreeToContact"
                      class="input-align" aria-labelledby="phoneMarketing" (change)="phoneOptValueChanged()">
                    <p class="termContent" *ngIf="!showPhoneOptRequiredText" id="phoneMarketing"
                      [innerHtml]="apiResponseC?.field_term2"></p>
                    <p class="termContent" *ngIf="showPhoneOptRequiredText" id="phoneMarketing"
                      [innerHtml]="apiResponseC?.field_term2_required"></p>
                  </div>
                  <p class="errorptag marketingError" *ngIf="showPhoneOptRequiredText">
                    <span class="emailError error ml-[35px]"
                      [innerHtml]="drupalErrorMessages?.field_phone_opt_in_required"></span>
                  </p>
                </div>
              </div>
            </ng-container>
          </fieldset>
        </form>
        <div class="text-[13px] mb-6">
          <span class="text-[#ce4844]">*</span> Indicates required field
        </div>
        <div class="mb-6">
          <div id="terms" class="text-[13px]" *ngIf="apiResponseCStatus && apiResponseC?.field_summery && !showTermUS"
            [innerHtml]="apiResponseC?.field_summery"></div>
          <div id="terms" class="text-[13px]" *ngIf="apiResponseCStatus && apiResponseC?.field_summery && showTermUS"
            [innerHtml]="apiResponseC?.field_summary_us"></div>
        </div>
        <div class="flex flex-col lg:flex-row">
          <div *ngIf="submitLoading" class="row is-12 btnLoaderOuter">
            <div class="btnLoader">
              <img src="assets/components/images/loading.gif" class="formLoader" alt="">
            </div>
          </div>
          <div *ngIf="!submitLoading" class="">
            <input type="submit" id="requestQuoteSubmitId" (click)="leadGenSubmit()" [disabled]="lead_gen_form?.controls?.firstname?.invalid ||
             lead_gen_form?.controls?.lastname?.invalid ||
             lead_gen_form?.controls?.country?.invalid ||
             lead_gen_form?.controls?.email?.invalid ||
             lead_gen_form?.controls?.zipcode?.invalid ||
             (pageName === 'charters' && lead_gen_form?.controls?.companyname?.invalid) ||
             lead_gen_form?.controls?.phone?.errors?.required ||
             lead_gen_form.controls?.phone?.errors?.phoneIncorrect==true ||
             !userArea ||
             showPhoneOptRequiredText ||
             preferredVoyageLimitExceed" class="button-primary lg:min-w-[200px]" value="Submit">
          </div>
          <input type="reset" class="button-tertiary lg:w-auto lg:min-w-[130px]" id="requestQuoteResetId"
            (click)="ngOnInit(); clearCached()" value="Clear All" [ngClass]="{'disabledCursor': submitLoading}"
            [disabled]="submitLoading">
          <ng-container *ngIf="submitSuccessful">
            <div class="text-success flex gap-2 tracking-wide items-center w-full"><img src="assets/img/success.svg"
                alt="">Your
              request has been sent successfully</div>
          </ng-container>
        </div>
      </div>
      <!--      Success-->
    </div>
  </ng-container>
</swal>