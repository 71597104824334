import { Component, OnInit, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';

import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import * as moment from 'moment';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
declare var com: any;
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';
// declare var jQuery: any;
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-rcyc-simple-page',
  templateUrl: './rcyc-simple-page.component.html',
  styleUrls: ['./rcyc-simple-page.component.css'],
  // encapsulation: ViewEncapsulation.None,
})
export class RcycSimplePageComponent implements OnInit, AfterViewInit {

  currentUrlPath: any;
  currentUrlPath_1: any;
  currentUrlPathAsArray: any;

  apiUrlA: any;
  apiUrlCommon: any;
  apiUrlEBrochureKey: any;

  apiResponse_A: any;
  apiResponse_common: any;
  apiResponseEBrochureKey: any;

  apiResponse_ATemp: any = [];
  apiResponse_commonTemp: any = [];
  apiResponseEBrochureKeyTemp: any = [];

  apiResponse_common_status = false;
  apiResponse_A_status = false;
  apiResponseEBrochureKeyStatus = false;

  apiUrlMetatag: any;
  currentComponentName: any;

  eBrochureKey: any;
  eBrochureHeightTimeOut: any;
  completeSuccessQueryString1: string;
  snapshotUrl: any;
  apiResponseData: any;
  jobsData: any;
  fieldUrlKey: any;
  rcycCurrentPage = '';
  preContractFormView: any = false;
  confirmerName: any = '';
  bookingId: any = '';
  disableButton: boolean = true;
  bookingIdFlag: boolean = true;
  isContractType: boolean = false;
  subHeaderText: any;
  documentVersion: any;
  documentName: any;
  statusMessage: any;
  messageclassType: any;
  currentUrlPath_2: any;
  minIframeHeight: any;
  bookingIdReqValue: boolean = true;
  confirmerNameReqValue: boolean = true;
  apiResponseGError: any;
  apiResponseGSuccess: any;

  eBrochureSubscriptionCode: string;
  apiResponse_ebrochure_status = false;
  apiResponse_ebrochure: any = [];
  acknowledgeButton: boolean = false;
  euPreContractForm:  any;
  previousUrl: string;
  currentUrl: string;
  ebrochureDrupalUrl: string;
  urlCategory: string;

  constructor(

    private rcycCommonService: RcycCommonService,
    private router: Router,
    // private rcycSimplePageService: RcycSimplePageService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private rcycInternalApisService: RcycInternalApisService,
    private _SharedServiceService: SharedServiceService,
    private formBuilder: UntypedFormBuilder,
  ) {
    
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlPath = this.currentUrlPathAsArray[0].path;

    this.router.events.subscribe(event => {      
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;        
        localStorage.setItem('eBrochurePreviousUrl', event.url);
      };
    });

    clearTimeout(this.eBrochureHeightTimeOut);

    // Current subHeader Status
    this.rcycCommonService.changeSubHeaderStatus(true);

    this.currentComponentName = 'RcycSimplePageComponent';
    // change changeCurrentComponent
    this.rcycCommonService.changeCurrentComponent(this.currentComponentName);

    const ebrochureUrlPart=this.currentUrlPathAsArray[this.currentUrlPathAsArray.length-1].path;    
    if(ebrochureUrlPart.match('-brochure')) { 
      this.rcycSimplePageEBrochureContent();   
      this.rcycSimplePageDifferentEBrochure();      
    } else {
      this.pageApiUrlSettingFn();
    }
  }

  /* ================================================
   Function for setting api urls for different pages
 ================================================== */
  pageApiUrlSettingFn() {
    let switchKey;
    switch (this.currentUrlPath) {
     
      // LEGAL
      case 'legal':

        this.urlCategory = 'legal';
        if (this.currentUrlPathAsArray.length === 1) {

          this.apiUrlA = '/api/v1/legal/header/a?_format=json';
          this.apiUrlMetatag = '/api/v1/meta/legal?_format=json';
          this.apiUrlCommon = '/api/v1/legal/content/b?_format=json';

          this.rcycSimplePageContent();
        } else {

          this.currentUrlPath_1 = this.currentUrlPathAsArray[1].path;
          switchKey = this.currentUrlPath_1;
          switch (switchKey) {

            case 'terms-conditions':

              this.apiUrlA = '/api/v1/termsconditions/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/termsconditions?_format=json';
              this.apiUrlCommon = '/api/v1/termsconditions/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'rcyc-terms-conditions-page';
              break;

            case 'eu-terms-conditions':

              this.apiUrlA = '/api/v1/termsconditionseu/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/termsconditionseu?_format=json';
              this.apiUrlCommon = '/api/v1/termsconditionseu/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'rcyc-terms-conditions-eu-page';
              break;

            case 'privacy-policy':

              this.apiUrlA = '/api/v1/privacypolicy/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/privacypolicy?_format=json';
              this.apiUrlCommon = '/api/v1/privacypolicy/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'rcyc-privacy-policy-page';
              break;

            case 'ticket-contract':

              this.apiUrlA = '/api/v1/ticketcontract/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/ticketcontract?_format=json';
              this.apiUrlCommon = '/api/v1/ticketcontract/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'rcyc-ticket-contract-page';
              break;

            case 'eu-privacy-policy':

              this.apiUrlA = '/api/v1/privacypolicyeu/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/privacypolicyeu?_format=json';
              this.apiUrlCommon = '/api/v1/privacypolicyeu/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'rcyc-privacy-policy-eu-page';
              break;

            case 'eu-ticket-contract':

              this.apiUrlA = '/api/v1/ticketcontracteu/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/ticketcontracteu?_format=json';
              this.apiUrlCommon = '/api/v1/ticketcontracteu/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'rcyc-ticket-contract-eu-page';
              break;

              case 'sms':

                this.apiUrlA = '/api/v1/sms/header/a?_format=json';
                this.apiUrlMetatag = '/api/v1/meta/sms?_format=json';
                this.apiUrlCommon = '/api/v1/sms/content/b?_format=json';
  
                this.rcycSimplePageContent();
                this.rcycCurrentPage = 'rcyc-sms-page';
                break;

            case 'documents':
             
              if (this.currentUrlPathAsArray.length == 2) {

                this.preContractFormView = false;
                this.apiUrlA = '/api/v1/documents/header/a?_format=json';
                this.apiUrlMetatag = '/api/v1/meta/documents?_format=json';
                this.apiUrlCommon = '/api/v1/documents/content/a?_format=json';
                this.rcycCurrentPage = 'rcyc-precontract-eu-page';
                this.isContractType = false;

              }
              // docType
              if (this.currentUrlPathAsArray.length == 3) {
                this.preContractFormView = true;
                this.isContractType = true;
                this.currentUrlPath_2 = this.currentUrlPathAsArray[2].path;
                this.apiUrlA = '/api/v1/' + this.currentUrlPath_2 + '/header/a?_format=json';
                this.apiUrlMetatag = '/api/v1/meta/' + this.currentUrlPath_2 + '?_format=json';
                this.apiUrlCommon = '/api/v1/' + this.currentUrlPath_2 + '/content/c?_format=json';
                this.rcycCurrentPage = 'rcyc-precontract-eu-page';
                this.rcycGetContractPageVersionApi(this.currentUrlPath_2);
                this.euPreContractFormFn();
              }
              this.rcycSimplePageContent();

              break;

            case 'booking-terms-conditions':

              this.apiUrlA = '/api/v1/bookingtermsconditions/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/bookingtermsconditions?_format=json';
              this.apiUrlCommon = '/api/v1/bookingtermsconditions/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'booking-terms-conditions';
              break;

            case 'booking-terms-conditions-eu':

              this.apiUrlA = '/api/v1/bookingtermsconditionseu/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/bookingtermsconditionseu?_format=json';
              this.apiUrlCommon = '/api/v1/bookingtermsconditionseu/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'booking-terms-conditions-eu';
              break;

          }
        }

        break;

      // PRESAILING
      case 'presailing':

        this.urlCategory = 'presailing';
        if (this.currentUrlPathAsArray.length === 2) {

          this.currentUrlPath_1 = this.currentUrlPathAsArray[1].path;
          switchKey = this.currentUrlPath_1;
          switch (switchKey) {

            case 'FAQ':

              this.apiUrlA = '/api/v1/faq/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/faq?_format=json';
              this.apiUrlCommon = '/api/v1/faq/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'rcyc-presailing-faq-page';
              break;

            case 'visas':

              this.apiUrlA = '/api/v1/visas/header/a?_format=json';
              this.apiUrlMetatag = '/api/v1/meta/visas?_format=json';
              this.apiUrlCommon = '/api/v1/visas/content/b?_format=json';

              this.rcycSimplePageContent();
              this.rcycCurrentPage = 'rcyc-presailing-visas-page';
              break;

            default:

              break;
          }
        }
        break;

      // DEFAULT
      default:
        
        this.urlCategory='default';
        this.ebrochureDrupalUrl='/api/v1/ebrochure/aboutus?_format=json';        
        switchKey = this.currentUrlPath;
        switch (switchKey) {

          case 'press':

            this.apiUrlA = '/api/v1/press/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/press?_format=json';
            this.apiUrlCommon = '/api/v1/press/content/b?_format=json';

            this.rcycSimplePageContent();
            break;

          case 'about':

            this.apiUrlA = '/api/v1/aboutus/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/aboutus?_format=json';
            this.apiUrlCommon = '/api/v1/aboutus/content/b?_format=json';
            this.rcycCurrentPage = 'rcyc-about-page';           

            this.rcycSimplePageContent();
            break;

          // case 'careers':

          //   this.apiUrlA = '/api/v1/careers/header/a?_format=json';
          //   this.apiUrlMetatag = '/api/v1/meta/careers?_format=json';
          //   this.apiUrlCommon = '/api/v1/careers/content/b?_format=json';

          //   this.rcycSimplePageContent();
          //   break;

          case 'corporate':

            this.apiUrlA = '/api/v1/corporate/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/corporate?_format=json';
            this.apiUrlCommon = '/api/v1/corporate/content/b?_format=json';

            this.rcycSimplePageContent();
            break;

          case 'awards':

            this.apiUrlA = '/api/v1/awards/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/awards?_format=json';
            this.apiUrlCommon = '/api/v1/awards/content/b?_format=json';

            this.rcycSimplePageContent();
            break;

          /*case 'charters':

            this.apiUrlA = '/api/v1/charters/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/charters?_format=json';
            this.apiUrlCommon = '/api/v1/charters/content/b?_format=json';

            this.rcycSimplePageContent();
            break;*/

          case 'travelagent':

            this.apiUrlA = '/api/v1/travelagents/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/travelagents?_format=json';
            this.apiUrlCommon = '/api/v1/travelagents/content/b?_format=json';

            this.rcycSimplePageContent();
            break;

          case 'pressroom':

            this.apiUrlA = '/api/v1/pressroom/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/pressroom?_format=json';
            this.apiUrlCommon = '/api/v1/pressroom/content/b?_format=json';

            this.rcycSimplePageContent();
            break;

          case 'loyalty-faqs':

            this.apiUrlA = '/api/v1/loyaltyfaqs/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/loyaltyfaqs?_format=json';
            this.apiUrlCommon = '/api/v1/loyaltyfaqs/content/b?_format=json';

            this.rcycSimplePageContent();
            break;
            
          case 'loyalty-terms-conditions':

            this.apiUrlA = '/api/v1/loyaltytermsconditions/header/a?_format=json';
            this.apiUrlMetatag = '/api/v1/meta/loyaltytermsconditions?_format=json';
            this.apiUrlCommon = '/api/v1/loyaltytermsconditions/content/b?_format=json';

            this.rcycSimplePageContent();
            break;

          default:            
            this.rcycSimplePageEBrochureContent();
            this.ebrochureDrupalUrl = '/api/v1/ebrochure?_format=json';
            this.getEbrochureFn();            
            break;
        }
        
        break;

    }
  }

  ngOnInit() {
    this.getErrorCommonMsgs();
    this.getSuccessCommonMsg();
    /* var script1 = document.createElement('script');
    script1.src = 'http://www.nxtbook.com/fx/scriptlib/swfobject-2.2/swfobject.js';
    document.getElementsByTagName('head')[0].appendChild(script1);

    var script2 = document.createElement('script');
    script2.src = 'http://www.nxtbook.com/fx/scriptlib/swfobject-2.2/swfmacmousewheel.js';
    document.getElementsByTagName('head')[0].appendChild(script2);

    var script3 = document.createElement('script');
    script3.src = 'http://www.nxtbook.com/fx/config/redirect/embed_js.php?
    rp=/nxtbooks/ritzcarltonyachtcollection/brochure_2018&w=800&h=600';
    document.getElementsByTagName('head')[0].appendChild(script3);*/
  }

  ngAfterViewInit() {

    this.afterViewInitFn();
  }

  /* ================================================
   Function for afterviewinit
 ================================================== */

  afterViewInitFn() {

    switch (this.eBrochureSubscriptionCode) {
      case 'zmags':
        break;
      default:
        this.eBrochureIframeHeightApply();
        // section-2 iframe
        if (document.querySelector('.section-2 iframe')) {
          document.querySelector('.section-2 iframe').setAttribute('style', 'width: 100%;');
        }
        break;
    }
  }
  /* ================================================
     Function to call all initial Simple page APIs
   ================================================== */

  rcycSimplePageContent() {

    this.rcycSimplePageMetaTagApi();
    this.rcycSimplePageAPositionApi();
    this.rcycSimplePageCommonApi();

  }

  /* =================================================
     Function to call all initial Simple page APIs
   ===================================================*/

  rcycSimplePageEBrochureContent() {

    // Current subHeader Status
    this.rcycCommonService.changeSubHeaderStatus(false);
    this.apiUrlA = '/api/v1/ebrochuresuccess/header/a?_format=json';
    this.apiUrlMetatag = '/api/v1/meta/ebrochuresuccess?_format=json';
    this.rcycSimplePageMetaTagApi();
    this.rcycSimplePageAPositionApi();
    // Current subHeader Status
    // this.rcycCommonService.changeSubHeaderStatus(false);
    // const response = this.apiResponseEBrochureKeyTemp;
    // const res = Object.keys(response).map(function(key) {
    //             return response[key];
    //         });

    // this.apiUrlEBrochureKey = '/api/v1/ebrochure?_format=json';
    // this.rcycInternalApisService.getDrupal(this.apiUrlEBrochureKey).subscribe(
    //   (response: any = []) => {

    //     // this.apiResponseEBrochureKeyTemp = response;
    //     // if (this.apiResponseEBrochureKeyTemp && this.apiResponseEBrochureKeyTemp.length) {

    //     //   // Calling all Ebrochure Content
    //     //   this.rcycSimplePageEBrochureContent();
    //     // } else {

    //     //   this.router.navigate(['/404']);
    //     // }
    //     this.rcycCurrentPage = 'rcyc-ebrochure-page';
    //     const res = response;
    //     if (res && res.length) {

    //       res.sort(this.rcycCommonService.orderByProp('field_sort_order')).reverse();
    //       const factor = res[0];
    //       this.apiResponse_ebrochure_status = true;
    //       this.apiResponse_ebrochure = res[0];

    //       switch (factor.field_subscriber_name.toLowerCase()) {
    //         case 'zmags':
    //           this.eBrochureSubscriptionCode = 'zmags';

    //           this.zmagHeightFn();

    //           var viewer = new com.zmags.api.Viewer();
    //           const zmagPublicationID = factor.field_subscription_code;
    //           viewer.setPublicationID(zmagPublicationID);
    //           viewer.setParentElementID("zmagContent");
    //           viewer.show();
    //           break;
    //         default:

    //           this.eBrochureSubscriptionCode = 'ebrochure';
    //           // Add 1st script
    //           const script_SwfObject = document.createElement('script');
    //           script_SwfObject.src = '../../../assets/components/js/rcyc-eBrochure/swfobject.js';
    //           // script_SwfObject.id='eBrochureSwfObjectId';
    //           script_SwfObject.setAttribute('eBrochure-attr', 'eBrochure-value_1');
    //           document.getElementsByTagName('head')[0].appendChild(script_SwfObject);

    //           // Add 2nd script
    //           const script_SwfMacMouseWheel = document.createElement('script');
    //           script_SwfMacMouseWheel.src = '../../../assets/components/js/rcyc-eBrochure/swfmacmousewheel.js';
    //           // script_SwfMacMouseWheel.id='eBrochureSwfMacMouseWheelId';
    //           script_SwfMacMouseWheel.setAttribute('eBrochure-attr', 'eBrochure-value_2');
    //           document.getElementsByTagName('head')[0].appendChild(script_SwfMacMouseWheel);

    //           const script_ebrochure = document.createElement('script');
    //           // script_ebrochure.src='../../../assets/components/js/rcyc-eBrochure/brochure_2018.js';
    //           script_ebrochure.src = 'https://www.nxtbook.com/fx/config/redirect/embed_js.php?' + factor.field_subscription_code;
    //           script_ebrochure.setAttribute('eBrochure-attr', 'eBrochure-value_' + 3);
    //           document.getElementsByTagName('head')[0].appendChild(script_ebrochure);
    //           this.apiResponseEBrochureKeyStatus = true;
    //           break;
    //       }


    //       // this.eBrochureHeightTimeOut = setTimeout(() => {
    //       //   this.eBrochureIframeHeightApply();
    //       //   // section-2 iframe
    //       //   if (document.querySelector('.section-2 iframe')) {
    //       //     document.querySelector('.section-2 iframe').setAttribute('style', 'width: 100%;');
    //       //   }
    //       //   // setupLoader();
    //       // }, 2000);

    //     }
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );

  }

  /* ===================================
      Function to call Meta tag APIs
    ====================================*/

  rcycSimplePageMetaTagApi() {

    /****************** Section Metatag********************/
    this.rcycCommonService.getMetaTags(this.apiUrlMetatag);
  }

  /* =============================================
      Function to call Position A subheader API
    ============================================== */

  rcycSimplePageAPositionApi() {

    /****************** Section A********************/

    this.rcycInternalApisService.getDrupal(this.apiUrlA).subscribe(
      response => {

        this.apiResponse_ATemp = response;
        if (this.apiResponse_ATemp && this.apiResponse_ATemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {

            this.apiResponse_A_status = true;
            this.apiResponse_A = res[0];

            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A(this.apiResponse_A);
          } else {

            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A([]);
          }
        } else {

          // Changing subheader A value
          this.rcycCommonService.changeSubHeaderDataArray_A([]);
        }

      },
      err => {
        console.log(err);
      }
    );
  }

  /* ===================================
      Function to call common API
    ====================================*/

  rcycSimplePageCommonApi() {

    /****************** Section Common********************/
    this.rcycInternalApisService.getDrupal(this.apiUrlCommon).subscribe(
      response => {

        this.apiResponse_commonTemp = response;
        if (this.apiResponse_commonTemp && this.apiResponse_commonTemp.length) {

          // var res = Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.apiResponse_common = res[0];
            this.fieldUrlKey = res[0].field_url_key;
            this.apiResponse_common_status = true;
            this.apiResponseData = res[0].field_summery;

            if (this.isContractType) {
              this.subHeaderText = res[0].field_sub_header
            }
            // if (this.apiResponseData) {
            //   const convertStringData = new DOMParser().parseFromString(this.apiResponseData, 'text/html');
            //   if (this.router.url === '/careers') {
            //     const splitingData = convertStringData.getElementById('career').outerHTML;
            //     this.apiResponseData = this.apiResponseData.split(splitingData);
            //     this.apiResponseData.splice(1, 0, splitingData);
            //     this.apiResponseData.splice(2, 0, this.jobsData);
            //     this.apiResponseData.splice(3, 0, '<div id="BambooHR" style="padding-bottom:20px"><div id="BambooHR-Footer">Powered by<a href="http://www.bamboohr.com" target="_blank" rel="noopener external nofollow noreferrer"><img src="https://resources.bamboohr.com/images/footer-logo.png" alt="BambooHR - HR software"></a></div></div>')
            //     this.apiResponseData = this.apiResponseData.join('');
            //     if (!this.jobsData) {
            //       this.getJobs();
            //     }
            //   }
            // }
          }
        }

      },
      err => {

        console.log('Section terms & conditions Api error');
        console.log(err);
      }
    );
  }


  /* ===================================
      Function to call getPre contract Version Details
    ====================================*/

  rcycGetContractPageVersionApi(urlParam) {

    /****************** Section Common********************/
    // this.apiDocDeetails ='api/v1/public/euprecontract?_format=json';
    this.rcycInternalApisService.getDrupal('/api/v1/public/' + urlParam + '?_format=json').subscribe(
      response => {

        let apiResponse_Temp: any = [];
        apiResponse_Temp = response;
        if (apiResponse_Temp && apiResponse_Temp.length) {
          this.documentVersion = apiResponse_Temp[0].field_eu_document_version;
          this.documentName = apiResponse_Temp[0].field_eu_document_name;
        }

      },
      err => {

        console.log('Section terms & conditions Api error');
        console.log(err);
      }
    );
  }

  /* =================================
         Height for ebrochure Iframe
    ================================== */
  eBrochureIframeHeightApply() {
    
    if (document.getElementById('flashcontent')) {

      let totalHeight;
      const screenHeight = window.innerHeight;
      totalHeight = (document.getElementById('rcyc-page-header') as HTMLElement).offsetHeight +
        (document.querySelector('nav.menu-wrapper.brand-nav-wrapper') as HTMLElement).offsetHeight;
      const eBrochureIframeHeight = screenHeight - totalHeight;     
      document.getElementById('flashcontent').setAttribute('style', 'height: ' + eBrochureIframeHeight + 'px;');

      document.querySelector('.appComponentBody').setAttribute('style', 'margin-bottom: 0px;');
    }
    // });

  }
  // focusOutFunction(input) {
  //   this.buttonStatus(input);
  // }
  // buttonStatus(input?: any) {


  //   if (input) {
  //     if (isNaN(input.data) && input.currentTarget.id == 'bookingNumberId') {
  //       input.srcElement.value = input.srcElement.value.replace(input.data, '');
  //       this.bookingId = input.srcElement.value;
  //     }
  //   }


  //   var re = new RegExp(/^[0-9]+$/);
  //   if (re.test(this.bookingId)) {
  //     this.bookingIdFlag = true;
  //     this.disableButton = false;
  //   } else {
  //     this.bookingIdFlag = false;
  //     this.disableButton = true;
  //   }
  //   if (this.bookingId && input.currentTarget.id == 'bookingNumberId') {
  //     this.bookingIdReqValue = true;
  //   }
  //   if (!this.bookingId && input.currentTarget.id == 'bookingNumberId') {
  //     this.bookingIdReqValue = false;

  //   }
  //   if (this.confirmerName && input.currentTarget.id == 'confirmerName') {
  //     this.confirmerNameReqValue = true;
  //   }
  //   if (!this.confirmerName && input.currentTarget.id == 'confirmerName') {
  //     this.confirmerNameReqValue = false;
  //   }
  //   if (this.confirmerName && this.bookingId) {
  //     this.disableButton = false;
  //   }
  //   else {
  //     this.disableButton = true;
  //   }
  // }
  acknowledgeClick() {
    this.acknowledgeButton = true;
    this.confirmerName;
    this.bookingId;
    let apiUrl = '/api/termsAndCoditions';
    let today = moment(new Date()).format("YYYY-MM-DD");
    const param: any = {
      "bookingNumber": this.euPreContractForm.value.bookingNumber,
      "name": this.euPreContractForm.value.guestName,
      "documentName": this.documentName,
      "documentVersion": this.documentVersion,
      "confirmationDate": today,

    };

    this.rcycInternalApisService.postReservationForm(apiUrl, param).subscribe(
      (res: any) => {

        if (res && res.code === 200) {
          this.statusMessage = this.apiResponseGSuccess.field_eu_pre_contract_acknowledg;
          this.messageclassType = 'success';
          this.acknowledgeButton = false;
        }
        else {
          this.statusMessage = res.message;

          if(res.errcode==524)
          {
            this.statusMessage = this.apiResponseGError.field_eu_booking_no_not_exist; //res.message;
          }
          this.messageclassType = 'error';
          this.acknowledgeButton = false;

          this.disableButton = true;
        }



      });


  }
  cancelOption() {
    this.confirmerName = '';
    this.bookingId = '';
    this.euPreContractForm.get('bookingNumber').setValue('');
    this.euPreContractForm.get('guestName').setValue('');
    // this.buttonStatus();
  }
  getErrorCommonMsgs() {
    this._SharedServiceService.getErrorCommonMsg().subscribe(res => {
      this.apiResponseGError = res;
    });
  }
  getSuccessCommonMsg() {
    this._SharedServiceService.getSuccessCommonMsg().subscribe(res => {
      this.apiResponseGSuccess = res;
    });
  }

  euPreContractFormFn() {
    this.euPreContractForm = this.formBuilder.group({
      bookingNumber: ['', [Validators.required,Validators.pattern('[0-9]*')]],
      guestName: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
    });

  }

  ngOnDestroy() {
    // console.log('DESTROY--- simplecomponent');
    // localStorage.setItem('previousUrl', this.currentUrl)
  }

  /* =============================================
      Function to call Different e brochure api
    ============================================== */

    rcycSimplePageDifferentEBrochure() {
      const currentUrlPath=this.currentUrlPathAsArray[this.currentUrlPathAsArray.length-1].path;
      let currentExperienceUrlPath:string;
      if(this.currentUrlPathAsArray.length===2) {
        currentExperienceUrlPath=this.currentUrlPathAsArray[this.currentUrlPathAsArray.length-2].path;  
      }
          
      let currentEBrochureUrlKey:string;
      if(currentUrlPath.match('-brochure')) {  
        currentEBrochureUrlKey=currentUrlPath.replace(/-brochure$/, '');
        if(currentExperienceUrlPath&&currentExperienceUrlPath=='experience') {
          this.ebrochureDrupalUrl='/api/v1/ebrochure/experience/'+currentEBrochureUrlKey+'?_format=json';
        }  else {
          this.ebrochureDrupalUrl='/api/v1/ebrochure/'+currentEBrochureUrlKey+'?_format=json';
        }          
      } 
      this.getEbrochureFn();
    }

/* =============================================
      Function to get Ebrochure
    ============================================== */
    getEbrochureFn() {
      let eBrochureURL = window.location.pathname
      if(eBrochureURL !== '/ebrochure') {
        this.ebrochureDrupalUrl = '/api/v1/ebrochure' + eBrochureURL + '?_format=json';
      }
      this.rcycInternalApisService.getDrupal(this.ebrochureDrupalUrl).subscribe(
        (response: any = []) => {
  
              this.rcycCurrentPage = 'rcyc-ebrochure-page';
              const res = response;
              if (res && res.length) {
  
                if(localStorage.getItem('previousUrl')) {
                  localStorage.removeItem('previousUrl');
                }             
                res.sort(this.rcycCommonService.orderByProp('field_sort_order')).reverse();

                const factor = res[0];
                this.apiResponse_ebrochure_status = true;
                this.apiResponse_ebrochure = res[0];

                switch (factor.field_subscriber_name.toLowerCase()) {
                  case 'flippingbook':
                      this.eBrochureSubscriptionCode = 'flippingbook';
                      let elementToWriteFlipBook = document.querySelector('#FlippingBookContent') as HTMLElement;
                      let flippingBookID = factor.field_subscription_code;
                      let flippingBookElement = `<a href="https://online.flippingbook.com/view/${flippingBookID}/" class="fbo-embed" data-fbo-id="${flippingBookID}" data-fbo-lightbox="yes" data-fbo-width="100%" data-fbo-height="550px" data-fbo-version="1" style="max-width: 100%">The Ritz-Carlton Yacht Collection Brochure</a>`;
                      elementToWriteFlipBook.innerHTML = flippingBookElement;
                      const script_flipBook = document.createElement('script');
                      script_flipBook.src = `https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=${flippingBookID}`;
                      script_flipBook.defer = true;
                      document.getElementsByTagName('head')[0].appendChild(script_flipBook);

                    break;
                  default:
  
                    this.eBrochureSubscriptionCode = 'ebrochure';
                    // Add 1st script
                    const script_SwfObject = document.createElement('script');
                    script_SwfObject.src = '../../../assets/components/js/rcyc-eBrochure/swfobject.js';
                    // script_SwfObject.id='eBrochureSwfObjectId';
                    script_SwfObject.setAttribute('eBrochure-attr', 'eBrochure-value_1');
                    document.getElementsByTagName('head')[0].appendChild(script_SwfObject);
  
                    // Add 2nd script
                    const script_SwfMacMouseWheel = document.createElement('script');
                    script_SwfMacMouseWheel.src = '../../../assets/components/js/rcyc-eBrochure/swfmacmousewheel.js';
                    // script_SwfMacMouseWheel.id='eBrochureSwfMacMouseWheelId';
                    script_SwfMacMouseWheel.setAttribute('eBrochure-attr', 'eBrochure-value_2');
                    document.getElementsByTagName('head')[0].appendChild(script_SwfMacMouseWheel);
  
                    const script_ebrochure = document.createElement('script');
                    // script_ebrochure.src='../../../assets/components/js/rcyc-eBrochure/brochure_2018.js';
                    script_ebrochure.src = 'https://www.nxtbook.com/fx/config/redirect/embed_js.php?' + factor.field_subscription_code;
                    script_ebrochure.setAttribute('eBrochure-attr', 'eBrochure-value_' + 3);
                    document.getElementsByTagName('head')[0].appendChild(script_ebrochure);
                    this.apiResponseEBrochureKeyStatus = true;
                    break;
                }
  
  
                // this.eBrochureHeightTimeOut = setTimeout(() => {
                //   this.eBrochureIframeHeightApply();
                //   // section-2 iframe
                //   if (document.querySelector('.section-2 iframe')) {
                //     document.querySelector('.section-2 iframe').setAttribute('style', 'width: 100%;');
                //   }
                //   // setupLoader();
                // }, 2000);
  
              }
            },
            err => {
              console.log(err);
            }
          );
    }
}
