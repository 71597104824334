<div id="rcyc-destination-landing" class="bodyContentOuter">

  <!-- Hero Banner -->
  <app-rcyc-hero-module *ngIf="apiResponseB  && responseArray.length>0" [responseArray]="apiResponseB"
    [pageName]="'destinations'">
  </app-rcyc-hero-module>

  <!-- Destinations 50-50 components -->
  <ng-container *ngFor="let eachResponse of apiResponseD">
    <div class="py-10 lg:py-16 overflow-x-hidden" (click)="setFilterData(eachResponse?.field_group_url_key)">
      <app-rcyc-50-50 *ngIf="apiResponseD" [data]="eachResponse"></app-rcyc-50-50>
    </div>
  </ng-container>

  <!-- Slider Editorial -->
  <app-rcyc-slider variant="editorial" id="home-editorial-slider" *ngIf="apiResponseE" pageName="destinations" [data]="{
        topContent: {
          eyebrow: apiResponseE[0]?.field_com_eyebrow,
          title: apiResponseE[0]?.title | convertHtml,
        },
        items: apiResponseE
      }"></app-rcyc-slider>
</div>