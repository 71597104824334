<div class="shadow opacity-100 z-10 fixed-cover lg:display-none" *ngIf="showHideFilterStatus"
  xmlns="http://www.w3.org/1999/html"></div>

<!--  Page heading -->
<div class="container pt-10 lg:pt-16 bodyContentOuter"
  [ngClass]="pageName === 'Loyalty'? 'lg:pb-[30px]' : 'lg:pb-8 pb-6'"
  *ngIf="findACruiseArray && !compareClickStatus && pageName !== 'offersProgramsDetails'">
  <h1 *ngIf="pageName !== 'destinationsDetails' && pageName !== 'Loyalty'"
    class="h1 mb-6 lg:mb-10 lg:text-[49px] text-[29px] font-normal lg:tracking-[0.588px] tracking-[0.348px] !leading-[115%] font-['Adobe Caslon Pro']">
    Find your dream
    voyage</h1>
  <h1 *ngIf="pageName === 'Loyalty'"
    class="h1 lg:text-[49px] text-[29px] font-normal lg:tracking-[0.588px] tracking-[0.348px] !leading-[115%] font-['Adobe Caslon Pro']">
    {{totalData}} Matching Cruises</h1>
  <div *ngIf="pageName !== 'Loyalty'"
    [ngClass]="{'uppercase tracking-wide': pageName !== 'offersProgramsDetails' || pageName !== 'destinationsDetails', 'uppercase font-caslon lg:text-[49px] text-[29px] tracking-wide lg:mb-[16px] mb-0': pageName === 'offersProgramsDetails' || pageName === 'destinationsDetails'}">
    {{totalData}} Matching Cruises
  </div>
</div>

<!-- Filter section-->
<div #topFilters>
  <div class="container flex flex-col lg:flex-row lg:justify-between element2"
    *ngIf="findACruiseArray && !compareClickStatus && pageName !== 'offersProgramsDetails'">
    <div class="mb-4 lg:mb-0 flex text-[14px] tracking-wide">
      <div class="leading-6 whitespace-nowrap text-grey mr-2">SORT BY:</div>
      <div id="selectTopOuter">
        <select #n (change)="sortBy(n.value)" id="sort_dropdown_top" [ngModel]="selectedValue" tabindex="0"
          class="border-none focus:border-none text-[14px] h-auto text-grey uppercase max-w-none w-auto">
          <option value="DepartureDateAscending" selected>Departure Date - ascending</option>
          <option value="DepartureDateDescending">Departure Date - descending</option>
          <option value="PricelowTohigh">Price - low to high</option>
          <option value="PricehighTolow">Price - high to low</option>
          <option value="DurationshortestTolongest">Duration - shortest to longest</option>
          <option value="DurationlongestToshortest">Duration - longest to shortest</option>
        </select>
      </div>
    </div>
    <div class="flex lg:justify-end lg:w-full"
      [ngClass]="{'justify-between' : pageName !== 'destinationsDetails', 'justify-center' : pageName === 'destinationsDetails'}">
      <div *ngIf="findACruiseArray && pageName !== 'destinationsDetails'" class="w-1/2 lg:w-auto lg:display-none"
        (click)="showHideFilterClick(!showHideFilterStatus)" (keyup.enter)="showHideFilterClick(!showHideFilterStatus)">
        <button id="hideFiterBtnId"
          class="button-primary button-small flex mt-0 text-sm group max-w-[159px] lg:max-w-none w-full lg:w-auto"
          tabindex="0">
          <img src="assets/img/filter-outline.svg" class="display-none group-hover:flex w-4" alt="">
          <img src="assets/img/filter-outline-white.svg" class="group-hover:display-none w-4" alt="">
          <span class="ml-1">
            <span *ngIf="showHideFilterStatus">
              HIDE
            </span>
            <span *ngIf="!showHideFilterStatus">
              SHOW
            </span>
            <span class="ml-1">FILTERS</span>
          </span>
        </button>
      </div>

      <div class="lg:flex lg:gap-4 lg:w-full lg:justify-end"
        [ngClass]="{'w-full' : pageName === 'destinationsDetails'}">
        <div class="display-none lg:flex">
          <button id="btn_grid_top" tabindex="0"
            class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm group w-1/2"
            (keyup.enter)="showDisplayClick('grid')" (click)="showDisplayClick('grid')"
            (focus)="gridListFocus($event, 'grid', 'top')" (focusout)="gridListFocusOut($event, 'grid', 'top')"
            [class]="gridSelectedStatus ? 'button-primary' : 'button-secondary'">
            <span *ngIf="gridSelectedStatus" class="flex items-center justify-center">
              <img src="assets/img/view-grid.svg" class="display-none group-hover:flex w-4" alt="">
              <img src="assets/img/view-grid-white.svg" class="group-hover:display-none w-4" alt="">
            </span>
            <span *ngIf="!gridSelectedStatus" class="flex items-center justify-center">
              <img src="assets/img/view-grid-white.svg" class="display-none group-hover:flex w-4" alt="">
              <img src="assets/img/view-grid.svg" class="group-hover:display-none w-4" alt="">
            </span>
            <span *ngIf="!gridSelectedStatus" class="ml-1 text-black group-hover:text-white no-underline">GRID</span>
            <span *ngIf="gridSelectedStatus" class="ml-1 text-white group-hover:text-black no-underline">GRID</span>
          </button>
          <button id="btn_list_top" tabindex="0"
            class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm group w-1/2"
            (keyup.enter)="showDisplayClick('list')" (click)="showDisplayClick('list')"
            (focus)="gridListFocus($event, 'list', 'top')" (focusout)="gridListFocusOut($event, 'list', 'top')"
            [class]="listSelectedStatus ? 'button-primary' : 'button-secondary'">

            <span *ngIf="listSelectedStatus" class="flex items-center justify-center">
              <img src="assets/img/list-bulleted.svg" class="display-none group-hover:flex w-4" alt="">
              <img src="assets/img/list-bulleted-white.svg" class="group-hover:display-none w-4" alt="">
            </span>
            <span *ngIf="!listSelectedStatus" class="flex items-center justify-center">
              <img src="assets/img/list-bulleted-white.svg" class="display-none group-hover:flex w-4" alt="">
              <img src="assets/img/list-bulleted.svg" class="group-hover:display-none w-4" alt="">
            </span>
            <span *ngIf="!listSelectedStatus" class="ml-1 text-black group-hover:text-white no-underline">LIST</span>
            <span *ngIf="listSelectedStatus" class="ml-1 text-white group-hover:text-black no-underline">LIST</span>
          </button>
        </div>

        <div class="flex" [ngClass]="{'min-w-[167px]' : !imageMode}">
          <button *ngIf="imageMode" (click)="setImageMode('map')"
            class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm group w-1/2"
            [ngClass]="{'button-primary' : imageMode === 'map', 'button-secondary' : imageMode === 'photo'}">
            <span class="flex items-center justify-center">
              <img [src]="imageMode === 'map' ? 'assets/img/map-white.svg' : 'assets/img/map.svg'" class="flex w-4"
                alt="">
            </span>
            <span class="ml-1">
              Map
            </span>
          </button>
          <button *ngIf="imageMode" (click)="setImageMode('photo')"
            class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/2"
            [ngClass]="{'button-primary' : imageMode === 'photo', 'button-secondary' : imageMode === 'map'}">
            <span class="flex items-center justify-center">
              <img [src]="imageMode === 'photo' ? 'assets/img/photo-white.svg' : 'assets/img/photo.svg'"
                class="flex w-4" alt="">
            </span>
            <span class="ml-1">
              Photo
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<section
  *ngIf="showHideFilterStatus && !compareClickStatus && pageName !== 'offersProgramsDetails' && this.pageName !== 'destinationsDetails'"
  class="shadow-lg fixed lg:sticky lg:left-[24px] lg:top-[55px] z-20 -translate-y-1/2
  top-0 left-0 w-full h-full">
  <div class="absolute left-0 bottom-0 w-full h-full " [ngClass]="{
    'backdrop-blur-effect ': !isMobileView,
   }"></div> <!-- Filters Shadow -->

  <div
    class="bg-white lg:bg-white/40 lg:backdrop-blur-effect relative translate-y-0 lg:w-full w-[calc(100%-48px)] lg:top-0 top-56 m-auto"
    *ngIf="findCruiseData">
    <button class="lg:display-none absolute top-2 right-2 m-0 unset-button" (click)="showHideFilterClick(false)"
      (keyup.enter)="showHideFilterClick(false)">
      <img src="assets/img/close.svg" alt="Close Filters" />
    </button>

    <div class="container lg:flex py-12 lg:py-0 lg:py-4">
      <div class="basic-form_group mb-4 lg:mb-0 flex lg:w-1/6 lg:mr-4">
        <div class="basic-form_input w-full">
          <span
            [ngClass]="{'cursor-not-allowed': pageName === 'destinationsDetails', 'cursor-pointer': pageName !== 'destinationsDetails'}"
            class="region_title flex border-b hover:border-dark-blue-1 border-solid border-primary text-primary group">
            <span
              class="regionDD uppercase flex justify-between leading-none pt-1 py-3 w-full group-hover:text-dark-blue-1 tracking-wide"
              tabindex="{{regionDDTabIndex}}" (keyup.enter)="clickout($event)" (keyup)="onRegionFocus($event)"
              (focusout)="onRegionFocusOut($event)">
              <div>
                <span *ngIf="slct_regionname.length===0" class="">Region</span>
                <span *ngIf="slct_regionname.length>0" class="">{{slct_regionname[0]}}</span>
              </div>
              <div class="flex gap-[25px] md:gap-[15px]">
                <span *ngIf="slct_regionname.length>1"> +{{slct_regionname.length-1}}</span>
                <span class="icon-arrow-down text-primary group-hover:text-dark-blue-1"
                  [ngClass]="{'rotate-180': regionDropDownFlag, 'cursor-not-allowed': pageName === 'destinationsDetails', 'cursor-pointer': pageName !== 'destinationsDetails'}"></span>
              </div>
            </span>
          </span>
          <ul
            [ngClass]="{'cursor-not-allowed': pageName === 'destinationsDetails', 'cursor-pointer': pageName !== 'destinationsDetails'}"
            class="absolute w-full left-0 top-full bg-white z-10 max-h-[150px] overflow-auto shadow-[0_4px_16px_0px_rgba(28,28,28,0.12)] p-[10px]"
            *ngIf="region && FindCruiseRegion.length && regionDropDownFlag" id="region_dropdown">
            <ng-container *ngFor="let sarchregion of FindCruiseRegion">
              <li class="flex ml-0 mb-3 lg:mb-0">
                <input tabindex="0"
                  (change)="filtervalue('region',sarchregion.travelRegion,$event,sarchregion.generalDescription)"
                  type="checkbox" [checked]="Checkotpionselect('region',sarchregion.travelRegion)" name="region"
                  value="{{sarchregion.travelRegion}}" class="min-w-[17px] findacruiseCheckbox mr-[6px]" />
                <span tabindex="-1" class="text-sm">{{sarchregion.generalDescription}}</span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <!-- DropSection-departureDate -->
      <div class="basic-form_group mb-4 lg:mb-0 flex lg:w-1/6 lg:mr-4">
        <div class="basic-form_input w-full">
          <span
            class="region_title flex cursor-pointer border-b hover:border-dark-blue-1 border-solid border-primary text-primary group">
            <span
              class="departureDateDD uppercase flex justify-between leading-none pt-1 py-3 w-full group-hover:text-dark-blue-1 tracking-wide"
              tabindex="{{departureDateDDTabIndex}}" (keyup.enter)="clickout($event)"
              (keyup)="onDepartureDateFocus($event)" (focusout)="onDepartureDateFocusOut($event)">
              <div>
                <span *ngIf="slct_deptnname.length===0" class="">Departure Date</span>
                <span *ngIf="slct_deptnname.length>0" class="">{{slct_deptnname[0]}}</span>
              </div>
              <div class="flex gap-[25px] md:gap-[15px]">
                <span *ngIf="slct_deptnname.length>1"> +{{slct_deptnname.length-1}}</span>
                <span class="icon-arrow-down text-primary group-hover:text-dark-blue-1"
                  [ngClass]="(departureDateDropDownFlag)?'rotate-180':''"></span>
              </div>
            </span>
          </span>

          <ul *ngIf="departure_date  && FindCruiseDepdate.length && departureDateDropDownFlag"
            class="absolute w-full left-0 top-full bg-white z-10 max-h-[150px] overflow-auto shadow-[0_4px_16px_0px_rgba(28,28,28,0.12)] p-[10px]"
            id="departure_dropdown">
            <li *ngFor="let sarchdepDate of FindCruiseDepdate" class="flex ml-0 mb-3 lg:mb-0">
              <input tabindex="0" (change)="filtervalue('departure_date',sarchdepDate,$event,sarchdepDate)"
                type="checkbox" name="departure_date" [checked]="Checkotpionselect('departure_date',sarchdepDate)"
                value="{{sarchdepDate}}" class="min-w-[17px] findacruiseCheckbox mr-[6px]" />
              <span tabindex="-1" class="text-sm">{{sarchdepDate}}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- DropSection-Duration -->
      <div class="basic-form_group mb-4 lg:mb-0 flex lg:w-1/6 lg:mr-4">
        <div class="basic-form_input w-full">
          <span
            class="region_title flex cursor-pointer border-b hover:border-dark-blue-1 border-solid border-primary text-primary group">
            <span
              class="durationDD uppercase flex justify-between leading-none pt-1 py-3 w-full group-hover:text-dark-blue-1 tracking-wide"
              tabindex="{{durationDDTabIndex}}" (keyup.enter)="clickout($event)" (keyup)="onDurationFocus($event)"
              (focusout)="onDurationFocusOut($event)">

              <div>
                <span *ngIf="slct_duration.length===0" class="">Duration</span>
                <span *ngIf="slct_duration.length>0" class="">{{slct_duration[0]}}</span>
              </div>
              <div class="flex gap-[25px] md:gap-[15px]">
                <span *ngIf="slct_duration.length>1"> +{{slct_duration.length-1}}</span>
                <span class="icon-arrow-down text-primary group-hover:text-dark-blue-1"
                  [ngClass]="(durationDropDownFlag)?'rotate-180':''"></span>
              </div>
            </span>
          </span>
          <ul *ngIf="duration && FindCruiseDuration.length && durationDropDownFlag"
            class="absolute w-full left-0 top-full bg-white z-10 max-h-[150px] overflow-auto shadow-[0_4px_16px_0px_rgba(28,28,28,0.12)] p-[10px]"
            id="duration_dropdown">
            <li *ngFor="let searchDuration of FindCruiseDuration" class="flex ml-0 mb-3 lg:mb-0">
              <input tabindex="0"
                (change)="filtervalue('duration',searchDuration.duration,$event,searchDuration.description)"
                type="checkbox" name="duration" [checked]="Checkotpionselect('duration',searchDuration.description)"
                value="{{searchDuration.duration}}" class="min-w-[17px] findacruiseCheckbox mr-[6px]" />
              <span tabindex="-1" class="text-sm">{{searchDuration.description | titlecase}}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- DropSection-port -->
      <div class="basic-form_group mb-4 lg:mb-0 flex lg:w-1/6 lg:mr-4">
        <div class="basic-form_input w-full">
          <span
            class="region_title flex cursor-pointer border-b hover:border-dark-blue-1 border-solid border-primary text-primary group">
            <span
              class="portsDD uppercase flex justify-between leading-none pt-1 py-3 w-full group-hover:text-dark-blue-1 tracking-wide"
              tabindex="{{portsDDTabIndex}}" (keyup)="onPortFocus($event)" (keyup.enter)="clickout($event)"
              (focusout)="onPortFocusOut($event)">
              <div>
                <span *ngIf="slct_portnname.length===0" class="">Ports</span>
                <span *ngIf="slct_portnname.length>0" class="">{{slct_portnname[0]}}</span>
              </div>
              <div class="flex gap-[25px] md:gap-[15px]">
                <span *ngIf="slct_portnname.length>1"> +{{slct_portnname.length-1}}</span>
                <span class="icon-arrow-down text-primary group-hover:text-dark-blue-1"
                  [ngClass]="(portDropDownFlag)?'rotate-180':''"></span>
              </div>
            </span>
          </span>

          <ul *ngIf="ports && FindCruisePorts.length && portDropDownFlag" id="ports_dropdown"
            class="absolute w-full left-0 top-full bg-white z-10 max-h-[150px] overflow-auto shadow-[0_4px_16px_0px_rgba(28,28,28,0.12)] p-[10px]">
            <li *ngFor="let sarchPorts of FindCruisePorts" class="flex ml-0 mb-3 lg:mb-0">
              <input tabindex="0" (change)="filtervalue('ports',sarchPorts.portCode,$event,sarchPorts.portName)"
                type="checkbox" [checked]="Checkotpionselect('ports',sarchPorts.portCode)"
                value="{{sarchPorts.portCode}}" class="min-w-[17px] findacruiseCheckbox mr-[6px]" />
              <span tabindex="-1" class="text-sm" *ngIf="!sarchPorts.countryName">{{sarchPorts.portName}}</span>
              <span tabindex="-1" class="text-sm" *ngIf="sarchPorts.countryName">{{sarchPorts.portName}},
                {{sarchPorts.countryName}}</span>
            </li>
          </ul>

        </div>
      </div>
      <!-- DropSection-yacht -->
      <div class="basic-form_group mb-4 lg:mb-0 flex lg:w-1/6 lg:mr-4">
        <div class="basic-form_input w-full">
          <span
            class="region_title flex cursor-pointer border-b hover:border-dark-blue-1 border-solid border-primary text-primary group">
            <span
              class="yachtDD uppercase flex justify-between leading-none pt-1 py-3 w-full group-hover:text-dark-blue-1 tracking-wide"
              tabindex="{{yachtDDTabIndex}}" (keyup.enter)="clickout($event)" (keyup)="onYachtFocus($event)"
              (focusout)="onYachtFocusOut($event)">
              <div>
                <span *ngIf="slct_yachtName.length===0" class="">Yacht</span>
                <span *ngIf="slct_yachtName.length>0" class="">{{slct_yachtName[0]}}</span>
              </div>
              <div class="flex gap-[25px] md:gap-[15px]">
                <span *ngIf="slct_yachtName.length>1"> +{{slct_yachtName.length-1}}</span>
                <span class="icon-arrow-down text-primary group-hover:text-dark-blue-1"
                  [ngClass]="(yachtDropDownFlag)?'rotate-180':''"></span>
              </div>
            </span>
          </span>
          <ul *ngIf="yacht && FindCruiseYacht.length && yachtDropDownFlag" id="yacht_dropdown"
            class="absolute w-full left-0 top-full bg-white z-10 max-h-[150px] overflow-auto shadow-[0_4px_16px_0px_rgba(28,28,28,0.12)] p-[10px]">
            <li *ngFor="let searchYacht of FindCruiseYacht" class="flex ml-0 mb-3 lg:mb-0">
              <input tabindex="0" (change)="filtervalue('yacht',searchYacht.yachtId,$event,searchYacht.yachtName)"
                type="checkbox" [checked]="Checkotpionselect('yacht',searchYacht.yachtId)" name="yacht"
                value="{{searchYacht.yachtId}}" class="min-w-[17px] findacruiseCheckbox mr-[6px]" />
              <span tabindex="-1" class="text-sm">{{searchYacht.yachtName | titlecase}}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex flex-grow gap-4 lg:pl-2 xl:min-w-[240px] lg:items-baseline">
        <a id="clearFiltersBtnId" class="button-secondary button-small flex flex-grow !text-[16px]"
          (click)="clear_cruisefilter();" (keyup.enter)="clear_cruisefilter();" tabindex="0"
          title="CLEAR FILTERS">CLEAR</a>
        <button id="findACruiseBtnId" class="button-primary button-small !m-0 flex flex-grow !text-[16px]"
          (click)="select_filter('')" (keyup.enter)="select_filter('')" (focus)="clickout($event)" type="submit"
          value="FIND A CRUISE" title="FIND A CRUISE" tabindex="0">Apply</button>
      </div>
    </div>
  </div>
</section>

<div id="scrollPosition" class="container" *ngIf="!compareClickStatus">
  <div [ngClass]="pageName==='offersProgramsDetails' ? 'pt-0' : 'pt-12'"
    [class]="gridClickStatus ? 'grid md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 xl:gap-x-8 xl:gap-y-8' : 'flex flex-col gap-12'">
    <!-- Featured -->

    <div class="relative" *ngFor="let item of findACruiseArray; let i=index;">
      <div *ngIf="pageName !== 'offersProgramsDetails'"
        class="absolute z-10 top-0 w-12 h-12 bg-white/10 cursor-pointer right-0"
        [class]="listClickStatus ? 'lg:left-[295px]' : ''">
        <input id="itemId_{{item.voyageId}}" (click)="compareCheckboxClick($event, item)" type="checkbox"
          class=" [&:read-only]:cursor-pointer min-w-full min-h-full !opacity-0 [&:checked~.inactive]:display-none [&:checked~.active]:flex compare_checkbox findacruiseCheckbox" />
        <img src="assets/img/star.svg"
          class="w-6 display-none active absolute top-[30%] left-[30%] -translate-x-1/2 -translate-y-1/2 pointer-events-none"
          alt="">
        <img src="assets/img/star-outline.svg"
          class="w-6 inactive absolute top-[30%] left-[30%] -translate-x-1/2 -translate-y-1/2 pointer-events-none"
          alt="">
        <span class="sr-only">COMPARE</span>
      </div>
      <app-rcyc-card-featured *ngIf="item" class="h-full" [variant]="gridClickStatus ? 'grid' : 'list'" [data]="item"
        [imageScrollPosition]="imageMode" [backButton]="true" [currencyCode]="currencyCode"
        [additionalCostInfo]="additionalCostInfo" [PCFIToggleInfo]="PCFIToggleInfo" [staticDrupalTexts]="staticDrupalTexts"
        (setPreviousUrlForVoyagePageBack)="setPreviousUrlForVoyagePageBack($event)"></app-rcyc-card-featured>

    </div>
  </div>

</div>

<div *ngIf="pageName === 'offersProgramsDetails' && !compareClickStatus" class="flex container lg:pt-[48px] pt-[36px]">
  <div class="promotion text-center !text-[13px] lg:px-[216px]" [innerHTML]="apiResponseB?.field_summery"></div>
</div>

<div id="rcyc-page-findACruise">
  <section class="nonCompareSection" *ngIf="!compareClickStatus">
    <div class="container mt-8">
      <div *ngIf="findACruiseArray?.length && totalData && (findACruiseArray?.length < totalData)">
        <app-rcyc-pagination [totalItems]="totalData" [itemsPerPage]="perPage" [pageNumbersToShow]="pageNumbersToShow"
          (pageChanged)="onPageChanged($event)"></app-rcyc-pagination>
      </div>
    </div>
  </section>

  <div *ngIf="pageName !== 'offersProgramsDetails' && !compareClickStatus && compareCheckboxArray.length > 0"
    class="fixed bottom-0 left-0 w-full lg:bg-white/40 z-10 shadow-header pt-3 pb-4 backdrop-blur-effect">
    <div class="flex justify-between items-center mb-1 lg:mb-4 container uppercase">
      <span>Select up to 3 items to compare</span>
    </div>

    <div class="flex mb-[10px]">
      <div class="flex container overflow-auto gap-4">
        <div *ngFor="let item of compareCheckboxArray"
          class="min-w-[250px] min-w-none lg:w-1/3 flex bg-white border border-solid border-primary relative p-2 gap-4 items-center">
          <button class="absolute top-2 right-2 bg-transparent m-0 p-0 border-0 hover:bg-transparent hover:opacity-75"
            (click)="removeFromCompared(item)">
            <img src="assets/img/close.svg" alt="Close Filters" />
          </button>
          <img *ngIf="item.slider.length && item.slider[0]" [src]="item.slider[0]" alt="" class="w-12 h-12">
          <div>
            <div class="text-[13px] italic">{{item.yachtName}}</div>
            <div>{{item.voyageName}}</div>
          </div>
        </div>

        <div *ngIf="!compareCheckboxArray[1]"
          class="min-w-[250px] min-w-none lg:w-1/3 border border-dashed border-primary/40 flex justify-center items-center">
          <img src="assets/img/compare-placeholder.svg" alt="">
        </div>

        <div *ngIf="!compareCheckboxArray[2]"
          class="min-w-[250px] min-w-none lg:w-1/3 border border-dashed border-primary/40 flex justify-center items-center">
          <img src="assets/img/compare-placeholder.svg" alt="">
        </div>

        <div class="display-none lg:flex">
          <button class="button-primary m-0 whitespace-nowrap" (click)="showDisplayClick('compare')" value="COMPARE"
            tabindex="0">COMPARE
            VOYAGES</button>
        </div>
      </div>
    </div>

    <div class="container">
      <button class="button-primary button-small m-0 lg:display-none w-full" (click)="showDisplayClick('compare')"
        value="COMPARE" tabindex="0">COMPARE Voyages</button>
    </div>
  </div>

  <!-- Section comes on Compare Click-->
  <section class="compareSection" *ngIf="compareClickStatus">
    <div id="rcyc-page-findACruiseCompare">

      <div class="container py-16 flex justify-between">
        <h1 class="m-0 text-[29px] lg:text-[32px] w-1/2 !font-caslon">Comparing {{compareCheckboxArray.length}} voyages
        </h1>
        <button (keyup.enter)="showDisplayClick('goback')" (click)="showDisplayClick('goback')"
          class="button-secondary mt-0 mb-auto !py-[11.5px] !px-[8.5px] lg:!px-[32px] lg:!py-[16.5px]">
          <img src="assets/img/arrow_left_small.svg" alt=""> GO BACK
        </button>
      </div>

      <div class="container flex flex-col lg:flex-row gap-8 lg:gap-12 lg:grid lg:grid-cols-3">
        <div class="relative" *ngFor="let item of compareCheckboxArray; let i=index;">
          <div class="absolute z-10 top-0 right-0 w-12 h-12 bg-white/10" id="itemId_{{i+1}}">
            <img src="assets/img/star.svg"
              class="w-6 absolute top-[30%] left-[30%] -translate-x-1/2 -translate-y-1/2 pointer-events-none" alt="">
          </div>
          <app-rcyc-card-featured variant="grid-small" [currencyCode]="currencyCode" [data]="item" [showPorts]="true"
            [additionalCostInfo]="additionalCostInfo" [PCFIToggleInfo]="PCFIToggleInfo" [staticDrupalTexts]="staticDrupalTexts"
            [imageScrollPosition]="imageMode"></app-rcyc-card-featured>
        </div>
      </div>
    </div>
  </section>
</div>

<!--Duplicated the filters code for mobile-->
<div class="transition-all duration-75 ease-linear lg:hidden w-full bg-white/40 backdrop-blur-effect z-10"
  *ngIf="pageName !== 'offersProgramsDetails' && !compareClickStatus && !(!compareClickStatus && compareCheckboxArray.length > 0)"
  [ngClass]="{
            '-bottom-[65px]': isTestDivScrolledIntoView,
            'bottom-0 sticky fixed': !isTestDivScrolledIntoView,
          }">
  <div class="container py-4 flex lg:justify-end w-full" [ngClass]="{
      'justify-between': pageName !== 'destinationsDetails',
      'justify-center': pageName === 'destinationsDetails'
    }">
    <div *ngIf="findACruiseArray && pageName !== 'destinationsDetails'" class="w-1/2 lg:w-auto lg:display-none"
      (click)="showHideFilterClick(!showHideFilterStatus)" (keyup.enter)="showHideFilterClick(!showHideFilterStatus)">
      <button id="hideFiterBtnId"
        class="button-primary button-small flex mt-0 text-sm group max-w-[159px] lg:max-w-none w-full lg:w-auto"
        tabindex="0">
        <img src="assets/img/filter-outline.svg" class="display-none group-hover:flex w-4" alt="">
        <img src="assets/img/filter-outline-white.svg" class="group-hover:display-none w-4" alt="">
        <span class="ml-1">
          <span *ngIf="showHideFilterStatus">
            HIDE
          </span>
          <span *ngIf="!showHideFilterStatus">
            SHOW
          </span>
          <span class="ml-1">FILTERS</span>
        </span>
      </button>
    </div>

    <div class="lg:flex lg:gap-4 lg:w-full lg:justify-end" [ngClass]="{
        'w-full': pageName === 'destinationsDetails'
      }">
      <div class="display-none lg:flex">
        <button id="btn_grid_top" tabindex="0"
          class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm group w-1/2"
          (keyup.enter)="showDisplayClick('grid')" (click)="showDisplayClick('grid')"
          (focus)="gridListFocus($event, 'grid', 'top')" (focusout)="gridListFocusOut($event, 'grid', 'top')"
          [class]="gridSelectedStatus ? 'button-primary' : 'button-secondary'">
          <span *ngIf="gridSelectedStatus">
            <img src="assets/img/view-grid.svg" class="display-none group-hover:flex w-4" alt="">
            <img src="assets/img/view-grid-white.svg" class="group-hover:display-none w-4" alt="">
          </span>
          <span *ngIf="!gridSelectedStatus">
            <img src="assets/img/view-grid-white.svg" class="display-none group-hover:flex w-4" alt="">
            <img src="assets/img/view-grid.svg" class="group-hover:display-none w-4" alt="">
          </span>
          <span>GRID</span>
        </button>
        <button id="btn_list_top" tabindex="0"
          class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 -mr-[1px] m-0 text-sm group w-1/2"
          (keyup.enter)="showDisplayClick('list')" (click)="showDisplayClick('list')"
          (focus)="gridListFocus($event, 'list', 'top')" (focusout)="gridListFocusOut($event, 'list', 'top')"
          [class]="listSelectedStatus ? 'button-primary' : 'button-secondary'">

          <span *ngIf="listSelectedStatus">
            <img src="assets/img/list-bulleted.svg" class="display-none group-hover:flex w-4" alt="">
            <img src="assets/img/list-bulleted-white.svg" class="group-hover:display-none w-4" alt="">
          </span>
          <span *ngIf="!listSelectedStatus">
            <img src="assets/img/list-bulleted-white.svg" class="display-none group-hover:flex w-4" alt="">
            <img src="assets/img/list-bulleted.svg" class="group-hover:display-none w-4" alt="">
          </span>
          <span class="ml-1">LIST</span>
        </button>
      </div>

      <div class="flex" [ngClass]="{'min-w-[167px]' : !imageMode}">
        <button *ngIf="imageMode" (click)="setImageMode('map')"
          class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/2"
          [ngClass]="{'button-primary' : imageMode === 'map', 'button-secondary' : imageMode === 'photo'}">
          <span>
            <img [src]="imageMode === 'map' ? 'assets/img/map-white.svg' : 'assets/img/map.svg'" class="flex w-4"
              alt="">
          </span>
          <span class="ml-1">
            Map
          </span>
        </button>
        <button *ngIf="imageMode" (click)="setImageMode('photo')"
          class="button-small min-w-[84px] lg:min-h-6 px-0 lg:px-0 m-0 text-sm group w-1/2"
          [ngClass]="{'button-primary' : imageMode === 'photo', 'button-secondary' : imageMode === 'map'}">
          <span>
            <img [src]="imageMode === 'photo' ? 'assets/img/photo-white.svg' : 'assets/img/photo.svg'" class="flex w-4"
              alt="">
          </span>
          <span class="ml-1">
            Photo
          </span>
        </button>
      </div>
    </div>
  </div>
</div>