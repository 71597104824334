import { Component, Input, OnInit } from '@angular/core';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycRequestBrochureService } from '../rcyc-request-brochure/rcyc-request-brochure.service';
import { RcycRequestBrochureRedesignService } from './rcyc-request-brochure-redesign.service';
import { Observable, concatMap, from } from 'rxjs';

@Component({
  selector: 'app-rcyc-req-brochure-redesign',
  templateUrl: './rcyc-req-brochure-redesign.component.html',
  styleUrls: ['./rcyc-req-brochure-redesign.component.css'],
})

export class RcycReqBrochureRedesignComponent implements OnInit {
  allBrochuredata: any = [];
  apiResponseA: any = null;

  constructor(
    private rcycCommonService: RcycCommonService,
    private requestBrochureService: RcycRequestBrochureService,
    private rcycRequestBrochureRedesignService: RcycRequestBrochureRedesignService,

  ) { }

  ngOnInit(): void {
    // this.getVoyageData();
    this.getHeaderData();
    this.getAllBrochureData();
    this.rcycCommonService.removeSessionData('regionlistDrupalData');
  }

  getHeaderData() {
    /****************** Section A********************/
    this.rcycRequestBrochureRedesignService.getHeadingData().subscribe(
      (res: any = []) => {
        if (res && res.length) {
          this.apiResponseA = res;
        }
      },
      err => {
        console.log('Section A Request Brochure Api error');
        console.log(err);
      }
    );
  }

  getAllBrochureData() {
    const brochureURLs: string[] = ['/api/v1/50-50/requestbrochure/a?_format=json','/api/v1/50-50/requestbrochure/b?_format=json', '/api/v1/50-50/requestbrochure/c?_format=json', '/api/v1/50-50/requestbrochure/d?_format=json', '/api/v1/50-50/requestbrochure/e?_format=json', '/api/v1/50-50/requestbrochure/f?_format=json'];
    
    from(brochureURLs)
      .pipe(
        concatMap(url => this.rcycRequestBrochureRedesignService.getDrupalDataFor5050(url))
      )
      .subscribe((response: any) => {
        if (response && response.length > 0) {
          this.allBrochuredata = this.allBrochuredata.concat(response);
        }
      });
  }

  /**
  * Get departure date and region data from cache
  */
  // getVoyageData() {
  //   let voyageListArray: any[] = [];
  //   let distinctRegions: any[] = [];
  //   let resetRegionAndDepDatesData: boolean = false;
  //   let cruiseRegionsAndDepartureDatesData = JSON.parse(sessionStorage.getItem("CruiseRegionsAndDepartureDatesData"));
  //   if (cruiseRegionsAndDepartureDatesData) {
  //     if (this.rcycCommonService.getDateDifference(cruiseRegionsAndDepartureDatesData.expireDate)) {
  //       voyageListArray = cruiseRegionsAndDepartureDatesData.data;
  //       distinctRegions = Array.from(new Set(voyageListArray.map(voyage => voyage.voyageRegionExpansion)));
  //       resetRegionAndDepDatesData = false;
  //     } else {
  //       resetRegionAndDepDatesData = true;
  //     }
  //   } else {
  //     resetRegionAndDepDatesData = true;
  //   }

  //   if (resetRegionAndDepDatesData) {
  //     // Reset cache and fetch data from API 
  //     sessionStorage.removeItem("CruiseRegionsAndDepartureDatesData");
  //     this.getVoyageDataFromAPI();
  //   }
  // }

  /**
   *  Get Iventory from Elatic API
   */
  // getVoyageDataFromAPI() {
  //   let geoCountryCode;
  //   let geoApiResponse: any= {};
  //   let yachtIds;
  //   if (!geoApiResponse.country_code) {
  //     geoCountryCode = "US";
  //   } else {
  //     geoCountryCode = geoApiResponse.country_code;
  //   }
  //   let apiUrl_C = "/api/criteria-result";
  //   const param1 = {
  //     countryCode: geoCountryCode,
  //     yachtIds: yachtIds
  //   };

  //   this.requestBrochureService.postElastic(param1, apiUrl_C).subscribe((response: any) => {
  //     if (response) {
  //       /**
  //        * Set region and departure date data cache
  //        */
  //       let todayTime: any = new Date();
  //       sessionStorage.setItem("CruiseRegionsAndDepartureDatesData", JSON.stringify({ data: response.data.voyages, expireDate: todayTime }));
  //       this.getVoyageData();
  //     }
  //   });
  // }
}
