import { Injectable } from '@angular/core';

import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';

@Injectable()
export class RcycCountryPhonenoService {

  country_no: any;
  UserDetails: any;
  continent_code: any;
  cntrycode: any;

  apiUrlB: any;
  apiResponseBTemp: any;
  region: any;

  constructor(
    private rcycCommonService: RcycCommonService,
    private rcycInternalApisService: RcycInternalApisService,
    private _SharedServiceService: SharedServiceService,
  ) {

    // this.userDetails();
   }

  /* =====================================================================================================================================
          Function to get User's Location details
      ================================================================================================================================= */
      userDetails() {

        if (!localStorage.getItem('UserDetails')) {

          this.rcycCommonService.getUser_Details().subscribe(
            response => {

              // let temp_data;
              this.UserDetails = response;

              this.rcycCommonService.setData('UserDetails', this.UserDetails);
              this.continent_code = this.UserDetails.continent_code;
              this.rcycCommonService.setcountryCode(this.UserDetails.country_code);              
              return this.county_callno(this.UserDetails);
            },
            err => {

              this.UserDetails.continent_code = 'NA';
              this.continent_code = 'NA';
              this.UserDetails.country_code = 'US';
              this.rcycCommonService.setData('UserDetails', this.UserDetails);
              this.rcycCommonService.setcountryCode(this.UserDetails.country_code);
              return this.county_callno(this.UserDetails);
            });
        } else {

          this.UserDetails = this.rcycCommonService.getData('UserDetails');
          this.continent_code = this.UserDetails.continent_code;
          this.rcycCommonService.setcountryCode(this.UserDetails.country_code);
          this.rcycCommonService.expireLocation();
          return this.county_callno(this.UserDetails);

        }
      }


      /* =======================================================
          Function to get county_callno
          ====================================================== */

      county_callno(param) {

        if (param.country_code) {
          this.cntrycode = param.country_code;
        }

        this._SharedServiceService.getCountryphone(this.cntrycode).subscribe(res => {
          this.country_no = res.title;
          this.region = res.field_call_center_region;
          this.rcycCommonService.changeCountryPhoneNo(this.country_no);
        });

        // this.apiUrlB = '/api/v1/countryphone/' + this.cntrycode + '?_format=json';
        // this.rcycInternalApisService.getDrupal(this.apiUrlB).subscribe(
        //   response => {

        //     this.apiResponseBTemp = response;
        //     if (this.apiResponseBTemp && this.apiResponseBTemp.length) {

        //       const res = Object.keys(response).map(function (key) {
        //         return response[key];
        //       });
        //       if (res.length) {
        //         this.country_no = res[0].title;
        //         this.region = res[0].field_call_center_region;
        //         // return this.country_no;
        //         // this.assignCountryPhoneNo(this.country_no);
        //         this.rcycCommonService.changeCountryPhoneNo(this.country_no);
        //       } else {
        //         this.country_no = '+356 2778 0076';
        //         // return this.country_no;
        //         // this.assignCountryPhoneNo(this.country_no);
        //         this.rcycCommonService.changeCountryPhoneNo(this.country_no);
        //       }
        //     } else {
        //       this.country_no = '+356 2778 0076';
        //       // return this.country_no;
        //       // this.assignCountryPhoneNo(this.country_no);
        //       this.rcycCommonService.changeCountryPhoneNo(this.country_no);
        //     }
        //   },
        //   err => {

        //     this.country_no = '+356 2778 0076';
        //     // return this.country_no;
        //     // this.assignCountryPhoneNo(this.country_no);
        //     this.rcycCommonService.changeCountryPhoneNo(this.country_no);
        //   }
        // );

      }

      /* =======================================================
        Function to get county_callno
        ====================================================== */

    assignCountryPhoneNo(param) {
      // this.rcycCommonService.countryPhoneNo.subscribe(countryPhoneNo => {
      //   this.countryPhoneNo = countryPhoneNo;

      // });
    }
}
