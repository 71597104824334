<div id="rcyc-page-milestones" class="header_view bodyContentOuter">

	<!-- Section 1-->
	
	<app-rcyc-hero-module *ngIf="response_B_status" [responseArray]="responseArray"></app-rcyc-hero-module>

	<!-- Section 2  Year & Month Slider-->

	<section class="mt60 section-2">
		<div class="l-container-wrapper">
			<div class="l-container">
				<div class="row is-12 carousel_1">

					<h2 *ngIf="response_C_status && response_C?.title" id="mainTitleId" class="page-title" [title]="response_C?.title | convertHtml"
					 [innerHtml]="response_C?.title | limitTo: 75"></h2>

					<div *ngIf="response_D_status" class="column is-2-2-2-2-2">
						<ngu-carousel id="nguCarouselId_1" #milestoneTimelineCarousel [inputs]="carouselOne" (carouselLoad)="myfunc($event)" [dataSource]="response_D">

							<!-- <button id="bannerLeftNavId_D" NguCarouselPrev class="lrArrow_carousel leftRs" tabindex="0">
								<img src="assets/components/images/rcyc-milestones/arrow---left.png">
							</button> -->

							<ngu-item NguCarouselItem *nguCarouselDef="let item; let i= index;" id="nguCarouselItemId_{{i+1}}">
								<div class="feature-block flex-item">
									<div class="carousel_1_yearOuter" [ngClass]="{'leftBorder': item.yearStatus, 'rightBorder': i+1 == response_D.length}">
										<h4 *ngIf="item?.field_milestones_year" id="titleId_D_{{i+1}}" class="year" [title]="item?.field_milestones_year | convertHtml"
										 [innerHtml]="item?.field_milestones_year"></h4>
									</div>
									<a href="javascript:void(0)" id="milestoneId_{{i}}" (click)="yearMonthClick(item)" title="{{item?.field_milestones_month | convertHtml}} / {{item?.field_milestones_year | convertHtml}}" (focus)="onMilestoneItemsFocus($event)" style="display:block;" tabindex="0">
										<div class="carousel_1_contentOuter" [ngClass]="{'rightBorder': i+1 == response_D.length , 'is-active': selectedYearMonthTab == item?.field_milestones_year+'-'+item?.field_milestones_month}" id='{{selectedYearMonthTab + "_" + i}}'>
											<h4 *ngIf="item?.field_milestones_month" id="fieldSubHeaderId_D_{{i+1}}" class="month" [innerHtml]="item?.field_milestones_month"></h4>
											<p *ngIf="item?.body" id="descriptionId_D_{{i+1}}" [innerHtml]="item?.body"></p>
										</div>
									</a>
								</div>
							</ngu-item>
							<!-- <button id="bannerRightNavId_D" NguCarouselNext class="lrArrow_carousel rightRs" tabindex="0">
								<img src="assets/components/images/rcyc-milestones/arrow---right.png">
							</button> -->

							<button id="bannerLeftNavId_D" NguCarouselPrev *ngIf="response_D.length > 1" class="lrArrow_carousel leftRs hideMileStonePrevBtn" tabindex="{{leftBtnTabindex}}"
								(click)="leftbtnClick($event)"
								[style.display]="((milestoneTimelineCarousel.isFirst) || (milestoneTimelineCarousel.isFirst === 'undefined') || (milestoneTimelineCarousel.isFirst === 'null')) ? none:block"
								[style.visibility]="((milestoneTimelineCarousel.isFirst) || (milestoneTimelineCarousel.isFirst === 'undefined') || (milestoneTimelineCarousel.isFirst === 'null')) ? hidden:visible"
								(focus)="timelineBtnFocus($event, 'left')" (focusout)="timelineBtnFocusOut($event, 'left')"
								(mouseenter)="timelineBtnFocus($event, 'left')" (mouseleave)="timelineBtnFocusOut($event, 'left')"
								title="Scroll left">
									<img src="{{bannerLeftNavId_DImageUrl}}" alt="Scroll left">
								</button>
								<button id="bannerRightNavId_D" NguCarouselNext *ngIf="response_D.length > 1" class="lrArrow_carousel rightRs" tabindex="0"
									[style.display]="milestoneTimelineCarousel?.isLast ? none: block"
									[style.visibility]="milestoneTimelineCarousel?.isLast ? hidden: visible"
									(click)="rightbtnClick($event)"
									(focus)="timelineBtnFocus($event, 'right')" (focusout)="timelineBtnFocusOut($event, 'right')"
									(mouseenter)="timelineBtnFocus($event, 'right')" (mouseleave)="timelineBtnFocusOut($event, 'right')"
									title="Scroll right">
										<img src="{{bannerRightNavId_DImageUrl}}" alt="Scroll right">
								</button>

						</ngu-carousel>
					</div>
					</div>
			</div>
		</div>
	</section>

	<!-- Section 3-->
	<section class="mt60 section-3">
		<div class="l-container-wrapper type2">
			<div class="l-container">

				<div *ngIf="response_E_status">
					<h4 id="monthId_E" class="monthYear" title="{{response_E?.field_milestones_month | convertHtml}} / {{response_E?.field_milestones_year | convertHtml}}"
					 innerHtml="{{response_E?.field_milestones_month | limitTo: 30 }} / {{response_E?.field_milestones_year | convertHtml | limitTo: 50}}"></h4>

					<h2 id="yearId_E" class="section-title" [title]="response_E?.title | convertHtml" [innerHtml]="response_E?.title"></h2>


					<div class="row is-12 is-6-6">
						<div class="column is-6">
							<!-- <img id="imageId_E" [title]="response_E?.field_image_title_content | convertHtml" [alt]="response_E?.field_image_alt_content | convertHtml"
							 [src]="response_E?.field_images"
							 class="feature-block__image p04_img"> -->
							 <div *ngIf="response_F_status" class="p02_A_01  p02_photo-tour p02_A photosDisplay" data-blocks-uuid="ae97bec4-e9f5-4805-a848-fbcc40e94309">
								<app-rcyc-ngu-carousel *ngIf="response_F_status" [portData]='carouselArray'></app-rcyc-ngu-carousel>
							</div>


						</div>
						<div class="column is-6">
							<h4 *ngIf="response_E?.field_sub_header" id="titleId_E" [title]="response_E?.field_sub_header | convertHtml"
							 [innerHtml]="response_E?.field_sub_header | convertHtml | limitTo: 50"></h4>
							<p *ngIf="response_E?.field_summery" id="descriptionId_E" class="textContent" [innerHtml]="response_E?.field_summery"></p>
						</div>
					</div>
				</div>

			</div>
		</div>
	</section>
</div>
