<ng-container>
    <span *ngIf="currencyCode === 'USM'">
        <span *ngIf="(voyageBasicData?.startingPrice % 1) === 0">
            {{voyagePrice | currency:'USD':'symbol':'1.0-0'}}
        </span>
        <span *ngIf="(voyageBasicData?.startingPrice % 1) !== 0">
            {{voyagePrice | currency:'USD':'symbol':'1.2-2'}}
        </span>
        <span *ngIf="showCurrency">&nbsp; USD*</span>
    </span>
    <span *ngIf="currencyCode !== 'USM'">
        <span *ngIf="(voyageBasicData?.startingPrice % 1) === 0">
            {{voyagePrice | currency:currencyCode:'symbol':'1.0-0'}}
        </span>
        <span *ngIf="(voyageBasicData?.startingPrice % 1) !== 0">
            {{voyagePrice | currency:currencyCode:'symbol':'1.2-2'}}
        </span>
        <span *ngIf="showCurrency">{{currencyCode}}*</span>
    </span>
</ng-container>