<div [class]="variant === 'light' ? 'text-white' : 'text-primary'">

  <ng-container *ngIf="data.voyageRegion">
    <div class="text-sm uppercase mb-3 lg:mb-[24px] brochure-content" *ngIf="data.eyebrow">
      {{data.eyebrow}}
    </div>
    <h2 *ngIf="heading === 'h2'"
      class="text-[23px] lg:text-[31px] mb-5 lg:mb-4 leading-[33px] lg:leading-[36px] lg:[&+.field-content]:-mt-2"
      [class]="variant === 'light' ? 'text-white' : 'text-primary'">
      {{data.title | convertHtml}}
    </h2>
  </ng-container>
  <ng-container *ngIf="!data.voyageRegion">
    <ng-container *ngIf="data?.header_variant == 'full_image'">
      <div class="mb-[20px] lg:mb-[48px]">
        <span *ngIf="data?.subHeader" class="uppercase text-sm/[14px]">{{data?.subHeader}}</span>
        <div *ngIf="data?.title" class="mt-6 lg:flex">
          <h2 *ngIf="heading === 'h2'"
            class=" text-[29px] lg:text-[49px] !mb-0  leading-[33px] lg:leading-[56px] lg:[&+.field-content]:-mt-2"
            [class]="variant === 'light' ? 'text-white' : 'text-primary'"
            [ngClass]="data?.description ? 'lg:w-1/2' : 'lg:w-full'">
            {{data?.title | convertHtml}}
          </h2>
          <span *ngIf="data?.description" class="leading-6 lg:w-1/2">
            {{data?.description}}
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data?.header_variant != 'full_image'">
      <div class="text-sm uppercase mb-4 lg:mb-[18px]" *ngIf="data.eyebrow">
        {{data.eyebrow}}
      </div>
      <h2 *ngIf="heading === 'h2'"
        class=" lg:[&+.field-content]:-mt-2"
        [class]="variant === 'light' ? 'text-white' : 'text-primary'" [ngClass]="isFAQ? 'mt-6 text-[23px] lg:text-[31px]':(sectionVariant === 'blue_background_variant'? 'text-[23px] lg:text-[31px] mb-5 lg:mb-[48px] leading-[26.45px] lg:leading-[35.65px]': (data?.description && pageName==='home')?'text-[29px] lg:text-[49px] leading-[33px] lg:leading-[56px]' : 'text-[29px] lg:text-[49px] mb-9 lg:mb-[30px] leading-[33px] lg:leading-[56px]' )">
        {{data.title | convertHtml}}
      </h2>
      <h3 *ngIf="heading === 'h3' && pageName!=='thankyou'" class="text-[26px] lg:text-[39px] uppercase mt-2 mb-5 lg:mb-6 lg:bt-4"
        [class]="variant === 'light' ? 'text-white' : 'text-primary'">
        {{data.title | convertHtml}}
      </h3>
      <h3 *ngIf="heading === 'h3' && pageName==='thankyou'" 
        class="text-primary text-[26px] lg:text-[39px] uppercase mt-6 lg:leading-[48px] leading-[32px] !mb-0">
        {{data.title | convertHtml}}
      </h3>
      <div *ngIf="pageName==='thankyou' && data.reference_link" class="pt-[8px] pb-[16px] lg:pb-[30px]">
        <a href="data.reference_link" class="text-[16px] font-proxima-nova leading-[24px]">Reference No. 4329913</a>
      </div>
      <span *ngIf="data?.description && pageName==='home'" class="leading-6 lg:w-1/2">
        {{data.description}}
      </span>
    </ng-container>
  </ng-container>
  <div *ngIf="data.text && pageName!=='thankyou'" class="mb-6 lg:mb-[48px] text-[16px] font-normal tracking-[0.192px] leading-[165%] font-['Proxima Nova']"
    [ngClass]="{'text-content--light' : variant === 'light', 'sm:min-h-full min-h-36' : sliderVariant === 'barSmall'}"
    [innerHTML]="data.text"></div>
  <div *ngIf="data.text && pageName==='thankyou'">
    <h3 class="mt-6 h6 font-adobe-caslon-pro text-[20px] leading-[32px]" [innerHTML]="data.text"></h3>
  </div>

  <div *ngIf="data.cta" class="leading-6 uppercase"
    [class]="variant === 'light' ? '[&>a]:text-white' : '[&>a]:text-primary'">
    <a [href]="actionUrl" *ngIf="actionUrl" class="link-cta">
      {{data.cta.title}}
    </a>
    <a [routerLink]="data.cta.route" *ngIf="data.cta.route" class="link-cta">
      {{data.cta.title}}
    </a>
  </div>
</div>