import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './rcyc-components/rcyc-home/rcyc-home.component';
import { RcycStarProgramComponent } from './rcyc-components/rcyc-star-program/rcyc-star-program.component';
import { LoginCheckGuard } from './rcyc-authguard/rcyc-loginCheckGuard';
import { AuthGuard } from './rcyc-authguard/rcyc-authguard';
import { ComplexUrlMatchers1, ComplexUrlMatchers2 } from './complexUrlMatcher';
import { REDIRECTED_ROUTES } from "./rcyc-constants/rcyc-redirected-routes";

const appRoutes: Routes = [

  { path: '', component: HomeComponent, pathMatch: 'full' },
  ...REDIRECTED_ROUTES,
  { path: 'home', component: HomeComponent, pathMatch: 'full' },
  { path: 'starsprogram', component: RcycStarProgramComponent, pathMatch: 'full' },
  { path: 'travel-agent/registration', loadChildren: () => import('./rcyc-components/rcyc-travel/rcyc-travel-registration/rcyc-travel-registration.module').then(m => m.RcycTaRegistrationPageModule), canActivate: [LoginCheckGuard] },
  { path: 'travel-agent/login', loadChildren: () => import('./rcyc-components/rcyc-travel/rcyc-ta-login-page/rcyc-ta-login-page.module').then(m => m.RcycTaLoginPageModule), canActivate: [LoginCheckGuard] },
  { path: 'guest/dashboard', loadChildren: () => import('./rcyc-components/rcyc-guest/rcyc-dashboard/rcyc-dashboard.module').then(m => m.RcycDashboardModule), pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'travel-agent/dashboard', loadChildren: () => import('./rcyc-components/rcyc-travel/rcyc-travel-dashboard/rcyc-travel-dashboard.module').then(m => m.RcycTravelDashboardModule), pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'registration', loadChildren: () => import('./rcyc-components/rcyc-guest/rcyc-registration/rcyc-registration.module').then(m => m.RcycRegistrationModule), pathMatch: 'full', canActivate: [LoginCheckGuard] },
  { matcher: ComplexUrlMatchers1, loadChildren: () => import('./rcyc-components/rcyc-voyages/rcyc-voyages.module').then(m => m.RcycVoyagesModule) },
  { matcher: ComplexUrlMatchers2, loadChildren: () => import('./rcyc-components/rcyc-voyages/rcyc-voyages.module').then(m => m.RcycVoyagesModule) },
  { path: '', loadChildren: () => import('./feature/feature.module').then(m => m.FeatureModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule { }
