import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable()
export class RcycHomeService {

  API_BASE_URL: any = AppConfig.API_BASE_URL;
  ELASTIC_API_BASE_URL: any = AppConfig.ELASTIC_API_BASE_URL;

  constructor(

    private http: HttpClient
  ) {

  }

  /* =========================================================================================
    Funtion to call All Apis for Home
	* ==========================================================================================*/

  home(apiUrl: string) {

    const url = this.API_BASE_URL + apiUrl;
    return this.http.get(url);
  }


  /* ======================================================================================
    Funtion to call Find A Cruise Apis for Home
	* =======================================================================================*/
  find_cruise(apiUrl: string, region) {
    const url = this.ELASTIC_API_BASE_URL + apiUrl;
    let body: any;
    if (region) {
      body = region;
    } else {
      body = {
        yachtSearchDTO: ''
      };
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(url, { headers: headers, params: body });
  }

  get_voyage(voyageId, CountryCode = 'US') {
    const url = this.ELASTIC_API_BASE_URL + '/api/voyage?voyageId=' + voyageId + '&countryCode=' + CountryCode;
    return this.http.get(url);
  }

}
