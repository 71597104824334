<div id="rcyc-page-home" class="bodyContentOuter">

	<!-- Banner Image -->
  <app-rcyc-hero-module *ngIf="apiResponseAStatus && apiResponseA.length > 0" [responseArray]="apiResponseA" [pageName]="'home'"></app-rcyc-hero-module>
  <div *ngIf="!(apiResponseAStatus && apiResponseA.length>0)" style="height: 33.33vw;"></div> <!--html fix to avoid large layout shift-->
	<!-- Find a cruise starts -->

  <section *ngIf="totaldata" class="lg:container relative z-[1]">
    <button type="button"
            class="left-0 right-0 mx-auto button-primary button-primary--light m-auto absolute bottom-full mb-[72px] w-[calc(100%-32px)] -translate-x-1/2 lg:display-none"
            (click)="(scrollDown())">
            Find a voyage
    </button>

    <div class="
      md:flex md:gap-[20px] lg:gap-[36px] py-[40px] px-[24px] lg:absolute lg:bottom-full lg:mb-16 lg:-translate-x-2/4
      text-primary lg:max-w-[1248px] lg:p-2 lg:w-[calc(100%-48px)] lg:bg-white/40 backdrop-blur-xl"
         id="findacruise">
      <div class="basic-form_group md:w-1/3 m-0">
        <div id="regionSelectId" class="basic-form_input w-full">
          <span class="region_title flex cursor-pointer border-b hover:border-dark-blue-1 border-solid border-primary text-primary group">
            <span class="regionDD uppercase flex justify-between leading-none py-[20px] group-hover:text-dark-blue-1 tracking-wide"
                  tabindex="{{regionDDTabIndex}}"
                  (keyup.enter)="clickout($event)"
                  (keyup)="onRegionFocus($event)"
                  (focusout)="onRegionFocusOut($event)">
              <div>
                <span *ngIf="slct_regionname.length==0" class="">Destinations</span>
                <span *ngIf="slct_regionname.length>0" class="">{{slct_regionname[0]}}</span>
              </div>
              <div class="flex gap-[25px] md:gap-[15px]">
                <span *ngIf="slct_regionname.length>1" id="regionDropdownRegionCount"> +{{slct_regionname.length-1}}</span>
                <span class="icon-arrow-down text-primary group-hover:text-dark-blue-1" [ngClass]="(regionDropDownFlag)?'rotate-180':''" id="regionDropDownIcon"></span>
              </div>
            </span>
          </span>
          <ul class="regions" *ngIf="region && FindCruiseRegion.length && regionDropDownFlag" id="region_dropdown">
            <li *ngFor="let sarchregion of FindCruiseRegion">
              <input *ngIf="region" tabindex="0" (change)="filtervalue('region',sarchregion.travelRegion,$event,sarchregion.generalDescription)" type="checkbox"
                     [checked]="Checkotpionselect('region',sarchregion.travelRegion)" name="region" id="{{sarchregion.travelRegion}}"
                     value="{{sarchregion.travelRegion}}" class="findacruiseCheckbox"/>
              <span tabindex="-1" class="text-sm">{{sarchregion.generalDescription}}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="basic-form_group md:w-1/3 m-0">
        <div id="departureDateSelectId" class="basic-form_input w-full">
          <span class="region_title flex cursor-pointer border-b hover:border-dark-blue-1 border-solid border-primary text-primary group">
            <span class="departureDateDD uppercase flex justify-between leading-none py-[20px] group-hover:text-dark-blue-1 tracking-wide" tabindex="{{departureDateDDTabIndex}}" (keyup.enter)="clickout($event)" (keyup)="onDepartureDateFocus($event)" (focusout)="onDepartureDateFocusOut($event)">
              <div>
                <span *ngIf="slct_deptnname.length==0" class="">Dates</span>
                <span *ngIf="slct_deptnname.length>0" class="">{{slct_deptnname[0]}}</span>
              </div>

              <div class="flex gap-[25px] md:gap-[15px]">
                <span *ngIf="slct_deptnname.length>1" id="departureDropdownDateCount"> +{{slct_deptnname.length-1}}</span>
                <span class="icon-arrow-down text-primary group-hover:text-dark-blue-1" [ngClass]="(departureDateDropDownFlag)?'rotate-180':''" id="departureDateDropDownIcon"></span>
              </div>
            </span>
          </span>
          <ul class="regions" *ngIf="departure_date && FindCruiseDepdate.length && departureDateDropDownFlag" id="departure_dropdown">
            <li *ngFor="let sarchdepDate of FindCruiseDepdate">
              <input tabindex="0" (change)="filtervalue('departure_date',sarchdepDate,$event,sarchdepDate)" type="checkbox" name="departure_date"
                     [checked]="Checkotpionselect('departure_date',sarchdepDate)" value="{{sarchdepDate}}" class="findacruiseCheckbox"
              />
              <span tabindex="-1" class="text-sm">{{sarchdepDate}}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="basic-form_group md:w-1/3 m-0">
        <div id="durationValuesSelectId" class="basic-form_input w-full">
          <span class="region_title flex cursor-pointer border-b hover:border-dark-blue-1 border-solid border-primary text-primary group">
            <span class="durationValuesDD w-full uppercase flex justify-between leading-none py-[20px] group-hover:text-dark-blue-1 tracking-wide" tabindex="{{durationValuesDDTabIndex}}" (keyup.enter)="clickout($event)" (keyup)="onDurationFocus($event)" (focusout)="onDurationFocusOut($event)">
              <div>
                <span *ngIf="slct_durationnname.length==0" id="durationDropdownLabel" class="">Duration</span>
                <span *ngIf="slct_durationnname.length>0" id="durationDropdownDurationName" class="">{{slct_durationnname[0]}}</span>
              </div>
              <div class="flex gap-[25px] md:gap-[15px]">
                <span *ngIf="slct_durationnname.length>1" id="durationDropdownDurationCount"> +{{slct_durationnname.length-1}}</span>
                <span  id="durationDropdownIcon" class="icon-arrow-down text-primary group-hover:text-dark-blue-1" [ngClass]="(durationDropDownFlag)?'rotate-180':''"></span>
              </div>
            </span>
          </span>
          <div class="dropdown durationdrobdwn">
            <ul class="regions durationdropdownul" *ngIf="durationValues && FindCruisedurationValues.length && durationDropDownFlag" id="durationValues_dropdown">
              <li *ngFor="let sarchdurationValues of FindCruisedurationValues">
                <input tabindex="0" (change)="filtervalue('durationValues',sarchdurationValues.duration,$event,sarchdurationValues.description)" type="checkbox"
                       [checked]="Checkotpionselect('durationValues',sarchdurationValues)"
                       value="{{sarchdurationValues.duration}}"  id="{{sarchdurationValues}}" class="findacruiseCheckbox"/>
                <span tabindex="-1" class="text-sm">{{sarchdurationValues.description }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <input id="findACruiseBtnId" type="submit" value="Find a voyage" (click)="find_a_cruise()"
             class="button-primary mx-0 mt-2 md:mt-0 md:w-[150px] lg:w-[184px]"
             tabindex="0"
             title="Find a voyage" alt="Find a voyage" (focus)="clickout($event)">
    </div>
	</section>
	<!-- Find a cruise Ends -->

  <!-- Destinations -->
  <app-rcyc-destinations *ngIf="apiResponseATilesStatus" [data]="apiResponseATiles"></app-rcyc-destinations>

  <!-- Content Slider -->
  <div class="py-10 lg:py-16">
  <app-rcyc-slider
    *ngIf="apiResponseBStatus"
    variant="barSmall"
    id="home-page-content-slider"
    [data]="{
      topContent: {
        title: apiResponseB[0]?.title | convertHtml,
        eyebrow: apiResponseB[0]?.field_com_eyebrow,
      },
      items: apiResponseB
    }"
  ></app-rcyc-slider>
</div>
<div class="py-10 lg:py-16">
  <app-rcyc-50-50 *ngIf="apiResponseCStatus" [data]="apiResponseC"></app-rcyc-50-50>
</div>

  <!-- Slider Featured -->
  <div class="py-10 lg:py-16">
  <app-rcyc-slider
    variant="featured"
    [additionalCostInfo]="additionalCostInfo"
    id="home-featured-slider"
    *ngIf="apiResponseDStatus && apiResponseD"
    [data]="{
        topContent: {
          eyebrow: apiResponseD[0]?.field_com_eyebrow,
          title: apiResponseD[0]?.title | convertHtml,
        },
        items: apiResponseD
      }"
  ></app-rcyc-slider>
</div>

    <!-- Slider Editorial -->
    <div class="py-10 lg:py-16">
    <app-rcyc-slider
      variant="editorial"
      id="home-editorial-slider"
      *ngIf="apiResponseEStatus"
      [data]="{
        topContent: {
          eyebrow: apiResponseE[0]?.field_com_eyebrow,
          title: apiResponseE[0]?.title | convertHtml,
        },
        items: apiResponseE
      }"
    ></app-rcyc-slider>
  </div>
</div>