import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RcycOnboardDiningModalComponent } from '../../rcyc-onboard-dining-modal/rcyc-onboard-dining-modal.component';
import { RcycInternalApisService } from '../../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';

@Component({
  selector: 'app-rcyc-offer-card',
  templateUrl: './rcyc-offer.component.html',
})

export class RcycOfferComponent implements OnInit {
  @Input() data: any;
  @Input() variant: any;
  @Input() index: number;
  @Input() pageName: string;
  @Output() clearCompareValueEmitter = new EventEmitter<string>();
  @ViewChild('onboardDiningModal', { static: false }) onboardDiningModal: RcycOnboardDiningModalComponent;

  detailsUrl: any;
  highlight: any;
  imagesReady: any = false;
  ctaURL: string = '';

  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlKey_1: any;
  currentUrlKey_2: any;
  isDining: boolean;
  isOnboardLanding: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycCommonService: RcycCommonService,
  ) {
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    if (this.currentUrlPathAsArray && this.currentUrlPathAsArray.length > 0) {
      this.currentUrlKey_1 = this.currentUrlPathAsArray[1] ? this.currentUrlPathAsArray[1]?.path : '';
      this.currentUrlKey_2 = this.currentUrlPathAsArray[2] ? this.currentUrlPathAsArray[2]?.path : '';
    }
  }

  ngOnInit(): void {
    let voyageEmbarkPort_new;
    let voyageDisembarkPort_new;
    if (this.data?.voyageEmbarkPort) {
      voyageEmbarkPort_new = this.data.voyageEmbarkPort
        .replace(/[^a-zA-Z-]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
    }
    if (this.data?.voyageDisembarkPort) {
      voyageDisembarkPort_new = this.data.voyageDisembarkPort
        .replace(/[^a-zA-Z-]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
    }

    this.detailsUrl = '/' + this.data?.voyageUrlPath + '/' + voyageEmbarkPort_new + '-to-' + voyageDisembarkPort_new + '-' + this.data?.voyageId

    this.ctaURL = this.data?.field_com_cta_link ? this.data?.field_com_cta_link : this.data?.field_url_key;

    this.data.field_image = this.data?.field_image ? this.data?.field_image : this.data?.field_tile_image;
    this.data.field_summary = this.data?.field_tile_details ? this.data?.field_tile_details : this.data?.field_summary;
    this.data.field_sub_header = this.data?.field_sub_header ? this.data?.field_sub_header : this.data?.field_com_eyebrow;

    if (this.ctaURL && this.ctaURL?.indexOf('internal:') !== -1) {
      this.ctaURL = this.ctaURL?.split(':')[1];
    }
    this.data.field_sub_header = (this.variant === 'amenities') ? this.data?.field_com_eyebrow : this.data?.field_sub_header;
    if (this.currentUrlKey_2 === "dining") {
      this.isDining = true;
    }
    if (this.currentUrlKey_1 === "evrima" || this.currentUrlKey_1 === "ilma" || this.currentUrlKey_1 === "luminara") {
      this.isOnboardLanding = true;
    }
  }

  modalOpen(data: any) {
    if (this.isDining === true) {
      this.getDiningRestData(data.title);
      this.onboardDiningModal.modalOpen({ yacht: this.currentUrlKey_1, dining: data.title, eyebrow: data.field_com_eyebrow });
    } else {
      if (this.variant === 'charters') {
        window.location.href = this.ctaURL
      } else {
        this.router.navigate([this.ctaURL]);
      }
    }
  }

  getDiningRestData(diningMetaData: any) {
    const apiUrl = "/api/v1/restaurant/" + this.currentUrlKey_1?.toLowerCase() + "/" + diningMetaData?.toLowerCase() + "?_format=json";
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (res: any = []) => {
        if (res && res.length) {
          let tempDiningData: any = {};
          tempDiningData.imageSliderData = res[0].field_images
            .split(",")
            .map((img) => {
              return { field_tile_image: img?.trim() };
            });
          tempDiningData.headerTableData = [
            {
              header: res[0].field_block_title_1,
              body: res[0].field_block_detail_1,
            },
            {
              header: res[0].field_block_title_2,
              body: res[0].field_block_detail_2,
            },
            {
              header: res[0].field_block_title_3,
              body: res[0].field_block_detail_3,
            },
          ];
          tempDiningData.accordionData = res.map((rest, i) => {
            return {
              body: rest.body,
              field_tile_title: rest.field_com_eyebrow,
              field_sort_order: +rest.field_sort_order,
            };
          });
          tempDiningData.accordionData.splice(0, 1);

        }
      },
      (err) => {
        console.log(err, "error while getting restaurant dining res");
      }
    );
  }
  clearCompareValue(url: string) {
    this.clearCompareValueEmitter.emit(url);
  }
}
