<div class="flex items-center justify-between">
    <div class="flex flex-1 justify-end min-w-[72px]">
        <div (click)="previousPage()"
            class="cursor-pointer text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']  flex items-center uppercase">
            <img src="assets/img/arrow_left_small.svg" alt="Previous">
            Previous
        </div>
    </div>

    <div class="sm:flex sm:flex-1 sm:items-center sm:justify-center">
        <div>
            <nav class="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">
                <!-- Display left dots if current page is greater than a certain number -->
                <span *ngIf="showLeftElipses"
                    class="cursor-pointer text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova'] text-[#1C1C1C] items-center ">...</span>

                <!-- Loop through the pages and highlight the current page -->
                <span *ngFor="let page of visiblePages" (click)="goToPage(page)" [ngClass]="{
                      'text-[#1C1C1C69]': currentPage === page,
                      'underline text-[#1C1C1C]': currentPage !== page
                 }"
                    class="cursor-pointer text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova'] items-center lg:px-4 px-2">
                    {{ page }}
                </span>

                <!-- Display right dots if total pages exceed a certain number -->
                <span *ngIf="showRightElipses"
                    class="text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova'] items-center ">...</span>
            </nav>
        </div>
    </div>

    <div class="flex flex-1 justify-start min-w-[53px]">
        <div (click)="nextPage()"
            class="cursor-pointer text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova'] text-[#1C1C1C] flex items-center uppercase">
            Next
            <img src="assets/img/arrow_right_small.svg" alt="Next">
        </div>
    </div>
</div>