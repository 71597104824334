import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { RcycCommonService } from '../rcyc-common/rcyc-common.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { ResetStore } from '../../store/actions/rcyc-reservation.action';

@Injectable()
export class RcycCommonLogoutService {
  API_BASE_URL_RESERVATION: any = AppConfig.API_BASE_URL_RESERVATION;
  constructor(
    private router: Router,
    private rcycCommonService: RcycCommonService,
    private http: HttpClient,
    private store: Store<fromStore.UserLoggedState>
  ) {

  }

  /* =======================================================================
      Function to call logout function
    ==================================================================== */
  logout() {
    this.store.dispatch(new ResetStore());

    if (localStorage.getItem('loggedUserDetails')) {

      const userDetails = JSON.parse(localStorage.getItem('loggedUserDetails'));
      localStorage.removeItem('loggedUserDetails');

      this.rcycCommonService.setLoggedInStatus(true);
      // change changeCurrentUserName
      this.rcycCommonService.changeCurrentUser('currentUserName');
      this.rcycCommonService.setSessionExpiredStatus(false);
      this.router.navigate(['/signout']);

    } else {

      // this.rcycCommonService.setLoggedInStatus(true);

      // change changeCurrentUserName
      this.rcycCommonService.changeCurrentUser('currentUserName');
      this.rcycCommonService.setSessionExpiredStatus(false);
      this.router.navigate(['/signout']);
    }
  }


  /* =======================================================================
      Function to call logout function
    ==================================================================== */
    autoLogout() {

      if (localStorage.getItem('loggedUserDetails')) {

        const userDetails = JSON.parse(localStorage.getItem('loggedUserDetails'));
        const userType = userDetails.type;
        localStorage.removeItem('loggedUserDetails');

        // localStorage.setItem('loggedUserDetails', stringifiedLoginData);
        this.rcycCommonService.setLoggedInStatus(true);

        // change changeCurrentUserName
        this.rcycCommonService.changeCurrentUser('currentUserName');
        // this.loggedGuestStatus = false;


        switch (userType) {
          case 'TA':
              // this.router.navigate(['/travel-agent/login']);
              this.rcycCommonService.setSessionExpiredStatus(true);
              this.router.navigate(['/travelprofessionals']);
          break;
          case 'RA':
              // this.router.navigate(['/travel-agent/login']);
              this.rcycCommonService.setSessionExpiredStatus(true);
              this.router.navigate(['/reservation-agent/login']);
          break;
          default:
              this.rcycCommonService.setSessionExpiredStatus(true);
              this.router.navigate(['/login']);
          break;
        }

      } else {

        // localStorage.setItem('loggedUserDetails', stringifiedLoginData);
        // this.rcycCommonService.setLoggedInStatus(true);

        // change changeCurrentUserName
        this.rcycCommonService.changeCurrentUser('currentUserName');
        // this.loggedGuestStatus = false;

        this.rcycCommonService.setSessionExpiredStatus(false);

        this.router.navigate(['/signout']);
      }



    }

    checkExpiry(){
      let userData:any=localStorage.getItem('loggedUserDetails');
      const userDetails = JSON.parse(userData);
      let noCheck=true;
      if(userDetails){
        const userType = userDetails.type;
        let url;

        switch (userType) {
          case 'TA':
          // this.router.navigate(['/travel-agent/login']);
          this.rcycCommonService.setSessionExpiredStatus(true);
          url = this.API_BASE_URL_RESERVATION + '/api/checkJWTtoken';
          break;
          case 'G':
          // this.router.navigate(['/travel-agent/login']);
          this.rcycCommonService.setSessionExpiredStatus(true);
          url = this.API_BASE_URL_RESERVATION + '/api/checkJWTtoken';
          break;
          default:
          noCheck=false;
          break;
        }
        if (userDetails && userDetails.jwtToken) {

          const authToken = userDetails.jwtToken;
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json; charset=utf-8',
              'Accept': '*/*',
              'Authorization': `Bearer ${authToken}`
            })
          };
      if(noCheck){
        this.http.post(url, {}, httpOptions).subscribe(
          (response:any)=>{
            if(response&&response.code){
              if(response.code==200){
                if(!response.data.tokenValid){
                  localStorage.removeItem('loggedUserDetails');
                  this.rcycCommonService.setLoggedInStatus(true);
                  // change changeCurrentUserName
                  this.rcycCommonService.changeCurrentUser('currentUserName');
                  this.rcycCommonService.setSessionExpiredStatus(false);
                }
              }
            }
          });
        }
        } else {
          localStorage.removeItem('loggedUserDetails');
          this.rcycCommonService.setLoggedInStatus(true);
          // change changeCurrentUserName
          this.rcycCommonService.changeCurrentUser('currentUserName');
          this.rcycCommonService.setSessionExpiredStatus(false);
        }
      }
    }

}
