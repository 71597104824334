<div id="rcyc-login-header-new">
  
  <header class="row pageHeader">
    <div class="l-container-wrapper">
      <div *ngIf="toLoginHeaderData?.pageName =='myReservationGuestInfo'" class="l-container is-8-4">
        <h1 class="column is-8 pageHeaderTitle"> MY YACHT ACCOUNT </h1>
        <div  class="column is-4 returnSection">
          <span class="icon-arrow icon-arrow-left"></span>
          <a (click)="guestReturnDetailsClick()"> Return to Reservation {{toLoginHeaderData?.bookingCode}}</a>
        </div>
      </div>
      <div *ngIf="toLoginHeaderData?.pageName =='myReservation'" class="l-container row">
        <h1 class="pageHeaderTitle"> MY YACHT ACCOUNT </h1>        
      </div>
    </div>
  </header>
</div>
