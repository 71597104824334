import { Injectable } from '@angular/core';
import { AppConfig } from '../../rcyc-config/config';
import { HttpClient } from '@angular/common/http';
import { shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const CACHE_SIZE = 1;
@Injectable()
export class SharedServiceService {
  API_BASE_URL: any = AppConfig.API_BASE_URL;
  cacheCommonError$: Observable<any>;
  cacheCommonSuccess$: Observable<any>;
  cacheCommonInfo$: Observable<any>;
  cacheCountryPhone$: Observable<any>;
  cachedrupalPageToggleStatus$: Observable<any>;
  cacheCountryCode$: any = '';
  cachePCFIToggleInfo$: Observable<any>;
  cacheStaticImages$: Observable<any>;
  userType: any;
  cacheGdprRegionCode$: any;

  constructor(private http: HttpClient) {

  }
  /* ======================================================================================================================================
    Funtion to get drupal Static Images
  * ======================================================================================================================================*/
  public getStaticImages() {
    if (!this.cacheStaticImages$) {
      this.cacheStaticImages$ = this.requestStaticImages();
    }
    return this.cacheStaticImages$;
  }
  /* ======================================================================================================================================
    Funtion to get drupal error messages
  * ======================================================================================================================================*/
  public getErrorCommonMsg() {
    if (!this.cacheCommonError$) {
      this.cacheCommonError$ = this.requestErrorCommonMsg();
    }
    return this.cacheCommonError$;
  }
  /* ======================================================================================================================================
    Funtion to get drupal success messages
  * ======================================================================================================================================*/
  public getSuccessCommonMsg() {
    if (!this.cacheCommonSuccess$) {
      this.cacheCommonSuccess$ = this.requestSuccessCommonMsg();
    }
    return this.cacheCommonSuccess$;
  }
/* ======================================================================================================================================
    Funtion to get drupal information messages
  * ======================================================================================================================================*/
  public getInfoCommonMsg() {
    if (!this.cacheCommonInfo$) {
      this.cacheCommonInfo$ = this.requestInfoCommonMsg();
    }
    return this.cacheCommonInfo$;
  }
/* ======================================================================================================================================
    Funtion to get Country phone number
  * ======================================================================================================================================*/
  public getCountryphone(cntrycode) {

    if (this.cacheCountryCode$ != cntrycode) {
      this.cacheCountryCode$ = cntrycode;
      this.cacheCountryPhone$ = undefined;
    }
    if (!this.cacheCountryPhone$) {
      this.cacheCountryPhone$ = this.requestCountryphone(cntrycode);
    }
    return this.cacheCountryPhone$;
  }
/* ======================================================================================================================================
    Funtion to get drupal error messages
  * ======================================================================================================================================*/
 public getPageToggleStatus() {

  if (!this.cachedrupalPageToggleStatus$) {
    this.cachedrupalPageToggleStatus$ = this.requestDrupalPageToggle();
  }
  return this.cachedrupalPageToggleStatus$;
  }
  public getGdprRegionCode(countryCode, regionCode) {

    if (this.cacheCountryCode$ != countryCode) {
      this.cacheCountryCode$ = countryCode;
      this.cacheGdprRegionCode$ = undefined;
    }
    if (!this.cacheGdprRegionCode$) {
      this.cacheGdprRegionCode$ = this.requestGdprRegionCode(countryCode, regionCode);
    }
    return this.cacheGdprRegionCode$;
  }

  public getUserType(){
    return this.fetchUserType();
    // return this.userType;
  }

  public getPCFIToggleInfo() {
    if (!this.cachePCFIToggleInfo$) {
      this.cachePCFIToggleInfo$ = this.requestPCFIToggleInfo();
    }
    return this.cachePCFIToggleInfo$;
  }

  private requestStaticImages() {
    const url = this.API_BASE_URL + '/api/v1/edocimages?_format=json';
    return this.http.get(url).pipe(
      map(response => response[0]), shareReplay()
    );
  }

  public requestErrorCommonMsg() {
    const url = this.API_BASE_URL + '/api/v1/errormessages?_format=json';
    return this.http.get(url).pipe(
      map(response => response[0]), shareReplay()
    );
  }

  private requestSuccessCommonMsg() {
    const url = this.API_BASE_URL + '/api/v1/successmessages?_format=json';
    return this.http.get(url).pipe(
      map(response => response[0]), shareReplay()
    );
  }

  private requestInfoCommonMsg() {
    const url = this.API_BASE_URL + '/api/v1/infomessages?_format=json';
    return this.http.get(url).pipe(
      map(response => response[0]), shareReplay()
    );
  }

  private requestDrupalPageToggle() {

    const url = this.API_BASE_URL + '/api/v1/featuretoggle?_format=json';
    return this.http.get(url).pipe(
      map(response => response[0]), shareReplay()
    );
    // return of({date:"test"});
  }

  private requestCountryphone(cntrycode) {

    const url = this.API_BASE_URL + '/api/v1/countryphone/' + cntrycode + '?_format=json';
    return this.http.get(url).pipe(
      map(response => response[0]), shareReplay()
    );
  }

  private requestGdprRegionCode(countryCode, regionCode) {

    const url = this.API_BASE_URL + '/api/v1/statecookie/'+countryCode+'/'+regionCode+'?_format=json';
    return this.http.get(url).pipe(
      map( response => response[0]), shareReplay()
    );
  }

  private fetchUserType(){
    if (localStorage.getItem('loggedUserDetails')) {
      const localStorageUserDetails:any = localStorage.getItem('loggedUserDetails');
      return this.userType = JSON.parse(localStorageUserDetails).type;
    }
  }
  private requestPCFIToggleInfo() {
    const url = this.API_BASE_URL + '/api/v1/togglepcfi?_format=json';
    return this.http.get(url).pipe(
      map(response => response[0]), shareReplay()
    );
  }

}
