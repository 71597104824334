<div id="rcyc-page-channels" class="bodyContentOuter">
  <!-- Section - 1 -->
  <app-rcyc-hero-module *ngIf="apiResponseB  && responseArray.length>0"
    [responseArray]="apiResponseB" [pageName]="'destinations'">
  </app-rcyc-hero-module>
  <ng-container *ngFor="let eachResponse of apiResponseD">
    <div class="py-10 lg:py-16">
      <app-rcyc-50-50 *ngIf="apiResponseD" [data]="eachResponse"></app-rcyc-50-50>
    </div>
  </ng-container>

  <!-- Slider Editorial -->
  <app-rcyc-slider variant="editorial" id="home-editorial-slider" *ngIf="apiResponseE" pageName="destinations" [data]="{
        topContent: {
          eyebrow: apiResponseE[0]?.field_com_eyebrow,
          title: apiResponseE[0]?.title | convertHtml,
        },
        items: apiResponseE
      }"></app-rcyc-slider>
</div>