import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SpecialCountryHighlight'
})
export class RcycSpecialCountryHighlightPipe implements PipeTransform {

  transform(value: any, args?: any): any {   
    if(!args)return value;
    return value.replace('+', '').replace(new RegExp(args.trim().replace('+', ''), "gi"), match => {
      return '<span class="specialCountryHighlightText">' + match + '</span>';
    });
  }

}
