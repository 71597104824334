import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RcycVoyagesService } from '../../rcyc-components/rcyc-voyages/rcyc-voyages.service';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycHomeService } from '../../rcyc-components/rcyc-home/rcyc-home.service';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';

@Component({
  selector: 'app-rcyc-public-marquee-events',
  templateUrl: './rcyc-public-marquee-events.component.html',
  styleUrls: ['./rcyc-public-marquee-events.component.css']
})
export class RcycPublicMarqueeEventsComponent implements OnInit, OnChanges {

  @Input() marqueeGetData: any;
  @Input() countryNo: any;
  temp: any;
  marqueeMore: any = [];
  marqueeMoreArray = [];
  selectedMarqeeId: any;
  currentUrlPath: any;
  currentUrlPathAsArray: any;
  voyages_basic: any;
  addtionalparam: any;
  excursion_frm_elastc: any;
  apiUrlA: any;
  apiResponseA: any;
  apiResponseAStatus;
  carouselArrayMarquee = [];
  marqueeEventShow = false;
  showHeaders = true;
  UserDetails;
  cntrycode;
  apiUrlB;
  apiResponseBTemp;
  country_no;
  reservationStyle = '';
  noAditionalMaqueeFalg = true;
  apiResponseWebsiteHeadings: any = [];

  constructor(
    private rcycVoyagesService: RcycVoyagesService,
    private router: Router,
    private rcycCommonService: RcycCommonService,
    private rcycHomeService: RcycHomeService,
    private _SharedServiceService: SharedServiceService,
    private rcycInternalApisService: RcycInternalApisService
  ) {
    this.currentUrlPath = this.router.url;
    this.currentUrlPathAsArray = this.currentUrlPath.split('/');
    this.currentUrlPathAsArray.shift();
  }

  ngOnInit() {

    this.websiteFormHeadings();
  }

  ngOnChanges() {
    this.onChangeFn();

  }


  onChangeFn() {
    if (this.marqueeGetData) {
      // this.apiResponseA = this.marqueeGetData.marqCarousel;
      this.marqueeMore = this.marqueeGetData.data;


      this.marqueeEventShow = true;
      if (this.marqueeGetData.category === 'reservation') {

        if(this.marqueeMore&&this.marqueeMore.length){
          this.marqueeMore.map((checkbookedstatus) => {
            if(checkbookedstatus.bookedFolioList && !checkbookedstatus.bookedFolioList.length){
              this.noAditionalMaqueeFalg =false;
            }
          });
        }


        this.showHeaders = false;
        this.reservationStyle = 'reservStyle';
        if (localStorage.getItem('UserDetails')) {
          this.UserDetails = this.rcycCommonService.getData('UserDetails');
          if (this.UserDetails.country_code) {
            this.cntrycode = this.UserDetails.country_code;
          }
          this._SharedServiceService.getCountryphone(this.cntrycode).subscribe(res => {
            this.country_no = res.title;
            // this.region = res.field_call_center_region;
            this.rcycCommonService.changeCountryPhoneNo(this.country_no);
          });
        }
      }
      else if (this.marqueeGetData.category === 'port') {
        // if(this.marqueeMore.length)
        // this.noAditionalMaqueeFalg =true;
        this.marqueeMore = this.marqueeGetData.tourData;
      }
      if (this.marqueeGetData.voyageId) {
        this.excursionPortdate(this.marqueeGetData.voyageId);
      }
    }
    else {
      this.marqueeEventShow = false;
    }
  }

  marqueeMoreClick(event, index, tourData, item, arrowStatus) {
    if (this.selectedMarqeeId !== index) {
      this.selectedMarqeeId = index;
    } else if (this.selectedMarqeeId === index) {
      this.selectedMarqeeId = -1;
    }
    // this.excursionsMoreClickStatus = true;
    this.carouselArrayMarquee = [];
    if (this.apiResponseA) {
      this.apiResponseA.map((data) => {
        if (tourData && (tourData.slice(0, 8) === data.field_excursion_code) )  {
          this.carouselArrayMarquee.push(data);
        }
      });
    }
    if (this.marqueeGetData.category === 'port') {
      if (this.marqueeGetData.portData) {
        this.marqueeGetData.portData.map((data) => {
          if (tourData && (tourData.slice(0, 8) === data.field_excursion_code) )  {
            this.carouselArrayMarquee.push(data);
          }
        });
      }
    }
    // Add arrowStatus to the excursionMore Elastic Array
    this.marqueeMore.map((resData) => {
      if (resData.tourCd === item.tourCd) {
        resData.arrowStatus = arrowStatus;
      }
      if (resData.tourCatCd) {

        resData.tourCatCd_1 = resData.tourCatCd.substr(0, resData.tourCatCd.indexOf(' '));
        // resData.tourCatCd_2 = resData.tourCatCd.substr(resData.tourCatCd.indexOf(' ') + 1 );
        resData.tourCatCd_2 = resData.tourCatCd.replace(resData.tourCatCd_1, '');

      }
    });
  }

  excursionPortdate(voyageId) {
    this.rcycVoyagesService.excursionPortdate(voyageId).subscribe(
      response => {

        this.temp = response;
        if (this.temp && this.temp.code === 200 && this.temp.data && this.temp.data.marqueeEvents.length) {
          this.excursion_frm_elastc = this.temp.data.marqueeEvents;
          this.excursion_frm_elastc.forEach( (value) => {
              this.marqueeData(value)
            }
          );
        }
      },
      err => { }
    );
  }

  marqueeData(item) {
    const marqueeData: any = {
      date: item.date? item.date: '',
      portCode:  item.portCode? item.portCode: '',
      voyageCode: this.marqueeGetData.voyageId? this.marqueeGetData.voyageId: ''
    };
    //  const marqueeData: any = {
    //    date: '2020-02-19T19:00:00.000+0000',
    //    portCode:  'DOSAM',
    //    voyageCode: '11200529'
    //  };
    this.rcycVoyagesService.excursionMoreData(marqueeData).subscribe(
      response => {
        this.temp = response;
        if (this.temp && this.temp.code === 200) {
          // this.excursionMore = true;
          this.marqueeMoreArray.push(this.temp.data.marqueeEvents[0])
          this.marqueeMore = this.marqueeMoreArray;
          this.excursionsImage(item);
        }
      },
      err => { }
    );
  }

  /* ==========================================================
  Api to get images of excursions
  ============================================================= */
  excursionsImage(item) {

    this.apiUrlA = '/api/v1/excursions/port/' + item.portCode + '?_format=json';

    this.rcycInternalApisService.getDrupal(this.apiUrlA).subscribe(
      response => {

        const apiResponseA: any = response;
        if (apiResponseA && apiResponseA.length) {
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });

          if (res.length) {

            this.apiResponseA = res;
            this.apiResponseAStatus = true;
            this.resThumbnailData();
          }
        }
      },
      err => {
        console.log('err  apiUrlA');
        console.log(err);
      }
    );
  }

  resThumbnailData() {
    this.marqueeMore = this.rcycCommonService.setImages(this.apiResponseA, this.marqueeMore);
  }

  /**
   * Drupal Api for website headings
   */
  websiteFormHeadings() {
    const apiUrl = '/api/v1/formheadings/website?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (res: any=[]) => {
        if (res && res.length) {
          this.apiResponseWebsiteHeadings = res[0];
        }
      },
      err => {
        console.log(err,'err website form heading');
      }
    );
  }
  
}
