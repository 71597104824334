<div id="rcyc-page-specialOffersLanding" class="row bodyContentOuter" [ngClass]="{'offer-loyalty-program': currentOfferKey==='loyalty'|| 'starsprogram'}">
  <!-- <app-rcyc-reqcall-modal #requestCallModal></app-rcyc-reqcall-modal> -->
  <section id="nonCompareSection" class="nonCompareSection">
    
    <div *ngIf="!compareClickStatus">
    
        <section class="row" *ngIf="apiResponseABCDStatus">
          <!-- Section 1-->						
          <app-rcyc-hero-module *ngIf="apiResponseBannerStatus  && responseArray.length>0" [responseArray]="responseArray"></app-rcyc-hero-module>

           <!-- Section 2-->
          <section class="row section-2">
            <div class="l-container-wrapper">
              <div class="l-container">

                <div *ngIf="apiResponseABCD?.field_sub_header" id="mainSubTitleId" class="sub-title" [title]="apiResponseABCD?.field_sub_header | convertHtml" [innerHtml]="apiResponseABCD?.field_sub_header"></div>
                <h1 *ngIf="apiResponseABCD?.title" id="mainTitleId" class="page-title" [title]="apiResponseABCD?.title | convertHtml" [innerHtml]="apiResponseABCD?.title"></h1>                
                <div class="row is-12">										
                  <p *ngIf="apiResponseABCD?.body" id="mainDescriptionId" [innerHtml]="apiResponseABCD?.body"></p>												
                </div> 
               
                <!-- <div class="row is-12">                  
                  <a class="button requestACall" id="requestCallBtnId" (click)="requestCallClick()" (keypress)="requestCallClick()" title="Request a call" tabindex=0>
                    <span>Learn More</span>
                    <span [ngClass]="{'icon-arrow-up': requestCallClickStatus, 'icon-arrow-down': !requestCallClickStatus}"></span>
                  </a>
                  <app-rcyc-request-call *ngIf="requestCallClickStatus" [specialOfferExpandFlag]="specialOfferExpandFlag"></app-rcyc-request-call>
                </div> -->
                <div class="row is-12">
                  <p id="webTable" class="Webileonly" *ngIf="apiResponseABCD?.field_web_benefits_table" [innerHtml]="apiResponseABCD?.field_web_benefits_table"></p>
                  <p id="mobileTable" class="Mobileonly mobile" *ngIf="apiResponseABCD?.field_mobile_benefits_table" [innerHtml]="apiResponseABCD?.field_mobile_benefits_table"></p>
                </div>
              </div>
            </div>							
          </section>						
        </section>

        <section class="row RequestFormSection">
          <app-rcyc-request-quote *ngIf="apiResponseABCD?.field_form_type == 'RAQ'"></app-rcyc-request-quote>
          <app-rcyc-request-brochure-new *ngIf="apiResponseABCD?.field_form_type == 'REB'"></app-rcyc-request-brochure-new>
          <app-rcyc-request-call *ngIf="apiResponseABCD?.field_form_type == 'RAC'"></app-rcyc-request-call>
          <app-rcyc-special-offers *ngIf="apiResponseABCD?.field_form_type == 'SPECIALOFFERS'"></app-rcyc-special-offers>               
        </section>

        <div *ngIf="apiResponseElasticAStatus">
          <!-- Section 3-->
          <!-- <section class="section-3">
            <div class="l-container-wrapper">
              <div class="l-container cruiseListLContainer">				
                <h2 *ngIf="matchingCruisesLength" id="matchingCruiseTitleId" title="{{ matchingCruisesLength }} Matching Cruises"> {{ matchingCruisesLength }} Matching Cruises</h2>
              </div>
            </div>
          </section> -->
          
          <!-- Section 4 cruise List header -->
          <!-- <section class="section-4">
            <div class="l-container-wrapper sortContainer">
              <div class="l-container cruiseListLContainer">				
                <div class="row outerRow">
                  <div class="column innerMain1">
                    <div class="column innerSub1 sortBySectionOuter">
                      
                      <div class="row">
                        <div class="column">
                          
                          <div class="column sort_by_tab">									
                            <div class="sortBy">SORT BY: </div>
                          </div>
                          
                          <div class="column sort_by_drop_down_tab">
                            <div class="basic-form_group noMarginBottom">															          
                              <div class="selectOuter" id="specialSelectTopOuter">                                													
                                <select class="selectTitle"  #n (change)="sortBy(n.value, 'input')" [ngModel]="selectedValue" (focus)="focusSortByDropdown($event, 'focus', 'top')" (focusout)="focusSortByDropdown($event, 'focusout', 'top')">
                                  <option id="departureDateAscendingId_upper" value="DepartureDateAscending">Departure Date - ascending</option>
                                  <option id="departureDateDescendingId_upper" value="DepartureDateDescending">Departure Date - descending</option>
                                  <option id="pricelowTohighId_upper" value="PricelowTohigh">Price - low to high</option>
                                  <option id="pricehighTolowId_upper" value="PricehighTolow">Price - high to low</option>
                                  <option id="durationshortestTolongestId_upper" value="DurationshortestTolongest">Duration - shortest to longest</option>
                                  <option id="durationlongestToshortestId_upper" value="DurationlongestToshortest">Duration - longest to shortest</option>
                                </select>
                              </div>								
                            </div>
                          </div>
                          
                        </div>								
                      </div>
                                        
                    </div>
                                    
                    <div class="column gridListView innerSub1">
                      <a id="gridViewLinkId_upper" class="gridViewLink" tabindex="0" (keyup.enter)="showDisplayClick('grid')" (click)="showDisplayClick('grid')"
                        [ngClass]="{'active': gridClickStatus, 'gridSelected': gridSelectedStatus, 'listGridViewBtn': gridClickStatus}" [ngStyle]="{'color': gridClickStatus ? 'white' : 'black'}"
                        (focus)="gridListFocus($event, 'grid', 'top')" (focusout)="gridListFocusOut($event, 'grid', 'top')" (mouseenter)="gridListMouseOver($event)"
                        (mouseleave)="gridListMouseLeave($event, 'grid', 'top')">
                          <img class="gridlistImg" src="assets/components/images/grid.png">
                          <span class="gridlistSpan">GRID VIEW</span>
                      </a>																																														
                      <a id="listViewLinkId_upper" class="listViewLink" tabindex="0" (keyup.enter)="showDisplayClick('list')" (click)="showDisplayClick('list')"
                        [ngClass]="{'active': listClickStatus, 'listSelected': listSelectedStatus, 'listGridViewBtn': listClickStatus}" [ngStyle]="{'color': listClickStatus ? 'white' : 'black', 'margin-left' : listClickStatus ? '4px' : 'null' }"
                        (focus)="gridListFocus($event, 'list', 'top')" (focusout)="gridListFocusOut($event, 'list', 'top')" (mouseenter)="gridListMouseOver($event)"
                        (mouseleave)="gridListMouseLeave($event, 'list', 'top')">
                          <img class="gridlistImg" src="assets/components/images/list.png">
                          <span class="gridlistSpan">LIST VIEW</span>
                      </a>																																																												
                    </div>
                    <div class="column innerSub2 compareBtnSectionOuter">										                      
                      <div class="column innerSub2-1 compare_btn_outr">
                        <button id="compareBtn_upper" class="button compare" title="Compare" [disabled]="!compareCheckboxArray.length || compareCheckboxArray.length < 2" (click)="showDisplayClick('compare')">COMPARE</button>
                      </div>                                               									
                    </div>									
                              
                  </div>						
                </div>
               </div>
            </div>
          </section> -->
          
          <!-- Section 5-->
          <section class="row section-5">
            <div class="l-container-wrapper">
              <div class="l-container cruiseListLContainer">
                
                <!-- GRID View Starts -->
                <div *ngIf="gridClickStatus">                  
                      <div *ngIf="comparelimitStatus" class="alert alert-error">
                        {{this.compareLimitError}}
                      </div>
                                  
                      <div class="row is-12 gridView">
                          
                        <div class="row is-3-3-3-3 eachOuterMainList">													
                          <div *ngFor="let item of loadedItems; let i=index;" id="eachGridItemId_{{i+1}}" class="column is-3 eachOuter eachOuter-grid-{{item.voyageId}}"
                          [ngClass]="{'noMarginRight': (i+1)%4 == 0}"
                          [ngStyle]="{height: (maxEachOuterHeight)? maxEachOuterHeight+'px':''}">											
                                                       
                            <div class="eachRow-1">
                              <a class="clickable-image" id="gridViewItineraryId_img{{i+1}}" [routerLink]="item?.itineraryUrl"
                                  (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)"
                                 (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)">
                                <img *ngIf="item?.voyageImagePath" id="gridVoyageImgId_{{i+1}}" [src]="item?.voyageImagePath" class="p04_img"/>
                              </a>
                              <div class="seeAll" *ngIf="item.embarkationCountry !==  item.disEmbarkationCountry">
                                <div class="seeAllTitleSec">
                                  {{ item.embarkationCountry}} TO {{ item.disEmbarkationCountry}}
                                </div>
                              </div>
                              <div class="seeAll" *ngIf="item.embarkationCountry ===  item.disEmbarkationCountry">
                                <div class="seeAllTitleSec">
                                  ROUNDTRIP {{ item.embarkationCountry}}
                                </div>
                              </div>
                            </div>                              
    
                            <div class="row eachRow-2">
                              <div class="voyageName" [innerHTML]="item?.voyageName"
                              [ngStyle]="{height: maxEachVoyagenameHeight? maxEachVoyagenameHeight+'px':''}"></div>
                            </div>
  
                            <div class="row eachRow-3"
                            [ngStyle]="{height: maxEachDDFHeight? maxEachDDFHeight+'px':''}">
                              <div class="durationColumn splitColumn">
                                <div><span>DURATION</span></div>
                                <div><span class="splitRowMiddle" [innerHTML]="item.nights"></span></div>
                                <div>Nights</div>
                              </div>
                              <div class="departsColumn splitColumn">
                                <div><span>DEPARTS</span></div>
                                <div class="splitRowMiddle" [innerHTML]="item.voyageStartDate | date: 'MMM dd':'UTC'"></div>
                                <div [innerHTML]="item.voyageStartDate | date: 'y':'UTC'"></div>
                              </div>
                              <div class="faresFromColumn splitColumn">
                                <div><span>FARES FROM</span></div>
                                
                                <!-- <div *ngIf="item?.suiteAvailability && item?.suiteAvailability >= 1 && item?.startingPriceMap && item?.portFeeMap && currencyCode && item?.startingPriceMap[currencyCode]">
                                  <div *ngIf="currencyCode=='USM'">
                                    <div class="splitRowMiddle">{{item.startingPriceMap[currencyCode] | currency:USD:'symbol':'1.0-0'}}*</div>
                                    <div>USD</div>
                                  </div>
                                  <div *ngIf="currencyCode!='USM'">
                                    <div class="splitRowMiddle"> {{item.startingPriceMap[currencyCode] | currency:currencyCode:'symbol':'1.0-0'}}* </div>
                                    <div>{{currencyCode}}</div>
                                  </div>											
                                </div>												
                                                      
                                <div *ngIf="(!item?.suiteAvailability || item?.suiteAvailability < 1 || !item?.startingPriceMap || !item?.portFeeMap || !currencyCode || !item?.startingPriceMap[currencyCode])">
                                  <div class="pcfiText">
                                    <span>Call For Information</span>
                                  </div>
                                </div> -->
                                <div *ngIf="!item?.pcfiToggleStatus && item?.suiteAvailability && item?.suiteAvailability >= 1 &&
                                  item?.startingPriceMap && item?.portFeeMap && item?.feeCurrency &&
                                  item?.startingPriceMap[item?.feeCurrency]">
                                  <div *ngIf="item?.startingPriceMap[item?.feeCurrency] && item?.feeCurrency!='USM'">
                                    <div class="splitRowMiddle">
                                      {{item?.startingPriceMap[item?.feeCurrency] | currency:item?.feeCurrency:'symbol':'1.0-0'}}*
                                    </div>
                                      <div>{{item?.feeCurrency}}</div>
                                  </div>
                                  <div *ngIf="item?.startingPriceMap[item?.feeCurrency] && item?.feeCurrency=='USM'">
                                    <div class="splitRowMiddle">
                                      {{item?.startingPriceMap[item?.feeCurrency] | currency:USD:'symbol':'1.0-0'}}*
                                    </div>
                                    <div>USD</div>
                                  </div>
                                </div>
                                <div *ngIf="item?.pcfiToggleStatus || !item?.suiteAvailability || item?.suiteAvailability < 1 ||
                                !item?.startingPriceMap || !item?.portFeeMap ||  !item?.feeCurrency ||
                                !item?.startingPriceMap[item?.feeCurrency]">
                                  <!-- <div class="pcfiText">
                                    Please Call For Information
                                  </div> -->
                                  <div class="pcfiText" [innerHTML]="PCFIToggleInfo?.field_toggle_text"></div>
                                </div>
                                
                              </div>
                            </div> 
  
                            <div class="row eachRow-4">
                              <div class="compareCheckBox yacht-name">
                                <span>{{item?.yachtName}}</span>
                              </div>																										
                              <div *ngIf="item?.itineraryUrl" >
                                <a class="button request_more_info" id="gridViewItineraryId_{{i+1}}" [routerLink]="item?.itineraryUrl"
                                title="More Information" (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)"
                                (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '');viewingVoyageClick(item)">
                                  MORE INFORMATION
                              
                                </a>
                              </div>
                              <div class="row">
                                <a class="button requestQuote" id="btn_grid_quote_{{i+1}}" routerLink="/request-quote/{{item.voyageId}}" title="Request a Quote" (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')" (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')">
                                  REQUEST A QUOTE
                                </a>
                              </div>							
                              
                              <div class="row compareCheckBox">														
                                <input name="region" (keyup.enter)="compareCheckboxClick($event, item, i+1, 'grid')" (click)="compareCheckboxClick($event, item, i+1, 'grid')" type="checkbox" id="gridCheckbox_{{i+1}}" value="compare" class="compare_checkbox compareCheckBoxPort">
                                <span class="heading com-size">COMPARE</span>
                              </div>
  
                              <!-- <div class="extra_fee">
                                <span *ngIf="currencyCode=='USD'">
                                  *Taxes, fees and port expenses of {{item?.portFeeMap[currencyCode] | currency:currencyCode:'symbol':'1.0-0'}} per passenger are additional. Fares are per person based on double occupancy.
                                </span>
                                <span *ngIf="currencyCode=='USM' ">
                                  *Fares are per person based on double occupancy. Taxes, fees and port expenses of {{item.portFeeMap[currencyCode] | currency:'USD':'symbol':'1.0-0'}} per passenger are included.
                                </span>
                                <span *ngIf="item?.portFeeMap[currencyCode] && currencyCode!='USD' && currencyCode!='USM' ">
                                  *Fares are per person based on double occupancy. Taxes, fees and port expenses of {{item.portFeeMap[currencyCode] | currency:currencyCode:'symbol':'1.0-0'}} per passenger are included.
                                </span>
                                <span *ngIf="!item?.portFeeMap[currencyCode]">
                                  *Taxes, fees and port expenses of {{item?.portFeeMap['USD'] | currency:USD:'symbol':'1.0-0'}} per passenger are additional. Fares are per person based on double occupancy.
                                </span>
                              </div> -->
                              <!-- Drupal controlled Language based on currency code -->
                              <div class="extra_fee"
                              [ngStyle]="{height: (maxExtrafeeHeight)? maxExtrafeeHeight+'px':''}">
                                <span [innerHTML]="infoCommonMsg?.[currencyFieldMap[item?.feeCurrency]]"></span>                              </div>
                              <!-- Removing the following old harcoded language - Updating this to drupal controlled as above -->
                              <div class="row extra_fee" *ngIf="false"
                              [ngStyle]="{height: (maxExtrafeeHeight)? maxExtrafeeHeight+'px':''}">
                                <span *ngIf="item?.feeCurrency=='USD'">
                                  *Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are additional. Fares are per person based on double occupancy.
                                </span>														
                                <span *ngIf="item?.feeCurrency=='USM' ">
                                  *Fares are per person based on double occupancy. Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:'USD':'symbol':'1.0-0'}} per passenger are included.
                                </span>
                                <span *ngIf="item?.portFeeMap[item?.feeCurrency] && item?.feeCurrency!='USD' && item?.feeCurrency!='USM'">
                                  *Fares are per person based on double occupancy. Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are included.
                                </span>
                                <span *ngIf="!item?.portFeeMap[item?.feeCurrency]">
                                  *Taxes, fees and port expenses of {{item.portFeeMap['USD'] | currency:USD:'symbol':'1.0-0'}} per passenger are additional. Fares are per person based on double occupancy.
                                </span>
                              </div>
                              <!-- <div class="seeAll" *ngFor="let callItem of item.callOutText; let i=index;" >
                                <p *ngIf="callItem && callItem.field_appear_on_voyage_listing == '1'" > 
                                  <span> OFFERS/PROGRAMS:</span> {{callItem.title}} 										
                                </p>
                                <a tabindex="0" id="sea_all _id{{i+1}}" title="SEE ALL" *ngIf="callItem && callItem.field_appear_on_voyage_listing == '1'" routerLink="{{item.voyageUrl_new_offer}}">SEE ALL</a>
                              </div>	 -->
                            </div>
                            
                          </div>		

                        </div>															
                      </div>
                      
                      <div class="alert alert-error" *ngIf="comparelimitStatus">
                        {{this.compareLimitError}}
                      </div>
                   
                </div>														
                <!-- GRID View Ends -->
                  
                <!-- LIST View Starts -->
                <!-- <div *ngIf="listClickStatus">
                  
                  <div class="alert alert-error" *ngIf="comparelimitStatus">
                    {{this.compareLimitError}}
                  </div>
                  <div class="row is-12 listView" *ngIf="apiResponseElasticAStatus">
                    <div *ngFor="let item of loadedItems; let i=index;" id="eachListItemId_{{i+1}}" class="column is-9-3 eachOuter" [ngClass]="{'firstItem': i+1 == 1, 'noMarginRight': (i+1)%4 == 0, 'active': compareCheckboxClickStatusId == (i+1) }">
                      
                      <div class="column is-9">
                        
                        <div class="row is-12">
                          <div class="column is-3-9">
                            
                            <div class="column is-3">
                              <div class="eachColumn-1">
                                <h4 title="DEPARTURE" class="departure">DEPARTURE</h4>						
                                <h2 *ngIf="item?.voyageStartDate" id="listMonthDateId_{{i+1}}" class="departure_date"> {{item?.voyageStartDate | date: 'MMM dd' :'UTC'}}</h2>	
                                <h4 *ngIf="item?.voyageStartDate" id="listYearId_{{i+1}}" class="departure_year">{{item?.voyageStartDate | date: 'y' :'UTC'}}</h4>
                              </div>
                            </div>											
                            <div class="column is-9 mainContentOuter">
                              
                              <div class="row is-12">
                                <div class="column is-9-3">
                                  
                                  <div class="column is-9 mainContentInner">	
                                    <div class="eachColumn-2">													                                      
                                      <div id="listVoyageNameId_{{i+1}}">
                                        <p> <span class="heading"> Voyage:</span> <span *ngIf="item?.voyageName">{{item?.voyageName}}</span> <span *ngIf="item?.voyageId"> {{item?.voyageId}}</span></p>
                                      </div>
                                      <div id="listDurationId_{{i+1}}">
                                        <p> <span class="heading"> Duration:</span> <span *ngIf="item?.nights" [innerHtml]="item?.nights"></span></p>
                                      </div>																																				                                      
                                      <div class="seeAll" *ngFor="let callItem of item.callOutText; let i=index;" >
                                          <p class="noMarginBottom" *ngIf="callItem && callItem.field_appear_on_voyage_listing == '1'" > 
                                            <span> OFFERS/PROGRAMS:</span> {{callItem.title}} 										
                                          </p>
                                          <a tabindex="0" id="sea_all _id{{i+1}}" title="SEE ALL" *ngIf="callItem && callItem.field_appear_on_voyage_listing == '1'" routerLink="{{item.voyageUrl_new_offer}}">SEE ALL</a>
                                        </div>
                                    </div>															
                                  </div>
                                  <div class="column is-3">
                                    <div class="eachColumn-3">
                                      <h4>Fares from</h4>
                                      <div *ngIf="item?.suiteAvailability && item?.suiteAvailability >= 1 && item?.startingPriceMap && item?.portFeeMap && item?.feeCurrency && item?.startingPriceMap[item?.feeCurrency]">
                                        <h2 *ngIf="item?.feeCurrency!='USM'">{{item?.feeCurrency}}  {{item?.startingPriceMap[item?.feeCurrency] | currency:item?.feeCurrency: 'symbol' :'1.0-0'}}*</h2>
                                        <h2 *ngIf="item?.feeCurrency=='USM'">USD  {{item?.startingPriceMap[item?.feeCurrency] | currency:USD: 'symbol' :'1.0-0'}}*</h2>
                                        <div class="extra_fee">
                                          <span *ngIf="item?.feeCurrency=='USD'">
                                            *Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are additional. Fares are per person based on double occupancy.
                                          </span>
                                          <span *ngIf="item.feeCurrency=='USM' ">
                                            *Fares are per person based on double occupancy. Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:'USD':'symbol':'1.0-0'}} per passenger are included.
                                          </span>
                                          <span *ngIf="item?.portFeeMap[item.feeCurrency] && item.feeCurrency!='USD' && item.feeCurrency!='USM'">
                                            *Fares are per person based on double occupancy. Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are included.
                                          </span>
                                          <span *ngIf="!item?.portFeeMap[item.feeCurrency]">
                                            *Taxes, fees and port expenses of {{item.portFeeMap['USD'] | currency:USD:'symbol':'1.0-0'}} per passenger are additional. Fares are per person based on double occupancy.
                                          </span>
                                        </div>
                                      </div>

                                      <div *ngIf="!item?.suiteAvailability || item?.suiteAvailability < 1 || !item?.startingPriceMap || !item?.portFeeMap ||  !item?.feeCurrency || !item?.startingPriceMap[item?.feeCurrency]">
                                        <h2 class="pcfiText">
                                          Please Call For Information
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="column is-3">
                        
                        <div class="eachColumn-4">
                            <a class="button request_more_info"  tabindex="0" id="listViewItineraryId_{{i+1}}"  title="More Information" (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '')" (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '')">
                                MORE INFORMATION
                            
                              </a>
                              <a class="button requestQuote" id="btn_list_quote_{{i+1}}" routerLink="/request-quote/{{item.voyageId}}" title="Request a Quote" (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')" (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')"> 
                                REQUEST A QUOTE
                              </a>                           
                          
                          <div class="compareCheckBox">
                            <input name="region" (click)="compareCheckboxClick($event, item, i+1, 'list')" type="checkbox" id="listCheckbox_{{i+1}}" value="compare"  class="compare_checkbox compareCheckBoxSpecialOffers"/>COMPARE
                          </div>
                          
                        </div>
                        
                      </div>
                      
                    </div>							
                  </div>
                  
                  <div class="alert alert-error" *ngIf="comparelimitStatus">
                    {{this.compareLimitError}}
                  </div>								
                </div> -->
                <!-- LIST View Ends -->													
                            
              </div>
            </div>
          </section>	
          

        </div>

        <div *ngIf="loadedItems?.length < matchingCruisesLength" class="row loadMoreBtnOuter">      
          <div class="loadMoreBtnInner">
            <button *ngIf="!loadMoreApiStatus" tabindex="0" class="button loadMoreBtn" (click)="loadMoreCruise()" (keyup.enter)="loadMoreCruise()" [disabled]="apiResponseOverview?.length >= matchingCruisesLength">Load More...</button>										
            <div *ngIf="loadMoreApiStatus" class="loaderBtnOuter">
              <img class="loaderImg" src="assets/components/images/loading.gif">
            </div>
          </div>
        </div>

        <!-- Section Script -->
        <section class="row extScript"
          [ngClass]="{'offandprogBeforeScript': scriptExist && !apiResponseElasticAStatus && apiResponseABCD?.field_form_type && apiResponseABCD?.field_form_type!='',
              'noContentBeforScript': scriptExist && !apiResponseElasticAStatus && !apiResponseABCD?.field_form_type && !apiResponseABCD?.body}">
          <div class="l-container-wrapper">
            <div class="l-container">
              <div id="specialOffersAndProgramEternalScript"></div>
            </div>
          </div>
        </section>

        <!-- Section 7-->
        <section *ngIf="apiResponseABCD?.field_offers_landing_page_bottom" class="row section-7">
          <div class="l-container-wrapper">
            <div class="l-container">									
              <div id="descriptionId_ABCD" class="pageBottomText" [innerHtml]="apiResponseABCD?.field_offers_landing_page_bottom"> </div>					
            </div>
          </div>
        </section>

        <!-- Offers List Outer Starts-->
        <section class="row offersListOuter">
          <div class="l-container-wrapper">
            <div class="l-container">	
              <!-- You might also like -->
              <h4 class="offerListTitle" [innerHTML]="apiResponseABCD?.field_you_might_also_like"></h4>
              <div class="row is-4-4-4 offersListInner">
                <!-- <div *ngFor="let item of offersListArray" class="column is-4 eachOfferListOuter">
                  <img class="offerListInnerImg" [src]="item?.url"/>
                  <h4 class="offerListInnerTitle" [innerHTML]="item?.title"></h4>
                  <p class="offerListInnerContent" [innerHTML]="item?.content"></p>                  
                  <a class="offerListLearnMore">Learn more</a>                
                </div>            -->
                <div class="column is-4" [ngClass]="{'eachOfferListOuter': apiResponseABCD?.field_block_image_1||
                apiResponseABCD?.field_block_title_1||
                apiResponseABCD?.field_text_block_1}">
                  <a *ngIf="apiResponseABCD?.field_block_image_1" 
                  [href]="apiResponseABCD?.field_image_1_url">
                    <img class="offerListInnerImg" [src]="apiResponseABCD?.field_block_image_1"
                    [alt]="apiResponseABCD?.field_image_1_alt | convertHtml"
                    [title]="apiResponseABCD?.field_image_1_title | convertHtml"/>
                  </a>
                  <h4 *ngIf="apiResponseABCD?.field_block_title_1"
                  class="offerListInnerTitle" [innerHTML]="apiResponseABCD?.field_block_title_1"></h4>
                  <p *ngIf="apiResponseABCD?.field_text_block_1"
                  class="offerListInnerContent" [innerHTML]="apiResponseABCD?.field_text_block_1"></p>                  
                  <!-- <a class="offerListLearnMore">Learn more</a>                 -->
                </div>
                <div class="column is-4" [ngClass]="{'eachOfferListOuter': apiResponseABCD?.field_block_image_2||
                apiResponseABCD?.field_block_title_2||
                apiResponseABCD?.field_text_block_2}">
                  <a *ngIf="apiResponseABCD?.field_block_image_2" 
                  [href]="apiResponseABCD?.field_image_2_url">
                    <img class="offerListInnerImg" [src]="apiResponseABCD?.field_block_image_2"
                    [alt]="apiResponseABCD?.field_image_2_alt | convertHtml"
                    [title]="apiResponseABCD?.field_image_2_title | convertHtml"/>
                  </a>
                  <h4 *ngIf="apiResponseABCD?.field_block_title_2"
                  class="offerListInnerTitle" [innerHTML]="apiResponseABCD?.field_block_title_2"></h4>
                  <p *ngIf="apiResponseABCD?.field_text_block_2"
                  class="offerListInnerContent" [innerHTML]="apiResponseABCD?.field_text_block_2"></p>                  
                  <!-- <a class="offerListLearnMore">Learn more</a>                 -->
                </div>
                <div class="column is-4" [ngClass]="{'eachOfferListOuter': apiResponseABCD?.field_block_image_3||
                apiResponseABCD?.field_block_title_3||
                apiResponseABCD?.field_text_block_3}">
                  <a *ngIf="apiResponseABCD?.field_block_image_3" 
                  [href]="apiResponseABCD?.field_image_3_url">
                    <img class="offerListInnerImg" [src]="apiResponseABCD?.field_block_image_3"
                    [alt]="apiResponseABCD?.field_image_3_alt | convertHtml"
                    [title]="apiResponseABCD?.field_image_3_title | convertHtml"/>
                  </a>
                  <h4 *ngIf="apiResponseABCD?.field_block_title_3"
                  class="offerListInnerTitle" [innerHTML]="apiResponseABCD?.field_block_title_3"></h4>
                  <p *ngIf="apiResponseABCD?.field_text_block_3"
                  class="offerListInnerContent" [innerHTML]="apiResponseABCD?.field_text_block_3"></p>                  
                  <!-- <a class="offerListLearnMore">Learn more</a>                 -->
                </div>
              </div>

            </div>
          </div>
        </section>
        <!-- Offers List Outer Starts-->

        <section class="row offerLastAreaOuter">
          <div class="l-container-wrapper">
            <div class="l-container">									              
              <p  id="offerLastAreaContentId" class="offerLastAreaContent" [innerHtml]="apiResponseABCD?.field_paragraph"></p>
            </div>
          </div>
        </section>
        
      <!-- </div> -->
    </div>
  </section>

  <!-- ==============================================================================================================================================
    Compare Click
  =================================================================================================================================================== -->
  <!-- Section comes on Compare Click STARTS-->
  <section id="compareSection" class="compareSection" *ngIf="compareClickStatus">
    <div id="rcyc-page-specialOffersLandingCompare">

      <!-- Section 1-->
      <section class="section-1">
        <div class="l-container-wrapper">
          <div class="l-container">
            <div id="compareGoback_upper" (keyup.enter)="showDisplayClick('goback')" (click)="showDisplayClick('goback')">
              <a title="Go back " class="goBackLink" tabindex="0"> <span></span>GO BACK </a>
            </div>
          </div>
        </div>
      </section>	
        
      <!-- Section 2-->
      <section class="section-2">
        <div class="l-container-wrapper">
          <div class="l-container">
            
            <div class="row is-12">
              <div class="is-4-4-4">
                <div class="column is-4 eachOuter active" *ngFor="let item of compareCheckboxArray; let i= index;" [ngClass]="{'noMarginRight': (i+1)%3 == 0 }">
                  
                  <div class="eachRow-1">
                    <!-- <img src="{{item.image}}" />	-->
                    <h4 title="DEPARTURE" class="departure">DEPARTURE</h4>
                    <div>						
                      <h2 *ngIf="item?.voyageStartDate" id="compareMonthDate_{{i+1}}" [innerHtml]="item?.voyageStartDate | date: 'MMM dd' :'UTC'"></h2>	
                      <h4 *ngIf="item?.voyageStartDate" id="compareYear_{{i+1}}" [innerHtml]="item?.voyageStartDate | date: 'y' :'UTC'"></h4>	
                    </div>																											
                  </div>	
                  
                  <div class="eachRow-2">									
                    <h4>Embark/Disembark</h4>
                    <p *ngIf="item?.voyageEmbarkPort || item?.voyageDisembarkPort" id="compareEmbark_{{i+1}}" class="content" innerHtml="{{item?.voyageEmbarkPort}} / {{item?.voyageDisembarkPort}}"></p>
                    <!-- <p *ngIf="item?.itineraryUrl" class="viewMore">
                      <a id="compareViewItinerary_{{i+1}}" tabindex="0" class="secondary-cta" title="View Itinerary" [routerLink]="item?.itineraryUrl | convertHtml">View Itinerary</a>
                    </p> -->
                    <a class="button request_more_info"  id="compareViewItinerary_{{i+1}}" tabindex="0"  title="More Information" (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '')" (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, '')">
                        MORE INFORMATION
                    
                      </a>
                      <a class="button requestQuote" id="btn_cmpr_quote_{{i+1}}" routerLink="/request-quote/{{item.voyageId}}" title="Request a Quote" (click)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')" (keyup.enter)="setPreviousUrlForVoyagePageBack(item?.itineraryUrl, 'requestQuote')"> 
                        REQUEST A QUOTE
                      </a> 
                    
                  </div>
              
                  <div class="eachRow-3">
                    <h4>Fares from</h4>
                    <div *ngIf="item?.suiteAvailability && item?.suiteAvailability >= 1 && item?.startingPriceMap && item?.portFeeMap && item?.feeCurrency && item?.startingPriceMap[item?.feeCurrency]">
                      <h2 *ngIf="item?.feeCurrency!='USM'">{{item?.feeCurrency}}  {{item?.startingPriceMap[item?.feeCurrency] | currency:item?.feeCurrency: 'symbol' :'1.0-0'}}*</h2>
                      <h2 *ngIf="item?.feeCurrency=='USM'">USD  {{item?.startingPriceMap[item?.feeCurrency] | currency:USD: 'symbol' :'1.0-0'}}*</h2>
                      <!-- Drupal controlled Language based on currency code -->
                      <div class="extra_fee">
                        <span [innerHTML]="infoCommonMsg?.[currencyFieldMap[item?.feeCurrency]]"></span>
                      </div>
                      <!-- Removing the following old harcoded language - Updating this to drupal controlled as above -->
                      <div class="extra_fee" *ngIf="false">
                        <span *ngIf="item?.feeCurrency=='USD'">
                          *Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are additional. Fares are per person based on double occupancy.
                        </span>
                        <span *ngIf="item?.feeCurrency=='USM' ">
                          *Fares are per person based on double occupancy. Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:'USD':'symbol':'1.0-0'}} per passenger are included.
                        </span>
                        <span *ngIf="item?.portFeeMap[item?.feeCurrency] && item?.feeCurrency!='USD' && item?.feeCurrency!='USM' ">
                          *Fares are per person based on double occupancy. Taxes, fees and port expenses of {{item.portFeeMap[item.feeCurrency] | currency:item.feeCurrency:'symbol':'1.0-0'}} per passenger are included.
                        </span>
                        <span *ngIf="!item?.portFeeMap[item?.feeCurrency]">
                          *Taxes, fees and port expenses of {{item.portFeeMap['USD'] | currency:USD:'symbol':'1.0-0'}} per passenger are additional. Fares are per person based on double occupancy.
                        </span>
                      </div>
                    </div>
                    <div *ngIf="(!item?.suiteAvailability || item?.suiteAvailability < 1 || !item?.startingPriceMap || !item?.portFeeMap || !item?.startingPriceMap[item?.feeCurrency])">
                      <h2 class="pcfiText">
                        Please Call For Information
                      </h2>
                    </div>
                  </div>
                  
                  <div class="eachRow-4">
                    <!-- <a class="button requestQuote"> 
                      REQUEST QUOTE
                    </a> -->
                    <h4>Voyage</h4>		
                    <p> <span *ngIf="item?.voyageName">{{item?.voyageName}}</span> <span *ngIf="item?.voyageId">{{item?.voyageId}}</span></p>																					
                  </div>
                  
                  <div class="eachRow-5">
                    <!-- <agm-map [latitude]="lat" [longitude]="lng">
                      <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                    </agm-map> -->
                    <!-- <img src="assets/components/images/voyage-map.jpg"/> -->
                    <img id="compareVoyageMapImgId" [title]="item?.field_image_title_content | convertHtml" [alt]="item?.field_image_alt_content | convertHtml" [src]="item?.voyageMapImgUrl" />										
                  </div>
                  
                  <div class="eachRow-6">
                    <p *ngFor="let port of item?.portData; let i = index;">
                      <span *ngIf="port?.portName" class="title">PORT {{i+1}} :</span> 
                      <span *ngIf="port?.portName" id="comparePortName_{{i+1}}" [innerHtml]="port?.portName"></span>
                    </p>
                  </div>
                  
                  <!-- <a class="button requestQuote"> 
                      REQUEST QUOTE
                  </a> -->
                  
                </div>						
              </div>
            </div>
            
          </div>
        </div>
      </section>
      
        <!-- Section 3 same section 1-->
      <section class="section-1">
        <div class="l-container-wrapper">
          <div class="l-container">
            <div id="compareGoback_lower" (keyup.enter)="showDisplayClick('goback')" (click)="showDisplayClick('goback')">
              <a title="Go back " class="goBackLink"  tabindex="0"> <span></span>GO BACK </a>
            </div>
          </div>
        </div>
      </section>	
      
    </div>
  </section>
  <!-- Section comes on Compare Click STARTS-->
  
</div>
