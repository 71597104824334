import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryNameSearchFilter'
})
export class RcycCountrynameSearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(item => {
      let splittedItem = item.name.toLowerCase().split(" ")
      if (item.dial_code.replace("+", "").startsWith(searchText) ||
        item.dial_code.replace(/ /g, "").startsWith(searchText) ||
        item.dial_code.replace("+", "").replace(/ /g, "").startsWith(searchText) ||
        item.dial_code.startsWith(searchText) ||
        splittedItem[0].startsWith(searchText)||splittedItem[splittedItem.length-1].startsWith(searchText)) {
        return true;
      }
    });        
  }
}
