import { Injectable, ElementRef, Renderer2, ViewChild } from '@angular/core';
import Player from '@vimeo/player';

@Injectable()
export class Rcyc3playVimeoService {
  public current3playVimeoPlayer: Player;


  private createCurrent3playVimeoPlayer(pluginId){    
    if(pluginId)  this.current3playVimeoPlayer = new Player(pluginId,{});
  }

  setPlayerInstance(pluginId){
    this.createCurrent3playVimeoPlayer(pluginId);
  }
  // Function to mute a vimeo
  muteVimeo() {   
    this.current3playVimeoPlayer.setMuted(true).then(function(muted) {
        // muted was turned off        
      }).catch(function(error) {
          // an error occurred          
      });
  }
// Function to unmute a vimeo
  unmuteVimeo() {    
    this.current3playVimeoPlayer.setMuted(false).then(function(muted) {
        // muted was turned on        
      }).catch(function(error) {
          // an error occurred          
      });
      
  }

// Function to play a vimeo
  playVimeo() {    
    this.current3playVimeoPlayer.play().then(function() {
      // the video was played     
    }).catch(function(error) {      
        switch (error.name) {
            case 'PasswordError':
                // the video is password-protected and the viewer needs to enter the
                // password first
                break;
    
            case 'PrivacyError':
                // the video is private
                break;
    
            default:
                // some other error occurred
                break;
        }
    });
  }
// Function to pause a vimeo
  pauseVimeo() {     
    return new Promise((resolve,reject)=>{
      this.current3playVimeoPlayer.pause().then(function(status) {
        // the video was played        
        resolve(status);
      }).catch(function(error) {        
          reject(error.name);
          switch (error.name) {
              case 'PasswordError':
                  // the video is password-protected and the viewer needs to enter the
                  // password first
                  break;
      
              case 'PrivacyError':
                  // the video is private
                  break;
      
              default:
                  // some other error occurred
                  break;

          }
      });   
    })
      
  }
// Function to enable Cc of a vimeo
  enableCcVimeo() {    
      this.current3playVimeoPlayer.enableTextTrack('en').then((track) => {              
      }).catch((error) => {             
        switch (error.name) {
          case 'InvalidTrackLanguageError':
            break;
          case 'InvalidTrackError':
            break;
          default:
            break;
        }
      });      
  }

// Function to disable Cc of a vimeo
  disableCcVimeo() {   
    this.current3playVimeoPlayer.disableTextTrack().then(function() {
      // the track was disabled      
    }).catch(function(error) {
        // an error occurred       
    });
  }
// Function to set volume of a vimeo to 0
  setVolume0() {
    this.current3playVimeoPlayer.setVolume(0).then(function(volume) {
      // volume was set      
    }).catch(function(error) {
        switch (error.name) {
            case 'RangeError':
                // the volume was less than 0 or greater than 1
                break;
    
            default:
                // some other error occurred
                break;
        }
    });
  }
// Function to set volume of a vimeo to 1
  setVolume1() {
    this.current3playVimeoPlayer.setVolume(0.5).then(function(volume) {
      // volume was set      
    }).catch(function(error) {
        switch (error.name) {
            case 'RangeError':
                // the volume was less than 0 or greater than 1
                break;
    
            default:
                // some other error occurred
                break;
        }
    });
  }
// Function to set volume of a vimeo to 0
setVolume(range) {
  this.current3playVimeoPlayer.setVolume(range).then(function(volume) {  
    // volume was set
  }).catch(function(error) {
      switch (error.name) {
          case 'RangeError':
              // the volume was less than 0 or greater than 1
              break;
  
          default:
              // some other error occurred
              break;
      }
  });
}

  add3PlayScript(item) {    
    // "zone.js": "^0.8.5"
    if (document.getElementById('3playmediavimeomain')) {
      // Remove if 3playmediavimeomain already exists
      const element = document.getElementById('3playmediavimeomain');
      element.parentNode.removeChild(element);
    }
    const audioDescriptionScript = document.createElement('script');
    audioDescriptionScript.type = 'text/javascript';
    audioDescriptionScript.id = '3playmediavimeomain';
    
    // FOR TEST-1
    audioDescriptionScript.src = 'https://plugin.3playmedia.com/ajax.js?mf='+item.loaded3PlayMFId+
      '&mute=false&p3sdk_version=1.10.7&p=33872&pt=361&target='+item.loaded3PlayTargetPluginId+
      '&vembed=0&video_id='+item.loadedVimeoId+'&video_target='+item.current3PlayPuginId;
    // audioDescriptionScript.src = 'https://plugin.3playmedia.com/ajax.js?ad=1&cc=1&cc_minimizable=0&cc_minimize_on_load=0&cc_multi_text_track=1&cc_overlay=0&cc_searchable=0&embed=ajax&height=500px&mf=3513574&p3sdk_version=1.10.7&p=33872&player_type=vimeo_v2&plugin_skin=light&target=3p-plugin-target-3513574-5mgdacc1&vembed=0&video_id=313209156&video_target=tpm-plugin-5mgdacc1-313209156&width=500px';

    if(item.loaded3PlayMFId&&item.loaded3PlayTargetPluginId&&item.loadedVimeoId&&item.current3PlayPuginId) {
      document.getElementsByTagName('head')[0].appendChild(audioDescriptionScript);
    }

  }

  
  getEnded(){    
    return new Promise((resolve,reject)=>{
      this.current3playVimeoPlayer.on('ended',()=>{
        resolve(true);
      })   
    })
  }

  toggleLoop(loopStatus){
    this.current3playVimeoPlayer.setLoop(loopStatus).then(
      ()=>{        
      }
    ).catch(
      error=>{console.log(error)}
    )
  }
  getTextTracks(){    
    return new Promise((resolve,reject)=>{
      this.current3playVimeoPlayer.getTextTracks().then(
        (status)=>{ resolve(status) }  
      ).catch(
        (error)=>{reject(error)}
      )
    })
  } 
  getMuted(){
    return new Promise((resolve,reject)=>{
      this.current3playVimeoPlayer.getMuted().then(
        (status)=>{           
          resolve(status);
         }  
      ).catch(
        (error)=>{reject(error)}
      )
    });
  }
  setMuteStatus(status){    
    return new Promise((resolve,reject)=>{      
      this.current3playVimeoPlayer.setMuted(status).then(
        (status)=>{
          resolve(status);
        }  
      ).catch((error)=>{
          reject(error);
      });
    });
  }
  loadVideo(param:Number){
    return new Promise((resolve,reject)=>{     
      this.current3playVimeoPlayer.off('ended'); //To remove all listneres to work properly
      this.current3playVimeoPlayer.loadVideo(param).then(
        (status)=>{
          resolve(status);
        }
      ).catch(
        (error)=>{
          reject(error)
        }
      )
      });
  }
}
