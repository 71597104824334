<div class="py-10 lg:py-16 bg-white flex flex-col lg:flex-row container gap-12">
  <app-rcyc-section-content
    heading="h2"
    class="lg:w-1/2 flex items-center"
    [data]="getData(data.slice(0, 1)[0])"></app-rcyc-section-content>

  <div class="lg:w-1/2 flex flex-col gap-6 lg:gap-12">
    <div
      *ngFor="let tab of data.slice(1)"
      class="relative w-full bg-black text-white lg:py-6
      flex items-center transition-all duration-[2000ms] overflow-hidden"
      [class]="opened === tab.id ? 'h-[600px]' : 'h-[50px] lg:h-[72px]'"
      (click)="openTab(tab.id)">
      <img
        *ngIf="tab.field_tile_image"
        [src]="tab.field_tile_image"
        alt=""
        class="background-image min-h-[600px]">
      <div class="background-image bg-primary/40"></div>
      <div class="absolute top-0 left-0 flex items-center h-[50px] lg:h-[72px] uppercase w-full px-2 px-6 cursor-pointer z-10">
        <span class="transition-all font-caslon relative top-[3px] lg:text-[18px] duration-[2000ms] "
          [class]="opened === tab.id ? 'opacity-0' : 'opacity-100'">
          {{tab.field_tile_title | convertHtml}}
        </span>
        <div class="w-[18px] h-[18px] lg:w-[26px] lg:h-[26px] rounded-full border border-solid border-white absolute
          top-[16px] lg:top-[24px] right-[8px] lg:right-[24px] text-[10px] flex leading-none justify-center items-center"
          *ngIf="opened !== tab.id"
        >
          <img src="assets/img/accordion-open.svg" alt="">
        </div>
      </div>
      <div class="p-4 p-12 h-[600px] flex items-center absolute top-0 left-0 transition-all duration-[2000ms] lg:px-[48px]"
           [class]="opened === tab.id ? 'opacity-100' : 'opacity-0'">
        <app-rcyc-section-content variant="light" heading="h3" [data]="getData(tab)"></app-rcyc-section-content>
      </div>
    </div>
  </div>
</div>
