import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable()
export class RcycRequestBrochureService {

    ELASTIC_API_BASE_URL: any = AppConfig.ELASTIC_API_BASE_URL;
    ELASTIC_API_BASE_URL_EBROCHURE: any = AppConfig.ELASTIC_API_BASE_URL_EBROCHURE;
    API_BASE_URL: any =   AppConfig.API_BASE_URL;
  constructor(
        private http: HttpClient
    ) {

    }

     /* ====================================================================================================================================
    Funtion to call All Apis for Request eBrochure Drupal
    * ====================================================================================================================================*/
    requestBrochureDrupalGet(apiUrl) {

        const url = this.API_BASE_URL + apiUrl;
        return this.http.get(url);
    }

     /* ====================================================================================================================================
    Funtion to Submit All Apis for Request eBrochure Drupal
    * ====================================================================================================================================*/
    brochureFormData(apiUrl, params, authToken = '') {

        const url = this.ELASTIC_API_BASE_URL_EBROCHURE + apiUrl;

        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json; charset=utf-8',
              'Accept': '*/*',
              'Authorization': `Bearer ${authToken}`
            })
        };

        return this.http.post(url, params, httpOptions);
    }

    /**
     * Funtion to call All GET Drupal Apis
     * @param apiUrl Url of drupal API
     * @returns Drupal response
     */
    getDrupal(apiUrl: string) {
        const url = this.API_BASE_URL + apiUrl;
        return this.http.get(url);
    }

    /**
     * Funtion to call All POST Elastic Apis
     * @param params 
     * @param apiUrl 
     * @returns 
     */
    postElastic(params, apiUrl) {
      const elasticParams = params;
      const url = this.ELASTIC_API_BASE_URL + apiUrl;
      return this.http.post(url, elasticParams);
    }

}

