export const  guestConfiguration =  {
    "documents": {
        "passport":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "emergency_contact":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "preferences":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "medical":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "air":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        }
    },
    "itinerary":{
        "dining":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "spa":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "shorex":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "marquee":{
            "allow_view":true,
            "allow_create":true,
            "allow_delete":true,
            "allow_edit":true
        }
    },
    "amenities":{
        "credit_card":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "bonvoy":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "selfie":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "arrival_time": {
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        }
    },
    "accommodations":{
        "hotel":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "land":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        },
        "transfer":{
            "allow_view":true,
            "allow_create":true,
            "allow_edit":true,
            "allow_delete":true
        }
    },
    "edoc":{
        "suite_highlight":{
            "allow_edit":true,
            "allow_delete":true,
            "allow_create":true,
            "allow_view":true
        },
        "booking_detail":{
            "allow_edit":true,
            "allow_delete":true,
            "allow_create":true,
            "allow_view":true
        },
        "itinerary_detail":{
            "allow_edit":true,
            "allow_delete":true,
            "allow_create":true,
            "allow_view":true
        },
        "port_highlight":{
            "allow_edit":true,
            "allow_delete":true,
            "allow_create":true,
            "allow_view":true
        },
    }
}
