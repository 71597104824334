import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, HostListener } from '@angular/core';
// import { RcycLoginHeaderService } from './rcyc-login-header.service';
import { Router } from '@angular/router';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycCommonLogoutService } from '../../../rcyc-services/rcyc-logout/rcyc-logout.service';
import { RcycInternalApisService } from '../../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';

@Component({
  selector: 'app-rcyc-login-header-new',
  templateUrl: './rcyc-login-header-new.component.html',
  styleUrls: ['./rcyc-login-header-new.component.css']
})
export class RcycLoginHeaderNewComponent implements OnInit {

  loggedMenuObj: any;
  isMenuOpen = false;
  subheaderComponent: any;
  loginMenuName: any;
  apiUrl: any;
  initialHeaderMenu = '';
  initalmobilebanner = '';
  apiResponseFindAnswer: any;
  userType:any;
  apiResponseFindAnswerStatus = false;
  @Input() componentName: any;
  @Output() loginHeaderTo = new EventEmitter();
  scrWidth: any;
  @Input() toLoginHeaderData: any;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const mainTitle = document.querySelector('#mainTitleId') as HTMLElement;
    const findAnswer = document.querySelector('#findAnswerId') as HTMLElement;
    if (window.pageYOffset > 50 && window.innerWidth <= 600) {
      this.initialHeaderMenu = 'brand-header-mobile';
      if (mainTitle) {
        (document.querySelector('#mainTitleId') as HTMLElement).style.marginTop = '40px';
      } else {
      if (findAnswer) {
         (document.querySelector('#findAnswerId') as HTMLElement).style.marginTop = '40px';
        }
      }
    } else {
      this.initialHeaderMenu = '';
      if (mainTitle) {
        (document.querySelector('#mainTitleId') as HTMLElement).style.marginTop = '0px';
      } else {
        if (findAnswer) {
        (document.querySelector('#findAnswerId') as HTMLElement).style.marginTop = '0px';
        }
      }
    }
  }
  constructor(
    // private rcycLoginHeaderService: RcycLoginHeaderService,
    private router: Router,
    private rcycCommonService: RcycCommonService,
    private rcycCommonLogoutService: RcycCommonLogoutService,
    private rcycInternalApisService: RcycInternalApisService,
  ) {

  }

  ngOnInit() {
    this.subheaderComponent = this.componentName;
    this.loggedMenuObjects();
    this.getFindAnswer();
    this.getMenuDetails(this.subheaderComponent);
    this.getLoggedUserDetails();    
    this.loginHeaderTo.emit(
      {
        'apiResponseFindAnswer' : this.apiResponseFindAnswer,
      }
    );
  }

  getLoggedUserDetails ()
  {
    if (this.rcycCommonService.getData('loggedUserDetails')) {
      const localStorageUserDetails = this.rcycCommonService.getData('loggedUserDetails');
      this.userType = localStorageUserDetails.type;
    }
    if(this.userType=="RA")
    {
      this.loggedMenuObj = [{ name: 'Logout',
      url: '/signout',
      id: 'guestLogout'}]
    }
  }

  loggedMenuObjects() {
    this.loggedMenuObj = [{
      name: 'Dashboard',
      url: '/guest/dashboard',
      id: 'guestDashboard',
      component: 'RcycDashboardComponent'
    }, {
      name: 'My Reservations',
      url: '/guest/my-reservations',
      id: 'guestMyReservations',
      component: 'RcycMyReservationsComponent'
    }, {
      name: 'My Profile',
      url: '/guest/my-profile',
      id: 'guestMyProfile',
      component: 'RcycMyProfileComponent'
    },
    //  {
    //   name: 'Marketing Preferences',
    //   url: 'guest/marketing-preferences',
    //   id: 'guestMarketingPreferences',
    //   component: 'RcycMarketingComponent'
    // },
    {
      name: 'Change Password',
      url: '/guest/password-update',
      id: 'guestPasswordUpdate',
      component: 'RcycPasswordUpdateComponent'
    },
    {
      name: 'Logout',
      url: '/signout',
      id: 'guestLogout',
      // component: 'RcycPasswordUpdateComponent'
    }
  ];

  }

  openMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  getMenuDetails(component) {
    switch (component) {
      case 'RcycDashboardComponent':
        this.loginMenuName = 'My Collection';
        this.apiUrl = '/api/v1/dashboard/header/a?_format=json';
        break;
      case 'RcycMyReservationsComponent':
        this.loginMenuName = 'My Profile';
        this.apiUrl = '/api/v1/guestmyreservations/header/a?_format=json';
        break;
      case 'RcycMyProfileComponent':
        this.loginMenuName = 'My Collection';
        this.apiUrl = '/api/v1/guestmyprofile/header/a?_format=json';
        break;
      case 'RcycPasswordUpdateComponent':
        this.loginMenuName = 'My Account';
        this.apiUrl = '/api/v1/updatepassword/header/a?_format=json';
        break;
      case 'RcycContactusComponent':
        this.loginMenuName = 'My Collection';
        this.apiUrl = '/api/v1/contactus/header/a?_format=json';
      break;
      case 'RcycPreContractComponent':
        // this.loginMenuName = 'My Collection';
        this.apiUrl = '/api/v1/eu-precontract/header/a?_format=json';
      break;

    }
    if (this.apiUrl) {
      this.rcycInternalApisService.getDrupal(this.apiUrl).subscribe(
        res => {
          const temp: any = res;
          if (temp && temp.length) {
            this.loginMenuName = temp[0].title;
          }
        },
        err => {
        }
      );
    }

  }
    // Function to Get Find Answer from Drupal

    getFindAnswer() {
      const apiUrl = '/api/v1/tamyprofile/header/c?_format=json';
      this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
        res => {
          const temp: any = res;
          if (temp && temp.length) {
            // this.loginHeaderTo.emit(temp[0]);
            this.apiResponseFindAnswer = temp[0];
            this.apiResponseFindAnswerStatus = true;
          }
        },
        err => {
        }
      );
    }
  /* =======================================================================
      Function to call logout function
    ==================================================================== */
    logOutClick() {
      // if (localStorage.getItem('loggedUserDetails')) {

      //   localStorage.removeItem('loggedUserDetails');
      //   this.router.navigate(['/login']);

      //   // localStorage.setItem('loggedUserDetails', stringifiedLoginData);
      //   this.rcycCommonService.setLoggedInStatus(true);

      //   // change changeCurrentUserName
      //   this.rcycCommonService.changeCurrentUser('currentUserName');
      // }
      // console.log('logout');
      // this.rcycCommonLogoutService.logout();
    }

    /* ==============================================================
      Return to Guest details click
      =============================================================== */
  guestReturnDetailsClick() {

    this.loginHeaderTo.emit(
      {       
        'returnClickStatus': true
      }
    );
  }

}
