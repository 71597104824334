<swal #diningSwal (beforeOpen)="beforeOpen($event)" (open)="onOpen($event)" [showConfirmButton]="false"
  [showCancelButton]="false" [showCloseButton]="true" customClass="diningSwtOuter" [allowOutsideClick]="false"
  [animation]="false" (close)="onClose($event)" [reverseButtons]="true" [width]="'75%'">
  <ng-container *swalPartial>
    <div id="rcyc-dining-modal">
      <div class="py-8 px-0 lg:px-4">
        <div class="w-full lg:px-0 pr-[1px]" *ngIf="onboardDiningData">
          <app-rcyc-suites-list class="listItemAccordion" [SuiteFare_result]='onboardDiningData' [isPricing]="false"
            [isHeading]="false" variant="experience" id="itinerary-slider" [pageName]="'diningModal'"></app-rcyc-suites-list>
        </div>
      </div>
    </div>
  </ng-container>
</swal>