import { environment } from '../../environments/environment';
export const AppConfig = {

  GOOGLE_API_KEY: environment.GOOGLE_API_KEY,
  // GOOGLE_ANALYTIC_KEY: environment.GOOGLE_ANALYTIC_KEY,

  API_BASE_URL: environment.API_BASE_URL,
  IPDATA_API_KEY: environment.IPDATA_API_KEY,
  IMG_BASE_URL: '', //  devgatewayapi micro services devgatewayapi
  IMG_CDNHOST_URL: environment.IMG_CDNHOST_URL,
  ELASTIC_API_BASE_URL: environment.ELASTIC_API_BASE_URL,
  ELASTIC_API_BASE_URL_EBROCHURE: environment.ELASTIC_API_BASE_URL_EBROCHURE,
  ELASTIC_API_BASE_URL_LEADGENFORM: environment.ELASTIC_API_BASE_URL_LEADGENFORM,
  API_BASE_URL_RESERVATION : environment.API_BASE_URL_RESERVATION,
  API_BASE_URL_EDOC : environment.API_BASE_URL_EDOC,
  API_BASE_URL_ADONIS_CAREER_LIST : environment.API_BASE_URL_ADONIS_CAREER_LIST,
  SALESFORCE_WEB_TO_LEAD_API_URL : environment.SALESFORCE_WEB_TO_LEAD_API_URL,
  sALESFORCE_WEB_TO_CASE_API_URL : environment.sALESFORCE_WEB_TO_CASE_API_URL,
  SALESFORCE_LEADGENFORM_API_OID : environment.SALESFORCE_LEADGENFORM_API_OID,
  SALESFORCE_LEADGENFORM_RET_URL : environment.SALESFORCE_LEADGENFORM_RET_URL,
  salesForceImplementation: environment. salesForceImplementation,

};
