<div *ngIf="isSpecialOfferSignupStatus" id="rcyc-page-specialOffers" class="row bodyContentOuter">
	
		<!-- Section 1-->
	
		<section class="section-1" *ngIf="apiResponseBStatus">					
			<div class="bannerImageOuter">				
				<!-- <img id="bannerImageId" [title]="apiResponseB?.field_image_title_content | convertHtml" [alt]="apiResponseB?.field_image_alt_content | convertHtml" [src]="apiResponseB?.field_images" srcset="{{apiResponseB.field_images_srcset}}" sizes="50vw" class="p04_img slider_data_list" /> -->
				<img id="bannerImageId" [title]="apiResponseB?.field_image_title_content | convertHtml" [alt]="apiResponseB?.field_image_alt_content | convertHtml" [src]="apiResponseB?.field_images" class="p04_img slider_data_list" />
				<!-- <picture>
						<source media="(max-width: 1920px)" [srcset]="apiResponseB?.field_images_src1">
						<source media="(max-width: 1440px)" [srcset]="apiResponseB?.field_images_src2">
						<source media="(max-width: 768px)" [srcset]="apiResponseB?.field_images_src3">
						<source media="(max-width: 375px)" [srcset]="apiResponseB?.field_images_src4">
						<img id="bannerImageId" [title]="apiResponseB?.field_image_title_content | convertHtml" [alt]="apiResponseB?.field_image_alt_content | convertHtml" [src]="apiResponseB?.field_images" class="p04_img slider_data_list" />
					</picture> -->
			</div>							
		</section>	
		
		<section class="section-1-1">
			<div class="l-container-wrapper">
				<div class="l-container">
					<div class="row is-8-4">
						<div class="column is-8">
	
							<div class="row is-12 signupHeaderSection">
								<!-- <div class="cloumn is-8-4">
									<div class="cloumn is-8">
										<span class="heading">Sign-up to Receive Special Offers </span>									
									</div>
									<div class="cloumn is-4 moreInfoSection">
										<a href="javascript:void(0);" class="moreInfo" (click)="signupMoreInfoClick()">MORE INFO								
											<span [ngClass]="{'icon-arrow-up': signupMoreInfoClickStatus === true , 'icon-arrow-down': signupMoreInfoClickStatus === false}"></span>
										</a>	
									</div>		
								</div> -->
								<div class="row is-6-6 mg-top">
									<div class="cloumn is-6">												
										<a *ngIf="apiResponseContentB&&apiResponseContentB?.title" href="javascript:void(0);" class="moreInfo" (click)="signupMoreInfoClick()">
											<!-- <span class="heading">Sign-up to Receive Special Offers </span>					 -->
											<span id="pageTitleId" class="headline pageTitle"
												[title]="apiResponseContentB?.title | convertHtml" [innerHTML]="apiResponseContentB?.title">
											</span>					
											<span [ngClass]="{'icon-arrow-up': signupMoreInfoClickStatus === true , 'icon-arrow-down': signupMoreInfoClickStatus === false}"></span>
										</a>								
									</div>
									<div class="cloumn is-6">
										
									</div>		
								</div>
							</div>
	
							<div *ngIf="signupMoreInfoClickStatus" class="formOuter">
	
								<div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
									<span class="error">Something went wrong. Please submit the form again.</span>						
								</div>
								<div id="formSubmitErrorId" *ngIf="offerFormStatus">
									<span class="error">{{sessionExpiredMessages}}</span>
								</div>
								<form id="eNewsLetterSignupFormId" name="eNewsLetterSignupForm" [formGroup]="eNewsletterSignUp" novalidate autocomplete="false">
									<div class="basic-form_group lead_form_field">
	
										<div class="row is-6-6">
											<div class="column is-6 rcyc-hide-honey">
												<div class="row is-12">
													<div class="column is-12 form_fields">
														<label class="basic-form_label form_label basic-form_label-required">Third Name</label>
													</div>
													<div class="column is-12 form_fields">													
														<input id="thirdNameId" class="basic-form_input basic-form_input-half" type="text" name="honey"  maxlength="30" placeholder='Third Name' autocomplete='off'>
													</div>
												</div>
											</div>
											<div class="column is-6">
												<div class="row is-12">
													<div class="column is-12 form_fields">
														<label class="basic-form_label form_label basic-form_label-required">First Name</label>
													</div>
													<div class="column is-12 form_fields">													
														<input id="firstNameId" class="basic-form_input basic-form_input-half" type="text" formControlName="firstName" maxlength="30" placeholder='First Name'>
														<!-- <span *ngIf="!eNewsletterSignUp.controls.firstName.valid && !fnameWhitespaceError && eNewsletterSignUp.controls.firstName.touched" class="error">
															This field is required
														</span> -->
														<!-- <span *ngIf="fnameWhitespaceError" class="error" [innerHtml]="fnameValidationMsg"></span> -->
														<p class="errorptag" *ngIf="eNewsletterSignUp.controls.firstName.invalid && (eNewsletterSignUp.controls.firstName.dirty || eNewsletterSignUp.controls.firstName.touched)">
															<span *ngIf="eNewsletterSignUp.controls.firstName.errors.required" class="error">This field is required</span>
															<span *ngIf="!eNewsletterSignUp.controls.firstName.errors.required&&eNewsletterSignUp.controls.firstName.errors.pattern" class="error">Please enter a valid first name </span>
														</p>
														
													</div>
												</div>
											</div>
											<div class="column is-6">
												<div class="row is-12">
													<div class="column is-12 form_fields">
														<label class="basic-form_label form_label basic-form_label-required">Last Name</label>
													</div>
													<div class="column is-12 form_fields">
														<input id="lastNameId" class="basic-form_input basic-form_input-half" type="text" formControlName="lastName" maxlength="60" placeholder='Last Name'>
														<!-- <span *ngIf="!eNewsletterSignUp.controls.lastName.valid && !lnameWhitespaceError && eNewsletterSignUp.controls.lastName.touched" class="error">
															This field is required
														</span> -->
														<!-- <span *ngIf="lnameWhitespaceError" class="error" [innerHtml]="lnameValidationMsg"></span> -->
														<p class="errorptag" *ngIf="eNewsletterSignUp.controls.lastName.invalid && (eNewsletterSignUp.controls.lastName.dirty || eNewsletterSignUp.controls.lastName.touched)">
															<span *ngIf="eNewsletterSignUp.controls.lastName.errors.required" class="error">This field is required</span>
															<span *ngIf="!eNewsletterSignUp.controls.lastName.errors.required&&eNewsletterSignUp.controls.lastName.errors.pattern" class="error">Please enter a valid last name </span>
														</p>
													</div>
												</div>
											</div>									
										</div>
	
										<div class="row is-6-6">
											<div class="column is-6">
												<div class="row is-12">
													<div class="column is-12 form_fields">
														<label class="basic-form_label form_label basic-form_label-required">Country / Region of Residence</label>
													</div>
													<div class="column is-12 form_fields">
														<select id="selectCountryId" class="basic-form_input basic-form_select" formControlName="country" (change)="countryselect()" (blur)="eNewsletterSignUp.controls.country.value=='0' ? countryErr=true : countryErr=false">
															<option  value="0" id="countryOption_0">Select Country/Region of Residence</option>															
															<option *ngFor="let county of countylist; let i=index;" id="countryOption_{{i+1}}" value="{{county.name}}">{{county.name}}</option>
														</select>
														<span *ngIf="countryErr" class="error">This field is required</span>
													</div>
												</div>
											</div>
											<div class="column is-6">
												<div class="row is-12">
													<div class="column is-12 form_fields">
														<label class="basic-form_label form_label basic-form_label-required">Email</label>
													</div>
													<div class="column is-12 form_fields">
														<input id="emailId" class="basic-form_input basic-form_input-half" type="email" formControlName="email" maxlength="60" placeholder='Email'>
														<!-- <span *ngIf="!eNewsletterSignUp.controls.email.valid && eNewsletterSignUp.controls.email.touched" class="error">
															This field is required
														</span>
														<span *ngIf="!eNewsletterSignUp.controls.email.errors.required&&eNewsletterSignUp.controls.email.errors.pattern" class="error">
															Enter a valid email address (e.g. name@example.com) </span> -->
														<p class="errorptag" *ngIf="eNewsletterSignUp?.controls?.email?.invalid && (eNewsletterSignUp?.controls?.email?.dirty || eNewsletterSignUp?.controls?.email?.touched)">
															<span *ngIf="eNewsletterSignUp?.controls?.email?.errors?.required" class="error">This field is required</span>
															<span *ngIf="!eNewsletterSignUp?.controls.email.errors?.required&&eNewsletterSignUp?.controls?.email?.errors?.pattern" class="error">
																	Enter a valid email address (e.g. name@example.com) 
															</span>
														</p>
													</div>
												</div>
											</div>									
										</div>
	
									</div>
								</form>
								
								<div class="row is-12 termsConditionsSection">
									<!-- <i>
									By clicking the "Submit" button below, I represent that: (1) I am 18+ years of age;
									and (2) I have read and agreed to the <a>Terms and Conditions</a> and <a>Privacy policy.</a>
									</i> -->
									<div *ngIf="apiresponseENewsletter?.field_summery" class="basic-form_condition" [innerHTML]="apiresponseENewsletter?.field_summery"></div>
								</div>
	
								<div class="row is-6-6 submitOuter"> 
									
									<div class="column is-6 submitInner">  
										
										<div class="row is-6-6">	
											<div *ngIf = "!submitLoading" class="column is-6 form_fields submitBtnOuter">    											 												
												<input type="submit" id="submitSignupId" (click)="eNewsletterSignupSubmit()"
												[disabled]="eNewsletterSignUp?.controls?.firstName?.invalid
												|| eNewsletterSignUp?.controls?.lastName?.invalid
												|| eNewsletterSignUp?.controls?.country?.value === '0'
												|| eNewsletterSignUp?.controls?.email?.invalid || !userArea" value="Submit">												
											</div>
											<div *ngIf = "submitLoading" class="row is-12 btnLoaderOuter">
												<div class="btnLoader">
													<img src="assets/components/images/loading.gif" class="formLoader">
												</div> 
											  </div>
						  
											<div class="column is-6 form_fields clearBtnOuter">
												<input type="reset" id="clearBtnId" (click)="clearProfileUpdateForm()" value="Clear" [ngClass]="{'disabledCursor': submitLoading}" [disabled]="submitLoading">
											</div>
										</div>
	
									</div>
									<div class="column is-6">
	
									</div>
								</div>   
	
							</div>
						</div>
						<div class="column is-4">
	
						</div>
					</div>
				</div>
			</div>
		</section>
	
		<!-- Section 2-->
		<section class="section-2">
			<div class="l-container-wrapper">
				<div class="l-container">
					
					<div *ngIf="apiResponseCStatus">
						<h4 *ngIf="apiResponseC?.title" id="mainTitleId" class="page-title" [title]="apiResponseC?.title | convertHtml" [innerHtml]="apiResponseC?.title"></h4>
						<h2 *ngIf="apiResponseC?.field_sub_header" id="mainSubTitleId" class="sub-title" [title]="apiResponseC?.field_sub_header | convertHtml" [innerHtml]="apiResponseC?.field_sub_header"></h2>
					</div>
					
					<div class="row is-12" *ngIf="apiResponseDStatus">
						<div class="is-4-4-4">
							<div class="column is-4 eachOuter" *ngFor="let item of apiResponseD; let i= index;" [ngClass]="{'lastRowItem': (i+1)%3 == 0, 'clearRow': (i+1)%3 == 1}">
                <a *ngIf="item?.voyageCount && item?.offerUrl_new" id="eachSeeVoyages_{{i+1}}" title="See Voyages" [routerLink]="item?.offerUrl_new" (keyup.enter)="clearCompareValue()" (click)="clearCompareValue()">
								  <img *ngIf="item?.field_image_title_content" id="eachImgId_{{i+1}}"  [src]="item?.field_image" [title]="item?.field_image_title_content | convertHtml" [alt]="item?.field_image_alt_content | convertHtml"/>
                </a>
                <a *ngIf="item?.voyageCount && item?.offerUrl_new" id="eachSeeVoyages_{{i+1}}" title="See Voyages" [routerLink]="item?.offerUrl_new" (keyup.enter)="clearCompareValue()" (click)="clearCompareValue()">
                  <img *ngIf="!item?.field_image_title_content" id="eachImgId_{{i+1}}"  [src]="item?.field_image"/>
                </a>
                <h4 *ngIf="item?.title" id="eachTitleId_{{i+1}}" [title]="item?.title | convertHtml" [innerHtml]="item?.title"></h4>
								<p *ngIf="item?.field_summery" id="eachDescriptionId_{{i+1}}" [innerHtml]="item?.field_summery"></p>
								<a *ngIf="item?.voyageCount && item?.offerUrl_new" id="eachSeeVoyages_{{i+1}}" title="See Voyages" class="seeXVoyages secondary-cta" [routerLink]="item?.offerUrl_new" (keyup.enter)="clearCompareValue()" (click)="clearCompareValue()"> {{apiResponseContentC}} </a>
							</div>						
						</div>				
					</div>
					
				</div>
			</div>
		</section>
		
		<!-- Section 3-->
		<section class="section-3" *ngIf="apiResponseEStatus&&apiResponseE?.field_summary_1">
			<div class="l-container-wrapper">
				<div class="l-container">				
					<div id="" class="offerSummery" [innerHtml]="apiResponseE?.field_summary_1"></div>
				</div>
			</div>
		</section>
		<!-- Section 4-->
		<section class="section-4" *ngIf="apiResponseEStatus">
			<div class="l-container-wrapper">
				<div class="l-container">				
					<div *ngIf="apiResponseE?.field_summery" id="descriptionId_E" class="offerSummery" [innerHtml]="apiResponseE?.field_summery"></div>
				</div>
			</div>
		</section>
		
	</div>
	
	
	<!-- Special offer SignUp in Offers & Programs STARTS -->
	<div *ngIf="!isSpecialOfferSignupStatus" id="rcyc-specialOfferSignup-offerPrograms">
	<section class="content_section new_content">
		<div class="l-container-wrapper">
			<div class="l-container intro-header leadinto new_pad">
				<div class="row">					
					<h1 *ngIf="apiResponseContentB&&apiResponseContentB?.title" id="pageTitleId" class="headline pageTitle"
						[title]="apiResponseContentB?.title | convertHtml" [innerHTML]="apiResponseContentB?.title">
					</h1>				
				</div>
				<div class="row">
					<!-- SIGN UP TO RECEIVE SPECIAL OFFERS -->
					<p id="requiredField"><span>*</span> Indicates required field</p>
				</div>
				<div class="formOuter">	
					<div class="formErrorSec"> 
						<div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
							<span class="error">Something went wrong. Please submit the form again.</span>						
						</div>
						<!-- <div *ngIf="specialOfferFormErrorMsg" id="formSubmitUIErrorId">
							<span class="error" [innerHTML]="specialOfferFormErrorMsg"></span>
						</div> -->
					</div>
					<form id="eNewsLetterSignupFormId" name="eNewsLetterSignupForm" [formGroup]="eNewsletterSignUp" novalidate autocomplete="false">
						<div class="basic-form_group lead_form_field">
							<div class="row is-4-4-4">
								<div class="column is-4 form_fields">																																																							
									<input id="firstNameId" class="input-1" type="text" required
									formControlName="firstName" maxlength="30" 
									[ngClass]="{'error-borderBtm': ( eNewsletterSignUp.controls.firstName.invalid &&
									(eNewsletterSignUp.controls.firstName.dirty || eNewsletterSignUp.controls.firstName.touched || specialOfferFormErrorMsg) )&&
								 (eNewsletterSignUp.controls.firstName.errors.required || (!eNewsletterSignUp.controls.firstName.errors.required&&eNewsletterSignUp.controls.firstName.errors.pattern) ) }"
								 (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />									
									<p class="errorptag" *ngIf="eNewsletterSignUp.controls.firstName.invalid &&
									(eNewsletterSignUp.controls.firstName.dirty || eNewsletterSignUp.controls.firstName.touched || specialOfferFormErrorMsg)">
										<span *ngIf="eNewsletterSignUp.controls.firstName.errors.required" class="error">This field is required</span>
										<span *ngIf="!eNewsletterSignUp.controls.firstName.errors.required&&eNewsletterSignUp.controls.firstName.errors.pattern" class="error">Please enter a valid first name </span>
									</p>
									<label class="label-1" for="firstNameId" style="padding-top: 16px;letter-spacing: 0px;">First Name</label>																																																																			
								</div>
								<div class="column is-4 form_fields">																												
									<input id="lastNameId" class="input-1" type="text" required
									formControlName="lastName" maxlength="60"
									[ngClass]="{'error-borderBtm': ( eNewsletterSignUp.controls.lastName.invalid &&
									(eNewsletterSignUp.controls.lastName.dirty || eNewsletterSignUp.controls.lastName.touched || specialOfferFormErrorMsg) )&&
								 (eNewsletterSignUp.controls.lastName.errors.required || (!eNewsletterSignUp.controls.lastName.errors.required&&eNewsletterSignUp.controls.lastName.errors.pattern) ) }"
								 (click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />												
									<p class="errorptag" *ngIf="eNewsletterSignUp.controls.lastName.invalid &&
									(eNewsletterSignUp.controls.lastName.dirty || eNewsletterSignUp.controls.lastName.touched || specialOfferFormErrorMsg)">
										<span *ngIf="eNewsletterSignUp.controls.lastName.errors.required" class="error">This field is required</span>
										<span *ngIf="!eNewsletterSignUp.controls.lastName.errors.required&&eNewsletterSignUp.controls.lastName.errors.pattern" class="error">Please enter a valid last name </span>
									</p>
									<label class="label-1" for="lastNameId" style="padding-top: 16px;letter-spacing: 0px;">Last Name</label>                        
								</div>	
								<div class="column is-4 form_fields">																											
									<!-- <div class="countrySelectInner">									
										<select id="selectCountryId" class="basic-form_input basic-form_select" formControlName="country"
										(change)="countryselect()" (blur)="eNewsletterSignUp.controls.country.value=='0' ? countryErr=true : countryErr=false"
										[ngClass]="{'error-borderBtm': countryErr}">
											<option  value="0" id="countryOption_0" disabled>Select Country/Region of Residence</option>															
											<option *ngFor="let county of countylist; let i=index;" id="countryOption_{{i+1}}" value="{{county.name}}">{{county.name}}</option>
										</select>
										<span class="icon-arrow-down"></span>
									</div>
									<div *ngIf="countryErr" class="error">This field is required</div> -->
	
									<div class="specialCountryOuter">
										<div class="specialCountryInputOuter">
											<div class="inputCloseOuter">
												<!-- <input id="specialCountryInputId" class="basic-form_input basic-form_input-half" type="text"
												placeholder="Select Country/Region of Residence" maxlength="60" [(ngModel)]="specialCountry"
												[ngModelOptions]="{standalone: true}" (click)="specialCountryInputClickStatus=true"
												[ngClass]="{'error-borderBtm': lnameWhitespaceError}" /> -->
												<input id="specialCountryInputId" class="input-1" type="text" required
												maxlength="60" formControlName="country"
												(click)="specialCountryInputClickStatus=true;specialCountryInputCloseBtnStatus=true"
												[ngClass]="{'error-borderBtm': ( (eNewsletterSignUp.controls.country.invalid &&
												(eNewsletterSignUp.controls.country.dirty || eNewsletterSignUp.controls.country.touched || specialOfferFormErrorMsg))&&
													(eNewsletterSignUp.controls.country.errors.required ) )
												||( ( eNewsletterSignUp.controls.country.valid && (eNewsletterSignUp.controls.country.dirty || eNewsletterSignUp.controls.country.touched) ) &&
												!isSpecialCountrySelect)}" />
												<span *ngIf="specialCountryInputCloseBtnStatus" class="icon-close" (click)="specialCountryInputCloseClick()"
												[ngClass]="{'error-specialCountryCloseBtn': ( (eNewsletterSignUp.controls.country.invalid &&
												(eNewsletterSignUp.controls.country.dirty || eNewsletterSignUp.controls.country.touched || specialOfferFormErrorMsg))&&
												(eNewsletterSignUp.controls.country.errors.required ) )
											||( ( eNewsletterSignUp.controls.country.valid && (eNewsletterSignUp.controls.country.dirty || eNewsletterSignUp.controls.country.touched) ) &&
											!isSpecialCountrySelect)}"></span>
												
												<p class="errorptag"
													*ngIf="eNewsletterSignUp.controls.country.invalid &&
													(eNewsletterSignUp.controls.country.dirty || eNewsletterSignUp.controls.country.touched || specialOfferFormErrorMsg)">
													<span *ngIf="eNewsletterSignUp.controls.country.errors.required" class="error">This field is
														required</span>													
												</p>
												<p class="errorptag"
													*ngIf="eNewsletterSignUp.controls.country.valid && (eNewsletterSignUp.controls.country.dirty || eNewsletterSignUp.controls.country.touched)">													
													<span *ngIf="!isSpecialCountrySelect" class="error">Please select a country</span>														
												</p>
												<label class="label-1" for="specialCountryInputId" style="padding-top: 16px;letter-spacing: 0px;">Select Country/Region of Residence</label>																								
											</div>
											<div *ngIf="specialCountryInputClickStatus" class="specialCountryListOuter">
												<ul *ngIf="countylist?.length" class="specialCountryUl">
													<li class="specialCountryLi" *ngFor="let item of countylist | specialCountrynameFilter: eNewsletterSignUp.controls.country.value;let i=index"
														id="specialCountryListItem_{{i}}"
														[innerHTML]="item?.name | SpecialCountryHighlight: eNewsletterSignUp.controls.country.value"
														(click)="specialCountryLiClick(item);">
													</li>
												</ul>
											</div>
										</div>
									</div>
	
	
								</div>															
							</div>
	
							<div class="row is-4-4-4">				
								<div class="column is-4 form_fields">																									
									<input id="emailId" class="input-1" type="text" required
									formControlName="email" maxlength="60"
									[ngClass]="{'error-borderBtm': ( eNewsletterSignUp.controls.email.invalid &&
									(eNewsletterSignUp.controls.email.dirty || eNewsletterSignUp.controls.email.touched || specialOfferFormErrorMsg) )&&
								(eNewsletterSignUp.controls.email.errors.required || (!eNewsletterSignUp.controls.email.errors.required&&eNewsletterSignUp.controls.email.errors.pattern) )  }" 
								(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />																		
									<p class="errorptag"
										*ngIf="eNewsletterSignUp?.controls?.email?.invalid &&
										(eNewsletterSignUp?.controls?.email?.dirty || eNewsletterSignUp?.controls?.email?.touched || specialOfferFormErrorMsg)">
										<span *ngIf="eNewsletterSignUp?.controls?.email?.errors?.required" class="error">This field is
											required</span>
										<span *ngIf="!eNewsletterSignUp?.controls?.email?.errors?.required&&eNewsletterSignUp?.controls?.email?.errors?.pattern" class="error">
											Enter a valid email address (e.g. name@example.com) </span>
									</p>
									<label class="label-1" for="emailId" style="padding-top: 16px;letter-spacing: 0px;">Email</label>																																																																			
								</div>	
								<div class="column is-4"></div>	
								<div class="column is-4"></div>							
							</div>
							
						</div>
					</form>
					
					<div class="row is-12 termsConditionsSection">					
						<div *ngIf="apiresponseENewsletter?.field_summery" class="basic-form_condition declaration" [innerHTML]="apiresponseENewsletter?.field_summery"></div>
					</div>								
					<div class="row">	
						<div *ngIf = "!submitLoading"class="submit_btn specialSignUpSubmitBtn"> 							
							<!-- <input type="submit" id="submitSignupId" (click)="eNewsletterSignupSubmit()"
							[disabled]="!eNewsletterSignUp.controls.firstName.valid ||
							fnameWhitespaceError || !eNewsletterSignUp.controls.lastName.valid ||
							lnameWhitespaceError || eNewsletterSignUp.controls.country.value === '0' ||
							!eNewsletterSignUp.controls.email.valid ||
							!userArea || !isSpecialCountrySelect" value="Submit">												 -->

							<!-- <input type="submit" id="submitSignupId" (click)="eNewsletterSignupSubmit()"
							[disabled]="eNewsletterSignUp?.controls?.firstName?.invalid||
							eNewsletterSignUp?.controls?.lastName.invalid||
							eNewsletterSignUp?.controls?.email?.invalid||
							eNewsletterSignUp?.controls?.country?.invalid||
							!userArea || !isSpecialCountrySelect" value="Submit">	 -->
							
							<input type="submit" id="submitSignupId" (click)="eNewsletterSignupSubmit()"
							value="Submit">	
							
						</div>					
						<div *ngIf = "submitLoading" class="specialSignUpSubmitBtn">
							<div class="submitLoader">
								<img src="assets/components/images/loading.gif">
							</div> 
						</div>	
								
					</div>													  
				</div>
	
			</div>
		</div>
	</section>
	</div>
	<!-- Special offer SignUp in Offers & Programs ENDS -->
