<div id="rcyc-page-nguCarousel">
  <div class="p02_A_01  p02_photo-tour photosDisplay">
    <div class="p02_photo_row_wrapper banner_wrapper_sec" data-target="photo-tour-wrapper">
      <div class="p02_photo_row banner_wrapper map_route_wrapper_fix">

        <ngu-carousel #pageNguCarousel id="pageNguCarouselId" [inputs]="pageCarousel" [dataSource]="response_F"
        (carouselLoad)="nextCarouselload($event)" (onMove)="onmoveFn($event)">											

          <ngu-item NguCarouselItem *nguCarouselDef="let item; let i = index;" id="pageNguCarouselItemId_{{i+1}}"
            [title]="item?.field_image_title_content | convertHtml" class="imageClass"
          >
            <img *ngIf="item?.field_image"  class="p04_img slider_data_list imageClass"
              [alt]="item?.field_image_alt_content | convertHtml"
              [src]="item?.field_image"
              appRcycErrorSrc [onErrImage]="item?.field_image" [ngStyle]="{'visibility':i===currentSlide ? 'visible' : 'hidden' }"
            />
              
          </ngu-item>
          <button NguCarouselPrev *ngIf="response_F.length > 1" id="pageNguCarouselLeft" class="btn_carousel leftRs" tabindex="0" (click)="changeSliderContentIndex('left')">
              <img class="sliderLeftnav" src="assets/img/arrow_left.svg" alt="Scroll to the previous image" title="Scroll to the previous image">
            </button>
          
          <button NguCarouselNext *ngIf="response_F.length > 1" id="pageNguCarouselRight" class="btn_carousel rightRs" tabindex="0" (click)="changeSliderContentIndex('right')">
            <img class="sliderRightnav" src="assets/img/arrow_right.svg" alt="Scroll to the next image" title="Scroll to the next image">
          </button>			
        </ngu-carousel>

        <span *ngIf="(response_F?.length > 0 && response_F[currentSlide]?.field_video)" class="play-btn-wrapper">
          <span class="play-btn-cover">
            <span class="play-btn-box">
              <!-- <a class="play-btn" (keyup.enter)="showLightbox(sliderIndex)" tabindex="0" (click)="showLightbox(sliderIndex)">
                <img [src]="'assets/img/play_button.png'" />
              </a> -->
              <a class="play-btn" title="Play" data-fancybox [href]="response_F[currentSlide]?.field_video" tabindex="0"
              (click)="vimeoOpenClick()">
                  <img [src]="'assets/img/play_button.png'" />
              </a>
            </span>
          </span>
        </span>

      </div>
    </div>
  </div>

</div>
