import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable()
export class RcycHomesService {
  constructor(private http: HttpClient) {}
  // Service to call dynamic content
  API_BASE_URL: any = AppConfig.API_BASE_URL;
  ELASTIC_API_BASE_URL: any = AppConfig.ELASTIC_API_BASE_URL;


  Homecontent_sectionA() {
    const url = this.API_BASE_URL + '/api/v1/home/video/a?_format=json';
    return this.http.get(url);
  }

  Homecontent_sectionB() {
    const url = this.API_BASE_URL + '/api/v1/home/header/b?_format=json';
    return this.http.get(url);
  }

  Homecontent_sectionC() {
    const url = this.API_BASE_URL + '/api/v1/home/content/c?_format=json';
    return this.http.get(url);
  }

  Homecontent_sectionD() {
    const url = this.API_BASE_URL + '/api/v1/home/video/d?_format=json';
    return this.http.get(url);
  }

  Homecontent_sectionE() {
    const url = this.API_BASE_URL + '/api/v1/home/content/e?_format=json';
    return this.http.get(url);
  }

  Homecontent_sectionF() {
    const url = this.API_BASE_URL + '/api/v1/home/content/f?_format=json';
    return this.http.get(url);
  }

  find_cruise(region) {
    const url = this.ELASTIC_API_BASE_URL + '/api/criteria-result';
    let body: any;
    if (region) {
      body = region;
    } else {
      body = {
        yachtSearchDTO: ''
      };
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(url, { headers: headers, params: body });
    // return this.http.post(url, body, { headers: headers });
  }
}
