<div *ngIf="isRaqGenericOrSpecific" id="rcyc-request-quote-bform" class="bodyContentOuter">
	<!-- <app-rcyc-error #errorModal></app-rcyc-error> -->
	<!-- Section - 1 -->

	<!-- Section - 2 -->
	<section class="content_section">
		<div class="l-container-wrapper">
			<div class="l-container intro-header leadinto">
				<div class="l-container row is-8-4">

					<div *ngIf="apiResponseBStatus" class="body-copy">
						<div class="headline-block c01_D intro-header leadinto">
							<h4 *ngIf="apiResponseB?.field_sub_header" id="pageSubTitleId"
								[title]="apiresponseB?.field_sub_header | convertHtml"
								[innerHtml]="apiResponseB?.field_sub_header"></h4>
							<h3 *ngIf="previousUrlStatus && !voyagePageUrlStatus" (keyup.enter)="backToPrevPage()"
								(click)="backToPrevPage()" tabindex="0" class="backBtn">
								&lt;BACK TO RESULTS</h3>
									<h1 *ngIf="apiResponseB?.title" id="pageTitleId"
										[title]="apiresponseB?.title | convertHtml" class="headline"
										[innerHtml]="apiResponseB?.title"></h1>
						</div>
						<p *ngIf="apiResponseB?.field_summery" [innerHtml]="apiResponseB?.field_summery"></p>
					</div>

					<!-- left side -->
					<div *ngIf="false" class="column is-3">
						<div class="headline-block">
							<h2 class="headline lead_form_left_head">VOYAGE</h2>
						</div>
						<div class="feature-block flex-item">
							<img *ngIf="voyageImagePath" class="feature-block__image" [src]="voyageImagePath">
							<h3 *ngIf="apiResponseVoyage?.voyageEmbarkPort && apiResponseVoyage?.voyageDisembarkPort"
								class="media-object__headline media-object__headline--large"
								innerHtml="{{apiResponseVoyage?.voyageEmbarkPort}} TO {{apiResponseVoyage?.voyageDisembarkPort}}">
							</h3>
							<p *ngIf="apiResponseVoyage?.voyageStartDate" class="feature-block__body"
								innerHtml="{{apiResponseVoyage?.voyageStartDate | date:'MMMM d' :'UTC'}}, {{apiResponseVoyage?.voyageStartDate | date:'y' :'UTC'}} | Voyage #{{voyageId}}">
							</p>
						</div>
					</div>

					<!-- right side -->
					<div class="column is-9 lead_form_right">
						<div>
							<p><span id="requiredField">*</span> Indicates required field</p>
						</div>
						<div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
							<span class="error">{{tokenErrorMsg}}</span>
						</div>
						<div id="formSubmitErrorId" *ngIf="quoteFormStatus">
							<span class="error">{{tokenMessage}}</span>
						</div>
						<form id="requestCallFormId" name="requestCallForm" [formGroup]="leadGenForm" novalidate
							autocomplete="false">
							<fieldset>

								<div class="basic-form_group lead_form_field formFirstRow">
									<!-- name_sec -->
									<div class="row is-6-6">
										<div class="column is-6 form_fields">
											<label class="basic-form_label form_label basic-form_label-required">First
												Name</label>
											<input id="firstNameId" class="basic-form_input basic-form_input-half"
												type="text" formControlName="firstname" placeholder="First Name"
												maxlength="30">
											<p class="errorptag"
												*ngIf="leadGenForm.controls.firstname.invalid && (leadGenForm.controls.firstname.dirty || leadGenForm.controls.firstname.touched)">
												<span *ngIf="leadGenForm.controls.firstname.errors.required"
													class="error">This field is required</span>
												<span
													*ngIf="!leadGenForm.controls.firstname.errors.required&&leadGenForm.controls.firstname.errors.pattern"
													class="error">Please enter a valid first name </span>
											</p>
										</div>
										<div class="column is-6 form_fields">
											<label class="basic-form_label form_label basic-form_label-required">Last
												Name</label>
											<input id="lastNameId" class="basic-form_input basic-form_input-half"
												type="text" formControlName="lastname" placeholder="Last Name"
												maxlength="60">
											<p class="errorptag"
												*ngIf="leadGenForm.controls.lastname.invalid && (leadGenForm.controls.lastname.dirty || leadGenForm.controls.lastname.touched)">
												<span *ngIf="leadGenForm.controls.lastname.errors.required"
													class="error">This field is required</span>
												<span
													*ngIf="!leadGenForm.controls.lastname.errors.required&&leadGenForm.controls.lastname.errors.pattern"
													class="error">Please enter a valid last name </span>
											</p>
										</div>
									</div>
									<!-- name_sec -->
								</div>

								<div class="basic-form_group lead_form_field">
									<div class="row is-6-6">
										<div class="row is-6-6">
											<div class="column is-6 form_fields">
												<label
													class="basic-form_label form_label basic-form_label-required">Country/Region
													of
													Residence</label>
												<select id="selectCountryId" class="basic-form_input basic-form_select"
													formControlName="country">
													<option id="selectCountryId_0" value="">Select Country/Region of
														Residence</option>
													<option *ngFor="let county of countryListArray;let i=index"
														id="countryOptionId_{{i+1}}" value="{{county.code}}">
														{{county.name}}</option>
												</select>
												<span
													*ngIf="leadGenForm.controls.country.invalid && (leadGenForm.controls.country.dirty || leadGenForm.controls.country.touched) && leadGenForm.controls.country.errors.required"
													class="error">This field is required</span>
											</div>
											<div class="column is-6 form_fields">
												<label class="basic-form_label form_label"
													[ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
													ZIP/Postal Code (Required for USA/Canada)</label>
												<input id="zipCodeId" class="basic-form_input basic-form_input-half"
													type="text" formControlName="zipcode" placeholder="ZIP/Postal Code"
													[maxlength]="zipcodeMaxLength" (input)="checkZipcodeFormat($event)">
												<p class="errorptag"
													*ngIf="leadGenForm.controls.zipcode.invalid && (leadGenForm.controls.zipcode.dirty || leadGenForm.controls.zipcode.touched) ">
													<span *ngIf="leadGenForm.controls.zipcode.errors.required"
														class="error">This field is
														required</span>
													<span
														*ngIf="!leadGenForm.controls.zipcode.errors.required&&leadGenForm.controls.zipcode.errors.pattern"
														class="error">ZIP/Postal Code is not valid </span>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div class="basic-form_group lead_form_field">
									<div class="row is-6-6">

										<div class="column is-6 form_fields">
											<label
												class="basic-form_label form_label basic-form_label-required">Email</label>
											<input id="emailId" class="basic-form_input basic-form_input-half"
												type="email" maxlength="60" formControlName="email" placeholder="Email">
											<p class="errorptag"
												*ngIf="leadGenForm.controls.email.invalid && (leadGenForm.controls.email.dirty || leadGenForm.controls.email.touched)">
												<span *ngIf="leadGenForm.controls.email.errors.required"
													class="error">This field is required</span>
												<span
													*ngIf="!leadGenForm.controls.email.errors.required&&leadGenForm.controls.email.errors.pattern"
													class="error">
													<!-- Please enter a valid email address (Example: johndoe@example.com) -->
													Enter a valid email address (e.g. name@example.com)
												</span>
											</p>
										</div>
										<div class="column is-6 form_fields">
											<div class="row">
												<div class="column">

													<div id="phoneFlagContainerId" class="phoneFlagContainer"
														(clickOutside)="onClickedOutside($event)">
														<label class="basic-form_label form_label"
															[ngClass]="{'basic-form_label-required': phNumReqStatusDynamic}">Phone</label>
														<div class="row is-3-9">
															<div class="column is-3 phoneFlagOuter"
																(keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
																(click)="flagCodeClickStatus = !flagCodeClickStatus"
																tabindex='0'>
																<span class="flagOuter"><img
																		[src]="userCountryDetails?.flagUrl"
																		class="flagImg" /></span>
																<span class="countryCodeOuter">
																	{{userCountryDetails?.dial_code}}</span>
															</div>

															<!-- Country List -->
															<div *ngIf="flagCodeClickStatus" class="countryResultOuter">
																<div *ngIf="flagCodeClickStatus"
																	class="countryResultInner">
																	<div class="countrySearchOuter">
																		<input type="text" [(ngModel)]="countrySearch"
																			[ngModelOptions]="{standalone: true}" />
																	</div>
																	<ul *ngIf="phoneDataArray.length"
																		class="countrySearchListOuter">
																		<li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
																			(keyup.enter)="selectCountryClick(item)"
																			(click)="selectCountryClick(item)"
																			[ngStyle]="{'border-bottom': 
																					!countrySearch && 
																					( 
																						( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
																						( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )                                        
																					)
																					? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
																			(focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
																			<span
																				[innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
																			({{item?.dial_code}})
																		</li>
																	</ul>
																	<p class="noResultsFound letterSpacing"
																		*ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length">
																		No results
																		match "{{countrySearch}}"</p>
																</div>
															</div>
															<!-- Country List -->

															<div class="column is-9 phoneNumberInputOuter">
																<input id="phoneId"
																	class="basic-form_input basic-form_input-half phoneNumberInput"
																	*ngIf="userCountryDetails?.defaultFormat" type="tel"
																	formControlName="phone"
																	[(placeholder)]="userCountryDetails.defaultFormat"
																	[maxlength]="leadGenForm?.controls?.phone?.errors?.phoneNoMaxLength"
																	[textMask]="{mask: mask, guide:false}"
																	(input)="phoneValueChangeFn()" />
															</div>
														</div>
														<p class="errorptag"
															*ngIf="leadGenForm.controls.phone.invalid && (leadGenForm.controls.phone.dirty || leadGenForm.controls.phone.touched)">
															<span *ngIf="leadGenForm.controls.phone.errors?.required"
																class="phoneNoError error">This field is
																required</span>
															<span
																*ngIf="!leadGenForm.controls.phone.errors?.required&&leadGenForm.controls.phone.errors?.phoneIncorrect"
																class="phoneNoError error">Enter a valid phone number
																(e.g. {{ userCountryDetails?.defaultPhoneNumber }})
															</span>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="!isRaqSpecific" class="basic-form_group">
									<div class="row is-12">
										<div class="column is-12 form_fields">
											<label
												class="basic-form_label form_label">Preferred
												Voyage</label>
											<select id="selectVoyageId" class="basic-form_input basic-form_select"
												formControlName="preferredVoyage">
												<option id="selectVoyageId_0" value="">Select Preferred Voyage</option>
												<option *ngFor="let voyage of voyageListArray;let i=index" id="countryOptionId_{{i+1}}" value="{{voyage.voyageId}}">
													{{voyage.voyageStartDate | date :'MMM dd, y' : voyage.voyageStartDate.substring( voyage.voyageStartDate.length - 6
													)}} | {{voyage.nights}}
													Nights | {{ ( voyage.voyageEmbarkPort != voyage.voyageDisembarkPort ) ? ( voyage.voyageEmbarkPort | titlecase ) +'
													to '+ ( voyage.voyageDisembarkPort | titlecase ) : (voyage.voyageEmbarkPort | titlecase ) +' Roundtrip' }}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div *ngIf="apiResponseCStatus && !showTermUS" class="termsSection">
									<div *ngIf="apiResponseC?.field_term2&&showPhoneopt" class="eachTermSection">
										<input id="phoneOptId" type="checkbox" value="true"
											formControlName="isAgreeToContact" aria-labelledby="phoneMarketing"
											(change)="phoneOptValueChanged()">
										<p class="termContent" *ngIf="!showPhoneOptRequiredText" id="phoneMarketing"
											[innerHtml]="apiResponseC?.field_term2"></p>
										<p class="termContent" *ngIf="showPhoneOptRequiredText" id="phoneMarketing"
											[innerHtml]="apiResponseC?.field_term2_required"></p>
										<p class="errorptag marketingError" *ngIf="showPhoneOptRequiredText">
											<span class="emailError error"
												[innerHtml]="drupalErrorMessages?.field_phone_opt_in_required"></span>
										</p>
									</div>
									<div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
										<input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail"
											aria-labelledby="emailMarketing">
										<p class="termContent" id="emailMarketing"
											[innerHtml]="apiResponseC?.field_term1"></p>
									</div>
								</div>
								<div class="basic-form_group">
									<div class="row is-12">
										<div class="column is-12 form_fields">
											<label class="basic-form_label form_label">Comments</label>
											<textarea id="commentId" class="basic-form_input" rows="4"
												formControlName="comment" maxlength="200"></textarea>
										</div>
									</div>
								</div>
								<div *ngIf="apiResponseCStatus && showTermUS" class="termsSection">
									<div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
										<input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail"
											aria-labelledby="emailMarketing">
										<p class="termContent" id="emailMarketing"
											[innerHtml]="apiResponseC?.field_term1"></p>
									</div>
								</div>
								<!-- Remove *ngIf to show sms opt option -->
								<div class="basic-form_group" *ngIf="false">
									<label class="basic-form_label form_label">Is this a mobile phone number?
										(Optional)</label><br>
									<div class="form_radio_buttons">
										<input type="radio" id="mobileRadioYes" formControlName="isMobile" value="true"
											class="mobileRadio">
										<label class="radio_label" for="mobileRadioYes">Yes</label>
										<input type="radio" id="mobileRadioNo" formControlName="isMobile" value="false"
											class="mobileRadio" [checked]="true">
										<label class="radio_label" for="mobileRadioNo">No</label><br>
									</div>

									<div *ngIf="isMobilebutton=='true'" class="termsSection">
										<p class="mobiletext">May we contact you via SMS text messaging?</p>
										<div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
											<input id="mobileOptId" type="checkbox" value="true"
												formControlName="isAgreeToSms" aria-labelledby="mobileMarketing">
											<p class="termContent mobiletext" id="mobileMarketing"
												[innerHtml]="apiResponseC?.field_term3"></p>
										</div>
									</div>
								</div>

							</fieldset>
						</form>
						<div id="terms" *ngIf="apiResponseCStatus && apiResponseC?.field_summery && !showTermUS"
							[innerHtml]="apiResponseC?.field_summery"></div>
						<div id="terms" *ngIf="apiResponseCStatus && apiResponseC?.field_summery && showTermUS"
							[innerHtml]="apiResponseC?.field_summary_us"></div>
						<div class="row is-6-6">
							<div class="column is-6">
								<div class="row is-6-6">
									<div class="column is-6 submit_btn">
										<input type="submit" id="requestQuoteSubmitId" (click)="leadGenSubmit()"
											[disabled]="leadGenForm?.controls?.firstname?.invalid ||
											 leadGenForm?.controls?.lastname?.invalid ||
											 leadGenForm?.controls?.country?.invalid ||
											 leadGenForm?.controls?.email?.invalid ||
											 leadGenForm?.controls?.zipcode?.invalid ||
											 leadGenForm?.controls?.phone?.errors?.required ||
											 leadGenForm.controls?.phone?.errors?.phoneIncorrect==true ||
											 !userArea ||
											 showPhoneOptRequiredText" value="Submit">
									</div>
									<div class="column is-6 clear_btn">
										<input type="reset" id="requestQuoteResetId" (click)="ngOnInit()" value="Clear">
									</div>
								</div>
							</div>
							<div class="column is-6"></div>
						</div>
					</div>
					<div *ngIf="!voyageId || selectedVoyageId" class="column is-3"></div>
				</div>
			</div>
		</div>
	</section>

</div>

<!-- REQUEST A QUOTE in Offers & Programs STARTS -->
<div *ngIf="!isRaqGenericOrSpecific" id="rcyc-request-quote-offerPrograms">
	<section class="content_section">
		<div class="l-container-wrapper">
			<div class="l-container intro-header leadinto">
				<div class="row is-8-4">
					<div *ngIf="apiResponseBStatus" class="row body-copy">
						<div class="headline-block c01_D intro-header leadinto">
							<h1 *ngIf="apiResponseB?.title" id="pageTitleId" [title]="apiresponseB?.title | convertHtml"
								class="headline pageTitle" [innerHtml]="apiResponseB?.title"></h1>
							<p id="requiredField"><span>*</span> Indicates required field</p>
						</div>
					</div>
					<!-- right side -->
					<div class="row is-12 lead_form_right">
						<div class="formErrorSec">
							<div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
								<span class="error">{{tokenErrorMsg}}</span>
							</div>
						</div>
						<form id="requestCallFormId" name="requestCallForm" [formGroup]="leadGenForm" novalidate
							autocomplete="false">
							<fieldset>
								<div class="basic-form_group lead_form_field formFirstRow">
									<div class="row is-4-4-4">
										<div class="column is-4 form_fields">
											<label class="basic-form_label form_label basic-form_label-required">First
												Name</label>
											<input id="firstNameId" class="basic-form_input basic-form_input-half"
												type="text" formControlName="firstname" placeholder="First Name"
												maxlength="30"
												[ngClass]="{'error-borderBtm': (leadGenForm.controls.firstname.invalid &&
													(leadGenForm.controls.firstname.dirty || leadGenForm.controls.firstname.touched || specialOfferFormErrorMsg))&&
												 (leadGenForm.controls.firstname.errors.required || (!leadGenForm.controls.firstname.errors.required&&leadGenForm.controls.firstname.errors.pattern) ) }"
												(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
											<p class="errorptag"
												*ngIf="leadGenForm.controls.firstname.invalid &&
													(leadGenForm.controls.firstname.dirty || leadGenForm.controls.firstname.touched || specialOfferFormErrorMsg)">
												<span *ngIf="leadGenForm.controls.firstname.errors.required"
													class="error">This field is required</span>
												<span
													*ngIf="!leadGenForm.controls.firstname.errors.required&&leadGenForm.controls.firstname.errors.pattern"
													class="error">Please enter a valid first name </span>
											</p>
										</div>
										<div class="column is-4 form_fields">
											<label class="basic-form_label form_label basic-form_label-required">Last
												Name</label>
											<input id="lastNameId" class="basic-form_input basic-form_input-half"
												type="text" formControlName="lastname" placeholder="Last Name"
												maxlength="60"
												[ngClass]="{'error-borderBtm': ( leadGenForm.controls.lastname.invalid &&
													(leadGenForm.controls.lastname.dirty || leadGenForm.controls.lastname.touched || specialOfferFormErrorMsg) )&&
												 (leadGenForm.controls.lastname.errors.required || (!leadGenForm.controls.lastname.errors.required&&leadGenForm.controls.lastname.errors.pattern) ) }"
												(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
											<p class="errorptag"
												*ngIf="leadGenForm.controls.lastname.invalid &&
													(leadGenForm.controls.lastname.dirty || leadGenForm.controls.lastname.touched || specialOfferFormErrorMsg)">
												<span *ngIf="leadGenForm.controls.lastname.errors.required"
													class="error">This field is required</span>
												<span
													*ngIf="!leadGenForm.controls.lastname.errors.required&&leadGenForm.controls.lastname.errors.pattern"
													class="error">Please enter a valid last name </span>
											</p>
										</div>
										<div class="column is-4 form_fields">
											<label
												class="basic-form_label form_label basic-form_label-required">Country/Region
												of
												Residence</label>
											<div class="specialCountryOuter">
												<div class="specialCountryInputOuter">
													<div class="inputCloseOuter">
														<input id="specialCountryInputId"
															class="basic-form_input basic-form_input-half" type="text"
															placeholder="Select Country/Region of Residence"
															maxlength="60" formControlName="country"
															(click)="specialCountryInputClickStatus=true;specialCountryInputCloseBtnStatus=true"
															[ngClass]="{'error-borderBtm': ( (leadGenForm.controls.country.invalid &&
																	(leadGenForm.controls.country.dirty || leadGenForm.controls.country.touched || specialOfferFormErrorMsg))&&
																		(leadGenForm.controls.country.errors.required ) )
																	||( ( leadGenForm.controls.country.valid && (leadGenForm.controls.country.dirty || leadGenForm.controls.country.touched) ) &&
																	!isSpecialCountrySelect)}" />
														<span *ngIf="specialCountryInputCloseBtnStatus"
															class="icon-close" (click)="specialCountryInputCloseClick()"
															[ngClass]="{'error-specialCountryCloseBtn': ( (leadGenForm.controls.country.invalid &&
																	(leadGenForm.controls.country.dirty || leadGenForm.controls.country.touched || specialOfferFormErrorMsg))&&
																	(leadGenForm.controls.country.errors.required ) )
																||( ( leadGenForm.controls.country.valid && (leadGenForm.controls.country.dirty || leadGenForm.controls.country.touched) ) &&
																!isSpecialCountrySelect)}"></span>

														<p class="errorptag"
															*ngIf="leadGenForm.controls.country.invalid &&
																		(leadGenForm.controls.country.dirty || leadGenForm.controls.country.touched || specialOfferFormErrorMsg)">
															<span *ngIf="leadGenForm.controls.country.errors.required"
																class="error">This field is
																required</span>
														</p>
														<p class="errorptag"
															*ngIf="leadGenForm.controls.country.valid && (leadGenForm.controls.country.dirty || leadGenForm.controls.country.touched)">
															<span *ngIf="!isSpecialCountrySelect" class="error">Please
																select a country</span>
														</p>
													</div>
													<div *ngIf="specialCountryInputClickStatus"
														class="specialCountryListOuter">
														<ul *ngIf="countryListArray?.length" class="specialCountryUl">
															<li class="specialCountryLi"
																*ngFor="let item of countryListArray | specialCountrynameFilter: leadGenForm.controls.country.value;let i=index"
																id="specialCountryListItem_{{i}}"
																[innerHTML]="item?.name | SpecialCountryHighlight: leadGenForm.controls.country.value"
																(click)="specialCountryLiClick(item);selectCountryClick(item);">
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="basic-form_group lead_form_field">
									<div class="row is-4-4-4">
										<div class="column is-4 form_fields">
											<label class="basic-form_label form_label"
												[ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
												ZIP/Postal Code (Required for USA/Canada)</label>
											<input id="zipCodeId" class="basic-form_input basic-form_input-half"
												type="text" formControlName="zipcode" placeholder="ZIP/Postal Code"
												[maxlength]="zipcodeMaxLength" (input)="checkZipcodeFormat($event)"
												[ngClass]="{'error-borderBtm': ( leadGenForm.controls.zipcode.invalid &&
														(leadGenForm.controls.zipcode.dirty || leadGenForm.controls.zipcode.touched || specialOfferFormErrorMsg) )&&
													 (leadGenForm.controls.zipcode.errors.required || (!leadGenForm.controls.zipcode.errors.required&&leadGenForm.controls.zipcode.errors.pattern)) }"
												(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
											<p class="errorptag"
												*ngIf="leadGenForm.controls.zipcode.invalid &&
														(leadGenForm.controls.zipcode.dirty || leadGenForm.controls.zipcode.touched || specialOfferFormErrorMsg) ">
												<span *ngIf="leadGenForm.controls.zipcode.errors.required"
													class="error">This field is
													required</span>
												<span
													*ngIf="!leadGenForm.controls.zipcode.errors.required&&leadGenForm.controls.zipcode.errors.pattern"
													class="error">ZIP/Postal Code is not valid </span>
											</p>
										</div>
										<div class="column is-4 form_fields">
											<label
												class="basic-form_label form_label basic-form_label-required">Email</label>
											<input id="emailId" class="basic-form_input basic-form_input-half"
												type="email" maxlength="60" formControlName="email" placeholder="Email"
												[ngClass]="{'error-borderBtm': ( leadGenForm.controls.email.invalid &&
													(leadGenForm.controls.email.dirty || leadGenForm.controls.email.touched || specialOfferFormErrorMsg) )&&
												 (leadGenForm.controls.email.errors.required || (!leadGenForm.controls.email.errors.required&&leadGenForm.controls.email.errors.pattern) ) }"
												(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false" />
											<p class="errorptag" *ngIf="leadGenForm.controls.email.invalid &&
													(leadGenForm.controls.email.dirty || leadGenForm.controls.email.touched || specialOfferFormErrorMsg)">
												<span *ngIf="leadGenForm.controls.email.errors.required"
													class="error">This field is required</span>
												<span
													*ngIf="!leadGenForm.controls.email.errors.required&&leadGenForm.controls.email.errors.pattern"
													class="error">
													<!-- Please enter a valid email address (Example: johndoe@example.com) -->
													Enter a valid email address (e.g. name@example.com)
												</span>
											</p>
										</div>
										<div class="column is-4 form_fields">

											<div id="phoneFlagContainerId" class="phoneFlagContainer"
												(clickOutside)="onClickedOutside($event)">
												<label class="basic-form_label form_label"
													[ngClass]="{'basic-form_label-required': phNumReqStatusDynamic}">Phone</label>
												<div class="row is-3-9">
													<div class="column is-3 phoneFlagOuter"
														(keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
														(click)="flagCodeClickStatus = !flagCodeClickStatus;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
														tabindex='0'
														[ngClass]="{'error-borderBtm': (leadGenForm.controls.phone.invalid &&
																(leadGenForm.controls.phone.dirty || leadGenForm.controls.phone.touched || specialOfferFormErrorMsg))&&
																 (leadGenForm.controls.phone.errors.required || (!leadGenForm.controls.phone.errors.required&&leadGenForm.controls.phone.errors.phoneIncorrect) ) }">
														<span class="flagOuter"><img [src]="userCountryDetails?.flagUrl"
																class="flagImg" /></span>
														<span class="countryCodeOuter">
															{{userCountryDetails?.dial_code}}</span>
													</div>

													<!-- Country List -->
													<div *ngIf="flagCodeClickStatus" class="countryResultOuter">
														<div *ngIf="flagCodeClickStatus" class="countryResultInner">
															<div class="countrySearchOuter">
																<input type="text" [(ngModel)]="countrySearch"
																	[ngModelOptions]="{standalone: true}" />
															</div>
															<ul *ngIf="phoneDataArray.length"
																class="countrySearchListOuter">
																<li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
																	(keyup.enter)="selectCountryClick(item)"
																	(click)="selectCountryClick(item)" [ngStyle]="{'border-bottom': 
																			!countrySearch && 
																			( 
																				( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
																				( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )                                        
																			)
																			? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
																	(focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
																	<span
																		[innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
																	({{item?.dial_code}})
																</li>
															</ul>
															<p class="noResultsFound letterSpacing"
																*ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length">
																No results
																match "{{countrySearch}}"</p>
														</div>
													</div>
													<!-- Country List -->

													<div class="column is-9 phoneNumberInputOuter">
														<input id="phoneId"
															class="basic-form_input basic-form_input-half phoneNumberInput"
															*ngIf="userCountryDetails?.defaultFormat" type="tel"
															formControlName="phone"
															[(placeholder)]="userCountryDetails.defaultFormat"
															[maxlength]="leadGenForm?.controls?.phone?.errors?.phoneNoMaxLength"
															[ngClass]="{'error-borderBtm': (leadGenForm.controls.phone.invalid &&
																	(leadGenForm.controls.phone.dirty || leadGenForm.controls.phone.touched || specialOfferFormErrorMsg))&&
																 (leadGenForm.controls.phone.errors.required || (!leadGenForm.controls.phone.errors.required&&leadGenForm.controls.phone.errors.phoneIncorrect) ) }"
															(click)="specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
															(input)="phoneValueChangeFn()"
															[textMask]="{mask: mask, guide:false}" />
													</div>
												</div>
												<p class="errorptag"
													*ngIf="leadGenForm?.controls?.phone?.invalid &&
															(leadGenForm.controls.phone.dirty || leadGenForm?.controls?.phone?.touched || specialOfferFormErrorMsg)">
													<span *ngIf="leadGenForm.controls.phone.errors.required"
														class="phoneNoError error">This field is
														required</span>
													<span
														*ngIf="!leadGenForm?.controls?.phone?.errors?.required&&leadGenForm?.controls?.phone?.errors?.phoneIncorrect"
														class="phoneNoError error">Enter a valid phone number (e.g. {{
														userCountryDetails?.defaultPhoneNumber }} ) </span>
												</p>
											</div>
										</div>
									</div>
								</div>

								<div class="basic-form_group lead_form_field">
									<div class="row is-12">
										<div class="column is-12 form_fields">
											<label
												class="basic-form_label form_label basic-form_label-required">Preferred
												Voyage</label>
											<div class="specialCountryOuter">
												<div class="specialCountryInputOuter">
													<div class="inputCloseOuter">
														<input id="specialSelectedVoyageId"
															class="basic-form_input basic-form_input-half" type="text"
															placeholder="Select Preferred Voyage" maxlength="60"
															formControlName="preferredVoyage"
															(click)="specialSelectedVoyageClickStatus=true;specialSelectedVoyageCloseBtnStatus=true"
															[ngClass]="{'error-borderBtm': ( (leadGenForm.controls.preferredVoyage.invalid &&
															(leadGenForm.controls.preferredVoyage.dirty || leadGenForm.controls.preferredVoyage.touched || specialOfferFormErrorMsg))&&
																(leadGenForm.controls.preferredVoyage.errors.required ) )
															||( ( leadGenForm.controls.preferredVoyage.valid && (leadGenForm.controls.preferredVoyage.dirty || leadGenForm.controls.preferredVoyage.touched) ) &&
															!isSpecialVoyageSelect)}" />
														<span *ngIf="specialSelectedVoyageCloseBtnStatus"
															class="icon-close"
															(click)="specialSelectedVoyageCloseClick()" [ngClass]="{'error-specialCountryCloseBtn': ( (leadGenForm.controls.preferredVoyage.invalid &&
															(leadGenForm.controls.preferredVoyage.dirty || leadGenForm.controls.preferredVoyage.touched || specialOfferFormErrorMsg))&&
															(leadGenForm.controls.preferredVoyage.errors.required ) )
														||( ( leadGenForm.controls.preferredVoyage.valid && (leadGenForm.controls.preferredVoyage.dirty || leadGenForm.controls.preferredVoyage.touched) ) &&
														!isSpecialVoyageSelect)}"></span>

														<p class="errorptag"
															*ngIf="leadGenForm.controls.preferredVoyage.invalid &&
																(leadGenForm.controls.preferredVoyage.dirty || leadGenForm.controls.preferredVoyage.touched || specialOfferFormErrorMsg)">
															<span
																*ngIf="leadGenForm.controls.preferredVoyage.errors.required"
																class="error">This field is
																required</span>
														</p>
														<p class="errorptag"
															*ngIf="leadGenForm.controls.preferredVoyage.valid && (leadGenForm.controls.preferredVoyage.dirty || leadGenForm.controls.preferredVoyage.touched)">
															<span *ngIf="!isSpecialCountrySelect" class="error">Please
																select a country</span>
														</p>
													</div>
													<div *ngIf="specialSelectedVoyageClickStatus"
														class="specialCountryListOuter">
														<ul *ngIf="voyageListArray?.length" class="specialCountryUl">
															<li class="specialCountryLi"
																*ngFor="let item of voyageListArray;let i=index"
																id="specialCountryListItem_{{i}}"
																[innerHTML]="item?.voyageName"
																(click)="specialPreferredVoyageLiClick(item);selectCountryClick(item);">
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div *ngIf="apiResponseCStatus" class="row termsSection">
									<div *ngIf="apiResponseC?.field_term2 && showPhoneopt" class="eachTermSection">
										<input id="phoneOptId" type="checkbox" value="true"
											formControlName="isAgreeToContact"
											(change)="phoneOptValueChanged();specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
											aria-labelledby="phoneMarketing">
										<p class="termContent" id="phoneMarketing" *ngIf="!showPhoneOptRequiredText"
											[innerHtml]="apiResponseC?.field_term2"></p>
										<p class="termContent" id="phoneMarketing" *ngIf="showPhoneOptRequiredText"
											[innerHtml]="apiResponseC?.field_term2_required"></p>
										<p class="errorptag marketingError" *ngIf="showPhoneOptRequiredText">
											<span class="emailError error"
												[innerHtml]="drupalErrorMessages?.field_phone_opt_in_required"></span>
										</p>
									</div>
									<div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
										<input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail"
											[checked]="emailReceiveCB"
											(change)="emailReceiveCB=!emailReceiveCB;emailCBManual=!emailCBManual;specialCountryInputCloseBtnStatus=false;specialCountryInputClickStatus=false"
											aria-labelledby="emailMarketing">
										<p class="termContent" id="emailMarketing"
											[innerHtml]="apiResponseC?.field_term1"></p>
									</div>
								</div>
							</fieldset>
						</form>
						<div class="row declaration"
							*ngIf="apiResponseCStatus && apiResponseC?.field_summery && !showTermUS"
							[innerHtml]="apiResponseC?.field_summery"></div>
						<div class="row declaration"
							*ngIf="apiResponseCStatus && apiResponseC?.field_summary_us && showTermUS"
							[innerHtml]="apiResponseC?.field_summary_us"></div>
						<div class="row">
							<div class="submit_btn specialRAQSubmitBtn">
								<input type="submit" id="requestQuoteSubmitId" (click)="leadGenSubmit()" value="Submit">
							</div>
						</div>
					</div>
					<div *ngIf="!voyageId" class="column is-3"></div>
				</div>
			</div>
		</div>
	</section>
</div>
<!-- REQUEST A QUOTE in Offers & Programs ENDS -->