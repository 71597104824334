import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertHtml'
})
export class RcycDecodeInsideHtmlPipe implements PipeTransform {

  transform(value: string) {

    // console.log(value);
    if (value) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = value;
      return tempElement.textContent;
    } else {
      return ' ';
    }
  }

}
