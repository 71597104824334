<div id="rcyc-public-page-marquee">
  <div>
    <section class="mt60 section-1">
      <div class="l-container-wrapper">
        <div class="l-container headerDiv {{reservationStyle}}">
          <span *ngIf="marqueeMore?.length">
            <h2 *ngIf="marqueeMore" id="marqueeHeading" [innerHTML]="apiResponseWebsiteHeadings.field_sub_heading_20">Concierge Ashore</h2>
          </span>
          <div class="row is-12">
            <!-- FOR DESKTOP ONLY Starts-->
            <div class="desktop">
              <!-- <div *ngIf="!marqueeMore?.length">
                <div class="noDetailsFound">
                  No marquee events found.
                </div>
              </div> -->
              <!-- <div *ngIf="!marqueeMore?.length">
                  <div class="noDetailsFound">
                      No additional Marquee Event packages are available for reservation.
                  </div>
              </div> -->
              <div *ngIf="marqueeMore?.length">
                <div class="forLoop1" *ngFor="let item of marqueeMore;let j =index;">

                  <div *ngIf="!item?.arrowStatus" class="row is-8-4 eachItem" [ngClass]="{'firstItem1': j+1 == 1 }">
                    <div class="column is-8">
                      <div class="row is-3-9">
                        <div class="column is-3">
                          <img *ngIf="item?.field_thumbnail" class="moreInfoImg" [src]="item?.field_thumbnail" />
                        </div>
                        <div class="column is-9">
                          <h6 class="title" [title]="item?.tourName | convertHtml" [innerHTML]="item?.tourName"></h6>
                          <span class="subTitleContent" [innerHTML]="item?.summaryDesc"></span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <div class="column shore_excursion is-6-6 item_vertical">
                        <!-- 	<div class="column is-6">
                                      <img src="{{item.image}}"/>
                                    </div> -->
                        <div *ngIf="item.arrowStatus" id="eachShoreExcMoreInfoId_{{j+1}}"
                          class="column is-4 shoreExcursions textRight cursorPointer morePartDesk"
                          (keyup.enter)="marqueeMoreClick( $event, j, item?.tourCd, item, false)"
                          (click)="marqueeMoreClick( $event, j, item?.tourCd, item, false)">
                          <p>
                            <span tabindex="0" class="moreInfoDesk"> More Info </span>
                            <span class="underlinePort icon-arrow-up"></span>
                          </p>
                        </div>
                        <div *ngIf="!item.arrowStatus" id="eachShoreExcMoreInfoId_{{j+1}}"
                          class="column is-4 shoreExcursions textRight cursorPointer morePartDesk"
                          (keyup.enter)="marqueeMoreClick( $event, j, item?.tourCd,item, true)"
                          (click)="marqueeMoreClick( $event, j, item?.tourCd, item, true)">
                          <p>
                            <span tabindex="0" class="moreInfoDesk"> More Info </span>
                            <span class="underlinePort icon-arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div *ngIf="selectedMarqeeId == i+'more'" class="row is-12 excursion_pad_desc ">
                            <div class="detailDescrip">
                              <span [innerHTML]="item.detailedDesc"></span>
                            </div>
                          </div> -->

                  <div *ngIf="item?.arrowStatus" class="row is-12 excursion_pad_desc excursions-moreInfo-2"
                    [ngClass]="{'firstItem': j+1 == 1 }">
                    <div class="row is-9-3 rowSection-1">
                      <div class="column is-9">
                        <h6 class="title lessInfoTitle" [title]="item?.tourName | convertHtml"
                          [innerHTML]="item?.tourName"></h6>
                      </div>
                      <div class="column is-3 moreInfoSection"
                        (click)="marqueeMoreClick($event, j, tourCd, item, false)"
                        (keyup.enter)="marqueeMoreClick($event, j, item?.tourCd, item, false)">
                        <span class="icon-arrow-up moreInfoIconArrow underlinePort"></span>
                        <div class="textRight cursorPointer moreInfo moreInfoDesk" tabindex="0">Less Info
                        </div>
                      </div>
                    </div>
                    <div class="row detailDescrip">
                      <div class="column is-6-6">
                        <div class="column is-6">
                          <app-rcyc-ngu-carousel [portData]='carouselArrayMarquee'></app-rcyc-ngu-carousel>
                        </div>
                        <div class="column is-6">
                          <div class="row">
                              <div class="row rowSection rowSection-1-1">
                                  <div class="column is-8-4">
                                      <div class="column is-8">                                       
                                          <p>
                                              <label class="labelStyle">Duration:</label>
                                              <span
                                                  [innerHTML]="item?.durationHrs"></span>
                                              <span>hour<span
                                                      *ngIf="item?.durationHrs > 1">s</span></span>
                                          </p>
                                      </div>
                                      <div class="column is-4"></div>
                                  </div>
                              </div>
                          </div>
                          <div class="row" class="detailedDescription">
                            <span [innerHTML]="item?.detailedDesc"></span>
                            <div *ngIf="country_no" class="counrtyInfo">
                              <p class="moreInfoBlueBox">Please call {{country_no}} for more information</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FOR DESKTOP ONLY Ends-->


            <!-- FOR MOBILE ONLY Starts-->
            <div class="mobile" *ngIf="marqueeMore">
              <div class="forLoop" *ngFor="let item of marqueeMore;let j =index;">
                <div *ngIf="!item?.arrowStatus" class="row thumbnailMain">

                  <div class="row is-3-9">
                    <div class="column is-3 mainDivMobile mobileImage">
                      <img *ngIf="item?.field_thumbnail" class="thumbnailClassMobile" [src]="item?.field_thumbnail" />
                    </div>
                    <div class="column is-9 mainDivMobile mobileHeading">
                      <h6 class="title" [title]="item?.tourName | convertHtml" [innerHTML]="item?.tourName"></h6>
                      <div class="mainDivMobile mobileIconArrow">
                        <!-- <p *ngIf="item.arrowStatus" id="eachShoreExcMoreInfoMobId_{{i+1}}" class="mobile_arrow"
                          (click)="marqueeMoreClick($event, j,  item?.tourCd, item, false)">
                          <a>More</a>
                          <span class="cursorPointer icon-arrow-up"></span>
                        </p> -->
                        <p *ngIf="!item.arrowStatus" id="eachShoreExcMoreInfoMobId_{{j+1}}" class="mobile_arrow"
                          (click)="marqueeMoreClick($event, j,  item?.tourCd, item, true)">
                          <span class="cursorPointer icon-arrow-down"></span>
                        </p>
                      </div>
                      <div class="row subTitleContent" [innerHTML]="item?.summaryDesc"></div>
                    </div>
                  </div>


                </div>
                <div *ngIf="item.arrowStatus" class="excursionsDynamicContentDetails excursionsDynamicContentDetails1">
                  <div class="row is-12 excursionsDCD">
                    <div class="column is-12">
                      <div class="row mobileHeaderOuter item_{{j+1}}">
                        <div>
                          <img *ngIf="item.field_thumbnail" class="moreInfoImg" [src]="item.field_thumbnail" />
                        </div>
                        <div class="row is-9-3">
                          <div class="column is-9 title" [innerHtml]="item?.tourName"></div>
                          <div class="column is-3 moreInfoSection cursorPointer">
                            <p class="moreInfoIconArrowMobile"
                              (click)="marqueeMoreClick($event, j,  item?.tourCd, item, false)">
                              <span class="cursorPointer icon-arrow-up"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="excursionsDynamicContentDetails excursionsDynamicContentDetails2">
                      <div class="row is-12 excursionsDCD">
                        <div class="column is-12">
                          <div class="column item_{{j+1}}">
                            <app-rcyc-ngu-carousel class="row is-12" [portData]='carouselArrayMarquee'>
                            </app-rcyc-ngu-carousel>                            
                            <!-- <div class="row eachItemOuter">
                              <div class="column eachItem eachItem-1">
                                <h4 class="eachItem-line-1">category</h4>
                                 <h2 class="eachItem-line-2" [innerHTML]="item?.tourCatCd"></h2>	 -->
                                <!-- <h2 [innerHTML]="item?.tourCatCd_1"></h2>
                                <h4 class="tourCatCd_2" [innerHTML]="item?.tourCatCd_2"></h4>
                              </div>
                              <div class="column eachItem eachItem-2">
                                <h4 class="eachItem-line-1">activity</h4>
                                <img *ngIf="item?.activityLvl == 'Easy'" class="meters-image"
                                  src="assets/components/images/meters-1.png" />
                                <img *ngIf="item?.activityLvl == 'Medium'" class="meters-image"
                                  src="assets/components/images/meters-2.png" />
                                <img *ngIf="item?.activityLvl == 'Hard'" class="meters-image"
                                  src="assets/components/images/meters-3.png" /> -->
                                <!-- <h2 class="eachItem-line-2"></h2> -->
                                <!-- <h4 class="eachItem-line-3" [innerHTML]="item?.activityLvl"></h4>
                              </div>
                              <div class="column eachItem eachItem-3">
                                <h4 class="eachItem-line-1">duration</h4>
                                <h2 class="eachItem-line-2" [innerHTML]="item?.durationHrs"></h2>
                                <h4 class="eachItem-line-3">hours</h4>
                              </div> -->
                            <!-- </div> --> 

                            <div class="row">
                              <div class="row rowSection rowSection-1-1">
                                  <div class="column is-8-4">
                                      <div class="column is-8">                                       
                                          <p>
                                              <label class="labelStyle">Duration:</label>
                                              <span
                                                  [innerHTML]="item?.durationHrs"></span>
                                              <span>hour<span
                                                      *ngIf="item?.durationHrs > 1">s</span></span>
                                          </p>
                                      </div>
                                      <div class="column is-4"></div>
                                  </div>
                              </div>
                          </div>

                            <span class="detailedDescMobile" *ngIf="item?.detailedDesc"
                              id="eachShoreExcBodyMobId_{{i+1}}" [innerHtml]="item?.detailedDesc"></span>
                            <div *ngIf="country_no" class="counrtyInfo">
                              <p>Please call {{country_no}} for more information</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FOR MOBILE ONLY Ends-->
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
