<div *ngIf="isRequestEBrochureStatus" id="rcyc-page-request-brochure-asia" class="bodyContentOuter">

    <!-- Section - 1 -->
    <section class="content_section">
        <div class="l-container-wrapper">
            <div class="l-container intro-header leadinto">
                <div class="l-container row is-8-4">
                    <!-- Section Header and content -->
                    <div class="body-copy" *ngIf="apiresponseB">
                        <div class="headline-block c01_D intro-header leadinto">
                            <h4 *ngIf="apiresponseB?.field_sub_header"
                                [title]="apiresponseB?.field_sub_header | convertHtml"
                                [innerHTML]="apiresponseB?.field_sub_header"></h4>
                            <h1 *ngIf="apiresponseB?.title" class="headline" [title]="apiresponseB?.title | convertHtml"
                                [innerHTML]="apiresponseB?.title"></h1>
                        </div>
                        <div *ngIf="apiresponseB?.field_summery" [innerHTML]="apiresponseB?.field_summery"></div>
                    </div>

                    <!-- left side - Brochure review -->
                    <div class="column is-3">
                        <div class="headline-block" *ngIf="apiresponseC">
                            <h2 *ngIf="apiresponseC?.title" class="headline lead_form_left_head"
                                [title]="apiresponseC?.title | convertHtml" [innerHTML]="apiresponseC?.title"></h2>
                        </div>

                        <div *ngIf="apiresponseD">
                            <div _ngcontent-c3="" class="feature-block flex-item" *ngFor="let item of apiresponseD">
                                <img _ngcontent-c3="" [title]="item?.field_image_title_content | convertHtml"
                                    [alt]="item?.field_image_alt_content | convertHtml" class="feature-block__image"
                                    [src]="item?.field_image"
                                    src-fallback='assets/components/images/rcyc-request-brochure/reuqestbrochure.jpg'>
                                <h3 *ngIf="item?.title" _ngcontent-c3=""
                                    class="media-object__headline media-object__headline--large"
                                    [title]="item?.title | convertHtml" [innerHTML]="item?.title"></h3>
                            </div>
                        </div>
                    </div>

                    <!-- right side - form -->
                    <div class="column is-9 lead_form_right">
                        <div>
                            <p id="requiredField"><span>*</span> Indicates required field</p>
                        </div>

                        <div id="formSubmitErrorId" *ngIf="eBrochureFormStatus">
                            <span class="error">{{sessionExpiredMessages}}</span>
                        </div>

                        <form id="requestEbrochureFormId" name="requestEBrochureForm" [formGroup]="lead_gen_form" novalidate autocomplete="off">
                            <fieldset>
                                <div class="basic-form_group lead_form_field formFirstRow">
                                    <div class="row is-6-6">
                                        <!-- Honeypot Field -->
                                        <div class="column is-6 form_fields rcyc-hide-honey">
                                            <label class="basic-form_label form_label basic-form_label-required">Third
                                                Name</label>
                                            <input id="thirdNameId" class="basic-form_input basic-form_input-half" type="text" name="honey"
                                                placeholder="Third Name" maxlength="30" autocomplete='off'>
                                        </div>
                                        <!-- First Name -->
                                        <div class="column is-6 form_fields">
                                            <label class="basic-form_label form_label basic-form_label-required">First
                                                Name</label>
                                            <input id="firstNameId" class="basic-form_input basic-form_input-half" type="text"
                                                formControlName="firstname" placeholder="First Name" maxlength="30">
                                            <p class="errorptag"
                                                *ngIf="lead_gen_form?.controls?.firstname?.invalid && (lead_gen_form?.controls?.firstname?.dirty || lead_gen_form?.controls?.firstname?.touched)">
                                                <span *ngIf="lead_gen_form?.controls?.firstname?.errors?.required" class="error">This field is
                                                    required</span>
                                                <span
                                                    *ngIf="!lead_gen_form?.controls?.firstname?.errors?.required&&lead_gen_form?.controls?.firstname?.errors?.pattern"
                                                    class="error">Please check the name format </span>
                                            </p>
                                        </div>
                                        <!-- Last Name -->
                                        <div class="column is-6 form_fields">
                                            <label class="basic-form_label form_label basic-form_label-required">Last
                                                Name</label>
                                            <input id="lastNameId" class="basic-form_input basic-form_input-half" type="text"
                                                formControlName="lastname" placeholder="Last Name" maxlength="60">
                                            <p class="errorptag"
                                                *ngIf="lead_gen_form?.controls?.lastname?.invalid && (lead_gen_form?.controls?.lastname?.dirty || lead_gen_form?.controls?.lastname?.touched)">
                                                <span *ngIf="lead_gen_form?.controls?.lastname?.errors?.required" class="error">This field is
                                                    required</span>
                                                <span
                                                    *ngIf="!lead_gen_form?.controls?.lastname?.errors?.required&&lead_gen_form?.controls?.lastname?.errors?.pattern"
                                                    class="error">Please check the name format </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="basic-form_group lead_form_field">
                        
                                    <div class="row is-6-6">
                                        <!-- Country/Region of Residence -->
                                        <div class="column is-6 form_fields">
                                            <label class="basic-form_label form_label basic-form_label-required">Country/Region
                                                of
                                                Residence</label>
                                            <select id="selectCountryId" class="basic-form_input basic-form_select" formControlName="country">
                                                <option id="selectCountryId_0" value="">Select Country/Region of
                                                    Residence</option>
                                                <option *ngFor="let county of countryListArray;let i=index" id="countryOptionId_{{i+1}}"
                                                    value="{{county.code}}">{{county.name}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf="lead_gen_form?.controls?.country?.invalid && (lead_gen_form?.controls?.country?.dirty || lead_gen_form?.controls?.country?.touched) && lead_gen_form?.controls?.country?.errors?.required"
                                                class="error">This field is required</span>
                                        </div>
                                        <!-- ZIP/Postal Code -->
                                        <div class="column is-6 form_fields">
                                            <label class="basic-form_label form_label"
                                                [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic}">
                                                ZIP/Postal Code (Required for USA/Canada)</label>
                                            <input id="zipCodeId" class="basic-form_input basic-form_input-half" type="text"
                                                formControlName="zipcode" placeholder="ZIP/Postal Code" [maxlength]="zipcodeMaxLength"
                                                (input)="checkZipcodeFormat($event)">
                                            <p class="errorptag"
                                                *ngIf="lead_gen_form?.controls?.zipcode?.invalid && (lead_gen_form?.controls?.zipcode?.dirty || lead_gen_form?.controls?.zipcode?.touched) ">
                                                <span *ngIf="lead_gen_form?.controls?.zipcode?.errors?.required" class="error">This field is
                                                    required</span>
                                                <span
                                                    *ngIf="!lead_gen_form?.controls?.zipcode?.errors?.required&&lead_gen_form?.controls?.zipcode?.errors?.pattern"
                                                    class="error">ZIP/Postal Code is not valid </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="basic-form_group lead_form_field">
                                    <div class="row is-6-6">
                                        <!-- Email -->
                                        <div class="column is-6 form_fields">
                                            <label class="basic-form_label form_label basic-form_label-required">Email</label>
                                            <input id="emailId" class="basic-form_input basic-form_input-half" type="email" maxlength="60"
                                                formControlName="email" placeholder="Email">
                                            <p class="errorptag"
                                                *ngIf="lead_gen_form?.controls?.email?.invalid && (lead_gen_form?.controls?.email?.dirty || lead_gen_form?.controls?.email?.touched)">
                                                <span *ngIf="lead_gen_form?.controls?.email?.errors?.required" class="error">This field is
                                                    required</span>
                                                <span
                                                    *ngIf="!lead_gen_form?.controls?.email?.errors?.required&&lead_gen_form?.controls?.email?.errors?.pattern"
                                                    class="error">
                                                    <!-- Please enter a valid email address (Example: johndoe@example.com)  -->
                                                    Enter a valid email address (e.g. name@example.com)
                                                </span>
                                            </p>
                                        </div>
                                        <!-- Phone -->
                                        <div class="column is-6 form_fields">
                                            <div class="row">
                                                <div class="column">
                        
                                                    <div id="phoneFlagContainerId" class="phoneFlagContainer"
                                                        (clickOutside)="onClickedOutside($event)">
                                                        <label class="basic-form_label form_label"
                                                            [ngClass]="{'basic-form_label-required': phNumReqStatusDynamic}">Phone</label>
                                                        <div class="row is-3-9">
                                                            <div class="column is-3 phoneFlagOuter"
                                                                (keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
                                                                (click)="flagCodeClickStatus = !flagCodeClickStatus" tabindex='0'>
                                                                <span class="flagOuter"><img [src]="userCountryDetails?.flagUrl"
                                                                        class="flagImg" /></span>
                                                                <span class="countryCodeOuter">
                                                                    {{userCountryDetails?.dial_code}}</span>
                                                            </div>
                        
                                                            <!-- Country List -->
                                                            <div *ngIf="flagCodeClickStatus" class="countryResultOuter">
                                                                <div *ngIf="flagCodeClickStatus" class="countryResultInner">
                                                                    <!-- <input type="text" [(ngModel)]="countrySearch" (ngModelChange)="countrySearchChanged($event)" (ngModelChange)="searchCountryChange()"> -->
                                                                    <div class="countrySearchOuter">
                                                                        <input type="text" [(ngModel)]="countrySearch"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <ul *ngIf="phoneDataArray.length" class="countrySearchListOuter">
                                                                        <li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
                                                                            (keyup.enter)="selectCountryClick(item)"
                                                                            (click)="selectCountryClick(item)" [ngStyle]="{'border-bottom': 
                                                                                                        !countrySearch && 
                                                                                                        ( 
                                                                                                          ( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
                                                                                                          ( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )                                        
                                                                                                        )
                                                                                                        ? '1px solid #bfbfbf' : 'none'}"
                                                                            tabindex="0" class="eachPhoneCountryName"
                                                                            (focusout)="(i == (phoneDataArray?.length - 1) ) ? flagCodeClickStatus = false: '' ">
                                                                            <span
                                                                                [innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
                                                                            ({{item?.dial_code}})
                                                                        </li>
                                                                    </ul>
                                                                    <p class="noResultsFound letterSpacing"
                                                                        *ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length">
                                                                        No results
                                                                        match "{{countrySearch}}"</p>
                                                                </div>
                                                            </div>
                                                            <!-- Country List -->
                                                            <div class="column is-9 phoneNumberInputOuter">
                                                                <input id="phoneId"
                                                                    class="basic-form_input basic-form_input-half phoneNumberInput"
                                                                    *ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone"
                                                                    [(placeholder)]="userCountryDetails.defaultFormat"
                                                                    [maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
                                                                    [textMask]="{mask: mask, guide:false}" (input)="phoneValueChangeFn()" />
                                                            </div>
                                                        </div>
                                                        <p class="errorptag"
                                                            *ngIf="lead_gen_form?.controls?.phone?.invalid && (lead_gen_form?.controls?.phone?.dirty || lead_gen_form?.controls?.phone?.touched)">
                                                            <span *ngIf="lead_gen_form?.controls?.phone?.errors?.required"
                                                                class="phoneNoError error">This field
                                                                is
                                                                required</span>
                                                            <span
                                                                *ngIf="!lead_gen_form?.controls?.phone?.errors?.required&&lead_gen_form?.controls?.phone?.errors?.phoneIncorrect"
                                                                class="phoneNoError error">Enter a valid phone number
                                                                (e.g. {{ userCountryDetails?.defaultPhoneNumber }})
                                                            </span>
                                                        </p>
                                                    </div>
                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                                <!-- Selected Voyage Date -->
                                <div class="basic-form_group">
                                    <div class="row is-12">
                                        <div class="column is-12 form_fields">
                                            <label class="basic-form_label form_label">Preferred Voyage Date</label>
                                            <div class="multiselect-container">
                                                <div class="multiselect-selected-options">
                                                    <div class="multiselect-select-box" (click)="toggleDropdown()">
                                                        <span class="placeholder">
                                                            <span class="placeholder">{{
                                                                getFirstSelectedDate(selectedDates) }}</span>
                                                        </span>
                                                        <span class="moreVoyageSelected"
                                                            [ngClass]="{'moreVoyageSelectedExeed':preferredDateLimitExceed}"
                                                            *ngIf="selectedDates.length > 1">+{{ selectedDates.length -
                                                            1 }}</span>
                                                        <span class="clear-button" (click)="clearDropdownOptions()"
                                                            *ngIf="isDropdownOpen && selectedDates.length > 0">Clear</span>
                                                        <span class="dropdown-icon"></span>
                                                    </div>
                                                    <div class="checkbox-options1" *ngIf="isDropdownOpen || keepDropdownOpen">
                                                        <div class="checkbox-option"
                                                            *ngFor="let monthAndYear of distinctMonthsAndYears;let i=index">
                                                            <input class="optCB" type="checkbox" id="dateCheckboxId_{{ i + 1 }}"
                                                                [value]="monthAndYear"
                                                                (change)="toggleDateCheckbox($event, monthAndYear)"
                                                                [checked]="selectedDates.includes(monthAndYear)">
                                                            <label class="opt" [ngClass]="{'disableOpt': preferredDateLimitExceed}"
                                                                for="dateCheckboxId_{{ i + 1 }}">
                                                                {{monthAndYear}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="errorptag" *ngIf="preferredDateLimitExceed">
                                                <span class="error">More than 5 Voyage Dates cannot be selected</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        
                                <!-- Terms and Conditions content with a checkbox -->
                                <div *ngIf="apiresponseE" class="termsSection termsSection-up">
                                    <div *ngIf="apiresponseE?.field_term2 && showPhoneopt" class="eachTermSection">
                                        <input id="phoneOptId" type="checkbox" value="true" formControlName="isAgreeToContact"
                                            (change)="phoneOptValueChanged()">
                                        <div class="termContent" *ngIf="!showPhoneOptRequiredText" [innerHtml]="apiresponseE?.field_term2">
                                        </div>
                                        <div class="termContent" *ngIf="showPhoneOptRequiredText"
                                            [innerHtml]="apiresponseE?.field_term2_required"></div>
                                        <p class="marketingError" *ngIf="showPhoneOptRequiredText">
                                            <span class="emailError error"
                                                [innerHtml]="drupalErrorMessages?.field_phone_opt_in_required"></span>
                                        </p>
                                    </div>
                                    <div *ngIf="apiresponseE?.field_term1" class="eachTermSection">
                                        <input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail">
                                        <p class="termContent" [innerHTML]="apiresponseE?.field_term1"></p>
                                    </div>
                                </div>
                                <!-- Term section -->
                                <div *ngIf="apiresponseE" class="termsSection">
                                    <div *ngIf="apiresponseE?.field_summery && !showTermUS" class="basic-form_condition"
                                        [innerHTML]="apiresponseE?.field_summery"></div>
                                    <div *ngIf="apiresponseE?.field_summery && showTermUS" class="basic-form_condition"
                                        [innerHTML]="apiresponseE?.field_summary_us"></div>
                                </div>
                            </fieldset>
                        </form>

                        <div class="row is-6-6">
                            <div class="column is-6">
                                <div class="row is-6-6">
                                    <div *ngIf="!submitLoading" class="column is-6 submit_btn">
                                        <input type="submit" id="submitId" title="Submit" (click)="leadGenSubmit()" [disabled]="lead_gen_form?.controls?.firstname?.invalid||
                                                lead_gen_form?.controls?.lastname?.invalid||
                                                lead_gen_form?.controls?.country?.invalid||
                                                lead_gen_form?.controls?.email?.invalid||
                                                lead_gen_form.controls?.phone?.errors?.phoneIncorrect==true ||
                        										    (lead_gen_form.controls?.phone?.errors?.phoneIncorrect!=false&&phNumReqStatusDynamic) ||
                                                (lead_gen_form?.controls?.zipcode?.invalid&&zipcodeReqStatusDynamic)||
                                                !userArea ||
                                                showPhoneOptRequiredText ||
                                                preferredDateLimitExceed" value="Submit">
                        
                                    </div>
                                    <div *ngIf=" submitLoading " class="row is-12 btnLoaderOuter">
                                        <div class="btnLoader">
                                            <img src="assets/components/images/loading.gif" class="formLoader">
                                        </div>
                                    </div>
                        
                                    <div class="column is-6 clear_btn">
                                        <input type="reset" id="resetId" title="Clear" (click)="ngOnInit()" value="Clear"
                                            [ngClass]="{'disabledCursor': submitLoading}" [disabled]="submitLoading">
                                    </div>
                                </div>
                            </div>
                            <div class="column is-6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>