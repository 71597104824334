import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { getMaskedCode } from '../../../../utils/phone-number-validation';
import { ValidatePhoneNumber, PhoneDataFormat, formatPhoneList, deepPhoneConfiguration } from '../../../rcyc-share-components/rcyc-phonenumber-validator/rcyc-phonenumber-validator';
import { RcycRequestQuoteService } from '../rcyc-request-quote.service';
import { DatePipe } from '@angular/common';
import { RcycInternalApisService } from '../../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from '../../../rcyc-config/config';
import { RcycFormTokenService } from '../../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { SalesForceFormData } from '../../../rcyc-services/rcyc-salesforce/salesforce-form-data.interface';
import { RcycHiddenSalesforceFormService } from '../../../rcyc-services/rcyc-salesforce/rcyc-hidden-salesforce-form.service'
import { PreviewVoyageList, RcycNewFindACruiseService } from '../../rcyc-new-find-a-cruise/rcyc-new-find-a-cruise.service';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycHomeService } from '../../rcyc-home/rcyc-home.service';


declare let ga: Function;

@Component({
  selector: 'app-rcyc-req-quote',
  templateUrl: './rcyc-req-quote.component.html',
  styleUrls: ['../rcyc-request-quote-bform.component.css','../request-quote-base.css'],
})

export class RcycReqQuoteComponent implements OnInit {

  IMG_BASE_URL: any =   AppConfig.IMG_BASE_URL;
  IMG_CDNHOST_URL: any =   AppConfig.IMG_CDNHOST_URL;
  salesForceImplementation:boolean = AppConfig.salesForceImplementation;

  lead_gen_form: any;
  zipcodereq = false;

  apiResponseA: any;
  // apiResponseB: any;
  apiResponseC: any;
  apiResponseElasticA: any;
  apiResponseComboId: any;
  apiResponseVoyage: any = {};
  requestQuoteFormResponse: any = {};

  apiResponseAStatus: any = false;
  // apiResponseBStatus: any = false;
  apiResponseCStatus: any = false;
  apiResponseVoyageStatus: any = false;

  apiUrlA: string;
  apiUrlB: string;
  apiUrlC: string;
  apiUrlMetatag: string;
  apiUrlVoyage: string;
  apiUrl_requestQuote_Submit: string;
  apiUrlComboId: string;

  voyageId: any;
  voyageImagePath: any = [];
  sliderHighlight: any;
  geoApiResponse: any = {};

  requestQuoteFormToken: any;

  formSubmitFailStatus = false;
  tokenErrorMsg: any = this.rcycFormTokenService.tokenErrorMsg;
  userAreaDetails: any;
  userArea = '';
  apiUrlD: string;
  rescoVoyageCode = '';
  previousUrlStatus = false;
  apiUrlE: string;
  // @ViewChild('errorModal') errorModal: RcycErrorModalComponent;
  voyagePageUrlStatus = false;
  isMobilebutton: boolean;

  flagCodeClickStatus = false;
  countrySearch: string;
  userDetails: any = [];
  formttedPhoneList: any = {};
  phNumReqStatusDynamic = false;
  zipcodeReqStatusDynamic = false;
  phoneDataArray: PhoneDataFormat[];
  userCountryDetails: PhoneDataFormat;
  permanentGeoLocation: any;
  countryListArray: any;
  tokenMessage:any;
  quoteFormStatus = false;

  phoneFieldTouchStatus = false;
  zipcodeMaxLength: number=10;
  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlPath: any;
  isRequestQuoteStatus=false;
  specialCountryInputClickStatus=false;
  specialCountryInputCloseBtnStatus=false;
  isSpecialCountrySelect=false;
  selectCountryCode: string;
  mask = [];
  showPhoneopt = false;
  showTermUS = false;
  showPhoneOptRequiredText = false;
  drupalErrorMessages: any;
  sessionExpiredMessages:any;
  isRaqSpecific: boolean = false;
  yachtIds: any ;
  voyageApiResponse:any = {};
  voyageListArray: any = [];
  isDropdownOpen = false;
  selectedVoyagesDetails:any = [];
  selectedVoyages: string[] = [];
  keepDropdownOpen: boolean = false;
  selectedVoyagesForSalesForce: string = '';
  submitLoading:boolean = false;

  specialOfferFormErrorMsg:string;
  prefferedVoyageCount : any = null;
  preferredVoyageLimitExceed : boolean = false;
  sf_SpecificVoyageName: any;
  sf_SpecificVoyageDate: any;
  sf_SpecificVoyageCode: any;
  submitSuccessful: boolean = false;
  isMobileScreen: boolean;
  isLoyalty: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private rcycCommonService: RcycCommonService,
    private http: HttpClient,
    private router: Router,
    private rcycRequestQuoteService: RcycRequestQuoteService,
    private datePipe: DatePipe,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycHomeService: RcycHomeService,
    private cookies: CookieService,
    private rcycFormTokenService: RcycFormTokenService,

    private rcycNewFindACruiseService: RcycNewFindACruiseService,
    private elementRef: ElementRef,
    private rcycHiddenSalesforceFormService : RcycHiddenSalesforceFormService,
  ) {
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlPath = this.currentUrlPathAsArray[0].path;
    if (this.currentUrlPath == 'request-quote' || this.currentUrlPath == 'request-quote-bform') {
      this.isRequestQuoteStatus = true;
    }
    if (this.currentUrlPath == 'loyalty'){
      this.isLoyalty = true;
     }
     
    this.getCountryCodeFromGeoAPI()
    this.activatedRoute.params.subscribe(
      params => {
        this.voyageId = params.voyageId;
        if (this.voyageId) {
          this.isRaqSpecific = true;
          this.isCancelledVoyageRedirection(this.voyageId);
        } else {
          this.isRaqSpecific = false;
        }
        this.checkForRedirection();
      });
    if (this.voyageId) {

      // regex for only 1 letter and ending with that letter
      const voyageIdPregMAtch = /^[^a-z]*([a-z])$/i;
      if (!isNaN(this.voyageId) && this.voyageId.length === 8) {
        this.voyageDetailsApiCall();
      } else if (this.voyageId.length === 9 && voyageIdPregMAtch.test(this.voyageId)) {
        this.voyageDetailsApiCall();
      } else {
        this.router.navigate(['/request-quote']);
      }
    } else {
      this.constructorDataFn();
    }

    if (this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl')) {
      const currentUrl = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
      if (this.voyageId) {
        this.previousUrlStatus = true;
        this.voyagePageUrlStatus = false;
      }
      if (this.rcycCommonService.getSessionData('requestAQuoteClick')) {
        this.previousUrlStatus = false;
        this.voyagePageUrlStatus  = true;
        this.rcycCommonService.removeSessionData('requestAQuoteClick');
      }
    }

  }

  ngOnInit() {

    this.getYachtId()
    this.zipcodereq = false;
   // var emailValidation =/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    // let emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    if(!this.isRequestQuoteStatus) {
      this.lead_gen_form = this.formBuilder.group({
        firstname: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        lastname: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$')]],
        country: ['', Validators.required],
        countryCode: ['US', Validators.required],
        zipcode: ['', Validators.required],
        phone: [''],
        isMobile: ['no'],
        // preferred: ['', Validators.required],
        // comment: [''],
        isAgreeToReceiveMail: [false],
        isAgreeToContact: [false],
        isAgreeToSms: [false],
        dialCode:['']
      });
    } {
      this.lead_gen_form = this.formBuilder.group({
        firstname: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        lastname: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$')]],
        country: ['', Validators.required],
        countryCode: ['US', Validators.required],
        zipcode: [''],
        phone: ['', Validators.required],
        comment: [''],
        isMobile: [false],
        isAgreeToReceiveMail: [false],
        isAgreeToContact: [false],
        isAgreeToSms: [false],
        dialCode: ['']
      });
    }

    this.isMobileScreen = (window.innerWidth <= 500) ? true : false;


    this.http.get('./assets/select_country.json').subscribe((data) => {
      this.countryListArray =  data;
    });
    this.http.get('./assets/rescoNumberFormatted.json').subscribe((phoneDataArray: PhoneDataFormat[]) => {
      this.phoneDataArray = phoneDataArray;
      this.formttedPhoneList = formatPhoneList(phoneDataArray);
      this.deepPhoneConfigurations();
    });

    this.formValueChangeCapture();
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      this.getMyProfileDetails();
    }
    this.getDrupalErrorMessages();

    //To clear the preferred voyage initialy and on clear button click
    this.selectedVoyages = [];
    this.selectedVoyagesDetails = [];
    this.selectedVoyagesForSalesForce = '';
    this.preferredVoyageLimitExceed = false; // clear the limit exceed message
  }
  @HostListener('window:resize',['$event'])
  onResize(event) {
    this.isMobileScreen = (event.target.innerWidth <= 500) ? true : false;
  }
  deepPhoneConfigurations() {
    let res: { formttedPhoneList: any, userCountryDetails: PhoneDataFormat, phoneDataArray: PhoneDataFormat[] } = deepPhoneConfiguration(this.phoneDataArray, this.formttedPhoneList);
    this.formttedPhoneList = res.formttedPhoneList;
    this.userCountryDetails = res.userCountryDetails;
    this.phoneDataArray = res.phoneDataArray;
    this.permanentGeoLocation=Object.assign({},this.userCountryDetails);
    this.lead_gen_form.get('countryCode').setValue(this.userCountryDetails.code);
    this.lead_gen_form.get('dialCode').setValue(this.userCountryDetails.dial_code);
    this.mask = getMaskedCode(res.userCountryDetails);
    // console.log(res, 'out class');
  }

   /* ===================================================
  Function for value  change of form
  =======================================================*/
  formValueChangeCapture() {

    this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
    this.lead_gen_form.get('isMobile').valueChanges.subscribe(newValue => {
      if(newValue === false) {
        this.lead_gen_form.patchValue({isAgreeToSms: false});
      }
      this.isMobilebutton = newValue;
    });
    if(!this.isRequestQuoteStatus) {

      this.lead_gen_form.valueChanges.subscribe((val) => {
        this.specialOfferFormErrorMsg='';
        this.formSubmitFailStatus = false;
      });
      this.lead_gen_form.get('country').valueChanges.subscribe((val) => {

        // Editing country field
        this.selectCountryCode='';
        this.specialCountryInputClickStatus=true;
        this.specialCountryInputCloseBtnStatus=true
        this.isSpecialCountrySelect=false;
      });

    } else {
      this.lead_gen_form.valueChanges.subscribe((val) => {
        this.formSubmitFailStatus = false;
      });
      this.lead_gen_form.get('country').valueChanges.subscribe((val) => {
        this.countryValueChangeFn(val);
      });
    }

  }

  // get formCall() {
  //   return this.lead_gen_form.controls
  // }

  /* =======================================================================================================================================
    To get all functions loading in constructor
  ======================================================================================================================================= */

    constructorDataFn() {

      if(this.isRequestQuoteStatus) {
        // Changing subheader A value
        this.rcycCommonService.changeSubHeaderDataArray_A({});
        this.rcycCommonService.changeSubHeaderStatus(true);
      }

      // Function for Quote form token generation
      this.requestQuoteFormTokenFn();

      if (!this.voyageId) {

        // Api urls for Generic Quote
        // Call get Metatag function
        this.apiUrlMetatag = '/api/v1/meta/requestquotegeneric?_format=json';

        this.apiUrlA = '/api/v1/requestquotegeneric/header/a?_format=json';
        this.apiUrlB = '/api/v1/requestquotegeneric/content/b?_format=json';
        this.apiUrlC = '/api/v1/requestquotegeneric/terms?_format=json';

      } else {

        // Api urls for Specific Quote
        // Call get Metatag function
        this.apiUrlMetatag = '/api/v1/meta/requestquotevoyages?_format=json';

        this.apiUrlA = '/api/v1/requestquotevoyages/header/a?_format=json';
        this.apiUrlB = '/api/v1/requestquotevoyages/content/b?_format=json';
        this.apiUrlC = '/api/v1/requestquotevoyages/terms?_format=json';
        this.apiUrlComboId = '/api/v1/voyagecombo/' + this.voyageId + '?_format=json';

        // this.apiUrlD = '/api/v1/voyage/eventcodes/' + this.voyageId + '?_format=json';

        // Function to get Field Resco Voyage Code
        // this.getRescoVoyageCodeFromDrupal(this.apiUrlD);

        // Function call to get comboId
        this.getComboIdApiCall();
      }

      if (!this.rcycCommonService.getData('UserDetails')) {

        this.rcycCommonService.getUser_Details().subscribe(
          response => {

            this.geoApiResponse = response;

            this.rcycRequestAQuoteContent();
          },
          err => {
            console.log('Geo Port APi err');
            this.rcycRequestAQuoteContent();

          }
        );
      } else {

        this.geoApiResponse = this.rcycCommonService.getData('UserDetails');
        this.rcycRequestAQuoteContent();

      }

      // Subscriber to get User Area
      this.rcycCommonService.userAreaCode.subscribe((userAreaDetails) => {
        if (userAreaDetails.length) {
          this.userAreaDetails = userAreaDetails[0];
          this.userArea = this.userAreaDetails.field_area_code;
        }
      });

    }


  /**
   * Get the yacht code from session storage or drupal to get elastic voyages list
   */
  getYachtId() {
    if (sessionStorage.getItem("yachtcode")) {
      const yachtCodeFromSession: any = JSON.parse(
        sessionStorage.getItem("yachtcode")
      );
      if (yachtCodeFromSession) {
        this.yachtIds = Object.values(yachtCodeFromSession).map(Number);
      }
      this.getVoyageData();
    } else {
      this.getYachtIdFromDrupal();
    }
  }

  /**
   * To get the yacht code from drupal
   */
  getYachtIdFromDrupal() {
    const apiUrl = "/api/v1/yachtcode?_format=json";
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (res: any = []) => {
        if (res.length) {
          let yachtcode = {
            evrima: res[0].field_evrima_code,
            ilma: res[0].field_ilma_code,
            luminara: res[0].field_luminara_code,
          };
          sessionStorage.setItem("yachtcode", JSON.stringify(yachtcode));
          // Extract all yacht IDs and store them in this.yachtIds array
          this.yachtIds = Object.values(yachtcode).map(Number);

          this.getVoyageData();
        }
      },
      (err) => {
      }
    );
  }

  /**
   *  Get Iventory from Elatic
   */
  getVoyageData() {
    let geoCountryCode;
    if (!this.geoApiResponse.country_code) {
      geoCountryCode = "US";
    } else {
      geoCountryCode = this.geoApiResponse.country_code;
    }
    /****************** Section C********************/
    this.apiUrlC = "/api/voyageListWithBasicData";
    const param1 = {
      countryCode: geoCountryCode,
      yachtIds: this.yachtIds
    };
    this.rcycInternalApisService
      .postElastic(param1, this.apiUrlC)
      .subscribe((response) => {
        this.voyageApiResponse = response;
        if (this.voyageApiResponse) {
          this.voyageListArray = this.voyageApiResponse.data.voyages;
        }
      });
  }

  /**
  * To toggle the dropdown to select preferred voyage
  */
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  /**
   * Get the details of preferred voyages selected
   * @param event voyage which is selected
   * @param voyageDetails the details of voyage preferred
   */
  toggleCheckbox(event: any, voyageDetails: any) {
    const voyageId = event.target.value;
    let formattedSFValues = [];
    let tripType;

    if (event.target.checked) {
      this.selectedVoyages.push(voyageId);
      this.selectedVoyagesDetails.push(voyageDetails);
    } else {
      const index = this.selectedVoyages.indexOf(voyageId);
      if (index !== -1) {
        this.selectedVoyages.splice(index, 1);
        const voyageIndex = this.selectedVoyagesDetails.findIndex(item => item.voyageId === voyageId);
        if (voyageIndex !== -1) {
          this.selectedVoyagesDetails.splice(voyageIndex, 1);
        }
      }
    }

    this.selectedVoyagesDetails.sort((a, b) => {
      return new Date(a.voyageStartDate).getTime() - new Date(b.voyageStartDate).getTime();
    });

    //Setting Salesforce Value
    for (const voyage of this.selectedVoyagesDetails) {
      const startDate = this.datePipe.transform(new Date(voyage.voyageStartDate), 'MMM d, y','UTC');
      const embarkPort = voyage.voyageEmbarkPort;
      const disembarkPort = voyage.voyageDisembarkPort;
      tripType = (embarkPort !== disembarkPort) ? `${embarkPort} to ${disembarkPort}` : `${embarkPort} Round Trip`;
      const nights = voyage.nights + " Nights";
      formattedSFValues.push(`${startDate} | ${tripType} | ${nights}`);
    }
    this.selectedVoyagesForSalesForce = formattedSFValues.join(",\n");

    this.prefferedVoyageCount = this.selectedVoyages.length;
    this.preferredVoyageLimitExceed = (this.prefferedVoyageCount > 10) ? true :false;
  }
  /**
   * To clear the voyages selected
   */
  clearDropdownOptions() {
    this.selectedVoyages = []; // Clear selectedVoyages array
    this.selectedVoyagesDetails = []; // Clear selectedVoyagesDetails array
    this.keepDropdownOpen = true;
    this.selectedVoyagesForSalesForce = ''; //Clear selectedVoyages data for salesforce
    this.preferredVoyageLimitExceed = false; // clear the limit exceed message
  }

  /**
   * To get the voyage names of selected voyages to display in select box
   * @param voyagesDetails details of voyage selected
   * @returns voyage embadarkation to disembarkation name of selected voyage
   */
  getFirstPortNames(voyagesDetails: any[]): string {
    if (voyagesDetails.length > 0) {
      const firstVoyage = voyagesDetails[0];
      const embarkPort = firstVoyage.voyageEmbarkPort;
      const disembarkPort = firstVoyage.voyageDisembarkPort;

      if (embarkPort !== disembarkPort) {
        return `${embarkPort} to ${disembarkPort}`;
      } else {
        return `${embarkPort} Roundtrip`;
      }
    }
    return 'Select Preferred Voyage';
  }

  /**
   * To set the focus in and out of preferred voyage select box
   * @param event Click event
   */
  @HostListener('document:click', ['$event'])
  onClick(event: any) {
    if (!this.isRaqSpecific) {
      const targetElement = event.target as HTMLElement;
      const dropdownContainer = this.elementRef.nativeElement.querySelector('.multiselect-container');

      const cancelClasses = ['clear-button', 'optCB', 'opt'];
      this.keepDropdownOpen = cancelClasses.some(className => targetElement.classList.contains(className));
      if (this.keepDropdownOpen) {
        this.isDropdownOpen = true;
      }
      if (!dropdownContainer.contains(targetElement)) {
          if (!this.keepDropdownOpen){
          this.isDropdownOpen = false;
        }
      }
    }
    const targetElement = event.target as HTMLElement;
    const isOutsideClick = !targetElement.closest('.phoneFlagOuter') && !targetElement.closest('.countryResultOuter');
    if(isOutsideClick){
      this.flagCodeClickStatus = false
    }
  }

  /* =======================================================================================================================================
    Function to submit Request Quote
  ======================================================================================================================================= */
  leadGenSubmitApiCall() {

      this.submitLoading = true;
      // let requestData;
      // let phoneNumber = this.userCountryDetails.dial_code + this.lead_gen_form.value.phone.replace(/ /g, "");
      // if(!this.lead_gen_form.value.phone.replace(/ /g, "")) {
      //   phoneNumber = "";
      // }
      let dial_code:string = this.userCountryDetails.dial_code;
      this.quoteFormStatus = false;

      // if(dial_code.indexOf('+1') !== -1) {
      //   dial_code= '+1';
      // }
      let phoneNumber;
      if(!this.lead_gen_form.value.phone.replace(/ /g, "")) {
        phoneNumber="";
      } else {
        phoneNumber = dial_code+this.lead_gen_form.value.phone.replace(/ /g, "");
      }
      let requestData: any={};
      requestData = {
        'firstname': this.lead_gen_form.value.firstname.trim(),
        'lastname': this.lead_gen_form.value.lastname.trim(),
        'email': this.lead_gen_form.value.email.trim(),
        'phone': phoneNumber,
        // 'country': this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '',
        'zipcode': this.lead_gen_form.value.zipcode.trim(),
        // 'preferred': this.lead_gen_form.value.preferred,
        'emailOpt': this.lead_gen_form.value.isAgreeToReceiveMail,
        'phoneOpt': this.lead_gen_form.value.isAgreeToContact,
        'smsOpt': this.lead_gen_form.value.isAgreeToSms && this.isMobilebutton,
        //'comment': this.lead_gen_form.value.comment.trim(),
        //'type': 'specific',
        //'value': this.rescoVoyageCode,
        'info': this.voyageId,
        'area': this.userArea,
        'utmSource': this.cookies.get('utm_source'),
        'utmMedium': this.cookies.get('utm_medium'),
        'utmCampaign': this.cookies.get('utm_campaign'),
        'internalSource': this.cookies.get('int_source'),
        'internalCampaign': this.cookies.get('int_campaign'),
        'folId': this.cookies.get('fol_num')
      };

      if(!this.isRequestQuoteStatus) {
        requestData['comment'] = '';
        requestData['country']= this.selectCountryCode;
      } else {
        requestData['comment'] = this.lead_gen_form.value.comment?this.lead_gen_form.value.comment:'';
        requestData['country']= this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '';
      }

      if (requestData['country'] == 'US') {
        if (this.lead_gen_form.value.phone) {
          requestData['phoneOpt'] = true;
        } else {
          requestData['phoneOpt'] = false;
        }
      }

      if (this.voyageId && this.apiResponseVoyage && this.isRaqSpecific) {

        if (this.rescoVoyageCode) {
          // this.comboId = this.apiResponseComboId.title;
          this.rescoVoyageCode = this.rescoVoyageCode;
        }
        requestData['type'] = 'specific';
        requestData['value'] = this.rescoVoyageCode;
        // requestData = {

        //   'googleId': (this.requestQuoteFormToken) ? this.requestQuoteFormToken : '',
        //   'firstname': this.lead_gen_form.value.firstname.trim(),
        //   'lastname': this.lead_gen_form.value.lastname.trim(),
        //   'email': this.lead_gen_form.value.email.trim(),
        //   'phone': phoneNumber,
        //   'country': this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '',
        //   'zipcode': this.lead_gen_form.value.zipcode.trim(),
        //   'preferred': this.lead_gen_form.value.preferred,
        //   'emailOpt': this.lead_gen_form.value.isAgreeToReceiveMail,
        //   'phoneOpt': this.lead_gen_form.value.isAgreeToContact,
        //   'comment': this.lead_gen_form.value.comment.trim(),
        //   'type': 'specific',
        //   'value': this.rescoVoyageCode,
        //   'info': this.voyageId,
        //   'area': this.userArea
        // };

        this.apiUrl_requestQuote_Submit = '/secureleadapi/req-quote-specific-ga';
      } else {

        requestData['type'] = 'generic';
        requestData['info'] = this.selectedVoyages
        // requestData = {
        //   'googleId': (this.requestQuoteFormToken) ? this.requestQuoteFormToken : '',
        //   'firstname': this.lead_gen_form.value.firstname.trim(),
        //   'lastname': this.lead_gen_form.value.lastname.trim(),
        //   'email': this.lead_gen_form.value.email.trim(),
        //   'phone': phoneNumber,
        //   'country': this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '',
        //   'zipcode': this.lead_gen_form.value.zipcode.trim(),
        //   'preferred': this.lead_gen_form.value.preferred,
        //   'emailOpt': this.lead_gen_form.value.isAgreeToReceiveMail,
        //   'phoneOpt': this.lead_gen_form.value.isAgreeToContact,
        //   'comment': this.lead_gen_form.value.comment.trim(),
        //   'type': 'generic',
        //   'area': this.userArea
        // };

        this.apiUrl_requestQuote_Submit = '/secureleadapi/req-quote-generic-ga';
      }

      /****************** Section RequestQuote Submission********************/

      this.rcycInternalApisService.postSecureEbrochureForm(this.apiUrl_requestQuote_Submit, requestData, this.requestQuoteFormToken).subscribe(
        response => {

          if (!this.salesForceImplementation) {
            // Change The value of Flag (salesForceImplementation) in env to execute handling of post LeadGenForm submission to resco - and Discontinue W2L salesforce submit

            this.submitLoading = false;
            this.requestQuoteFormResponse = response;
            const res = this.requestQuoteFormResponse;

            if (res && res.code === 200) {
              this.submitSuccessful = true;
              let leadId = (res.data && res.data.leadId) ? res.data.leadId : '';
              this.formSubmitFailStatus = false;
              if (this.voyageId && this.isRaqSpecific) {

                // this.router.navigate(['/complete'], { queryParams: { success: 'raqvoyage','lead_id':leadId } });
                ga('create', 'UA-114428670-1', 'auto');
                ga('require', 'ecommerce', 'ecommerce.js');
                ga('ecommerce:addItem', {
                  'id': leadId,
                  'name':'raqvoyage'
                });
                ga('ecommerce:send');
                ga('send','pageview')
              } else {

                // this.router.navigate(['/complete'], { queryParams: { success: 'raqnovoyage','lead_id':leadId } });
                ga('create', 'UA-114428670-1', 'auto');
                ga('require', 'ecommerce', 'ecommerce.js');
                ga('ecommerce:addItem', {
                  'id': leadId,
                  'name':'raqnovoyage'
                });
                ga('ecommerce:send');
                ga('send','pageview')
              }
            // } else if ( res && ( res.code === 403 || res.code === 401 || res.code === 400 ) ) {

              // if (this.voyageId) {

              //   this.voyageDetailsApiCall();
              // } else {

              //   this.constructorDataFn();
              // }
              // this.formSubmitFailStatus = true;
              // /* const scrollConfig: ScrollToConfigOptions = {
              //   target: 'formSubmitErrorId'
              // };
              // this.scrollToService.scrollTo(scrollConfig);*/
              // window.scrollTo(0, 0);
            } else {
              this.formSubmitFailStatus = true;
              if (res && res.code === 500 && res.status == 407) {

                this.quoteFormStatus = true;
                this.formSubmitFailStatus = false;
                this.tokenMessage = this.rcycFormTokenService.tokenErrorMsg;
                this.sessionExpiredMessages=this.drupalErrorMessages.field_form_session_expired;
              }
              /* const scrollConfig: ScrollToConfigOptions = {
                target: 'formSubmitErrorId'
              };
              this.scrollToService.scrollTo(scrollConfig);*/
              // window.scrollTo(0, 0);
              document.querySelector('.page-title').scrollIntoView();

              this.requestQuoteFormTokenFn();

            }
          }
        }, err => {

          console.log(err);
        }
      );

    // }

    if (this.salesForceImplementation) {
      // Salesforce Web to Lead - W2L START

      const salesForceRequestData: SalesForceFormData = {
        'lead_source' : 'Website',
        'first_name' : this.lead_gen_form.value.firstname.trim(),
        'last_name' : this.lead_gen_form.value.lastname.trim(),
        'phone' : phoneNumber,
        'email' : this.lead_gen_form.value.email.trim(),
        'zip' : this.lead_gen_form.value.zipcode.trim(),
        'area' : (this.userArea) ? this.userArea : '',
        'smsOpt': (this.lead_gen_form.value.isAgreeToSms && this.isMobilebutton) ? 'Yes' : 'No Response',
        'Phone_Opt_In__c': (this.lead_gen_form.value.isAgreeToContact == true) ? 'Yes' : 'No Response',
        'Email_Opt_In__c' : (this.lead_gen_form.value.isAgreeToReceiveMail == true ) ? 'yes' : 'No Response',
        'UTM_Source__c' : this.cookies.get('utm_source'),
        'UTM_Medium__c' : this.cookies.get('utm_medium'),
        'UTM_Campaign__c' : this.cookies.get('utm_campaign'),
        'INT_Source__c' : this.cookies.get('int_source'),
        'INT_Campaign__c' : this.cookies.get('int_campaign'),
      }
      if(!this.isRequestQuoteStatus) {
        salesForceRequestData['Comments__c'] = '';
        salesForceRequestData['country_code'] = this.selectCountryCode;
      } else {
        salesForceRequestData['Comments__c'] = this.lead_gen_form.value.comment? this.lead_gen_form.value.comment.trim(): '';
        salesForceRequestData['country_code'] = this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '';
      }
      if(salesForceRequestData['country_code'] == 'US' || salesForceRequestData['country_code'] == 'United States') {
        if (this.lead_gen_form.value.phone) {
          salesForceRequestData['Phone_Opt_In__c'] = 'Yes';
        } else {
          salesForceRequestData['Phone_Opt_In__c'] = 'No Response';
        }
      }
      let qParam;
      if (this.voyageId && this.apiResponseVoyage && this.isRaqSpecific) {
        //SPECIFIC
        salesForceRequestData['Lead_Type__c'] = 'Request a Quote - Voyage';
        salesForceRequestData['Voyage_Name__c'] = this.sf_SpecificVoyageName;
        salesForceRequestData['Voyage_Date__c'] = this.sf_SpecificVoyageDate;
        salesForceRequestData['Voyage__c'] = this.sf_SpecificVoyageCode;
        qParam = 'raqvoyage';
      } else {
        //GENERIC
        salesForceRequestData['Lead_Type__c'] = 'Request a Quote - Generic'
        salesForceRequestData['Preferred_Voyage__c'] = this.selectedVoyagesForSalesForce;
        qParam = 'raqnovoyage';
      }

      this.rcycHiddenSalesforceFormService.generateFormW2L(salesForceRequestData, qParam);

      // Salesforce Web to Lead - W2L END
    }

  }

  /* =======================================================================================================================================
    Function to call all  Request a Quote APIs
  ======================================================================================================================================= */

  rcycRequestAQuoteContent() {

    if(this.isRequestQuoteStatus) {
      /****************** Section Metatag********************/
      this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

      /****************** Section A********************/
      this.rcycInternalApisService.getDrupal(this.apiUrlA).subscribe(
        (res : any=[]) => {
          if (res.length) {
            this.apiResponseAStatus = true;
            this.apiResponseA = res[0];
            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A(this.apiResponseA);
          } else {
            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A([]);
          }
        },
        err => {
          console.log('Section A Request Quote Api error');
          console.log(err);
        }
      );
    }

    /****************** Section B********************/
    // this.rcycInternalApisService.getDrupal(this.apiUrlB).subscribe(
    //   (res : any=[]) => {
    //     if (res.length) {
    //       this.apiResponseB = res[0];
    //       this.apiResponseBStatus = true;
    //     }
    //   },
    //   err => {
    //     console.log('Section B Request Quote Api error');
    //     console.log(err);
    //   }
    // );

    /****************** Section C********************/
    this.rcycInternalApisService.getDrupal(this.apiUrlC).subscribe(
      (res : any=[]) => {
        if (res.length) {
          this.apiResponseC = res[0];
          this.apiResponseCStatus = true;
          let message = this.apiResponseC.field_term2.replace('<p>', "").replace("</p>","");
          let newMessage = `<p> ${message}<span>*</span></p>`;
          this.apiResponseC["field_term2_required"] = newMessage;
        }
      },
      err => {
        console.log('Section C Request Quote Api error');
        console.log(err);
      }
    );

  }


  getSlider() {
    this.rcycCommonService.drupalApisGet('/api/v1/voyages/' + this.voyageId + '/tab/itineraryoverview?_format=json').subscribe(
      response => {
        const res = Object.keys(response).map(function (key) {
          return response[key];
        });

        if (res.length && this.voyageImagePath) {
          this.voyageImagePath[1] = res[0].field_voyage_image;

          // Get data for each voyage
          this.rcycCommonService.drupalApisGet('/api/v1/voyage-card-slider/' + this.voyageId + '?_format=json').subscribe(
            response => {
              if (!response) return;
              const res = Object.keys(response).map(function (key) {
                return response[key];
              });

              if (res.length) {
                this.voyageImagePath = [...this.voyageImagePath, ...res[0].field_com_images.split(', ')];
                this.sliderHighlight = res[0].field_com_highlight_text
              }
            },
            err => { }
          );

        }
      },
      err => {
        console.log(err);
      }
    );

  }

  /* =====================================================================
    Function to call specific Voyage Details
  ========================================================================== */
  voyageDetailsApiCall() {

    /****************** Section Voyages ********************/
    // if(this.voyageId) {

      // Voyage Image
      this.voyageImagePath = [this.IMG_CDNHOST_URL + '/thumbnails/' + this.voyageId + '.jpg'];
      this.getSlider();

      let countryCode;
      if (this.geoApiResponse.country_code) {

        countryCode = this.geoApiResponse.country_code;
      } else {

        countryCode = 'US';
      }
      this.apiUrlVoyage = '/api/voyage?voyageId=' + this.voyageId + '&countryCode=' + countryCode;

      this.rcycInternalApisService.getElastic(this.apiUrlVoyage).subscribe(
        response => {

          this.apiResponseElasticA = response;
          const res = this.apiResponseElasticA;

          if (res && res.code === 200 && res.data) {

            this.apiResponseVoyageStatus = true;
            this.apiResponseVoyage = res.data;
            this.rescoVoyageCode = res.data.id;
            this.sf_SpecificVoyageName = res.data.voyageName;
            this.sf_SpecificVoyageDate = res.data.voyageStartDate.split('T')[0];
            this.sf_SpecificVoyageCode = res.data.voyageId;
            if (this.apiResponseVoyage && this.apiResponseVoyage.voyageId) {
              this.getCruiseImageDrupal();
            }

            // Calling constructor Functions
            this.constructorDataFn();
          } else {

            this.router.navigate(['/request-quote']);
          }
        },
        err => {

          console.log('response Elastic Voyage Request Quote specific ERROR+++++');
          console.log(err);
        }
      );
    // }

  }
  /* ==========================================================
    Function to call ComboId by passing voyageId
  ================================================================== */
  getComboIdApiCall() {

    /****************** Section Combo********************/

    this.rcycInternalApisService.getDrupal(this.apiUrlComboId).subscribe(
      (res : any=[]) => {
        if (res.length) {
          this.apiResponseComboId = res[0];
        }
      },
      err => {
        console.log('Section ComboId Request Quote Api error');
        console.log(err);
      }
    );

  }

  /* =======================================================================
    Funtion for creating token for request Quote (Generic / Specific)
  * ===========================================================================*/
  requestQuoteFormTokenFn() {

    this.rcycFormTokenService.formTokenService().subscribe(
      (res: any) => {
        if (res && res.code === 200 && res.data.logInfo) {
          this.requestQuoteFormToken = res.data.logInfo;
        }
      },
      err => {
        console.log('err  formTokenService Request Quote');
        console.log(err);
      }
    );
  }

  // Function to get Drupal API
  getCruiseImageDrupal() {
    const Api_Url = '/api/v1/voyages/thumbnails?_format=json';

    this.rcycInternalApisService.getDrupal(Api_Url).subscribe(
      (res : any=[]) => {
         if (res.length) {

          this.filterByVoyageId(res);
         }
      },
      (err) => {
         console.log('Error Occured on Getting drupal images');
         console.log(err);
       }
     );
   }

   // Function to filter based on voyageId
   filterByVoyageId(drupalImageArray) {
     if (this.voyageId && drupalImageArray.length &&
      this.apiResponseVoyage && this.apiResponseVoyage.voyageId) {
        drupalImageArray.filter((data, index2) => {
          if (this.apiResponseVoyage.voyageId === data.field_voyages) {
            // Set drupal image url
            this.voyageImagePath = [data.field_voyage_thumbnail];
            this.getSlider();
          }
        });
     }
   }

   /* ==================================================================================================================
    Function to get field_resco_voyage_code by passing voyageId
  =====================================================================================================================*/
   getRescoVoyageCodeFromDrupal(url) {
    // this.rcycInternalApisService.getDrupal(url).subscribe(
    //   (response) => {
    //     this.rescoVoyadeCodeArray = response;

    //     if (this.rescoVoyadeCodeArray && this.rescoVoyadeCodeArray.length) {
    //       this.rescoVoyageCode = this.rescoVoyadeCodeArray[0].field_resco_voyage_code;
    //     }
    //   },
    //   err => {
    //     console.log('Error in Getting Resco Voyage Code From Drupal', err);
    //   }
    // );
   }
/* ===============================================================
    Function to go back url
  ==================================================================*/
   backToPrevPage() {
    let previousUrl = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
    this.rcycCommonService.setSessionData('voyageBackButtonPressed', true);
    if (previousUrl) {
      previousUrl = previousUrl.replace(/['"]+/g, '');
      // this.rcycCommonFindACruiseService.setCruiseInfoBackBtn(false);
      if (previousUrl.includes('region=') || previousUrl.includes('port=') || previousUrl.includes('date=')) {
        // Set Session variables for Query Param Results
        if (previousUrl.includes('region=')) {
         // this.rcycCommonService.setSessionData('findACruiseRegionCodes', regionCodes)
        }
        if (previousUrl.includes('port=')) {

        }
        if (previousUrl.includes('date=')) {

        }
       const splittedBySlash =  previousUrl.split('/')[1];
       if (splittedBySlash) {
          const splittedByQuestionMark = splittedBySlash.split('?')[0];
          if (splittedByQuestionMark)  {
            previousUrl = splittedByQuestionMark;
          }
       }
        this.previousUrlStatus = false;
      }
      setTimeout(() => {
        this.router.navigate([previousUrl]);
      }, 500);
    }
  }

/* ==================================================================================================================
    Function to get Area code on Country Select
  =====================================================================================================================*/
  getAreaCodeOnCountrySelect(cntrycode) {
    if (cntrycode) {
      this.apiUrlE = '/api/v1/countryphone/' + cntrycode + '?_format=json';
      this.rcycHomeService.home(this.apiUrlE).subscribe(
        (res : any=[]) => {
          this.rcycCommonService.userAreaDetails(res);
        },
        err => {
          console.log('Error in Getting Area', err);
        }
      );
    }
  }

   /* ===================================================
    Function for select country click
  =======================================================*/
  selectCountryClick(item) {
    this.lead_gen_form.get('phone').setValue('');
    this.lead_gen_form.get('countryCode').setValue(item.code);
    this.lead_gen_form.get('dialCode').setValue(item.dial_code);
    this.userCountryDetails.dial_code = item.dial_code;
    this.userCountryDetails.code = item.code;
    this.userCountryDetails.flagUrl = item.flagUrl;
    this.userCountryDetails.ph_length = Number(item.ph_length);
    this.userCountryDetails.defaultFormat = item.defaultFormat;
    this.userCountryDetails.defaultPhoneNumber = item.defaultPhoneNumber;
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
    this.mask = getMaskedCode(item);
  }

  /* ===================================================
     Function for get outside click of country list
   =======================================================*/
  onClickedOutside(e: Event) {
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
  }
  phoneList: any;
  downloadImageArray: any = [];

  download() {
    this.http.get('./assets/rescoNumberFormatted.json').subscribe(phonelist => {
      this.phoneList = phonelist;
      this.phoneList.map( (item, index) =>  {
        // item.flagUrl = 'https://flagpedia.net/data/flags/normal/'+ item.code.toLowerCase() +'.png';
        item.flagUrl = 'https://www.countryflags.io/'+item.code.toLowerCase()+'/flat/64.png';
        // const options = {
        //   url: 'http://www.countryflags.io/'+item.code.toLowerCase()+'/flat/64.png',
        //   dest: 'assets/flags'                // Save to /path/to/dest/image.jpg
        // }

        // download.image(options)
        //   .then(({ filename, image }) => {
        //     console.log('Saved to', filename)  // Saved to /path/to/dest/image.jpg
        //   })
        //   .catch((err) => console.error(err))
        this.downloadImageArray.push('https://www.countryflags.io/'+item.code.toLowerCase()+'/flat/64.png');
        // if(this.phoneList.length === index) {
        //   downloadFiles(files).then(function (response) {
        //     //on success
        //     var file = new Blob([response.data], { type: 'application/zip' });
        //     saveAs(file, 'example.zip');
        //   }, function (error) {
        //       //on error
        //       //write your code to handle error
        //   });
        // }


      });
    });
  }

  /* ===================================================
     Function for zipcode format
   =======================================================*/
   checkZipcodeFormat(input) {
    let countryCode=this.lead_gen_form.get('country').value;
    if(!this.isRequestQuoteStatus) {
      const countryName=this.lead_gen_form.get('country').value;
      countryCode=this.countryListArray.filter(p => p.name === countryName)[0].code;
    }

    if(countryCode&&countryCode.toUpperCase()==="US") {

      if(isNaN(input.data)) {
        input.srcElement.value=input.srcElement.value.replace(input.data,'');
      }
      const position=input.target.selectionStart;
      let value=input.srcElement.value;
      value=value.replace(/-/g,'');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), '-', value.slice(5)].join('');
      }
      this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      input.target.selectionStart=(position>5)?position+1:position;
      input.target.selectionEnd=(position>5)?position+1:position;
    } else if(countryCode&&countryCode.toUpperCase()==="CA") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>3)  {
        input.srcElement.value=[value.slice(0, 3), ' ', value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="AF") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>4)  {
        input.srcElement.value=[value.slice(0, 4), ' ', value.slice(4)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="PK") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), ' ', value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="SD") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>3)  {
        input.srcElement.value=[value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="ET") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>4)  {
        input.srcElement.value=[value.slice(0, 4), ' ', value.slice(4)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="IR") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), '', value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>12){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,12));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="NG") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>12){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,12));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="YE") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>12){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,12));
      }
    }
    else if(countryCode&&countryCode.toUpperCase()==="IQ") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5),value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>12){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,12));
      }
    }
    else{

      let value= input.srcElement.value.replace(/  /g, ' ');
      this.lead_gen_form.get('zipcode').setValue(value.replace(/--/g, '-'));
    }

  }

  specialCountryLiClick(item) {
    this.selectCountryClick(item);
    this.lead_gen_form.get('country').setValue(item.name);
    this.specialCountryInputClickStatus=false;
    this.specialCountryInputCloseBtnStatus=false;
    this.isSpecialCountrySelect=true;
    this.countryValueChangeFn(this.lead_gen_form.get('country').value);
  }
  specialCountryInputCloseClick() {
    this.lead_gen_form.get('country').setValue('');
    this.specialCountryInputCloseBtnStatus=false;
  }
  // onClickedOutside(e: Event) {
  //   console.log('Clicked outside:', e);
  // }
  onChange($event) {
    if ($event.target.value === 'AT' || $event.target.value === 'BE' || $event.target.value === 'BG' || $event.target.value ==='HR' || $event.target.value === 'CY' || $event.target.value === 'CZ' || $event.target.value === 'DK' || $event.target.value === 'EE'
      || $event.target.value === 'FI' || $event.target.value === 'FR' || $event.target.value === 'DE' || $event.target.value === 'GR' || $event.target.value === 'HU' || $event.target.value === 'IT' || $event.target.value === 'LV' || $event.target.value === 'LT'
      || $event.target.value === 'LU' || $event.target.value === 'MT' || $event.target.value === 'NL' || $event.target.value === 'PL' || $event.target.value === 'PT' || $event.target.value === 'RO' || $event.target.value === 'SK' || $event.target.value === 'SI'
      || $event.target.value === 'ES' || $event.target.value === 'SE' || $event.target.value === 'GB' || $event.target.value === 'CH' || $event.target.value === 'BH' || $event.target.value === 'IL' || $event.target.value === 'QA' || $event.target.value === 'TR'
      || $event.target.value === 'KE' || $event.target.value === 'MU' || $event.target.value === 'NG' || $event.target.value === 'ZA' || $event.target.value === 'UG' || $event.target.value === 'JP' || $event.target.value === 'KR' || $event.target.value === 'NZ'
      || $event.target.value === 'AR' || $event.target.value === 'BR' || $event.target.value === 'UY' || $event.target.value === 'CA' || $event.target.value === 'IE'
    ) {
      this.showPhoneopt = true;
    } else {
      this.showPhoneopt = false;
    }
  }
  countryValueChangeFn(val) {
    if(!this.isRequestQuoteStatus) {
      if (val) {
        val=this.countryListArray.filter(p => p.name === val)[0].code;
        this.selectCountryCode=val;
      }
    }
    let zipCodeValidation: any;
    this.zipcodeReqStatusDynamic = false;
    this.zipcodeMaxLength=10;
    if (val) {
      this.getAreaCodeOnCountrySelect(val);
      this.userCountryDetails = Object.assign({},this.formttedPhoneList[val]);
      this.userCountryDetails.ph_length = Number(this.formttedPhoneList[val].ph_length);
      this.lead_gen_form.get('phone').setValue('');
      this.lead_gen_form.get('countryCode').setValue(val);
      this.lead_gen_form.get('dialCode').setValue(this.formttedPhoneList[val].dial_code);
    }
    else{
      this.getAreaCodeOnCountrySelect(this.permanentGeoLocation.code);
      this.userCountryDetails = Object.assign({},this.permanentGeoLocation);
      this.userCountryDetails.ph_length = Number(this.permanentGeoLocation.ph_length);
    }
    if (val === 'US') {
      zipCodeValidation = /^[0-9]{5}(?:-[0-9]{4})?$/;
      this.lead_gen_form.get('isAgreeToContact').setValue(false);
      this.lead_gen_form.get('isAgreeToReceiveMail').setValue(true);
      this.zipcodeReqStatusDynamic = true;
      this.showPhoneopt = false;
      this.showTermUS = true;
      this.lead_gen_form.get('phone').clearValidators();
      this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
      this.lead_gen_form.get('phone').updateValueAndValidity();
      this.phNumReqStatusDynamic = false;
    }
    else {
      this.showTermUS = false;
      if (val === 'CA') {
        zipCodeValidation = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        this.zipcodeReqStatusDynamic = true;
        this.zipcodeMaxLength=7;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
        this.phNumReqStatusDynamic = false;
      }
      else if  (val === 'AF'){
        this.zipcodeMaxLength=4;
      zipCodeValidation = /^(1[0-9]|2[0-9]|3[0-9]|4[0-3])(0[1-9]|[1-9][0-9])$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if  (val === 'PK'){
        this.zipcodeMaxLength=5;
        zipCodeValidation =  /^([1-9][0-9]|[1-8][0-9]|9[0-8])([0-9]{3})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if  (val === 'SD'){
        this.zipcodeMaxLength=5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if  (val === 'ET'){
        this.zipcodeMaxLength=4;
        zipCodeValidation = /^([1-9]{1})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if  (val === 'IR'){
        this.zipcodeMaxLength=10;
       zipCodeValidation = /\b(?!(\d)\1{3})[13-9]{4}[1346-9][0-9]{5}\b/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if  (val === 'NG'){
        this.zipcodeMaxLength=5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if  (val === 'YE'){
        this.zipcodeMaxLength=5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if  (val === 'IQ'){
        this.zipcodeMaxLength=5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
       else {
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
        this.phNumReqStatusDynamic = false;
      }
      this.lead_gen_form.get('isAgreeToContact').setValue(false);
      this.lead_gen_form.get('isAgreeToReceiveMail').setValue(false);
    }
    if (zipCodeValidation) {
      this.lead_gen_form.get('zipcode').setValidators([Validators.required, Validators.pattern(zipCodeValidation)]);
      this.lead_gen_form.get('zipcode').updateValueAndValidity();
    } else {
      this.lead_gen_form.get('zipcode').setValidators(null);
      this.lead_gen_form.get('zipcode').updateValueAndValidity();
    }
    this.phoneValueChangeFn();
  }

  leadGenSubmit() {
    this.specialOfferFormErrorMsg='';
    let honeyField = <HTMLInputElement> document.getElementById('thirdNameId');
    if(this.isRequestQuoteStatus && !honeyField.value) {
      this.leadGenSubmitApiCall();
    } else {
      if(
        this.lead_gen_form.controls.firstname.invalid||
        this.lead_gen_form.controls.lastname.invalid||
        this.lead_gen_form.controls.country.invalid||
        this.lead_gen_form.controls.email.invalid||
        this.lead_gen_form.controls.zipcode.invalid||
        (
          this.lead_gen_form.controls.phone&&this.lead_gen_form.controls.phone.errors&&
          (
            this.lead_gen_form.controls.phone.errors.phoneIncorrect==true||
            (this.lead_gen_form.controls.phone.errors.phoneIncorrect!=false&&this.phNumReqStatusDynamic)
          )
        )||
        !this.userArea||
        !this.isSpecialCountrySelect
      ) {
        this.specialOfferFormErrorMsg='Please fill all mandatory fields';
        // setTimeout(() => {
        //   const invalidControl = this.elementRef.nativeElement.querySelector('.error-borderBtm');
        //   if (invalidControl) {
        //     invalidControl.focus();
        //   }
        // }, 500);
        document.querySelector('.page-title').scrollIntoView();
      } else {
        this.leadGenSubmitApiCall();
      }
    }

  }
  getMyProfileDetails() {
    const param = {
    };
    const apiUrl = '/secureapi/getMyProfileDetails';
    this.rcycInternalApisService.postReservationFormWithAuth(apiUrl, param).subscribe(
      res  => {
        const data: any = res;
        this.lead_gen_form.patchValue({
          'firstname' : data.data.forename,
          'lastname' : data.data.surname,
          'country': data.data.country,
          'zipcode' : data.data.postalCode,
          'email' : data.data.email1
        });
        if (data.data.phone1) {
          let phoneWOdialcode=data.data.phone1.replace(this.userCountryDetails.dial_code, '');
          this.lead_gen_form.patchValue({ 'phone': phoneWOdialcode });

          if(this.lead_gen_form.controls.phone.errors.getPhoneCountryCode) {
            let getPhoneCountryCode=this.lead_gen_form.controls.phone.errors.getPhoneCountryCode;
            this.lead_gen_form.get('countryCode').setValue(getPhoneCountryCode);
            this.lead_gen_form.get('dialCode').setValue(this.formttedPhoneList[getPhoneCountryCode].dial_code);
            this.userCountryDetails.dial_code = this.formttedPhoneList[getPhoneCountryCode].dial_code;
            this.userCountryDetails.code = getPhoneCountryCode;
            this.userCountryDetails.flagUrl= this.formttedPhoneList[getPhoneCountryCode].flagUrl;
            this.userCountryDetails.defaultFormat = this.formttedPhoneList[getPhoneCountryCode].defaultFormat;
            this.userCountryDetails.defaultPhoneNumber = this.formttedPhoneList[getPhoneCountryCode].defaultPhoneNumber;
            let phoneWOdialcodeSecond=data.data.phone1.replace(this.formttedPhoneList[getPhoneCountryCode].dial_code, '');
            this.lead_gen_form.patchValue({ 'phone': phoneWOdialcodeSecond });
          }
        }
      },
      err=>{
        console.log(err)
      })
    }

    phoneValueChangeFn() {
      if (this.lead_gen_form.value.phone) {
        if (this.lead_gen_form.value.isAgreeToContact = false) {
          this.showPhoneOptRequiredText = false;
        }
      } else {
        this.showPhoneOptRequiredText = false;
      }
    }
    phoneOptValueChanged() {        
      if(this.lead_gen_form.value.isAgreeToContact) {
        this.lead_gen_form.get('phone').setValidators([Validators.required,ValidatePhoneNumber]);
        this.phNumReqStatusDynamic = true;
        this.showPhoneOptRequiredText = false;
      }
      else {
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.phNumReqStatusDynamic = false;
        if (this.lead_gen_form.value.phone) {
          this.showPhoneOptRequiredText = true;
        }
      }
      this.lead_gen_form.get('phone').updateValueAndValidity();
    }
    getDrupalErrorMessages() {
      const apiUrl = "/api/v1/errormessages?_format=json";
      this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
        (response:any) => {
          if (response && response.length) {
            this.drupalErrorMessages = response[0];
          }
        },
        err => {
          console.log('Drupal Call Api error');
          console.log(err);
        }
      );
    }

    checkForRedirection() {
      this.rcycNewFindACruiseService.getPreviewVoyages().subscribe(
        (value: PreviewVoyageList) => {
          if (value.field_voyages.find(previewVoyageCode => previewVoyageCode == this.voyageId)) {
            this.router.navigate(['/request-quote']);
          }
        }
      )
    }

  /**
   * Function to check the status of voyage for redirection of expired or invalid voyages
   * @param voyageId id of specific voyages
   */
  isCancelledVoyageRedirection(voyageId) {
    this.rcycRequestQuoteService.invalidVoyageDetails(voyageId).subscribe({
      next: (response: any = []) => {
        if (response.code == 200) {
          if (response.data.voyageStatus != "ACTIVE") {
            this.router.navigate(['/request-quote']);
          }
        }
      },
      error: (error) => {
        console.log("Elastic Api error in getting expired/cancelled voyage");
        console.log(error);
      },
    });
  }

  getCountryCodeFromGeoAPI() {
    if (!this.rcycCommonService.getData('UserDetails')) {
      this.rcycCommonService.getUser_Details().subscribe(
        response => {
          this.geoApiResponse = response;
        },
        err => {
          console.log('Geo Port APi err');
        }
      );
    }  else {
      this.geoApiResponse = this.rcycCommonService.getData('UserDetails');
    }
  }

}
