import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CounrtyHighlightPipe'
})
export class RcycCounrtyHighlightPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!args)return value;
    return value.replace('+', '').replace(new RegExp(args.trim().replace('+', ''), "gi"), match => {
      return '<span class="highlightText">' + match + '</span>';
    });;
  }

}