<div id="rcyc-offers-programs-landing" class="flex gap-8 flex-col">
  <!-- Banner Image -->
  <div class="h-[400px] -mt-[51px] lg:-mt-[84px] relative  m-[0px]">
    <img [src]="apiResponseG?.field_image_1440px" class="fit-image" alt="">
    <div class="absolute top-1/3 left-0 -translate-y-1/2 mt-[25px] lg:mt-[42px] text-white w-full">
      <div class="container lg:mb-[0px] lg:mt-[0px] ">
        <div class="md:mb-[24px] mb-[14px]" [innerHtml]="apiResponseG?.field_com_eyebrow"></div>
        <h1 class="text-[32px] lg:text-[61px] leading-tight font-big-caslon mb-3 lg:mb-6"
          [innerHtml]="apiResponseG?.title"></h1>
        <div *ngIf="apiResponseG?.field_com_cta_link" class="text-white uppercase mb-[18px] tracking-wide"><a
            [routerLink]="'/request-call'" [innerHtml]="apiResponseG?.field_com_cta_title"
            class="link-cta text-white"></a>
        </div>
      </div>
    </div>
  </div>

  <div class="container overflow-hidden mt-[31px]">
    <div class="flex lg:flex-row flex-col lg:gap-3 gap-0 pb-0">
      <div class="lg:w-1/2 flex flex-col gap-5 lg:gap-8">
        <div class="lg:text-[14px] text-[#1C1C1C]" [innerHtml]="apiResponseH?.field_com_eyebrow"></div>
        <h2 class="lg:text-[49px] text-[29px] leading-[2rem] lg:leading-[3rem]" [innerHtml]="apiResponseH?.title">
        </h2>
      </div>
      <div>
        <!--Sign Up and Close Button for Small Screens-->
        <div *ngIf="viewValue ==='mobile'&& !signUpStatus"  class="flex justify-center">
          <button 
            class="group !mt-0  w-full lg:min-w-[130px] bg-black hover:bg-transparent hover:text-black"
            (click)="populateSignUp()">
            <div class="flex items-center justify-center gap-2.5">
              <!-- Arrow images -->
              <span>
                <img src="assets/img/down-arrow-white.svg" class="block group-hover:display-none w-4"
                  alt="Down Arrow White">
              </span>
              <span>
                <img src="assets/img/down-arrow.svg" class="display-none group-hover:block w-4" alt="Down Arrow Black">
              </span>
              <span>Sign up</span>
            </div>
          </button>
        </div>
        <div *ngIf="viewValue ==='mobile'&& signUpStatus" class="flex justify-center">
          <button 
            class=" group !mt-0  w-full lg:min-w-[130px]  text-black bg-transparent hover:bg-black hover:text-white"
            (click)="populateSignUp()">
            <div class="flex items-center justify-center gap-2.5">
              <!-- Arrow images -->
              <span>
                <img src="assets/img/up-arrow.svg" class="block group-hover:display-none w-4" alt="Up Arrow Black">
              </span>
              <span>
                <img src="assets/img/up-arrow-white.svg" class="display-none group-hover:block w-4"
                  alt="Up Arrow White">
              </span>
              <span>Close</span>
            </div>
          </button>
        </div>
      </div>
      <div *ngIf="signUpStatus || viewValue ==='desktop' " class="lg:w-1/2 lg:mt-9">
        <div class="text-content text-base mt-[20px] md:mt-[0px] lg:mt-[0px] lg:pl-[11px]" [innerHtml]="apiResponseH?.field_summery"></div>
      </div>
    </div>
    <!-- Form  -->
    <form *ngIf="signUpStatus || viewValue ==='desktop'" id="requestCallFormId" class="mb-6" name="requestCallForm"
      [formGroup]="leadGenFform" novalidate autocomplete="false">
      <fieldset>
        <div class="basic-form_group !mb-0 lead_form_field lg:grid grid-cols-2 lg:gap-x-12">
          <div class="field-wrapper relative mb-[24px]">
            <input id="firstNameId" class="basic-form_input w-full !mb-0" type="text" formControlName="firstname"
              placeholder="First Name" maxlength="30">
            <ng-container *ngIf="leadGenFform?.controls?.firstname.value === ''">
              <label
                class="form-label absolute left-0 text-base basic-form_label-required w-full first-name-req top-[4px] left-[73px]"></label>
            </ng-container>
            <p class="errorptag"
              *ngIf="leadGenFform.controls.firstname.invalid && (leadGenFform.controls.firstname.dirty || leadGenFform.controls.firstname.touched)">
              <span *ngIf="leadGenFform.controls.firstname.errors.required" class="error">This field is
                required</span>
              <span
                *ngIf="!leadGenFform.controls.firstname.errors.required&&leadGenFform.controls.firstname.errors.pattern"
                class="error">Please enter a valid first name </span>
            </p>
          </div>
          <div class="field-wrapper relative mb-[24px]">
            <input id="lastNameId" class="basic-form_input w-full !mb-0" type="text" formControlName="lastname"
              placeholder="Last Name" maxlength="60">
            <ng-container *ngIf="leadGenFform?.controls?.lastname.value === ''">
              <label
                class="form-label absolute top-[11px] left-0 text-base basic-form_label-required first-name-req top-[4px] left-[73px]"></label>
            </ng-container>
            <p class="errorptag"
              *ngIf="leadGenFform.controls.lastname.invalid && (leadGenFform.controls.lastname.dirty || leadGenFform.controls.lastname.touched)">
              <span *ngIf="leadGenFform.controls.lastname.errors.required" class="error">This field is required</span>
              <span
                *ngIf="!leadGenFform.controls.lastname.errors.required&&leadGenFform.controls.lastname.errors.pattern"
                class="error">Please enter a valid last name </span>
            </p>
          </div>
          <div class="field-wrapper relative mb-[24px]">
            <select id="selectCountryId" class="basic-form_input w-full !mb-0 relative h-[47px] text-[16px] left-[-4px]"
              formControlName="country" (change)="onChange($event)">
              <option id="selectCountryId_0" value="">Country / Region</option>
              <option *ngFor="let county of countryListArray;let i=index" id="countryOptionId_{{i+1}}"
                value="{{county.code}}">{{county.name}}</option>
            </select>
            <ng-container *ngIf="leadGenFform?.controls?.country.value === ''">
              <label
                class="form-label absolute top-[11px] left-0 text-base basic-form_label-required country-req top-[4px] left-[116px]"></label>
            </ng-container>
            <span
              *ngIf="leadGenFform.controls.country.invalid && (leadGenFform.controls.country.dirty || leadGenFform.controls.country.touched) && leadGenFform.controls.country.errors.required"
              class="error">This field is required</span>
          </div>
          <div class="field-wrapper relative lg:mb-0 mb-[24px]">
            <input id="emailId" class="basic-form_input w-full !mb-0" type="email" maxlength="60"
              formControlName="email" placeholder="Email Address">
            <ng-container *ngIf="leadGenFform?.controls?.email.value === ''">
              <label
                class="form-label absolute top-[11px] left-0 text-base basic-form_label-required email-req top-[4px] left-[98px]"></label>
            </ng-container>
            <p class="errorptag"
              *ngIf="leadGenFform.controls.email.invalid && (leadGenFform.controls.email.dirty || leadGenFform.controls.email.touched)">
              <span *ngIf="leadGenFform.controls.email.errors.required" class="error">This field is required</span>
              <span *ngIf="!leadGenFform.controls.email.errors.required&&leadGenFform.controls.email.errors.pattern"
                class="error">
                <!-- Please enter a valid email address (Example: johndoe@example.com) -->
                Enter a valid email address (e.g. name@example.com)
              </span>
            </p>
          </div>
        </div>

        <div *ngIf="termsData" class="termsSection lg:grid grid-cols-2 lg:gap-x-12 w-full">
          <ng-container *ngIf="showPhoneopt">
            <div *ngIf="termsData?.field_term1" class="eachTermSection flex">
              <input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail" 
                aria-labelledby="emailMarketing">
              <p class="termContent pl-2" id="emailMarketing" [innerHtml]="termsData?.field_term1"></p>
            </div>
          </ng-container>
        </div>
      </fieldset>
    </form>
    <!-- Submit and disclainer section -->
    <div *ngIf="signUpStatus || viewValue ==='desktop'" class="">
      <div class="mt-[24px]">
        <div class="text-[13px] mb-6">
          <span class="text-[#ce4844]">*</span> Indicates required field
        </div>
        <div class="mb-11">
          <div id="terms" class="text-[13px]" *ngIf="termsData && termsData?.field_summery && !showTermUS"
            [innerHtml]="termsData?.field_summery"></div>
          <div id="terms" class="text-[13px]" *ngIf="termsData && termsData?.field_summery && showTermUS"
            [innerHtml]="termsData?.field_summary_us"></div>
        </div>
        <div *ngIf="submitLoading" class="row is-12 btnLoaderOuter">
          <div class="btnLoader">
            <img src="assets/components/images/loading.gif" class="formLoader" alt="">
          </div>
        </div>
        <div class="flex flex-col lg:flex-row">
          <div *ngIf="!submitLoading" class="">
            <input type="submit" id="requestQuoteSubmitId" (click)="eNewsletterSignupSubmit()" [disabled]="leadGenFform?.controls?.firstname?.invalid ||
                 leadGenFform?.controls?.lastname?.invalid ||
                 leadGenFform?.controls?.country?.invalid ||
                 leadGenFform?.controls?.email?.invalid" class="button-primary lg:min-w-[200px]" value="Submit">
          </div>
          <input type="reset" class="button-tertiary lg:w-auto lg:min-w-[130px] bg-transparent" id="requestQuoteResetId"
            (click)="ngOnInit()" value="Clear All" [ngClass]="{'disabledCursor': submitLoading}"
            [disabled]="submitLoading">
          <ng-container *ngIf="submitSuccessful">
            <div class="text-success flex gap-2 tracking-wide items-center w-full">
              <img src="assets/img/success.svg" alt="">
              Your request has been sent successfully
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="flex lg:flex-row flex-col lg:gap-3 gap-0 lg:pb-0 pb-8 pt-12 lg:pt-20">
      <div class="lg:w-1/2 flex flex-col gap-3">
        <div class="text-base uppercase" [innerHtml]="apiResponseI?.field_com_eyebrow"></div>
        <h2 class="text-[29px] lg:text-[49px] leading-[2rem] lg:leading-normal" [innerHtml]="apiResponseI?.title"></h2>
      </div>
      <div class="lg:w-1/2">
          <div class="text-content text-base" [innerHtml]="apiResponseI?.field_summery"></div>
      </div>
    </div>

    <div class="lg:pt-12 gap-x-12 gap-y-16 grid lg:grid-cols-2">
      <app-rcyc-offer-card *ngFor="let item of apiResponseB" (clearCompareValueEmitter)="clearCompareValue($event)" variant="offers-programs-landing" [data]="item"></app-rcyc-offer-card>
    </div>
    <div class="px-0 lg:px-40 pt-8 lg:pt-16 lg:pb-8 lg:mt-16 lg:mb-0 text-[13px]" [innerHTML]="apiResponseE?.field_summery">
    </div>
  </div>

  <!-- Slider Featured-->
  <div class="py-10 lg:py-16">
    <app-rcyc-slider variant="featured" id="home-featured-slider" *ngIf="apiResponseD" [data]="{
  topContent: {
    eyebrow: apiResponseD[0]?.field_com_eyebrow,
    title: apiResponseD[0]?.title | convertHtml,
  },
  items: apiResponseD
}"></app-rcyc-slider>
  </div>
</div>