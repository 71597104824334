import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../rcyc-config/config";
import { Observable ,  of } from "rxjs";
import { map, tap } from "rxjs/operators";

export interface PreviewVoyageResponse {
  title: string;
  field_alternative_label: string;
  field_voyages: string;
}
export interface PreviewVoyageList {
  field_alternative_label: string;
  field_voyages: Array<string>;
}
@Injectable()
export class RcycNewFindACruiseService {
  API_BASE_URL: any = AppConfig.API_BASE_URL;
  private previewVoyageListCache: PreviewVoyageList;
  constructor(private http: HttpClient) {}

  // Function to get drupal API
  getCruiseImageDrupal(apiUrl: string) {
    const url = this.API_BASE_URL + apiUrl;
    return this.http.get(url);
  }
  /**
   * Function to return Preview Voyages from cache if its present, or returns getPreviewVoyagesDrupal()
   * @returns Observable<PreviewVoyageList>
   */
  getPreviewVoyages(): Observable<PreviewVoyageList> {
    if (this.previewVoyageListCache) {
      return of(this.previewVoyageListCache);
    } else {
      return this.getPreviewVoyagesDrupal();
    }
  }
  /**
   * Function to featch Preview Voyages from Drupal API
   * @returns Observable<PreviewVoyageList>
   */
  getPreviewVoyagesDrupal(): Observable<PreviewVoyageList> {
    const url = `${this.API_BASE_URL}/api/v1/preview-voyages?_format=json`;
    return this.http.get(url).pipe(
      map((value: Array<PreviewVoyageResponse | null>) => {
        if (value && value.length) {
          return {
            field_alternative_label: value[1].field_alternative_label,
            field_voyages: value[1].field_voyages.split(", "),
          };
        } else {
          return {
            field_alternative_label: "Preview Only",
            field_voyages: [],
          };
        }
      }),
      tap((previewVoyageList) => {
        this.previewVoyageListCache = previewVoyageList;
      })
    );
  }
}
