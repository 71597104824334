import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-rcyc-common-voyage-price',
    templateUrl: './rcyc-common-voyage-price.component.html',
})
export class CommonVoyagePriceComponent {
    @Input() currencyCode: any;
    @Input() voyagePrice: any;
    @Input() showCurrency?: boolean = true;
}
