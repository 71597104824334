<div class="container lg:container-lg md:container-lg">
<div class="flex flex-col-reverse lg:flex-row mx-[24px] gap-7 lg:gap-12">
  <div>
    <!-- left side -->
    <div>
      <!-- <p *ngIf="apiResponseB?.field_summery" [innerHtml]="apiResponseB?.field_summery" class="tracking-wide mb-6"></p> -->
      <p class="tracking-wide mb-6">Please complete the information below and a representative from The Ritz-Carlton Yacht
        Collection will contact you to provide any information you may need. If you are a professional travel advisor,
        please <a href="https://www.ritzcarltonyachtcollection.com/travelprofessionals">click here</a>.
      </p>
  
  
      <!-- <div id="formSubmitErrorId" *ngIf="formSubmitFailStatus">
        <span class="error">{{tokenErrorMsg}}</span>
      </div> -->
      <div id="formSubmitErrorId" *ngIf="quoteFormStatus">
        <span class="error">{{sessionExpiredMessages}}</span>
      </div>
  
  
  
      <form id="requestCallFormId" class="mb-6" name="requestCallForm" [formGroup]="lead_gen_form" novalidate
        autocomplete="false">
        <fieldset>
          <div class="field-wrapper relative mb-[24px] rcyc-hide-honey">
            <input id="thirdNameId" class="basic-form_input w-full !mb-0" type="text" name="honey" placeholder="Third Name"
              maxlength="60" autocomplete='off'>
            <label class="form-label absolute top-[11px] left-0 text-base basic-form_label-required">Third Name</label>
          </div>
          <div class="basic-form_group !mb-0 lead_form_field lg:grid grid-cols-2 lg:gap-x-12">
            <div class="field-wrapper relative mb-[24px]">
              <input id="firstNameId" [ngClass]="{'loyalty-bg':isLoyalty}" class="basic-form_input w-full !mb-0" type="text" formControlName="firstname"
                placeholder="First Name">
              <ng-container *ngIf="lead_gen_form?.controls?.firstname.value === ''">
                <label
                  class="form-label absolute left-0 text-base basic-form_label-required w-full first-name-req"></label>
              </ng-container>
              <p class="errorptag"
                *ngIf="lead_gen_form.controls.firstname.invalid && (lead_gen_form.controls.firstname.dirty || lead_gen_form.controls.firstname.touched)">
                <span *ngIf="lead_gen_form.controls.firstname.errors.required" class="error">This field is required</span>
                <span
                  *ngIf="!lead_gen_form.controls.firstname.errors.required&&lead_gen_form.controls.firstname.errors.pattern"
                  class="error">Please enter a valid first name </span>
              </p>
            </div>
            <div class="field-wrapper relative mb-[24px]">
              <input id="lastNameId" [ngClass]="{'loyalty-bg':isLoyalty}" class="basic-form_input w-full !mb-0" type="text" formControlName="lastname"
                placeholder="Last Name">
              <ng-container *ngIf="lead_gen_form?.controls?.lastname.value === ''">
                <label
                  class="form-label absolute top-[11px] left-0 text-base basic-form_label-required first-name-req"></label>
              </ng-container>
              <p class="errorptag"
                *ngIf="lead_gen_form.controls.lastname.invalid && (lead_gen_form.controls.lastname.dirty || lead_gen_form.controls.lastname.touched)">
                <span *ngIf="lead_gen_form.controls.lastname.errors.required" class="error">This field is required</span>
                <span
                  *ngIf="!lead_gen_form.controls.lastname.errors.required&&lead_gen_form.controls.lastname.errors.pattern"
                  class="error">Please enter a valid last name </span>
              </p>
            </div>
            <div class="field-wrapper relative mb-[24px]">
              <select id="selectCountryId" [ngClass]="{'loyalty-bg':isLoyalty}" class="basic-form_input w-full !mb-0" formControlName="country"
                (change)="onChange($event)">
                <option id="selectCountryId_0" value="">Country / Region</option>
                <option *ngFor="let county of countryListArray;let i=index" id="countryOptionId_{{i+1}}"
                  value="{{county.code}}">{{county.name}}</option>
              </select>
              <ng-container *ngIf="lead_gen_form?.controls?.country.value === ''">
              <label class="form-label absolute top-[11px] left-0 text-base basic-form_label-required country-req"></label>
            </ng-container>
              <span
                *ngIf="lead_gen_form.controls.country.invalid && (lead_gen_form.controls.country.dirty || lead_gen_form.controls.country.touched) && lead_gen_form.controls.country.errors.required"
                class="error">This field is required</span>
            </div>
            <div class="field-wrapper relative mb-[24px]">
              <ng-container *ngIf="isMobileScreen === false">
              <input id="zipCodeId" [ngClass]="{'loyalty-bg':isLoyalty}" class="basic-form_input w-full !mb-0" type="text" formControlName="zipcode"
                placeholder="ZIP/Postal Code (Required for US & Canada)" [maxlength]="zipcodeMaxLength"
                (input)="checkZipcodeFormat($event)">
              </ng-container>
                <ng-container *ngIf="isMobileScreen === true">
                <textarea id="zipCodeId" class="basic-form_input w-full !mb-0 zip-textarea" formControlName="zipcode"
                placeholder="ZIP/Postal Code (Required for US & &#10;Canada)" [maxlength]="zipcodeMaxLength"
                (input)="checkZipcodeFormat($event)"></textarea>
              </ng-container>
                <ng-container *ngIf="lead_gen_form?.controls?.zipcode.value === ''">
              <label class="form-label absolute "
                [ngClass]="{'basic-form_label-required': zipcodeReqStatusDynamic, 'zip-req':zipcodeReqStatusDynamic }"></label>
                </ng-container>
              <p class="errorptag"
                *ngIf="lead_gen_form.controls.zipcode.invalid && (lead_gen_form.controls.zipcode.dirty || lead_gen_form.controls.zipcode.touched) ">
                <span *ngIf="lead_gen_form.controls.zipcode.errors.required" class="error">This field is
                  required</span>
                <span
                  *ngIf="!lead_gen_form.controls.zipcode.errors.required&&lead_gen_form.controls.zipcode.errors.pattern"
                  class="error">ZIP/Postal Code is not valid </span>
              </p>
            </div>
            <div class="field-wrapper relative lg:mb-0 mb-[24px]">
              <input id="emailId" [ngClass]="{'loyalty-bg':isLoyalty}" class="basic-form_input w-full !mb-0" type="email" formControlName="email"
                placeholder="Email Address">
                <ng-container *ngIf="lead_gen_form?.controls?.email.value === ''">
                  <label class="form-label absolute top-[11px] left-0 text-base basic-form_label-required email-req"></label>
                </ng-container>
              <p class="errorptag"
                *ngIf="lead_gen_form.controls.email.invalid && (lead_gen_form.controls.email.dirty || lead_gen_form.controls.email.touched)">
                <span *ngIf="lead_gen_form.controls.email.errors.required" class="error">This field is required</span>
                <span *ngIf="!lead_gen_form.controls.email.errors.required&&lead_gen_form.controls.email.errors.pattern"
                  class="error">
                  <!-- Please enter a valid email address (Example: johndoe@example.com) -->
                  Enter a valid email address (e.g. name@example.com)
                </span>
              </p>
            </div>
            <div class="field-wrapper relative lg:mb-0 mb-[24px]">
              <div class="row">
                <div class="column">
  
                  <div id="phoneFlagContainerId" class="phoneFlagContainer !mb-0" (clickOutside)="onClickedOutside($event)">
                    <ng-container *ngIf="lead_gen_form?.controls?.phone.value === ''">
                    <label class="form-label absolute "
                      [ngClass]="{'basic-form_label-required': phNumReqStatusDynamic, 'phone-req': phNumReqStatusDynamic}"></label>
                    </ng-container>
                    <div class="row is-3-9">
                      <div class="column is-3 phoneFlagOuter" (keyup.enter)="flagCodeClickStatus = !flagCodeClickStatus"
                        (click)="flagCodeClickStatus = !flagCodeClickStatus" tabindex='0'>
                        <span class="flagOuter"><img [src]="userCountryDetails?.flagUrl" class="flagImg" /></span>
                        <span class="countryCodeOuter"> {{userCountryDetails?.dial_code}}</span>
                      </div>
  
                      <!-- Country List -->
                      <div *ngIf="flagCodeClickStatus" class="countryResultOuter">
                        <div *ngIf="flagCodeClickStatus" class="countryResultInner">
                          <!-- <input type="text" [(ngModel)]="countrySearch" (ngModelChange)="countrySearchChanged($event)" (ngModelChange)="searchCountryChange()"> -->
                          <div class="countrySearchOuter">
                            <input type="text" [(ngModel)]="countrySearch" [ngModelOptions]="{standalone: true}" />
                          </div>
                          <ul *ngIf="phoneDataArray.length" class="countrySearchListOuter">
                            <li *ngFor="let item of phoneDataArray | countryNameSearchFilter : countrySearch; let i=index"
                              (keyup.enter)="selectCountryClick(item)" (click)="selectCountryClick(item)" [ngStyle]="{'border-bottom':
                                    !countrySearch &&
                                    (
                                      ( i==2 && item?.code == 'CA') || (i==1 && item?.code == 'CA') ||
                                      ( (i==1  && item?.code == 'US' && permanentGeoLocation.code == 'CA') )
                                    )
                                    ? '1px solid #bfbfbf' : 'none'}" tabindex="0" class="eachPhoneCountryName"
                              (focusout)="(i == (phoneDataArray.length - 1) ) ? flagCodeClickStatus = false: '' ">
                              <span [innerHTML]="item?.name | CounrtyHighlightPipe : countrySearch"></span>
                              ({{item?.dial_code}})
                            </li>
                          </ul>
                          <p class="noResultsFound letterSpacing"
                            *ngIf="!(phoneDataArray | countryNameSearchFilter : countrySearch).length"> No results
                            match "{{countrySearch}}"</p>
                        </div>
                      </div>
                      <!-- Country List -->
  
                      <div class="column is-9 phoneNumberInputOuter">
                        <!-- <input type="text" [(ngModel)]="phoneNumber" class="phoneNumberInput" /> -->
                        <!-- //[textMask]="{mask: mask}" (focusout)="phoneTBFocusout()" -->
                        <input id="phoneId" [ngClass]="{'loyalty-bg':isLoyalty}" class="basic-form_input phoneNumberInput w-full !mb-0"
                          *ngIf="userCountryDetails?.defaultFormat" type="tel" formControlName="phone" placeholder="Phone Number"
                          [maxlength]="lead_gen_form?.controls?.phone?.errors?.phoneNoMaxLength"
                          [textMask]="{mask: mask, guide:false}" (input)="phoneValueChangeFn()" />
                      </div>
  
                    </div>
                    <p class="errorptag"
                      *ngIf="lead_gen_form.controls.phone.errors?.phoneIncorrect && (lead_gen_form.controls.phone.dirty || lead_gen_form.controls.phone.touched)">
                      <span *ngIf="lead_gen_form.controls.phone.errors?.required" class="phoneNoError error">This field is
                        required</span>
                      <span
                        *ngIf="!lead_gen_form.controls.phone.errors?.required&&lead_gen_form.controls.phone.errors?.phoneIncorrect"
                        class="phoneNoError error">Enter a valid phone number (e.g. {{
                        userCountryDetails?.defaultPhoneNumber }}) </span>
                    </p>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
  
          <!-- Preferred Voyage -->
          <div *ngIf="!isRaqSpecific" class="basic-form_group mt-[24px] !mb-0">
            <div class="row is-12">
              <div class="column is-12 form_fields">
                <label class="sr-only basic-form_label form_label">Preferred Voyage</label>
                <div class="multiselect-container">
                  <div class="multiselect-selected-options !mb-0" [ngClass]="{'open': isDropdownOpen}">
                    <div class="multiselect-select-box" [ngClass]="{'loyalty-bg':isLoyalty}" (click)="toggleDropdown()">
                      <span class="placeholder">
                        <span class="placeholder">{{ getFirstPortNames(selectedVoyagesDetails) }}</span>
                      </span>
                      <span class="moreVoyageSelected" [ngClass]="{'moreVoyageSelectedExeed':preferredVoyageLimitExceed}"
                        *ngIf="selectedVoyagesDetails.length > 1">+{{selectedVoyagesDetails.length - 1}}</span>
                      <span class="clear-button" (click)="clearDropdownOptions()"
                        *ngIf="isDropdownOpen && selectedVoyagesDetails.length > 0">Clear</span>
                      <span class="dropdown-icon"></span>
                    </div>
                    <div class="checkbox-options1" *ngIf="isDropdownOpen || keepDropdownOpen">
                      <div class="checkbox-option" *ngFor="let voyage of voyageListArray;let i=index">
                        <input class="optCB" type="checkbox" id="voyageCheckboxId_{{i+1}}" [value]="voyage.voyageId"
                          (change)="toggleCheckbox($event,voyage)" [checked]="selectedVoyages.includes(voyage.voyageId)">
                        <label class="opt label-width" [ngClass]="{'disableOpt': preferredVoyageLimitExceed}"
                          for="voyageCheckboxId_{{i+1}}">
                          {{voyage.voyageStartDate | date :'MMM dd, y' :
                          voyage.voyageStartDate.substring(voyage.voyageStartDate.length - 6)}}
                          | {{ ( voyage.voyageEmbarkPort != voyage.voyageDisembarkPort ) ? (
                          voyage.voyageEmbarkPort | titlecase ) + ' to ' + ( voyage.voyageDisembarkPort |
                          titlecase ) : (voyage.voyageEmbarkPort | titlecase ) + ' Roundtrip' }}
                          | {{voyage.nights}} Nights
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="errorptag" *ngIf="preferredVoyageLimitExceed">
                  <span class="error">More than 10 voyages cannot be selected</span>
                </p>
              </div>
            </div>
          </div>
  
          <div *ngIf="apiResponseCStatus && !showTermUS" class="termsSection lg:grid grid-cols-2 lg:gap-x-12 w-full">
            <ng-container *ngIf="showPhoneopt">
              <div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
                <input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail"
                  aria-labelledby="emailMarketing">
                <p class="termContent" id="emailMarketing" [innerHtml]="apiResponseC?.field_term1"></p>
              </div>
              <div *ngIf="apiResponseC?.field_term2" class="eachTermSection">
                <input id="phoneOptId" type="checkbox" value="true" formControlName="isAgreeToContact"
                  aria-labelledby="phoneMarketing" (change)="phoneOptValueChanged()">
                <p class="termContent" *ngIf="!showPhoneOptRequiredText" id="phoneMarketing"
                  [innerHtml]="apiResponseC?.field_term2"></p>
                <p class="termContent" *ngIf="showPhoneOptRequiredText" id="phoneMarketing"
                  [innerHtml]="apiResponseC?.field_term2_required"></p>
                <p class="errorptag marketingError" *ngIf="showPhoneOptRequiredText">
                  <span class="emailError error" [innerHtml]="drupalErrorMessages?.field_phone_opt_in_required"></span>
                </p>
              </div>
            </ng-container>
          </div> 
          <div class="basic-form_group mb-5 mt-[48px]">
            <div class="row is-12">
              <div class="column is-12 form_fields">
                <label class="sr-only basic-form_label form_label">Comments</label>
                <textarea id="commentId" [ngClass]="{'loyalty-bg':isLoyalty}" class="px-4 py-3 placeholder:text-primary !mb-0" rows="4"
                  formControlName="comment" placeholder="Comments"></textarea>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="apiResponseCStatus && showTermUS" class="termsSection">
            <div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
              <input id="emailOptId" type="checkbox" formControlName="isAgreeToReceiveMail"
                aria-labelledby="emailMarketing">
              <p class="termContent" id="emailMarketing" [innerHtml]="apiResponseC?.field_term1"></p>
            </div>
          </div> -->
          <!-- Remove *ngIf to show sms opt option -->
          <div class="basic-form_group" *ngIf="false">
            <label class="sr-only basic-form_label form_label">Is this a mobile phone number? (Optional)</label><br>
            <div class="form_radio_buttons">
              <input type="radio" id="mobileRadioYes" formControlName="isMobile" value="true" class="mobileRadio">
              <label class="radio_label" for="mobileRadioYes">Yes</label>
              <input type="radio" id="mobileRadioNo" formControlName="isMobile" value="false" class="mobileRadio"
                [checked]="true">
              <label class="radio_label" for="mobileRadioNo">No</label><br>
            </div>
  
            <div *ngIf="isMobilebutton=='true'" class="termsSection">
              <p class="mobiletext">May we contact you via SMS text messaging?</p>
              <div *ngIf="apiResponseC?.field_term1" class="eachTermSection">
                <input id="mobileOptId" type="checkbox" value="true" formControlName="isAgreeToSms"
                  aria-labelledby="mobileMarketing">
                <p class="termContent mobiletext" id="mobileMarketing" [innerHtml]="apiResponseC?.field_term3"></p>
              </div>
            </div>
          </div>
  
        </fieldset>
      </form>
    </div> 
    <!--      Success-->
  </div>

  <!-- right side -->
  <div *ngIf="apiResponseVoyageStatus && isRaqSpecific">
    <div class="lg:w-[384px]">
      <app-rcyc-card-featured
        variant="grid-small"
        [data]="{
          highlight: sliderHighlight,
          slider: voyageImagePath,
          voyageId: voyageId,
          yachtName: apiResponseVoyage.yachtName,
          voyageName: apiResponseVoyage?.voyageName,
         }"
        hideInfo="true"
        hideButtons="true"
        [imageScrollPosition]="'map'"
      >
        <div class="text-content uppercase text-primary">
          <div *ngIf="apiResponseVoyage?.voyageStartDate">
            {{apiResponseVoyage?.voyageStartDate | date:'MMMM d, YYYY' :'UTC'}}
          </div>
          <div>
            Voyage #{{voyageId}}
          </div>
        </div>
      </app-rcyc-card-featured>
    </div>

  </div>
</div>
</div>
<div class="container lg:container-lg md:container-lg">
<div class="mx-[24px] mt-[24px]">
  <div class="text-[13px] mb-6">
    <span class="text-[#ce4844]">*</span> Indicates required field
  </div>

  <div class="mb-6">
    <div id="terms" class="text-[13px]" *ngIf="apiResponseCStatus && apiResponseC?.field_summery && !showTermUS"
      [innerHtml]="apiResponseC?.field_summery"></div>
    <div id="terms" class="text-[13px]" *ngIf="apiResponseCStatus && apiResponseC?.field_summery && showTermUS"
      [innerHtml]="apiResponseC?.field_summary_us"></div>
  </div>
  

  <div class="flex flex-col lg:flex-row">
    <div *ngIf="submitLoading" class="row is-12 btnLoaderOuter">
      <div class="btnLoader">
        <img src="assets/components/images/loading.gif" class="formLoader" alt="">
      </div>
    </div>
    <div *ngIf="!submitLoading" class="">
      <input type="submit" id="requestQuoteSubmitId" (click)="leadGenSubmit()" [disabled]="lead_gen_form?.controls?.firstname?.invalid ||
           lead_gen_form?.controls?.lastname?.invalid ||
           lead_gen_form?.controls?.country?.invalid ||
           lead_gen_form?.controls?.email?.invalid ||
           lead_gen_form?.controls?.zipcode?.invalid ||
           lead_gen_form?.controls?.phone?.errors?.required ||
           lead_gen_form.controls?.phone?.errors?.phoneIncorrect==true ||
           !userArea ||
           showPhoneOptRequiredText ||
           preferredVoyageLimitExceed" class="button-primary lg:w-[200px]" value="Submit">
    </div>

    <input type="reset" class="button-tertiary lg:w-auto lg:min-w-[130px]" id="requestQuoteResetId"
      (click)="ngOnInit()" value="Clear All" [ngClass]="{'disabledCursor': submitLoading}" [disabled]="submitLoading">

    <ng-container *ngIf="submitSuccessful">
      <div class="text-success flex gap-2 tracking-wide items-center w-full"><img src="assets/img/success.svg" alt="">Your
        request has been sent successfully</div>
    </ng-container>
  </div>
</div>
</div>