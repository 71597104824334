<section id="banneimg_section" class="section-1" *ngIf='showBannerCarousel' (scroll)="scrollHandler($event)"
  [ngClass]="{'-mt-[0] lg:-mt-[0]': pageName !== 'home', '-mt-[51px] lg:-mt-[84px]': pageName === 'home'}">
  <!-- Section for Hero Banner Images  -->
  <div *ngIf="!heroVideo" class="h-scree w-full relative" (mouseenter)="enter()" (mouseleave)="leave()">
    <!-- Section for Load Images   -->
    <ngu-carousel #nguCarouselHomePage *ngIf="!heroVideo" [inputs]="heroCarouselSettings"
      [dataSource]="carouselImageArray">
      <ngu-item *nguCarouselDef="let item; let j=index" class="p-0">
        <div class="overflow-hidden"
          [ngClass]="{'h-full': pageName !== 'home', 'h-[100vh] relative': pageName === 'home'}">
          <img [src]="item.url" alt="" class="min-w-full w-auto max-w-[none] h-auto min-h-full cursor-pointer"
            [ngClass]="{'h-full absolute -translate-y-1/2 -translate-x-1/2': pageName === 'home', 'w-full': pageName !== 'home'}"
            (click)="sliderImgClick(j)">

          <!-- ::::: As only home page is going live, making the hero video/image component like old one -->
          <div *ngIf="pageName == 'home'" class="background-image bg-primary/40"></div>
          <div *ngIf="pageName == 'home'" #nguHeroCarouselContent
            class="mx-auto text-left text-primary absolute left-0 right-0 top-1/2 -mt-[40px] -translate-y-1/2 px-4 lg:px-0 lg:max-w-[1248px] text-white w-full lg:w-[calc(100%-48px)] lg:-translate-x-1/2">
            <div class="max-w-[816px]">
              <div class="uppercase mb-3 lg:mb-6">{{item.eyebrow}}</div>
              <h1 class="text-[32px] lg:text-[61px] leading-tight font-big-caslon mb-3 lg:mb-6">{{item.title}}</h1>
              <a *ngIf="item?.href" [href]="item.href"
                class="button-tertiary button-light p-0 float-left">{{item.cta}}</a>
            </div>
          </div>
        </div>
      </ngu-item>

      <ul #nguHeroCarouselControls id="carouselControls" *ngIf="carouselImageArray.length > 1"
        class="hidden carousel-dots list-none flex gap-[16px] m-0 items-center px-3 absolute top-1/2 lg:left-1/2 w-full lg:max-w-[1248px] lg:-translate-x-1/2"
        NguCarouselPoint>
        <li class="m-0 flex items-center">
          <button id="ngu_prev" NguCarouselPrev class="m-0 bg-transparent border-0 hover:bg-transparent p-0"
            tabindex="-1">
            <img src="assets/img/arrow_left_white.svg" alt="Scroll to the previous image"
              title="Scroll to the previous image">
          </button>
        </li>
        <li class="rounded-full bg-white/40 w-2 h-2 cursor-pointer m-0"
          *ngFor="let j of nguCarouselHomePage.pointNumbers" [class.active]="j==nguCarouselHomePage.activePoint"
          (click)="nguCarouselHomePage.moveTo(j)"></li>
        <li class="m-0 flex items-center">
          <button id="ngu_next" NguCarouselNext class="m-0 bg-transparent border-0 hover:bg-transparent p-0"
            tabindex="-1">
            <img src="assets/img/arrow_right_white.svg" alt="Scroll to the next image" title="Scroll to the next image">
          </button>
        </li>
      </ul>
    </ngu-carousel>
  </div>
  <div *ngIf="heroVideo" id="heroVideoOuterId"
    class="relative flex overflow-hidden justify-center items-center w-screen"
    [ngClass]="{'h-[80vh]': pageName !== 'home', 'h-[100vh]': pageName === 'home'}">
    <div class="absolute h-full pb-[56.25%] -z-50 bg-black" [ngClass]="{'w-[1000%]': '!yachtSuite || !destDetails', 'w-[100%]':'yachtSuite || destDetails'}">
      <div class="absolute w-full h-full inset-0">
        <iframe (load)="enablePlay()" [src]="heroVideo" *ngIf="heroVideo" id='{{current3PlayPuginId}}' tabindex="-1"
          allow="autoplay" loop="1" width="100%" height="100%" frameborder="0 " webkitallowfullscreen mozallowfullscreen
          allowfullscreen></iframe>
      </div>
    </div>

    <div class="l-container-wrapper absolute left-0 bottom-[20px] w-full">
      <div class="l-container flex justify-end gap-[10px]">
        <div *ngIf="current3PlayPuginId" id="threePlayMediaPlayPause" tabindex="0"
          (keyup)="keyUpVideoControl($event,isVideoPlaying)"
          (click)="isVideoPlaying?clickedVideoPause():clickedVideoPlay()">
          <a [title]="isVideoPlaying? 'Pause': 'Play'" class="cursor-pointer">
            <img [src]="isVideoPlaying ? 'assets/img/pause.svg' : 'assets/img/play.svg'" alt="" />
          </a>
        </div>

        <div *ngIf="current3PlayPuginId" id="threePlayMediaPlayCc" [tabindex]="isVideoPlaying?'0':'-1'"
          (click)="togleClosedCaption()" (keyup.enter)="togleClosedCaption()">
          <a [title]="!isCCEnabled ? 'Enable cc': 'Disable cc'" class="cursor-pointer">
            <img [src]="isCCEnabled ? 'assets/img/cc-off.svg' : 'assets/img/cc-on.svg'" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="heroVideo" class="container relative">
    <div *ngIf="pageName == 'home' || pageName == 'destinations' || pageName == 'yachtSuite'"
    class="w-full lg:w-3/5 text-left text-primary absolute left-0 bottom-[15rem] md:bottom-[20rem] lg:bottom-[15rem] top-auto -mt-[40px] -translate-y-1/2 px-4 text-white lg:max-w-[1248px] lg:-translate-x-1/2"
    [ngClass]="pageName === 'destinations'? '!bottom-[19rem] md:!bottom-[23.5rem] lg:!bottom-[12rem] ipad xsmob': '' ">
      <div class="uppercase mb-3 lg:mb-6">{{videoData.eyebrow}}</div>
      <h1 class="text-4xl md:text-[50px] lg:text-[61px] leading-tight font-caslon mb-3">
        {{videoData.title}}</h1>
      <a *ngIf="videoData?.url" [href]="videoData.url"
        class="button-tertiary button-light !p-0 float-left">{{videoData.cta}}</a>
    </div>
    <div *ngIf="destDetails"
      class="w-full lg:w-3/5 text-left text-primary absolute left-0 bottom-[5rem] md:bottom-[10rem] lg:bottom-[5rem] top-auto -mt-[40px] -translate-y-1/2 px-4 text-white lg:max-w-[1248px] lg:-translate-x-1/2">
      <div class="uppercase mb-3 lg:mb-6">{{videoData.eyebrow}}</div>
      <h1 class="text-4xl md:text-[50px] lg:text-[61px] leading-tight font-caslon mb-3 lg:mb-6">
        {{videoData.title}}</h1>
        <a *ngIf="videoData?.url && destDetails" [href]="videoData.url"
        class="button-tertiary button-light p-0 float-left">{{videoData.cta}}</a>
    </div>
  </div>
</section>