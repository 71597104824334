import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';
import { Observable } from 'rxjs';

@Injectable()
export class RcycVoyagesService {

  constructor(
    private http: HttpClient
  ) { }
  // Service to call dynamic content
  API_BASE_URL: string = AppConfig.API_BASE_URL;
  ELASTIC_API_BASE_URL: string = AppConfig.ELASTIC_API_BASE_URL;

  //Itinerary page headers
  getHeaders(heading){
    const headers = {
      voyage_itinerary: '/api/v1/voyages/header/b?_format=json',
      suites_fares: '/api/v1/voyages/header/c?_format=json',
      dining: '/api/v1/voyages/header/d?_format=json',
      exp: '/api/v1/voyages/header/e?_format=json'
    }
    const url = this.API_BASE_URL + headers[heading];
    return this.http.get(url);
  }

  Voyages_itineraries(voyageId) {

    const url = this.ELASTIC_API_BASE_URL + '/api/voyage/itineraries';
    const params = new HttpParams().set('voyageId', voyageId);
    return this.http.get(url, { params });
  }

  Voyages_suitesFare(voyageId, CountryCode, yachtName) {
    const url = this.API_BASE_URL + '/api/v1/voyages/suiteslists/'+yachtName+'?_format=json';
    const params = new HttpParams()
      .set('voyageId', voyageId)
      .set('countryCode', CountryCode);
    return this.http.get(url, { params });
  }

  tooglePcfiCall(){
    const url = this.API_BASE_URL + '/api/v1/togglepcfi?_format=json'
    return this.http.get(url);
  }

  Voyages_banner_img(voyageId) {

    // const url = this.API_BASE_URL + "/api/v1/voyages/"+voyageId+"/content/b?_format=json";
    const url = this.API_BASE_URL + '/api/v1/banners/voyages/' + voyageId + '/b?_format=json';
    return this.http.get(url);
  }


  Voyages_tabs(voyageId, tabname, yachtName = "invalid-yacht") {
    if (tabname !== 'yacht' && tabname !== 'suiteslisting') {
      const url = this.API_BASE_URL + '/api/v1/voyages/' + voyageId + '/tab/' + tabname + '?_format=json';
      return this.http.get(url);
    } else if (tabname === 'yacht') {
      const url = `${this.API_BASE_URL}/api/v1/voyages/tab/yacht/${yachtName}?_format=json`;
      return this.http.get(url);
    } else if (tabname === 'suiteslisting') {
      const url = this.API_BASE_URL + '/api/v1/voyages/tab/suiteslisting?_format=json';
      return this.http.get(url);
    }


  }

  Voyages_tabContent(voyageId, tabname, yachtName = "invalid-yacht") {
    if (tabname !== 'yacht' && tabname !== 'suiteslisting' && tabname !== 'offersprograms') {
      const url = this.API_BASE_URL + '/api/v1/voyages/' + voyageId + '/listing/' + tabname + '?_format=json';
      return this.http.get(url);
    } else if (tabname === 'offersprograms') {
      const url = this.API_BASE_URL + '/api/v1/voyages/' + voyageId + '/tab/offergroup?_format=json';
      return this.http.get(url);
    } else if (tabname === 'yacht') {
      const url = `${this.API_BASE_URL}/api/v1/voyages/listing/yacht/${yachtName}?_format=json`;
      return this.http.get(url);
    } else if (tabname === 'suiteslisting') {
      const url = `${this.API_BASE_URL}/api/v1/voyages/suiteslists/${yachtName}?_format=json`;
      return this.http.get(url);
    }

  }

  excursionPortdate(voyageId) {

    const url = this.ELASTIC_API_BASE_URL + '/api/voyage/excursions-port-dates';
    const params = new HttpParams().set('voyageCode', voyageId);
    return this.http.get(url, { params });
  }


  Itinerary_Portdate(portcode) {

    const url = this.API_BASE_URL + '/api/v1/voyages/ports/' + portcode + '?_format=json';
    return this.http.get(url);
  }
  Drupal_API(suiteapi_url) {

    const url = this.API_BASE_URL + suiteapi_url;
    return this.http.get(url);
  }

  DrupalSuitesSlider_API(yachtName, suiteCode){
    const suiteapi_url = '/api/v1/yacht/voyages/suitesslider/'+ yachtName + '/' + suiteCode + '/' + '?_format=json';
    const url = this.API_BASE_URL + suiteapi_url;
    return this.http.get(url);
  }

  DrupalSuitesDetails_API(yachtName, suiteCode){
    const suiteapi_url = '/api/v1/yacht/voyages/suitesdetails/'+yachtName + '/' + suiteCode + '/' + '?_format=json';
    const url = this.API_BASE_URL + suiteapi_url;
    return this.http.get(url);
  }

  excursionMoreData(excursion) {

    const url = this.ELASTIC_API_BASE_URL + '/api/voyage/excursions';
    let body: any;
    if (excursion) {

      body = excursion;
    } else {
      body = {
        excursionCriteriaDTO: ''
      };
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, body, { headers: headers });
  }

  Voyages_basic(voyageId, CountryCode) {
    const url = this.ELASTIC_API_BASE_URL + '/api/voyage';
    let params;        
    params={
      'voyageId': voyageId,
      'countryCode': CountryCode
    }    
    return this.http.get(url, {params});
  }

  /**
   * Function to get Land and Hotel Details from Elastic API
  */
  landAndHotelDetailsFromElastics(voyageCode) {
    const url = this.ELASTIC_API_BASE_URL + '/api/voyage/landAndHotel?voyageCode=' + voyageCode;
    return this.http.get(url);
  }
 
  invalidVoyageDetails(voyageCode){
    const url = this.ELASTIC_API_BASE_URL + "/api/voyageStatus";
    let body: any = {};
    if (voyageCode) {
      body = { voyageCode: voyageCode };
    }
    return this.http.post(url, body);
  }

  fullImgTileData(voyageCode: any) {
    const url = this.API_BASE_URL + '/api/v1/exquisites-shore-experiences/voyages/' + voyageCode + '?_format=json';
    return this.http.get(url);
  }

  Voyages_suitesFarePrice(voyageId, CountryCode) {
    const url = this.ELASTIC_API_BASE_URL + '/api/voyage/pricing?_format=json';
    const params = new HttpParams()
      .set('voyageId', voyageId)
      .set('countryCode', CountryCode);
    return this.http.get(url, { params });
  }

  marqueeExperience(voyageId): Observable<any> {
    const url = this.API_BASE_URL + '/api/v1/50-50-voyages/marquee_experience/' + voyageId + '?_format=json';
    return this.http.get(url);
  }

  landHotel(voyageId): Observable<any> {
    const url = this.API_BASE_URL + '/api/v1/50-50-voyages/land_and_hotel/' + voyageId + '?_format=json';
    return this.http.get(url);
  }
}

