<swal #reservationSwal (beforeOpen)="beforeOpen($event)" [showConfirmButton]="false" (close)="onClose($event)"
  tabindex="0" (keyup.enter)="onClose($event)" [showCancelButton]="false" 
  [allowOutsideClick]="true" [animation]="false" [reverseButtons]="true">
  <ng-container *swalPartial>

      <div  id="rcyc-portal-closed-popup" class="portal-closed-modal">

          <section class="section-1">
            <div class="l-container-wrapper">
              <div class="container-nnr">
                <h2 class="swalHeading spa-modal-heading" [innerHTML]="modalData?.heading"></h2>
                <div class="itinerary-details-outer-div">
                 <p [innerHTML]="modalData?.messageToShow" class="messageToShow"></p>
                </div>
                <button  tabindex= "0" class="button reserveBtn" (click)="swalButtonClick()" (keyup.enter)="swalButtonClick()">{{modalData?.buttonText}}</button>
              </div>
            </div>
          </section>

        </div>




  </ng-container>
</swal>