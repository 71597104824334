import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-rcyc-onboard-dining-modal',
  templateUrl: './rcyc-onboard-dining-modal.component.html',
})

export class RcycOnboardDiningModalComponent implements OnInit {
  @ViewChild('diningSwal', { static: true }) diningSwal: SwalComponent;
  @Input() isMobile: boolean;

  onboardDiningData: any = [];
  opened: number[] = [];
  page: number;
  nrOfPages: number;
  currentSlide: number;

  constructor(
    private rcycInternalApisService: RcycInternalApisService
  ) { }

  ngOnInit(): void {
    this.calcPagination();
  }

  // method to call while opening the modal
  onOpen(event) {
  }

  onClose(event) {
    // this.animationStatus = false;
  }

  // method to open modal
  modalOpen(diningMetaData: any) {
    this.onboardDiningData = []
    this.getDiningRestData(diningMetaData);
    this.diningSwal.show();
  }

  // method to call just before opening the modal
  beforeOpen(event) {
    event.modalElement.parentElement.setAttribute('id', 'onboardDiningSwalId');
  }

  openTab(id: number) {
    if (this.opened.includes(id)) {
      this.opened = this.opened.filter(tabId => tabId !== id);
    } else {
      this.opened.push(id);
    }
  }

  getData(item) {
    return {
      id: item.id,
      destination: item.field_tile_title,
      image: {
        src: item.field_tile_image
      },
      eyebrow: item.field_tile_eyebrow,
      title: item.field_tile_title,
      text: item.field_tile_details,
      cta: {
        title: item.field_tile_cta_title,
        url: item.field_tile_cta_url,
      }
    }
  }

  calcPagination() {
    const slidesToShow = 5;
    this.page = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1)
    this.nrOfPages = Math.ceil(this.onboardDiningData[0]?.imageSliderData.length / slidesToShow);
  }

  /**
   * Drupal Api for Onboard Dining Data
   * accordiondetails/evrima/S.E.A?_format=json
   */
  getDiningRestData(diningMetaData: any) {
    const sliderApiUrl = '/api/v1/restaurants/slider/' + diningMetaData?.yacht.toLowerCase() + '/' + diningMetaData?.dining.toLowerCase() + '?_format=json';
    const accordionApiUrl = '/api/v1/restaurants/accordiondetails/' + diningMetaData?.yacht.toLowerCase() + '/' + diningMetaData?.dining.toLowerCase() + '?_format=json';
    const detailsApiUrl = '/api/v1/restaurants/details/' + diningMetaData?.yacht.toLowerCase() + '/' + diningMetaData?.dining.toLowerCase() + '?_format=json';
    forkJoin([this.rcycInternalApisService.getDrupal(sliderApiUrl), this.rcycInternalApisService.getDrupal(accordionApiUrl), this.rcycInternalApisService.getDrupal(detailsApiUrl)]).subscribe(
      (res: any = []) => {
        if (res && res.length > 0) {
          let tempDiningData: any = { imageSliderData: null, accordionData: null, headerTableData: null, metaData: null };
          tempDiningData.imageSliderData = res[0] && res[0]?.length
            ? res[0]
              .filter(item => item?.field_image || item?.field_video) // Filter out items without field_image and field_video
              .map(item => ({
                field_image: item?.field_image || null, // Set to null if empty
                field_video: item?.field_video || null  // Set to null if empty
              }))
            : [];
          tempDiningData.accordionData = res[1] && res[1]?.length && res[1].map((rest, i) => {
            return {
              body: rest?.body,
              field_tile_title: rest?.field_accordion_title,
              field_sort_order: +rest?.field_sort_order,
              cta_title: rest?.cta_title,
              cta_link: rest?.cta_link,
            }
          });

          tempDiningData.headerTableData = res[2] && res[2]?.length && res[2];
          tempDiningData.metaData = { dineName: diningMetaData?.dining, menuName: diningMetaData?.cta_title, menuLink: diningMetaData?.cta_link };
          this.onboardDiningData.push(tempDiningData);
          this.calcPagination();
        }
      },
      err => {
        console.log(err, 'err website form heading');
      }
    );
  }

}
