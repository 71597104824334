import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'specialCountrynameFilter'
})
export class RcycSpecialCountrynameFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(item => { 
      let countryName= item.name.toLowerCase();
      if(countryName.indexOf(searchText) !== -1) {
        return true;
      }
    });        
  }

}
