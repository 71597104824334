<div class="lg:flex flex-col m-auto p-auto" *ngIf="data">
  <div class="flex flex-col [5/4] w-auto mb-8 lg:mb-0 px-0 lg:px-12">
      <ng-container *ngIf="variant !== 'amenities'">
        <img alt="" *ngIf="data?.field_image" class="" [src]="data?.field_image">
      </ng-container>
      <ng-container *ngIf="variant === 'amenities'">
        <app-rcyc-ngu-carousel [portData]='data.imageVideoArray'></app-rcyc-ngu-carousel>
      </ng-container>
  </div>

  <div class="lg:text-center lg:items-center lg:pt-12 lg:flex lg:flex-grow lg:flex-col px-0 lg:px-12 px-0 lg:px-12">
      <div *ngIf="data?.field_sub_header"
      class="uppercase mb-4 lg:mb-6 text-[14px] font-normal tracking-[0.168px] leading-[165%] font-['Proxima Nova']"
      [innerHTML]="data?.field_sub_header">
      </div>
    <h2 class="text-[23px] lg:text-[31px] font-normal tracking-[0.276px] lg:tracking-[0.372px] leading-[115%] font-['Adobe Caslon Pro']"
      [ngClass]="{'lg:min-h-[130px] lg:h-[130px] lg:overflow-hidden lg:mb-1': variant === 'offers-programs-landing', 'mb-8 lg:mb-12': variant !== 'offers-programs-landing'}"
      [innerHTML]="data?.title">
    </h2>
    <div class="text-content flex flex-col xl:flex-row gap-3 xl:gap-11 text-[16px] font-normal tracking-[0.192px] leading-[165%] font-['Proxima Nova']"
      [innerHTML]="data?.field_summary"
      [ngClass]="{'lg:min-h-[80px]': isOnboardLanding}">
    </div>
    <div *ngIf="data?.field_com_cta_title" class="pt-[32px] lg:pt-[48px]">
      <a (click)="modalOpen(data)" class="link-cta button-secondary uppercase" [innerHTML]="data?.field_com_cta_title">
      </a>
    </div>
  </div>
</div>
<ng-container *ngIf="!index">
  <div class="border-solid border-b text-[#1c1c1c] mt-8 lg:hidden"></div>
</ng-container>
<app-rcyc-onboard-dining-modal #onboardDiningModal></app-rcyc-onboard-dining-modal>