import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class RcycToasterMessagesService {
  // messageType: any='tt';
  private messageType = new Subject<any>();
  constructor() { }

  public getToastMessage(): Observable<any[]> {
    // return this.messageType;
    return this.messageType.asObservable();
    
  }
  public setToastMessage(data:typeInterFace) {
    this.messageType.next(data);
  }
}

interface typeInterFace  {messageToShow:String,messageType:String,TimeIntreval?:Number};



