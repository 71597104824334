import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable()
export class RcycRequestCallService {

  API_BASE_URL: any =   AppConfig.API_BASE_URL;
  ELASTIC_API_BASE_URL_EBROCHURE: any = AppConfig.ELASTIC_API_BASE_URL_EBROCHURE;
  API_BASE_URL_RESERVATION: any = AppConfig. API_BASE_URL_RESERVATION;

  constructor(

        private http: HttpClient
  ) {

  }

  /* ======================================================================================================================================
    Funtion to call All Apis for Request A Call Drupal
    * ====================================================================================================================================*/

    requestACallDrupal(apiUrl: string) {

        const url = this.API_BASE_URL + apiUrl;
        return this.http.get(url);
    }

    /* =====================================================================================================================================
    Funtion to Submit All Apis for Request A Call
    * ====================================================================================================================================*/
    requestCallFormData(apiUrl, params, authToken = '') {

      const url = this.ELASTIC_API_BASE_URL_EBROCHURE + apiUrl;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': '*/*',
          'Authorization': `Bearer ${authToken}`
        })
      };
      return this.http.post(url, params, httpOptions);
    }

    /**
     * 
     * @param apiUrl (api path)
     * @param params (form parameters)
     * @param authToken 
     * @returns the successfull submission of california-no information API call
     */
    noInformationData(apiUrl, params, authToken = '') {
      const url = this.API_BASE_URL_RESERVATION + apiUrl;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': '*/*',
          'Authorization': `Bearer ${authToken}`
        })
      };
      return this.http.post(url, params, httpOptions);
    }

}
