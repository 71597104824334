import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { RcycCommonService } from './../../rcyc-services/rcyc-common/rcyc-common.service';
import { Router } from '@angular/router';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
// import { RcycDashboardService } from './rcyc-dashboard.service';
import { RcycCommonLogoutService } from './../../rcyc-services/rcyc-logout/rcyc-logout.service';
import { RcycInternalApisService } from './../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';

@Component({
  selector: 'app-rcyc-precontract-modal',
  templateUrl: './rcyc-precontract-modal.component.html',
  styleUrls: ['./rcyc-precontract-modal.component.css']
})

export class RcycPrecontractModalComponent implements OnInit {

  componentName: any;

  apiUrlGError: any;
  apiResponseGErrorTemp: any;
  apiResponseGError: any;
  apiResponseGErrorStatus = false;

  apiUrlC: any;
  apiResponseCTemp: any;
  apiResponseC: any;
  apiResponseCStatus = false;

  apiUrlD: any;
  apiResponseDTemp: any;
  apiResponseD: any;
  apiResponseDStatus = false;

  apiUrlE: any;
  apiResponseETemp: any;
  apiResponseE: any;
  apiResponseEStatus = false;
  animationStatus = true;
  @ViewChild('guestPreContractSwal', { static: true }) guestPreContractSwal: SwalComponent;

  constructor(
    private rcycCommonService: RcycCommonService,
    // private rcycDashboardService: RcycDashboardService,
    private router: Router,
    private rcycCommonLogoutService: RcycCommonLogoutService,
    private rcycInternalApisService: RcycInternalApisService,
    private _SharedServiceService: SharedServiceService
  ) {

    this.constructorFn();
  }

  ngOnInit() {

    this.onInitFn();
  }

   // Function for Constructor
   constructorFn() {


  }

  // Function for OnInit
  onInitFn() {

    this.componentName = 'RcycPreContractComponent';

     /****************** Section Global error messages ********************/
     this.getErrorCommonMsgs();

    //  this.apiUrlGError = '/api/v1/errormessages?_format=json';

    //  this.rcycInternalApisService.getDrupal(this.apiUrlGError).subscribe(
    //    response => {

    //      this.apiResponseGErrorTemp = response;
    //      if (this.apiResponseGErrorTemp && this.apiResponseGErrorTemp.length) {
    //        const res = Object.keys(response).map(function (key) {
    //          return response[key];
    //        });

    //        if (res.length) {

    //          this.apiResponseGError = res[0];
    //          this.apiResponseGErrorStatus = true;
    //        }
    //      }
    //    },
    //    err => {
    //      console.log('err  apiUrlGError');
    //      console.log(err);
    //    }
    //  );

    /****************** Section C ********************/

    this.apiUrlC = '/api/v1/euprecontract/content/c?_format=json';

    this.rcycInternalApisService.getDrupal(this.apiUrlC).subscribe(
      response => {
        const apiResponseCTemp: any = response;
        if (apiResponseCTemp && apiResponseCTemp.length) {
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });

          if (res.length) {

            this.apiResponseC = res[0];
            this.apiResponseCStatus = true;
          }
        }
      },
      err => {
        console.log('err  apiUrlC Err');
        console.log(err);
      }
    );

    /****************** Section D ********************/

    this.apiUrlD = '/api/v1/euprecontract/terms?_format=json';

    this.rcycInternalApisService.getDrupal(this.apiUrlD).subscribe(
      response => {
        const apiResponseDTemp: any = response;
        if (apiResponseDTemp && apiResponseDTemp.length) {
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });

          if (res.length) {

            this.apiResponseD = res[0];
            this.apiResponseDStatus = true;
          }
        }
      },
      err => {
        console.log('err  apiUrlD Err');
        console.log(err);
      }
    );

  }
  getErrorCommonMsgs() {
    this._SharedServiceService.getErrorCommonMsg().subscribe(res=>{
      this.apiResponseGError=res;      
      if(this.apiResponseGError){
        this.apiResponseGErrorStatus=true
      }
    });
  }


  // Acknowledge Button Click
  acknowledgeClick () {

    this.rcycCommonService.setAcknowledgePreContract(true);
    /// this.router.navigate(['/guest/dashboard']);
    this.guestPreContractSwal.nativeSwal.close();
  }
  modalOpen() {
    this.guestPreContractSwal.show();
  }
  onClose(event) {
    this.animationStatus = false;
  }
  beforeOpen(event) {
    // To add ad id to parent html tag
    event.modalElement.parentElement.setAttribute('id', 'guestPreContractSwalId');
  }
}
