import {
  Component, Input, OnInit, HostListener, Output, EventEmitter,
  Inject, OnDestroy, PLATFORM_ID, ViewChild, AfterViewInit
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycGoogleAnalyticsEventsService } from '../../rcyc-services/rcyc-google-analytics-events/rcyc-google-analytics-events.service';
// import { ScrollToService } from 'ng2-scroll-to-el';
// import { CookieService } from "ngx-cookie-service";
// import { CookiesService } from '@ngx-utils/cookies';
import {CookieService} from 'ngx-cookie-service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { RcycLoginComponent } from '../rcyc-guest/rcyc-login/rcyc-login.component';
import { RcycCommonLogoutService } from '../../rcyc-services/rcyc-logout/rcyc-logout.service';

declare const ga: Function;

@Component({
  selector: 'app-header',
  templateUrl: './rcyc-header.component.html',
  styleUrls: ['./rcyc-header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  subHeaderStatus: any;
  mainHeaderStatus: any;
  subHeaderDataArray_A: any;
  currentComponent: any;
  currentUrlPathDataArray: any;
  gaApiUrl: any;
  initalmobilebanner: string;
  initialHeaderMenu = 'menu-wrapper brand-nav-wrapper';
  initialMobile = false;
  menuObj: object;
  loggedMenuObj: object;
  gdpr_cookie: any;
  cookieSubscription: any;
  UserDetails: any = {};
  continent_code: any;
  country_no: any = '';
  currentUserName: any = '';
  userDetail: any;
  userImgSubMenus: any;
  loggedGuestStatus: any;
  isOpen = false;
  isMobOpen = false;
  userType: any;
  @ViewChild('guestLoginModal', { static: true }) guestLoginModal: RcycLoginComponent;
  requestCallTemp: any;
  skipNavPath: any;
  @Output() toggleMenu: EventEmitter<any> = new EventEmitter<any>();
  @Input() countryNo: any;
  stickyHeader = false;
  @HostListener('window:keyup.Tab', ['$event']) onKeyUp(e: any) {
    if (e && e.srcElement.innerText === 'REQUEST A CALL' && e.srcElement.id === 'hd_cruise') {
      document.getElementById('hd_cruise').style.color = 'black';
      document.getElementById('hd_cruise').style.background = 'white';
    } else {
      document.getElementById('hd_cruise').style.color = 'white';
      document.getElementById('hd_cruise').style.background = 'black';
    }
  }
  @HostListener('window:keydown.Shift.Tab', ['$event']) onKeyDown(e: any) {
    if (e && e.srcElement.innerText === 'SKIP NAVIGATION') {
      document.getElementById('hd_cruise').style.color = 'black';
      document.getElementById('hd_cruise').style.background = 'white';
    } else {
      document.getElementById('hd_cruise').style.color = 'white';
      document.getElementById('hd_cruise').style.background = 'black';
    }
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > 50) {
      this.initialHeaderMenu = 'menu-wrapper brand-nav-wrapper is-stuck';
      this.initalmobilebanner = 'is-stuck';
      this.stickyHeader = true;
    } else {
      this.initialHeaderMenu = 'menu-wrapper brand-nav-wrapper';
      this.initalmobilebanner = '';
      this.stickyHeader = false;
    }
  }


  constructor(

    private router: Router,
    private rcycCommonService: RcycCommonService,
    public googleAnalyticsEventsService: RcycGoogleAnalyticsEventsService,
    private rcycHomeService: RcycHomeService,
    // private scrollService: ScrollToService,
    // private cookieService: CookieService,
    // private elementRef: ElementRef,
    private cookies: CookieService,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private rcycCommonLogoutService: RcycCommonLogoutService,
  ) {


    this.headerConstructorFn();

    this.router.events.subscribe((val: any) => {
      this.userImgSubMenusFn();
      if (localStorage.getItem('loggedUserDetails')) {
        this.mobSubmenuHide();
      }
    });

  }

   /* =========================================================================
      Function to call all menus and submenus
    ============================================================================ */
  headerConstructorFn() {

    this.rcycCommonService.subHeaderStatus.subscribe(status => this.subHeaderStatus = status);
    this.rcycCommonService.mainHeaderStatus.subscribe(status => this.mainHeaderStatus = status);
    this.rcycCommonService.currentComponent.subscribe(componentName => this.currentComponent = componentName);
    this.rcycCommonService.currentUserName.subscribe(currentUserName => {
      this.currentUserName = currentUserName;
      if (currentUserName !== 'currentUserName') {
        this.loggedGuestStatus = true;
      }
    });

    // List all menus and sub menus
    this.menuObjects();

    this.userNameSet();
    // this.userImgSubMenusFn();


  }
  // Mobile menu Toggle function
  toggleMobileMenu() {
    this.initialMobile = !this.initialMobile;
    this.toggleMenu.emit(this.initialMobile);
  }

  // Mobile menu hide function
  hideMenu() {
    this.initialMobile = !this.initialMobile;
    this.toggleMenu.emit(this.initialMobile);
  }

  // Route go to
  routeGoTo(menu) {
    this.hideMenu();
    if (menu === 'find-a-cruise' || menu === 'request-call') {
      this.router.navigate([menu]);
    } else {
      this.router.navigate([menu.url]);
    }
  }

  ngOnInit() {

    // Changing subheader A value
    this.rcycCommonService.subHeaderDataArray_A
      .subscribe(
        params => {
          this.subHeaderDataArray_A = params;
        }
      );
  }

  ngAfterViewInit() {

    setTimeout(() => {
      // this.guestLoginModal.modalOpen();
    }, 0);


  }

  showSubmenu() {
    this.isOpen = true;
  }

  hideSubMenu() {
    this.isOpen = false;
  }
  mobSubmenuShow() {
    this.isMobOpen = !this.isMobOpen;
  }

  // Scroll to a particular Area on click
  commonPointScrollDown() {

    let totalHeight = 0;
    const switchKey = this.currentComponent;
    switch (switchKey) {

      case 'RcycRegionComponent':
        /*totalHeight = (document.getElementById('rcyc-page-header') as HTMLElement).offsetHeight +
                        (document.querySelector('nav.menu-wrapper.brand-nav-wrapper') as HTMLElement).offsetHeight +
                        (document.querySelector('.bannerImageOuter') as HTMLElement).offsetHeight +
                        (document.querySelector('.section-2-1') as HTMLElement).offsetHeight +
                        (document.querySelector('.section-2-2') as HTMLElement).offsetHeight;*/
        // if (document.getElementById('rcyc-page-header')) {
        //   totalHeight += (document.getElementById('rcyc-page-header') as HTMLElement).offsetHeight;
        // }
        // if (document.querySelector('nav.menu-wrapper.brand-nav-wrapper')) {
        //   totalHeight += (document.querySelector('nav.menu-wrapper.brand-nav-wrapper') as HTMLElement).offsetHeight;
        // }
        // if (document.querySelector('#rcyc-page-region .bannerImageOuter')) {
        //   totalHeight += (document.querySelector('#rcyc-page-region .bannerImageOuter') as HTMLElement).offsetHeight;
        // }
        // if (document.querySelector('#rcyc-page-region .section-2 .section-2-1')) {
        //   totalHeight += (document.querySelector('#rcyc-page-region .section-2 .section-2-1') as HTMLElement).offsetHeight;
        // }
        // if (document.querySelector('#rcyc-page-region .section-2 .section-2-2')) {
        //   totalHeight += (document.querySelector('#rcyc-page-region .section-2 .section-2-2') as HTMLElement).offsetHeight;
        // }
        document.querySelector('#rcyc-page-channels .section-3').scrollIntoView();

        /*this.scrollService.scrollTo(document.getElementById("allPortsListScroll"));
        this.scrollService.scrollTo(document.querySelector(".section-2-3"));*/


        //this.scrollToAPositionFn(totalHeight);

        break;
      case 'RcycChannelsComponent':
        /*totalHeight = (document.getElementById('rcyc-page-header') as HTMLElement).offsetHeight +
                (document.querySelector('nav.menu-wrapper.brand-nav-wrapper') as HTMLElement).offsetHeight +
                (document.querySelector('.section-1') as HTMLElement).offsetHeight +
                (document.querySelector('.section-2') as HTMLElement).offsetHeight - 120;*/
        // if (document.getElementById('rcyc-page-header')) {
        //   totalHeight += (document.getElementById('rcyc-page-header') as HTMLElement).offsetHeight;
        // }
        // if (document.querySelector('nav.menu-wrapper.brand-nav-wrapper')) {
        //   totalHeight += (document.querySelector('nav.menu-wrapper.brand-nav-wrapper') as HTMLElement).offsetHeight;
        // }
        // if (document.querySelector('#rcyc-page-channels .section-1')) {
        //   totalHeight += (document.querySelector('#rcyc-page-channels .section-1') as HTMLElement).offsetHeight;
        // }
        // if (document.querySelector('#rcyc-page-channels .section-2')) {
        //   totalHeight += (document.querySelector('#rcyc-page-channels .section-2') as HTMLElement).offsetHeight;
        // }
        /* this.scrollService.scrollTo(document.getElementById("allPortsList"));*/
        // this.scrollToAPositionFn(totalHeight - 120);

        document.querySelector('#rcyc-page-region #portSubHeaderId').scrollIntoView();

        break;
      default:
        // console.log(switchKey);
        break;
    }

  }

  // Function to scroll to A position
  scrollToAPositionFn(totalHeight) {

    if (isPlatformBrowser(this.platformId)) {

      window.scrollTo(0, totalHeight);
    }
  }

  /* =====================================================================================
      Function to call all menus and submenus
    =================================================================================== */
  menuObjects() {

    this.menuObj = [
      {
        name: 'Yacht Experience',
        url: '/experience',
        id: 'experience',
        subMenus: [
          {
            name: 'Service',
            url: '/experience/service',
            id: 'service',
          },
          {
            name: 'Inclusions',
            url: '/experience/inclusions',
            id: 'inclusions',
          },
          {
            name: 'Dining',
            url: '/experience/dining',
            id: 'dining',
          },
          {
            name: 'Suites',
            url: '/experience/suites',
            id: 'suites',
          },
          {
            name: 'Onboard spaces',
            url: '/experience/interior',
            id: 'onboardSpaces',
          },
          {
            name: 'Ashore',
            url: '/experience/shore-excursions',
            id: 'ashore',
          },
          {
            name: 'Airfare',
            url: '/experience/cruise-packages',
            id: 'airfare',
          },
          {
            name: 'Deck Plans',
            url: '/experience/deck-plans',
            id: 'deckplans',
          },
          {
            name: 'Milestones',
            url: '/experience/building-milestones',
            id: 'milestones',
          },
        ]
      },
      {
        name: 'Destinations',
        url: '/cruise-ports',
        id: 'destination',
      },
      {
        name: 'Find a Cruise',
        url: '/find-a-cruise',
        id: 'voyages',
      },
      {
        name: 'Offers & programs',
        url: '/specialoffers',
        id: 'specialoffers',
      },
      {
        name: 'Request E-Brochure',
        url: '/request-brochure',
        id: 'requestEBrochure',
      }
    ];
  }

  // Open login modal
  loginOpenClick() {


    this.guestLoginModal.modalOpen();
  }

  /* =======================================================================
      Function to set the username in header
    ==================================================================== */
  userNameSet() {

    if (localStorage.getItem('loggedUserDetails')) {

      const loggedUserDetails = JSON.parse(localStorage.getItem('loggedUserDetails') );
      const userType = loggedUserDetails.type.toUpperCase();
      let nameOfCurrentUser;
      switch (userType) {
        case 'TA':
              nameOfCurrentUser = loggedUserDetails.TAName;
              // change changeCurrentUserName
              this.rcycCommonService.changeCurrentUser(nameOfCurrentUser);
              this.loggedGuestStatus = true;
        break;
        default:
            nameOfCurrentUser = loggedUserDetails.guestName;
            // change changeCurrentUserName
            this.rcycCommonService.changeCurrentUser(nameOfCurrentUser);
            this.loggedGuestStatus = true;
        break;
      }

      // const nameOfCurrentUser = loggedUserDetails.guestName;
      // const stringifiedLoginData = JSON.stringify(loginDatas);

      // localStorage.setItem('loggedUserDetails', stringifiedLoginData);
      this.rcycCommonService.setLoggedInStatus(true);

    }
  }

  /* =======================================================================
      Function to call all sub menus when user Img hover
    ==================================================================== */
  userImgSubMenusFn() {

    if (localStorage.getItem('loggedUserDetails')) {
      const userDetail = JSON.parse(localStorage.getItem('loggedUserDetails'));
      const userType = userDetail.type;
      this.userType = userType;

      switch (userType) {

        case 'TA' :
            this.userImgSubMenus = [
              {
                name: 'Dashboard',
                url: '/travel-agent/dashboard',
                id: 'taDashboard',
              },
              {
                name: 'Reservations',
                url: '/travel-agent/booking-summary',
                id: 'taBookings',
              },
              {
                name: 'Inventory',
                url: '/travel-agent/inventory',
                id: 'taInventory',
              },
              {
                name: 'Resources',
                url: '/travel-agent/resources',
                id: 'taResources',
              },
              {
                name: 'My Profile',
                url: '/travel-agent/my-profile',
                id: 'taProfile',
              },
              {
                name: 'Change Password',
                url: '/travel-agent/password-update',
                id: 'taChangePassword',
              },
              {
                name: 'Logout',
                url: '/signout',
                id: 'taLogout',
              }
            ];
        break;
        case 'RA' :
            this.userImgSubMenus = [
              {
                name: 'Search Guest',
                url: 'reservation-agent/guest-search',
                id: 'searchGuest',
              },
              {
                name: 'Logout',
                url: '/signout',
                id: 'taLogout',
              }
            ];
        break;

        default:
            this.userImgSubMenus = [
              {
                name: 'Dashboard',
                url: '/guest/dashboard',
                id: 'gCollections',
              },
              {
                name: 'My Reservations',
                url: '/guest/my-reservations',
                id: 'gReservations',
              },
              {
                name: 'My Profile',
                url: '/guest/my-profile',
                id: 'gProfile',
              },
              {
                name: 'Change Password',
                url: '/guest/password-update',
                id: 'gChangePassword',
              },
              {
                name: 'Logout',
                url: '/signout',
                id: 'glogout',
              }
            ];
        break;
      }

    }

  }
  /* =======================================================================
        Click on User Icon in header
      ==================================================================== */
  userIconClick() {
    switch (this.userType) {
      case 'TA' :
          this.router.navigate(['/travel-agent/dashboard']);
      break;
      default:
      this.router.navigate(['/guest/dashboard']);
      break;
    }
  }
  /* =======================================================================
        Function call to hide user subMenu on page load
      ==================================================================== */

  mobSubmenuHide() {
    this.isMobOpen = false;
  }
  /* =======================================================================
        Function call on user menu outer div click to hide user subMenu
      ==================================================================== */
  mobileUserSubMenuOuterClick() {
    this.isMobOpen = false;
  }

  // Clear Voyage Detail from Local Storage
  clearVoyageDetail(params) {


    if (document.getElementById('rcycGtag')) {
      // Remove if rcycGtag already exists
      const element = document.getElementById('rcycGtag');
      element.parentNode.removeChild(element);
    }

    if (document.getElementById('rcycGtagAsync')) {
      // Remove if rcycGtagAsync already exists
      const element = document.getElementById('rcycGtagAsync');
      element.parentNode.removeChild(element);
    }

    if (document.getElementById('rcycGtmId')) {
      // Remove if rcycGtmId already exists
      const element = document.getElementById('rcycGtmId');
      element.parentNode.removeChild(element);
    }

    if (document.getElementById('rcycGtmIda')) {
      // Remove if rcycGtmIda already exists
      const element = document.getElementById('rcycGtmIda');
      element.parentNode.removeChild(element);
    }

    this.rcycCommonService.removeSessionData('specialOfferPageDetails');
    this.rcycCommonService.removeSessionData('portPageDetails');
    this.rcycCommonService.removeSessionData('regionPageDetails');

    this.rcycCommonService.removeSessionData('userPreferenceData');
    this.rcycCommonService.removeSessionData('findACruisePaginationArray');
    this.rcycCommonService.removeSessionData('backToResultVoyageListPrevUrl');
    this.rcycCommonService.removeSessionData('findACruiseSearchCriteria');
    this.rcycCommonService.removeData('pagesArray');

  }
  requestCall(tag) {
    if (tag === 'enter') {
       document.getElementById('hd_cruise').style.color = 'black';
       document.getElementById('hd_cruise').style.background = 'white';
    } else if (tag === 'leave') {
       document.getElementById('hd_cruise').style.color = 'white';
       document.getElementById('hd_cruise').style.background = 'black';
    } else {
       this.router.navigate(['/request-call']);
       document.getElementById('hd_cruise').style.color = 'white';
       document.getElementById('hd_cruise').style.background = 'black';
    }
  }
  skipNavigationClick() {
    this.skipNavPath = [];
    const findIdInUrl = this.router.url.split('#');
    if (findIdInUrl.length > 1) {
      this.skipNavPath = findIdInUrl[0] + '#forSkipNav';
    } else {
      this.skipNavPath = this.router.url + '#forSkipNav';
    }
  }
}
