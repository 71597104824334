import { Action } from '@ngrx/store';
import * as userModel from '../models/user.model'

export enum ReservationActionTypes {
    LIST_BOOKING = "[reservation] List Bookings",
    LIST_BOOKING_FAILED_STATUS = "[reservation] List Bookings Failed Status",
    LIST_BOOKING_FAILED_ERRORMSG = "[reservation] List Bookings Failed Error Msg",
    LIST_BOOKING_SUCCESS = "[reservation] List Bookings Success",
    LIST_BOOKING_SUCCESS_STATUS = "[reservation] List Bookings Success Status",
    MY_RESERVATION_BOOKINGID = "[reservation] Get current Booking Id",
    MY_RESERVATIONS_BOOKING_DETAILS = "[reservation] reservation details by  booking id",
    MY_RESERVATIONS_BOOKING_DETAILS_FAILED = "[reservation] reservation details by  booking id failed",
    MY_RESERVATIONS_BOOKING_DETAILS_FAILED_ERRORMSG = "[reservation] reservation details by  booking id failed Error Msg",
    MY_RESERVATIONS_BOOKING_DETAILS_SUCCESS = "[reservation] reservation details by  booking id success",
    MY_RESERVATION_SHORE_EX_BOOKING="[reservation] get shore excursion booking by port",
    MY_RESERVATION_SHORE_EX_BOOKING_SUCCESS="[reservation] get shore excursion booking by port success",
    MY_RESERVATION_SHORE_EX_BOOKING_FAILED="[reservation] get shore excursion booking by port failed",
    MY_RESERVATION_MARQUEE_BOOKING="[reservation] get marquee booking by port",
    MY_RESERVATION_MARQUEE_BOOKING_SUCCESS="[reservation] get marquee booking by port success",
    MY_RESERVATION_MARQUEE_BOOKING_FAILED="[reservation] get marquee booking by port failed",
    MY_RESERVATION_DINING_BOOKING="[reservation] get dining booking by port",
    MY_RESERVATION_DINING_BOOKING_SUCCESS="[reservation] get dining booking by port success",
    MY_RESERVATION_DINING_BOOKING_FAILED="[reservation] get dining booking by port failed",
    MY_RESERVATION_SPA_BOOKING="[reservation] get spa booking by port",
    MY_RESERVATION_SPA_BOOKING_SUCCESS="[reservation] get spa booking by port success",
    MY_RESERVATION_SPA_BOOKING_FAILED="[reservation] get spa booking by port failed",
    MY_RESERVATION_COMMON_ERR_MSG="[reservation] dispatch error message to store for common display usage",
    MY_RESERVATION_RESET_ITINERARY_RESV="[reservation] reset my reservation excursion and all reservation based on port",
    RESET_STORE = "[reset] app reset on logout",
}


//MY RESERVATION PAGE
export class BookingList implements Action {
    readonly type = ReservationActionTypes.LIST_BOOKING;
    constructor(public payload: any) { }
}
export class BookingListSuccess implements Action {
    readonly type = ReservationActionTypes.LIST_BOOKING_SUCCESS;
    constructor(public payload: any) { }
}
export class BookingListSuccessStatus implements Action {
    readonly type = ReservationActionTypes.LIST_BOOKING_SUCCESS_STATUS;
    constructor(public payload: boolean) { }
}
export class BookingListFailedStatus implements Action {
    readonly type = ReservationActionTypes.LIST_BOOKING_FAILED_STATUS;
    constructor(public payload: boolean) { }
}
export class BookingListFailedMsg implements Action {
    readonly type = ReservationActionTypes.LIST_BOOKING_FAILED_ERRORMSG;
    constructor(public payload: any) { }
}
export class BookingIdCurrent implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_BOOKINGID;
    constructor(public payload: string) { }
}
export class BookingDetails implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS;
    constructor(public payload: {params:{bookingId:string},apiUrl:string}) { }
}
export class BookingDetailsFailed implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS_FAILED;
    constructor(public payload: boolean) { }
}
export class BookingDetailsSuccess implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS_SUCCESS;
    constructor(public payload: any) { }
}
export class BookingDetailsFailedMsg implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATIONS_BOOKING_DETAILS_FAILED_ERRORMSG;
    constructor(public payload: any) { }
}
export class BookingShoreExDetails implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_SHORE_EX_BOOKING;
    constructor(public payload: {params:any,apiUrl:string}) { }
}
export class BookingShoreExDetailsSuccess implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_SHORE_EX_BOOKING_SUCCESS;
    constructor(public payload:any){}
}
export class BookingShoreExDetailsFailed implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_SHORE_EX_BOOKING_FAILED;
    constructor(public payload:any){}
}
export class BookingMarqueeDetails implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_MARQUEE_BOOKING;
    constructor(public payload: {params:any,apiUrl:string}) { }
}
export class BookingMarqueeDetailsSuccess implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_MARQUEE_BOOKING_SUCCESS;
    constructor(public payload:any){}
}
export class BookingMarqueeDetailsFailed implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_MARQUEE_BOOKING_FAILED;
    constructor(public payload:any){}
}
export class BookingDiningDetails implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_DINING_BOOKING;
    constructor(public payload: {params:any,apiUrl:string}) { }
}
export class BookingDiningDetailsSuccess implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_DINING_BOOKING_SUCCESS;
    constructor(public payload:any){}
}
export class BookingDiningDetailsFailed implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_DINING_BOOKING_FAILED;
    constructor(public payload:any){}
}
export class BookingSPADetails implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_SPA_BOOKING;
    constructor(public payload: {params:any,apiUrl:string}) { }
}
export class BookingSPADetailsSuccess implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_SPA_BOOKING_SUCCESS;
    constructor(public payload:any){}
}
export class BookingSPADetailsFailed implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_SPA_BOOKING_FAILED;
    constructor(public payload:any){}
}

export class BookingReservationCommonErrMsg implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_COMMON_ERR_MSG;
    constructor(public payload:any){}
}

export class ResetReservation implements Action {
    readonly type = ReservationActionTypes.MY_RESERVATION_RESET_ITINERARY_RESV;
    constructor() { }
}
export class ResetStore implements Action {
    readonly type = ReservationActionTypes.RESET_STORE;
    constructor() { }
}
export function clearState(reducer) {
    return function (state, action) {  
      if (action.type === ReservationActionTypes.RESET_STORE) {
        state = undefined;
      }  
      return reducer(state, action);
    };
  }


export type All = ResetStore | BookingDetails | BookingIdCurrent | BookingList | BookingListSuccess | BookingListFailedMsg |
 BookingListSuccessStatus | BookingListFailedStatus | BookingDetailsFailed | BookingDetailsSuccess | BookingDetailsFailedMsg | 
 BookingShoreExDetails | BookingShoreExDetailsSuccess | BookingShoreExDetailsFailed | BookingDiningDetails | BookingDiningDetailsSuccess |
 BookingDiningDetailsFailed | BookingMarqueeDetails |BookingMarqueeDetailsSuccess | BookingMarqueeDetailsFailed |
 BookingSPADetails | BookingSPADetailsSuccess | BookingSPADetailsFailed | ResetReservation | BookingReservationCommonErrMsg;