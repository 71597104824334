import { Injectable } from '@angular/core';

declare const ga: Function;
@Injectable()
export class RcycGoogleAnalyticsEventsService {

  constructor() { }

  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventLabel: string = null,
                   eventValue: number = null) {
      if (typeof ga === 'function') {

        ga('send', 'event', { eventCategory, eventLabel, eventAction, eventValue });
      }

  }

}
