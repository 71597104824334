import {
  Component, Input, OnInit, OnChanges, HostListener, Output, EventEmitter,
  Inject, OnDestroy, PLATFORM_ID, ViewChild, AfterViewInit
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycGoogleAnalyticsEventsService } from '../../rcyc-services/rcyc-google-analytics-events/rcyc-google-analytics-events.service';
// import { ScrollToService } from 'ng2-scroll-to-el';
// import { CookieService } from "ngx-cookie-service";
// import { CookiesService } from '@ngx-utils/cookies';
import {CookieService} from 'ngx-cookie-service';

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { RcycLoginComponent } from '../rcyc-guest/rcyc-login/rcyc-login.component';
import { RcycCommonLogoutService } from '../../rcyc-services/rcyc-logout/rcyc-logout.service';
// import { IfObservable } from 'rxjs/observable/IfObservable';

declare const ga: Function;


@Component({
  selector: 'app-rcyc-header-new',
  templateUrl: './rcyc-header-new.component.html',
  styleUrls: ['./rcyc-header-new.component.css']
})
export class RcycHeaderNewComponent implements OnInit, AfterViewInit, OnChanges {

  subHeaderStatus: any;
  mainHeaderStatus: any;
  isSelect = false;
  subHeaderDataArray_A: any;
  currentComponent: any;
  currentUrlPathDataArray: any;
  gaApiUrl: any;
  initalmobilebanner: string;
  initialMobile = false;
  menuObj: object;
  loggedMenuObj: object;
  gdpr_cookie: any;
  cookieSubscription: any;
  UserDetails: any = {};
  continent_code: any;
  country_no: any = '';
  currentUserName: any = '';
  userDetail: any;
  userImgSubMenus: any;
  loggedGuestStatus: any;
  isOpen = false;
  isMobOpen = false;
  userType: any;
  rightSideMenuShowStatus: boolean = false;
  @ViewChild('guestLoginModal', { static: true }) guestLoginModal: RcycLoginComponent;
  requestCallTemp: any;
  skipNavPath: any;
  loggedUserData: any;
  ReturnToDashboard = "Return to Dashboard";
  ReturnToMyReservations = "Return to My Reservations"
  @Output() toggleMenu: EventEmitter<any> = new EventEmitter<any>();
  @Input() countryNo: any;
  stickyHeader = false;
  ReturnToReservation: string ="Return to Reservation ";
  topOffset: number = 0;
  isScrolled : boolean = false;

  @HostListener('window:keyup.Tab', ['$event']) onKeyUp(e: any) {
    if (e && e.srcElement.innerText === 'REQUEST A CALL' && e.srcElement.id === 'hd_cruise') {
      document.getElementById('hd_cruise').style.color = 'black';
      document.getElementById('hd_cruise').style.background = 'white';
    } else {
      document.getElementById('hd_cruise')?document.getElementById('hd_cruise').style.color = 'white':'';
      document.getElementById('hd_cruise')?document.getElementById('hd_cruise').style.background = 'black':'';
    }
  }
  @HostListener('window:keydown.Shift.Tab', ['$event']) onKeyDown(e: any) {
    if (e && e.srcElement.innerText === 'SKIP NAVIGATION') {
      document.getElementById('hd_cruise').style.color = 'black';
      document.getElementById('hd_cruise').style.background = 'white';
    } else {
      document.getElementById('hd_cruise').style.color = 'white';
      document.getElementById('hd_cruise').style.background = 'black';
    }
  }


  @HostListener('window:resize',[]) onResisze(){
    if(window.innerWidth < 420){
      this.ReturnToDashboard = "Dashboard";
      this.ReturnToReservation ="Reservations ";
    } else {
      this.ReturnToDashboard = "Return to Dashboard";
      this.ReturnToReservation = "Return to Reservation ";
    }

    if (window.innerWidth > 769) {
      this.hideMenu()
    }
  }

  returnToReservationArray: any;
  guestReturnDetailsStatus = false;
  gobackToAnyArray: any;
  passedisLoggedMenu: any = { 'isLoggedMenu': false };
  canActivateLoginStatus = false;
  authGuardLoggedStatus = false;
  gobackReturnToReservationArray: any;

  constructor(

    private router: Router,
    private rcycCommonService: RcycCommonService,
    public googleAnalyticsEventsService: RcycGoogleAnalyticsEventsService,
    private rcycHomeService: RcycHomeService,
    // private scrollService: ScrollToService,
    // private cookieService: CookieService,
    // private elementRef: ElementRef,
    private cookies: CookieService,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private rcycCommonLogoutService: RcycCommonLogoutService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.headerConstructorFn();

    this.router.events.subscribe((val: any) => {
      this.rightSideMenuShowStatus = false;
      if (document.body.style.overflow == 'hidden') {
        document.body.style.overflow = 'auto';
      }
      // this.menuObjects();
      this.passedisLoggedMenu.isLoggedMenu = false;
      this.publicMenus();
      this.getLoggedUSerName();
      this.userImgSubMenusFn();
      if (localStorage.getItem('loggedUserDetails')) {
        this.mobSubmenuHide();
        // this.loggedMenus();
      }

      if (this.router.url.match('/guest/') ||
        this.router.url.match('/travel-agent/') ||
        this.router.url.match('/reservation-agent')||
        this.router.url.match('/travel-agent')) {
        this.authGuardLoggedStatus = true;
        this.loggedMenus();
      } else {
        this.authGuardLoggedStatus = false;
      }
    });

    this.rcycCommonService.gobackReturnToReservationArray
      .subscribe(
        params => {
          let TepmParam:any = params;
          if (TepmParam.confirmCancelPopUp == false) {
            this.guestReturnDetailsStatus = true;
          }
          // this.gobackReturnToReservationArray =  params;
        }
      );

    this.rcycCommonService.gobackToAnyArray
      .subscribe(
        params => {
          this.gobackToAnyArray = params;
          if(this.gobackToAnyArray.from === 'myreservation-guestinfo' ||
             this.gobackToAnyArray.from  === 'dashboard' ||
             this.gobackToAnyArray.from  === '404' ||
             this.gobackToAnyArray.from === 'myreservation' ||
             this.gobackToAnyArray.from === 'amenities' ||
             this.gobackToAnyArray.from === 'guestInfoPage' ||
             this.gobackToAnyArray.userFormCancel) {
                this.returnToReservationArray.bookingCode = '';
          }
        }
      );

    // this.rcycCommonService.canActivateArray
    // .subscribe(
    //   params => {
    //     let paramInit: any = params;
    //     this.canActivateLoginStatus = paramInit.loggedUrlStatus;
    //     this.canActivateArray = params;
    //   }
    // );


  }

  ngOnChanges() {

    this.ngOnChangeFn();
  }

  /* =========================================================================
     Function to call all menus and submenus
   ============================================================================ */
  headerConstructorFn() {

    this.rcycCommonService.subHeaderStatus.subscribe(status => this.subHeaderStatus = status);
    this.rcycCommonService.mainHeaderStatus.subscribe(status => this.mainHeaderStatus = status);
    this.rcycCommonService.currentComponent.subscribe(componentName => this.currentComponent = componentName);
    this.rcycCommonService.currentUserName.subscribe(currentUserName => {
      this.currentUserName = currentUserName;
      if (currentUserName !== 'currentUserName') {
        this.loggedGuestStatus = true;
      }
    });

    // List all menus and sub menus
    // this.menuObjects();
    this.publicMenus();

    this.userNameSet();
    this.userImgSubMenusFn();

  }
  // Mobile menu Toggle function
  toggleMobileMenu() {
    this.initialMobile = !this.initialMobile;
    this.toggleMenu.emit(this.initialMobile);
  }

  // Mobile menu hide function
  hideMenu() {
    this.initialMobile = false;
    this.toggleMenu.emit(this.initialMobile);
  }

  // Route go to
  routeGoTo(menu) {
    this.rcycCommonService.changeGobackToAnyArray(
      {
        'goBack': false,
      });
    this.returnToReservationArray.bookingCode = '';

    this.hideMenu();
    if (menu === 'find-a-cruise' || menu === 'request-call') {
      this.router.navigate([menu]);
    } else {
      this.router.navigate([menu.url]);
    }
  }

  ngOnInit() {

    if(window.innerWidth < 420){
      this.ReturnToDashboard = "Dashboard";
      this.ReturnToReservation ="Reservations";
    }
    // Changing subheader A value
    this.rcycCommonService.subHeaderDataArray_A
      .subscribe(
        params => {
          this.subHeaderDataArray_A = params;
        }
      );

    this.rcycCommonService.returnToReservationArray
      .subscribe(
        params => {
          this.returnToReservationArray = params;
          this.guestReturnDetailsStatus = false;
        }
      );


    // Decide Header
    this.rcycCommonService.headerMenuArray
      .subscribe(
        params => {
          // let passedisLoggedMenu: any = {'isLoggedMenu': ''};
          this.passedisLoggedMenu = params;
          if (this.passedisLoggedMenu.isLoggedMenu) {
            this.loggedMenus();
          }
        }
      );

  }
  ngOnChangeFn() {

  }

  guestReturnDetailsClick(item) {
    this.gobackToAnyArray.goBack = true;
    this.gobackToAnyArray.to = 'dashboard';
    this.returnToReservationArray.bookingCode = '';

    this.guestReturnDetailsStatus = true;
    if (this.userType === 'TA') {
      this.router.navigate(['/travel-agent/booking-summary/' + item.bookingId]);
    } else {
      this.router.navigate(['/guest/my-reservations/' + item.bookingId]);
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.setTopOffset(51);
    }, 0);


  }

  private setTopOffset(offset: number): void {
    this.topOffset = offset;
  }

  showSubmenu() {
    this.isOpen = true;
  }

  hideSubMenu() {
    this.isOpen = false;
  }
  mobSubmenuShow() {
    this.isMobOpen = !this.isMobOpen;
  }

  // Scroll to a particular Area on click
  commonPointScrollDown() {

    let totalHeight = 0;
    const switchKey = this.currentComponent;
    let scrolledY;
    switch (switchKey) {

      case 'RcycRegionComponent':

        document.querySelector('#rcyc-page-region .section-2-3').scrollIntoView();
        scrolledY = window.scrollY;

        break;
      case 'RcycChannelsComponent':
        document.querySelector('#rcyc-page-channels .section-3').scrollIntoView();
        scrolledY = window.scrollY;
        break;
      default:
        // console.log(switchKey);
        break;
    }

    if (scrolledY) {
      window.scroll(0, scrolledY - ((document.querySelector('nav.menu-wrapper.brand-nav-wrapper') as HTMLElement).offsetHeight + 50));
    }

  }

  // Function to scroll to A position
  scrollToAPositionFn(totalHeight) {

    if (isPlatformBrowser(this.platformId)) {

      window.scrollTo(0, totalHeight);
    }
  }



  // Open login modal
  loginOpenClick() {

    this.router.navigate(['/login']);

  }

  /* =======================================================================
      Function to set the username in header
    ==================================================================== */
  userNameSet() {

    if (localStorage.getItem('loggedUserDetails')) {

      const loggedUserDetails = JSON.parse(localStorage.getItem('loggedUserDetails'));
      const userType = loggedUserDetails.type.toUpperCase();
      let nameOfCurrentUser;
      switch (userType) {
        case 'TA':
          nameOfCurrentUser = loggedUserDetails.TAName;
          // change changeCurrentUserName
          this.rcycCommonService.changeCurrentUser(nameOfCurrentUser);
          this.loggedGuestStatus = true;
          break;
        default:
          nameOfCurrentUser = loggedUserDetails.guestName;
          // change changeCurrentUserName
          this.rcycCommonService.changeCurrentUser(nameOfCurrentUser);
          this.loggedGuestStatus = true;
          break;
      }

      // const nameOfCurrentUser = loggedUserDetails.guestName;
      // const stringifiedLoginData = JSON.stringify(loginDatas);

      // localStorage.setItem('loggedUserDetails', stringifiedLoginData);
      this.rcycCommonService.setLoggedInStatus(true);

    }
  }

  /* =======================================================================
      Function to call all sub menus when user Img hover
    ==================================================================== */
  userImgSubMenusFn() {

    if (localStorage.getItem('loggedUserDetails')) {
      const userDetail = JSON.parse(localStorage.getItem('loggedUserDetails'));
      const userType = userDetail.type;
      this.userType = userType;

      switch (userType) {

        case 'TA':
          this.userImgSubMenus = [
            {
              name: 'Dashboard',
              url: '/travel-agent/dashboard',
              id: 'taDashboard',
              menuType: 'logged',
            },
            {
              name: 'My Reservations',
              url: '/travel-agent/booking-summary',
              id: 'taBookings',
              menuType: 'logged',
            },
            {
              name: 'Inventory',
              url: '/travel-agent/inventory',
              id: 'taInventory',
              menuType: 'logged',
            },
            {
              name: 'Resources',
              url: '/travel-agent/resources',
              id: 'taResources',
              menuType: 'logged',
            },
            {
              name: 'My Profile',
              url: '/travel-agent/my-profile',
              id: 'taProfile',
              menuType: 'logged',
            },

            {
              name: 'Change Password',
              url: '/travel-agent/password-update',
              id: 'taChangePassword',
              menuType: 'logged',
            },
            {
              name: 'Logout',
              url: '/signout',
              id: 'taLogout',
              menuType: 'backToMain',
            }
          ];
          break;
        case 'RA':
          this.userImgSubMenus = [
            {
              name: 'Search Guest',
              url: 'reservation-agent/guest-search',
              id: 'searchGuest',
              menuType: 'logged',
            },
            {
              name: 'Logout',
              url: '/signout',
              id: 'taLogout',
              menuType: 'backToMain',
            }
          ];
          break;

        default:
          this.userImgSubMenus = [
            {
              name: 'Dashboard',
              url: '/guest/dashboard',
              id: 'gCollections',
              menuType: 'logged',
            },
            {
              name: 'My Reservations',
              url: '/guest/my-reservations',
              id: 'gReservations',
              menuType: 'logged',
            },
            {
              name: 'My Profile',
              url: '/guest/my-profile',
              id: 'gProfile',
              menuType: 'logged',
            },
            {
              name: 'Change Password',
              url: '/guest/password-update',
              id: 'gChangePassword',
              menuType: 'logged',
            },
            {
              name: 'Logout',
              url: '/signout',
              id: 'glogout',
              menuType: 'backToMain',
            }
          ];
          break;
      }

    }

  }
  /* =======================================================================
        Click on User Icon in header
      ==================================================================== */
  userIconClick() {
    if (JSON.parse(localStorage.getItem('loggedUserDetails'))) {
      let loggedUserDetails = JSON.parse(localStorage.getItem('loggedUserDetails'));
      loggedUserDetails.backToMainSiteStatus = true;
      localStorage.setItem('loggedUserDetails', JSON.stringify(loggedUserDetails));
      // this.menuObjects();
      this.loggedMenus();
    }

    switch (this.userType) {
      case 'TA':
        this.router.navigate(['/travel-agent/dashboard']);
        break;
      default:
        this.router.navigate(['/guest/dashboard']);
        break;
    }
  }
  /* =======================================================================
        Function call to hide user subMenu on page load
      ==================================================================== */

  mobSubmenuHide() {
    this.isMobOpen = false;
  }
  /* =======================================================================
        Function call on user menu outer div click to hide user subMenu
      ==================================================================== */
  mobileUserSubMenuOuterClick() {
    this.isMobOpen = false;
  }

  // Clear Voyage Detail from Local Storage
  clearVoyageDetail(params) {


    if (document.getElementById('rcycGtag')) {
      // Remove if rcycGtag already exists
      const element = document.getElementById('rcycGtag');
      element.parentNode.removeChild(element);
    }

    if (document.getElementById('rcycGtagAsync')) {
      // Remove if rcycGtagAsync already exists
      const element = document.getElementById('rcycGtagAsync');
      element.parentNode.removeChild(element);
    }

    if (document.getElementById('rcycGtmId')) {
      // Remove if rcycGtmId already exists
      const element = document.getElementById('rcycGtmId');
      element.parentNode.removeChild(element);
    }

    if (document.getElementById('rcycGtmIda')) {
      // Remove if rcycGtmIda already exists
      const element = document.getElementById('rcycGtmIda');
      element.parentNode.removeChild(element);
    }

    this.rcycCommonService.removeSessionData('specialOfferPageDetails');
    this.rcycCommonService.removeSessionData('portPageDetails');
    this.rcycCommonService.removeSessionData('regionPageDetails');

    this.rcycCommonService.removeSessionData('userPreferenceData');
    this.rcycCommonService.removeSessionData('findACruisePaginationArray');
    this.rcycCommonService.removeSessionData('backToResultVoyageListPrevUrl');
    this.rcycCommonService.removeSessionData('findACruiseSearchCriteria');
    this.rcycCommonService.removeData('pagesArray');

  }
  requestCall(tag) {
    if (tag === 'enter') {
      document.getElementById('hd_cruise').style.color = 'black';
      document.getElementById('hd_cruise').style.background = 'white';
    } else if (tag === 'leave') {
      document.getElementById('hd_cruise').style.color = 'white';
      document.getElementById('hd_cruise').style.background = 'black';
    } else {
      this.router.navigate(['/request-call']);
      document.getElementById('hd_cruise').style.color = 'white';
      document.getElementById('hd_cruise').style.background = 'black';
    }
  }
  skipNavigationClick() {
    this.skipNavPath = [];
    const findIdInUrl = this.router.url.split('#');
    if (findIdInUrl.length > 1) {
      this.skipNavPath = findIdInUrl[0] + '#forSkipNav';
    } else {
      this.skipNavPath = this.router.url + '#forSkipNav';
    }
  }

  /* =======================================================================
      Function for back to main site
    ==================================================================== */
  backToMainSiteMenuClick(item, type) {

    // if (item.menuType === 'backToMain') {
    //   let loggedUserDetails = JSON.parse(localStorage.getItem('loggedUserDetails'));
    //   loggedUserDetails.backToMainSiteStatus = false;
    //   localStorage.setItem('loggedUserDetails', JSON.stringify(loggedUserDetails));
    // } else if (item.menuType === 'logged') {
    //   let loggedUserDetails = JSON.parse(localStorage.getItem('loggedUserDetails'));
    //   loggedUserDetails.backToMainSiteStatus = true;
    //   localStorage.setItem('loggedUserDetails', JSON.stringify(loggedUserDetails));
    // }
    // // this.menuObjects();
    // this.publicMenus();
    if (item.menuType === 'backToMain') {
      this.publicMenus();
    }

    this.rcycCommonService.changeGobackToAnyArray(
      {
        'goBack': false,
      });
    this.returnToReservationArray.bookingCode = '';

  }

  /* =======================================================================
    Goto back subheader click
  ==================================================================== */
  gobackToAnyClick(item) {
    this.rcycCommonService.changeGobackToAnyArray(
      {
        'goBack': false,
      });
    switch (item) {
      case 'dashboard':
        if (this.userType === 'TA') {
          this.router.navigate(['/travel-agent/dashboard']);
        } else {
          this.router.navigate(['/guest/dashboard']);
        }
        break;
      case 'myReservations':
        if (this.userType === 'TA') {
          this.router.navigate(['/travel-agent/booking-summary']);
        } else {
          this.router.navigate(['/guest/my-reservations']);
        }
        break;
      case 'searchOrder':
        if (this.userType === 'RA') {
          this.router.navigate(['/reservation-agent/guest-search']);
        }
        break;
    }
  }


  /* =============================================================
      Logged Menus
  ================================================================= */

  loggedMenus() {
    const userDetail = JSON.parse(localStorage.getItem('loggedUserDetails'));
    const userType = userDetail.type;
    this.userType = userType;

    switch (userType) {

      case 'TA':
        this.menuObj = [
          {
            name: 'Dashboard',
            url: '/travel-agent/dashboard',
            id: 'taDashboard',
            menuType: 'logged',
          },
          {
            name: 'Reservations',
            url: '/travel-agent/booking-summary',
            id: 'taBookings',
            menuType: 'logged',
          },
          {
            name: 'My Profile',
            url: '/travel-agent/my-profile',
            id: 'taProfile',
            menuType: 'logged',
          },
          {
            name: 'Change Password',
            url: '/travel-agent/password-update',
            id: 'taChangePassword',
            menuType: 'logged',
          },
          {
            name: 'Back to main site',
            url: '/home',
            id: 'backToMainSite',
            menuType: 'backToMain',
          },
          {
            name: 'Logout',
            url: '/signout',
            id: 'taLogout',
            menuType: 'logged',
          }
        ];
        break;
      case 'RA':
        this.menuObj = [
          {
            name: 'Search Guest',
            url: 'reservation-agent/guest-search',
            id: 'searchGuest',
            menuType: 'logged',
          },
          {
            name: 'Back to main site',
            url: '/home',
            id: 'backToMainSite',
            menuType: 'backToMain',
          },
          {
            name: 'Logout',
            url: '/signout',
            id: 'taLogout',
            menuType: 'logged',
          }
        ];
        break;

      default:
        this.menuObj = [
          {
            name: 'Dashboard',
            url: '/guest/dashboard',
            id: 'gCollections',
            menuType: 'logged',
          },
          {
            name: 'My Reservations',
            url: '/guest/my-reservations',
            id: 'gReservations',
            menuType: 'logged',
          },
          {
            name: 'My Profile',
            url: '/guest/my-profile',
            id: 'gProfile',
            menuType: 'logged',
          },
          {
            name: 'Change Password',
            url: '/guest/password-update',
            id: 'gChangePassword',
            menuType: 'logged',
          },
          {
            name: 'Back to main site',
            url: '/home',
            id: 'backToMainSite',
            menuType: 'backToMain',
          },
          {
            name: 'Logout',
            url: '/signout',
            id: 'glogout',
            menuType: 'backToMain',
          }
        ];
        break;
    }
  }


  /* ==================================================
  Public Menus
  ===================================================== */

  publicMenus() {
    this.menuObj = [
      {
        name: 'Yachts',
        url: '/yachts',
        id: 'experience',
        menuType: 'public',
      },
      {
        name: 'Destinations',
        url: '/cruise-ports',
        id: 'destination',
        menuType: 'public',
      },
      {
        name: 'Voyages',
        url: '/find-a-cruise',
        id: 'voyages',
        menuType: 'public',
      },
      // {
      //   name: 'Offers & Programs',
      //   url: '/specialoffers',
      //   id: 'specialoffers',
      //   menuType: 'public',
      // },
      {
        name: 'The Compass',
        url: '/the-compass',
        id: 'the-compass',
        menuType: 'public',
      },
      {
        name: 'Brochure',
        url: JSON.parse(localStorage.getItem('loggedUserDetails')) ? '/ebrochure':'/request-brochure',
        id: 'requestEBrochure',
        menuType: 'public',
      }
    ];
  }
  loggedMenuOpen() {
    this.rightSideMenuShowStatus = true;
    if (screen.width < 769) {
      document.body.style.overflow = 'hidden';
    }

  }
  loggedMenuClose() {
    this.rightSideMenuShowStatus = false;
    if (document.body.style.overflow == 'hidden') {
      document.body.style.overflow = 'auto';
    }

  }
  getLoggedUSerName() {
    if (localStorage.getItem('loggedUserDetails')) {
      this.loggedUserData = JSON.parse(localStorage.getItem('loggedUserDetails'));

    }

  }
}