import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycVoyagesService } from '../rcyc-voyages/rcyc-voyages.service';

@Component({
  selector: 'app-rcyc-itinerary-overview-modal',
  templateUrl: './rcyc-itinerary-overview-modal.component.html',
})

export class RcycItineraryOverviewModalComponent implements OnInit {
  @ViewChild('diningSwal', { static: true }) diningSwal: SwalComponent;
  @Output() onItineraryOverviewModal = new EventEmitter<string>();
  itineraryOverviewData: any;
  portExcursionsData: any[] = null;
  opened: string;
  diningName: string;
  page: number;
  nrOfPages: number;
  currentSlide: number;

  constructor(
    private rcycInternalApisService: RcycInternalApisService,
    private rcycVoyagesService: RcycVoyagesService,
  ) { }

  ngOnInit(): void {
    this.calcPagination();
    this.portExcursionsData = null;
  }

  // method to call while opening the modal
  onOpen(event) {
  }

  onClose(event) {
    this.onItineraryOverviewModal.emit('itineraryOverview');
  }
  // method to open modal
  modalOpen(excursionsMetaData: any) {
    this.getPortCodeData(excursionsMetaData);
    this.getPortOverviewData(excursionsMetaData);
    this.diningSwal.show();
  }

  // method to call just before opening the modal
  beforeOpen(event) {
    event.modalElement.parentElement.setAttribute('id', 'itineraryOverviewModalSwalId');
  }

  openTab(id) {
    if (this.opened === id) {
      this.opened = null;
    } else {
      this.opened = id;
    }
  }

  getData(item) {
    return {
      id: item.id,
      destination: item.field_tile_title,
      image: {
        src: item.field_tile_image
      },
      eyebrow: item.field_tile_eyebrow,
      title: item.field_tile_title,
      text: item.field_tile_details,
      cta: {
        title: item.field_tile_cta_title,
        url: item.field_tile_cta_url,
      }
    }
  }

  calcPagination() {
    const slidesToShow = 5;
    this.page = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1)
    this.nrOfPages = Math.ceil(this.itineraryOverviewData?.imageSliderData.length / slidesToShow);
  }

  /**
   * Drupal Api for port Data
   */
  getPortOverviewData(excursionsMetaData: any) {
    const apiUrl = '/api/v1/voyages/ports/' + excursionsMetaData?.portCode + '?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (res: any) => {
        if (res && res.length) {
          let tempItineraryOverviewData: any = {};
          tempItineraryOverviewData = res[0];
          tempItineraryOverviewData.departureTime = excursionsMetaData.departureTime;
          tempItineraryOverviewData.date = excursionsMetaData.date;
          tempItineraryOverviewData.arrivalTime = excursionsMetaData.arrivalTime;
          tempItineraryOverviewData.dayStop = '';
          tempItineraryOverviewData.dayStop = `${excursionsMetaData.heading} / ${excursionsMetaData.field_tab_section}`;
          tempItineraryOverviewData.imageSliderData = [];
          tempItineraryOverviewData.imageSliderData.push({ field_tile_image: res[0].field_image });
          this.itineraryOverviewData = tempItineraryOverviewData;
        }
      },
      err => {
        console.log(err, 'err website form heading');
      }
    );
  }

  /**
   * ES Api for port excursions data
   */
  getPortCodeData(portItem: any) {
    const voyageDate: any = new Date(portItem.voyageStartDate);
    const todayDate: any = new Date();
    const diffTime = Math.abs(todayDate - voyageDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const postData: any = {
      date: portItem?.date,
      portCode: portItem?.portCode,
    };
    if (diffDays && diffDays <= 180) {
      postData.voyageCode = portItem?.voyageId ? portItem?.voyageId : portItem?.voyageNumber
    }
    this.rcycVoyagesService.excursionMoreData(postData).subscribe(
      response => {
        const temp: any = response;
        if (temp && temp.data) {
          if (temp.data.marqueeEvents) {
            this.getPortImages(portItem?.portCode);
          }
          this.portExcursionsData = temp.data?.excursions.map((item, index, oriArray) => {
            if (item.storeRoomCd !== 'SHOREX_PE' && item.storeRoomCd !== 'SHOREX_CE' && item.storeRoomCd !== 'SHOREX_CP' && (index === oriArray.findIndex(o => o.tourName === item.tourName))) {
              return { ...item, ...{ yachtName: item.portName, voyageName: item.tourName } };
            }
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * Drupal Api for port excursions data
   */
  getPortImages(portCode) {
    const apiUrl = '/api/v1/excursions/port/' + portCode + '?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      response => {
        const temp: any = response;
        if (temp && temp.length) {
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.setPortImages(res);
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * Extracting images  
   */
  setPortImages(apiResponseE) {
    apiResponseE.forEach((imageData) => {
      this.portExcursionsData.forEach((contentData) => {
        if (contentData['tourCd'].slice(0, 8) === imageData['field_excursion_code'] && imageData['field_thumbnail']) {
          contentData['field_thumbnail'] = imageData['field_thumbnail'];
          contentData.slider.push(imageData['field_thumbnail']);
        }
      });
    });
  }

}
