import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';

@Component({
  selector: 'app-rcyc-onboard-exp-modal',
  templateUrl: './rcyc-onboard-exp-modal.component.html',
})

export class RcycOnboardExpModalComponent implements OnInit {
  @ViewChild('diningSwal', { static: true }) diningSwal: SwalComponent;
  onboardExpData: any;
  opened: string;
  page: number;
  nrOfPages: number;
  currentSlide: number;

  constructor(
    private rcycInternalApisService: RcycInternalApisService
  ) { }

  ngOnInit(): void {
    this.calcPagination();
  }

  // method to call while opening the modal
  onOpen(event) { }

  onClose(event) { }

  // method to open modal
  modalOpen(diningMetaData: any) {
    this.getDiningRestData(diningMetaData);
    this.diningSwal.show();
  }

  // method to call just before opening the modal
  beforeOpen(event) {
    event.modalElement.parentElement.setAttribute('id', 'onboardExpSwalId');
  }

  openTab(id) {
    if (this.opened === id) {
      this.opened = null;
    } else {
      this.opened = id;
    }
  }

  getData(item) {
    return {
      id: item.id,
      destination: item.field_tile_title,
      image: {
        src: item.field_tile_image
      },
      eyebrow: item.field_tile_eyebrow,
      title: item.field_tile_title,
      text: item.field_tile_details,
      cta: {
        title: item.field_tile_cta_title,
        url: item.field_tile_cta_url,
      }
    }
  }

  calcPagination() {
    const slidesToShow = 5;
    this.page = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1)
    this.nrOfPages = Math.ceil(this.onboardExpData?.imageSliderData.length / slidesToShow);
  }

  /**
   * Drupal Api for Onboard Exp Data
   **/
  getDiningRestData(diningMetaData: any) {
    const apiUrl = '/api/v1/experiences/' + diningMetaData?.yacht.toLowerCase() + '/' + diningMetaData?.experience.toLowerCase() + '?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (res: any = []) => {
        if (res && res.length) {
          let tempDiningData: any = res;
          tempDiningData = tempDiningData.map(rest => {
            return {
              body: rest.body,
              title: rest.field_meta_title,
              eyebrow: rest.field_com_eyebrow,
              sort_order: rest.field_sort_order,
              cta_title: rest.field_com_cta_title,
              cta_link: (rest?.field_com_cta_uri?.replace('internal:','')),
              imageSliderData: rest.field_com_images.split(',').map(img => { return { field_tile_image: img?.trim() } }),
            }
          });
          this.onboardExpData = tempDiningData[0];
          this.calcPagination();
        }
      },
      err => {
        console.log(err, 'err website form heading');
      }
    );
  }

}
