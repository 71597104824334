import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycVoyagesService } from '../rcyc-voyages/rcyc-voyages.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { settings } from 'cluster';
import { DomSanitizer } from '@angular/platform-browser';
 
const { detect } = require('detect-browser');
const browser: any = detect();
 
@Component({
  selector: 'app-rcyc-suites-list',
  templateUrl: './rcyc-suites-list.component.html',
  styleUrls: ['./rcyc-suites-list.component.css'],
})
export class RcycSuitesListComponent implements OnInit {
  isMobile: any;
  suitetitle: any;
  @Input() SuiteFare_result: any;
  @Input() isPricing: boolean;
  @Input() isHeading: boolean;
  @Input() id: any;
  @Input() variant: any;
  @Input() pageName: any;
  @ViewChild('pageNguCarousel') pageNguCarousel: NguCarousel<any>;
  @ViewChild('nguPageCarouselControls') nguPageCarouselControls: any;
  slideConfig: any;
  page: number;
  nrOfPages: number;
  pageNumber: number;
  opened: any;
  public pageCarousel: any;
  currentSlide = 0;
  sliderIndex = 0;
  nguTotalPage: any;
  nguPage: any;
  openedTabs: number[] = [];
  slideValue: number = 0;
  calltext: any;
  isTab: boolean;
  headerContent: any;
  openedTabsAccor: any [] = [];
  accordionData: any = {}
  imageScrollPosition: any;
  imageSliderLength: boolean;
  diningSliderLength: boolean;
 
  constructor(
    private rcycCommonService: RcycCommonService,
    private rcycVoyagesService: RcycVoyagesService,
    private sanitizer: DomSanitizer
  ) { }
 
 
  ngOnInit(): void {
    this.getCallText()
    this.getHeader()
    this.pageCarousel = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 500,
      interval: 0,
      point: {
        visible: false
      },
      // load: 2,
      touch: true,
      loop: true,
      // custom: 'banner'
    };
 
    this.screenSize();
    if(this.pageName=='diningModal'){
      setTimeout(()=>{
        let slidesToShow = 1;
        this.nguTotalPage= this.SuiteFare_result[0]?.imageSliderData?.length;
        this.nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
        this.diningSliderLength = this.SuiteFare_result[0]?.imageSliderData?.length>1
        if(this.SuiteFare_result[0]?.imageSliderData[0]?.field_video ){
          this.imageScrollPosition = 'tour'
        }else{
          this.imageScrollPosition = 'map'
        }
      },1000)
    }
  }
 
  @HostListener('window:resize', ['$event'])
  screenSize() {
    this.isMobile = window.innerWidth <= 769;
    this.isTab = window.innerWidth <= 1024;
  }
 
  getHeader(){
    this.rcycVoyagesService.getHeaders('suites_fares').subscribe(response=>{
      if(response){
        this.headerContent = response[0];
      }
    })
  }
 
  requestAQuoteClick() {
    this.rcycCommonService.setSessionData('requestAQuoteClick', true);
  }
 
  isOpenedTab(id: any): boolean {
    return this.openedTabsAccor.includes(id);
  }
 
  toggleTabAccor(id: any, item): void {
    const index = this.openedTabsAccor.indexOf(id);
 
    if (this.isOpenedTab(id)) {
      this.openedTabsAccor.splice(index,1); // Close the tab if it's already opened
    } else {
      this.openedTabsAccor.push(id);
      this.getSuitesDetails(id,item) // Open the tab
    }
  }
 
  getSuitesDetails(id,item) {
 
    // cruiseCode
    // currency
      if(!this.accordionData[id]){
        this.accordionData[id]={}
      }
      this.rcycVoyagesService.DrupalSuitesSlider_API(item.field_yacht_name, this.pageName=='yachtSuite'? item.field_suites_tabs_code: item.field_suite_code).subscribe(
        response => {
          if (response) {
            this.suitetitle = response;
            this.accordionData[id] = this.accordionData[id] || {};
            this.accordionData[id].imageScrollPosition = 'tour'
            this.accordionData[id].suitemoreInfo_slider = this.suitetitle;
            this.accordionData[id]?.suitemoreInfo_slider?.sort((a,b)=> a?.field_sort_order - b?.field_sort_order);
            let slidesToShow = 1;
            this.accordionData[id].nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
            this.accordionData[id].nguTotalPage = Math.ceil(this.accordionData[id]?.suitemoreInfo_slider?.length / slidesToShow);
            this.imageSliderLength = (this.accordionData[id]?.suitemoreInfo_slider && this.accordionData[id]?.suitemoreInfo_slider?.length>1)
          }
        },
        err => {
 
      });
 
      this.rcycVoyagesService.DrupalSuitesDetails_API(item.field_yacht_name, this.pageName=='yachtSuite'? item.field_suites_tabs_code: item.field_suite_code).subscribe(
        (response: any) => {
          if (response) {
            this.accordionData[id] = this.accordionData[id] || {};
            this.accordionData[id].suitemoreInfo = response;
            this.accordionData[id].suitemoreInfo?.sort((a,b)=>a?.field_sort_order-b?.field_sort_order)
          }
        },
        err => {
 
      });
  }
 
 
  getSafeUrl(url){
    let safeUrl = url.split('?')[0];
    return this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl+'?autoplay=0&autopause=0&loop=1&texttrack=null&muted=1&controls=0')
  }
 
  getCallText(){
    this.rcycVoyagesService.tooglePcfiCall().subscribe((response:any)=>{
      this.calltext = response[0].field_toggle_text;              
    })
  }
 
  setImageMode(id,position){
    if(position == 'tour'){
        this.pageNguCarousel?.moveTo(0);
        if(this.pageName=='diningModal'){
          this.imageScrollPosition = 'tour'
        }else{
          this.accordionData[id].imageScrollPosition = 'tour'
        }
    }else if(position == 'map'){
      if(this.pageName=='diningModal'){
          this.imageScrollPosition = 'map'
          if(this.SuiteFare_result[0]?.imageSliderData[0]?.field_video){
            this.pageNguCarousel?.moveTo(1)
          }else{
            this.pageNguCarousel?.moveTo(0);
          }
      }else{
        this.accordionData[id].imageScrollPosition = 'map'
        this.pageNguCarousel?.moveTo(1)
      }
    }else if(position == 'photo'){  
        if(this.pageName=='diningModal'){
          this.imageScrollPosition = 'photo'
          if(this.SuiteFare_result[0]?.imageSliderData[0]?.field_video){
            this.pageNguCarousel?.moveTo(2)
          }else{
            this.pageNguCarousel?.moveTo(1);
          }
        }else{
          this.accordionData[id].imageScrollPosition = 'photo'
          this.pageNguCarousel?.moveTo(2)
        }
    }
  }
 
  toogleTab(id:number){
    const index = this.openedTabs.indexOf(id);
    if(index===-1){
      this.openedTabs.push(id);
    }else{
      this.openedTabs.splice(index,1);
    }
  }
 
  isOpened(id: number): boolean{
    return this.openedTabs.includes(id);
  }
 
  onmoveFn(event: NguCarouselStore, id?: any) {
    this.currentSlide = event?.currentSlide;
    let slidesToShow = 1;
    if(this.pageName=='diningModal'){
      this.nguPage = Math.ceil((this.currentSlide? this.currentSlide:0)/slidesToShow + 1);
      this.nguTotalPage = Math.ceil(this.SuiteFare_result[0]?.imageSliderData.length/slidesToShow);
      if(this.nguPage==1){
        if(this.SuiteFare_result[0]?.imageSliderData[0]?.field_video){
          this.imageScrollPosition = 'tour'
        }else{
          this.imageScrollPosition = 'map'
        }
      }else if(this.nguPage==2){
        if(this.SuiteFare_result[0]?.imageSliderData[0]?.field_video){
          this.imageScrollPosition = 'map'
        }else{
          this.imageScrollPosition = 'photo'
        }
      }else{
        this.imageScrollPosition = 'photo'
      }
    }else{
      this.accordionData[id].nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
      this.accordionData[id].nguTotalPage = Math.ceil(this.accordionData[id]?.suitemoreInfo_slider?.length / slidesToShow);
      if(this.accordionData[id]?.nguPage==1){
        this.accordionData[id].imageScrollPosition = 'tour'
      }else if(this.accordionData[id]?.nguPage==2){
        this.accordionData[id].imageScrollPosition = 'map'
      }else{
        this.accordionData[id].imageScrollPosition = 'photo'
      }
    }
  }
 
  prevSlide(id?) {
    const prevIndex = this.pageNguCarousel?.currentSlide - 1;
    if (prevIndex < 0) {
      this.pageNguCarousel?.moveTo(this.pageName=='diningModal'? (this.SuiteFare_result[0].imageSliderData.length - 1) : (this.accordionData[id]?.suitemoreInfo_slider?.length - 1));
    } else {
      this.pageNguCarousel?.moveTo(prevIndex);
    }
  }
 
  nextSlide(id?) {
    const nextIndex = this.pageNguCarousel?.currentSlide + 1;
    if (nextIndex >= (this.pageName=='diningModal'? (this.SuiteFare_result[0].imageSliderData.length) : (this.accordionData[id]?.suitemoreInfo_slider?.length))) {
      this.pageNguCarousel?.moveTo(0);
    } else {
      this.pageNguCarousel?.moveTo(nextIndex)
    }
  }
}