import { Component, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { DomSanitizer } from '@angular/platform-browser';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycVoyagesService } from '../rcyc-voyages/rcyc-voyages.service';
import { settings } from 'cluster';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';
import { RcycPlanYourVoyageComponent } from '../rcyc-booking-configurator/rcyc-plan-your-voyage/rcyc-plan-your-voyage.component';

const { detect } = require('detect-browser');
const browser: any = detect();

@Component({
  selector: 'app-rcyc-suites-list',
  templateUrl: './rcyc-suites-list.component.html',
  styleUrls: ['./rcyc-suites-list.component.css'],
})
export class RcycSuitesListComponent implements OnInit {
  isMobile: any;
  suitetitle: any;
  @Input() SuiteFare_result: any;
  @Input() isPricing: boolean;
  @Input() isHeading: boolean;
  @Input() id: any;
  @Input() variant: any;
  @Input() pageName: any;
  @Input() currencyCode: any;  
  @Input() isGetPricing: boolean;
  @Input() yachtName;
  @Input() currentVoyageId;
  staticDrupalTexts: any;
  // @ViewChild('pageNguCarousel') pageNguCarousel: NguCarousel<any>;
  @ViewChildren('pageNguCarousel') pageNguCarousel: QueryList<NguCarousel<any>>;
  @ViewChild('nguPageCarouselControls') nguPageCarouselControls: any;
  @ViewChild('openBookingConfiguratorModal', { static: false }) openBookingConfiguratorModal: RcycPlanYourVoyageComponent;
  slideConfig: any;
  page: number;
  nrOfPages: number;
  pageNumber: number;
  opened: any;
  public pageCarousel: any;
  currentSlide = 0;
  sliderIndex = 0;
  nguTotalPage: any;
  nguPage: any;
  openedTabs: number[] = [];
  slideValue: number = 0;
  calltext: any;
  isTab: boolean;
  headerContent: any;
  openedTabsAccor: any[] = [];
  accordionData: any = []
  imageScrollPosition: any;
  diningSliderLength: boolean;
  diningVideo: any;
  PCFIVoyageIdArray: any = [];
  buttons: any;

  constructor(
    private rcycCommonService: RcycCommonService,
    private rcycVoyagesService: RcycVoyagesService,
    private sanitizer: DomSanitizer,
    private sharedServiceService: SharedServiceService,
  ) { }

  ngOnInit(): void {
    this.getCallText();
    this.getHeader();
    this.pageCarousel = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 500,
      interval: 0,
      point: {
        visible: false
      },
      // load: 2,
      touch: true,
      loop: true,
      // custom: 'banner'
    };
    this.screenSize();
    if (this.pageName == 'diningModal') {
      setTimeout(() => {
        this.SuiteFare_result[0]?.imageSliderData?.sort((a,b)=>a?.field_sort_order-b?.field_sort_order)
        let slidesToShow = 1;
        this.currentSlide = 0;
        this.nguTotalPage = this.SuiteFare_result[0]?.imageSliderData?.length;
        this.nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
        this.diningSliderLength = this.SuiteFare_result[0]?.imageSliderData?.length > 1
        if (this.SuiteFare_result[0]?.imageSliderData[0]?.field_video || this.SuiteFare_result[0]?.imageSliderData[0]?.field_sort_order === '01') {
          this.imageScrollPosition = 'tour'
          this.diningVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.SuiteFare_result[0]?.imageSliderData[0]?.field_video);
        } else {
          this.imageScrollPosition = 'map'
        }
      }, 1000)
    }
    this.getStaticDrupalData();
  }

  @HostListener('window:resize', ['$event'])
  screenSize() {
    this.isMobile = window.innerWidth <= 769;
    this.isTab = window.innerWidth <= 1024;
  }

  getHeader() {
    this.rcycVoyagesService.getHeaders('suites_fares').subscribe(response => {
      if (response) {
        this.headerContent = response[0];
      }
    })
    this.rcycVoyagesService.getButtons().subscribe(response => {
      if(response){
        this.buttons = response;
      }
    })
  }

  requestAQuoteClick() {
    this.rcycCommonService.setSessionData('requestAQuoteClick', true);
  }

  isOpenedTab(id: any): boolean {
    return this.openedTabsAccor.includes(id);
  }

  toggleTabAccor(id: any, item): void {
    const index = this.openedTabsAccor.indexOf(id);

    if (this.isOpenedTab(id)) {
      this.openedTabsAccor.splice(index, 1); // Close the tab if it's already opened
    } else {
      this.openedTabsAccor.push(id);
      this.setCrousalFirstSlide(id);
      this.getSuitesDetails(id, item) // Open the tab
    }
  }

  getSuitesDetails(id, item) {
    // cruiseCode
    // currency
    if (!this.accordionData[id]) {
      this.accordionData[id] = []
    }
    this.rcycVoyagesService.DrupalSuitesSlider_API(item.field_yacht_name, this.pageName == 'yachtSuite' ? item.field_suites_tabs_code : item.field_suite_code).subscribe(
      response => {
        if (response) {
          this.suitetitle = response;
          this.accordionData[id] = this.accordionData[id] || [];
          this.accordionData[id].suitemoreInfo_slider = this.suitetitle;
          this.accordionData[id]?.suitemoreInfo_slider?.sort((a, b) => a?.field_sort_order - b?.field_sort_order);
          this.setCrousalFirstSlide(id);
          if (this.accordionData[id]?.suitemoreInfo_slider[0]?.field_video && this.accordionData[id]?.suitemoreInfo_slider[0]?.field_video.length) {
            this.getSafeVideoUrl(this.accordionData[id]?.suitemoreInfo_slider[0]?.field_video, id, false)
          }
          let slidesToShow = 1;
          this.accordionData[id].nguPage = slidesToShow;
          this.accordionData[id].nguTotalPage = Math.ceil(this.accordionData[id]?.suitemoreInfo_slider?.length / slidesToShow);
          this.accordionData[id].imageSliderLength = (this.accordionData[id]?.suitemoreInfo_slider && this.accordionData[id]?.suitemoreInfo_slider?.length > 1);
        }
      }, err => {
        console.error(err);
      });
    this.rcycVoyagesService.DrupalSuitesDetails_API(item.field_yacht_name, this.pageName == 'yachtSuite' ? item.field_suites_tabs_code : item.field_suite_code).subscribe(
      (response: any) => {
        if (response) {
          this.accordionData[id] = this.accordionData[id] || [];
          this.accordionData[id].suitemoreInfo = response;
          this.accordionData[id].suitemoreInfo?.sort((a, b) => a?.field_sort_order - b?.field_sort_order)
        }
      }, err => {
        console.error(err);
      });
  }

  setCrousalFirstSlide(id: any) {
    this.pageNguCarousel?.toArray().forEach((element: any) => {
      if (element?.carousel?.id === id) {
        element?.moveTo(0)
      }
    });
    let button: any;
      if(this.pageName === 'diningModal'){
        button = this.SuiteFare_result[0]?.imageSliderData[0];
      }else{
        button = this.accordionData[id]?.suitemoreInfo_slider[0];
      }
      const fieldSortOrder = parseInt(button?.field_sort_order, 10); // Convert to number
      if (fieldSortOrder === 1) {
        this.accordionData[id].imageScrollPosition = 'tour';
      } else if (fieldSortOrder === 2) {
        this.accordionData[id].imageScrollPosition = 'map';
      } else if (fieldSortOrder === 3) {
        this.accordionData[id].imageScrollPosition = 'photo';
      } else if (fieldSortOrder === 4) {
        this.getSafeVideoUrl(this.accordionData[id]?.suitemoreInfo_slider[0]?.field_video, id, true)
        this.accordionData[id].imageScrollPosition = 'view';
      }

      if (!this.accordionData[id]) {
        this.accordionData[id] = {}; // Initialize if undefined
      }
      this.accordionData[id].buttonInfo = []; // Initialize buttonInfo as an empty array
      
      this.accordionData[id]?.suitemoreInfo_slider?.forEach((item) => {
        const sortOrder = parseInt(item?.field_sort_order, 10);
      
        if (sortOrder === 1) {
          this.accordionData[id].buttonInfo.push({ id: 'tour', order: 1 });
        } else if (sortOrder === 2) {
          this.accordionData[id].buttonInfo.push({
            id: 'map',
            order: this.accordionData[id].buttonInfo.some((b) => b.order === 1) ? 2 : 1,
          });
        } else if (sortOrder === 3) {
          this.accordionData[id].buttonInfo.push({
            id: 'photo',
            order: this.accordionData[id].buttonInfo.some((b) => b.order === 2)
              ? 3
              : this.accordionData[id].buttonInfo.some((b) => b.order === 1)
              ? 2
              : 1,
          });
        } else if (sortOrder === 4) {
          this.getSafeVideoUrl(item?.field_video, id, true)
          this.accordionData[id].buttonInfo.push({
            id: 'view',
            order: this.accordionData[id].buttonInfo.some((b) => b.order === 3)
              ? 4
              : this.accordionData[id].buttonInfo.some((b) => b.order === 2)
              ? 3
              : this.accordionData[id].buttonInfo.some((b) => b.order === 1)
              ? 2
              : 1,
          });
        }
      });      
  }
 
  getSafeVideoUrl(url, id, is360: boolean) {
    let safeUrl = url.split('?')[0];
    if(is360){
      this.accordionData[id].view = this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl + '?autoplay=0&autopause=0&loop=1&texttrack=null&muted=1&controls=1')
    }else{
      this.accordionData[id].video = this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl + '?autoplay=0&autopause=0&loop=1&texttrack=null&muted=1&controls=1')
    }
  }

  getCallText() {
    this.rcycVoyagesService.tooglePcfiCall().subscribe((response: any) => {
      if (response && response.length > 0) {
        this.calltext = response[0].field_toggle_text;
        if (response[0].field_voyages) {
          this.PCFIVoyageIdArray = response[0].field_voyages.replace(/\s/g, '').split(",");
        }
      }
      this.SuiteFare_result.forEach(element => {
        this.currencyCode = this.currencyCode ? this.currencyCode : element.currency;
        element.pcfiToggleStatus = false;
        if (this.PCFIVoyageIdArray.includes(element.voyageId)) {
          element.pcfiToggleStatus = true;
        }
      });
    });
  }


  setImageMode(id, position?) {
    const buttonId: any = this.accordionData[id]?.buttonInfo?.filter(item=>item?.id=== position);
    this.pageNguCarousel?.toArray().forEach((element: any, index) => {
      if (element?.carousel?.id === id) {
        if (position == 'tour') {
          if (this.pageName == 'diningModal') {
            this.imageScrollPosition = 'tour'
            element.moveTo(0);
          } else {
            this.accordionData[id].imageScrollPosition = 'tour';
            element?.moveTo(buttonId[0]?.order-1)
          }
        } else if (position == 'map') {
          if (this.pageName == 'diningModal') {
            this.imageScrollPosition = 'map'
            if (this.SuiteFare_result[0]?.imageSliderData[0]?.field_video) {
              element.moveTo(1);
            } else {
              element.moveTo(0);
            }
          } else {
            this.accordionData[id].imageScrollPosition = 'map';
            element?.moveTo(buttonId[0]?.order-1)
          }
        } else if (position == 'photo') {
          if (this.pageName == 'diningModal') {
            this.imageScrollPosition = 'photo'
            if (this.SuiteFare_result[0]?.imageSliderData[0]?.field_video) {
              element.moveTo(2);
            } else {
              element.moveTo(1);
            }
          } else {
            this.accordionData[id].imageScrollPosition = 'photo';
            element?.moveTo(buttonId[0]?.order-1)
          }
        }else {
          this.accordionData[id].imageScrollPosition = 'view';
          element?.moveTo(buttonId[0]?.order-1)
        }
      }
    });
  }

  toogleTab(id: number) {
    const index = this.openedTabs.indexOf(id);
    if (index === -1) {
      this.openedTabs.push(id);
    } else {
      this.openedTabs.splice(index, 1);
    }
  }

  isOpened(id: number): boolean {
    return this.openedTabs.includes(id);
  }

  onmoveFn(event: NguCarouselStore, id?: any) {
    this.currentSlide = event?.currentSlide;
    let slidesToShow = 1;
    if (this.pageName == 'diningModal') {
      this.nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
      this.nguTotalPage = Math.ceil(this.SuiteFare_result[0]?.imageSliderData.length / slidesToShow);
      if (this.nguPage == 1) {
        if (this.SuiteFare_result[0]?.imageSliderData[0]?.field_video) {
          this.imageScrollPosition = 'tour'
        } else {
          this.imageScrollPosition = 'map'
        }
      } else if (this.nguPage == 2) {
        if (this.SuiteFare_result[0]?.imageSliderData[0]?.field_video) {
          this.imageScrollPosition = 'map'
        } else {
          this.imageScrollPosition = 'photo'
        }
      } else {
        this.imageScrollPosition = 'photo'
      }
    } else {
      this.accordionData[id].nguPage = Math.ceil((this.currentSlide ? this.currentSlide : 0) / slidesToShow + 1);
      this.accordionData[id].nguTotalPage = Math.ceil(this.accordionData[id]?.suitemoreInfo_slider?.length / slidesToShow);
      const buttonId: any = this.accordionData[id]?.buttonInfo?.filter((item)=> item.order === this.accordionData[id].nguPage)
      this.accordionData[id].imageScrollPosition = buttonId[0]?.id;
    }
  }

  prevSlide(id?) {
    this.pageNguCarousel?.toArray().forEach((element: any, index) => {
      if (element?.carousel?.id === id) {
        const prevIndex = element?.currentSlide - 1;
        if (prevIndex < 0) {
          element.moveTo(this.pageName == 'diningModal' ? (this.SuiteFare_result[0].imageSliderData.length - 1) : (this.accordionData[id]?.suitemoreInfo_slider?.length - 1));
        } else {
          element?.moveTo(prevIndex);
        }
      }
    });
  }

  getPositionValue(id?, index?: number): string {
    let button: any;
    if(this.pageName === 'diningModal'){
      button = this.SuiteFare_result[0]?.imageSliderData[index];
    }else{
      button = this.accordionData[id]?.suitemoreInfo_slider[index];
    }
    const fieldSortOrder = parseInt(button?.field_sort_order, 10); // Convert to number

    // Assign a dynamic position value based on field_sort_order
    if (fieldSortOrder === 1) {
      return 'tour';
    } else if (fieldSortOrder === 2) {
      return 'map';
    } else if (fieldSortOrder === 3) {
      return 'photo';
    } else if (fieldSortOrder === 4 && this.pageName !== 'diningModal') {
      return 'view';
    }
  }

  getPosition(id?: any){
    if(this.pageName === 'diningModal'){
      return this.imageScrollPosition;
    }else{
      return this.accordionData[id]?.imageScrollPosition;
    }
  }

  getImage(type: string, isActive: boolean): string {
    const images = {
      tour: { active: 'Motion-white.svg', inactive: 'Motion.svg' },
      map: { active: 'Map-w.svg', inactive: 'map.svg' },
      photo: { active: 'photo-white.svg', inactive: 'photo.svg' },
      view: { active: 'Motion-white.svg', inactive: 'Motion.svg' }
    };
    return isActive ? images[type]?.active : images[type]?.inactive;
  }
  
  getButtonLabel(id?: any,index?: number): string {
    let button: any;
    if(this.pageName === 'diningModal'){
      button = this.SuiteFare_result[0]?.imageSliderData[index];
    }else{
      button = this.accordionData[id]?.suitemoreInfo_slider[index];
    }
    const buttonInfo = this.buttons?.filter((item: any)=>item?.field_sort_order === button?.field_sort_order);

    return buttonInfo[0]?.field_com_title;
  }

  
  nextSlide(id?) {
    this.pageNguCarousel?.toArray().forEach((element: any, index) => {
      if (element?.carousel?.id === id) {
        const nextIndex = element?.currentSlide + 1;
        if (nextIndex >= (this.pageName == 'diningModal' ? (this.SuiteFare_result[0].imageSliderData.length) : (this.accordionData[id]?.suitemoreInfo_slider?.length))) {
          element?.moveTo(0);
        } else {
          element?.moveTo(nextIndex)
        }
      }
    });
  }
  openBookingConfig() {
    this.openBookingConfiguratorModal.modalOpen();
  }
  getStaticDrupalData() {
    const Api_Url = '/api/v1/dynamic-title-eyebrow/find-a-cruise-voyage-tile?_format=json';
    this.rcycVoyagesService.Drupal_API(Api_Url).subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          this.staticDrupalTexts = response.sort(this.rcycCommonService.orderByProp('field_sort_order'));
        }
      }, (err) => {
        console.error(err);
      });
  }
}