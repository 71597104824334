import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { PhoneDataFormat, ValidatePhoneNumber, deepPhoneConfiguration, formatPhoneList } from '../../../rcyc-share-components/rcyc-phonenumber-validator/rcyc-phonenumber-validator';
import { getMaskedCode } from '../../../../utils/phone-number-validation';
import { RcycInternalApisService } from '../../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycFormTokenService } from '../../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { SalesForceFormData } from '../../../rcyc-services/rcyc-salesforce/salesforce-form-data.interface';
import { AppConfig } from '../../../rcyc-config/config';
import { RcycBookingConfigService } from '../rcyc-booking-config.service';
import { RcycHomeService } from '../../rcyc-home/rcyc-home.service';
import { RcycVoyagesService } from '../../rcyc-voyages/rcyc-voyages.service';
import { SharedServiceService } from '../../../rcyc-services/rcyc-shared/shared-service.service';
import { GoogleAnalyticsService } from '../rcyc-booking-config-ga-tag/rcyc-ga-tag.service';
declare let ga: Function;

@Component({
  selector: 'app-rcyc-plan-your-voyage',
  templateUrl: './rcyc-plan-your-voyage.component.html',
  styleUrls: ['./rcyc-plan-your-voyage.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RcycPlanYourVoyageComponent implements OnInit {

  @ViewChild('openConfiguratorSwal', { static: true }) openConfiguratorSwal: SwalComponent;
  @Input() voyageId: any;
  @Input() yachtName: string;
  @Input() currencyCode: string;
  flagCodeClickStatus = false;
  countrySearch: string;
  phoneDataArray: PhoneDataFormat[];
  userCountryDetails: PhoneDataFormat;
  permanentGeoLocation: any;
  mask = [];
  lead_gen_form: any;
  formttedPhoneList: any = {};
  bookingConfigHeader: any;
  bookingConfigTerms: any;
  requestQuoteFormToken: any;
  rescoVoyageCode = '';
  sf_SpecificVoyageName: any;
  sf_SpecificVoyageDate: any;
  sf_SpecificVoyageCode: any;
  geoApiResponse: any = {};
  showTermUS = false;
  salesForceImplementation: boolean = AppConfig.salesForceImplementation;
  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlPath: any;
  drupalErrorMessages: any;
  countryListArray: any = [];
  showPhoneopt: boolean = false;
  zipcodeReqStatusDynamic: boolean = false;
  selectCountryCode: string;
  zipcodeMaxLength: number = 10;
  phNumReqStatusDynamic: boolean;
  isMobileScreen: boolean = false;
  userArea: any;
  showPhoneOptRequiredText: boolean = false;
  formSubmitFailStatus: boolean = false;
  PCFIVoyageIdArray = null;
  voyageBasicData = null;
  ports = null;
  voyageThumbImage = null;
  startTime: number;

  constructor(private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycCommonService: RcycCommonService,
    private rcycFormTokenService: RcycFormTokenService,
    private bookingConfigService: RcycBookingConfigService,
    private activatedRoute: ActivatedRoute,
    private rcycHomeService: RcycHomeService,
    private rcycVoyagesService: RcycVoyagesService,
    private sharedServiceService: SharedServiceService,
    private gaTagService: GoogleAnalyticsService,
    private router: Router,
  ) {
    // Subscriber to get User Area
    this.rcycCommonService.userAreaCode.subscribe((userAreaDetails) => {
      if (userAreaDetails.length) {
        const tempUserAreaDetails: any = userAreaDetails[0];
        this.userArea = tempUserAreaDetails.field_area_code;
      }
    });
  }

  ngOnInit() {
    this.itinFormData();
    this.requestQuoteFormTokenFn();
  }

  itinFormData() {
    this.lead_gen_form = this.formBuilder.group({
      firstname: ['', [Validators.required, Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
      lastname: ['', [Validators.required, Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$')]],
      phone: ['', Validators.required],
      dialCode: [''],
      countryCode: ['US', Validators.required],
      country: ['', Validators.required],
      zipcode: [''],
      isAgreeToReceiveMail: [false],
      isAgreeToContact: [false],
    });

    this.http.get('./assets/select_country.json').subscribe((data: any) => {
      if (data && data?.length) {
        this.countryListArray = data;
      }
    });

    this.http.get('./assets/rescoNumberFormatted.json').subscribe((phoneDataArray: PhoneDataFormat[]) => {
      this.phoneDataArray = phoneDataArray;
      this.formttedPhoneList = formatPhoneList(phoneDataArray);
      this.deepPhoneConfigurations();
    });

    this.rcycCommonService.getUser_Details().subscribe(
      response => {
        this.geoApiResponse = response;
      },
      err => {
      }
    );
    this.isMobileScreen = (window.innerWidth < 1024) ? true : false;
    this.getUserAreaCode();
  }

  /* ===================================================
 Function for value  change of form
 =======================================================*/
  formValueChangeCapture() {
    this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
    this.lead_gen_form.get('phone').updateValueAndValidity();
    this.lead_gen_form.get('isMobile').valueChanges.subscribe(newValue => {
      if (newValue === false) {
        this.lead_gen_form.patchValue({ isAgreeToSms: false });
      }
    });
    this.lead_gen_form.valueChanges.subscribe((val) => {
      this.formSubmitFailStatus = false;
    });
    this.lead_gen_form.get('country').valueChanges.subscribe((val) => {
      // Editing country field
      this.selectCountryCode = '';
      this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
      this.lead_gen_form.get('phone').updateValueAndValidity();
      this.phNumReqStatusDynamic = false;
      this.countryValueChange(val);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileScreen = (event.target.innerWidth < 1024) ? true : false;
  }

  // method to call just before opening the modal
  beforeOpen(event) {
    event.modalElement.parentElement.setAttribute('id', 'planVoyageSwalId');
  }

  // method to call while opening the modal
  onOpen(event) {
  }

  onClose(event) {
    const timeOnPage = (Date.now() - this.startTime) / 1000;
    this.gaTagService.trackBCModalClose(timeOnPage);
    this.openConfiguratorSwal.nativeSwal.close();
  }

  modalOpen() {
    this.startTime = Date.now();
    this.getHeadingData();
    this.getBookingConfigTerms();
    this.getInitialDrupalData();
    if (this.voyageId) {
      this.voyageDetailsApiCall();
      this.getPCFIToggleInfoFn();
    }
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    let path1 = this.currentUrlPathAsArray[0]?.path;
    let path2 = this.currentUrlPathAsArray[1]?.path;
    if (this.currentUrlPathAsArray.length == 0) {
      this.currentUrlPath = ''
    } else if (path1 && !path2) {
      this.currentUrlPath = path1;
    } else if (path1 && path2) {
      this.currentUrlPath = path1 + '/' + path2;
    }
    this.openConfiguratorSwal.show();
  }

  cancelClicked() {
    this.openConfiguratorSwal.nativeSwal.close();
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const isOutsideClick = !targetElement.closest('.phoneFlagOuter') && !targetElement.closest('.countryResultOuter');
    if (isOutsideClick) {
      this.flagCodeClickStatus = false
    }
  }

  /* ===================================================
       Function for get outside click of country list
     =======================================================*/
  onClickedOutside(e: Event) {
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
  }

  deepPhoneConfigurations() {
    let res: { formttedPhoneList: any, userCountryDetails: PhoneDataFormat, phoneDataArray: PhoneDataFormat[] } = deepPhoneConfiguration(this.phoneDataArray, this.formttedPhoneList);
    this.formttedPhoneList = res.formttedPhoneList;
    this.userCountryDetails = res.userCountryDetails;
    this.phoneDataArray = res.phoneDataArray;
    this.permanentGeoLocation = Object.assign({}, this.userCountryDetails);
    this.lead_gen_form.get('countryCode').setValue(this.userCountryDetails.code);
    this.lead_gen_form.get('dialCode').setValue(this.userCountryDetails.dial_code);
    this.mask = getMaskedCode(res.userCountryDetails);
  }

  getHeadingData() {
    const apiUrlB = '/api/v1/getPricing/header/b?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlB).subscribe(
      (res: any = []) => {
        if (res?.length) {
          this.bookingConfigHeader = res[0];
        }
      },
      err => {
        console.log('Section C Request Quote Api error');
        console.log(err);
      }
    );

    const apiUrl = "/api/v1/errormessages?_format=json";
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (response: any) => {
        if (response && response.length) {
          this.drupalErrorMessages = response[0];
        }
      },
      err => {
        console.log('Drupal Call Api error');
        console.log(err);
      }
    );
  }

  getBookingConfigTerms() {
    const apiUrlC = '/api/v1/requestcall/terms?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlC).subscribe(
      (res: any = []) => {
        if (res && res?.length > 0) {
          this.bookingConfigTerms = res[0];
          let message = this.bookingConfigTerms.field_term2.replace('<p>', "").replace("</p>", "");
          let newMessage = `<p> ${message}<span>*</span></p>`;
          this.bookingConfigTerms["field_term2_required"] = newMessage;
        }
      },
      err => {
        console.log('Section C Request Quote Api error');
        console.log(err);
      }
    );
  }

  /* ===================================================
   Function for select country click
  ======================================================*/
  selectCountryClick(item) {
    this.lead_gen_form.get('phone').setValue('');
    this.lead_gen_form.get('countryCode').setValue(item.code);
    this.lead_gen_form.get('dialCode').setValue(item.dial_code);
    this.userCountryDetails.dial_code = item.dial_code;
    this.userCountryDetails.code = item.code;
    this.userCountryDetails.flagUrl = item.flagUrl;
    this.userCountryDetails.ph_length = Number(item.ph_length);
    this.userCountryDetails.defaultFormat = item.defaultFormat;
    this.userCountryDetails.defaultPhoneNumber = item.defaultPhoneNumber;
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
    this.mask = getMaskedCode(item);
  }

  /* =======================================================================
   Funtion for creating token for request Quote (Generic / Specific)
 * ===========================================================================*/
  requestQuoteFormTokenFn() {
    this.rcycFormTokenService.formTokenService().subscribe(
      (res: any) => {
        if (res && res.code === 200 && res.data.logInfo) {
          this.requestQuoteFormToken = res.data.logInfo;
        }
      },
      err => {
        console.log('err  formTokenService Request Quote');
        console.log(err);
      }
    );
  }

  private setCommonData() {
    let phoneNumber;
    let dial_code: string = this.userCountryDetails.dial_code;
    if (!this.lead_gen_form.value.phone.replace(/ /g, "")) {
      phoneNumber = "";
    } else {
      phoneNumber = this.lead_gen_form.value.phone.replace(/ /g, "");
    }
    const salesForceRequestData: SalesForceFormData = {
      'lead_source': 'Website',
      'first_name': this.lead_gen_form.value.firstname.trim(),
      'last_name': this.lead_gen_form.value.lastname.trim(),
      'phone': phoneNumber,
      'dialCode': this.lead_gen_form.value.dialCode,
      'email': this.lead_gen_form.value.email.trim(),
      'zip': this.lead_gen_form.value.zipcode.trim(),
      'area': (this.userArea) ? this.userArea : '',
      'Phone_Opt_In__c': (this.lead_gen_form.value.isAgreeToContact === true) ? 'yes' : 'No Response',
      'Email_Opt_In__c': (this.lead_gen_form.value.isAgreeToReceiveMail === true) ? 'yes' : 'No Response',
    }
    salesForceRequestData['Lead_Type__c'] = 'Request a Quote - Configurator';
    salesForceRequestData['Voyage_Name__c'] = this.sf_SpecificVoyageName;
    salesForceRequestData['Voyage_Date__c'] = this.sf_SpecificVoyageDate;
    salesForceRequestData['Voyage__c'] = this.sf_SpecificVoyageCode;
    salesForceRequestData['Yacht_Name__c'] = this.yachtName;
    salesForceRequestData['country_code'] = this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '';
    this.rcycCommonService.setSessionData('backToLaunchPath', this.currentUrlPath);
    this.bookingConfigService.setBookingConfigData({ ...salesForceRequestData, ...{ APICallCounter: 0, currencyCode: this.currencyCode, voyageBasicData: this.voyageBasicData } });
  }

  configStart() {
    this.gaTagService.trackContinue('get_pricing_continue_click');
    this.setCommonData();
    this.bookingConfigService.setBookingConfigData({ bookingConfigRedirectURL: 'plan-your-voyage' });
    this.rcycCommonService.removeSessionData('bookingConfigSharedData');
    this.rcycCommonService.setSessionData('bookingConfigSharedData', this.bookingConfigService.getBookingConfigData());
    // this.router.navigate(['/plan-your-voyage']);
    this.bookingConfigService.submitLeadToSalesforce();
  }

  /* =====================================================================
   Function to call specific Voyage Details
 ========================================================================== */
  voyageDetailsApiCall() {
    let countryCode;
    if (this.geoApiResponse.country_code) {
      countryCode = this.geoApiResponse.country_code;
    } else {
      countryCode = 'US';
    }
    const apiUrlVoyage = '/api/voyage?voyageId=' + this.voyageId + '&countryCode=' + countryCode;
    this.rcycInternalApisService.getElastic(apiUrlVoyage).subscribe(response => {
      const res: any = response;
      if (res && res.code === 200 && res.data) {
        this.rescoVoyageCode = res.data.id;
        this.sf_SpecificVoyageName = res.data.voyageName;
        this.sf_SpecificVoyageDate = res.data.voyageStartDate.split('T')[0];
        this.sf_SpecificVoyageCode = res.data.voyageId;
      }
    }, err => {
      console.log('response Elastic Voyage Request Quote specific ERROR+++++');
      console.log(err);
    });
  }

  getUserAreaCode() {
    // Subscriber to get User Area
    this.rcycCommonService.userAreaCode.subscribe((userAreaDetails: any) => {
      if (userAreaDetails && userAreaDetails.length > 0) {
        const tempUserAreaDetails = userAreaDetails[0];
        this.userArea = tempUserAreaDetails.field_area_code;
      }
    });
  }

  /* ==================================================================================================================
    Function to get Area code on Country Select
  =====================================================================================================================*/
  getAreaCodeOnCountrySelect(cntrycode) {
    // console.log(cntrycode, 'ewtwwe');
    if (cntrycode) {
      let apiUrlD = '/api/v1/countryphone/' + cntrycode + '?_format=json';
      this.rcycHomeService.home(apiUrlD).subscribe(
        (response: any = []) => {
          this.rcycCommonService.userAreaDetails(response);
          this.getUserAreaCode();
        },
        err => {
          console.log('Error in Getting Area', err);
        }
      );
    }
  }

  countryValueChange(val) {
    let zipCodeValidation: any;
    this.zipcodeReqStatusDynamic = false;
    this.zipcodeMaxLength = 10;
    if (val) {
      this.getAreaCodeOnCountrySelect(val);
      this.userCountryDetails = Object.assign({}, this.formttedPhoneList[val]);
      this.userCountryDetails.ph_length = Number(this.formttedPhoneList[val].ph_length);
      this.lead_gen_form.get('phone').setValue('');
      this.lead_gen_form.get('countryCode').setValue(val);
      this.lead_gen_form.get('dialCode').setValue(this.formttedPhoneList[val].dial_code);
      this.selectCountryClick(this.formttedPhoneList[val]);
    }
    else {
      this.getAreaCodeOnCountrySelect(this.permanentGeoLocation.code);
      this.userCountryDetails = Object.assign({}, this.permanentGeoLocation);
      this.userCountryDetails.ph_length = Number(this.permanentGeoLocation.ph_length);
    }
    if (val === 'US') {
      zipCodeValidation = /^[0-9]{5}(?:-[0-9]{4})?$/;
      this.lead_gen_form.get('isAgreeToContact').setValue(false);
      this.lead_gen_form.get('isAgreeToReceiveMail').setValue(true);
      this.zipcodeReqStatusDynamic = true;
      this.showPhoneopt = false;
      this.showTermUS = true;
      this.lead_gen_form.get('phone').clearValidators();
      this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
      this.lead_gen_form.get('phone').updateValueAndValidity();
      this.phNumReqStatusDynamic = false;
    }
    else {
      this.showTermUS = false;
      if (val === 'CA') {
        zipCodeValidation = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        this.zipcodeReqStatusDynamic = true;
        this.zipcodeMaxLength = 7;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
        this.phNumReqStatusDynamic = false;
      }
      else if (val === 'AF') {
        this.zipcodeMaxLength = 4;
        zipCodeValidation = /^(1[0-9]|2[0-9]|3[0-9]|4[0-3])(0[1-9]|[1-9][0-9])$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if (val === 'PK') {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9][0-9]|[1-8][0-9]|9[0-8])([0-9]{3})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if (val === 'SD') {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if (val === 'ET') {
        this.zipcodeMaxLength = 4;
        zipCodeValidation = /^([1-9]{1})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if (val === 'IR') {
        this.zipcodeMaxLength = 10;
        zipCodeValidation = /\b(?!(\d)\1{3})[13-9]{4}[1346-9][0-9]{5}\b/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if (val === 'NG') {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if (val === 'YE') {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else if (val === 'IQ') {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
      }
      else {
        this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get('phone').updateValueAndValidity();
        this.phNumReqStatusDynamic = false;
      }
      this.lead_gen_form.get('isAgreeToContact').setValue(false);
      this.lead_gen_form.get('isAgreeToReceiveMail').setValue(false);
    }
    if (zipCodeValidation) {
      this.lead_gen_form.get('zipcode').setValidators([Validators.required, Validators.pattern(zipCodeValidation)]);
      this.lead_gen_form.get('zipcode').updateValueAndValidity();
    } else {
      this.lead_gen_form.get('zipcode').setValidators(null);
      this.lead_gen_form.get('zipcode').updateValueAndValidity();
    }
    this.phoneValueChangeFn();
  }

  phoneValueChangeFn() {
    if (this.lead_gen_form.value.phone) {
      if (this.lead_gen_form.value.isAgreeToContact = false) {
        this.showPhoneOptRequiredText = false;
      }
    } else {
      this.showPhoneOptRequiredText = false;
    }
  }

  phoneOptValueChanged() {
    if (this.lead_gen_form.value.isAgreeToContact) {
      this.lead_gen_form.get('phone').setValidators([Validators.required, ValidatePhoneNumber]);
      this.lead_gen_form.get('phone').updateValueAndValidity();
      this.phNumReqStatusDynamic = true;
      this.showPhoneOptRequiredText = false;
    }
    else {
      this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]);
      this.lead_gen_form.get('phone').updateValueAndValidity();
      this.phNumReqStatusDynamic = false;
      if (this.lead_gen_form.value.phone) {
        this.showPhoneOptRequiredText = true;
      }
    }
    this.lead_gen_form.get('phone').updateValueAndValidity();
  }

  /* ===================================================
     Function for zipcode format
   =======================================================*/
  checkZipcodeFormat(input) {
    let countryCode = this.lead_gen_form.get('country').value;
    const countryName = this.lead_gen_form.get('country').value;
    countryCode = this.countryListArray.filter(p => p.code === countryName)[0]?.code;
    if (countryCode && countryCode.toUpperCase() === "US") {
      if (isNaN(input.data)) {
        input.srcElement.value = input.srcElement.value.replace(input.data, '');
      }
      const position = input.target.selectionStart;
      let value = input.srcElement.value;
      value = value.replace(/-/g, '');
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), '-', value.slice(5)].join('');
      }
      this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      input.target.selectionStart = (position > 5) ? position + 1 : position;
      input.target.selectionEnd = (position > 5) ? position + 1 : position;
    } else if (countryCode && countryCode.toUpperCase() === "CA") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 3) {
        input.srcElement.value = [value.slice(0, 3), ' ', value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 7) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 7));
      }
    }
    else if (countryCode && countryCode.toUpperCase() === "AF") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 4) {
        input.srcElement.value = [value.slice(0, 4), ' ', value.slice(4)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 7) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 7));
      }
    }
    else if (countryCode && countryCode.toUpperCase() === "PK") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), ' ', value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 7) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 7));
      }
    }
    else if (countryCode && countryCode.toUpperCase() === "SD") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 3) {
        input.srcElement.value = [value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 7) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 7));
      }
    }
    else if (countryCode && countryCode.toUpperCase() === "ET") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 4) {
        input.srcElement.value = [value.slice(0, 4), ' ', value.slice(4)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 7) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 7));
      }
    }
    else if (countryCode && countryCode.toUpperCase() === "IR") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), value.slice(5)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 12) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 12));
      }
    }
    else if (countryCode && countryCode.toUpperCase() === "NG") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 3) {
        input.srcElement.value = [value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 7) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 7));
      }
    }
    else if (countryCode && countryCode.toUpperCase() === "YE") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 3) {
        input.srcElement.value = [value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 7) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 7));
      }
    }
    else if (countryCode && countryCode.toUpperCase() === "IQ") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(input.data, input.data.toUpperCase());
      }
      let value = input.srcElement.value;
      value = value.replace(' ', '');
      if (value.length > 3) {
        input.srcElement.value = [value.slice(0, 3), value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get('zipcode').value.length > 7) {
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0, 7));
      }
    }
    else {
      let value = input.srcElement.value.replace(/  /g, ' ');
      this.lead_gen_form.get('zipcode').setValue(value.replace(/--/g, '-'));
    }
  }

  onChange($event) {
    if ($event.target.value === 'AT' || $event.target.value === 'BE' || $event.target.value === 'BG' || $event.target.value === 'HR' || $event.target.value === 'CY' || $event.target.value === 'CZ' || $event.target.value === 'DK' || $event.target.value === 'EE'
      || $event.target.value === 'FI' || $event.target.value === 'FR' || $event.target.value === 'DE' || $event.target.value === 'GR' || $event.target.value === 'HU' || $event.target.value === 'IT' || $event.target.value === 'LV' || $event.target.value === 'LT'
      || $event.target.value === 'LU' || $event.target.value === 'MT' || $event.target.value === 'NL' || $event.target.value === 'PL' || $event.target.value === 'PT' || $event.target.value === 'RO' || $event.target.value === 'SK' || $event.target.value === 'SI'
      || $event.target.value === 'ES' || $event.target.value === 'SE' || $event.target.value === 'GB' || $event.target.value === 'CH' || $event.target.value === 'BH' || $event.target.value === 'IL' || $event.target.value === 'QA' || $event.target.value === 'TR'
      || $event.target.value === 'KE' || $event.target.value === 'MU' || $event.target.value === 'NG' || $event.target.value === 'ZA' || $event.target.value === 'UG' || $event.target.value === 'JP' || $event.target.value === 'KR' || $event.target.value === 'NZ'
      || $event.target.value === 'AR' || $event.target.value === 'BR' || $event.target.value === 'UY' || $event.target.value === 'CA' || $event.target.value === 'IE'
    ) {
      this.showPhoneopt = true;
    } else {
      this.showPhoneopt = false;
    }
    this.countryValueChange($event.target.value)
  }

  //Get the voyage details data from Drupal
  getVoyageInfo() {
    this.rcycVoyagesService
      .Voyages_basic(this.voyageId, this.userCountryDetails.code)
      .subscribe(
        (response: any) => {
          if (response && response.code === 200 && response.data) {
            if (response.data?.pcfiToggleStatus) {
              response.data.pcfiToggleStatus = false;
            }
            if (this.PCFIVoyageIdArray && this.PCFIVoyageIdArray.includes(response.data.voyageId)) {
              response.data.pcfiToggleStatus = true;
            }
            this.voyageBasicData = response.data;
            this.ports = [];
            this.voyageBasicData?.portCodes?.map(portId => {
              const port = this.voyageBasicData?.portData.filter(item => item.portCode === portId && item.portName !== 'Sailing Time')[0];
              if (port) {
                this.ports.push({
                  name: port.portName,
                  code: portId
                })
              }
            });
          }
        },
        err => {
          console.log(err, 'err voyageBasicData api in voyage page')
        }
      );
  }

  private getPCFIToggleInfoFn() {
    this.sharedServiceService.getPCFIToggleInfo().subscribe((res) => {
      if (res && res.field_voyages) {
        this.PCFIVoyageIdArray = res.field_voyages.replace(/\s/g, '').split(",");
      }
      this.getVoyageInfo();
    }, error => {
      this.getVoyageInfo();
      console.error(error);
    })
  }

  getInitialDrupalData() {
    this.rcycCommonService.drupalApisGet('/api/v1/voyages/' + this.voyageId + '/tab/itineraryoverview?_format=json').subscribe((response: any) => {
      if (response && response.length > 0) {
        this.voyageThumbImage = response[0].field_voyage_image;
      }
    }, err => {
      console.log(err);
    });
  }
}