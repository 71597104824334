import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDashboard from './rcyc-reservation.reducer';

export interface UserLoggedState {
    dashboard: fromDashboard.State;
}

export const reducers: ActionReducerMap<UserLoggedState> = {
    dashboard: fromDashboard.reducer,
};
export const getUserPortalDetails = createFeatureSelector<UserLoggedState>(
    'dashboard'
  );