import { Component, OnInit, AfterViewInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from '../../rcyc-config/config';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import * as EmailValidator from 'email-validator';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RcycFormTokenService } from '../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
// import { CookiesService } from '@ngx-utils/cookies';
import {CookieService} from 'ngx-cookie-service';
import { RcycHiddenSalesforceFormService } from '../../rcyc-services/rcyc-salesforce/rcyc-hidden-salesforce-form.service'
import { SalesForceFormData } from '../../rcyc-services/rcyc-salesforce/salesforce-form-data.interface';
declare let ga: Function;

@Component({
  selector: 'app-rcyc-special-offers',
  templateUrl: './rcyc-special-offers.component.html',
  styleUrls: ['./rcyc-special-offers.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class RcycSpecialOffersComponent implements OnInit, AfterViewInit {

    IMG_BASE_URL: any =   AppConfig.IMG_BASE_URL;
    salesForceImplementation:boolean = AppConfig.salesForceImplementation;
    // specialOffersDynamicDataArray: any;
    apiUrlA: any;
    apiUrlB: any;
    apiUrlC: any;
    apiUrlD: any;
    apiUrlE: any;

    apiResponseA: any;
    apiResponseB: any;
    apiResponseC: any;
    apiResponseD: any;
    apiResponseE: any;

    apiResponseATemp: any = [];
    apiResponseBTemp: any = [];
    apiResponseCTemp: any = [];
    apiResponseDTemp: any = [];
    apiResponseETemp: any = [];

    apiResponseAStatus = false;
    apiResponseBStatus = false;
    apiResponseCStatus = false;
    apiResponseDStatus = false;
    apiResponseEStatus = false;

    apiUrlMetatag: any;
    currentComponentName: any;

    eNewsletterSignUpData: any;
    signupMoreInfoClickStatus = false;
    fnameValidationMsg: any;
    fnameWhitespaceError = false;
    lnameValidationMsg: any;
    lnameWhitespaceError = false;
    emailValidationMsg: any;
    emailErr = false;
    countylist: any;
    countryErr = false;
    selectedCountryCode: any;
    apiUrl_eNewsLetterSignup: any;
    eNewsletterSignUp: any;
    eNewsletterSignupFormToken: any;
    eNewsletterSignupFormTokenTemp: any;
    eNewsletterSignupFormResponse: any;
    expandQueryParam: any;
    tokenMessage: any;
    offerFormStatus = false;
    drupalErrorMessages: any;
    sessionExpiredMessages:any;
    submitLoading:boolean = false;

    apiUrl_eNewsletterTerms: any;
    apiresponseENewsletterTemp: any;
    apiresponseENewsletter: any;
    apiresponseENewsletterStatus: any;
    formSubmitFailStatus = false;
    userAreaDetails: any;
    userArea = '';
    apiUrlF: any;
    apiResponseF: any;
    apiResponseFTemp: any = [];

    snapshotUrl: any;
    currentUrlPathAsArray: any;
    currentUrlPath: any;
    isSpecialOfferSignupStatus=false;
    specialCountryInputClickStatus=false;
    specialCountryInputCloseBtnStatus=false;
    isSpecialCountrySelect=false;
    selectCountryCode: string;
    apiResponseContentB: any={};
    apiResponseContentC: any
    specialOfferFormErrorMsg:string;

    constructor(

      private rcycCommonService: RcycCommonService,
      private router: Router,
      private formBuilder: UntypedFormBuilder,
      private http: HttpClient,
      private rcycFormTokenService: RcycFormTokenService,
      private activatedRoute: ActivatedRoute,
      private rcycInternalApisService: RcycInternalApisService,
      private rcycHomeService: RcycHomeService,
      private cookies: CookieService,
      private elementRef: ElementRef,
      private rcycHiddenSalesforceFormService : RcycHiddenSalesforceFormService,
    ) {

      this.rcycSpecialOffersConstructor();
    }

    ngOnInit() {

      this.rcycSpecialOffersOnInit();
      this.getDrupalErrorMessages();
    }

    ngAfterViewInit() {

      /* document.addEventListener("DOMContentLoaded", function() {

        document.querySelector(".bodyContentOuter .section-3 .offerSummery p").setAttribute("style", "color: #757575");
        console.log(document.querySelector(".bodyContentOuter .section-3 .offerSummery p"));
      });*/
    }

    /* =====================================================
      Function to call all constructor Function
    ======================================================== */
    rcycSpecialOffersConstructor() {

      this.snapshotUrl = this.activatedRoute.snapshot as any;
      this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
      this.currentUrlPath = this.currentUrlPathAsArray[0].path;
      if(this.currentUrlPath == 'specialoffers') {
        this.isSpecialOfferSignupStatus = true;
      }
      this.activatedRoute.queryParams.subscribe(params => {
        this.expandQueryParam = params['expand'];
      });
      this.eNewsletterSignupFormTokenFn();
      // Changing subheader A value
      // this.rcycCommonService.changeSubHeaderDataArray_A({});
      // Current subHeader Status
      if(this.isSpecialOfferSignupStatus){
        this.rcycCommonService.changeSubHeaderStatus(true);
        this.currentComponentName = 'RcycSpecialOffersComponent';
        // change changeCurrentComponent
        this.rcycCommonService.changeCurrentComponent(this.currentComponentName);
        if (this.expandQueryParam === 'true') {
          this.signupMoreInfoClickStatus = true;
        }
      }
           
    }

    /* =====================================================
      Function to call all OnInit Function
    ======================================================== */
    rcycSpecialOffersOnInit() {

      this.formSubmitValidation();
      this.rcycSpecialOffersContent();
       // Subscriber to get User Area
       this.rcycCommonService.userAreaCode.subscribe((userAreaDetails) => {
        if (userAreaDetails.length) {
          this.userAreaDetails = userAreaDetails[0];
          this.userArea = this.userAreaDetails.field_area_code;
        }
      });
    }

    /* =====================================================
      Function to call all initial special offers APIs
    ======================================================== */

    rcycSpecialOffersContent() {

      if(this.isSpecialOfferSignupStatus) {
        /****************** Section Metatag********************/
        this.apiUrlMetatag = '/api/v1/meta/offersandprograms?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

        /****************** Section A********************/
        this.apiUrlA = '/api/v1/banners/offersandprograms/a?_format=json';
        this.rcycInternalApisService.getDrupal(this.apiUrlA).subscribe(
          response => {

            this.apiResponseATemp = response;
            if ( this.apiResponseATemp && this.apiResponseATemp.length ) {

              // var res= Object.values(response);
              const res = Object.keys(response).map(function(key) {
                            return response[key];
                        });
              if ( res.length ) {

                this.apiResponseAStatus = true;
                this.apiResponseA = res[0];

                // Changing subheader A value
                this.rcycCommonService.changeSubHeaderDataArray_A(this.apiResponseA);
              } else {

                // Changing subheader A value
                this.rcycCommonService.changeSubHeaderDataArray_A([]);
              }
            }  else {

              // Changing subheader A value
              this.rcycCommonService.changeSubHeaderDataArray_A([]);
            }

          },
          err => {
            console.log('special Offers A err');
            console.log(err);
          }
        );
      
      
      /****************** Section B********************/
      this.apiUrlB = '/api/v1/offersandprograms/video/b?_format=json';
      this.rcycInternalApisService.getDrupal(this.apiUrlB).subscribe(
        response => {

          this.apiResponseBTemp = response;
          if ( this.apiResponseBTemp && this.apiResponseBTemp.length ) {

            const res = Object.keys(response).map(function(key) {
                          return response[key];
                      });

            const resArray = [];
            if ( res.length ) {

              for (const index of Object.keys(res) ) {
                const factor = res[index];

                if ( factor.field_images ) {

                  factor.field_images_srcset = this.IMG_BASE_URL + factor.field_images + ' 1600w, '
                  + this.IMG_BASE_URL + factor.field_image_1440px + ' 1000w, '
                  + this.IMG_BASE_URL + factor.field_image_768px + ' 800w, '
                  + this.IMG_BASE_URL + factor.field_image_375px + ' 500w';
                  factor.field_images_src1 = this.IMG_BASE_URL + factor.field_images;
                  factor.field_images_src2 = this.IMG_BASE_URL + factor.field_image_1440px;
                  factor.field_images_src3 = this.IMG_BASE_URL + factor.field_image_768px;
                  factor.field_images_src4 = this.IMG_BASE_URL + factor.field_image_375px;
                  factor.field_images = this.IMG_BASE_URL + factor.field_images;

                  const imgTitle = this.rcycCommonService.imageNameAltSplit( factor.field_images );

                  factor.altTextBannerImg = factor.title + ' ' + imgTitle;
                  factor.titleTextBannerImg = factor.title + ' ' + imgTitle;
                } else {

                  factor.altTextBannerImg = factor.title;
                  factor.titleTextBannerImg = factor.title;
                }

                resArray.push(factor);
                if ( parseInt ( index, 10 ) + 1 === res.length ) {

                  this.apiResponseB = resArray[0];
                  this.apiResponseBStatus = true;
                }
              }
            }
          }

        },
        err => {

          console.log('special Offers B err');
          console.log(err);
        }
      );

      /****************** Section C********************/
      this.apiUrlC = '/api/v1/offersandprograms/content/c?_format=json';
      this.rcycInternalApisService.getDrupal(this.apiUrlC).subscribe(
        response => {

          this.apiResponseCTemp = response;
          if ( this.apiResponseCTemp && this.apiResponseCTemp.length ) {

            const res = Object.keys(response).map(function(key) {
                          return response[key];
                      });
            if ( res.length ) {

              this.apiResponseC = res[0];
              this.apiResponseCStatus = true;
            }
          }

        },
        err => {

          console.log(' special Offers C err');
          console.log(err);
        }
      );

      /****************** Section D********************/
      this.apiUrlD = '/api/v1/offers?_format=json';
      this.rcycInternalApisService.getDrupal(this.apiUrlD).subscribe(
        response => {

          this.apiResponseDTemp = response;
          if ( this.apiResponseDTemp && this.apiResponseDTemp.length ) {

            const res = Object.keys(response).map(function(key) {
                          return response[key];
                      });
            const resArray = [];
            if (res.length) {
              for (const index of Object.keys(res)) {

                const factor = res[index];
                if ( factor.field_image ) {
                  factor.field_image = this.IMG_BASE_URL + factor.field_image;
                  const imgTitle = this.rcycCommonService.imageNameAltSplit( factor.field_image );
                  factor.altTextSOImg = factor.title + ' ' + imgTitle;
                  factor.titleTextSOImg = factor.title + ' ' + imgTitle;
                } else {
                  factor.altTextSOImg = factor.title;
                  factor.titleTextSOImg = factor.title;
                }
                const offerkey = factor.field_url_key.toLowerCase();
                // factor.offerUrl_new = '/offer-' + offerkey + '-program';
                factor.offerUrl_new = '/'+offerkey;
                let voyageIdArray = [];
                if ( factor.field_voyages ) {
                  voyageIdArray = factor.field_voyages.split('###');
                  factor.voyageCount = voyageIdArray.length;
                }
                resArray.push( factor );
                if ( parseInt ( index, 10 ) + 1 === res.length ) {
                  this.apiResponseD = resArray;
                  this.apiResponseDStatus = true;
                }

              }
            }

          }

        },
        err => {
          console.log('special Offers D err');
          console.log(err);
        }
      );

      /****************** Section E********************/
      this.apiUrlE = '/api/v1/offersandprograms/content/e?_format=json';
      this.rcycInternalApisService.getDrupal(this.apiUrlE).subscribe(
        response => {

          this.apiResponseETemp = response;
          if ( this.apiResponseETemp && this.apiResponseETemp.length ) {

            const res = Object.keys(response).map(function(key) {
                          return response[key];
                      });
            if ( res.length ) {
              this.apiResponseE = res[0];
              this.apiResponseEStatus = true;
            }
          }

        },
        err => {
          console.log('special Offers E err');
          console.log(err);
        }
      );
    }

    this.apiUrl_eNewsletterTerms = '/api/v1/offersandprograms/terms?_format=json';
    this.rcycInternalApisService.getDrupal(this.apiUrl_eNewsletterTerms).subscribe(
      response => {

        this.apiresponseENewsletterTemp = response;
        if (this.apiresponseENewsletterTemp && this.apiresponseENewsletterTemp.length) {

          const res = Object.keys(response).map(function(key) {
                        return response[key];
                    });

          // this.brochureFooterContent=res[0].field_summery;
          if (res.length) {

            this.apiresponseENewsletter = res[0];
            this.apiresponseENewsletterStatus = true;
          }
        }
      },
      err => {
        console.log('err  apiUrl_E');
        console.log(err);
      }
    );

    /****************** Section content C********************/
    const apiUrlContentB = '/api/v1/offersandprograms/content/b?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlContentB).subscribe(
      (response:any=[]) => {        
        if (response&&response.length) {
          this.apiResponseContentB = response[0];         
        }
      },
      err => {
        console.log(' special Offers B Content err');
        console.log(err);
      }
    );

    /****************** Section content D********************/
    const apiUrlContentC = '/api/v1/infomessages?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlContentC).subscribe(
      (response:any=[]) => {        
        if (response&&response.length) {
          this.apiResponseContentC = response[0].field_cta_text;      
        }
      },
      err => {
        console.error(err);
      }
    );


    }

    /* =====================================================
      Click Function for More Info of e-newsletter signup
    ======================================================== */
    signupMoreInfoClick() {

      this.signupMoreInfoClickStatus = !this.signupMoreInfoClickStatus;

    }

    /* =====================================================
      Click Function countryselect -Select country name from country region
    ======================================================== */
    countryselect() {
      const countryName = this.eNewsletterSignUp.value.country;
      if (countryName && countryName !== '0') {
        this.selectedCountryCode = this.countylist.filter(p => p.name === countryName)[0].code;
        this.countryErr = false;
      } else {
        this.countryErr = true;
      }
      if (this.selectedCountryCode) {        
        this.getAreaCodeOnCountrySelect(this.selectedCountryCode);
      }      
    }

    /* =====================================================
     Function to validate form
    ======================================================== */
    formSubmitValidation() {

      const emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
      this.emailErr = false;
      
      if(!this.isSpecialOfferSignupStatus) {
        this.eNewsletterSignUp = this.formBuilder.group({

          firstName: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
          lastName: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
          // country: ['0', Validators.required],
          email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$')] ],
          country: ['', Validators.required],
        });

        this.eNewsletterSignUp.valueChanges.subscribe((val) => {
          this.specialOfferFormErrorMsg='';
          this.formSubmitFailStatus = false;
        });
        this.eNewsletterSignUp.get('country').valueChanges.subscribe((val) => {

          // Editing country field
          this.selectedCountryCode='';
          this.countryErr = true;
          this.specialCountryInputClickStatus=true;
          this.specialCountryInputCloseBtnStatus=true
          this.isSpecialCountrySelect=false;
        });
  
      } else {
       
        this.eNewsletterSignUp = this.formBuilder.group({

          firstName: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
          lastName: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
          country: ['0', Validators.required],
          email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$')] ]
        });

        this.eNewsletterSignUp.valueChanges.subscribe((val) => {          
          this.formSubmitFailStatus = false;
        });
  
      }

      this.http.get('./assets/countrylist.json').subscribe(countrydata => {
        this.countylist = countrydata;
      });

    }

    /* =====================================================
     Function to clear form
    ======================================================== */
    clearProfileUpdateForm() {

      this.eNewsletterSignUp = this.formBuilder.group({

        firstName: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        lastName: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        country: ['0', Validators.required],
        email: ['', [Validators.required, Validators.email]]
      });
      this.fnameValidationMsg = '';
      this.lnameValidationMsg = '';
      this.emailValidationMsg = '';
      this.fnameWhitespaceError = false;
      this.lnameWhitespaceError = false;
      this.emailErr = false;
      this.countryErr = false;

    }

    /* =====================================================
     Function to Submit e-newsletter signup form
    ======================================================== */
    eNewsletterSignupSubmitApiCall() {

      this.submitLoading = true;
      this.apiUrl_eNewsLetterSignup = '/secureleadapi/e-newsletter';
      /****************** Section Ebrochure Submission********************/
      const data = {
        'firstName' : this.eNewsletterSignUp.value.firstName,
        'lastName' : this.eNewsletterSignUp.value.lastName,
        'country' : this.selectedCountryCode,
        'email' : this.eNewsletterSignUp.value.email,
        'area' : this.userArea,
        'utmSource': this.cookies.get('utm_source'),
        'utmMedium': this.cookies.get('utm_medium'),
        'utmCampaign': this.cookies.get('utm_campaign'),
        'internalSource': this.cookies.get('int_source'),
        'internalCampaign': this.cookies.get('int_campaign'),
        'folId': this.cookies.get('fol_num'),
        'GCLID': this.cookies.get('gclid'),
        'FBCLID': this.cookies.get('fbcild'),
      };
      // if(!this.isSpecialOfferSignupStatus) {
      //   data['country']=this.selectedCountryCode;       
      // } else {
      //   data['country']=this.eNewsletterSignUp.value.country;
      // }

      this.rcycInternalApisService.postSecureEbrochureForm(this.apiUrl_eNewsLetterSignup, data, this.eNewsletterSignupFormToken).subscribe(
        response => {
          if (!this.salesForceImplementation) {
            this.submitLoading = false;
            this.eNewsletterSignupFormResponse = response;
            const res = this.eNewsletterSignupFormResponse;

            if (res && res.code === 200) {
              let leadId = (res.data && res.data.leadId) ? res.data.leadId : '';
              this.router.navigate(['/complete'], { queryParams: { success: 'sosignup','lead_id':leadId } });
              ga('create', 'UA-114428670-1', 'auto');
              ga('require', 'ecommerce', 'ecommerce.js');
              ga('ecommerce:addItem', {
                'id': leadId,
                'name':'sosignup'
              });
              ga('ecommerce:send');
              ga('send','pageview')
            } else if ( res && ( res.code === 403 || res.code === 401 || res.code === 400 || res.code === 500) ) {

              this.formSubmitFailStatus = true;
              if (res && res.status == 407) {

                this.offerFormStatus = true;
                this.formSubmitFailStatus = false;
                this.tokenMessage = this.rcycFormTokenService.tokenErrorMsg;
                this.sessionExpiredMessages=this.drupalErrorMessages.field_form_session_expired;
              }
              this.eNewsletterSignupFormTokenFn();
              window.scrollTo(0, 0);
            }
          }
        }, err => {
          console.log(err);
          // this.succmsg = false;
        }
      );

      if (this.salesForceImplementation) {      
        // Salesforce Web to Lead - W2L START

        const salesForceRequestData: SalesForceFormData = {
          'lead_source' : 'Website',
          'Lead_Type__c' : 'Special Offers Sign-Up',
          'first_name' : this.eNewsletterSignUp.value.firstName,
          'last_name' : this.eNewsletterSignUp.value.lastName,
          'country_code' : this.selectedCountryCode,
          'email' : this.eNewsletterSignUp.value.email,
          'area' : (this.userArea) ? this.userArea : '',
          'UTM_Source__c' : this.cookies.get('utm_source'),
          'UTM_Medium__c' : this.cookies.get('utm_medium'),
          'UTM_Campaign__c' : this.cookies.get('utm_campaign'),
          'INT_Source__c' : this.cookies.get('int_source'),
          'INT_Campaign__c' : this.cookies.get('int_campaign'),
          'GCLID__c': this.cookies.get('gclid'),
          'FBCLID__c': this.cookies.get('fbcild'),
          }
        
        this.rcycHiddenSalesforceFormService.generateFormW2L(salesForceRequestData, 'sosignup');

        // Salesforce Web to Lead - W2L END
      }
    }

    /* ========================================================
    Funtion for creating token for e-newsletter signup
  * ========================================================*/
  eNewsletterSignupFormTokenFn() {

    this.rcycFormTokenService.formTokenService().subscribe(
      response => {

        this.eNewsletterSignupFormTokenTemp = response;
        const res = this.eNewsletterSignupFormTokenTemp;

        if (res && res.code === 200 && res.data.logInfo) {

          this.eNewsletterSignupFormToken = res.data.logInfo;
        }
      },
      err => {

        console.log('err  formTokenService E-BROCHURE');
        console.log(err);
      }
    );
  }

  // Function to Clear Voyage Compare Data
  clearCompareValue() {
    this.rcycCommonService.removeData('findACruiseSearchCriteria');
    this.rcycCommonService.removeData('voyageCompareDetails');
    this.rcycCommonService.removeData('compareButtonPressed');
  }

  /* ==================================================================================================================
    Function to get Area code on Country Select
  =====================================================================================================================*/
  getAreaCodeOnCountrySelect(cntrycode) {
    if (cntrycode) {
      this.apiUrlF = '/api/v1/countryphone/' + cntrycode + '?_format=json';
      this.rcycHomeService.home(this.apiUrlF).subscribe(
        response => {
          this.apiResponseFTemp = response;
          console.log(response, 'response');
          this.rcycCommonService.userAreaDetails(response);
        },
        err => {
          console.log('Error in Getting Area', err);
        }
      );
    }
  }
  specialCountryLiClick(item) {   
    this.eNewsletterSignUp.get('country').setValue(item.name);
    this.specialCountryInputClickStatus=false;
    this.specialCountryInputCloseBtnStatus=false;
    this.isSpecialCountrySelect=true;
    this.countryselect();
  }
  specialCountryInputCloseClick() {
    this.eNewsletterSignUp.get('country').setValue('');
    this.specialCountryInputCloseBtnStatus=false;    
  }

  eNewsletterSignupSubmit() {
    this.specialOfferFormErrorMsg='';
    this.offerFormStatus = false;
    let honeyField = <HTMLInputElement> document.getElementById('thirdNameId');
    if(this.isSpecialOfferSignupStatus && !honeyField.value) {
      this.eNewsletterSignupSubmitApiCall();
    } else {
      if(
        this.eNewsletterSignUp.controls.firstName.invalid||
        this.eNewsletterSignUp.controls.lastName.invalid||
        this.eNewsletterSignUp.controls.email.invalid||
        this.eNewsletterSignUp.controls.country.invalid||
        !this.userArea||
        !this.isSpecialCountrySelect
      ) {
        this.specialOfferFormErrorMsg='Please fill all mandatory fields';
        // setTimeout(() => {
        //   const invalidControl = this.elementRef.nativeElement.querySelector('.error-borderBtm');           
        //   if (invalidControl) {            
        //     invalidControl.focus();
        //   }
        // }, 500);
        document.querySelector('.page-title').scrollIntoView();
      } else {
        this.eNewsletterSignupSubmitApiCall();
      }
    }
  }
  
  getDrupalErrorMessages() {
    const apiUrl = "/api/v1/errormessages?_format=json";
    this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
      (response:any) => {
        if (response && response.length) {   
          this.drupalErrorMessages = response[0];
        }
      },
    );
  }
}
