<div id="shore-ex">
    <!-- Section - 1 -->
    <app-rcyc-hero-module *ngIf="bannerData" [responseArray]="bannerData" [pageName]="'destinations'">
    </app-rcyc-hero-module>

    <!-- 50-50 Component Data not available-->
    <div *ngIf="apiResponseB" class="py-10 lg:py-16 overflow-x-hidden">
        <app-rcyc-50-50 [data]="apiResponseB"></app-rcyc-50-50>
    </div>

    <!-- Excursions Component-->
    <div *ngIf="excursionsData" class="container py-10 lg:py-16 !pl-[0px]">
        <app-rcyc-slider variant="full_image" id="full_image_slider" [pageName] = "'shore-ex'" [data]="{
            topContent: {
                title: excursionsData[0]?.title,
                header_variant: 'full_image',
                subHeader: excursionsData[0]?.field_com_eyebrow
            },
                items: excursionsData
            }
        "></app-rcyc-slider>
    </div>

    <!-- Benefits of Booking Cards -->
    <div class="container py-10 lg:py-16" *ngIf="bookingBenefits">
        <div>
            <div class="uppercase mb-4 brochure-content text-[14px] font-normal tracking-[0.168px] lg:tracking-[115%] leading-[165%] font-['Proxima Nova']" *ngIf="bookingBenefits?.component?.eyebrow"
                [innerHTML]="bookingBenefits?.component?.eyebrow">
            </div>
            <h2 *ngIf="bookingBenefits?.component?.title" [innerHTML]="bookingBenefits?.component?.title"
                class="mb-9 lg:[&+.field-content]:-mt-2 text-[29px] lg:text-[49px] font-normal tracking-[0.348px] leading-[115%] font-['Adobe Caslon Pro']">
            </h2>
        </div>
        <div class="lg:gap-x-12 gap-y-12 lg:gap-y-16 grid lg:grid-cols-3">
            <app-rcyc-offer-card variant="shore-ex" *ngFor="let item of bookingBenefits?.items; let isLast = last"
                [data]="item" [index]="isLast"></app-rcyc-offer-card>                
        </div>
    </div>

    <!-- FAQ -->
    <div class="container py-10 lg:py-16" id="faq-section" *ngIf="faqData">
        <div class="uppercase mb-6 brochure-content text-[14px] font-normal tracking-[0.168px] leading-[165%] font-['Proxima Nova']" *ngIf="faqData[0]?.field_com_eyebrow"
            [innerHTML]="faqData[0]?.field_com_eyebrow">
        </div>
        <h2 [innerHTML]="faqData[0]?.title"
            class="mb-12 lg:[&+.field-content]:-mt-2 text-[29px] lg:text-[49px] font-normal tracking-[0.348px] lg:tracking-[0.588px] leading-[115%] font-['Adobe Caslon Pro']">
        </h2>
        <div class="flex flex-col gap-6">
            <div *ngFor="let tab of faqData" class="flex flex-col w-full"
                (click)="toogleTab(tab?.field_sort_order)">
                <div *ngIf="tab?.field_accordion_title" class="flex justify-between items-center cursor-pointer">
                    <div class="accordion-title text-[20px] font-[600] tracking-[0.24px] lg:py-[16px] font-['Proxima Nova']" [innerHTML]="tab.field_accordion_title">
                    </div>
                    <div class="min-w-[18px] w-[18px] h-[18px] lg:w-[26px] lg:h-[26px]
                  top-[16px] lg:top-[10px] right-[8px] lg:right-[24px] text-[10px] leading-none">
                        <img *ngIf="!isOpened(tab.field_sort_order)" src="assets/img/plus_icon.svg" alt="">
                        <img *ngIf="isOpened(tab.field_sort_order)" src="assets/img/Minus_icon.svg" alt="">
                    </div>
                </div>
                <div *ngIf="tab?.field_accordion_details" [ngClass]="{
              'max-h-0 opacity-0 overflow-hidden': !isOpened(tab.field_sort_order),
              'opacity-100 overflow-visible': isOpened(tab.field_sort_order)
            }" class="transition-[max-height] duration-3000 ease-in-out">
                    <div class="my-4 mr-[40px] listItem text-[16px] font-normal tracking-[0.192px] leading-[165%] font-['Proxima Nova']"
                        [innerHTML]="tab?.field_accordion_details">
                    </div>
                </div>
                <div *ngIf="tab?.field_accordion_details" class="border-solid border-t border-[#646464]"></div>
            </div>
        </div>
    </div>

    <!-- Slider Editorial -->
    <div *ngIf="articlesData" class="py-10 lg:py-16">
        <app-rcyc-slider variant="editorial" id="home-editorial-slider" [data]="{
            topContent: {
              eyebrow: articlesData[0]?.field_com_eyebrow,
              title: articlesData[0]?.title | convertHtml,
            },
            items: articlesData
          }"></app-rcyc-slider>
    </div>
</div>