import { Component, HostListener, Input, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';
import { RcycEditorialHubArticleService } from './rcyc-editorial-hub-article.service';
import { AppConfig } from '../../rcyc-config/config';
import { switchMap, of, first } from 'rxjs';

@Component({
  selector: 'app-rcyc-editorial-hub-article',
  templateUrl: './rcyc-editorial-hub-article.component.html',
  styleUrls: ['./rcyc-editorial-hub-article.component.css']
})
export class RcycEditorialHubArticleComponent {

  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  IMG_CDNHOST_URL: any = AppConfig.IMG_CDNHOST_URL;

  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlKey: any;

  apiUrlMetatag: any;
  apiUrlA: any;
  apiUrlABCD: any;
  apiUrlBanner: any;
  apiUrlCarouselImages: any;

  geoApiResponse: any;
  apiResponseA: any;
  apiResponseABCD: any;
  apiResponseBanner: any;
  apiResponseCarouselImages: any;

  apiResponseAStatus: boolean = false;
  apiResponseABCDStatus: boolean = false;
  apiResponseBannerStatus: boolean = false;
  carouselImagesAvailable: boolean = false;

  splitVoyageIdArray: any = [];
  apiUrlMultipleVoyages: any;
  voyageDetails: any;
  voyageDetailsStatus: boolean = false;
  loadedItems: any;
  maxEachDDFHeight: number = 0;
  maxEachVoyagenameHeight: number = 0;
  initialDrupalCall: boolean = true;
  drupalImageArray: any = [];
  private allItemsOP: any = [];
  featuredVoyage: any;
  featuredVoyageDetails: any[] = [];
  videoArray: any = [];
  videoBlockAvailable: boolean = false;
  carouselImagesArray: any = [];
  storyDisplayCount: any = 8;
  storyTotalCount: any;
  showLoadMoreButton: boolean = false;
  compareCheckboxArray: any = [];
  viewingVoyageId: string;
  PCFIToggleInfo: any = {};
  PCFIVoyageIdArray: any = [];
  infoCommonMsg: any;
  currencyFieldMap = {
    USD: 'field_usd_additional_cost_info',
    USM: 'field_usm_additional_cost_info',
    EUR: 'field_eur_additional_cost_info',
    GBP: 'field_gbp_additional_cost_info',
  };

  @Input() content: any;
  additionalCostInfo: any;
  currencyCode: any;
  featuredTripMobilePosition: boolean;
  featuredTripOriginalPosition: boolean;
  details: any;
  staticDrupalTexts: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private rcycCommonService: RcycCommonService,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycEditorialHubArticleService: RcycEditorialHubArticleService,
    private _SharedServiceService: SharedServiceService,
    private renderer: Renderer2,
    private router: Router,

  ) {
    this.getCurrentUrlKey();
    this.getUserDetails();
  }

  ngOnInit() {
    this.getInfoCommonMsg();
    this.getEditorialArticleDrupalContent();
    this.handleFeaturedTripPosition();
    this.getPCFIToggleInfoFn();
    this.getStaticDrupalData();
  }

  /**
   * Get Current URL key from route
   */
  getCurrentUrlKey() {
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlKey = this.currentUrlPathAsArray[0].path;
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('window:resize', ['$event']) // Listen for both scroll and resize events
  onScrollOrResize(event: Event) {
    this.handleFeaturedTripPosition() // Call the function on scroll or resize
  }

  /**
   * Get user details
   */
  getUserDetails() {
    if (!this.rcycCommonService.getData('UserDetails')) {
      this.rcycCommonService.getUser_Details().subscribe(
        response => {
          this.geoApiResponse = response;
        },
        err => {
          console.log('Geo SOL APi err');
        }
      );
    } else {
      this.geoApiResponse = this.rcycCommonService.getData('UserDetails');
    }
  }

  /**
   * Get The editorial hub landing page contentfrom drupal
   */
  getEditorialArticleDrupalContent() {
    /****************** Section Metatag********************/
    this.apiUrlMetatag = "/api/v1/meta/" + this.currentUrlKey + "?_format=json";
    this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

    /****************** Section Banner Image *******************/
    this.apiUrlBanner = '/api/v1/banners/editorialarticle/' + this.currentUrlKey + '/b?_format=json';
    this.rcycInternalApisService.getDrupal(this.apiUrlBanner).subscribe({
      next: (response: any = []) => {
        if (response.length) {
          this.apiResponseBanner = response;
          this.apiResponseBannerStatus = true;
        }
      },
      error: (error) => {
        console.log("Editorial Hub Api B error");
        console.log(error);
      },
    });

    /****************** Drupal Api for Editorial Hub headings *******************/
    if (this.content) {
      this.apiResponseABCD = this.content;
      this.apiResponseABCDStatus = true;
      // format drupal details of article
      this.formatEditorialArticleDetails(this.apiResponseABCD, this.content);
    }

    /****************** Drupal Api for Editorial Article Carousel Images *******************/
    this.carouselImagesArray = []
    this.apiUrlCarouselImages = '/api/v1/editorialarticle/images/' + this.currentUrlKey + '?_format=json';
    this.rcycInternalApisService.getDrupal(this.apiUrlCarouselImages).subscribe({
      next: (res: any = []) => {
        if (res && res.length) {
          this.apiResponseCarouselImages = res[0];
          this.carouselImagesAvailable = true;
          this.carouselImagesArray = res;
        }
      },
      error: (error) => {
        console.log(error, 'err Editorial Hub form carousel images');
      }
    });
  }

  /**
   * Function to format the article details
   * @param editorialContent Drupal content for the article
   */
  formatEditorialArticleDetails(editorialContent, res) {
    if (this.apiResponseABCDStatus && editorialContent) {
      const resArray = [];
      let splitVoyageIds;

      // set Article header
      if (this.apiResponseABCDStatus && this.apiResponseABCD) {
        // Changing pageheader value      
        this.rcycCommonService.changeSubHeaderDataArray_A({ title: this.apiResponseABCD.field_header_title });
        this.rcycCommonService.changeSubHeaderStatus(true);
      } else {
        // Changing pageheader value
        this.rcycCommonService.changeSubHeaderDataArray_A([]);
      }

      //format voyages from drupal
      for (const index of Object.keys(res)) {
        const factor = res[index];
        const voyageIdArray = [];
        if (factor.field_image) {
          factor.field_image = this.IMG_BASE_URL + factor.field_image;
          const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_image);
          factor.altTextFloorPlanImg = factor.title + ' ' + imgTitle;
          factor.titleTextFloorPlanImg = factor.title + ' ' + imgTitle;
        } else {
          factor.altTextFloorPlanImg = factor.title;
          factor.titleTextFloorPlanImg = factor.title;
        }
        if (factor.field_voyages) {
          splitVoyageIds = factor.field_voyages;
          splitVoyageIds = splitVoyageIds.split('###');
          this.splitVoyageIdArray = splitVoyageIds;
          factor.voyageCount = voyageIdArray.length;
        }
        if (factor.field_voyage) {
          this.featuredVoyage = factor.field_voyage;
        }
        resArray.push(factor);
        if (factor.field_video) {
          this.videoArray = resArray;
          this.videoArray[0].field_image = '';
          this.videoBlockAvailable = true;
        } else {
          this.videoBlockAvailable = false;
        }
        if (parseInt(index, 10) + 1 === editorialContent.length) {
          this.apiResponseABCD = resArray;
          this.apiResponseABCD.sort((a, b) => a.field_sort_order - b.field_sort_order)
          this.details = this.apiResponseABCD.splice(1)
        }
        if (this.splitVoyageIdArray.length) {
          this.multipleVoyageElasticApiCall();
        }
      }
    }
  }

  /**
   * Function to call multiple voyages elastic api call
   */
  multipleVoyageElasticApiCall() {
    this.apiUrlMultipleVoyages = '/api/voyage/list';
    let params;
    let tempVoyages = this.splitVoyageIdArray;
    if (this.featuredVoyage) {
      tempVoyages.push(this.featuredVoyage)
    }
    const geoCountryCode = this.geoApiResponse;
    if (geoCountryCode) {
      params = {
        apiUrl: this.apiUrlMultipleVoyages,
        countryCode: geoCountryCode.country_code,
        voyageIds: tempVoyages
      };
    } else {
      params = {
        apiUrl: this.apiUrlMultipleVoyages,
        countryCode: 'US',
        voyageIds: tempVoyages
      };
    }
    this.rcycEditorialHubArticleService.multipleVoyageElasticApi(params).subscribe(
      response => {
        this.voyageDetails = response;
        if (this.voyageDetails && this.voyageDetails.code === 200) {
          const res = this.voyageDetails.data;
          if (res.length) {
            const resArray = [];
            for (const index of Object.keys(res)) {
              const factor = res[index];
              const voyageEmbarkPort_new = factor.voyageEmbarkPort.replace(/[^a-zA-Z-]/g, '').replace(/\s/g, '').toLowerCase();
              const voyageDisembarkPort_new = factor.voyageDisembarkPort.replace(/[^a-zA-Z-]/g, '').replace(/\s/g, '').toLowerCase();
              factor.itineraryUrl = '/' + factor.voyageUrlPath + '/' +
                voyageEmbarkPort_new + '-to-' + voyageDisembarkPort_new +
                '-' + factor.voyageId;
              factor.voyageUrl_new_offer = '/' + factor.voyageUrlPath + '/' +
                voyageEmbarkPort_new + '-to-' + voyageDisembarkPort_new +
                '-' + factor.voyageId + '/offers-programs';
              factor.voyageImagePath = this.IMG_CDNHOST_URL + '/thumbnails/' + factor.voyageId + '.jpg';
              factor.nights = factor.nights;
              factor.altTextVoyageImg = factor.voyageName + ' ' + factor.voyageId;
              factor.titleTextVoyageImg = factor.voyageName + ' ' + factor.voyageId;

              factor.pcfiToggleStatus = false;
              if (this.PCFIVoyageIdArray.includes(factor.voyageId)) {
                factor.pcfiToggleStatus = true;
              }

              if (factor.voyageId) {
                factor.callOutText = this.rcycCommonService.getOffersVoyageSortedOutField(factor.voyageId);
              }
              resArray.push(factor);
              if (parseInt(index, 10) + 1 === res.length) {

                this.voyageDetailsStatus = true;
                this.allItemsOP = resArray;
                if (this.initialDrupalCall) {
                  this.getCruiseImageDrupal();
                }
                const prevUrlForBackButton = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
                if (prevUrlForBackButton) {
                  const specialOfferPageDetails = this.rcycCommonService.getSessionData('specialOfferPageDetails');
                  let pager;
                  if (specialOfferPageDetails && specialOfferPageDetails.specialOffersPagePager) {
                    pager = specialOfferPageDetails.specialOffersPagePager;
                  }
                  if (pager) {
                    let sortDetail;
                    if (specialOfferPageDetails && specialOfferPageDetails.specialOffersPageSortOrder) {
                      sortDetail = specialOfferPageDetails.specialOffersPageSortOrder;
                    }
                    this.rcycCommonService.removeData('specialOffersPagePager');
                  }
                }
                if (window.innerWidth > 560) {
                  let timeOut;
                  clearTimeout(timeOut);
                  timeOut = setTimeout(() => {
                    this.eachOuterOfferLandingHeightAlign();
                  }, 3000);
                }
              }
            }
          }
        }
      },
      err => {
        console.log(' Editorial hub Multiple voyage Elastic api call err');
        console.log(err);
      }
    );
  }

  eachOuterOfferLandingHeightAlign() {
    let maxEachVoyagenameHeight: number = 0;
    if (document.querySelectorAll('.gridView .voyageName')) {
      Array.from(document.querySelectorAll('.gridView .voyageName')).forEach(
        function (el) {
          if (el.scrollHeight > maxEachVoyagenameHeight) {
            maxEachVoyagenameHeight = el.scrollHeight;
          }
        }
      );
      this.maxEachVoyagenameHeight = maxEachVoyagenameHeight;
    }

    let maxEachDDFHeight: number = 0;
    if (document.querySelectorAll('.gridView .eachRow-3')) {
      Array.from(document.querySelectorAll('.gridView .eachRow-3')).forEach(
        function (el) {
          if (el.scrollHeight > maxEachDDFHeight) {
            maxEachDDFHeight = el.scrollHeight;
          }
        }
      );
      this.maxEachDDFHeight = maxEachDDFHeight;
    }
  }

  /**
   * Function to get Drupal Images
   */
  getCruiseImageDrupal() {
    let drupalImageArray: any = this.rcycCommonService.getSessionData('drupalImageArray');
    if (drupalImageArray) {
      this.filterByVoyageId();
    } else {
      const Api_Url = '/api/v1/voyages/thumbnails?_format=json';
      this.rcycInternalApisService.getDrupal(Api_Url).subscribe(
        (response) => {
          this.rcycCommonService.setSessionData('drupalImageArray', response);
          this.filterByVoyageId();
        },
        (err) => {
          console.log('Drupal image getting Error');
          console.log(err);
        }
      );
    }
  }

  /**
   * Function to filter based on voyageId
   */
  filterByVoyageId() {
    this.drupalImageArray = this.rcycCommonService.getSessionData('drupalImageArray');
    if (this.drupalImageArray.length) {
      this.initialDrupalCall = false;
    }

    if (this.allItemsOP.length && this.drupalImageArray.length) {
      this.allItemsOP.filter((item, index1) => {
        this.drupalImageArray.filter((data) => {
          if (item.voyageId === data.field_voyages) {
            // Set drupal image url
            this.allItemsOP[index1].voyageImagePath = data.field_voyage_thumbnail;
            this.allItemsOP[index1].imageMode = 'photo';
          }
        });
      });

      // Find the index of the object with the matching voyageId
      const index = this.allItemsOP.findIndex((item) => item.voyageId === this.featuredVoyage);
      if (index !== -1) {
        // Use splice to remove the object from the array and store it in featuredVoyageDetails
        let tempFeaturedVoyage, isVoyagePresent;
        if (this.featuredVoyageDetails && this.featuredVoyageDetails.length > 0) {
          isVoyagePresent = this.featuredVoyageDetails.findIndex(item => {
            tempFeaturedVoyage = this.allItemsOP.splice(index, 1)[0];
            return item.voyageId === tempFeaturedVoyage.voyageId;
          })
          if (isVoyagePresent === -1) {
            this.featuredVoyageDetails.push(tempFeaturedVoyage);
          }
        } else
          this.featuredVoyageDetails.push(this.allItemsOP.splice(index, 1)[0]);

        this.currencyCode = this.featuredVoyageDetails[0]?.feeCurrency;
        this.setAdditionalCostInfo()
      }
      this.loadedItems = this.allItemsOP;
      this.storyTotalCount = this.loadedItems.length;
    }
  }

  private handleFeaturedTripPosition() {
    const screenWidth = window.innerWidth;
    const moveBelowScreenWidth = 560;
    const itemToReplace = document.querySelector('.featuredTripMobilePosition');
    const originalPosition = document.querySelector('.featuredTripOriginalPosition');

    if (screenWidth < moveBelowScreenWidth) {
      // If screen width is less than 560 pixels, move it to its mobile-oriented position
      this.featuredTripMobilePosition = true;
      this.featuredTripOriginalPosition = false;
    } else {
      // If screen width is greater than 560 pixels, move it back to its original position
      this.featuredTripOriginalPosition = true;
      this.featuredTripMobilePosition = false;
    }
  }

  /**
   * Function to handle the "Load More" button click.
   */
  loadMore() {
    // Increment the number of items to display.
    this.storyDisplayCount += 8;

    // Check if there are more items to load.
    if (this.storyDisplayCount >= this.storyTotalCount) {
      // Hide the "Load More" button when all items are displayed.
      this.showLoadMoreButton = false;
    }
  }

  /**
   * Function to handles checkbox select and deselect
   */
  compareCheckboxClick(event, item, index, viewMode) {
    let viewModeId;
    let checkboxId;
    if (viewMode === 'grid') {
      viewModeId = 'eachGridItemId__' + index;
      checkboxId = 'checkbox_' + index;
    } else if (viewMode === 'list') {
      viewModeId = 'eachGridItemId_' + index;
      checkboxId = 'checkbox_' + index;
    }
    const elem: Element = document.getElementById(viewModeId);
    if (event.target.checked === true) {
      this.compareCheckboxArray.push(item);
      this.renderer.addClass(elem, 'active');
    } else {
      this.removecompareCheckboxItem(item);
      this.renderer.removeClass(elem, 'active');
    }
  }

  /**
   * Fuction to remove item from compare array
   */
  removecompareCheckboxItem(item) {
    this.compareCheckboxArray.splice(this.compareCheckboxArray.indexOf(item), 1);
    if (this.compareCheckboxArray.length <= 3) {
    }
  }

  /**
   * Function to set Previous Url For Voyage Page Back Btn
   */
  setPreviousUrlForVoyagePageBack(url, item) {
    this.rcycCommonService.setSessionData('backToResultVoyageListPrevUrl', this.router.url);
    if (!item) {
      this.currentUrlPathAsArray = url.split('/');
      this.currentUrlPathAsArray.shift();
      this.router.navigate(['/' + this.currentUrlPathAsArray[0] + '/' + this.currentUrlPathAsArray[1]]);
    }
  }

  /**
   * function for viewing voyage click
   */
  viewingVoyageClick(item) {
    this.viewingVoyageId = item.voyageId;
    this.rcycCommonService.setSessionData('viewingVoyageId', this.viewingVoyageId);
  }

  /**
   * Function to get drupal pcfi toggle datas
   */
  getPCFIToggleInfoFn() {
    this._SharedServiceService.getPCFIToggleInfo().subscribe(
      (res) => {
        this.PCFIToggleInfo = res;
        if (res && res.field_voyages) {
          this.PCFIVoyageIdArray = res.field_voyages.replace(/\s/g, '').split(",");
        }
      }
    )
  }

  /**
   * Get common info messages from drupal
   */
  getInfoCommonMsg() {
    this.rcycCommonService.currentInfoMessageSource$.pipe(
      switchMap((value) => value ? of(value) : this._SharedServiceService.getInfoCommonMsg()),
      first()
    ).subscribe((response) => {
      this.infoCommonMsg = response;
      this.rcycCommonService.setInfoMessageSource(response);
    });
  }

  /**
 * Set the additional cost info based on currency code
 */
  setAdditionalCostInfo() {
    const currencyFieldMap = {
      USD: 'field_usd_additional_cost_info',
      USM: 'field_usm_additional_cost_info',
      EUR: 'field_eur_additional_cost_info',
      GBP: 'field_gbp_additional_cost_info',
    };
    this.additionalCostInfo = this.infoCommonMsg?.[currencyFieldMap[this.currencyCode]];
  }
  
  getStaticDrupalData() {
    const Api_Url = '/api/v1/dynamic-title-eyebrow/find-a-cruise-voyage-tile?_format=json';
    this.rcycInternalApisService.getDrupal(Api_Url).subscribe(
      (response: any) => {
        if (response && response.length > 0) {
          this.staticDrupalTexts = response.sort(this.rcycCommonService.orderByProp('field_sort_order'));
        }
      }, (err) => {
        console.error(err);
      });
  }
}
