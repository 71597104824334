import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RcycSafeHtmlPipe } from './rcyc-safe-html.pipe';
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        RcycSafeHtmlPipe
    ],
    exports: [
        RcycSafeHtmlPipe
    ]
})
export class RcycSafeHtmlPipeModule { }


