import { Routes } from "@angular/router";
export const REDIRECTED_ROUTES: Routes = [
    { path: 'experience', pathMatch: 'full' , redirectTo: '/yachts'},

    { path: 'experience/dining', pathMatch: 'full', redirectTo: '/yachts/evrima/dining'},
    { path: 'experience/spa', pathMatch: 'full', redirectTo: '/yachts/evrima/spa'},
    { path: 'experience/interior', pathMatch: 'full', redirectTo: '/yachts/evrima/interior'},
    { path: 'experience/deck-plans', pathMatch: 'full', redirectTo: '/yachts/evrima/deck-plans'},
    { path: 'experience/suites', pathMatch: 'full', redirectTo: '/yachts/evrima/suites'},
    { path: 'experience/suites/vista', pathMatch: 'full', redirectTo: '/yachts/evrima/suites/owners'},
    { path: 'experience/suites/:tab', pathMatch: 'full', redirectTo: '/yachts/evrima/suites/:tab'},

    { path: 'travelagent', pathMatch: 'full', redirectTo: '/travelprofessionals'},
    { path: 'signup', pathMatch: 'full', redirectTo: '/request-call' },
]