import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rcyc-card-experience',
  templateUrl: './rcyc-experience.component.html',
  styleUrls: ['./rcyc-experience.component.css'],
})

export class RcycExperienceComponent implements OnInit {
  @Input() data: any;
  @Input() topcontent: string;
  @Input() variant: any;
  @Input() isMobile: boolean;
  @Output() clearCompareValueEmitter = new EventEmitter<string>();
  @Output() openModal = new EventEmitter<any>();
  cssImageScale: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.topcontent = this.topcontent?.toLowerCase();
  }
  clearCompareValue(data: any) {
    this.clearCompareValueEmitter.emit(data?.url);
    this.openModal.emit(data);
  }

  onHover() {
    if (!this.isMobile) {
      this.cssImageScale = true;
    }
  }

  onBlur() {
    this.cssImageScale = false;
  }


  onSlideClick(item: string) {
    this.router.navigate([item]);

  }
}
