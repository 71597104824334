import { AbstractControl } from '@angular/forms';
import { AsYouType, parsePhoneNumberFromString, parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max';

let formValue: any;
export function ValidatePhoneNumber(control: AbstractControl) {
  let dialCode = control.parent.get('dialCode').value;
  let countryCode=control.parent.get('countryCode').value;
  let onType: any = new AsYouType(countryCode);
  let inputValue = control.value;
 
  if (control.value) {
    let phoneNumber:any = parsePhoneNumberFromString(inputValue, countryCode);
   
    if (inputValue != formValue) { //TO avoid circular dependency checking flag
            
      if(phoneNumber&&phoneNumber.number) {
        let chagedPhoneNumber=phoneNumber.formatInternational();
        chagedPhoneNumber=chagedPhoneNumber.replace(dialCode, '');       
        formValue = (chagedPhoneNumber) ? chagedPhoneNumber : '';  
        // control.setValue(chagedPhoneNumber);  
      }
      
    }

    let customizedNumber;
    let customizedNumberLength;
    if (phoneNumber) {          
      if(
        phoneNumber.isPossible()&&
        phoneNumber.isValid()&&   
        parseMax(phoneNumber.number).isValid()
      ) {
        return { phoneIncorrect: false ,phoneNoMaxLength: 20, getPhoneCountryCode: phoneNumber.country };
      } else {
        return { phoneIncorrect: true , phoneNoMaxLength: 20, getPhoneCountryCode: phoneNumber.country};
      }
    } else {     
      return { phoneIncorrect: true , phoneNoMaxLength: 20, getPhoneCountryCode: ''};
    }    
  }
  return null;
};


export function formatPhoneList(phLength: PhoneDataFormat[]) {
  let formttedPhoneList = {};
  phLength.forEach((element) => {
    formttedPhoneList[element.code] = element;
    formttedPhoneList[element.code].ph_length = Number(element.ph_length);
  });
  return formttedPhoneList;
}
export function deepPhoneConfiguration(phoneDataArray: PhoneDataFormat[], formttedPhoneList) {
  let countryFlagUserDetails;
  let userCountryDetails: PhoneDataFormat;
  if (localStorage.getItem('UserDetails')) {
    countryFlagUserDetails = JSON.parse(localStorage.getItem('UserDetails'));
    userCountryDetails = {
      code: countryFlagUserDetails.country_code,
      dial_code: formttedPhoneList[countryFlagUserDetails.country_code].dial_code,
      name: formttedPhoneList[countryFlagUserDetails.country_code].name,
      flagUrlIpData: formttedPhoneList[countryFlagUserDetails.country_code].flagUrlIpData,
      flagUrlOld: formttedPhoneList[countryFlagUserDetails.country_code].flagUrlOld,
      flagUrl: formttedPhoneList[countryFlagUserDetails.country_code].flagUrl,
      defaultFormat: formttedPhoneList[countryFlagUserDetails.country_code].defaultFormat,
      defaultPhoneNumber: formttedPhoneList[countryFlagUserDetails.country_code].defaultPhoneNumber,
      ph_length: formttedPhoneList[countryFlagUserDetails.country_code].ph_length,
    };
    // this.lead_gen_form.get('countryCode').setValue(countryFlagUserDetails.country_code);
    phoneDataArray.splice(arrayIndex(countryFlagUserDetails.country_code, phoneDataArray), 1);
    phoneDataArray.splice(arrayIndex('US', phoneDataArray), 1);
    phoneDataArray.splice(arrayIndex('CA', phoneDataArray), 1);

    switch(countryFlagUserDetails.country_code.toLowerCase()) {
      case 'us':
        phoneDataArray.unshift(
          {
            'code': 'US',
            'name': 'United States',
            'dial_code': '+1',            
            "defaultFormat": "xxx xxx xxxx",
            'ph_length': 10,
            'flagUrlIpData': formttedPhoneList['US'].flagUrlIpData,
            'flagUrlOld': formttedPhoneList['US'].flagUrlOld,
            'flagUrl': formttedPhoneList['US'].flagUrl,
            'defaultPhoneNumber': formttedPhoneList['US'].defaultPhoneNumber,
          },
          {
            "code": "CA",
            "name": "Canada",
            "dial_code": "+1",            
            "ph_length": 10,
            "defaultFormat": "xxx xxx xxxx",
            "flagUrlIpData": "https://ipdata.co/flags/ca.png",
            "flagUrlOld": "https://www.countryflags.io/ca/flat/64.png",
            "flagUrl": "assets/components/images/country-flags/ca.png",
            'defaultPhoneNumber': formttedPhoneList['CA'].defaultPhoneNumber,
          },
        );
      break;
      case 'ca':
          phoneDataArray.unshift(
            {
              "code": "CA",
              "name": "Canada",
              "dial_code": "+1",              
              "ph_length": 10,
              "defaultFormat": "xxx xxx xxxx",
              "flagUrlIpData": "https://ipdata.co/flags/ca.png",
              "flagUrlOld": "https://www.countryflags.io/ca/flat/64.png",
              "flagUrl": "assets/components/images/country-flags/ca.png",
              'defaultPhoneNumber': formttedPhoneList['CA'].defaultPhoneNumber,
            },
            {
              'code': 'US',
              'name': 'United States',
              'dial_code': '+1',              
              "defaultFormat": "xxx xxx xxxx",
              'ph_length': 10,
              'flagUrlIpData': formttedPhoneList['US'].flagUrlIpData,
              'flagUrlOld': formttedPhoneList['US'].flagUrlOld,
              'flagUrl': formttedPhoneList['US'].flagUrl,
              'defaultPhoneNumber': formttedPhoneList['US'].defaultPhoneNumber,
            },
          );
      break;
      default:      
          phoneDataArray.unshift(
            {
              'code': countryFlagUserDetails.country_code,
              'name': formttedPhoneList[countryFlagUserDetails.country_code].name,
              'dial_code': formttedPhoneList[countryFlagUserDetails.country_code].dial_code,              
              'ph_length': Number(formttedPhoneList[countryFlagUserDetails.country_code].ph_length),
              "defaultFormat": formttedPhoneList[countryFlagUserDetails.country_code].defaultFormat,
              'flagUrlIpData': formttedPhoneList[countryFlagUserDetails.country_code].flagUrlIpData,
              'flagUrlOld': formttedPhoneList[countryFlagUserDetails.country_code].flagUrlOld,
              'flagUrl': formttedPhoneList[countryFlagUserDetails.country_code].flagUrl,
              'defaultPhoneNumber': formttedPhoneList[countryFlagUserDetails.country_code].defaultPhoneNumber,
            }, 
            {
              'code': 'US',
              'name': 'United States',
              'dial_code': '+1',             
              "defaultFormat": "xxx xxx xxxx",
              'ph_length': 10,
              'flagUrlIpData': formttedPhoneList['US'].flagUrlIpData,
              'flagUrlOld': formttedPhoneList['US'].flagUrlOld,
              'flagUrl': formttedPhoneList['US'].flagUrl,
              'defaultPhoneNumber': formttedPhoneList['US'].defaultPhoneNumber,
            },
            {
              "code": "CA",
              "name": "Canada",
              "dial_code": "+1",              
              "ph_length": 10,
              "defaultFormat": "xxx xxx xxxx",
              "flagUrlIpData": "https://ipdata.co/flags/ca.png",
              "flagUrlOld": "https://www.countryflags.io/ca/flat/64.png",
              "flagUrl": "assets/components/images/country-flags/ca.png",
              'defaultPhoneNumber': formttedPhoneList['CA'].defaultPhoneNumber,
            },
          );
      break;
    }
   

  } else {
    userCountryDetails = {
      'code': 'US',
      'name': 'United States',
      'dial_code': '+1',      
      'flagUrlIpData': formttedPhoneList['US'].flagUrlIpData,
      'flagUrlOld': formttedPhoneList['US'].flagUrlOld,
      'flagUrl': formttedPhoneList['US'].flagUrl,
      'defaultFormat': "xxx xxx xxxx",
      'ph_length': 10,
      'defaultPhoneNumber': formttedPhoneList['US'].defaultPhoneNumber,
    };
    phoneDataArray.unshift(
      {
        'code': 'US',
        'name': 'United States',
        'dial_code': '+1',        
        'ph_length': 10,
        "defaultFormat": "xxx xxx xxxx",
        'flagUrlIpData': formttedPhoneList['US'].flagUrlIpData,
        'flagUrlOld': formttedPhoneList['US'].flagUrlOld,
        'flagUrl': formttedPhoneList['US'].flagUrl,
        'defaultPhoneNumber': formttedPhoneList['US'].defaultPhoneNumber,
      },
      {
        "code": "CA",
        "name": "Canada",
        "dial_code": "+1",        
        "ph_length": 10,
        "defaultFormat": "xxx xxx xxxx",
        "flagUrlIpData": "https://ipdata.co/flags/ca.png",
        "flagUrlOld": "https://www.countryflags.io/ca/flat/64.png",
        "flagUrl": "assets/components/images/country-flags/ca.png",
        'defaultPhoneNumber': formttedPhoneList['CA'].defaultPhoneNumber,
      },
    );

  }
  return { phoneDataArray, userCountryDetails, formttedPhoneList }
}

export function arrayIndex(code, phoneList): any {
  return phoneList.findIndex(
    (phone) => {
      return phone.code == code;
    })
}

export interface PhoneDataFormat { 
    "code": string;
    "name": string;
    "dial_code": string;
    "ph_length": number;
    "defaultFormat": string;
    "flagUrlIpData": string;
    "flagUrlOld": string;
    "flagUrl": string; 
    "defaultPhoneNumber":string;
}

export function ValidatePrimaryPhoneNumber(control: AbstractControl) {
  let dialCode = control.parent.get('primaryDialCode').value;
  let countryCode=control.parent.get('primaryCountryCode').value;
  let onType: any = new AsYouType(countryCode);
  let inputValue = control.value;

  if (control.value) {
    let phoneNumber:any = parsePhoneNumberFromString(inputValue, countryCode);
    if (inputValue != formValue) { //TO avoid circular dependency checking flag
      if(phoneNumber&&phoneNumber.number) {
        let chagedPhoneNumber=phoneNumber.formatInternational();
        chagedPhoneNumber=chagedPhoneNumber.replace(dialCode, '');     
        formValue = (chagedPhoneNumber) ? chagedPhoneNumber : '';  
        // control.setValue(chagedPhoneNumber);  
      }
      
    }    
    if (phoneNumber) {            
      if(
        phoneNumber.isPossible()&&
        phoneNumber.isValid()&&   
        parseMax(phoneNumber.number).isValid()
      ) {
        return { phoneIncorrect: false ,phoneNoMaxLength: 20, getPhoneCountryCode: phoneNumber.country };
      } else {
        return { phoneIncorrect: true , phoneNoMaxLength: 20, getPhoneCountryCode: phoneNumber.country};
      }
    } else {     
      return { phoneIncorrect: true , phoneNoMaxLength: 20, getPhoneCountryCode: ''};
    }    
  }
  return null;
};

export function ValidateAlternatePhoneNumber(control: AbstractControl) {
  let dialCode = control.parent.get('alternateDialCode').value;
  let countryCode=control.parent.get('alternateCountryCode').value;
  let onType: any = new AsYouType(countryCode);
  let inputValue = control.value;  

  if (control.value) {
    let phoneNumber:any = parsePhoneNumberFromString(inputValue, countryCode);
    if (inputValue != formValue) { //TO avoid circular dependency checking flag
      if(phoneNumber&&phoneNumber.number) {
        let chagedPhoneNumber=phoneNumber.formatInternational();
        chagedPhoneNumber=chagedPhoneNumber.replace(dialCode, '');     
        formValue = (chagedPhoneNumber) ? chagedPhoneNumber : '';  
        // control.setValue(chagedPhoneNumber);  
      }
      
    }      
    if (phoneNumber) {            
      if(
        phoneNumber.isPossible()&&
        phoneNumber.isValid()&&   
        parseMax(phoneNumber.number).isValid()
      ) {
        return { phoneIncorrect: false ,phoneNoMaxLength: 20, getPhoneCountryCode: phoneNumber.country };
      } else {
        return { phoneIncorrect: true , phoneNoMaxLength: 20, getPhoneCountryCode: phoneNumber.country};
      }
    } else {     
      return { phoneIncorrect: true , phoneNoMaxLength: 20, getPhoneCountryCode: ''};
    }    
  }
  return null;
};

export function ValidateCompanyPhoneNumber(control: AbstractControl) {
  let dialCode = control.parent.get('companyDialCode').value;
  let countryCode=control.parent.get('companyCountryCode').value;
  let onType: any = new AsYouType(countryCode);
  let inputValue = control.value;
 
  if (control.value) {
    let phoneNumber:any = parsePhoneNumberFromString(inputValue, countryCode);
    if (inputValue != formValue) { //TO avoid circular dependency checking flag
      if(phoneNumber&&phoneNumber.number) {
        let chagedPhoneNumber=phoneNumber.formatInternational();
        chagedPhoneNumber=chagedPhoneNumber.replace(dialCode, '');     
        formValue = (chagedPhoneNumber) ? chagedPhoneNumber : '';  
        // control.setValue(chagedPhoneNumber);  
      }
      
    }  
    if (phoneNumber) {        
      if(
        phoneNumber.isPossible()&&
        phoneNumber.isValid()&&   
        parseMax(phoneNumber.number).isValid()
      ) {
        return { phoneIncorrect: false ,phoneNoMaxLength: 20, getPhoneCountryCode: phoneNumber.country };
      } else {
        return { phoneIncorrect: true , phoneNoMaxLength: 20, getPhoneCountryCode: phoneNumber.country};
      }
    } else {     
      return { phoneIncorrect: true , phoneNoMaxLength: 20, getPhoneCountryCode: ''};
    }    
  }
  return null;
};