import { Component, OnInit, ViewChild, HostListener, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { Router, ActivatedRoute } from '@angular/router';
import { RcycMilestonesService } from './rcyc-milestones.service';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { AppConfig } from '../../rcyc-config/config';
import { DomSanitizer } from '@angular/platform-browser';
import * as $t from 'jquery';
import { Observable } from 'rxjs';

declare var lightGallery: any;
declare let $f: any;
declare let $: any;
const splitAt = index => x => [x.slice(0, index), x.slice(index)];

// declare var $1: any;
@Component({
  selector: 'app-milestones',
  templateUrl: './rcyc-milestones.component.html',
  styleUrls: ['./rcyc-milestones.component.css']
})
export class MilestonesComponent implements OnInit, AfterViewInit {


  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;

  public carouselOne: any;
  public carouselTwo: any;
  carouselArray: any = []
  response_A: any;
  response_B: any;
  response_C: any;
  response_D: any = [];
  response_E: any;
  response_F: any = [];

  response_ATemp: any = [];
  response_BTemp: any = [];
  response_CTemp: any = [];
  response_DTemp: any = [];
  response_ETemp: any = [];
  response_FTemp: any = [];

  response_A_status = false;
  response_B_status = false;
  response_C_status = false;
  response_D_status = false;
  response_E_status = false;
  response_F_status = false;

  apiUrl_A: any;
  apiUrl_B: any;
  apiUrl_C: any;
  apiUrl_D: any;
  apiUrl_E: any;
  apiUrl_F: any;

  pageTitle: any;
  carouselContents: any;
  mainContents: any;
  response_D_Array: any;

  selectedYear: any;
  selectedMonth: any;

  selectedYearMonthTab: any;
  apiUrlMetatag: any;

  currentComponentName: any;
  responseArray: any;

  currentSlide = 0;
  currentCarouselSlideIndex = 0;
  isVideoPlaying = true;

  photosVideoArrayLength: any;
  firstElemTabIndexStatus = true;
  carousel_2_visibleStatus = false;

  showCarouselPlayBtn = false;
  carouselVideoUrl: any;
  carouselImageUrl: any;
  pageReloaded = false;
  tabChanged = false;
  carouselToken: any;
  showImage = false;
  sliderIndex = 0;
  urlParams: any;
  noRecordFound = false;
  currentSlider;
  rightSideHide = true;
  leftSideHide = false;
  showPlayBtn = false;
  currentSlide1;
  carouselToken1;
  currentFocussedElement: any;
  bannerLeftNavId_DImageUrl: any = '';
  bannerRightNavId_DImageUrl: any = '';
  leftBtnTabindex = '0';
  mileStoneInitialLoad = true;
  mileStoneEndReached = false;
  mileStonePageInitialLoad = true;
  @ViewChild('milestonecarousel') milestonecarousel: any;
  @ViewChild('milestoneTimelineCarousel') milestoneTimelineCarousel: any;
  constructor(
    private rcycCommonService: RcycCommonService,
    private router: Router,
    private rcycMilestonesService: RcycMilestonesService,
    private sanitizer: DomSanitizer,
    // private nguCarouselService: NguCarouselService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {

    // Current subHeader Status
    this.rcycCommonService.changeSubHeaderStatus(true);

    this.currentComponentName = 'RcycMilestonesComponent';
    // change changeCurrentComponent
    this.rcycCommonService.changeCurrentComponent(this.currentComponentName);
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.year && params.month) {
        this.urlParams = params;
        this.yearMonthClick({
          'field_milestones_year': params.year,
          'field_milestones_month' : params.month.charAt(0).toUpperCase() + params.month.slice(1).toLowerCase()
        });
      } else {
        this.rcycMilestonesContent();        
      }
    });


    // Call get Metatag function
    this.apiUrlMetatag = '/api/v1/meta/milestones?_format=json';
    this.rcycCommonService.getMetaTags(this.apiUrlMetatag);

     // Set Milestone timeline button image
     this.bannerLeftNavId_DImageUrl = 'assets/components/images/rcyc-milestones/arrow---left_hover.png';
     this.bannerRightNavId_DImageUrl = 'assets/components/images/rcyc-milestones/arrow---right_hover.png';
  }

  ngOnInit() {
    this.carouselArray = []
    this.carouselOne = {
      grid: { xs: 1, sm: 5, md: 4, lg: 5, all: 0 },
      slide: 1,
      speed: 400,
      interval: {timing: 4000, initialDelay: 1000},
      point: {
        visible: false
      },
      load: 2,
      touch: true,
      loop: false,
      custom: 'banner',
      // easing: 'cubic-bezier(0, 0, 0.2, 1)'

    };

    this.carouselTwo = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 500,
      interval: 300000,
      point: {
        visible: false
      },
      // load: 2,
      touch: true,
      loop: true,
      // custom: 'banner'
   };

   // Check reload or not

   this.checkRefresh();

  //  this.getPreviewImage('https://player.vimeo.com/video/295384725');

   //  this.rcycCommonService.removeData('mileStoneCarouselNotScrolledtoStart');

  }

  // Carousel function
  myfunc(event: any) {

  }

  // Year Month Click Function
  yearMonthClick(item) {
    this.showPlayBtn = false;
    this.selectedYear = item.field_milestones_year;
    this.selectedMonth = item.field_milestones_month;
    this.selectedYearMonthTab = item.field_milestones_year + '-' + item.field_milestones_month;
    this.rcycMilestonesContent();
    // if (element) {
    //   this.onMilestoneItemsFocus(element);
    // }
  }

  /* ======================================================================================================================================
			Funtion to call milestone apis
	 * ======================================================================================================================================*/

  rcycMilestonesContent() {


    this.apiUrl_A = '/api/v1/experience/milestones/header/a?_format=json';
    // this.apiUrl_B = '/api/v1/experience/milestones/video/b?_format=json';
    this.apiUrl_B = '/api/v1/banners/experience/milestones/b?_format=json';
    this.apiUrl_C = '/api/v1/experience/milestones/header/c?_format=json';
    // this.apiUrl_D= "/api/v1/milestones/content/d?_format=json";
    this.apiUrl_D = '/api/v1/experience/milestones/years?_format=json';

    /**   !!!!!!!!!!!!!!!!!!! A !!!!!!!!!!!!!!!!!!!!  **/
    this.rcycMilestonesService.milestones(this.apiUrl_A)
     .subscribe(
      (response) => {

        this.response_ATemp = response;
        if (this.response_ATemp && this.response_ATemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {

            this.response_A = res[0];
            this.response_A_status = true;

            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A(this.response_A);
          } else {

            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A([]);
          }
        } else {

          // Changing subheader A value
          this.rcycCommonService.changeSubHeaderDataArray_A([]);
        }
      },
      (err) => {

        console.log('response_C err ====');
        console.log(err);
      },
    );

    /**   !!!!!!!!!!!!!!!!!!!  B  - Banner !!!!!!!!!!!!!!!!!!!!  **/
    this.rcycMilestonesService.milestones(this.apiUrl_B)
      .subscribe(
        (response) => {

        this.response_BTemp = response;
        if (this.response_BTemp && this.response_BTemp.length) {

          // var res= Object.values(response);
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          const resArray = [];
          if (res.length) {

            for (const index in res) {
              const factor = res[index];
              if (factor.field_images) {

                factor.field_images_srcset = this.IMG_BASE_URL + factor.field_images + ' 1600w, ' +
                this.IMG_BASE_URL + factor.field_image_1440px + ' 1000w, ' +
                this.IMG_BASE_URL + factor.field_image_768px + ' 800w, ' +
                this.IMG_BASE_URL + factor.field_image_375px + ' 500w';
                factor.field_images_src1 = this.IMG_BASE_URL + factor.field_images;
                factor.field_images_src2 = this.IMG_BASE_URL + factor.field_image_1440px;
                factor.field_images_src3 = this.IMG_BASE_URL + factor.field_image_768px;
                factor.field_images_src4 = this.IMG_BASE_URL + factor.field_image_375px;
                factor.field_images = this.IMG_BASE_URL + factor.field_images;

                const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_images);

                factor.altTextBannerImg = factor.title + ' ' + imgTitle;
                factor.titleTextBannerImg = factor.title + ' ' + imgTitle;
              } else {

                factor.altTextBannerImg = factor.title;
                factor.titleTextBannerImg = factor.title;
              }

              resArray.push(factor);
              if (parseInt(index, 10) + 1 === res.length) {

                this.response_B = resArray;
                this.responseArray = resArray;
                this.response_B_status = true;
              }
            }
          }
        }

      },
      (err) => {

          console.log('response_B err ====');
          console.log(err);
      },
    );

    /**   !!!!!!!!!!!!!!!!!!! C !!!!!!!!!!!!!!!!!!!!  **/
    this.rcycMilestonesService.milestones(this.apiUrl_C)
      .subscribe(
        (response) => {

          this.response_CTemp = response;
          if (this.response_CTemp && this.response_CTemp.length) {

            // var res= Object.values(response);
            const res = Object.keys(response).map(function (key) {
              return response[key];
            });
            if (res.length) {

              this.response_C = res[0];
              this.response_C_status = true;
            }
          }
        },
        (err) => {

          console.log('response_C err ====');
          console.log(err);
        },
      );

       /**   !!!!!!!!!!!!!!!!!!! D  Year Month Carousel !!!!!!!!!!!!!!!!!!!!  **/
       this.rcycMilestonesService.milestones(this.apiUrl_D)
        .subscribe(
          (response) => {
            this.response_DTemp = response;
            if (this.response_DTemp && this.response_DTemp.length) {

              // var res= Object.values(response);
              const res = Object.keys(response).map(function (key) {
                return response[key];
               });

              // res.sort(this.rcycCommonService.orderByProp('field_milestones_year'));
              if (res.length) {

                const sortArray = res;
                const resArray = [];
                /*	const months = ['January', 'February', 'March', 'April', 'May', 'June',
								  'July', 'August', 'September', 'October', 'November', 'December'
							  ];
							  // month sort
							  sortArray.sort((a, b) => {
							    return months.indexOf(a.field_milestones_month) - months.indexOf(b.field_milestones_month);
							  });

						  	// year sort
							  sortArray.sort((a, b) => (parseInt(a.field_milestones_year, 10) >
								parseInt(b.field_milestones_year, 10) ? -1 : 1)); */

                let previousYear = 0;
                for (const index in sortArray) {

                  const factor = sortArray[index];
                  if (factor.field_milestones_year != previousYear) {

                    factor.yearStatus = true;
                  } else {

                    factor.yearStatus = false;
                  }
                  previousYear = factor.field_milestones_year;
                  resArray.push(factor);
                  if (parseInt(index, 10) + 1 === sortArray.length) {

                    if (!this.selectedYear && !this.selectedMonth) {

                      this.selectedYear = resArray[0].field_milestones_year;
                      this.selectedMonth = resArray[0].field_milestones_month;

                      this.selectedYearMonthTab = resArray[0].field_milestones_year +
                      '-' + resArray[0].field_milestones_month;

                      this.apiCall_E_Fn();
                      this.apiCall_F_Fn();
                    } else {

                      this.apiCall_E_Fn();
                      this.apiCall_F_Fn();
                    }

                    this.response_D = resArray;
                    this.response_D.sort(this.rcycCommonService.orderByProp('field_sort_order')).reverse();
                    this.currentSlider = this.response_D;
                    this.response_D_status = true;

                    if (this.selectedYearMonthTab) {
                      const responseDTempArray = [];
                      this.response_D.map((data, index1) => {
                        responseDTempArray.push(data.field_milestones_year + '-' + data.field_milestones_month);
                        if (index1 === (this.response_D.length - 1)) {
                          let selectedIndex;
                          responseDTempArray.map((item, index2) => {
                            if (item === this.selectedYearMonthTab) {
                              selectedIndex = index2;
                              setTimeout( () => {
                                const mileStoneFocusElement = document.getElementById('milestoneId_' + (selectedIndex));
                                if (mileStoneFocusElement && !this.mileStonePageInitialLoad) {
                                  mileStoneFocusElement.focus();
                                } else if (mileStoneFocusElement && this.mileStonePageInitialLoad) {
                                  this.mileStonePageInitialLoad = false;
                                }
                              }, 500);
                            }
                          });
                        }
                      });
                    }

                  }
              }

            }
          }
        },
        (err) => {

          console.log('response_D err ====');
          console.log(err);
        },
    );
  }

  /* ======================================================================================================================================
			Funtion to call milestone E api (content according to date and year)
	* ======================================================================================================================================*/

  apiCall_E_Fn() {
    this.apiUrl_E = '/api/v1/experience/milestones/contents/' + this.selectedYear + '/' + this.selectedMonth + '?_format=json ';

    /**   !!!!!!!!!!!!!!!!!!! E corresponding Data !!!!!!!!!!!!!!!!!!!!  **/
    this.rcycMilestonesService.milestones(this.apiUrl_E)
      .subscribe(
        (response) => {
          this.response_ETemp = response;
          if (this.response_ETemp && this.response_ETemp.length) {

            // var res= Object.values(response);
            const res = Object.keys(response).map(function (key) {
              return response[key];
            });
            if (res.length) {

              // this.response_E= res[0];
              // this.response_E_status=true;
              const resArray = [];
              for (const index in res) {
                const factor = res[index];
                if (factor.field_images) {

                  factor.field_images = this.IMG_BASE_URL + factor.field_images;

                  const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_images);
                  factor.altTextInnerImg = factor.title + ' ' + imgTitle;
                  factor.titleTextInnerImg = factor.title + ' ' + imgTitle;
                } else {

                  factor.altTextInnerImg = factor.title;
                  factor.titleTextInnerImg = factor.title;
                }
                resArray.push(factor);
                if (parseInt(index, 10) + 1 === res.length) {

                  this.response_E = resArray[0];
                  this.response_E_status = true;

                }
              }
            }
          }

        },
        (err) => {

            console.log('response_E err ====');
            console.log(err);
        },
      );
  }

  /* ======================================================================================================================================
			Funtion to call milestone F api (content according to date and year)
  * ======================================================================================================================================*/

  apiCall_F_Fn() {


    this.apiUrl_F = '/api/v1/experience/milestones/images/' + this.selectedYear + '/' + this.selectedMonth + '?_format=json ';

    this.setCarouselOrder();

   /**   !!!!!!!!!!!!!!!!!!! F corresponding Data !!!!!!!!!!!!!!!!!!!!  **/
    this.rcycMilestonesService.milestones(this.apiUrl_F)
      .subscribe(
        (response : any = []) => {
          // this.onmoveFn({});
          // this.response_FTemp = response;
          // if (this.response_FTemp.length < 1 ) {
          //   this.response_F = [];
          // }
          
          let res = response;
          this.response_F_status = true;
          if (res.length) {
            this.carouselArray=res.map(function(obj) { 
              obj['field_image'] = obj['field_images']; 
              delete obj['field_images']; 
              return obj; 
          }); 
            const resArray = [];
            let videoLength = 0;
            for (const index in res) {
              const factor = res[index];
              if (factor.field_images) {

                factor.field_images = this.IMG_BASE_URL + factor.field_images;

                const imgTitle = this.rcycCommonService.imageNameAltSplit(factor.field_images);
                factor.altTextInnerImg = factor.title + ' ' + imgTitle;
                factor.titleTextInnerImg = factor.title + ' ' + imgTitle;
              } else {

                factor.altTextInnerImg = factor.title;
                factor.titleTextInnerImg = factor.title;
              }

              if (factor.field_images || factor.field_video) {
                resArray.push(factor);
              }
              if (!factor.field_images && factor.field_video) {
                videoLength = videoLength + 1;
              }

              if (parseInt(index, 10) + 1 === res.length) {

                this.photosVideoArrayLength = videoLength;
                this.carousel_2_visibleStatus = false;
                this.response_F_status = true;
                this.currentSlide = 0;
                // resArray.sort(this.rcycCommonService.orderByProp('field_sort_order')).reverse();
                resArray.sort(this.rcycCommonService.orderByProp('field_sort_order')).reverse();
                this.firstElemTabIndexStatus = true;

              } 
            }
          } else {
            if (this.urlParams) {
              this.selectedMonth = '';
              this.selectedYear = '';
              this.selectedYearMonthTab = '';
              this.urlParams = '';
              this.rcycMilestonesContent();
            }
          }
        },
        (err) => {

            console.log('response_F err ====');
            console.log(err);
        },
      );
  }
  /* ==============================================================================
     Function on carousel move
  =================================================================================== */
  onmoveFn(data: NguCarouselStore) {
    this.showImage = false;
   this.currentSlide = data.currentSlide;
    this.firstElemTabIndexStatus = false;

    this.carouselToken = data.token;
    this.showImage = false;

    if (this.currentSlide !== undefined) {
        if (this.tabChanged) {
          this.tabChanged = false;

      } else {
        this.tabChanged = false;
      }

    }
  }

  changeSliderContentMilestoneIndex(type) {
   if ( type === 'left' ) {
    document.getElementById('milestoneId_' + 0).focus();
    document.getElementById('bannerLeftNavId_D').tabIndex = 0;
    this.rightSideHide = true;
    this.leftSideHide = false;
    for (let k = 0; k < this.currentSlider.length; k++) {
      if (k <= 4) {
        document.getElementById('milestoneId_' + k).tabIndex = 0;
      } else {
      document.getElementById('milestoneId_' + k).tabIndex = -1;
    }
  }
    } else {
      const removeSliderFirstSetLength = this.currentSlider.length - 5;
      document.getElementById('milestoneId_' + 5).focus();
      this.rightSideHide = false;
      this.leftSideHide = true;
      for (let k = 0; k < this.currentSlider.length; k++) {
        if (removeSliderFirstSetLength <= k) {
          document.getElementById('milestoneId_' + k).tabIndex = 0;
        } else {
        document.getElementById('milestoneId_' + k).tabIndex = -1;
        }
      }
    }
  }

  nextCarouselload(i) {
    // console.log('nextCarouselload', i);
    this.currentCarouselSlideIndex = i;
    if (this.response_F[this.currentCarouselSlideIndex].field_image) {
      if (this.response_F[this.currentCarouselSlideIndex].field_video ) {

      }
    }
  }

  /* ==============================================================================
     Function to set carosel item height
  =================================================================================== */
  carouselSetItemHeight() {

    let timeOut;
    // clearTimeout(timeOut);
    if (this.photosVideoArrayLength === this.response_F.length ) {

      timeOut = setTimeout(() => {

        if (document.querySelector('.section-3 .videoIframe')) {

          const videoIframeElem = <HTMLElement>document.querySelector('.section-3 .videoIframe');
          videoIframeElem.style.height = '330px';
          videoIframeElem.style.width = '100%';
          videoIframeElem.style.position = 'relative';
        }
        if (window.matchMedia('(max-width: 1024px)').matches) {
          if (document.querySelector('.section-3 .videoIframe')) {
            const videoIframeElem = <HTMLElement>document.querySelector('.section-3 .videoIframe');
            videoIframeElem.style.height = '281px';
          }
        }
        if (window.matchMedia('(max-width: 920px)').matches) {
          if (document.querySelector('.section-3 .videoIframe')) {
            const videoIframeElem = <HTMLElement>document.querySelector('.section-3 .videoIframe');
            videoIframeElem.style.height = '255px';
          }
        }
        if (window.matchMedia('(max-width: 769px)').matches) {
          if (document.querySelector('.section-3 .videoIframe')) {
            const videoIframeElem = <HTMLElement>document.querySelector('.section-3 .videoIframe');
            videoIframeElem.style.height = '210px';
          }
        }
        if (window.matchMedia('(max-width: 766px)').matches) {
          if (document.querySelector('.section-3 .videoIframe')) {
            const videoIframeElem = <HTMLElement>document.querySelector('.section-3 .videoIframe');
            videoIframeElem.style.height = '425px';
          }
        }
        if (window.matchMedia('(max-width: 560px)').matches) {
          if (document.querySelector('.section-3 .videoIframe')) {
            const videoIframeElem = <HTMLElement>document.querySelector('.section-3 .videoIframe');
            videoIframeElem.style.height = '310px';
          }
        }
        if (window.matchMedia('(max-width: 420px)').matches) {
          if (document.querySelector('.section-3 .videoIframe')) {
            const videoIframeElem = <HTMLElement>document.querySelector('.section-3 .videoIframe');
            videoIframeElem.style.height = '250px';
          }
        }
        if (window.matchMedia('(max-width: 380px)').matches) {
          if (document.querySelector('.section-3 .videoIframe')) {
            const videoIframeElem = <HTMLElement>document.querySelector('.section-3 .videoIframe');
            videoIframeElem.style.height = '210px';
          }
        }
      }, 500);
    }

  }

  /* ==============================================================================
     Function to blank data by transform fixed
  =================================================================================== */
  clearTransformFn() {
    let timeOut;
    timeOut = setTimeout(() => {

      if (document.querySelector('.section-3 .ngucarousel .ngucarousel-inner .ngucarousel-items')) {

        const clearTransElem =
         <HTMLElement>document.querySelector('.section-3 .ngucarousel .ngucarousel-inner .ngucarousel-items');
        clearTransElem.style.transform = 'none';
        clearTransElem.style.transition = 'none';
      }

      if (window.matchMedia('(min-width: 768px)').matches) {
        if (document.querySelector('.section-3 .ngucarouselAdNpOn > .ngucarousel > .ngucarousel-inner > .ngucarousel-items')) {

          const clearTransElem =
           <HTMLElement>document.querySelector('.section-3 .ngucarouselAdNpOn > .ngucarousel > .ngucarousel-inner > .ngucarousel-items');
           clearTransElem.style.transform = 'none';
           clearTransElem.style.transition = 'none';
        }
      }

      if (window.matchMedia('(min-width: 992px)').matches) {
        if (document.querySelector('.section-3 .ngucarouselAdNpOn > .ngucarousel > .ngucarousel-inner > .ngucarousel-items')) {

          const clearTransElem =
           <HTMLElement>document.querySelector('.section-3 .ngucarouselAdNpOn > .ngucarousel > .ngucarousel-inner > .ngucarousel-items');
          clearTransElem.style.transform = 'none';
          clearTransElem.style.transition = 'none';
        }
      }

      if (window.matchMedia('(min-width: 1200px)').matches) {
        if (document.querySelector('.section-3 .ngucarouselAdNpOn > .ngucarousel > .ngucarousel-inner > .ngucarousel-items')) {

          const clearTransElem =
           <HTMLElement>document.querySelector('.section-3 .ngucarouselAdNpOn > .ngucarousel > .ngucarousel-inner > .ngucarousel-items');
          clearTransElem.style.transform = 'none';
          clearTransElem.style.transition = 'none';
        }
      }

    }, 500);
  }

 // Function to Set Url for Carousel

  setCarouselUrl(index: any) {
    if ((this.response_F[index] && this.response_F[index].field_video) &&
       (this.response_F[index] && this.response_F[index].field_images)) {
      this.response_F[index].field_video = this.response_F[index].field_video.changingThisBreaksApplicationSecurity;

      this.showCarouselPlayBtn = true;
       this.response_F[index].field_images = this.response_F[index].field_images;
       this.showImage = true;

    } else {

      this.carouselVideoUrl = '';
      this.showCarouselPlayBtn = false;
      if (this.response_F[index] && this.response_F[index].field_images) {
        this.response_F[index].field_images = this.response_F[index].field_images;
         this.showImage = true;
      }

    }
  }

  // Function to check page reloaded or not
  checkRefresh() {
    if (window.performance) {

  }
  if (performance.navigation.type === 1) {
    this.pageReloaded = true;

  } else {
    this.pageReloaded = false;
  }
}

// Function for thumbnail view

getPreviewImage(videoUrl: any, index: any) {
  if (videoUrl) {
    const splittedArray: any = videoUrl.split('/');
    const splittedArrayContent = splittedArray[splittedArray.length - 1];
    const substring = '?';
    let videoIndex = '';
    if (splittedArrayContent.indexOf(substring) !== -1) {
      videoIndex =  splittedArrayContent.split('?')[0];
    } else {
      videoIndex =  splittedArray[splittedArray.length - 1];
    }

    const Url: any =  'https://vimeo.com/api/v2/video/' + videoIndex + '.json';
    this.rcycMilestonesService.getVimeoApi(Url).subscribe(
      (response) => {
        if (response[0].thumbnail_large) {
          this.response_F[index].field_images = response[0].thumbnail_large;
          this.showPlayBtn = true;
          this.setCarouselUrl(index);
        }
      },
      (err) => {

        console.log('vimeo err ====');
        console.log(err);
        // Checking Array for
      },
     );
  }

}

// Function  For carousel item order issue
setCarouselOrder() {
  this.tabChanged = true;
  this.response_F = [];
  // this.currentSlide = 0;
  this.showCarouselPlayBtn = false;
  if (this.carouselToken) {
    if (this.milestonecarousel) {
      // this.nguCarouselService.reset(this.carouselToken);
    }
  }
}
  ngAfterViewInit() {
    this.pageReloaded = true;
    this.cdr.detectChanges();
  }
  showLightbox(itemIndex) {
    const lightBoxArray = [];
    let lightBoxArrayTemp: any = [];
    if (this.response_F.length) {
      lightBoxArrayTemp = this.response_F;
      // Order the array based  on click on the carousel items
     //  console.log('test', lightBoxArrayTemp.slice(3));
      // splitAt(2)(lightBoxArrayTemp
     lightBoxArrayTemp =  splitAt(itemIndex)(lightBoxArrayTemp)[1].concat(splitAt(itemIndex)(lightBoxArrayTemp)[0]);
     lightBoxArrayTemp.map((data, index) => {
        if (data.field_video) {
          lightBoxArray.push({src: data.field_video.changingThisBreaksApplicationSecurity});
        } else {
          lightBoxArray.push({src: data.field_images});
        }
        if ((index === this.response_F.length - 1)) {
          $.fancybox.open(
            lightBoxArray, {
              loop : true,
              // hash : 'test',
              autoPlay: true,
              playSpeed: 3000,
            }
          );
         $('.fancybox-infobar').hide();
        }
        this.urlParams = '';
      });

    }
  }

  // Function to change the index of the carousel
  changeSliderContentIndex(position) {
    if (this.response_F.length) {
      if (position === 'left') {
        if (this.sliderIndex > 0) {
          this.sliderIndex = this.sliderIndex - 1;
        } else if (this.sliderIndex === 0) {
          this.sliderIndex = this.response_F.length - 1;
        }
      } else if (position === 'right') {
        if (this.sliderIndex === (this.response_F.length - 1)) {

          this.sliderIndex = 0;

        } else {

          this.sliderIndex = this.sliderIndex + 1;
        }
      }
    }
  }
/* =============================================================================
   Function For onMilestoneItemsFocus
==============================================================================*/
  onMilestoneItemsFocus(event) {
    event.target.style.color = 'lightblack';
    if (document.getElementById(event.target.id)) {
      document.getElementById(event.target.id).style.display = 'block';
      document.getElementById(event.target.id).focus();
      // document.getElementById(event.target.id).scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start'
      // });
      // this.nguCarouselService.currentSlide = 0;

      const mileStoneFocusElementIndex = event.target.id.split('_')[1];
      this.currentFocussedElement = mileStoneFocusElementIndex;
      this.leftBtnTabindex = '0';
      if ((parseInt(mileStoneFocusElementIndex, 10) >  4) &&
        (parseInt(mileStoneFocusElementIndex, 10) < this.response_D.length - 5)) {
          const milestoneSliderRightArrow = document.getElementById('bannerRightNavId_D');
          milestoneSliderRightArrow.style.visibility = 'visible';
      }
      if (parseInt(mileStoneFocusElementIndex, 10) === (this.response_D.length - 1)) { // Hide right arrow on Focussing last item
        const milestoneSliderRightArrow = document.getElementById('bannerRightNavId_D');
        const milestoneSliderLeftArrow = document.getElementById('bannerLeftNavId_D');
        if (milestoneSliderRightArrow) {
          milestoneSliderRightArrow.style.visibility = 'hidden';
          milestoneSliderRightArrow.click();
          milestoneSliderRightArrow.click();
          this.mileStoneEndReached = true;
          milestoneSliderLeftArrow.style.visibility = 'visible';
          this.leftBtnTabindex = '-1';
        }
      }
      if (parseInt(mileStoneFocusElementIndex, 10) > 4 &&  this.response_D.length > 4) {
        const milestoneSliderLeftArrow = document.getElementById('bannerLeftNavId_D');
        milestoneSliderLeftArrow.style.visibility = 'visible';
        milestoneSliderLeftArrow.classList.remove('hideMileStonePrevBtn');
      }

    }
  }

  /* ==============================================================================
     Function on carousel move
  =================================================================================== */
 /* onmoveFn1(data: NguCarouselStore) {
    this.currentSlide1 = data.currentSlide;

    this.carouselToken1 = data.token;

    if (this.currentSlide1 > 0) {
      this.rcycCommonService.setData('mileStoneCarouselNotScrolledtoStart', true);
    } else {
      this.rcycCommonService.removeData('mileStoneCarouselNotScrolledtoStart');
    }
  }

    moveToSlide(position) {
    if (position === 'left') {
      console.log('left');
      if (this.carouselToken1) {
        if (this.milestonecarousel) {
          console.log('AAA');
          this.nguCarouselService.moveToSlide(this.carouselToken,  this.currentSlide1 - 1, false);
        }
      }
    }
    if (position === 'right') {
      console.log('right');
      if (this.carouselToken1) {
        if (this.milestonecarousel) {
          this.nguCarouselService.moveToSlide(this.carouselToken,  this.currentSlide1 + 1, false);
        }
      }
    }
   } */

  /* ===========================================================================
     Function on carousel Prev Button Press
  ============================================================================*/
  leftbtnClick(event) {
    event.preventDefault();
    const milestoneSliderRightArrow = document.getElementById('bannerRightNavId_D');
    if (this.mileStoneEndReached) {
      milestoneSliderRightArrow.style.visibility = 'visible';
    }
  }

  /* ===========================================================================
     Function on carousel Next Button Press
  ============================================================================*/
  rightbtnClick(event) {
    const milestoneSliderLeftArrow = document.getElementById('bannerLeftNavId_D');
    if (milestoneSliderLeftArrow) {
      milestoneSliderLeftArrow.classList.remove('hideMileStonePrevBtn');
    }
    event.preventDefault();
  }

/* =============================================================================
   Function Shift Tab
==============================================================================*/
  @HostListener('keydown', ['$event']) onKeyDown(e) {
    if (e.shiftKey && e.keyCode === 9) {

      const difference = this.response_D.length - parseInt(this.currentFocussedElement, 10);
      if (difference > 1 && ( ((this.response_D.length - difference) === 3) ||
      ((this.response_D.length - difference) === 2) ||
      ((this.response_D.length - difference) === 1))
      )  {

        const milestoneSliderLeftArrow = document.getElementById('bannerLeftNavId_D');
        if ((this.response_D.length - difference) === 1) {
          milestoneSliderLeftArrow.classList.add('hideMileStonePrevBtn');
        }
        const leftBtn = document.getElementById('bannerLeftNavId_D');

       //  setTimeout(() => {

          if (leftBtn) {
            document.getElementById('bannerLeftNavId_D').click();
            const milestoneSliderRightArrow = document.getElementById('bannerRightNavId_D');
            milestoneSliderRightArrow.style.visibility  = 'visible';
            milestoneSliderLeftArrow.style.visibility  = 'visible';
          }
        // }, 1000);


      }
    }
  }
/* =============================================================================
   Function For MileStone Timeline Focus
==============================================================================*/
  timelineBtnFocus(event, position) {
    if (position === 'left') {

      this.bannerLeftNavId_DImageUrl = 'assets/components/images/rcyc-milestones/arrow---left_hover.png';

    } else if (position === 'right') {

      this.bannerRightNavId_DImageUrl = 'assets/components/images/rcyc-milestones/arrow---right_hover.png';
    }
  }
  /* ===========================================================================
     Function For MileStone Timeline Focus Out
  ============================================================================*/
  timelineBtnFocusOut(event, position) {
    if (position === 'left') {

      this.bannerLeftNavId_DImageUrl = 'assets/components/images/rcyc-milestones/arrow---left_hover.png';

    } else if (position === 'right') {

      this.bannerRightNavId_DImageUrl = 'assets/components/images/rcyc-milestones/arrow---right_hover.png';
    }
  }

}
