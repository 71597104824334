import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RcycViewBrochureComponent } from '../../rcyc-req-brochure-redesign/rcyc-view-brochure/rcyc-view-brochure.component';
@Component({
  selector: 'app-rcyc-50-50',
  templateUrl: './rcyc-50-50.component.html',
})

export class Rcyc5050Component implements OnInit {
  @Input() data: any
  sectionContentData : any
  actionLink: string;
  secondaryAction: string;
  isBeyondDesktop: boolean;
  isEditorialHub: boolean = false;
  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlPath: any;
  isChartersPage=false;
  currentUrl: any;
  isFAQ: boolean;
  @ViewChild('viewBrochureModal', { static: false }) viewBrochureModal: RcycViewBrochureComponent;
  constructor(
    private activatedRoute: ActivatedRoute,
  ){
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlPath = this.currentUrlPathAsArray[0]?.path;
    this.currentUrl = this.currentUrlPathAsArray[1]?.path;
    this.isChartersPage = this.currentUrlPath === 'charters';
    this.isFAQ = this.currentUrl === 'FAQ';
    if(this.currentUrlPath=='the-compass'){
      this.isEditorialHub = true;
    }
  }

  ngOnInit(): void {
    this.screenSize()
    let tempCtaUrl = this.data?.field_com_cta_link;
    if (tempCtaUrl && tempCtaUrl?.indexOf('internal:') !== -1) {
      tempCtaUrl = tempCtaUrl?.split(':')[1];
    } 
    if(this.currentUrlPath == 'cruise-ports') {
      this.actionLink = this.data?.channelUrl
    } else {
    this.actionLink = tempCtaUrl;
  }
    let tempUrl = this.data?.field_com_secondary_cta_link;
    if (tempUrl && tempUrl?.indexOf('internal:') !== -1) {
      tempUrl = tempUrl?.split(':')[1];
    }
    this.secondaryAction = tempUrl;
    
    this.sectionContentData = {
      eyebrow: this.data.field_com_eyebrow,
      title: this.data.title,
      text: this.data.field_com_description,
    }
  }

  @HostListener('window:resize', ['$event'])
  screenSize() {
    this.isBeyondDesktop = window.innerWidth > 1500;
  }
  viewBrochureClick() {
    this.viewBrochureModal.modalOpen();
  }
}
