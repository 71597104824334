import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { RcycLoginComponent } from '../rcyc-login/rcyc-login.component';
@Component({
  selector: 'app-rcyc-account-exist-message',
  templateUrl: './rcyc-account-exist-message.component.html',
  styleUrls: ['./rcyc-account-exist-message.component.css']
})
export class RcycAccountExistMessageComponent implements OnInit, AfterViewInit {
@Input ()
accountExitMessage: any;
@Input ()
registrationToLoginPass: any;
@ViewChild('guestLoginModal', { static: true }) guestLoginModal: RcycLoginComponent;
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (document.querySelector('#bookingFoundSuccessId a')) {
      document.querySelector('#bookingFoundSuccessId a')
      .addEventListener('click', (e) =>  {
        this.guestLoginModal.modalOpen();
      });
    }
  }

}
