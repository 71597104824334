// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  clickToChatVisibility: true,
  noIndexRobots: false,
  ipdataApi: true,
  production: false,
  salesForceImplementation: true, //Flag to implement LeadGenForm Submit to Resco/SalesForce - (Currently - SalesForce)
  envName: 'staging',
  GOOGLE_API_KEY: 'AIzaSyBWTwEVB_uz4wSVjMYySxFK4yLihzpep8g',
  IPDATA_API_KEY: '42659fc9105412b2a308a3e290e3f9d132d7f7a0e71f6fcf70c85c6b',
  // GOOGLE_ANALYTIC_KEY: 'UA-129142174-1',
  adobeLaunchScript: '//assets.adobedtm.com/launch-ENc5bce53fadfd42dbb08b27acb53dc333-staging.min.js',
  beaconScriptSrc: '//cdn.evgnet.com/beacon/thercyc/development/scripts/evergage.min.js',

  API_BASE_URL: 'https://staginggatewayapi.ritzcarltonyachtcollection.com/cms-content',
  IMG_BASE_URL: '', //  devgatewayapi micro services devgatewayapi
  IMG_CDNHOST_URL: 'https://cdnstaging.ritzcarltonyachtcollection.com',
  ELASTIC_API_BASE_URL: 'https://staginggatewayapi.ritzcarltonyachtcollection.com/rcyc-yachtsearch',
  ELASTIC_API_BASE_URL_EBROCHURE: 'https://staginggatewayapi.ritzcarltonyachtcollection.com/rcyc-ebrochure',
  ELASTIC_API_BASE_URL_LEADGENFORM: 'https://api.ritzcarltonyachtcollection.com',
  API_BASE_URL_RESERVATION : 'https://staginggatewayapi.ritzcarltonyachtcollection.com/rcyc-reservation',
  API_BASE_URL_EDOC : 'https://staginggatewayapi.ritzcarltonyachtcollection.com/rcyc-edoc',
  API_BASE_URL_ADONIS_CAREER_LIST : 'https://portal.adonis.no/AWR_RCYC/VacancyListService.ashx?l=',
  SALESFORCE_WEB_TO_LEAD_API_URL : 'https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DD7000000o50g',
  sALESFORCE_WEB_TO_CASE_API_URL : 'https://rcyc--uatenv.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DD7000000o50g',
  SALESFORCE_LEADGENFORM_API_OID : '00DD7000000o50g',
  SALESFORCE_LEADGENFORM_RET_URL : 'https://staging.ritzcarltonyachtcollection.com/complete',
};
