import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Router } from '@angular/router'
@Component({
  selector: 'app-rcyc-portal-closed-info-popup',
  templateUrl: './rcyc-portal-closed-info-popup.component.html',
  styleUrls: ['./rcyc-portal-closed-info-popup.component.css']
})
export class RcycPortalClosedInfoPopupComponent implements OnInit {

  @ViewChild('reservationSwal', { static: true }) reservationSwal: SwalComponent;
  modalData: any;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  openModal(modalData) {
    this.modalData = modalData;
    this.reservationSwal.show();
  }

  onClose(event) {
    this.reservationSwal.nativeSwal.close();
  }
  
  beforeOpen(event) {

  }

  cancelReservClick() {
    this.reservationSwal.nativeSwal.close();
  }

  keepReservClick() {
    this.reservationSwal.nativeSwal.close();
  }

  swalButtonClick() {
    this.reservationSwal.nativeSwal.close();  
  }

}
