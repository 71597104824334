<!--
  Variants:
    grid
    grid-small
    list
    featured
-->

<div class="bg-light-blue-2 lg:flex " *ngIf="data" [ngClass]="{
  'flex-col h-full': variant === 'grid' || variant === 'grid-small'
}">
  <div class="relative">
    <div class="aspect-[5/4]" [ngClass]="{
        'lg:w-[344px]': variant === 'list' ||  variant === 'excursion',
        'lg:w-[492px]': variant === 'featured',
      }">
      <app-rcyc-image-slider *ngIf="data.slider?.length" [parent]="parent" [id]="data.id" [data]="data.slider"
        [imageScrollPosition]="data?.imageMode"></app-rcyc-image-slider>
      <div *ngIf="data.highlight"
        class="absolute left-0 top-0 uppercase text-sm px-2 py-[3px] bg-white/40 backdrop-blur-effect">
        {{data.highlight}}
      </div>
    </div>
  </div>

  <div
    [class]="textSectionCSSClasses ? 'lg:flex lg:flex-grow justify-between ' + textSectionCSSClasses : 'lg:flex lg:flex-grow justify-between '">
    <div [ngClass]="{
      'mb-4 lg:mb-0': variant === 'excursion',
    }">
      <div class="uppercase mb-1 text-[14px] font-normal tracking-[0.168px] leading-[23.1px] font-['proxima-nova']"
        *ngIf="data.yachtName" [ngClass]="{
          'italic mb-3': variant === 'grid-small',
          'italic': variant !== 'featured'
        }">
        {{data.yachtName}}
      </div>
      <h2
        class="mt-[12px] text-[23px] leading-[26.45px] mt-[12px] text-[14px] font-normal tracking-[0.276px] font-['Adobe Caslon Pro']"
        [ngClass]="{
        'lg:text-[23px] lg:leading-6 font-normal mb-2 xl:mb-6': variant === 'list' || variant === 'excursion',
        'sm:h-auto h-20 lg:text-[23px] lg:leading-6 font-normal ': variant === 'featured',
        'md:min-h-16 lg:text-[23px] lg:leading-6 font-normal ': variant === 'grid',
        'lg:min-h-20': variant === 'grid-small',
        }">
        {{data.voyageName}}
      </h2>
      <div *ngIf="!hideInfo && variant !== 'excursion'"
        [class]="voyageDetailsCSSClasses ? 'text-content flex uppercase ' + voyageDetailsCSSClasses : 'text-content flex uppercase '">
        <div class="flex flex-col gap-3">
          <div class="text-[14px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
            [ngClass]="{'mr-16 sm:mr-0 sm:h-auto h-[53px] text-[16px]': variant === 'featured'}"
            *ngIf="data.voyageStartDate">
            {{data.voyageStartDate | date:
            'fullDate':'UTC'}}</div>
          <div class="whitespace-break-spaces lg:whitespace-nowrap"
            *ngIf="data.startingPrice === 0 || data.startingPrice">
            <span *ngIf="staticDrupalTexts && staticDrupalTexts[8]"
              class="text-[14px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
              [innerHTML]="staticDrupalTexts[8]?.field_com_title+': '">
            </span>
            <span
              *ngIf="!data?.pcfiToggleStatus && data?.suiteAvailability && data?.suiteAvailability >= 1 && data?.startingPriceMap && data?.portFeeMap && currencyCode && data?.startingPriceMap[currencyCode]">
              <span *ngIf="currencyCode === 'USM'">
                <span class="text-[14px]  font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
                  *ngIf="(data.startingPrice % 1) === 0">{{data.startingPriceMap[currencyCode] |
                  currency:'USD':'symbol':'1.0-0'}}</span>
                <span class="text-[14px]  font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
                  *ngIf="(data.startingPrice % 1) !== 0">{{data.startingPriceMap[currencyCode] |
                  currency:'USD':'symbol':'1.2-2'}}</span>
                <span class="text-[14px]  font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']">&nbsp;
                  USD*</span>
              </span>
              <span *ngIf="currencyCode !== 'USM'">
                <span class="text-[14px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
                  *ngIf="(data.startingPrice % 1) === 0">{{data.startingPriceMap[currencyCode] |
                  currency:currencyCode:'symbol':'1.0-0'}}</span>
                <span class="text-[14px]  font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
                  *ngIf="(data.startingPrice % 1) !== 0">{{data.startingPriceMap[currencyCode] |
                  currency:currencyCode:'symbol':'1.2-2'}}</span>
                <span
                  class="text-[14px]  font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']">&nbsp;{{currencyCode}}*</span>
              </span>
            </span>
            <span
              *ngIf="(data?.pcfiToggleStatus || !data?.suiteAvailability || data?.suiteAvailability < 1 || !data?.startingPriceMap || !data?.portFeeMap || !currencyCode || !data?.startingPriceMap[currencyCode])"
              [innerHTML]="PCFIToggleInfo?.field_toggle_text"></span>
          </div>
        </div>
        <div class="flex flex-col gap-3">
          <div *ngIf="staticDrupalTexts && staticDrupalTexts[7] && data.nights"
            class="text-[14px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']">{{data.nights}}
            {{staticDrupalTexts[7]?.field_com_title}}</div>
          <div class="relative flex gap-1 group mr-auto " *ngIf="data.ports">
            <span
              class="underline underline-offset-4 text-[14px]  font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']" *ngIf="staticDrupalTexts && staticDrupalTexts[6]">{{ports.length}}
              {{staticDrupalTexts[6]?.field_com_title}}</span>
            <div class="flex cursor-pointer items-center">
              <img src="assets/img/info--grey.svg" alt="Ports list" class="w-4 h-4">
            </div>
            <div
              class="absolute bottom-full left-1/2 -translate-x-1/2 flex flex-col invisible group-hover:visible ml-[20px] lg:ml-0">
              <ul class="p-2 m-0 bg-white min-w-[180px] shadow-tooltip mb-[7px]">
                <li *ngFor="let item of ports; let index=index"
                  class="text-[13px] m-0 flex items-center font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']">
                  {{index+1}}. {{item.name}}
                  <img src="/assets/img/anchor.svg" alt="" *ngIf="item.isAnchor" class="ml-2 w-3">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ul class="-mt-[6px] border-solid border-t border-primary pt-6 pb-[14px] h-auto lg:h-[18rem] overflow-auto"
        *ngIf="showPorts && data.portData && variant !== 'excursion'">
        <li *ngFor="let item of ports; let index=index" class="m-0 mb-[10px] flex items-center">
          {{index+1}}. {{item.name}}
          <img src="/assets/img/anchor.svg" alt="" *ngIf="item.isAnchor" class="ml-2 w-4">
        </li>
      </ul>

      <ng-content></ng-content>

      <div class="text-left lg:m-0 lg:mt-2 md:mb-9 mb-4 mt-2" *ngIf="variant !== 'excursion'">
        <span class="text-left text-xs leading-[14px] text-[#1c1c1c]" [innerHTML]="additionalCostInfo"
          *ngIf="variant === 'list' && additionalCostInfo && data?.suiteAvailability && data?.suiteAvailability >= 1 && currencyCode"></span>
      </div>

      <div *ngIf="variant === 'excursion'" [innerHTML]="data.summaryDesc"></div>
    </div>

    <div class="flex flex-col gap-2 lg:gap-6" *ngIf="!hideButtons && variant !== 'excursion'" [ngClass]="{
        'lg:w-[210px] lg:justify-center': variant === 'list',
        'lg:flex-row': variant !== 'list' && variant !== 'grid-small'
      }">
      <div *ngIf="detailsUrl" [ngClass]="{
          'lg:w-1/2': variant !== 'list' && variant !== 'grid-small',
        }">
        <a [routerLink]="detailsUrl" (click)="setPrevPage()" (keyup)="setPrevPage()" *ngIf="staticDrupalTexts && staticDrupalTexts[5]"
          class="link-cta button-secondary uppercase whitespace-nowrap text-[16px] font-normal tracking-[0.192px] leading-[26.4px] font-['proxima-nova']"
          [innerHTML]="staticDrupalTexts[5]?.field_com_title"></a>
      </div>

      <div [ngClass]="{
          'lg:w-1/2': variant !== 'list' && variant !== 'grid-small',
        }">
        <a [routerLink]="'/request-quote/'+data.voyageId" (click)="setPrevPage()" (keyup)="setPrevPage()"
          *ngIf="staticDrupalTexts && staticDrupalTexts[4] && !isFromBookingConfigFlag || (data?.pcfiToggleStatus || !data?.suiteAvailability || data?.suiteAvailability < 1 || !data?.startingPriceMap || !data?.portFeeMap || !currencyCode || !data?.startingPriceMap[currencyCode])"
          class="link-cta button-secondary button-secondary--light uppercase whitespace-nowrap text-[16px] font-normal tracking-[0.192px] leading-[26.4px] font-['proxima-nova']"
          [innerHTML]="staticDrupalTexts[4]?.field_com_title"> </a>
        <a (click)="setPrevPage(); openBookingConfig()" (keyup)="setPrevPage()"
          *ngIf="staticDrupalTexts && staticDrupalTexts[3] && isFromBookingConfigFlag && !data?.pcfiToggleStatus && data?.suiteAvailability && data?.suiteAvailability >= 1 && data?.startingPriceMap && data?.portFeeMap && currencyCode && data?.startingPriceMap[currencyCode]"
          class="link-cta button-secondary button-secondary--light uppercase whitespace-nowrap text-[16px] font-normal tracking-[0.192px] leading-[26.4px] font-['proxima-nova']"
          [innerHTML]="staticDrupalTexts[3]?.field_com_title"></a>
      </div>
    </div>

    <div *ngIf="variant === 'excursion'" class="min-w-48 flex flex-col gap-2 justify-start uppercase">
      <span class="text-[16px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
        *ngIf="data.tourCatCd" [innerHTML]="data.tourCatCd"> </span>
      <span class="splitLasth4 text-[16px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
        *ngIf="staticDrupalTexts && staticDrupalTexts[2] && data?.activityLvl == 'Easy'" [innerHTML]="staticDrupalTexts[2]?.field_com_title"></span>
      <span class="splitLasth4 text-[16px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
        *ngIf="staticDrupalTexts && staticDrupalTexts[1] && data?.activityLvl == 'Medium'" [innerHTML]="staticDrupalTexts[1]?.field_com_title"></span>
      <span class="splitLasth4 text-[16px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
        *ngIf="staticDrupalTexts && staticDrupalTexts[0] && data?.activityLvl == 'Hard'" [innerHTML]="staticDrupalTexts[0]?.field_com_title"></span>
      <span class="text-[16px] font-normal tracking-[0.168px] leading-[26.4px] font-['proxima-nova']"
        *ngIf="data.durationHrs" [innerHTML]="data.durationHrs + ' hours'"></span>
    </div>

    <div class="mt-4 min-h-[33px] text-left" *ngIf="variant !== 'list' && variant !== 'excursion'">
      <span *ngIf="additionalCostInfo && data?.suiteAvailability && data?.suiteAvailability >= 1 && currencyCode"
        class="text-left text-xs leading-[14px] text-[#1c1c1c]" [innerHTML]="additionalCostInfo"></span>
    </div>
  </div>

</div>

<app-rcyc-plan-your-voyage #openBookingConfiguratorModal [voyageId]="data.voyageId" [yachtName]="data.yachtName"
  [currencyCode]="currencyCode"></app-rcyc-plan-your-voyage>