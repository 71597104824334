import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class RcycPlanYourVoyageService {

    // API_BASE_URL: any =   AppConfig.API_BASE_URL;
  constructor(

        private http: HttpClient
    ) { }

    /* =====================================================================================================================================
    Funtion to call All Apis 
    * ====================================================================================================================================*/



}
