import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { RcycShoreExcursionsComponent } from './rcyc-shore-excursions/rcyc-shore-excursions.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-rcyc-full-img-tile',
  templateUrl: './rcyc-full-img-tile.component.html',
  styleUrls: ['./rcyc-full-img-tile.component.css']
})
export class RcycFullImgTileComponent implements OnInit {
  @Input() data: any
  @ViewChild('shoreExcursionsModal', { static: false }) shoreExcursionsModal: RcycShoreExcursionsComponent;
  image: any;

  constructor( 
    private sanitizer: DomSanitizer,) {  }

  ngOnInit() {   
    this.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.field_tile_image);
  }
  shoreExcursionsClick(title: any) {
    this.shoreExcursionsModal.modalOpen(title);
  }
}
