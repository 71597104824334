import {
  Component, Input, OnInit, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { ActivatedRoute } from '@angular/router';
import { RcycNewFindACruiseComponent } from '../rcyc-new-find-a-cruise/rcyc-new-find-a-cruise.component';
const { detect } = require('detect-browser');
const browser: any = detect();

@Component({
  selector: 'app-rcyc-offers-and-programs-details',
  templateUrl: './rcyc-offers-and-programs-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./rcyc-offers-and-programs-details.component.css']
})
export class RcycOffersAndProgramsDetailsComponent implements OnInit {
  apiResponseB: any;
  apiResponseE: any;
  drupalVoyages: string[] = null;
  responseArray: any;
  currentUrlKey: any;
  @ViewChild('rcycNewFindACruiseComponent') rcycNewFindACruiseComponent: RcycNewFindACruiseComponent;
  @Input() data: any;

  constructor(
    private rcycCommonService: RcycCommonService,
    private rcycHomeService: RcycHomeService,
    private rcycInternalApisService: RcycInternalApisService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.rcycCommonService.changeSubHeaderStatus(false);
    this.rcycCommonService.changeSubHeaderDataArray_A({});
  }

  ngOnInit() {
    const snapshotUrl = this.activatedRoute.snapshot as any;
    const currentUrlPathAsArray = snapshotUrl._urlSegment.segments;
    this.currentUrlKey = currentUrlPathAsArray[0].path;
    if (this.data && this.data.length > 0) {
      this.apiResponseB = { title: this.data[0]?.title, field_com_description: this.data[0]?.body, field_com_eyebrow: this.data[0]?.field_sub_header, field_com_image: this.data[0]?.field_image_thumbnail, pageName: 'offersProgramsDetails', field_com_5050_variant: "tall_photo_variant", field_com_5050_alignment: "left" };
      if (this.data[0]?.field_voyages && this.data[0]?.field_callout_text) {
        this.drupalVoyages = this.data[0]?.field_voyages?.split('###').length > 0 ? this.data[0]?.field_voyages?.split('###') : null;
      }
    }
    this.getBannerImage();
    this.getArticleData();
  }

  getBannerImage() {
    let apiUrlBanner = '/api/v1/banners/offers-programs/' + this.currentUrlKey + '/b?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlBanner).subscribe(
      (response: any = []) => {
        if (response && response.length > 0) {
          this.responseArray = response;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getArticleData() {
    const apiUrlE = '/api/v1/featured-articles/' + this.currentUrlKey + '?_format=json';
    this.rcycHomeService.home(apiUrlE).subscribe(
      response => {
        let apiResponseETemp: any;
        apiResponseETemp = response;
        if (apiResponseETemp && apiResponseETemp.length) {
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.apiResponseE = res;
          }
        }
      },
      err => { }
    );
  }
}
