import { Component, OnInit } from '@angular/core';
import { RcycToasterMessagesService } from './rcyc-toaster-messages.service';
import * as moment from 'moment';

@Component({
  selector: 'app-rcyc-toaster-messages',
  templateUrl: './rcyc-toaster-messages.component.html',
  styleUrls: ['./rcyc-toaster-messages.component.css']
})
export class RcycToasterMessagesComponent implements OnInit {
  // messageToShow: any;
  // messageType: any;
  visibilityTime: any;
  MessageArray: any = [];
  type: any = 'success';

  constructor(
    private rcycToasterMessagesService: RcycToasterMessagesService
  ) { }

  ngOnInit() {
    // this.messageToShow = "This is a test message";
    // this.messageType = "success";
    // this.MessageArray.push({ messageToShow: 'This is a test message', messageType: 'success', divId: moment().unix() });
    this.messagSetMethod();
  }

  messagSetMethod() {
    this.rcycToasterMessagesService.getToastMessage().subscribe(
      response => {        
        let TempArray: any = response;
        let TimeIntrevalTemp: any = '3500';
        let r = Math.random().toString(36).substring(7);
        TempArray['divId'] = r + moment().unix();
        TempArray['closeClick'] = false;
        this.MessageArray.push(TempArray);        
        if (TempArray && TempArray.TimeIntreval) {
          // TimeIntrevalTemp = TempArray.TimeIntreval+1000;
        }
        
        setTimeout(() => this.removeFadeOut(TempArray['divId']), TimeIntrevalTemp );
        setTimeout(() => this.addFadeOut(TempArray['divId']), 500);
        setTimeout(() => this.addFadeOutClass(TempArray['divId']), TimeIntrevalTemp-500);  
      },
      err => {
        console.log('err');
        console.log(err);
      }
    );
  }

  addFadeOutClass(TempId) {
    var element = document.getElementById(TempId);
    element.classList.add("closeClickClass");
    // element.classList.add("fadeInDown");
  }

  addFadeOut(TempId) {
    var element = document.getElementById(TempId);
    element.classList.remove("animationFirstClass");
    // element.classList.remove("fadeOutUp");
  }
  removeFadeOut(TempId) {
    this.MessageArray.map((MsgArray, index) => {
      if (MsgArray.divId == TempId) {
       this.MessageArray.splice(index, 1);
      }
    });

  }
  closeToasterMessage(TempId) {
    var element = document.getElementById(TempId);
    element.classList.remove("animationFirstClass");
    setTimeout(() => this.removeFadeOut(TempId), 1000);
  }

}
