import { Component, OnInit, Renderer2, ElementRef, AfterViewInit, HostListener, ChangeDetectorRef, OnDestroy, ViewChild, Input, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { RcycHomesService } from '../../rcyc-services/rcyc-home/rcyc-home.service';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RcycVoyagesService } from '../rcyc-voyages/rcyc-voyages.service';
import { AppConfig } from '../../rcyc-config/config';
import { element } from 'protractor';
import { switchMap, first } from 'rxjs/operators';
import { RcycGlobalMessagesService } from '../../rcyc-services/rcyc-global-messages/rcyc-global-messages.service';
import { RcycNewFindACruiseService } from './rcyc-new-find-a-cruise.service';
// import { IfObservable } from 'rxjs/observable/IfObservable';
declare let $: any;
import { SharedServiceService } from '../../rcyc-services/rcyc-shared/shared-service.service';
import { Subscription, of } from 'rxjs';
import { HomeComponent } from '../rcyc-home/rcyc-home.component';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycSpecialOffersLandingService } from '../rcyc-special-offers-landing/rcyc-special-offers-landing.service';

@Component({
  selector: 'app-rcyc-new-find-a-cruise',
  templateUrl: './rcyc-new-find-a-cruise.component.html',
  styleUrls: ['./rcyc-new-find-a-cruise.component.css']
})
export class RcycNewFindACruiseComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {
  regionsSelectArray: any = [];
  regions: any = false;
  departureSelectArray: any = [];
  departureDate: any = false;
  durationSelectArray: any = [];
  duration: any = false;
  portsSelectArray: any = [];
  ports: any = false;
  yachtSelectArray: any = [];
  yacht: any = false;
  offerAndProgramSelectArray: any = [];
  offerAndProgram: any = false;
  FindCruiseRegion: any = [];
  FindCruiseDepdate: any = [];
  FindCruiseDuration: any;
  FindCruisePorts: any = [];
  FindCruiseYacht: any;
  findACruiseArray: any;
  compareClickStatus: any = false;
  gridClickStatus: any = false;
  listClickStatus: any = true;
  goBackClickStatus: any = false;
  compareCheckboxClickStatusId: any;
  compareCheckboxArray: any = [];
  region: any;
  departure_date: any;
  offer_program: any;
  Fistfilterfield: any = [];
  regionselect: any = [];
  departure_dateselect: any = [];
  duration_select: any = [];
  ports_select: any = [];
  yacht_select: any = [];
  offer_program_select: any = [];
  searchdata: any;
  comparelimterr: any = false;
  page: any = 0;
  perPage: any = 9;
  pages: any = [];
  fullsearchresult: any = [];
  totalPagecnt: any;
  findcruisehomeflag: any = false;
  currentpag: any = 0;
  lat: any = 40.73061;
  lng: any = -73.935242;
  totalData: number;
  UserDetails: any = {};
  apiUrlMetatag: any;
  departures: any;
  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  IMG_CDNHOST_URL: any = AppConfig.IMG_CDNHOST_URL;
  dep_dateselect: any = [];
  serachval: any = {};
  latitude: any;
  longitude: any;
  zoom: any;
  location: any;
  status: any = 1;
  select_feild: any;
  slct_regionname: any = [];
  slct_deptnname: any = [];
  slct_portnname: any = [];
  slct_yachtName: any = [];
  slct_duration: any = [];
  FindcruiseAPI: any;
  voyagesResult: any;
  totalpageno: any;
  currencyCode: any;
  current_view: any = 'list';
  offertab_group: any;
  temp_deptdate: any;
  feild: any;
  order: any;
  compareLimitError: any = this.rcycGlobalMessagesService.compareLimitError;
  gridTempValue = 0;
  selectedValue = 'DepartureDateAscending';
  initialDrupalCall = true;
  snapshotUrl: any;
  currentUrlKey: any;
  currentUrlPathAsArray = [];
  paginationPrevClickstatus = false;
  paginationNextClickStatus = false;
  paginationBtnClick = false;
  getUserPreferenceData;
  restoreRegionValues = false;

  regionTypedFlag = false;
  regionTyped = '';
  regionTypedInitialFlag = false;
  FindCruiseRegionTemp = [];
  FindCruiseFilteredRegion = [];
  yachtName: string;
  portTypedFlag = false;
  portTyped = '';
  portTypedInitialFlag: Boolean = false;
  FindCruisePortTemp: any = [];
  FindCruiseFilteredPort = [];

  departureDateTypedFlag = false;
  departureDateTyped = '';
  departureDateInitialFlag = false;
  FindCruiseDepartureDateTemp = [];
  FindCruiseFilteredDepartureDate = [];

  yachtTypedFlag: boolean = false;
  yachtTyped = '';
  yachtTypedInitialFlag: Boolean = false;
  FindCruiseYachtTemp: any = [];
  FindCruiseFilteredYacht = [];

  durationTypedFlag: boolean = false;
  durationTyped = '';
  durationTypedInitialFlag: Boolean = false;
  FindCruiseDurationTemp: any = [];
  FindCruiseFilteredDuration = [];

  regionDropDownFlag = false;
  departureDateDropDownFlag = false;
  portDropDownFlag = false;
  yachtDropDownFlag: boolean = false;
  durationDropDownFlag: boolean = false;
  findCruiseData: any;
  formattedDate: any;
  findCrusieSearchUrlData: any;
  findCruiseUrlCallGetData = false;
  searchUrlData = [];

  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December'];
  regionDDTabIndex: any = '0';
  departureDateDDTabIndex: any = '0';
  portsDDTabIndex: any = '0';
  yachtDDTabIndex: any = '0';
  durationDDTabIndex: any = '0';
  portValueGet = false;

  regionFocusFlag = false;
  departureDateFocusFlag = false;
  portFocusFlag = false;
  yachtFocusFlag = false;
  durationFocusFlag = false;
  sortByBottomSelectOuterTabIndex = '0';
  regionIconFocussed = false;
  departureDateIconFocussed = false;
  portIconFocussed = false;
  yachtIconFocussed = false;
  durationIconFocussed = false;
  gridSelectedStatus = true;
  listSelectedStatus = false;
  paginationNextBottomFocussed = false;
  paginationNextTopFocussed = false;
  gridFocus = false;
  listFocus = false;
  tabletype: any = '';
  DataTableDefaultViewArray: any = [];
  DataTableDefaultView: any;
  showHideFilterStatus = false;
  imageMode: string;
  loadMoreClickCount: number = 0;
  loadMoreClickStatus = false;
  filtervalueClickStatus = false;
  sortByClickStatus = false;
  isMobileView = false;
  onlyShowFilterStatus = false;
  showFilterStickyHeader = false;
  loadMoreApiStatus = false;
  isMobileMax_600 = false;
  scrollEndFiterStatus = false;
  isMobileMax_480 = false;
  footerHeight: number = 0;
  maxEachOuterHeight: number = 0;
  maxEachVoyagenameHeight: number = 0;
  maxEachDDFHeight: number = 0;
  maxExtrafeeHeight: number = 0;
  viewingVoyageId: string;
  PCFIToggleInfo: any = {};
  PCFIVoyageIdArray: any = [];
  previewVoyagesList: Array<string> = [];
  previewvoyageRaqAltText: string = "Preview Only";
  getPreviewVoyagesDrupalSubscription: Subscription;
  criteriaResultMasterData: any = {};
  voyageListMasterData: any = null;
  filteredResultMasterData: any = null;
  isFilterApplied: Boolean = false;
  findACruiseArrayStatus: any = false;
  @ViewChild('topFilters', { static: false }) private testDiv: ElementRef<HTMLDivElement>;
  isTestDivScrolledIntoView: boolean = true;
  clearFindCruiseData: boolean = false;
  infoCommonMsg: any;
  additionalCostInfo: any;
  pageNumbersToShow: number;
  apiResponseB: any;
  @Input() pageName: string;
  @Input() selectedRegion: string;
  @Input() drupalVoyages: string[];
  @Output() compareClickEventEmitter = new EventEmitter<boolean>();

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let totalHeight: any = 0;
    // With old header
    if (window.innerWidth < 767) {
      if (document.querySelector('#rcyc-page-header .header .section-2')) {
        totalHeight += (document.querySelector('#rcyc-page-header .header .section-2') as HTMLElement).offsetHeight;
      }
      if (document.querySelector('#rcyc-page-findACruise .section-2')) {
        totalHeight += (document.querySelector('#rcyc-page-findACruise .section-2') as HTMLElement).offsetHeight;
      }
      if (document.querySelector('#rcyc-page-findACruise .section-3')) {
        totalHeight += (document.querySelector('#rcyc-page-findACruise .section-3') as HTMLElement).offsetHeight;
      }
      if (document.querySelector('#rcyc-page-findACruise .hideFilterBtnForMobile')) {
        totalHeight += (document.querySelector('#rcyc-page-findACruise .hideFilterBtnForMobile') as HTMLElement).offsetHeight;
        totalHeight += (document.querySelector('#rcyc-page-findACruise .hideFilterBtnForMobile') as HTMLElement).offsetHeight;
      }
      // hideFilterBtnForMobile
    }
    if (document.querySelector('#rcyc-page-header .header .section-1 .menu')) {
      totalHeight += (document.querySelector('#rcyc-page-header .header .section-1 .menu') as HTMLElement).offsetHeight;
    }
    if (document.querySelector('#rcyc-page-header')) {
      totalHeight += (document.querySelector('#rcyc-page-header') as HTMLElement).offsetHeight;
    }
    if (document.querySelector('#rcyc-page-findACruise .section-1.dropdown_sec')) {
      totalHeight += (document.querySelector('.section-1.dropdown_sec') as HTMLElement).offsetHeight;
    }

    this.showFilterStickyHeader = false;
    if (document.documentElement.scrollTop + document.documentElement.offsetHeight != document.documentElement.scrollHeight) {
      if (window.pageYOffset > totalHeight) {
        this.onlyShowFilterStatus = true;
      } else {
        this.onlyShowFilterStatus = false;
      }
      this.scrollEndFiterStatus = false;
    }

    const showFilterDiv = (document.querySelector('.showFilterOuter') as HTMLElement);
    if (window.scrollY > 0 && showFilterDiv && window.innerWidth < 480 && this.onlyShowFilterStatus) {
      (document.querySelector('.showFilterOuter') as HTMLElement).style.marginTop = '-90px';
    } else if (window.scrollY > 0 && showFilterDiv && window.innerWidth < 600 && this.onlyShowFilterStatus) {
      (document.querySelector('.showFilterOuter') as HTMLElement).style.marginTop = '-79px';
    } else if (window.scrollY > 0 && showFilterDiv && window.innerWidth < 767 && this.onlyShowFilterStatus) {
      (document.querySelector('.showFilterOuter') as HTMLElement).style.marginTop = '-116px';
    } else if (window.innerWidth == 768 && showFilterDiv) {
      if (this.onlyShowFilterStatus) {

        (document.querySelector('.showFilterOuter') as HTMLElement).style.marginTop = '-138px';
      } else {
        (document.querySelector('.showFilterOuter') as HTMLElement).style.marginTop = '-57px';
      }
    }
    if (window.scrollY == 0) {
      if ((document.querySelector('.dropdown_sec') as HTMLElement)) {
        (document.querySelector('.dropdown_sec') as HTMLElement).style.marginTop = '0';
      }
      if (window.innerWidth < 767 && !this.showHideFilterStatus) {
        if ((document.querySelector('.showFilterOuter') as HTMLElement)) {
          (document.querySelector('.showFilterOuter') as HTMLElement).style.marginTop = '0';
        }
        if ((document.querySelector('.section-4') as HTMLElement)) {
          (document.querySelector('.section-4') as HTMLElement).style.marginTop = '0';
          // (document.querySelector('.section-4') as HTMLElement).style.paddingTop = '0';
        }
      }
      if (window.innerWidth == 768) {
        if (this.onlyShowFilterStatus && showFilterDiv) {
          (document.querySelector('.showFilterOuter') as HTMLElement).style.marginTop = '-63px';
        }
        if ((document.querySelector('.section-4') as HTMLElement)) {
          (document.querySelector('.section-4') as HTMLElement).style.marginTop = '0';
          (document.querySelector('.section-4') as HTMLElement).style.paddingTop = '0';
        }
      }

      // this.showHideFilterStatus=false;
      // this.onlyShowFilterStatus=false;
      // this.showFilterStickyHeader=false;
    } else {
      if (window.innerWidth >= 768 && this.onlyShowFilterStatus && window.innerWidth <= 890) {
        if ((document.querySelector('.dropdown_sec') as HTMLElement)) {
          (document.querySelector('.dropdown_sec') as HTMLElement).style.marginTop = '-138px';
        }
        if (showFilterDiv) {
          (document.querySelector('.showFilterOuter') as HTMLElement).style.marginTop = '-91px';
        }

      }
    }

    if (this.testDiv) {
      const rect = this.testDiv.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isTestDivScrolledIntoView = topShown
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // event.target.innerWidth;
    if (window.innerWidth > 560) {
      this.eachOuterCruiseHeightAlign();
    }
    this.showHideFilterStatus = window.innerWidth > 1023;
    this.isMobileView = !this.showHideFilterStatus;
    document.body.style.overflowY = "unset";
  }

  constructor(
    private rcycHomeService: RcycHomeService,
    private changeDetector: ChangeDetectorRef,
    private rcycCommonService: RcycCommonService,
    private renderer: Renderer2,
    private router: Router,
    private rcycHomecontentService: RcycHomesService,
    private rcycVoyagesService: RcycVoyagesService,
    private rcycGlobalMessagesService: RcycGlobalMessagesService,
    private rcycFindACruiseService: RcycNewFindACruiseService,
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private sharedServiceService: SharedServiceService,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycSpecialOffersLandingService: RcycSpecialOffersLandingService,
  ) {
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlKey = this.currentUrlPathAsArray[0].path;
    const isFromContructorFlag: boolean = this.currentUrlKey.toLowerCase() === 'find-a-cruise' || this.currentUrlKey.toLowerCase() === 'loyalty';;

    this.activatedRoute.params.subscribe(params => {
      if (params.tabletype) {
        this.tabletype = params.tabletype; //grid or list
      }
      else {
        this.tabletype = '';
      }
    });
    this.getPreviewVoyagesDrupalSubscription = this.rcycFindACruiseService.getPreviewVoyages().subscribe(
      value => {
        this.previewVoyagesList = value.field_voyages;
        this.previewvoyageRaqAltText = value.field_alternative_label;
      }
    );
    this.getDataTableDefaultView();
    // Current subHeader Status
    this.rcycCommonService.changeSubHeaderStatus(false);
    // Changing subheader A value
    this.rcycCommonService.changeSubHeaderDataArray_A({});
    if (!this.rcycCommonService.getSessionData('voyageBackButtonPressed')) {
      this.rcycCommonService.removeSessionData('findACruiseSearchCriteria');
      this.rcycCommonService.removeSessionData('userPreferenceData');
    }
    const backBtnUrl = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
    if (!localStorage.getItem('UserDetails')) {
      setTimeout(() => {
        if (backBtnUrl) {
          const compareBtnPressedFlag = this.rcycCommonService.getSessionData('compareClickStatus');
          if (compareBtnPressedFlag) {
            // this.compareClickStatus = true;
            const compareCheckboxArray = this.rcycCommonService.getSessionData('voyageCompareDetails');
            if (compareCheckboxArray.length) {
              this.compareCheckboxArray = compareCheckboxArray;
            }
          } else {
            this.get_userbasic_details(isFromContructorFlag);
          }
        } else {

          this.rcycCommonService.removeSessionData('compareClickStatus');
          this.rcycCommonService.removeSessionData('voyageCompareDetails');
          this.get_userbasic_details(isFromContructorFlag);
        }
      }, 2000);
    } else {
      if (backBtnUrl) {
        const compareBtnPressedFlag = this.rcycCommonService.getSessionData('compareClickStatus');
        if (compareBtnPressedFlag) {
          this.compareClickStatus = true;
          const compareCheckboxArray = this.rcycCommonService.getSessionData('voyageCompareDetails');
          if (compareCheckboxArray && compareCheckboxArray.length) {
            this.compareCheckboxArray = compareCheckboxArray;
          }
        } else {
          this.get_userbasic_details(isFromContructorFlag);
        }
      } else {
        this.rcycCommonService.removeSessionData('compareClickStatus');
        this.rcycCommonService.removeSessionData('voyageCompareDetails');
        this.get_userbasic_details(isFromContructorFlag);
      }
    }
    if (this.rcycCommonService.getData('searchcruiseresult')) {
      this.serachval = this.rcycCommonService.getData('searchcruiseresult');
    }
    if (this.rcycCommonService.getData('totalData')) {
      this.totalData = parseInt(this.rcycCommonService.getData('totalData'));
    }
    if (this.rcycCommonService.getData('dep_dateselect')) {
      this.dep_dateselect = this.rcycCommonService.getData('dep_dateselect');
    }
    this.fullsearchresult = this.rcycCommonService.getData('voyagesResult');

    setTimeout(() => {
      this.rcycCommonService.removeSessionData('voyagesResult');
      this.rcycCommonService.removeData('searchcruiseresult');
      this.rcycCommonService.removeData('totalData');
      this.rcycCommonService.removeData('dep_dateselect');
    }, 3000);

    if (this.serachval) {
      this.findcruisehomeflag = true;
      if (this.serachval.regionCodes) {
        this.regionselect = this.serachval.regionCodes;
      }

      if (this.serachval.departureYearMonths) {
        this.departure_dateselect = this.serachval.departureYearMonths;

        const temp_arry = [];

        this.departure_dateselect.map(function (val, i) {
          val = val.replace('_', '-');
          temp_arry.push(val + '-01');
        });

        this.FindCruiseDepdate = [];
        this.FindCruiseDepdate = temp_arry;

        const FilteredDepDateArray = [];
        if (temp_arry.length) {

          temp_arry.map((item, index) => {
            FilteredDepDateArray.push(this.months[parseInt(item.split('-')[1], 10) - 1] + ' ' + item.split('-')[0]);

            if (index === temp_arry.length - 1) {
              this.slct_deptnname = FilteredDepDateArray;
            }
          });
        }
      }

      if (this.serachval.durations) {
        this.duration_select = this.serachval.durations;
      }

      if (this.serachval.portCodes) {
        this.ports_select = this.serachval.portCodes;
      }

      if (this.serachval.yachtIds) {
        this.yacht_select = this.serachval.yachtIds;
      }

      const res = this.rcycCommonService.getPagignationArray(
        this.totalData,
        this.perPage,
        this.page
      );
      this.pages = res.pages;
    }

    // Get Image From Drupal
    this.getCruiseImageDrupal();
  }

  ngAfterContentInit(): void {
    if (this.pageName !== 'find-a-cruise')
      this.checkOtherPagesVoyages();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    // Call get Metatag function
    if(this.pageName === 'Voyages'){
      this.apiUrlMetatag = '/api/v1/find-a-cruise/meta?_format=json';
      this.rcycCommonService.getMetaTags(this.apiUrlMetatag);
    }
    this.getPCFIToggleInfoFn();
    this.getInfoCommonMsg();
    this.getInfoMessageData();
    this.pageNumbersToShow = 5;
    this.imageMode = 'map';

    if (window.innerWidth <= 1024) {
      this.isMobileView = true;
      this.pageNumbersToShow = 4;
    }
    else {
      this.showHideFilterStatus = true;
    }

    if (this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl')) {
      this.getUserPreferenceData = this.rcycCommonService.getSessionData('userPreferenceData');
      if (this.getUserPreferenceData) {
        if (this.getUserPreferenceData.sortBySelectdValue) {
          this.selectedValue = this.getUserPreferenceData.sortBySelectdValue;
        }
        if (this.getUserPreferenceData.gridClickStatus) {
          this.gridClickStatus = true;
          this.gridFocus = true;
          this.listFocus = false;
          setTimeout(() => {
            this.showDisplayClick('grid');
          }, 2000);
        } else {
          this.listFocus = true;
          this.gridFocus = false;
          this.compareClickStatus = true;
          setTimeout(() => {
            this.showDisplayClick('list');
            this.compareClickStatus = false;
          }, 2000);
        }
        if (this.getUserPreferenceData.imageMode) {
          this.imageMode = this.getUserPreferenceData.imageMode;
        }
        if (this.getUserPreferenceData.currentPage) {
          this.currentpag = this.getUserPreferenceData.currentPage;
        }
      }

      const findCruiseSearchData = this.rcycCommonService.getSessionData('findACruiseSearchCriteria');
      if (findCruiseSearchData && findCruiseSearchData.departureYearMonths) {
        this.departure_dateselect = findCruiseSearchData.departureYearMonths;
      }
    } else {
      this.selectedValue = 'DepartureDateAscending';
    }
    this.getCruiseImageDrupal();
  }

  getInfoMessageData() {
    const apiUrlB = '/api/v1/offersandprograms/content/e?_format=json';
    this.rcycHomeService.home(apiUrlB).subscribe(
      response => {
        let apiResponseBTemp: any;
        apiResponseBTemp = response;
        if (apiResponseBTemp && apiResponseBTemp.length) {
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });

          if (res.length) {
            // this.apiResponseBStatus = true;
            this.apiResponseB = res[0];
          }
        }
      },
      err => { }

    );
  }

  setLayoutViewStyle() {
    if (this.tabletype == 'list') {

      this.current_view = 'list';
      this.listClickStatus = true;
      this.compareClickStatus = false;
      this.gridClickStatus = false;
    }
    else if (this.tabletype == 'grid') {
      this.current_view = 'grid';
      this.listClickStatus = false;
      this.compareClickStatus = false;
      this.gridClickStatus = true;
    }
    else {
      if (this.tabletype == "") {

        if (this.DataTableDefaultView == 'list') {

          this.current_view = 'list';
          this.listClickStatus = true;
          this.compareClickStatus = false;
          this.gridClickStatus = false;
        }
        if (this.DataTableDefaultView == 'grid') {
          this.current_view = 'grid';
          this.listClickStatus = false;
          this.compareClickStatus = false;
          this.gridClickStatus = true;
        }
      }
      else {
        this.router.navigate(['/find-a-cruise']);
      }

    }
  }

  /**
   * Close all open dropedown
   * @param field
   */
  reset_filter(field) {
    if (field !== 'region') { this.region = false; }
    if (field !== 'departure_date') { this.departure_date = false; }
    if (field !== 'duration') { this.duration = false; }
    if (field !== 'ports') { this.ports = false; }
    if (field !== 'yacht') { this.yacht = false; }
    if (field !== 'offer_program') { this.offer_program = false; }
  }
  /**
   * Open selected search drop down
   * @param field
   */
  select_filter(field) {
    if (field === 'region') {
      if (this.FindCruiseRegion.length) {
        this.region = true;

        if (document.getElementById('region_dropdown')) {
          // document.getElementById('region_dropdown').style.visibility = 'visible';
          document.getElementById('region_dropdown').style.display = 'block';
          this.regionDropDownFlag = true;
        }
        this.regionTypedFlag = true;
        this.regionDDTabIndex = '-1';
        setTimeout(() => {
          if (document.getElementById('region_input')) {
            document.getElementById('region_input').focus();
          }
        }, 500);
      }
      this.regionTypedFlag = true;
      this.regionDDTabIndex = '-1';

      // Close Other drop Downs
      // Departure Date
      const departureDateDropDown = document.getElementById('departure_dropdown');
      if (departureDateDropDown && departureDateDropDown.style && departureDateDropDown.style.visibility) {
        // departureDateDropDown.style.visibility = 'hidden';
        departureDateDropDown.style.display = 'none';
        this.departureDateDropDownFlag = false;
      }
      // duration
      const durationDropDown = document.getElementById('duration_dropdown');
      if (durationDropDown && durationDropDown.style && durationDropDown.style.display) {
        durationDropDown.style.display = 'none';
        this.durationDropDownFlag = false;
      }
      // Ports
      const portDropDown = document.getElementById('ports_dropdown');
      if (portDropDown && portDropDown.style && portDropDown.style.visibility) {
        // portDropDown.style.visibility = 'hidden';
        portDropDown.style.display = 'none';
        this.portDropDownFlag = false;
      }
      // yacht
      const yachtDropDown = document.getElementById('yacht_dropdown');
      if (yachtDropDown && yachtDropDown.style && yachtDropDown.style.display) {
        yachtDropDown.style.display = 'none';
        this.yachtDropDownFlag = false;
      }

    }
    else if (field === 'departure_date') {
      // this.departure_date = !this.departure_date;
      if (this.FindCruiseDepdate.length) {
        this.departure_date = true;
        if (document.getElementById('departure_dropdown')) {
          // document.getElementById('departure_dropdown').style.visibility = 'visible';
          document.getElementById('departure_dropdown').style.display = 'none';
          this.departureDateDropDownFlag = true;
        }
        this.departureDateTypedFlag = true;
        this.departureDateDDTabIndex = '-1';
        setTimeout(() => {
          if (document.getElementById('departureDate_input')) {
            document.getElementById('departureDate_input').focus();
          }
        }, 500);
      }
      this.departureDateTypedFlag = true; // commment this line  when issue in date
      this.departureDateDDTabIndex = '-1';

      // Close Other drop Downs
      // Region
      const regionDropDown = document.getElementById('region_dropdown');
      if (regionDropDown && regionDropDown.style && regionDropDown.style.display) {
        // regionDropDown.style.visibility = 'hidden';
        regionDropDown.style.display = 'block';
        this.regionDropDownFlag = false;
      }
      // duration
      const durationDropDown = document.getElementById('duration_dropdown');
      if (durationDropDown && durationDropDown.style && durationDropDown.style.display) {
        durationDropDown.style.display = 'none';
        this.durationDropDownFlag = false;
      }
      // Ports
      const portDropDown = document.getElementById('ports_dropdown');
      if (portDropDown && portDropDown.style && portDropDown.style.display) {
        // portDropDown.style.visibility = 'hidden';
        portDropDown.style.display = 'none';
        this.portDropDownFlag = false;
      }
      // yacht
      const yachtDropDown = document.getElementById('yacht_dropdown');
      if (yachtDropDown && yachtDropDown.style && yachtDropDown.style.display) {
        yachtDropDown.style.display = 'none';
        this.yachtDropDownFlag = false;
      }
    }
    else if (field === 'duration') {
      if (this.FindCruiseDuration.length) {
        this.duration = true;

        if (document.getElementById('duration_dropdown')) {
          document.getElementById('duration_dropdown').style.display = 'block';
          this.durationDropDownFlag = true;
        }
        this.durationTypedFlag = true;
        this.durationDDTabIndex = '-1';
        setTimeout(() => {
          if (document.getElementById('duration_input')) {
            document.getElementById('duration_input').focus();
          }
        }, 500);
      }
      this.durationTypedFlag = true;
      this.durationDDTabIndex = '-1';

      // Close Other drop Downs
      // Region
      const regionDropDown = document.getElementById('region_dropdown');
      if (regionDropDown && regionDropDown.style && regionDropDown.style.display) {
        regionDropDown.style.display = 'block';
        this.regionDropDownFlag = false;
      }
      // Departure Date
      const departureDateDropDown = document.getElementById('departure_dropdown');
      if (departureDateDropDown && departureDateDropDown.style && departureDateDropDown.style.visibility) {
        departureDateDropDown.style.display = 'none';
        this.departureDateDropDownFlag = false;
      }
      // Ports
      const portDropDown = document.getElementById('ports_dropdown');
      if (portDropDown && portDropDown.style && portDropDown.style.visibility) {
        portDropDown.style.display = 'none';
        this.portDropDownFlag = false;
      }
      // yacht
      const yachtDropDown = document.getElementById('yacht_dropdown');
      if (yachtDropDown && yachtDropDown.style && yachtDropDown.style.display) {
        yachtDropDown.style.display = 'none';
        this.yachtDropDownFlag = false;
      }
    }
    else if (field === 'ports') {
      if (this.FindCruisePorts.length) {
        this.ports = true;
        if (document.getElementById('ports_dropdown')) {
          // document.getElementById('ports_dropdown').style.visibility = 'visible';
          document.getElementById('ports_dropdown').style.display = 'block';
          this.portDropDownFlag = true;
        }

        setTimeout(() => {
          if (document.getElementById('port_input')) {
            document.getElementById('port_input').focus();
          }
        }, 500);

        this.portTypedFlag = true;
        this.portsDDTabIndex = '-1';
      }

      // Close Other drop Downs
      // Departure Date
      const departureDateDropDown = document.getElementById('departure_dropdown');
      if (departureDateDropDown && departureDateDropDown.style && departureDateDropDown.style.visibility) {
        // departureDateDropDown.style.visibility = 'hidden';
        departureDateDropDown.style.display = 'none';
        this.departureDateDropDownFlag = false;
      }
      // duration
      const durationDropDown = document.getElementById('duration_dropdown');
      if (durationDropDown && durationDropDown.style && durationDropDown.style.display) {
        durationDropDown.style.display = 'none';
        this.durationDropDownFlag = false;
      }
      // Region
      const regionDropDown = document.getElementById('region_dropdown');
      if (regionDropDown && regionDropDown.style && regionDropDown.style.display) {
        // regionDropDown.style.visibility = 'hidden';
        regionDropDown.style.display = 'none';
        this.regionDropDownFlag = false;
      }
      // yacht
      const yachtDropDown = document.getElementById('yacht_dropdown');
      if (yachtDropDown && yachtDropDown.style && yachtDropDown.style.display) {
        yachtDropDown.style.display = 'none';
        this.yachtDropDownFlag = false;
      }
    }
    else if (field === 'yacht') {
      if (this.FindCruiseYacht.length) {
        this.yacht = true;

        if (document.getElementById('yacht_dropdown')) {
          document.getElementById('yacht_dropdown').style.display = 'block';
          this.yachtDropDownFlag = true;
        }
        this.yachtTypedFlag = true;
        this.yachtDDTabIndex = '-1';
        setTimeout(() => {
          if (document.getElementById('yacht_input')) {
            document.getElementById('yacht_input').focus();
          }
        }, 500);
      }
      this.yachtTypedFlag = true;
      this.yachtDDTabIndex = '-1';

      // Close Other drop Downs
      // Region
      const regionDropDown = document.getElementById('region_dropdown');
      if (regionDropDown && regionDropDown.style && regionDropDown.style.display) {
        regionDropDown.style.display = 'block';
        this.regionDropDownFlag = false;
      }
      // Departure Date
      const departureDateDropDown = document.getElementById('departure_dropdown');
      if (departureDateDropDown && departureDateDropDown.style && departureDateDropDown.style.visibility) {
        departureDateDropDown.style.display = 'none';
        this.departureDateDropDownFlag = false;
      }
      // duration
      const durationDropDown = document.getElementById('duration_dropdown');
      if (durationDropDown && durationDropDown.style && durationDropDown.style.display) {
        durationDropDown.style.display = 'none';
        this.durationDropDownFlag = false;
      }
      // Ports
      const portDropDown = document.getElementById('ports_dropdown');
      if (portDropDown && portDropDown.style && portDropDown.style.visibility) {
        portDropDown.style.display = 'none';
        this.portDropDownFlag = false;
      }
    }
    else if (field === 'offer_program') { this.offer_program = !this.offer_program; }
    else {
      if (window.innerWidth < 1024) {
        this.showHideFilterClick(false);
      }
    }

    window.scrollTo(0, 0);
  }

  /**
   * Function for clear all search dropdown select values
   * @param searchfeilds
   */
  clearSearchfield(searchfeilds) {
    if (this.Fistfilterfield.indexOf('region') === -1) { this.regionselect = []; }

    if (this.Fistfilterfield.indexOf('departure_date') === -1) {
      this.departure_dateselect = [];
    }

    if (this.Fistfilterfield.indexOf('duration') === -1) {
      this.duration_select = [];
    }

    if (this.Fistfilterfield.indexOf('ports') === -1) { this.ports_select = []; }

    if (this.Fistfilterfield.indexOf('yacht') === -1) { this.yacht_select = []; }

    if (this.Fistfilterfield.indexOf('offer_program') === -1) {
      this.offer_program_select = [];
    }
  }
  /**
   * Function for storing selected search values in an filter array
   * @param field
   * @param value
   * @param status
   */

  filtervalue(field, value, status, name) {
    this.isFilterApplied = true;
    this.loadMoreClickCount = 0;
    this.filtervalueClickStatus = true;
    this.loadMoreClickStatus = false;
    this.select_feild = '';
    // if (status.target.checked == true)
    this.portTypedFlag = false;
    this.portsDDTabIndex = '0';
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    this.durationTypedFlag = false;
    this.durationDDTabIndex = '0';
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    this.yachtTypedFlag = false;
    this.yachtDDTabIndex = '0';
    this.select_feild = field;

    if (this.Fistfilterfield.indexOf(field) === -1) {
      this.Fistfilterfield.push(field);
    }

    const index = this.Fistfilterfield.indexOf(field) + 1;
    this.Fistfilterfield.splice(index, this.Fistfilterfield.length - index);
    //  this.clearSearchfield(this.Fistfilterfield);

    // if (status.target.checked == false) this.goToPagignation(0);

    if (field === 'region' && status.target.checked === true) {
      this.regionselect.push(value);
      this.slct_regionname.push(name);
      // make the text field not editable

      // document.getElementById('region_dropdown').style.visibility = 'visible';
      document.getElementById('region_dropdown').style.display = 'block';
      this.regionDropDownFlag = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
    } else if (field === 'region' && status.target.checked === false) {
      this.regionselect.splice(this.regionselect.indexOf(value), 1);
      this.slct_regionname.splice(this.slct_regionname.indexOf(name), 1);
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      if (this.slct_regionname.length === 0 && this.serachval && this.serachval['regionCodes']
        && this.serachval['regionCodes'].length) {
        this.serachval['regionCodes'] = [];
      }
    }

    if (field === 'departure_date' && status.target.checked === true) {
      const array = value.split('-');

      const dateSplittedArray = value.split(' ');
      const requiredDateFormat = dateSplittedArray[1] + '_' + ('0' + (this.months.indexOf(dateSplittedArray[0]) + 1)).slice(-2);
      this.departure_dateselect.push(requiredDateFormat);
      this.dep_dateselect.push(requiredDateFormat);
      this.slct_deptnname.push(value);

      const departureDatedown = document.getElementById('departure_dropdown');
      if (departureDatedown && departureDatedown.style && departureDatedown.style.visibility) {
        // departureDatedown.style.visibility = 'visible';
        departureDatedown.style.display = 'none';
        this.departureDateDropDownFlag = true;
      } // remove this line when issue with date

      // make the text field not editable
      if (this.regionselect.length > 0) {
        this.restoreRegionValues = true;
      }
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
    } else if (field === 'departure_date' && status.target.checked === false) {

      const dateSplittedArray = value.split(' ');


      this.departure_dateselect.splice(
        this.departure_dateselect.indexOf(dateSplittedArray[1] + '_' + ('0' + (this.months.indexOf(dateSplittedArray[0]) + 1)).slice(-2)),
        1
      );

      const requiredDateFormat = dateSplittedArray[1] + '_' + ('0' + (this.months.indexOf(dateSplittedArray[0]) + 1)).slice(-2);

      // this.dep_dateselect.push(requiredDateFormat);
      this.dep_dateselect.splice(this.dep_dateselect.indexOf(requiredDateFormat), 1);
      this.slct_deptnname.splice(this.slct_deptnname.indexOf(value), 1);
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
    }

    if (field === 'duration' && status.target.checked === true) {
      value.forEach(element => {
        this.duration_select.push(element);
      });
      this.slct_duration.push(name);

      document.getElementById('duration_dropdown').style.display = 'block';
      this.durationDropDownFlag = true;
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
    } else if (field === 'duration' && status.target.checked === false) {
      value.forEach(element => {
        this.duration_select.splice(this.duration_select.indexOf(element), 1);
      });
      this.slct_duration.splice(this.slct_duration.indexOf(name), 1);
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      if (this.duration_select.length === 0 && this.serachval && this.serachval['durations']
        && this.serachval['durations'].length) {
        this.serachval['durations'] = [];
        this.serachval['selectedDurations'] = [];
      }
    }

    if (field === 'ports' && status.target.checked === true) {
      this.ports_select.push(value);
      this.slct_portnname.push(name);
      if (this.regionselect.length > 0) {
        this.restoreRegionValues = true;
      }
      if (this.regionselect.length === 0) {
        this.portValueGet = true;
      }
      // make the text field not editable
      // document.getElementById('ports_dropdown').style.visibility = 'visible';
      document.getElementById('ports_dropdown').style.display = 'block';
      this.portDropDownFlag = true;
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
    } else if (field === 'ports' && status.target.checked === false) {
      this.ports_select.splice(this.ports_select.indexOf(value), 1);
      this.slct_portnname.splice(this.slct_portnname.indexOf(name), 1);
      if (this.ports_select.length === 0) {
        this.restoreRegionValues = true;
      }
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      if (this.ports_select.length === 0 && this.serachval && this.serachval['portCodes']
        && this.serachval['portCodes'].length) {
        this.serachval['portCodes'] = [];
      }
    }

    if (field === 'yacht' && status.target.checked === true) {
      this.yacht_select.push(value);
      this.slct_yachtName.push(name);
      document.getElementById('yacht_dropdown').style.display = 'block';
      this.yachtDropDownFlag = true;
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
    } else if (field === 'yacht' && status.target.checked === false) {
      this.yacht_select.splice(this.yacht_select.indexOf(value), 1);
      this.slct_yachtName.splice(this.slct_yachtName.indexOf(name), 1);
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      if (this.slct_yachtName.length === 0 && this.serachval && this.serachval['yachtIds']
        && this.serachval['yachtIds'].length) {
        this.serachval['yachtIds'] = [];
      }
    }

    if (field === 'offer_program' && status.target.checked === true) {
      this.offer_program_select.push(value);
    } else if (field === 'offer_program' && status.target.checked === false) {
      this.offer_program_select.splice(
        this.offer_program_select.indexOf(value),
        1
      );
    }

    if (this.rcycCommonService.getSessionData('userPreferenceData')) {
      const preferenceData = {
        'currentPage': 0,
        'sortBySelectdValue': this.selectedValue,
        'gridClickStatus': this.gridClickStatus
      };
      this.rcycCommonService.setSessionData('userPreferenceData', preferenceData);
    }

    if (status.target.checked === false) {
      this.check_empty_filter();
    }

    this.page = 0;
    this.currentpag = 0;
    const sortValue = this.rcycCommonService.getSessionData('findACruiseSearchCriteria');
    if (sortValue && sortValue.sortField !== undefined) {
      this.searchdata = {
        regionCodes: this.regionselect,
        departureYearMonths: this.departure_dateselect,
        durations: this.duration_select,
        portCodes: this.ports_select,
        yachtIds: this.yacht_select,
        countryCode: this.UserDetails.country_code,
        page: this.page,
        size: this.perPage,
        sortField: sortValue.sortField,
        sortOrder: sortValue.sortOrder
      };
    } else {
      this.searchdata = {
        regionCodes: this.regionselect,
        departureYearMonths: this.departure_dateselect,
        durations: this.duration_select,
        portCodes: this.ports_select,
        yachtIds: this.yacht_select,
        countryCode: this.UserDetails.country_code,
        page: this.page,
        size: this.perPage,
        sortField: this.feild,
        sortOrder: this.order
      };
    }

    const filterParam = {
      regionselect: this.regionselect,
      slct_regionname: this.slct_regionname,
      dep_dateselect: this.dep_dateselect,
      slct_deptnname: this.slct_deptnname,
      duration_select: this.duration_select,
      slct_duration: this.slct_duration,
      ports_select: this.ports_select,
      slct_portnname: this.slct_portnname,
      yacht_select: this.yacht_select,
      slct_yachtName: this.slct_yachtName
    };

    const mergedFiterCriteria = { ...filterParam, ...this.searchdata };

    this.rcycCommonService.setSessionData('findACruiseSearchCriteria', mergedFiterCriteria);
    this.rcycCommonService.setSessionData('findACruisePaginationArray', [1, 2, 3, 4, 5, 6, 7, 8]);
    this.find_cruise_search(this.searchdata, false);
  }

  /**
  * Check for filter criteria is empty or not
  */

  check_empty_filter() {
    if (this.regionselect.length === 0 && this.dep_dateselect.length === 0 && this.ports_select.length === 0 && this.yacht_select.length === 0 && this.duration_select.length === 0) {
      this.select_feild = '';
    }

    if (this.regionselect.length === 0 && this.select_feild === 'region') {
      this.select_feild = '';
    }

    if (this.dep_dateselect.length === 0 && this.select_feild === 'departure_date') {
      this.select_feild = '';
    }

    if (this.ports_select.length === 0 && this.select_feild === 'ports') {
      this.select_feild = '';
    }

    if (this.yacht_select.length === 0 && this.select_feild === 'yacht') {
      this.select_feild = '';
    }

    if (this.duration_select.length === 0 && this.select_feild === 'duration') {
      this.select_feild = '';
    }
  }

  /**
   * Function for checking whether the checkbox is checked or not
   * @param field
   * @param value
   */
  Checkotpionselect(field, value) {
    if (field === 'region') { return this.regionselect.indexOf(value) > -1; }

    if (field === 'departure_date') {
      return this.slct_deptnname.indexOf(value) > -1;
    }

    if (field === 'duration') { return this.slct_duration.indexOf(value) > -1; }

    if (field === 'ports') { return this.ports_select.indexOf(value) > -1; }

    if (field === 'yacht') { return this.yacht_select.indexOf(value) > -1; }

    if (field === 'offer_program') {
      return this.offer_program_select.indexOf(value) > -1;
    }
  }


  /**
   * Function for clear search cruise crieteria
   */
  clear_cruisefilter() {
    this.isFilterApplied = false;
    this.loadMoreClickCount = 0;
    this.loadMoreClickStatus = false;
    this.rcycCommonService.removeData('searchcruiseresult');
    this.serachval = null;
    this.regionselect = [];
    this.departure_dateselect = [];
    this.duration_select = [];
    this.ports_select = [];
    this.yacht_select = [];
    this.offer_program_select = [];
    this.dep_dateselect = [];
    this.select_feild = '';
    this.selectedValue = 'DepartureDateAscending';

    this.slct_portnname = [];
    this.slct_deptnname = [];
    this.slct_regionname = [];
    this.slct_yachtName = [];
    this.slct_duration = [];
    this.page = 0;
    this.searchdata = {
      countryCode: this.UserDetails.country_code,
      page: this.page,
      size: this.perPage
    };

    this.rcycCommonService.removeSessionData('backToResultVoyageListPrevUrl');
    this.rcycCommonService.removeSessionData('findACruisePaginationArray');
    this.rcycCommonService.removeSessionData('findACruiseSearchCriteria');
    this.rcycCommonService.removeSessionData('pagesArray');
    this.rcycCommonService.removeSessionData('voyageBackButtonPressed');
    this.currentpag = 0;
    const preferenceData = {
      'currentPage': 0,
      'sortBySelectdValue': this.selectedValue,
      'gridClickStatus': this.gridClickStatus
    };
    this.rcycCommonService.setSessionData('userPreferenceData', preferenceData);
    this.rcycCommonService.removeSessionData('userPreferenceData');
    this.filteredResultMasterData = null;

    this.clearFindCruiseData = true;
    if (this.pageName === 'destinationsDetails') {
      this.checkOtherPagesVoyages();
    } else {
      this.find_cruise_search(this.searchdata, false);
    }
  }

  /**
   * Function to populate filter data i.e. yachtSearchCriterionForResponseDTO from filtered voyage list.
   */
  filterFindCrusefilters(field: string, isSelected) {
    const region = new Set();
    const departureYearMonths = new Set();
    const voyageStartDates = new Set();
    const yachts = new Set();
    const ports = new Set();
    const durations = new Set();

    for (let index = 0; index < this.filteredResultMasterData.data.voyages.length; index++) {
      (field !== 'region' || !isSelected) && region.add(this.filteredResultMasterData.data.voyages[index].voyageRegion);
      (field !== 'departure_date' || !isSelected) && departureYearMonths.add(this.filteredResultMasterData.data.voyages[index].departureYearMonth);
      (field !== 'departure_date' || !isSelected) && voyageStartDates.add(this.filteredResultMasterData.data.voyages[index].voyageStartDate);
      (field !== 'duration' || !isSelected) && durations.add(this.filteredResultMasterData.data.voyages[index].nights);
      (field !== 'ports' || !isSelected) && this.filteredResultMasterData.data.voyages[index].portCodes.forEach(port => ports.add(port));
      (field !== 'yacht' || !isSelected) && yachts.add(this.filteredResultMasterData.data.voyages[index].yachtId);
    }
    this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.regions = region.size ? this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.regions.filter(yacht => Array.from(region).indexOf(yacht.travelRegion) !== -1) : this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.regions;
    this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.ports = ports.size ? this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.ports.filter(port => Array.from(ports).indexOf(port.portCode) !== -1) : this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.ports;
    this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.yachts = yachts.size ? this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.yachts.filter(yacht => Array.from(yachts).indexOf(yacht.yachtId) !== -1) : this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.yacht;

    if (departureYearMonths.size) {
      this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.departureYearMonths = Array.from(departureYearMonths);
      this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.departureDates = Array.from(voyageStartDates);
    }

    if (durations.size) {
      this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.durations = Array.from(durations);

      this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.durationValues = this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.durationValues.filter(item => {
        let returnValue = false;
        for (let index = 0; index < item.duration.length; index++) {
          if (this.filteredResultMasterData.data.yachtSearchCriterionForResponseDTO.durations.indexOf(item.duration[index]) !== -1) {
            returnValue = true;
            break;
          } else {
            returnValue = false;
          }
        }
        return returnValue;
      });
    }
  }

  /**
   * Function to filter voyages when user selects any filter.
   */
  filterStaticCruiseSearchData(search, field, isSelected) {
    if (search.regionCodes.length || search.departureYearMonths.length || search.durations.length || search.portCodes.length || search.yachtIds.length) {
      this.filteredResultMasterData = null;
      let tempRegionCodes: string[] = null;
      this.filteredResultMasterData = structuredClone(this.criteriaResultMasterData);

      switch (field) {
        case 'region':
          if (search.regionCodes.length || isSelected) {
            if (search.regionCodes.indexOf('CARB') !== -1 || search.regionCodes.indexOf('MEDD') !== -1) {
              tempRegionCodes = ['TRAN'].concat(search.regionCodes);
              this.filteredResultMasterData.data.voyages = this.criteriaResultMasterData.data.voyages.filter(voyage => tempRegionCodes.indexOf(voyage.voyageRegion) !== -1);
            } else
              this.filteredResultMasterData.data.voyages = this.criteriaResultMasterData.data.voyages.filter(voyage => search.regionCodes.indexOf(voyage.voyageRegion) !== -1);
          }

          // Apply filter for other filters
          if (search.departureYearMonths.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.departureYearMonths.indexOf(voyage.departureYearMonth) !== -1);
          }
          if (search.durations.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.durations.indexOf(voyage.nights) !== -1);
          }
          if (search.portCodes.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => {
              let returnValue: boolean;
              for (let index = 0; index < search.portCodes.length; index++) {
                if (voyage.portCodes.indexOf(search.portCodes[index]) !== -1) {
                  returnValue = true;
                  break;
                } else {
                  returnValue = false;
                }
              }
              return returnValue;
            });
          }
          if (search.yachtIds.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.yachtIds.indexOf(voyage.yachtId) !== -1);
          }
          // Filter filters
          this.filterFindCrusefilters('region', isSelected);
          break;
        case 'departure_date':
          if (search.departureYearMonths.length || isSelected) {
            this.filteredResultMasterData.data.voyages = this.criteriaResultMasterData.data.voyages.filter(voyage => search.departureYearMonths.indexOf(voyage.departureYearMonth) !== -1);
          }

          // Apply filter for other filters
          if (search.regionCodes.length) {
            if (search.regionCodes.indexOf('CARB') !== -1 || search.regionCodes.indexOf('MEDD') !== -1) {
              tempRegionCodes = ['TRAN'].concat(search.regionCodes);
              this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => tempRegionCodes.indexOf(voyage.voyageRegion) !== -1);
            } else
              this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.regionCodes.indexOf(voyage.voyageRegion) !== -1);
          }
          if (search.durations.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.durations.indexOf(voyage.nights) !== -1);
          }
          if (search.portCodes.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => {
              let returnValue: boolean;
              for (let index = 0; index < search.portCodes.length; index++) {
                if (voyage.portCodes.indexOf(search.portCodes[index]) !== -1) {
                  returnValue = true;
                  break;
                } else {
                  returnValue = false;
                }
              }
              return returnValue;
            });
          }
          if (search.yachtIds.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.yachtIds.indexOf(voyage.yachtId) !== -1);
          }
          // Filter filters
          this.filterFindCrusefilters('departure_date', isSelected);
          break;
        case 'duration':
          if (search.durations.length || isSelected) {
            this.filteredResultMasterData.data.voyages = this.criteriaResultMasterData.data.voyages.filter(voyage => search.durations.indexOf(voyage.nights) !== -1);
          }

          // Apply filter for other filters
          if (search.regionCodes.length) {
            if (search.regionCodes.indexOf('CARB') !== -1 || search.regionCodes.indexOf('MEDD') !== -1) {
              tempRegionCodes = ['TRAN'].concat(search.regionCodes);
              this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => tempRegionCodes.indexOf(voyage.voyageRegion) !== -1);
            } else
              this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.regionCodes.indexOf(voyage.voyageRegion) !== -1);
          }
          if (search.departureYearMonths.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.departureYearMonths.indexOf(voyage.departureYearMonth) !== -1);
          }
          if (search.portCodes.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => {
              let returnValue: boolean;
              for (let index = 0; index < search.portCodes.length; index++) {
                if (voyage.portCodes.indexOf(search.portCodes[index]) !== -1) {
                  returnValue = true;
                  break;
                } else {
                  returnValue = false;
                }
              }
              return returnValue;
            });
          }
          if (search.yachtIds.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.yachtIds.indexOf(voyage.yachtId) !== -1);
          }
          // Filter filters
          this.filterFindCrusefilters('duration', isSelected);
          break;
        case 'ports':
          if (search.portCodes.length || isSelected) {
            this.filteredResultMasterData.data.voyages = this.criteriaResultMasterData.data.voyages.filter(voyage => {
              let returnValue: boolean;
              for (let index = 0; index < search.portCodes.length; index++) {
                if (voyage.portCodes.indexOf(search.portCodes[index]) !== -1) {
                  returnValue = true;
                  break;
                } else {
                  returnValue = false;
                }
              }
              return returnValue;
            });
          }

          // Apply filter for other filters
          if (search.regionCodes.length) {
            if (search.regionCodes.indexOf('CARB') !== -1 || search.regionCodes.indexOf('MEDD') !== -1) {
              tempRegionCodes = ['TRAN'].concat(search.regionCodes);
              this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => tempRegionCodes.indexOf(voyage.voyageRegion) !== -1);
            } else
              this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.regionCodes.indexOf(voyage.voyageRegion) !== -1);
          }
          if (search.departureYearMonths.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.departureYearMonths.indexOf(voyage.departureYearMonth) !== -1);
          }
          if (search.durations.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.durations.indexOf(voyage.nights) !== -1);
          }
          if (search.yachtIds.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.yachtIds.indexOf(voyage.yachtId) !== -1);
          }
          // Filter filters
          this.filterFindCrusefilters('ports', isSelected);
          break;
        case 'yacht':
          if (search.yachtIds.length || isSelected) {
            this.filteredResultMasterData.data.voyages = this.criteriaResultMasterData.data.voyages.filter(voyage => search.yachtIds.indexOf(voyage.yachtId) !== -1);
          }

          // Apply filter for other filters
          if (search.regionCodes.length) {
            if (search.regionCodes.indexOf('CARB') !== -1 || search.regionCodes.indexOf('MEDD') !== -1) {
              tempRegionCodes = ['TRAN'].concat(search.regionCodes);
              this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => tempRegionCodes.indexOf(voyage.voyageRegion) !== -1);
            } else
              this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.regionCodes.indexOf(voyage.voyageRegion) !== -1);
          }
          if (search.departureYearMonths.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.departureYearMonths.indexOf(voyage.departureYearMonth) !== -1);
          }
          if (search.durations.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => search.durations.indexOf(voyage.nights) !== -1);
          }
          if (search.portCodes.length) {
            this.filteredResultMasterData.data.voyages = this.filteredResultMasterData.data.voyages.filter(voyage => {
              let returnValue: boolean;
              for (let index = 0; index < search.portCodes.length; index++) {
                if (voyage.portCodes.indexOf(search.portCodes[index]) !== -1) {
                  returnValue = true;
                  break;
                } else {
                  returnValue = false;
                }
              }
              return returnValue;
            });
          }
          // Filter filters
          this.filterFindCrusefilters('yacht', isSelected);
          break;
      }
      this.filteredResultMasterData.data.totalCount = this.filteredResultMasterData.data.voyages.length;
      // this.find_cruise_search(search);
    } else {
      this.filteredResultMasterData = null;
      this.isFilterApplied = false;
      // this.find_cruise_search(search);
    }
  }

  /**
   * Function to sort voyages ac to user sorting preference.
   */
  sortStaticCruiseData(sortyByProperty: string, sortByAscDesc: string, sortSourcedata: string) {
    this[sortSourcedata].data.voyages.sort((p1, p2) => {
      const vaoyage1 = (sortyByProperty === "voyageStartDate") ? new Date(p1[sortyByProperty]) : p1[sortyByProperty];
      const vaoyage2 = (sortyByProperty === "voyageStartDate") ? new Date(p2[sortyByProperty]) : p2[sortyByProperty];
      if (sortByAscDesc === "ASC") {
        if (vaoyage1 < vaoyage2) return -1;
        if (vaoyage1 > vaoyage2) return 1;
        return 0;
      } else {
        if (vaoyage1 > vaoyage2) return -1;
        if (vaoyage1 < vaoyage2) return 1;
        return 0;
      }
    });
  }

  /**
   * Get offers and programs details voyage list from Drupal
   */
  getLandingContent() {
    const apiUrl = '/api/voyage/list';
    let params;
    params = {
      apiUrl: apiUrl,
      countryCode: this.UserDetails.country_code,
      voyageIds: this.drupalVoyages,
    };
    this.rcycSpecialOffersLandingService.multipleVoyageElasticApi(params).subscribe(
      (response: any) => {
        if (response && response?.code === 200 && response?.data?.length) {
          this.voyageListMasterData = response;
          this.loadPagingVoyageListData({ page: 0 });
        }
      },
      error => {
        console.error(error);
      });
  }

  loadPagingVoyageListData(searchData) {
    let pagingCruiseData = { code: 200, data: { voyages: null, currencyCode: null, totalCount: this.voyageListMasterData?.data?.length, yachtSearchCriterionForResponseDTO: null } };
    pagingCruiseData.data.voyages = this.voyageListMasterData?.data?.slice((searchData.page * this.perPage), ((searchData.page + 1) * this.perPage));
    pagingCruiseData.data.currencyCode = this.voyageListMasterData?.data[0]?.feeCurrency;
    this.find_cruise_searchInitial(pagingCruiseData);
  }

  /**
   * Function to to apply paging of 9 voyages from the static data.
   * @param searchData
   */
  loadStaticCruiseSearchData(searchData, isFiltered?) {
    let pagingCruiseData;
    if (isFiltered || this.isFilterApplied) {
      if (searchData.sortField) {
        this.sortStaticCruiseData(searchData.sortField, searchData.sortOrder, 'filteredResultMasterData');
      }
      pagingCruiseData = structuredClone(this.filteredResultMasterData);
    } else {
      if (searchData.sortField) {
        this.sortStaticCruiseData(searchData.sortField, searchData.sortOrder, 'criteriaResultMasterData');
      }
      pagingCruiseData = structuredClone(this.criteriaResultMasterData);
    }
    pagingCruiseData.data.voyages = pagingCruiseData.data.voyages.slice((searchData.page * this.perPage), ((searchData.page + 1) * this.perPage));

    pagingCruiseData.data.voyages = this.allItemsImages(pagingCruiseData.data.voyages);
    this.find_cruise_searchInitial(pagingCruiseData);
  }

  allItemsImages(getData) {
    let data = [...getData];
    data.map((item, i) => {
      data[i].slider = [];
      this.rcycCommonService.getSessionData('drupalImageArray').map((image, j) => {
        if (item.voyageId === image.field_voyages) {
          data[i].slider = ['', ''];
          data[i].slider[1] = [this.rcycCommonService.getSessionData('drupalImageArray')[j].field_voyage_thumbnail];
        }
      });
    });

    return data;
  }

  checkOtherPagesVoyages() {
    if (this.pageName === 'offersProgramsDetails') {
      this.getLandingContent();
      return;
    }
    if (this.pageName === 'destinationsDetails') {
      let search = {};
      const tempSearch = this.rcycCommonService.getSessionData('findCruise');
      if (tempSearch) {
        const regionCodes = tempSearch?.data?.yachtSearchCriterionForResponseDTO?.regions?.filter(item => item.generalDescription.toLowerCase() === this.selectedRegion?.toLocaleLowerCase() || item.generalDescription.toLowerCase().includes(this.selectedRegion?.toLocaleLowerCase()));
        search = { regionCodes: [regionCodes[0]?.travelRegion.toLocaleLowerCase()], page: 0, size: this.perPage, countryCode: this.UserDetails.country_code };
        this.find_cruise_searchInitial_api(search);
      } else {
        this.rcycHomeService.find_cruise('/api/criteria-result', { page: 0, size: 9, countryCode: this.UserDetails.country_code }).subscribe(
          (response: any) => {
            this.rcycCommonService.setSessionData('findCruise', response);
            const regionCodes = response?.data?.yachtSearchCriterionForResponseDTO?.regions?.filter(item => item.generalDescription.toLowerCase() === this.selectedRegion?.toLocaleLowerCase() || item.generalDescription.toLowerCase().includes(this.selectedRegion?.toLocaleLowerCase()));
            search = { regionCodes: [regionCodes[0]?.travelRegion.toLocaleLowerCase()], page: 0, size: this.perPage, countryCode: this.UserDetails.country_code };
            this.find_cruise_searchInitial_api(search);
          },
          err => {
            console.error(err);
          });
      }
    }
  }

  /**
   * Function to call getCruiseSearchDataAPI().
   */
  find_cruise_search(search, isFromContructor) {
    if (isFromContructor) {
      return;
    }
    if (this.voyageListMasterData && this.voyageListMasterData?.data?.length > 0) {
      this.loadPagingVoyageListData(search);
      return;
    }
    this.find_cruise_searchInitial_api(search);
  }

  /**
   * Function for search cruise based on selected crieteria using java API
   * @param search
   */
  getCruiseSearchDataAPI(search) {
    this.rcycCommonService.getAllCallOut('all', '');
    this.status = 0;
    this.rcycHomecontentService.find_cruise(search).subscribe(response => {
      this.criteriaResultMasterData = response;
      this.loadStaticCruiseSearchData(search);
    });
  }

  /**
   * Function to populate filter dropdown data.
   * @param response has 9 voyage results with yachtSearchCriterionForResponseDTO object for filter data.
   */
  find_cruise_searchInitial_api(search) {

    if (!search.sortField) {
      delete search.sortField;
    }

    if (!search.sortOrder) {
      delete search.sortOrder;
    }

    this.rcycHomecontentService.find_cruise(search).subscribe(
      response => {
        this.find_cruise_searchInitial(response);
      },
      err => { });
  }

  find_cruise_searchInitial(response: any) {
    const findcruise: any = response;
    if (!this.findCruiseData || this.clearFindCruiseData) {
      this.findCruiseData = response;
      if (this.findCruiseData && this.findCruiseData.data && this.findCruiseData.data.yachtSearchCriterionForResponseDTO) {
        this.findCruiseData = this.findCruiseData.data.yachtSearchCriterionForResponseDTO;
        if (this.pageName === 'destinationsDetails') {
          this.findCruiseData.regions = this.findCruiseData?.regions?.filter(item => item.generalDescription.toLowerCase() === this.selectedRegion?.toLocaleLowerCase() || item.generalDescription.toLowerCase().includes(this.selectedRegion?.toLocaleLowerCase()));
          findcruise.data.yachtSearchCriterionForResponseDTO.regions = this.findCruiseData.regions;
          this.serachval = this.serachval ? this.serachval : { regionCodes: [this.findCruiseData?.regions[0].travelRegion.toLocaleLowerCase()] };
        }
        this.clearFindCruiseData = false;
      }
    }
    if (!this.rcycCommonService.getSessionData('findCruise')) {
      this.rcycCommonService.setSessionData('findCruise', findcruise);
    }
    if (findcruise && findcruise.code === 200) {
      this.status = 1;
      if (findcruise) {
        // Setting image mode
        findcruise.data.voyages.forEach(voyage => {
          voyage.imageMode = this.imageMode;
        });
        this.currencyCode = findcruise.data.currencyCode;
        this.setAdditionalCostInfo();
        this.FindcruiseAPI =
          findcruise.data.yachtSearchCriterionForResponseDTO;
        if (!this.findcruisehomeflag || this.pageName === 'destinationsDetails') {
          this.fullsearchresult = findcruise.data.voyages;
        }
        this.totalData = parseInt(findcruise.data.totalCount);
        if (this.totalData !== 0) {
          if (this.select_feild !== 'region') {
            this.portValueGet = false;
            this.FindCruiseRegion = findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions;
            if (this.findCruiseUrlCallGetData) {
              if (this.searchUrlData['regionCodes']) {
                for (let i = 0; i < this.searchUrlData['regionCodes'].length; i++) {
                  for (let j = 0; j < findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions?.length; j++) {
                    const regionCodeValues = this.searchUrlData['regionCodes'][i].toLowerCase();
                    const tempApiRegionData = findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions[j].travelRegion
                      .toLowerCase();
                    if (regionCodeValues === tempApiRegionData) {
                      this.slct_regionname.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions[j].generalDescription);
                    }
                  }
                }
              }
            }
            if (this.serachval) {
              if (this.serachval['regionCodes']) {
                this.slct_regionname = [];
                this.regionselect = [];
                for (let i = 0; i < this.serachval['regionCodes'].length; i++) {
                  for (let j = 0; j < findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions?.length; j++) {
                    const regionCodeValues = this.serachval['regionCodes'][i].toLowerCase();
                    const tempApiRegionData = findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions[j].travelRegion
                      .toLowerCase();
                    if (regionCodeValues === tempApiRegionData) {
                      this.slct_regionname.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions[j].generalDescription);
                      this.regionselect.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions[j].travelRegion);
                    }
                  }
                }
              }
            }
          }
          if (this.select_feild !== 'departure_date') {
            const FindCruiseDepdate = findcruise?.data?.yachtSearchCriterionForResponseDTO?.departureYearMonths;
            FindCruiseDepdate && this.DepartureDate_sortMonth(FindCruiseDepdate);
          }
          if (this.select_feild !== 'duration') {
            this.FindCruiseDuration = findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues;

            if (this.findCruiseUrlCallGetData) {
              if (this.searchUrlData['durations']) {
                for (let i = 0; i < this.searchUrlData['durations'].length; i++) {
                  for (let j = 0; j < findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues?.length; j++) {
                    const durationValues = this.searchUrlData['durations'][i];
                    const tempApiDurationData = findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues[j].duration;
                    if (durationValues === tempApiDurationData) {
                      this.slct_duration.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues[j].description);
                    }
                  }
                }
              }
            }
            if (this.serachval) {
              if (this.serachval['durations']) {
                this.slct_duration = [];
                this.duration_select = [];
                for (let i = 0; i < this.serachval['selectedDurations'].length; i++) {
                  for (let j = 0; j < findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues?.length; j++) {
                    const durationValues = this.serachval['selectedDurations'][i];
                    const tempApiDurationData = findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues[j].duration;
                    if (JSON.stringify(durationValues) === JSON.stringify(tempApiDurationData)) {
                      this.slct_duration.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues[j].description);
                      findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues[j].duration.forEach(element => {
                        this.duration_select.push(element)
                      });
                    }
                  }
                  if (i === (this.serachval['selectedDurations'].length - 1)) {
                  }
                }
              }
            }
          }
          if (this.select_feild !== 'ports') {
            this.FindCruisePorts = findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports;
            if (this.findCruiseUrlCallGetData) {
              this.FindCruisePorts.map((data) => {
                data.portCode = data.portCode.toUpperCase();
              });
              if (this.searchUrlData['portCodes']) {
                for (let i = 0; i < this.searchUrlData['portCodes'].length; i++) {
                  for (let j = 0; j < findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports?.length; j++) {
                    const portCodeValues = this.searchUrlData['portCodes'][i].toLowerCase();
                    const tempApiPortData = findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports[j].portCode.toLowerCase();
                    if (portCodeValues === tempApiPortData) {
                      this.slct_portnname.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports[j].portName);
                    }
                  }
                }
              }
            }
            if (this.serachval) {
              if (this.serachval['portCodes']) {
                this.slct_portnname = [];
                this.ports_select = [];
                for (let i = 0; i < this.serachval['portCodes'].length; i++) {
                  for (let j = 0; j < findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports?.length; j++) {
                    const portCodeValues = this.serachval['portCodes'][i].toLowerCase();
                    const tempApiPortData = findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports[j].portCode.toLowerCase();
                    if (portCodeValues === tempApiPortData) {
                      this.slct_portnname.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports[j].portName);
                      this.ports_select.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports[j].portCode);
                    }
                  }
                }
              }
            }
          }
          if (this.select_feild !== 'yacht') {
            this.FindCruiseYacht = findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts;
            if (this.findCruiseUrlCallGetData) {
              if (this.searchUrlData['yachtIds']) {
                for (let i = 0; i < this.searchUrlData['yachtIds'].length; i++) {
                  for (let j = 0; j < findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts?.length; j++) {
                    const yachtValues = this.searchUrlData['yachtIds'][i];
                    const tempApiYachtData = findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts[j].yachtId;
                    if (yachtValues === tempApiYachtData) {
                      this.slct_yachtName.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts[j].yachtName);
                    }
                  }
                }
              }
            }
            if (this.serachval) {
              if (this.serachval['yachtIds']) {
                this.slct_yachtName = [];
                this.yacht_select = [];
                for (let i = 0; i < this.serachval['yachtIds'].length; i++) {
                  for (let j = 0; j < findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts?.length; j++) {
                    const yachtValues = this.serachval['yachtIds'][i];
                    const tempApiYachtData = findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts[j].yachtId;
                    if (yachtValues === tempApiYachtData) {
                      this.slct_yachtName.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts[j].yachtName);
                      this.yacht_select.push(findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts[j].yachtId);
                    }
                  }
                  if (i === (this.serachval['yachtIds'].length - 1)) {
                  }
                }
              }
            }
          }
        } else {
          if (this.findCruiseUrlCallGetData) {
            if (this.totalData === 0) {
              this.slct_deptnname = [];
              this.dep_dateselect = [];
              this.departure_dateselect = [];
              this.FindCruiseDepdate = [];
            }
          }
          this.select_feild = '';
          this.findACruiseArray = [];
          if (!this.findCruiseUrlCallGetData) {
            this.Reset_find_cruise_search('');
          }
        }

        const res = this.rcycCommonService.getPagignationArray(
          this.totalData,
          this.perPage,
          this.page
        );
        if (this.page === 0) {
          this.pages = res.pages;
          this.rcycCommonService.setSessionData('pagesArray', res.pages);
        }
        if (res.pages.length < this.perPage) {
          this.rcycCommonService.removeSessionData('findACruisePaginationArray');
        }
        this.totalPagecnt = this.pages.length;
        if (this.totalPagecnt > this.perPage) {
          this.pages.splice(this.perPage, this.totalPagecnt - this.perPage);
        }
        this.totalpageno = res.totalpageno;
        this.findcruisehomeflag = false;

        const totalPages = Math.ceil(this.totalData / this.perPage);
        setTimeout(() => {
          if (totalPages <= this.pageNumbersToShow) {
            this.pageNumbersToShow = totalPages;
          } else {
            this.pageNumbersToShow = 5;
            if (window.innerWidth <= 1024) {
              this.pageNumbersToShow = 4;
            }
          }
        }, 0);

        const resArray = [];
        const fullResult = this.fullsearchresult;
        // console.log(this.fullsearchresult, '====+this.fullsearchresult');
        if (fullResult && fullResult.length) {
          for (const index of Object.keys(fullResult)) {
            const factor = fullResult[index];
            // var url='/luxury-cruises/'+item.voyageEmbarkPort.replace(/[^A-Z0-9]/ig, '-')+
            // '-to-'+item.voyageDisembarkPort.replace(/[^A-Z0-9]/ig, '-')+'-'+item.voyageId;
            let voyageEmbarkPort_new;
            let voyageDisembarkPort_new;
            if (factor.voyageEmbarkPort) {
              voyageEmbarkPort_new = factor.voyageEmbarkPort
                .replace(/[^a-zA-Z-]/g, '')
                .replace(/\s/g, '')
                .toLowerCase();
            }
            if (factor.voyageDisembarkPort) {
              voyageDisembarkPort_new = factor.voyageDisembarkPort
                .replace(/[^a-zA-Z-]/g, '')
                .replace(/\s/g, '')
                .toLowerCase();
            }
            if (voyageDisembarkPort_new && voyageDisembarkPort_new && factor.voyageId && factor.voyageUrlPath) {
              factor.voyageUrl_new =
                '/' +
                factor.voyageUrlPath +
                '/' +
                voyageEmbarkPort_new +
                '-to-' +
                voyageDisembarkPort_new +
                '-' +
                factor.voyageId +
                '/itinerary';
            }
            if (voyageDisembarkPort_new && voyageDisembarkPort_new && factor.voyageId && factor.voyageUrlPath) {
              factor.voyageUrl_new_offer =
                '/' +
                factor.voyageUrlPath +
                '/' +
                voyageEmbarkPort_new +
                '-to-' +
                voyageDisembarkPort_new +
                '-' +
                factor.voyageId +
                '/offers-programs';
            }
            factor.pcfiToggleStatus = false;
            if (this.PCFIVoyageIdArray.includes(factor.voyageId)) {
              factor.pcfiToggleStatus = true;
            }
            if (factor.voyageId) {
              factor.callOutText = this.rcycCommonService.getOffersVoyageSortedOutField(factor.voyageId);
            }
            resArray.push(factor);
            if (parseInt(index, 10) + 1 === fullResult.length) {
              this.settingFindACruiseArrayLoad(resArray);
            }
          }
        } else {
          // console.log('Voyage Result returns null');
        }
      }
    } else {
      // this.clear_cruisefilter();
    }
    this.findCruiseUrlCallGetData = false;
    this.loadMoreApiStatus = false;
  }

  /**
   *For reset dropdown if the search result is empty.
   */
  Reset_find_cruise_search(search) {
    this.rcycHomecontentService.find_cruise(search).subscribe(
      response => {
        const findcruise: any = response;
        if (findcruise && findcruise.code === 200) {
          if (findcruise) {

            this.FindcruiseAPI =
              findcruise.data.yachtSearchCriterionForResponseDTO;

            if (this.select_feild !== 'region') {
              this.FindCruiseRegion =
                findcruise?.data?.yachtSearchCriterionForResponseDTO?.regions;
              if (this.pageName === 'destinationsDetails') {
                this.FindCruiseRegion = this.FindCruiseRegion?.filter(item => item.generalDescription.toLowerCase() === this.selectedRegion?.toLocaleLowerCase() || item.generalDescription.toLowerCase().includes(this.selectedRegion?.toLocaleLowerCase()));
              }
            }

            if (this.select_feild !== 'departure_date') {
              const FindCruiseDepdate =
                findcruise.data.yachtSearchCriterionForResponseDTO
                  .departureYearMonths;
              this.DepartureDate_sortMonth(FindCruiseDepdate);
            }

            if (this.select_feild !== 'duration') {
              this.FindCruiseDuration =
                findcruise?.data?.yachtSearchCriterionForResponseDTO?.durationValues;
            }

            if (this.select_feild !== 'ports') {
              this.FindCruisePorts =
                findcruise?.data?.yachtSearchCriterionForResponseDTO?.ports;
            }

            if (this.select_feild !== 'yacht') {
              this.FindCruiseYacht =
                findcruise?.data?.yachtSearchCriterionForResponseDTO?.yachts;
            }
          }
        }
      },
      err => { }
    );
  }

  showDisplayClick(item) {

    // if (this.compareCheckboxArray.length <= 3) {
    // this.comparelimterr = false;
    if (item === 'compare') {
      if (this.compareCheckboxArray && this.compareCheckboxArray.length > 1 && this.compareCheckboxArray.length <= 3) {
        this.get_map_img();
        this.rcycCommonService.setSessionData('compareClickStatus', true);
        this.rcycCommonService.setSessionData('voyageCompareDetails', this.compareCheckboxArray);
      } else if (this.compareCheckboxArray.length <= 1) {

      } else {

        this.comparelimterr = true;
      }
      this.compareClickEventEmitter.emit(this.compareClickStatus);
      window.scrollTo(0, 0)
    } else if (item === 'list') {
      this.current_view = 'list';
      this.listClickStatus = true;
      this.compareClickStatus = false;
      this.gridClickStatus = false;
      this.comparelimterr = false;

      this.gridSelectedStatus = false;
      this.listSelectedStatus = true;
    } else if (item === 'grid') {
      this.loadMoreClickCount = 0;
      this.current_view = 'grid';
      this.gridClickStatus = true;
      this.compareClickStatus = false;
      this.listClickStatus = false;
      this.comparelimterr = false;

      this.gridSelectedStatus = true;
      this.listSelectedStatus = false;
    } else if (item === 'goback') {
      if (this.current_view === 'grid') {
        this.gridClickStatus = true;
        this.listClickStatus = false;
      } else if (this.current_view === 'list') {
        this.gridClickStatus = false;
        this.listClickStatus = true;
      }
      this.compareClickStatus = false;
      this.compareCheckboxArray = [];
      this.comparelimterr = false;
      this.compareClickEventEmitter.emit(this.compareClickStatus);

      this.rcycCommonService.removeSessionData('showDisplayClick');
      this.rcycCommonService.removeSessionData('voyageCompareDetails');
      this.rcycCommonService.removeSessionData('voyageBackButtonPressed');

      const compareClickStatus = this.rcycCommonService.getSessionData('compareClickStatus');
      this.voyageListMasterData = null;
      if (compareClickStatus) {
        this.get_userbasic_details(true);
        this.rcycCommonService.removeSessionData('compareClickStatus');
      } else {
        this.rcycCommonService.removeSessionData('compareClickStatus');
      }
    }
  }

  removeFromCompared(item) {
    document.getElementById('itemId_' + item.voyageId).click();
  }

  // Fuction to check compare in List/Grid View and added item to compare array
  compareCheckboxClick(event, item) {
    if (event?.target?.checked && this.comparelimterr) {
      event.target.checked = false;
      return;
    }
    const elem: Element = document.getElementById('itemId_' + item.voyageId);
    if (event?.target?.checked === true) {
      if (this.compareCheckboxArray.length < 3) {
        this.compareCheckboxArray.push(item);
        this.renderer.addClass(elem, 'active');
      }
    } else {
      this.removecompareCheckboxItem(item);
      // this.renderer.setElementClass(elem, 'active', false);
      this.renderer.removeClass(elem, 'active');
    }
    this.comparelimterr = this.compareCheckboxArray.length > 2;
  }

  get_map_img() {
    let imgname = '';
    let imgsplt: any = [];
    this.compareCheckboxArray.map(eleemt => {
      this.rcycVoyagesService
        .Voyages_tabs(eleemt.voyageId, 'itineraryoverview')
        .subscribe(response => {
          this.offertab_group = response;
          if (this.offertab_group.length > 0) {
            eleemt['map'] =
              this.IMG_BASE_URL + this.offertab_group[0].field_voyage_image;
            imgname = this.offertab_group[0].field_voyage_image
              .split('/')
              .pop();
            imgsplt = imgname.split(/[.\-_*x]/);
            eleemt['altval'] = this.offertab_group[0].field_image_alt_content;
            eleemt[
              'titleval'
            ] = this.offertab_group[0].field_image_title_content;
          }
        });
    });
    this.compareClickStatus = true;
    // this.gridClickStatus = false;
    // this.listClickStatus = false;
  }
  // Fuction to remove item from compare array
  removecompareCheckboxItem(item) {
    this.compareCheckboxArray.splice(
      this.compareCheckboxArray.indexOf(item),
      1
    );
  }

  /**
   * Function for client side pagination
   * @param page
   */
  goToPagignation(page) {
    this.page = page;
    // const currentPage = this.rcycCommonService.getData('currentPage');
    // if (currentPage) {
    //   page = currentPage;
    // }
    this.paginationBtnClick = true;
    this.currentpag = page;
    // this.rcycCommonService.setData('currentPage', page);
    if (this.rcycCommonService.getSessionData('userPreferenceData')) {
      const findACruiseSearchCriteriaData = this.rcycCommonService.getSessionData('findACruiseSearchCriteria');
      if (findACruiseSearchCriteriaData && findACruiseSearchCriteriaData.sortField && findACruiseSearchCriteriaData.sortOrder) {
        this.feild = findACruiseSearchCriteriaData.sortField;
        this.order = findACruiseSearchCriteriaData.sortOrder;
      }
    }

    this.searchdata = {
      regionCodes: this.regionselect,
      departureYearMonths: this.departure_dateselect,
      durations: this.duration_select,
      portCodes: this.ports_select,
      yachtIds: this.yacht_select,
      countryCode: this.UserDetails.country_code,
      page: this.currentpag,
      size: this.perPage,
      sortField: this.feild,
      sortOrder: this.order
    };

    const filterParam = {
      regionselect: this.regionselect,
      slct_regionname: this.slct_regionname,
      dep_dateselect: this.dep_dateselect,
      slct_deptnname: this.slct_deptnname,
      duration_select: this.duration_select,
      slct_duration: this.slct_duration,
      ports_select: this.ports_select,
      slct_portnname: this.slct_portnname
    };

    const mergedFiterCriteria = { ...filterParam, ...this.searchdata };


    this.rcycCommonService.setSessionData('findACruiseSearchCriteria', mergedFiterCriteria);
    this.find_cruise_search(this.searchdata, false);
  }
  /**
   * Functiion for next button in pagination
   */
  next_page() {
    this.paginationNextClickStatus = true;
    const firstnum = this.pages[0];
    const lastnum = this.pages[this.pages.length - 1];
    const paginationParam = {
      totalpageno: this.totalpageno,
      lastnum: this.totalpageno,
      page: this.page
    };
    const findACruisePaginationArray = this.rcycCommonService.getSessionData('findACruisePaginationArray');
    if (findACruisePaginationArray && findACruisePaginationArray.length) {
      this.pages = findACruisePaginationArray;
      if (this.totalpageno > lastnum) {
        this.pages.shift(firstnum);
        this.pages.push(lastnum + 1);
        this.rcycCommonService.setSessionData('findACruisePaginationArray', this.pages);
      }
    } else {
      if (this.totalpageno > lastnum) {
        this.pages.shift(firstnum);
        this.pages.push(lastnum + 1);
        this.rcycCommonService.setSessionData('findACruisePaginationArray', this.pages);
      }
    }
    if (this.totalpageno > this.page + 1) {
      const getUserPreferenceData = this.rcycCommonService.getSessionData('userPreferenceData');
      if (getUserPreferenceData && getUserPreferenceData.currentPage) {
        this.goToPagignation(getUserPreferenceData.currentPage + 1);
        const preferenceData = {
          'currentPage': getUserPreferenceData.currentPage + 1,
          'sortBySelectdValue': getUserPreferenceData.sortBySelectdValue,
          'gridClickStatus': getUserPreferenceData.gridClickStatus
        };
        this.rcycCommonService.setSessionData('userPreferenceData', preferenceData);
      } else {
        this.goToPagignation(this.page + 1);
      }
    }
  }

  /**
   * Function for previous button in pagination
   */
  prev_page() {
    this.paginationPrevClickstatus = true;
    const firstnum = this.pages[0];
    const lastnum = this.pages[this.pages.length - 1];
    const findACruisePaginationArray = this.rcycCommonService.getSessionData('findACruisePaginationArray');
    if (findACruisePaginationArray && findACruisePaginationArray.length) {
      this.pages = findACruisePaginationArray;
      if (firstnum > 1) {
        this.pages.splice(this.pages.indexOf(lastnum), 1);
        this.pages.unshift(firstnum - 1);
        this.rcycCommonService.setSessionData('findACruisePaginationArray', this.pages);
      }
    } else {
      if (firstnum > 1) {
        this.pages.splice(this.pages.indexOf(lastnum), 1);
        this.pages.unshift(firstnum - 1);
        this.rcycCommonService.setSessionData('findACruisePaginationArray', this.pages);
      }
    }
    /* if (firstnum > 1) {
       this.pages.splice(this.pages.indexOf(lastnum), 1);
       this.pages.unshift(firstnum - 1);
       this.rcycCommonService.setData('findACruisePaginationArray', this.pages);
     } */
    if (this.currentpag > 0) {
      if (this.page + 1 > 0) {
        const getUserPreferenceData = this.rcycCommonService.getSessionData('userPreferenceData');
        if (getUserPreferenceData && getUserPreferenceData.currentPage) {
          this.goToPagignation(getUserPreferenceData.currentPage - 1);
          const preferenceData = {
            'currentPage': getUserPreferenceData.currentPage - 1,
            'sortBySelectdValue': getUserPreferenceData.sortBySelectdValue,
            'gridClickStatus': getUserPreferenceData.gridClickStatus
          };
          this.rcycCommonService.setSessionData('userPreferenceData', preferenceData);
        } else {
          this.goToPagignation(this.page - 1);
        }
      } else if (firstnum === 1) {
        this.goToPagignation(0);
      }
    }
  }

  /**
   *
   * @param item Function for passing voyage details to voyagepage
   */
  /*View_Itinerary(item) {
    this.rcycCommonService.setData('VIEWITINERARY', item);
    var url='/luxury-cruises/'+item.voyageEmbarkPort.replace(/[^A-Z0-9]/ig, '-')+
    '-to-'+item.voyageDisembarkPort.replace(/[^A-Z0-9]/ig, '-')+'-'+item.voyageId;
    this.router.navigate([url]);
  }*/


  /**
   * Function for sort departure based on year month and  create new array with same
   * @param scdate
   */
  DepartureDate_sortMonth(scdate) {
    const temp_arry = [];

    scdate.map(function (val, i) {
      val = val.replace('_', '-');
      temp_arry.push(val + '-01');
    });

    this.FindCruiseDepdate = [];
    this.FindCruiseDepdate = temp_arry;

    const FilteredDepDateArray = [];
    if (temp_arry.length) {

      temp_arry.map((item, index) => {
        FilteredDepDateArray.push(this.months[parseInt(item.split('-')[1], 10) - 1] + ' ' + item.split('-')[0]);

        if (index === temp_arry.length - 1) {
          this.FindCruiseDepdate = FilteredDepDateArray;
        }
      });

      // this.Checkotpionselect('')

      // Set FormattedDate globally
      if (!this.formattedDate) {
        this.formattedDate = this.FindCruiseDepdate;
      }


    }
  }

  get_userbasic_details(isBackButton) {
    const prevUrlForBackButton = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
    const searchDetails = this.rcycCommonService.getSessionData('findACruiseSearchCriteria');
    if (prevUrlForBackButton) {
      // Bind data when back to Find a cruise (contain query param)
      // this.activatedRoute.queryParams.subscribe(params => {

      const params = {
        region: '',
        date: '',
        port: '',
        yacht: '',
        duration: ''
      };

      if (this.rcycCommonService.getSessionData('findACruiseRegionCodes')) {
        params.region = this.rcycCommonService.getSessionData('findACruiseRegionCodes');
        setTimeout(() => {
          this.rcycCommonService.removeSessionData('findACruiseRegionCodes');
        }, 500);
      }
      if (this.rcycCommonService.getSessionData('findACruiseDepartureYearMonths')) {
        params.date = this.rcycCommonService.getSessionData('findACruiseDepartureYearMonths');
        setTimeout(() => {
          this.rcycCommonService.removeSessionData('findACruiseDepartureYearMonths');
        }, 500);
      }
      if (this.rcycCommonService.getSessionData('findACruisePortCodes')) {
        params.port = this.rcycCommonService.getSessionData('findACruisePortCodes');
        setTimeout(() => {
          this.rcycCommonService.removeSessionData('findACruisePortCodes');
        }, 500);
      }
      if (this.rcycCommonService.getSessionData('findACruiseyachtId')) {
        params.yacht = this.rcycCommonService.getSessionData('findACruiseyachtId');
        setTimeout(() => {
          this.rcycCommonService.removeSessionData('findACruiseyachtId');
        }, 500);
      }
      if (this.rcycCommonService.getSessionData('findACruiseDuration')) {
        params.duration = this.rcycCommonService.getSessionData('findACruiseDuration');
        setTimeout(() => {
          this.rcycCommonService.removeSessionData('findACruiseDuration');
        }, 500);
      }
      if (params && params.region || params.date || params.port || params.yacht || params.duration) {
        if (!this.searchUrlData) {
          this.searchUrlData = [];
        }
        if (params.region) {
          this.searchUrlData['regionCodes'] = String.prototype.toUpperCase.apply(params.region).split(',');
        }
        if (params.date) {
          this.searchUrlData['departureYearMonths'] = String.prototype.toUpperCase.apply(params.date).split(',');
        }
        if (params.port) {
          this.searchUrlData['portCodes'] = String.prototype.toUpperCase.apply(params.port).split(',');
        }
        if (params.yacht) {
          this.searchUrlData['yachtId'] = (params.yacht).split(',');
        }
        if (params.duration) {
          this.searchUrlData['durations'] = (params.duration).split(',');
        }
        this.findCruiseUrlCallGetData = true;
        this.searchdata = {
          regionCodes: this.searchUrlData['regionCodes'],
          departureYearMonths: this.searchUrlData['departureYearMonths'],
          durations: this.searchUrlData['durations'],
          portCodes: this.searchUrlData['portCodes'],
          yachtIds: this.searchUrlData['yachtId'],
          countryCode: this.UserDetails.country_code,
          page: searchDetails.page, // page bacToResults
          size: this.perPage,
          sortField: this.feild,
          sortOrder: this.order
        };
        if (this.searchUrlData['regionCodes']) {
          this.regionselect = this.searchUrlData['regionCodes'];
        }
        if (this.searchUrlData['departureYearMonths']) {
          this.departure_dateselect = this.searchUrlData['departureYearMonths'];
          const temp_arry = [];
          this.departure_dateselect.map(function (val, i) {
            val = val.replace('_', '-');
            temp_arry.push(val + '-01');
          });
          this.FindCruiseDepdate = [];
          this.FindCruiseDepdate = temp_arry;
          const FilteredDepDateArray = [];
          if (temp_arry.length) {
            temp_arry.map((item, index) => {
              FilteredDepDateArray.push(this.months[parseInt(item.split('-')[1], 10) - 1] + ' ' + item.split('-')[0]);
              if (index === temp_arry.length - 1) {
                this.slct_deptnname = FilteredDepDateArray;
              }
            });
          }
        }
        if (this.searchUrlData['durations']) {
          this.duration_select = this.searchUrlData['durations'];
        }
        if (this.searchUrlData['portCodes']) {
          this.ports_select = this.searchUrlData['portCodes'];
        }
        if (this.searchUrlData['yachtId']) {
          this.yacht_select = this.searchUrlData['yachtId'];
        }
      } else {
        // this.searchdata = {
        //   countryCode: this.UserDetails.country_code,
        //   page: 0,
        //   size: 4,
        //   sortField: this.feild,
        //   sortOrder: this.order
        // };
        if (searchDetails) {
          //  this.rcycCommonService.removeData('findACruiseSearchCriteria');

          if (searchDetails.regionselect) {
            this.regionselect = searchDetails.regionselect;
          }
          if (searchDetails.slct_regionname) {
            this.slct_regionname = searchDetails.slct_regionname;
            // this.backCombineRegion();
          }
          if (searchDetails.dep_dateselect) {
            this.dep_dateselect = searchDetails.dep_dateselect;
          }
          if (searchDetails.slct_deptnname) {
            this.slct_deptnname = searchDetails.slct_deptnname;
          }
          if (searchDetails.ports_select) {
            this.ports_select = searchDetails.ports_select;
          }
          if (searchDetails.slct_portnname) {
            this.slct_portnname = searchDetails.slct_portnname;
          }
          if (searchDetails.yacht_select) {
            this.yacht_select = searchDetails.yacht_select;
          }
          if (searchDetails.slct_yachtName) {
            this.slct_yachtName = searchDetails.slct_yachtName;
          }
          if (searchDetails.duration_select) {
            this.duration_select = searchDetails.duration_select;
          }
          if (searchDetails.slct_duration) {
            this.slct_duration = searchDetails.slct_duration;
          }
          if (searchDetails.page) {
            this.currentpag = searchDetails.page;
          }

          if (searchDetails) {
            this.setSortOrder(searchDetails);
          }
        }
      }
      // });
    } else {
      // console.log('no prev url');
      // this.rcycCommonService.removeData('findACruiseSearchCriteria');
    }


    if (!this.rcycCommonService.getData('UserDetails')) {
      this.rcycCommonService.getUser_Details().subscribe(
        response => {
          this.UserDetails = response;
          let searchfor: any;
          if (this.UserDetails) {
            this.rcycCommonService.setData('UserDetails', this.UserDetails);
            // const searchDetails = this.rcycCommonService.getData('seacrchCriteria');
            if (searchDetails) {
              this.searchdata = searchDetails;
              // this.currentpag = searchDetails.page;
            } else {
              this.searchdata = {
                countryCode: this.UserDetails.country_code,
                page: 0,
                size: 9,
                sortField: this.feild,
                sortOrder: this.order
              };
              if (this.selectedValue === 'DepartureDateAscending') {
                this.searchdata.sortOrder = 'ASC';
                this.searchdata.sortField = 'voyageStartDate';
              }
              this.rcycCommonService.setSessionData('findACruiseSearchCriteria', this.searchdata);
            }

          } else {
            if (searchDetails) {
              this.searchdata = searchDetails;
              // this.currentpag = searchDetails.page;
            } else {
              this.searchdata = {
                countryCode: 'US',
                page: 0,
                size: 9,
                sortField: this.feild,
                sortOrder: this.order
              };
              if (this.selectedValue === 'DepartureDateAscending') {
                this.searchdata.sortOrder = 'ASC';
                this.searchdata.sortField = 'voyageStartDate';
              }
              this.rcycCommonService.setSessionData('findACruiseSearchCriteria', this.searchdata);
            }
          }

          if (this.serachval) {
            this.serachval = this.rcycCommonService.getData(
              'searchcruiseresult'
            );
            searchfor = this.serachval;
          } else {
            searchfor = this.searchdata;
          }

          this.find_cruise_search(this.searchdata, !isBackButton);
        },
        err => {
          let searchfor: any;
          if (searchDetails) {
            this.searchdata = searchDetails;
            // this.currentpag = searchDetails.page;
          } else {
            this.searchdata = {
              countryCode: 'US',
              page: 0,
              size: 9,
              sortField: this.feild,
              sortOrder: this.order
            };
            if (this.selectedValue === 'DepartureDateAscending') {
              this.searchdata.sortOrder = 'ASC';
              this.searchdata.sortField = 'voyageStartDate';
            }
            this.rcycCommonService.setSessionData('findACruiseSearchCriteria', this.searchdata);
          }

          if (this.serachval) {
            this.serachval = this.rcycCommonService.getData(
              'searchcruiseresult'
            );
            searchfor = this.serachval;
          } else {
            searchfor = this.searchdata;
          }

          this.find_cruise_search(this.searchdata, !isBackButton);
        }
      );
    } else {
      this.UserDetails = this.rcycCommonService.getData('UserDetails');
      if (this.UserDetails.country_code) {
        this.UserDetails = this.rcycCommonService.getData('UserDetails');
        this.rcycCommonService.expireLocation();
      } else {
        this.UserDetails.country_code = 'US';
        localStorage.removeItem('UserDetails');
      }

      let searchfor: any;
      if (searchDetails) {
        this.searchdata = searchDetails;
        // this.currentpag = searchDetails.page;
      } else {
        this.activatedRoute.queryParams.subscribe(params => {
          if (params && params.region || params.date || params.port || params.yacht || params.durations) {
            if (!this.searchUrlData) {
              this.searchUrlData = [];
            }
            if (params.region) {
              this.searchUrlData['regionCodes'] = String.prototype.toUpperCase.apply(params.region).split(',');
              // Set Session variables for Query Param Results
              if (this.searchUrlData['regionCodes']) {
                this.rcycCommonService.setSessionData('findACruiseRegionCodes', this.searchUrlData['regionCodes']);
              }
            }
            if (params.date) {
              this.searchUrlData['departureYearMonths'] = String.prototype.toUpperCase.apply(params.date).split(',');
              // Set Session variables for Query Param Results
              if (this.searchUrlData['departureYearMonths']) {
                this.rcycCommonService.setSessionData('findACruiseDepartureYearMonths', this.searchUrlData['departureYearMonths']);
              }
            }
            if (params.durations) {
              this.searchUrlData['durations'] = (params.duration).split(',');
              // Set Session variables for Query Param Results
              if (this.searchUrlData['durations']) {
                this.rcycCommonService.setSessionData('findACruiseyachtId', this.searchUrlData['durations']);
              }
            }
            if (params.port) {
              this.searchUrlData['portCodes'] = String.prototype.toUpperCase.apply(params.port).split(',');
              // Set Session variables for Query Param Results
              if (this.searchUrlData['portCodes']) {
                this.rcycCommonService.setSessionData('findACruisePortCodes', this.searchUrlData['portCodes']);
              }
            }
            if (params.yacht) {
              this.searchUrlData['yachtId'] = (params.yacht).split(',');
              // Set Session variables for Query Param Results
              if (this.searchUrlData['yachtId']) {
                this.rcycCommonService.setSessionData('findACruiseyachtId', this.searchUrlData['yachtId']);
              }
            }
            this.findCruiseUrlCallGetData = true;
            this.searchdata = {
              regionCodes: this.searchUrlData['regionCodes'],
              departureYearMonths: this.searchUrlData['departureYearMonths'],
              durations: this.searchUrlData['durations'],
              portCodes: this.searchUrlData['portCodes'],
              yachtIds: this.yacht_select,
              countryCode: this.UserDetails.country_code,
              page: 0,
              size: 9,
              sortField: this.feild,
              sortOrder: this.order
            };
            if (this.searchUrlData['regionCodes']) {
              this.regionselect = this.searchUrlData['regionCodes'];
            }
            if (this.searchUrlData['departureYearMonths']) {
              this.departure_dateselect = this.searchUrlData['departureYearMonths'];
              const temp_arry = [];
              this.departure_dateselect.map(function (val, i) {
                val = val.replace('_', '-');
                temp_arry.push(val + '-01');
              });
              this.FindCruiseDepdate = [];
              this.FindCruiseDepdate = temp_arry;
              const FilteredDepDateArray = [];
              if (temp_arry.length) {
                temp_arry.map((item, index) => {
                  FilteredDepDateArray.push(this.months[parseInt(item.split('-')[1], 10) - 1] + ' ' + item.split('-')[0]);
                  if (index === temp_arry.length - 1) {
                    this.slct_deptnname = FilteredDepDateArray;
                  }
                });
              }
            }
            if (this.searchUrlData['durations']) {
              this.duration_select = this.searchUrlData['durations'];
            }
            if (this.searchUrlData['portCodes']) {
              this.ports_select = this.searchUrlData['portCodes'];
            }
            if (this.searchUrlData['yachtId']) {
              this.yacht_select = this.searchUrlData['yachtId'];
            }
          } else {
            this.searchdata = {
              countryCode: this.UserDetails.country_code,
              page: 0,
              size: 9,
              sortField: this.feild,
              sortOrder: this.order
            };
          }
        });
        if (this.selectedValue === 'DepartureDateAscending') {
          this.searchdata.sortOrder = 'ASC';
          this.searchdata.sortField = 'voyageStartDate';
        }
      }
      this.rcycCommonService.setSessionData('findACruiseSearchCriteria', this.searchdata);
      this.serachval = this.rcycCommonService.getData('searchcruiseresult');
      if (this.serachval) {
        searchfor = this.serachval;
        this.find_cruise_search(this.serachval, !isBackButton);
      } else {
        searchfor = this.searchdata;
        this.find_cruise_search(this.searchdata, !isBackButton);
      }
    }
  }

  sortBy(value) {
    this.sortByClickStatus = true;
    this.loadMoreClickStatus = false;
    this.loadMoreClickCount = 0;
    // localStorage.setItem('sortBySelectdValue', value);
    const findACruisePaginationArray = this.rcycCommonService.getSessionData('findACruisePaginationArray');
    if (findACruisePaginationArray && findACruisePaginationArray.length) {
      this.pages = findACruisePaginationArray;
    }
    this.compareCheckboxArray = [];
    const elements = this.elementRef.nativeElement.querySelectorAll('.eachOuter.active');
    if (elements.length) {
      elements.forEach(function (el) {
        el.classList.remove('active');
      });
    }

    const compareCheckBox = this.elementRef.nativeElement.querySelectorAll('.compare_checkbox');
    if (compareCheckBox.length) {
      compareCheckBox.forEach(function (el) {
        el.checked = false;
      });
    }
    this.selectedValue = value;
    if (value === 'DepartureDateAscending') {
      this.feild = 'voyageStartDate';
      this.order = 'ASC';
    }
    if (value === 'DepartureDateDescending') {
      this.rcycCommonService.expireLocation();
      this.feild = 'voyageStartDate';
      this.order = 'DESC';
    }
    if (value === 'PricelowTohigh') {
      this.feild = 'startingPrice';
      this.order = 'ASC';
    }
    if (value === 'PricehighTolow') {
      this.feild = 'startingPrice';
      this.order = 'DESC';
    }
    if (value === 'DurationshortestTolongest') {
      this.feild = 'nights';
      this.order = 'ASC';
    }
    if (value === 'DurationlongestToshortest') {
      this.feild = 'nights';
      this.order = 'DESC';
    }



    this.searchdata = {
      regionCodes: this.regionselect,
      departureYearMonths: this.departure_dateselect,
      durations: this.duration_select,
      portCodes: this.ports_select,
      yachtIds: this.yacht_select,
      countryCode: this.UserDetails.country_code,
      page: 0,
      size: this.perPage,
      sortField: this.feild,
      sortOrder: this.order
    };

    // this.currentpag = 0;localStorage.getItem('backToResultVoyageListPrevUrl')

    const filterParam = {
      regionselect: this.regionselect,
      slct_regionname: this.slct_regionname,
      dep_dateselect: this.dep_dateselect,
      slct_deptnname: this.slct_deptnname,
      duration_select: this.duration_select,
      slct_duration: this.slct_duration,
      ports_select: this.ports_select,
      slct_portnname: this.slct_portnname,
      yacht_select: this.yacht_select,
      slct_yachtName: this.slct_yachtName
    };

    const mergedFiterCriteria = { ...filterParam, ...this.searchdata };


    this.rcycCommonService.setSessionData('findACruiseSearchCriteria', mergedFiterCriteria);
    this.rcycCommonService.setSessionData('findACruisePaginationArray', [1, 2, 3, 4, 5, 6, 7, 8]);
    const getUserPreferenceData = this.rcycCommonService.getSessionData('userPreferenceData');
    if (getUserPreferenceData) {
      const preferenceData = {
        'currentPage': 0,
        'sortBySelectdValue': getUserPreferenceData.sortBySelectdValue,
        'gridClickStatus': getUserPreferenceData.gridClickStatus
      };
      this.rcycCommonService.setSessionData('userPreferenceData', preferenceData);
    }
    this.totalData = 0;
    this.goToPagignation(0);
    // this.find_cruise_search(this.searchdata);
  }

  // Function to get Drupal API
  getCruiseImageDrupal() {
    const Api_Url = '/api/v1/voyages/thumbnails?_format=json';

    this.rcycFindACruiseService.getCruiseImageDrupal(Api_Url).subscribe(
      (response: any) => {
        const drupalImageArray: any = response && response.length ? response : [];
        const drupalImageObjectNot = {};
        drupalImageArray.forEach(element => {
          drupalImageObjectNot[element.field_voyages] = { field_voyage_thumbnail: element.field_voyage_thumbnail, title: element.title };
        });
        this.rcycCommonService.setSessionData('drupalImageObjectNot', drupalImageObjectNot);
        this.rcycCommonService.setSessionData('drupalImageArray', response);
        if (drupalImageArray && drupalImageArray.length) {
          this.initialDrupalCall = false;
        }
      },
      (err) => {
        console.log('Error on Getting Cruise Images');
        console.log(err);
      },
    );
  }
  getDataTableDefaultView() {
    const Api_Url = '/api/v1/cruisepageview?_format=json';

    this.rcycFindACruiseService.getCruiseImageDrupal(Api_Url).subscribe(
      (response) => {
        this.DataTableDefaultViewArray = response;

        if (this.DataTableDefaultViewArray && this.DataTableDefaultViewArray.length) {

          this.DataTableDefaultViewArray.map((viewArray) => {
            if (viewArray.field_page_group == "Find-A-Cruise")
              this.DataTableDefaultView = viewArray.field_view_types;
            if (!this.DataTableDefaultView && viewArray.name == "Default")
              this.DataTableDefaultView = viewArray.field_view_types;
          });

          this.setLayoutViewStyle();

        }
      },
      (err) => {
        console.log('Error on Getting Cruise Images');
        console.log(err);
      }
    );
  }

  // Function to filter based on voyageId
  // filterByVoyageId(voyageId) {
  //   // const imageItem = this.drupalImageArray.filter(item => {
  //   //   if (item.field_voyages === voyageId) {
  //   //     return item.field_voyage_thumbnail;
  //   //   }
  //   // })
  //
  //
  //   // const {highlight, images} = this.rcycHomeService.getVoyageImages(this.trumbnails, this.data);
  //   // this.slider = images;
  //   // this.highlight = highlight;
  //
  //   // return imageItem[0]?.field_voyage_thumbnail;
  // }

  // Function to set Previous Url For Voyage Page Back Btn
  setPreviousUrlForVoyagePageBack(url) {
    const preferenceData = {
      'currentPage': this.currentpag,
      'sortBySelectdValue': this.selectedValue,
      'imageMode': this.imageMode,
      'gridClickStatus': this.gridClickStatus
    };
    this.rcycCommonService.setSessionData('userPreferenceData', preferenceData);
    this.rcycCommonService.setSessionData('backToResultVoyageListPrevUrl', this.router.url);
  }
  // Set Sort Order
  setSortOrder(data) {
    if (data.sortField === 'voyageStartDate' && data.sortOrder === 'ASC') {
      this.selectedValue = 'DepartureDateAscending';
    } else if (data.sortField === 'voyageStartDate' && data.sortOrder === 'DESC') {
      this.selectedValue = 'DepartureDateDescending';
    } else if (data.sortField === 'startingPrice' && data.sortOrder === 'ASC') {
      this.selectedValue = 'PricelowTohigh';
    } else if (data.sortField === 'startingPrice' && data.sortOrder === 'DESC') {
      this.selectedValue = 'PricehighTolow';
    } else if (data.sortField === 'nights' && data.sortOrder === 'ASC') {
      this.selectedValue = 'DurationshortestTolongest';
    } else if (data.sortField === 'nights' && data.sortOrder === 'DESC') {
      this.selectedValue = 'DurationlongestToshortest';
    }
  }

  // Filter based on typed Region
  onRegionTyped(event) {
    this.regionTyped = event;


    if (this.regionTyped) {
      this.region = true;
      this.FindCruiseFilteredRegion = this.findCruiseData.regions;
      this.FindCruiseFilteredRegion = this.FindCruiseRegionTemp.filter(item => item.generalDescription.toLowerCase()
        .startsWith(this.regionTyped.toLowerCase()));


      this.FindCruiseRegion = this.FindCruiseFilteredRegion;

      if (!this.FindCruiseRegion.length) {
        if (document.getElementById('region_dropdown')) {
          // document.getElementById('region_dropdown').style.visibility = 'hidden';
          document.getElementById('region_dropdown').style.display = 'none';
          this.regionDropDownFlag = false;
        }
      } else {
        if (document.getElementById('region_dropdown')) {
          // document.getElementById('region_dropdown').style.visibility = 'visible';
          document.getElementById('region_dropdown').style.display = 'block';
          this.regionDropDownFlag = true;
        }

      }

    } else {

      // ================================= Set Required Region
      if (!this.regionTypedInitialFlag) {

        // this.FindCruiseRegionTemp =   this.FindCruiseRegion;

        if (!this.slct_deptnname.length && !this.ports_select.length && !this.slct_regionname.length && !this.slct_yachtName.length && !this.slct_duration.length) {
          const findCruiseDefaultData = this.findCruiseData;
          if (findCruiseDefaultData && findCruiseDefaultData.regions) {
            this.FindCruiseRegionTemp = findCruiseDefaultData.regions;
          }
        } else {
          this.FindCruiseRegionTemp = this.FindCruiseRegion;
        }


        // Flag to set the region fild changed ( set only on initial change)
        this.regionTypedInitialFlag = true;
      } else if (!this.slct_deptnname.length && !this.ports_select.length && !this.slct_regionname.length && !this.slct_yachtName.length && !this.slct_duration.length) {
        // this.FindCruiseRegionTemp =  this.findCruiseData.regions;
        // this.FindCruiseRegionTemp =  this.findCruiseData.regions;
        const findCruiseDefaultData = this.findCruiseData;
        if (findCruiseDefaultData && findCruiseDefaultData.regions) {
          this.FindCruiseRegionTemp = findCruiseDefaultData.regions;
        }


      } else {
        if (!this.FindCruiseRegion.length) {
          this.FindCruiseRegion = this.FindCruiseRegionTemp;
        }
      }

      // =================================  Set Required Region

      // Set All Cruise Region array default
      this.FindCruiseRegion = this.FindCruiseRegion ? this.FindCruiseRegion : this.FindCruiseRegionTemp;
      this.regionTypedFlag = true;
      this.regionDDTabIndex = '-1';
      this.region = true;
      this.regionDropDownFlag = true;
      if (document.getElementById('region_dropdown')) {
        // document.getElementById('region_dropdown').style.visibility = 'visible';
        document.getElementById('region_dropdown').style.display = 'block';
      }

      setTimeout(() => {
        if (document.getElementById('region_input')) {
          document.getElementById('region_input').focus();
        }
      }, 500);
    }
  }

  // Filter based on typed Port
  onPortTyped(event) {
    this.portTyped = event;

    if (event) {
      this.FindCruiseFilteredPort = this.FindCruisePortTemp
        .filter(
          (item) => {
            const countryMatch = item.countryName ? item.countryName.toLowerCase().startsWith(this.portTyped.toLowerCase()) : false;
            let portMatch = false;
            const portSegment = item.portName.split(",").map((segment) => segment.trim());
            for (let index = 0; index < portSegment.length; index++) {
              const segment = portSegment[index];
              if (segment.toLowerCase().startsWith(this.portTyped.toLowerCase())) {
                portMatch = true;
                break;
              }
            }
            return portMatch || countryMatch
          }
        );
      this.FindCruisePorts = this.FindCruiseFilteredPort;

      if (!this.FindCruisePorts.length) {
        this.portDropDownFlag = false;
        if (document.getElementById('ports_dropdown')) {
          // document.getElementById('ports_dropdown').style.visibility = 'hidden';
          document.getElementById('ports_dropdown').style.display = 'none';
          this.ports = false;
        }

      } else {
        this.portDropDownFlag = true;
        this.ports = true;
        if (document.getElementById('ports_dropdown')) {
          // document.getElementById('ports_dropdown').style.visibility = 'visible';
          document.getElementById('ports_dropdown').style.display = 'block';
        }

      }
    } else {

      // ================================= Set Required Ports
      if (!this.portTypedInitialFlag) {

        //  this.FindCruisePortTemp =  this.FindCruisePorts;
        if (!this.slct_deptnname.length && !this.ports_select.length && !this.slct_regionname.length && !this.slct_yachtName.length && !this.slct_duration.length) {
          const findCruiseDefaultData = this.findCruiseData;
          if (findCruiseDefaultData && findCruiseDefaultData.ports) {
            this.FindCruisePortTemp = findCruiseDefaultData.ports;
          }
        } else {
          this.FindCruisePortTemp = this.FindCruisePorts;
        }

        // Flag to set the port field changed ( set only on initial change)
        this.portTypedInitialFlag = true;
      } else if ((!this.slct_deptnname.length && !this.ports_select.length && !this.slct_regionname.length && !this.slct_yachtName.length && !this.slct_duration.length)) {
        // this.FindCruisePortTemp =  this.findCruiseData.ports;

        const findCruiseDefaultData = this.findCruiseData;
        if (findCruiseDefaultData && findCruiseDefaultData.ports) {
          this.FindCruisePortTemp = findCruiseDefaultData.ports;
        }

      } else {
        this.FindCruisePortTemp = this.FindCruisePorts;
      }

      // =================================  Set Required Ports
      // Set All Cruise Ports array default
      this.FindCruisePorts = this.FindCruisePorts ? this.FindCruisePorts : this.FindCruisePortTemp;
      this.portTypedFlag = true;
      this.portsDDTabIndex = '-1';
      this.portDropDownFlag = true;
      this.ports = true;
      if (document.getElementById('ports_dropdown')) {
        // document.getElementById('ports_dropdown').style.visibility = 'visible';
        document.getElementById('ports_dropdown').style.display = 'block';
        //   this.portDropDownFlag = true;
      }
    }
  }

  // Filter based on typed Duration
  onDurationTyped(event) {
    this.durationTyped = event;
    if (this.durationTyped) {
      this.duration = true;
      this.FindCruiseFilteredDuration = this.findCruiseData.durationValues;

      this.FindCruiseDuration = this.FindCruiseFilteredDuration;

      if (!this.FindCruiseDuration.length) {
        if (document.getElementById("duration_dropdown")) {
          document.getElementById("duration_dropdown").style.display = "none";
          this.durationDropDownFlag = false;
        }
      } else {
        if (document.getElementById("duration_dropdown")) {
          document.getElementById("duration_dropdown").style.display = "block";
          this.durationDropDownFlag = true;
        }
      }
    } else {
      // ================================= Set Required Duration
      if (!this.durationTypedInitialFlag) {
        if (
          !this.slct_deptnname.length &&
          !this.ports_select.length &&
          !this.slct_regionname.length &&
          !this.slct_yachtName.length &&
          !this.slct_duration.length
        ) {
          const findCruiseDefaultData = this.findCruiseData;
          if (findCruiseDefaultData && findCruiseDefaultData.durationValues) {
            this.FindCruiseDurationTemp = findCruiseDefaultData.durationValues;
          }
        } else {
          this.FindCruiseDurationTemp = this.FindCruiseDuration;
        }

        // Flag to set the duration field changed ( set only on initial change)
        this.durationTypedInitialFlag = true;
      } else if (
        !this.slct_deptnname.length &&
        !this.ports_select.length &&
        !this.slct_regionname.length &&
        !this.slct_yachtName.length &&
        !this.slct_duration.length &&
        !this.duration_select.length
      ) {
        const findCruiseDefaultData =
          this.findCruiseData;
        if (
          findCruiseDefaultData &&
          findCruiseDefaultData.durationValues
        ) {
          this.FindCruiseDurationTemp =
            findCruiseDefaultData.durationValues;
        }
      }
      // =================================  Set Required Duration

      // Set All Cruise duration array default
      this.FindCruiseDuration = this.FindCruiseDuration ? this.FindCruiseDuration : this.FindCruiseDurationTemp;
      this.durationTypedFlag = true;
      this.durationDDTabIndex = "-1";
      this.duration = true;
      this.durationDropDownFlag = true;
      if (document.getElementById("duration_dropdown")) {
        document.getElementById("duration_dropdown").style.display = "block";
      }

      setTimeout(() => {
        if (document.getElementById("duration_input")) {
          document.getElementById("duration_input").focus();
        }
      }, 500);
    }
  }


  // Filter based on typed Departure Date
  onDepartureDateTyped(event) {
    this.departureDateTyped = event;


    if (this.departureDateTyped) {

      this.FindCruiseDepartureDateTemp = this.formattedDate; //
      this.FindCruiseFilteredDepartureDate = this.FindCruiseDepartureDateTemp.filter(item => item.toLowerCase()
        .startsWith(this.departureDateTyped.toLowerCase()));


      this.FindCruiseDepdate = this.FindCruiseFilteredDepartureDate;

      if (!this.FindCruiseDepdate.length) {
        this.departureDateDropDownFlag = false;
        if (document.getElementById('departure_dropdown')) {
          // document.getElementById('departure_dropdown').style.visibility = 'hidden';
          document.getElementById('departure_dropdown').style.display = 'none';
          this.departure_date = false;
        }
        // this.FindCruiseRegion = this.FindCruiseRegionTemp;
      } else {
        this.departureDateDropDownFlag = true;
        this.departure_date = true;
        if (document.getElementById('departure_dropdown')) {
          // document.getElementById('departure_dropdown').style.visibility = 'visible';
          document.getElementById('departure_dropdown').style.display = 'block';
        }

      }

    } else {
      // ================================= Set Required fotmatted date
      if (!this.departureDateInitialFlag) {
        // this.FindCruiseDepartureDateTemp =  this.FindCruiseDepdate;
        if (!this.slct_deptnname.length && !this.ports_select.length && !this.slct_regionname.length && !this.slct_yachtName.length && !this.slct_duration.length) {
          const findCruiseDefaultData = this.findCruiseData;
          if (findCruiseDefaultData && findCruiseDefaultData.departureYearMonths) {
            //  this.FindCruiseDepartureDateTemp =  this.FindCruiseDepdate;
            const temp_arry = [];
            findCruiseDefaultData.departureYearMonths.map(function (val, i) {
              val = val.replace('_', '-');
              temp_arry.push(val + '-01');
            });
            const FilteredDepDateArray = [];
            if (temp_arry.length) {

              temp_arry.map((item, index) => {
                FilteredDepDateArray.push(this.months[parseInt(item.split('-')[1], 10) - 1] + ' ' + item.split('-')[0]);

                if (index === temp_arry.length - 1) {
                  this.FindCruiseDepartureDateTemp = FilteredDepDateArray;
                }
              });
            }
          }
        } else {
          this.FindCruiseDepartureDateTemp = this.FindCruiseDepdate;
        }

        // Flag to set the departure Dtae field changed ( set only on initial change)
        this.departureDateInitialFlag = true;
      } else if (!this.slct_deptnname.length && !this.ports_select.length && !this.slct_regionname.length && !this.slct_yachtName.length && !this.slct_duration.length) {

        // this.FindCruiseDepartureDateTemp =  this.formattedDate;
        const findCruiseDefaultData = this.findCruiseData;
        if (findCruiseDefaultData && findCruiseDefaultData.departureYearMonths) {
          // this.FindCruisePortTemp = findCruiseDefaultData.data.yachtSearchCriterionForResponseDTO.departureYearMonths;
          const temp_arry = [];
          findCruiseDefaultData.departureYearMonths.map(function (val, i) {
            val = val.replace('_', '-');
            temp_arry.push(val + '-01');
          });
          const FilteredDepDateArray = [];
          if (temp_arry.length) {

            temp_arry.map((item, index) => {
              FilteredDepDateArray.push(this.months[parseInt(item.split('-')[1], 10) - 1] + ' ' + item.split('-')[0]);

              if (index === temp_arry.length - 1) {
                this.FindCruiseDepartureDateTemp = FilteredDepDateArray;
              }
            });
          }
        }

      } else {
        this.FindCruiseDepartureDateTemp = this.FindCruiseDepdate;
      }

      // =================================  Set Required fotmatted date

      // Set All Cruise DepartureDate array default
      this.FindCruiseDepdate = this.FindCruiseDepdate ? this.FindCruiseDepdate : this.FindCruiseDepartureDateTemp;
      this.departureDateTypedFlag = true;
      this.departureDateDDTabIndex = '-1';

      this.departure_date = true;
      this.departureDateDropDownFlag = true;
      if (document.getElementById('departure_dropdown')) {
        // document.getElementById('departure_dropdown').style.visibility = 'visible';
        document.getElementById('departure_dropdown').style.display = 'block';
      }

    }
  }

  // Filter based on typed Yacht
  onYachtTyped(event) {
    this.yachtTyped = event;
    if (this.yachtTyped) {
      this.yacht = true;
      this.FindCruiseFilteredYacht = this.findCruiseData.yachts;
      this.FindCruiseFilteredYacht = this.FindCruiseYachtTemp.filter((item) =>
        item.yachtName.toLowerCase().startsWith(this.yachtTyped.toLowerCase())
      );
      this.FindCruiseYacht = this.FindCruiseFilteredYacht;
      if (!this.FindCruiseYacht.length) {
        if (document.getElementById("yacht_dropdown")) {
          document.getElementById("yacht_dropdown").style.display = "none";
          this.yachtDropDownFlag = false;
        }
      } else {
        if (document.getElementById("yacht_dropdown")) {
          document.getElementById("yacht_dropdown").style.display = "block";
          this.yachtDropDownFlag = true;
        }
      }
    } else {
      // ================================= Set Required Yacht
      if (!this.yachtTypedInitialFlag) {
        if (
          !this.slct_deptnname.length &&
          !this.ports_select.length &&
          !this.slct_regionname.length &&
          !this.slct_yachtName.length &&
          !this.slct_duration.length
        ) {
          const findCruiseDefaultData = this.findCruiseData;
          if (findCruiseDefaultData && findCruiseDefaultData.yachts) {
            this.FindCruiseYachtTemp = findCruiseDefaultData.yachts;
          }
        } else {
          this.FindCruiseYachtTemp = this.FindCruiseYacht;
        }
        // Flag to set the yacht field changed ( set only on initial change)
        this.yachtTypedInitialFlag = true;
      } else if (
        !this.slct_deptnname.length &&
        !this.ports_select.length &&
        !this.slct_regionname.length &&
        !this.slct_yachtName.length &&
        !this.slct_duration.length
      ) {
        const findCruiseDefaultData = this.findCruiseData;
        if (
          findCruiseDefaultData &&
          findCruiseDefaultData.yachts
        ) {
          this.FindCruiseYachtTemp =
            findCruiseDefaultData.yachts;
        }
      }

      // =================================  Set Required yacht
      // Set All Cruise yacht array default
      this.FindCruiseYacht = this.FindCruiseYacht ? this.FindCruiseYacht : this.FindCruiseYachtTemp;
      this.yachtTypedFlag = true;
      this.yachtDDTabIndex = "-1";
      this.yacht = true;
      this.yachtDropDownFlag = true;
      if (document.getElementById("yacht_dropdown")) {
        document.getElementById("yacht_dropdown").style.display = "block";
      }

      setTimeout(() => {
        if (document.getElementById("yacht_dropdown")) {
          document.getElementById("yacht_dropdown").focus();
        }
      }, 500);
    }
  }

  // Function execute when click
  searchFieldClick(field) {
    switch (field) {
      case 'region':

        if (!this.ports_select.length && !this.dep_dateselect.length && !this.yacht_select.length && !this.duration_select.length) {

          this.FindCruiseRegion = this.findCruiseData.regions;
        }
        this.regionTypedFlag = true;
        this.regionDDTabIndex = '-1';
        if (!this.regionDropDownFlag) {
          this.regionTypedFlag = true;
          this.regionDDTabIndex = '-1';
          setTimeout(() => {
            if (document.getElementById('region_input')) {
              document.getElementById('region_input').focus();
            }
          }, 500);


          const regionDropDown = document.getElementById('region_dropdown');
          if (regionDropDown) {
            // regionDropDown.style.visibility = 'visible';
            regionDropDown.style.display = 'block';
            this.regionDropDownFlag = true;
          }


          // Filter Region based on typed region
          if (this.regionTyped) {  // 2
            this.onRegionTyped(this.regionTyped);
            // For Enable input
            this.regionTypedFlag = true;
            this.regionDDTabIndex = '-1';
            this.region = true;
          } else {
            this.onRegionTyped('');
            // For Enable input
            this.regionTypedFlag = true;
            this.regionDDTabIndex = '-1';
            this.region = true;
          }
        } else {

          setTimeout(() => {
            if (document.getElementById('region_input')) {
              document.getElementById('region_input').focus();
            }
          }, 300);

          this.regionDropDownFlag = false;
          this.regionTypedFlag = false;
          this.regionDDTabIndex = '0';
        }

        break;

      case 'departure_date':



        // if (!this.regionselect.length  &&   !this.slct_portnname.length  && !this.dep_dateselect.length) {
        //   this.FindCruiseDepdate = this.formattedDate;
        //   this.DepartureDate_sortMonth(this.FindCruiseDepdate);
        // } else if (!this.regionselect.length && !this.slct_portnname.length) {
        //   this.DepartureDate_sortMonth(this.FindCruiseDepdate);
        // } else if (!this.regionselect.length) {

        // }
        this.departureDateTypedFlag = true;
        this.departureDateDDTabIndex = '-1';
        if (!this.departureDateDropDownFlag) {
          this.departureDateTypedFlag = true;
          this.departureDateDDTabIndex = '-1';
          setTimeout(() => {
            if (document.getElementById('departureDate_input')) {
              document.getElementById('departureDate_input').focus();
            }
          }, 300);

          const departureDateDropDown = document.getElementById('departure_dropdown');
          if (departureDateDropDown) {
            // departureDateDropDown.style.visibility = 'visible';
            departureDateDropDown.style.display = 'block';
            this.departureDateDropDownFlag = true;
          }


          // Filter Departure Date based on Date typed
          if (this.departureDateTyped) {
            this.onDepartureDateTyped(this.departureDateTyped);
            // For Enable input
            this.departureDateTypedFlag = true;
            this.departureDateDDTabIndex = '-1';
            this.departure_date = true;
          } else {
            this.onDepartureDateTyped('');
            // For Enable input
            this.departureDateTypedFlag = true;
            this.departureDateDDTabIndex = '-1';
            this.departure_date = true;
          }
        } else {

          setTimeout(() => {
            if (document.getElementById('departureDate_input')) {
              document.getElementById('departureDate_input').focus();
            }
          }, 300);
          this.departureDateDropDownFlag = false;
          this.departureDateTypedFlag = false;
          this.departureDateDDTabIndex = '0';
        }

        break;

      case 'duration':
        if (!this.ports_select.length && !this.dep_dateselect.length && !this.regionselect.length && !this.yacht_select.length) {
          this.FindCruiseDuration = this.findCruiseData.durationValues;
        }
        this.durationTypedFlag = true;
        this.durationDDTabIndex = '-1';
        if (!this.durationDropDownFlag) {
          this.durationTypedFlag = true;
          this.durationDDTabIndex = '-1';
          setTimeout(() => {
            if (document.getElementById('duration_input')) {
              document.getElementById('duration_input').focus();
            }
          }, 300);
          const durationDropDown = document.getElementById('duration_dropdown');
          if (durationDropDown) {
            durationDropDown.style.display = 'block';
            this.durationDropDownFlag = true;
          }
          // Filter yacht based on duration typed
          if (this.durationTyped) {
            this.onDurationTyped(this.durationTyped);
            // For Enable input
            this.durationTypedFlag = true;
            this.durationDDTabIndex = '-1';
            this.duration = true;
          } else {
            this.onDurationTyped('');
            // For Enable input
            this.durationTypedFlag = true;
            this.durationDDTabIndex = '-1';
            this.duration = true;
          }
        } else {
          setTimeout(() => {
            if (document.getElementById('duration_input')) {
              document.getElementById('duration_input').focus();
            }
          }, 300);
          this.durationDropDownFlag = false;
          this.durationTypedFlag = false;
          this.durationDDTabIndex = '0';
        }
        break;

      case 'ports':

        if (!this.regionselect.length && !this.dep_dateselect.length && !this.yacht_select.length && !this.duration_select.length) {
          this.FindCruisePorts = this.findCruiseData.ports;
        }
        if (!this.portDropDownFlag) {
          this.portTypedFlag = true;
          this.portsDDTabIndex = '-1';

          setTimeout(() => {
            if (document.getElementById('port_input')) {
              document.getElementById('port_input').focus();
            }
          }, 300);


          // setTimeout(() => {
          const portDropDown = document.getElementById('ports_dropdown');
          if (portDropDown) {
            // portDropDown.style.visibility = 'visible';
            portDropDown.style.display = 'block';
            this.portDropDownFlag = true;
          }
          // }, 500);

          // Filter Port based on typed ports
          if (this.portTyped) {

            this.onPortTyped(this.portTyped);
            // For Enable input
            this.portTypedFlag = true;
            this.portsDDTabIndex = '-1';
            this.ports = true;
          } else {

            this.onPortTyped('');
            // For Enable input
            this.portTypedFlag = true;
            this.portsDDTabIndex = '-1';
            this.ports = true;
          }
        } else {
          setTimeout(() => {
            if (document.getElementById('port_input')) {
              document.getElementById('port_input').focus();
            }
          }, 300);
          this.portDropDownFlag = false;
          this.portTypedFlag = false;
          this.portsDDTabIndex = '0';
        }
        break;

      case 'yacht':
        if (!this.ports_select.length && !this.dep_dateselect.length && !this.regionselect.length && !this.duration_select.length) {
          this.FindCruiseYacht = this.findCruiseData.yachts;
        }
        this.yachtTypedFlag = true;
        this.yachtDDTabIndex = '-1';
        if (!this.yachtDropDownFlag) {
          this.yachtTypedFlag = true;
          this.yachtDDTabIndex = '-1';
          setTimeout(() => {
            if (document.getElementById('yacht_input')) {
              document.getElementById('yacht_input').focus();
            }
          }, 300);
          const yachtDropDown = document.getElementById('yacht_dropdown');
          if (yachtDropDown) {
            yachtDropDown.style.display = 'block';
            this.yachtDropDownFlag = true;
          }
          // Filter yacht based on yacht typed
          if (this.yachtTyped) {
            this.onYachtTyped(this.yachtTyped);
            // For Enable input
            this.yachtTypedFlag = true;
            this.yachtDDTabIndex = '-1';
            this.yacht = true;
          } else {
            this.onYachtTyped('');
            // For Enable input
            this.yachtTypedFlag = true;
            this.yachtDDTabIndex = '-1';
            this.yacht = true;
          }
        } else {
          setTimeout(() => {
            if (document.getElementById('yacht_input')) {
              document.getElementById('yacht_input').focus();
            }
          }, 300);
          this.yachtDropDownFlag = false;
          this.yachtTypedFlag = false;
          this.yachtDDTabIndex = '0';
        }
        break;
    }

  }

  onRegionFocus(event) {
    const regionFocusSpan = document.getElementsByClassName('regionDD')[0] as HTMLElement;
    if (!this.regionTypedFlag) {
      regionFocusSpan.classList.add('regionDDFocus');
    }
    this.regionFocusFlag = true;
    this.departureDateFocusFlag = false;
    this.portFocusFlag = false;
    this.yachtFocusFlag = false;
    this.durationFocusFlag = false;

    if (!this.slct_portnname.length) {
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      this.ports = false;
      this.portDropDownFlag = false;
      this.portTyped = null;
    } else if (this.slct_portnname.length) {
      this.ports = true;
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      this.portTyped = null;
    }
    if (!this.slct_deptnname.length) {
      this.departureDateTypedFlag = false;
      this.departure_date = false;
      this.departureDateDropDownFlag = false;
      this.departureDateTyped = null;
    } else if (this.slct_deptnname.length) {
      this.departure_date = true;
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    }
    if (!this.slct_yachtName.length) {
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      this.yacht = false;
      this.yachtDropDownFlag = false;
      this.yachtTyped = null;
    } else if (this.slct_yachtName.length) {
      this.yacht = true;
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      this.yachtTyped = null;
    }
    if (!this.slct_duration.length) {
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      this.duration = false;
      this.durationDropDownFlag = false;
      this.durationTyped = null;
    } else if (this.slct_duration.length) {
      this.duration = true;
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      this.durationTyped = null;
    }
    // For change region dropdown Icon
    this.regionIconFocussed = true;

  }
  onDepartureDateFocus(event) {
    const departureDateFocusSpan = document.getElementsByClassName('departureDateDD')[0] as HTMLElement;
    if (!this.departureDateTypedFlag) {
      departureDateFocusSpan.classList.add('departureDateDDFocus');
    }
    this.departureDateFocusFlag = true;
    this.portFocusFlag = false;
    this.regionFocusFlag = false;
    this.yachtFocusFlag = false;
    this.durationFocusFlag = false;

    if (!this.slct_regionname.length) {
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.region = false;
      this.regionDropDownFlag = false;
      this.regionTyped = null;
    } else if (this.slct_regionname.length) {
      this.region = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.regionTyped = null;
    }
    if (!this.slct_portnname.length) {
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      this.ports = false;
      this.portDropDownFlag = false;
      this.portTyped = null;
    } else if (this.slct_portnname.length) {
      this.ports = true;
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      this.portTyped = null;
    }
    if (!this.slct_yachtName.length) {
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      this.yacht = false;
      this.yachtDropDownFlag = false;
      this.yachtTyped = null;
    } else if (this.slct_yachtName.length) {
      this.yacht = true;
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      this.yachtTyped = null;
    }
    if (!this.slct_duration.length) {
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      this.duration = false;
      this.durationDropDownFlag = false;
      this.durationTyped = null;
    } else if (this.slct_duration.length) {
      this.duration = true;
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      this.durationTyped = null;
    }
    // For change departure date Icon
    this.departureDateIconFocussed = true;
  }
  onDurationFocus(event) {
    const durationFocusSpan = document.getElementsByClassName('durationDD')[0] as HTMLElement;
    if (!this.durationTypedFlag) {
      durationFocusSpan.classList.add('durationDDFocus');
    }
    this.durationFocusFlag = true;
    this.regionFocusFlag = false;
    this.departureDateFocusFlag = false;
    this.portFocusFlag = false;
    this.yachtFocusFlag = false;

    if (!this.slct_portnname.length) {
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      this.ports = false;
      this.portDropDownFlag = false;
      this.portTyped = null;
    } else if (this.slct_portnname.length) {
      this.ports = true;
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      this.portTyped = null;
    }
    if (!this.slct_deptnname.length) {
      this.departureDateTypedFlag = false;
      this.departure_date = false;
      this.departureDateDropDownFlag = false;
      this.departureDateTyped = null;
    } else if (this.slct_deptnname.length) {
      this.departure_date = true;
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    }
    if (!this.slct_regionname.length) {
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.region = false;
      this.regionDropDownFlag = false;
      this.regionTyped = null;
    } else if (this.slct_regionname.length) {
      this.region = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.regionTyped = null;
    }
    if (!this.slct_yachtName.length) {
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      this.yacht = false;
      this.yachtDropDownFlag = false;
      this.yachtTyped = null;
    } else if (this.slct_yachtName.length) {
      this.yacht = true;
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      this.yachtTyped = null;
    }
    // For change duration dropdown Icon
    this.durationIconFocussed = true;

  }
  onPortFocus(event) {
    const portFocusSpan = document.getElementsByClassName('portsDD')[0] as HTMLElement;
    if (!this.portTypedFlag) {
      portFocusSpan.classList.add('portsDDFocus');
    }
    this.portFocusFlag = true;
    this.departureDateFocusFlag = false;
    this.regionFocusFlag = false;
    this.yachtFocusFlag = false;
    this.durationFocusFlag = false;


    if (!this.slct_regionname.length) {
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.region = false;
      this.regionDropDownFlag = false;
      this.regionTyped = null;
    } else if (this.slct_regionname.length) {
      this.region = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.regionTyped = null;
    }
    if (!this.slct_deptnname.length) {
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departure_date = false;
      this.departureDateDropDownFlag = false;
      this.departureDateTyped = null;
    } else if (this.slct_deptnname.length) {
      this.departure_date = true;
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    }
    if (!this.slct_yachtName.length) {
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      this.yacht = false;
      this.yachtDropDownFlag = false;
      this.yachtTyped = null;
    } else if (this.slct_yachtName.length) {
      this.yacht = true;
      this.yachtTypedFlag = false;
      this.yachtDDTabIndex = '0';
      this.yachtTyped = null;
    }
    if (!this.slct_duration.length) {
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      this.duration = false;
      this.durationDropDownFlag = false;
      this.durationTyped = null;
    } else if (this.slct_duration.length) {
      this.duration = true;
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      this.durationTyped = null;
    }
    // For change port Icon
    this.portIconFocussed = true;
  }
  onYachtFocus(event) {
    const yachtFocusSpan = document.getElementsByClassName('yachtDD')[0] as HTMLElement;
    if (!this.yachtTypedFlag) {
      yachtFocusSpan.classList.add('yachtDDFocus');
    }
    this.yachtFocusFlag = true;
    this.regionFocusFlag = false;
    this.departureDateFocusFlag = false;
    this.portFocusFlag = false;
    this.durationFocusFlag = false;

    if (!this.slct_regionname.length) {
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.region = false;
      this.regionDropDownFlag = false;
      this.regionTyped = null;
    } else if (this.slct_regionname.length) {
      this.region = true;
      this.regionTypedFlag = false;
      this.regionDDTabIndex = '0';
      this.regionTyped = null;
    }
    if (!this.slct_portnname.length) {
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      this.ports = false;
      this.portDropDownFlag = false;
      this.portTyped = null;
    } else if (this.slct_portnname.length) {
      this.ports = true;
      this.portTypedFlag = false;
      this.portsDDTabIndex = '0';
      this.portTyped = null;
    }
    if (!this.slct_deptnname.length) {
      this.departureDateTypedFlag = false;
      this.departure_date = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    } else if (this.slct_deptnname.length) {
      this.departure_date = true;
      this.departureDateTypedFlag = false;
      this.departureDateDDTabIndex = '0';
      this.departureDateTyped = null;
    }
    if (!this.slct_duration.length) {
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      this.duration = false;
      this.durationDropDownFlag = false;
      this.durationTyped = null;
    } else if (this.slct_duration.length) {
      this.duration = true;
      this.durationTypedFlag = false;
      this.durationDDTabIndex = '0';
      this.durationTyped = null;
    }
    // For change yacht dropdown Icon
    this.yachtIconFocussed = true;
  }

  /* ===================================================================
       Host Listener
    ========================================================================= */
  @HostListener('document:click', ['$event'])
  clickout(event) {

    // Remove Three Dropdowns Focus Flag on Focussing Find A Cruise Btn
    if (event.target.classList.contains('findACruiseSearchBtn')) {
      this.regionFocusFlag = false;
    }

    // Related with REGION
    if (document.querySelector('.regionDD')) {
      if (document.querySelector('.regionDD').contains(event.target)) {
        if (this.pageName === 'destinationsDetails') return;
        const regionFocusSpan = document.getElementsByClassName('regionDD')[0] as HTMLElement;
        regionFocusSpan.classList.remove('regionDDFocus');
        this.searchFieldClick('region');
        this.regionHideOtherDropdown();
      }
    }
    if (document.getElementById('region_input')) {
      if (document.getElementById('region_input').contains(event.target)) {
        this.regionHideOtherDropdown();
      }
    }

    // Related with DEPARTUREDATE
    if (document.querySelector('.departureDateDD')) {
      if (document.querySelector('.departureDateDD').contains(event.target)) {
        const departureDateFocusSpan = document.getElementsByClassName('departureDateDD')[0] as HTMLElement;
        departureDateFocusSpan.classList.remove('departureDateDDFocus');
        this.searchFieldClick('departure_date');
        this.departureDateHideOtherDropdown();
      }
    }
    if (document.getElementById('departureDate_input')) {
      if (document.getElementById('departureDate_input').contains(event.target)) {
        this.departureDateHideOtherDropdown();
      }
    }

    // Related with PORTS
    if (document.querySelector('.portsDD')) {
      if (document.querySelector('.portsDD').contains(event.target)) {
        const regionFocusSpan = document.getElementsByClassName('portsDD')[0] as HTMLElement;
        regionFocusSpan.classList.remove('portsDDFocus');
        this.searchFieldClick('ports');
        this.portsDateHideOtherDropdown();
      }
    }
    if (document.getElementById('port_input')) {
      if (document.getElementById('port_input').contains(event.target)) {
        this.portsDateHideOtherDropdown();
      }
    }

    // Related with Yachts
    if (document.querySelector('.yachtDD')) {
      if (document.querySelector('.yachtDD').contains(event.target)) {
        const yachtFocusSpan = document.getElementsByClassName('yachtDD')[0] as HTMLElement;
        yachtFocusSpan.classList.remove('yachtDDFocus');
        this.searchFieldClick('yacht');
        this.yachtHideOtherDropdown();
      }
    }
    if (document.getElementById('yacht_input')) {
      if (document.getElementById('yacht_input').contains(event.target)) {
        this.yachtHideOtherDropdown();
      }
    }

    // Related with Duration
    if (document.querySelector('.durationDD')) {
      if (document.querySelector('.durationDD').contains(event.target)) {
        const durationFocusSpan = document.getElementsByClassName('durationDD')[0] as HTMLElement;
        durationFocusSpan.classList.remove('durationDDFocus');
        this.searchFieldClick('duration');
        this.durationHideOtherDropdown();
      }
    }
    if (document.getElementById('duration_input')) {
      if (document.getElementById('duration_input').contains(event.target)) {
        this.durationHideOtherDropdown();
      }
    }

    if (
      (document.querySelector('.regionDD') && document.querySelector('.regionDD').contains(event.target))
      || (document.getElementById('region_input') && document.getElementById('region_input').contains(event.target))
      || (document.getElementById('region_dropdown') && document.getElementById('region_dropdown').contains(event.target))

      || (document.querySelector('.departureDateDD') && document.querySelector('.departureDateDD').contains(event.target))
      || (document.getElementById('departureDate_input') && document.getElementById('departureDate_input').contains(event.target))
      || (document.getElementById('departure_dropdown') && document.getElementById('departure_dropdown').contains(event.target))

      || (document.querySelector('.portsDD') && document.querySelector('.portsDD').contains(event.target))
      || (document.getElementById('port_input') && document.getElementById('port_input').contains(event.target))
      || (document.getElementById('ports_dropdown') && document.getElementById('ports_dropdown').contains(event.target))

      || (document.querySelector('.yachtDD') && document.querySelector('.yachtDD').contains(event.target))
      || (document.getElementById('yacht_input') && document.getElementById('yacht_input').contains(event.target))
      || (document.getElementById('yacht_dropdown') && document.getElementById('yacht_dropdown').contains(event.target))

      || (document.querySelector('.durationDD') && document.querySelector('.durationDD').contains(event.target))
      || (document.getElementById('duration_input') && document.getElementById('duration_input').contains(event.target))
      || (document.getElementById('duration_dropdown') && document.getElementById('duration_dropdown').contains(event.target))
    ) {
    } else {
      this.outsideFiltersClick();
    }

  }

  backCombineRegion() {
    this.FindCruiseRegion.forEach((item1, index1) => {
      this.slct_regionname.forEach((item2, index2) => {
        if (item1 === item2) {
          item1.selected = true;
        } else {
          item1.selected = false;
        }
      });
    });
  }
  /* ===================================================================
       regionHideOtherDropdown
    ========================================================================= */
  regionHideOtherDropdown() {
    // check other twos are open Starts
    const portDropDown = document.getElementById('ports_dropdown');
    const departureDateDropDown = document.getElementById('departure_dropdown');
    const yachtDropDown = document.getElementById('yacht_dropdown');
    const durationDropDown = document.getElementById('duration_dropdown');

    if (portDropDown && portDropDown.style) {
      // portDropDown.style.visibility = 'hidden';
      portDropDown.style.display = 'none';
    }
    if (departureDateDropDown && departureDateDropDown.style) {
      // departureDateDropDown.style.visibility = 'hidden';
      departureDateDropDown.style.display = 'none';
    }
    if (yachtDropDown && yachtDropDown.style) {
      yachtDropDown.style.display = 'none';
    }
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.display = 'none';
    }
    this.portDropDownFlag = false;
    this.portTypedFlag = false;
    this.portsDDTabIndex = '0';
    this.departureDateDropDownFlag = false;
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    this.yachtDropDownFlag = false;
    this.yachtTypedFlag = false;
    this.yachtDDTabIndex = '0';
    this.durationDropDownFlag = false;
    this.durationTypedFlag = false;
    this.durationDDTabIndex = '0';
    if (!this.ports_select.length) {
      this.ports = false;
    }
    if (!this.dep_dateselect.length) {
      this.departure_date = false;
    }
    if (!this.yacht_select.length) {
      this.yacht = false;
    }
    if (!this.duration_select.length) {
      this.duration = false;
    }

    // check other twos are open Ends
    // Focusing the Corresponding field Field
    this.regionTypedFlag = true;
    this.regionDDTabIndex = '-1';
    if (document.getElementById('region_input')) {
      document.getElementById('region_input').focus();
    }
  }

  /* ===================================================================
    departureDateHideOtherDropdown
 ========================================================================= */
  departureDateHideOtherDropdown() {

    // check other twos are open Starts
    const portDropDown = document.getElementById('ports_dropdown');
    const regionDropDown = document.getElementById('region_dropdown');
    const yachtDropDown = document.getElementById('yacht_dropdown');
    const durationDropDown = document.getElementById('duration_dropdown');

    if (portDropDown && portDropDown.style) {
      // portDropDown.style.visibility = 'hidden';
      portDropDown.style.display = 'none';
    }
    if (regionDropDown && regionDropDown.style) {
      // regionDropDown.style.visibility = 'hidden';
      regionDropDown.style.display = 'none';
    }
    if (yachtDropDown && yachtDropDown.style) {
      yachtDropDown.style.display = 'none';
    }
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.display = 'none';
    }
    this.portDropDownFlag = false;
    this.portTypedFlag = false;
    this.portsDDTabIndex = '0';
    this.regionDropDownFlag = false;
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    this.yachtDropDownFlag = false;
    this.yachtTypedFlag = false;
    this.yachtDDTabIndex = '0';
    this.durationDropDownFlag = false;
    this.durationTypedFlag = false;
    this.durationDDTabIndex = '0';
    if (!this.ports_select.length) {
      this.ports = false;
    }
    if (!this.slct_regionname.length) {
      this.region = false;
    }
    if (!this.yacht_select.length) {
      this.yacht = false;
    }
    if (!this.duration_select.length) {
      this.duration = false;
    }

    // check other twos are open Ends
    // Focusing the Corresponding field Field

    this.departureDateTypedFlag = true;
    this.departureDateDDTabIndex = '-1';
    // setTimeout(() => {
    if (document.getElementById('departureDate_input')) {
      document.getElementById('departureDate_input').focus();
    }
    // }, 500);
  }

  /* ===================================================================
       regionHideOtherDropdown
    ========================================================================= */
  durationHideOtherDropdown() {
    // check other four are open Starts
    const portDropDown = document.getElementById('ports_dropdown');
    const departureDateDropDown = document.getElementById('departure_dropdown');
    const yachtDropDown = document.getElementById('yacht_dropdown')
    const regionDropDown = document.getElementById('region_dropdown')

    if (portDropDown && portDropDown.style) {
      portDropDown.style.display = 'none';
    }
    if (departureDateDropDown && departureDateDropDown.style) {
      departureDateDropDown.style.display = 'none';
    }
    if (yachtDropDown && yachtDropDown.style) {
      yachtDropDown.style.display = 'none';
    }
    if (regionDropDown && regionDropDown.style) {
      regionDropDown.style.display = 'none';
    }
    this.portDropDownFlag = false;
    this.portTypedFlag = false;
    this.portsDDTabIndex = '0';
    this.departureDateDropDownFlag = false;
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    this.yachtDropDownFlag = false;
    this.yachtTypedFlag = false;
    this.yachtDDTabIndex = '0';
    this.regionDropDownFlag = false;
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    if (!this.ports_select.length) {
      this.ports = false;
    }
    if (!this.dep_dateselect.length) {
      this.departure_date = false;
    }
    if (!this.yacht_select.length) {
      this.yacht = false;
    }
    if (!this.slct_regionname.length) {
      this.region = false;
    }

    // check other fours are open Ends
    // Focusing the Corresponding Field
    this.durationTypedFlag = true;
    this.durationDDTabIndex = '-1';
    if (document.getElementById('duration_input')) {
      document.getElementById('duration_input').focus();
    }
  }

  /* ===================================================================
   portsDateHideOtherDropdown
========================================================================= */
  portsDateHideOtherDropdown() {

    // check other twos are open Starts

    const regionDropDown = document.getElementById('region_dropdown');
    const departureDateDropDown = document.getElementById('departure_dropdown');
    const yachtDropDown = document.getElementById('yacht_dropdown');
    const durationDropDown = document.getElementById('duration_dropdown');

    if (regionDropDown && regionDropDown.style) {
      // regionDropDown.style.visibility = 'hidden';
      regionDropDown.style.display = 'none';
    }
    if (departureDateDropDown && departureDateDropDown.style) {
      // departureDateDropDown.style.visibility = 'hidden';
      departureDateDropDown.style.display = 'none';
    }
    if (yachtDropDown && yachtDropDown.style) {
      yachtDropDown.style.display = 'none';
    }
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.display = 'none';
    }
    this.regionDropDownFlag = false;
    this.regionTypedFlag = false;
    this.departureDateDropDownFlag = false;
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    this.regionDDTabIndex = '0';
    this.yachtDropDownFlag = false;
    this.yachtTypedFlag = false;
    this.yachtDDTabIndex = '0';
    this.durationDropDownFlag = false;
    this.durationTypedFlag = false;
    this.durationDDTabIndex = '0';
    if (!this.slct_regionname.length) {
      this.region = false;
    }
    if (!this.dep_dateselect.length) {
      this.departure_date = false;
    }
    if (!this.yacht_select.length) {
      this.yacht = false;
    }
    if (!this.duration_select.length) {
      this.duration = false;
    }

    // check other twos are open Ends
    // Focusing the Corresponding field Field
    this.portTypedFlag = true;
    this.portsDDTabIndex = '-1';
    // setTimeout(() => {
    if (document.getElementById('port_input')) {
      document.getElementById('port_input').focus();
    }
    // }, 500);
  }
  /**
   * yachtHideOtherDropdown
   */
  yachtHideOtherDropdown() {
    // Start of check if other three are open
    const portDropDown = document.getElementById('ports_dropdown');
    const departureDateDropDown = document.getElementById('departure_dropdown');
    const regionDropDown = document.getElementById('region_dropdown');
    const durationDropDown = document.getElementById('duration_dropdown');
    if (portDropDown && portDropDown.style) {
      portDropDown.style.display = 'none';
    }
    if (departureDateDropDown && departureDateDropDown.style) {
      departureDateDropDown.style.display = 'none';
    }
    if (regionDropDown && regionDropDown.style) {
      regionDropDown.style.display = 'none'
    }
    if (durationDropDown && durationDropDown.style) {
      durationDropDown.style.display = 'none';
    }
    this.regionDropDownFlag = false;
    this.regionTypedFlag = false;
    this.regionDDTabIndex = '0';
    this.portDropDownFlag = false;
    this.portTypedFlag = false;
    this.portsDDTabIndex = '0';
    this.departureDateDropDownFlag = false;
    this.departureDateTypedFlag = false;
    this.departureDateDDTabIndex = '0';
    this.durationDropDownFlag = false;
    this.durationTypedFlag = false;
    this.durationDDTabIndex = '0';
    if (!this.dep_dateselect.length) {
      this.departure_date = false;
    }
    if (!this.ports_select.length) {
      this.ports = false;
    }
    if (!this.regionselect.length) {
      this.region = false;
    }
    if (!this.duration_select.length) {
      this.duration = false;
    }
    // End of check other twos are open
    // Focusing the Corresponding field Field
    this.yachtTypedFlag = true;
    this.yachtDDTabIndex = '-1';
    if (document.getElementById('yacht_input')) {
      document.getElementById('yacht_input').focus();
    }
  }


  /* ===================================================================
    outsideFiltersClick
 ========================================================================= */
  outsideFiltersClick() {
    if (this.rcycCommonService.getData('searchcruiseresult')) {
    } else {
      const regionDropDown = document.getElementById('region_dropdown');

      if (regionDropDown && regionDropDown.style) { // If Region visible
        // regionDropDown.style.visibility = 'hidden';
        regionDropDown.style.display = 'none';
        this.regionDDTabIndex = '0';
        if (!this.slct_regionname.length) {
          this.region = false;
          this.regionDropDownFlag = false;
          this.regionTypedFlag = false;
          this.regionTyped = null;
        } else {
          this.regionDropDownFlag = false;
          this.regionTypedFlag = false;
          this.region = true;
          //
          this.regionTyped = null;
        }
      } else {
        this.regionDDTabIndex = '0';
        if (!this.slct_regionname.length) {
          this.region = false;
          this.regionDropDownFlag = false;
          this.regionTypedFlag = false;
          this.regionTyped = null;
        } else {
          //  this.region = true;
          this.regionTypedFlag = false;
          this.regionTyped = null; // new line
          // regionDropDown.style.visibility = 'hidden';
        }
      }

      const departureDateDropDown = document.getElementById('departure_dropdown');

      if (departureDateDropDown && departureDateDropDown.style) {
        // departureDateDropDown.style.visibility = 'hidden';
        departureDateDropDown.style.display = 'none';
        if (!this.slct_deptnname.length) {
          this.departure_date = false;
          this.departureDateDropDownFlag = false;
          this.departureDateTypedFlag = false;
          this.departureDateDDTabIndex = '0';
          this.departureDateTyped = null;
        } else {
          this.departureDateDropDownFlag = false;
          this.departureDateTypedFlag = false;
          this.departureDateDDTabIndex = '0';
          this.departure_date = true;
          //
          this.departureDateTyped = null;
        }
      } else {
        if (!this.slct_deptnname.length) {
          this.departure_date = false;
          this.departureDateDropDownFlag = false;
          this.departureDateTypedFlag = false;
          this.departureDateDDTabIndex = '0';
          this.departureDateTyped = null;
        } else {
          // this.departure_date = true;
          this.departureDateTypedFlag = false;
          this.departureDateDDTabIndex = '0';
          this.departureDateTyped = null; // new line
          // departureDateDropDown.style.visibility = 'hidden';
        }
      }

      const portDropDown = document.getElementById('ports_dropdown');

      if (portDropDown && portDropDown.style) {
        // portDropDown.style.visibility = 'hidden';
        portDropDown.style.display = 'none';
        if (!this.slct_portnname.length) {
          this.ports = false;
          this.portDropDownFlag = false;
          this.portTypedFlag = false;
          this.portsDDTabIndex = '0';
          this.portTyped = null;
        } else {
          this.portDropDownFlag = false;
          this.portTypedFlag = false;
          this.portsDDTabIndex = '0';
          this.ports = true;
          //
          this.portTyped = null;
        }
      } else {
        if (!this.slct_portnname.length) {
          this.ports = false;
          this.portDropDownFlag = false;
          this.portTypedFlag = false;
          this.portsDDTabIndex = '0';
          this.portTyped = null;
        } else {
          // this.ports = true;
          this.portTypedFlag = false;
          this.portsDDTabIndex = '0';
          this.portTyped = null; // new line
          // portDropDown.style.visibility = 'hidden';
        }
      }

      const yachtDropDown = document.getElementById('yacht_dropdown');
      if (yachtDropDown && yachtDropDown.style) { // If yacht visible
        yachtDropDown.style.display = 'none';
        this.yachtDDTabIndex = '0';
        if (!this.slct_yachtName.length) {
          this.yacht = false;
          this.yachtDropDownFlag = false;
          this.yachtTypedFlag = false;
          this.yachtTyped = null;
        } else {
          this.yachtDropDownFlag = false;
          this.yachtTypedFlag = false;
          this.yacht = true;
          this.yachtTyped = null;
        }
      } else {
        this.yachtDDTabIndex = '0';
        if (!this.slct_yachtName.length) {
          this.yacht = false;
          this.yachtDropDownFlag = false;
          this.yachtTypedFlag = false;
          this.yachtTyped = null;
        } else {
          this.yachtTypedFlag = false;
          this.yachtTyped = null;
        }
      }

      const durationDropDown = document.getElementById('duration_dropdown');
      if (durationDropDown && durationDropDown.style) { // If duration visible
        durationDropDown.style.display = 'none';
        this.durationDDTabIndex = '0';
        if (!this.slct_duration.length) {
          this.duration = false;
          this.durationDropDownFlag = false;
          this.durationTypedFlag = false;
          this.durationTyped = null;
        } else {
          this.durationDropDownFlag = false;
          this.durationTypedFlag = false;
          this.duration = true;
          this.durationTyped = null;
        }
      } else {
        this.durationDDTabIndex = '0';
        if (!this.slct_duration.length) {
          this.duration = false;
          this.durationDropDownFlag = false;
          this.durationTypedFlag = false;
          this.durationTyped = null;
        } else {
          this.durationTypedFlag = false;
          this.durationTyped = null;
        }
      }
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.regionFocusFlag) {
      const regionMainSpan: HTMLElement = document.getElementsByClassName('regionDD')[0] as HTMLElement;
      if (regionMainSpan) {
        regionMainSpan.focus();
        regionMainSpan.classList.add('regionDDFocus');
      }
    } else if (this.departureDateFocusFlag) {
      const departureDateMainSpan: HTMLElement = document.getElementsByClassName('departureDateDD')[0] as HTMLElement;
      if (departureDateMainSpan) {
        departureDateMainSpan.focus();
        departureDateMainSpan.classList.add('departureDateDDFocus');
      }
    } else if (this.portFocusFlag) {
      const portMainSpan: HTMLElement = document.getElementsByClassName('portsDD')[0] as HTMLElement;
      if (portMainSpan) {
        portMainSpan.focus();
        portMainSpan.classList.add('portsDDFocus');
      }
    } else if (this.regionFocusFlag) {
      const yachtMainSpan: HTMLElement = document.getElementsByClassName('yachtDD')[0] as HTMLElement;
      if (yachtMainSpan) {
        yachtMainSpan.focus();
        yachtMainSpan.classList.add('yachtDDFocus');
      }
    } else if (this.durationFocusFlag) {
      const durationMainSpan: HTMLElement = document.getElementsByClassName('durationDD')[0] as HTMLElement;
      if (durationMainSpan) {
        durationMainSpan.focus();
        durationMainSpan.classList.add('durationDDFocus');
      }
    }

    this.outsideFiltersClick();
  }

  mouseEnterOnRequestAQuoteButton(event) {
    if (event && event.target && event.target.id) {
      document.getElementById(event.target.id).style.color = 'black';
      document.getElementById(event.target.id).style.background = 'white';
      document.getElementById(event.target.id).style.border = '1px solid black';
    }
  }

  mouseLeaveOnRequestAQuoteButton(event) {
    if (event && event.target && event.target.id) {
      document.getElementById(event.target.id).style.color = 'white';
      document.getElementById(event.target.id).style.background = 'black';
    }
  }

  /*
  mouseEnterOnCompareButton(event, position) {

    if (position === 'top' && this.compareCheckboxArray.length >= 2) {
      document.getElementById('btn_cmpr_top').style.textDecoration = 'underline';
    } else if (position === 'bottom' && this.compareCheckboxArray.length >= 2) {
      document.getElementById('btn_cmpr_bottom').style.textDecoration = 'underline';
    }

  }
  mouseLeaveOnCompareButton(event, position) {
    if (position === 'top' && this.compareCheckboxArray.length >= 2) {
      document.getElementById('btn_cmpr_top').style.textDecoration = null;
    } else if (position === 'bottom' && this.compareCheckboxArray.length >= 2) {
      document.getElementById('btn_cmpr_bottom').style.textDecoration = null;
    }
  } */

  /***********************************************************************************************************************
    Function on Region Dropdown Focus Out
  ***********************************************************************************************************************/
  onRegionFocusOut(event) {
    this.regionIconFocussed = false;
    const regionFocusSpan = document.getElementsByClassName('regionDD')[0] as HTMLElement;
    regionFocusSpan.classList.remove('regionDDFocus');
  }

  /***********************************************************************************************************************
    Function on Departured Date Dropdown Focus Out
  ***********************************************************************************************************************/
  onDepartureDateFocusOut(event) {
    this.departureDateIconFocussed = false;
    const departureDateFocusSpan = document.getElementsByClassName('departureDateDD')[0] as HTMLElement;
    departureDateFocusSpan.classList.remove('departureDateDDFocus');
  }
  /***********************************************************************************************************************
    Function on Port Dropdown Focus Out
  ***********************************************************************************************************************/
  onPortFocusOut(event) {
    this.portIconFocussed = false;
    const portFocusSpan = document.getElementsByClassName('portsDD')[0] as HTMLElement;
    portFocusSpan.classList.remove('portsDDFocus');
  }
  /***********************************************************************************************************************
    Function on yacht Dropdown Focus Out
  ***********************************************************************************************************************/
  onYachtFocusOut(event) {
    this.yachtIconFocussed = false;
    const yachtFocusSpan = document.getElementsByClassName('yachtDD')[0] as HTMLElement;
    yachtFocusSpan.classList.remove('yachtDDFocus');
  }
  /***********************************************************************************************************************
    Function on duration Dropdown Focus Out
  ***********************************************************************************************************************/
  onDurationFocusOut(event) {
    this.durationIconFocussed = false;
    const durationFocusSpan = document.getElementsByClassName('durationDD')[0] as HTMLElement;
    durationFocusSpan.classList.remove('durationDDFocus');
  }
  /* =============================================================================
          Function on Grid - List Focus
   ===============================================================================*/
  gridListFocus(event, type, position) {
    if (position === 'top') {
      if (type === 'grid') {
        // event.target.style.textDecoration = 'underline';
        // this.gridSelectedStatus = false;
        // this.listSelectedStatus = false;

        this.gridFocus = true;
        this.listFocus = false;

      } else if (type === 'list') {
        // event.target.style.textDecoration = 'underline';

        // this.gridSelectedStatus = false;
        // this.listSelectedStatus = false;

        this.listFocus = true;
        this.gridFocus = false;

      }
    } else if (position === 'bottom') {
      if (type === 'grid') {
        // if (this.gridClickStatus) {
        // event.target.style.textDecoration = 'underline';
        // }
        // this.gridSelectedStatus = false;
        // this.listSelectedStatus = false;

        this.gridFocus = true;
        this.listFocus = false;

      } else if (type === 'list') {
        // if (this.listClickStatus) {
        // event.target.style.textDecoration = 'underline';
        // }
        // this.gridSelectedStatus = false;
        // this.listSelectedStatus = false;

        this.listFocus = true;
        this.gridFocus = false;
      }
    }
  }
  onPaginationNextFocus(event, position) {
    if (position === 'top') {
      this.paginationNextTopFocussed = true;
    } else if (position === 'bottom') {
      this.paginationNextBottomFocussed = true;
    }

  }
  onPaginationNextFocusOut(event, position) {
    if (position === 'top') {
      this.paginationNextTopFocussed = false;
    } else if (position === 'bottom') {
      this.paginationNextBottomFocussed = false;
    }
  }

  setImageMode(position) {
    this.imageMode = null;
    setTimeout(() => {
      this.imageMode = position;
      this.findACruiseArray.forEach(element => {
        element.imageMode = this.imageMode;
      });
    }, 100);
  }

  /* =============================================================================
    Function on Grid - List Mouse Enter
  ===============================================================================*/
  gridListFocusOut(event, type, position) {
    const topGrid = document.getElementById('btn_grid_top');
    const bottomGrid = document.getElementById('btn_grid_bottom');

    const topList = document.getElementById('btn_list_top');
    const bottomList = document.getElementById('btn_list_bottom');

    if (position === 'top') {
      if (type === 'grid') {

        if (topGrid) {
          topGrid.style.textDecoration = 'none';
        }
        if (bottomGrid) {
          bottomGrid.style.textDecoration = 'none';
        }

      } else {

        if (topList) {
          topList.style.textDecoration = 'none';
        }
        if (bottomList) {
          bottomList.style.textDecoration = 'none';
        }

      }
    } else if (position === 'bottom') {
      if (type === 'grid') {

        if (topGrid) {
          topGrid.style.textDecoration = 'none';
        }
        if (bottomGrid) {
          bottomGrid.style.textDecoration = 'none';
        }

      } else {

        if (topList) {
          topList.style.textDecoration = 'none';
        }
        if (bottomList) {
          bottomList.style.textDecoration = 'none';
        }

      }
    }
  }
  /* =============================================================================
    Function to remove Region From Selected  Regions
  ===============================================================================*/
  removeRegionFromSelectedRegion(FindCruiseRegion) {

    if (FindCruiseRegion && FindCruiseRegion.length) {
      const regionselectTemp = [];
      const slct_regionnameTemp = [];
      if (this.regionselect.length) {
        FindCruiseRegion.map((item, index1) => {
          this.regionselect.map((item1, index2) => {
            if (item.travelRegion === item1) {
              regionselectTemp.push(item1);
            }
            if (index1 === (FindCruiseRegion.length - 1)) {
              this.regionselect = regionselectTemp;
            }
          });
          this.slct_regionname.map((item2, index3) => {
            if (item.generalDescription === item2) {
              slct_regionnameTemp.push(item2);
            }
            if (index1 === (FindCruiseRegion.length - 1)) {
              this.slct_regionname = slct_regionnameTemp;
            }
          });
        });
      }
    }
  }

  /* =============================================================================
    Function to remove Port From Selected Ports
  ===============================================================================*/
  removePortFromSelectedPort(FindCruisePorts) {

    if (FindCruisePorts && FindCruisePorts.length) {
      const ports_selectTemp = [];
      const slct_portnnameTemp = [];
      if (this.slct_portnname.length) {
        FindCruisePorts.map((item, index1) => {
          this.ports_select.map((item1, index2) => {
            if (item.portCode === item1) {
              ports_selectTemp.push(item1);
            }
            if (index1 === (FindCruisePorts.length - 1)) {
              this.ports_select = ports_selectTemp;
            }
          });
          this.slct_portnname.map((item2, index3) => {
            if (item.portName === item2) {
              slct_portnnameTemp.push(item2);
            }
            if (index1 === (FindCruisePorts.length - 1)) {
              this.slct_portnname = slct_portnnameTemp;
            }
          });
        });
      }
    }
  }

  /* =============================================================================
     Function to show/hide filter
   ===============================================================================*/
  showHideFilterClick(item) {
    let footerHeight: number = 0;
    if (document.querySelector('#rcyc-page-footer .footer-fat')) {
      footerHeight += (document.querySelector('#rcyc-page-footer .footer-fat') as HTMLElement).offsetHeight;
    }
    if (document.querySelector('#rcyc-page-footer footer.g03')) {
      footerHeight += (document.querySelector('#rcyc-page-footer footer.g03') as HTMLElement).offsetHeight;
      // footerHeight += (document.querySelector('#rcyc-page-footer footer.g03') as HTMLElement).offsetHeight;
    }
    if (document.querySelector('.loadMoreBtnOuter')) {
      footerHeight += (document.querySelector('.loadMoreBtnOuter') as HTMLElement).offsetHeight;
    }
    footerHeight += 320;
    if (document.documentElement.scrollTop > document.documentElement.scrollHeight - footerHeight) {
      window.scrollTo(0, 0);
    } else {
      if (document.documentElement.scrollTop + document.documentElement.offsetHeight != document.documentElement.scrollHeight) {
        if (item === true) {
          document.body.style.overflowY = "hidden";
          if (this.onlyShowFilterStatus) {
            this.showFilterStickyHeader = true;
          }
          this.addDrodDownSecFilterStyle();
        } else
          document.body.style.overflowY = "unset";
      } else {
        this.scrollEndFiterStatus = true;
        this.addDrodDownSecFilterStyle();
      }
      this.showHideFilterStatus = item === true;
    }
  }

  addDrodDownSecFilterStyle() {
    const filterDiv = (document.querySelector('.dropdown_sec') as HTMLElement);
    if (filterDiv && window.innerWidth < 480 &&
      (this.showFilterStickyHeader || (this.scrollEndFiterStatus && this.showHideFilterStatus))
    ) {
      (document.querySelector('.dropdown_sec') as HTMLElement).style.marginTop = '-49px';
    } else if (filterDiv && window.innerWidth < 600 &&
      (this.showFilterStickyHeader || (this.scrollEndFiterStatus && this.showHideFilterStatus))
    ) {
      (document.querySelector('.dropdown_sec') as HTMLElement).style.marginTop = '-79px';
    } else if (filterDiv && window.innerWidth < 767 &&
      (this.showFilterStickyHeader || (this.scrollEndFiterStatus && this.showHideFilterStatus))
    ) {
      (document.querySelector('.dropdown_sec') as HTMLElement).style.marginTop = '-116px';
    }
  }

  ngAfterViewInit() {

  }
  eachOuterCruiseHeightAlign() {

    let maxEachVoyagenameHeight: number = 0;
    if (document.querySelectorAll('.gridView .voyageName')) {
      Array.from(document.querySelectorAll('.gridView .voyageName')).forEach(
        function (el) {
          if (el.scrollHeight > maxEachVoyagenameHeight) {
            maxEachVoyagenameHeight = el.scrollHeight;
          }
        }
      );
      this.maxEachVoyagenameHeight = maxEachVoyagenameHeight;
    }

    let maxEachDDFHeight: number = 0;
    if (document.querySelectorAll('.gridView .eachRow-3')) {
      Array.from(document.querySelectorAll('.gridView .eachRow-3')).forEach(
        function (el) {
          // console.info(Math.max(el.scrollHeight, maxEachDDFHeight));
          if (el.scrollHeight > maxEachDDFHeight) {
            maxEachDDFHeight = el.scrollHeight;
          }
        }
      );
      this.maxEachDDFHeight = maxEachDDFHeight;
    }
  }

  // Function to set find a cruise data on back to voyage click
  settingFindACruiseArrayLoad(resArray) {
    const isBackToUrlDatas = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
    const fullLoadedSearchResults = this.rcycCommonService.getSessionData('fullLoadedSearchResults');

    if (isBackToUrlDatas && fullLoadedSearchResults?.cruiseListArray.length) {
      this.findACruiseArray = fullLoadedSearchResults.cruiseListArray;
      this.loadMoreClickCount = fullLoadedSearchResults.loadMoreClickCount;
      this.viewingVoyageId = this.rcycCommonService.getSessionData('viewingVoyageId');

      this.backToResultUrlHandle();

      if (this.initialDrupalCall) {
        this.getCruiseImageDrupal();
      }

      if (this.viewingVoyageId) {
        let scrollToClassTimeOut;
        clearTimeout(scrollToClassTimeOut);
        scrollToClassTimeOut = setTimeout(() => {
          const scrollToClass = '.eachOuter-grid-' + this.viewingVoyageId;
          if (document.querySelector(scrollToClass)) {
            document.querySelector(scrollToClass).scrollIntoView();
          }
        }, 1000);
      }
    } else {
      // this.backToResultUrlHandle();
      if ((this.sortByClickStatus && this.loadMoreClickStatus)
        || (this.filtervalueClickStatus && this.loadMoreClickStatus)
        || (this.loadMoreClickStatus)
      ) {
        if ((this.findACruiseArray && this.findACruiseArray.length && this.totalData > this.perPage)) {

          this.findACruiseArray = this.findACruiseArray.concat(resArray);

        } else {
          this.findACruiseArray = resArray;
        }
      } else {
        this.findACruiseArray = resArray;
      }

      const paramFullLoadedSearchResults = {
        cruiseListArray: this.findACruiseArray,
        loadMoreClickCount: this.loadMoreClickCount,
      }

      if (this.rcycCommonService.getSessionData('fullLoadedSearchResults')) {
        this.rcycCommonService.removeSessionData('fullLoadedSearchResults');
      }
      this.rcycCommonService.setSessionData('fullLoadedSearchResults', paramFullLoadedSearchResults);

      if (this.initialDrupalCall) {
        this.getCruiseImageDrupal();
      }
    }
    if (window.innerWidth > 560) {
      let timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {

        this.eachOuterCruiseHeightAlign();
      }, 2000);
    }
  }

  // Function check backToResult Url present or not
  backToResultUrlHandle() {
    const backBtnUrl = this.rcycCommonService.getSessionData('backToResultVoyageListPrevUrl');
    if (backBtnUrl) {
      if (this.rcycCommonService.getSessionData('userPreferenceData')) {
        this.getUserPreferenceData = JSON.parse(sessionStorage.getItem('userPreferenceData'));
        this.currentpag = this.getUserPreferenceData.currentPage;
        const page = this.getUserPreferenceData.currentPage;
        this.page = this.getUserPreferenceData.currentPage;
      } else {
        const page = 0;
        this.currentpag = 0;
      }
      const findACruisePaginationArray = this.rcycCommonService.getSessionData('findACruisePaginationArray');

      this.paginationPrevClickstatus = true;
      if (this.paginationPrevClickstatus || this.paginationNextClickStatus || this.paginationBtnClick) {
        this.rcycCommonService.removeSessionData('backToResultVoyageListPrevUrl');
        this.rcycCommonService.removeSessionData('voyageBackButtonPressed');
        this.rcycCommonService.removeSessionData('viewingVoyageId');
      }
      if (findACruisePaginationArray && findACruisePaginationArray.length) {
        this.pages = findACruisePaginationArray;
        if (this.currentpag !== 0) {
          this.page = this.currentpag - 1;
        }
      }
    } else {
      if (this.paginationPrevClickstatus || this.paginationNextClickStatus || this.paginationBtnClick) {
      } else {
        this.rcycCommonService.removeSessionData('pagesArray');
        this.rcycCommonService.removeSessionData('findACruisePaginationArray');
        this.rcycCommonService.removeData('backToResultVoyageListPrevUrl');
        this.rcycCommonService.removeSessionData('voyageBackButtonPressed');
        this.rcycCommonService.removeSessionData('viewingVoyageId');
      }
    }
  }

  viewingVoyageClick(item) {
    this.viewingVoyageId = item.voyageId;
    this.rcycCommonService.setSessionData('viewingVoyageId', this.viewingVoyageId);
  }

  // Function to get drupal pcfi toggle datas
  getPCFIToggleInfoFn() {
    this.sharedServiceService.getPCFIToggleInfo().subscribe(
      (res) => {
        this.PCFIToggleInfo = res;
        if (res && res.field_voyages) {
          this.PCFIVoyageIdArray = res.field_voyages.replace(/\s/g, '').split(",");
        }
      }
    )
  }

  /**
  * Get common info messages from drupal
  */
  getInfoCommonMsg() {
    this.rcycCommonService.currentInfoMessageSource$.pipe(
      switchMap((value) => value ? of(value) : this.sharedServiceService.getInfoCommonMsg()),
      first()
    ).subscribe((response) => {
      this.infoCommonMsg = response;
      this.rcycCommonService.setInfoMessageSource(response);
    });
  }

  /**
 * Set the additional cost info based on currency code
 */
  setAdditionalCostInfo() {
    const currencyFieldMap = {
      USD: 'field_usd_additional_cost_info',
      USM: 'field_usm_additional_cost_info',
      EUR: 'field_eur_additional_cost_info',
      GBP: 'field_gbp_additional_cost_info',
    };
    this.additionalCostInfo = this.infoCommonMsg?.[currencyFieldMap[this.currencyCode]];
  }

  /**
   * Function will return true if given voyageID is listed under preview voyage in drupal
   * @param voyageCode
   * @returns boolean
   */
  isPreviewOnlyVoyage(voyageCode: string): boolean {
    return this.previewVoyagesList.find((previewvoyageCode) => previewvoyageCode == voyageCode) ? true : false;
  }

  public onPageChanged(page: number): void {
    this.goToPagignation(page);
  }

  /**
   * Angular lifecycle hook function triggers when component gets destroyed.
   */
  ngOnDestroy(): void {
    this.getPreviewVoyagesDrupalSubscription.unsubscribe();
  }

}