<div id="rcyc-page-footer" *ngIf="!mainHeaderStatus">

	<footer class="bg-light-blue-2">
    <div class="container py-10 lg:pb-[60px] lg:pt-[50px] text-center leading-[26px]">
      <span *ngIf="countryNo">
        <!-- For reservations, contact The Ritz-Carlton Yacht Collection at {{countryNo}} or your travel professional. -->
        <!-- {{countryNo}} -->
        <h2 class="text-[29px] lg:text-[49px] uppercase mb-2 lg:mb-1 leading-[33px] leading-[56px]">Make your reservation</h2>

        For reservations, contact The Ritz-Carlton Yacht Collection at
        <a class="desktopSection text-primary underline hover:no-underline focus:no-underline" href="/request-call">{{countryNo}}</a>
        <a class="mobileSection text-primary underline hover:no-underline focus:no-underline" href="tel:{{countryNo}}">{{countryNo}}</a>
        or your travel professional.
      </span>
    </div>
  </footer>

  <footer class="py-10 lg:pt-16 bg-primary text-white">
    <div class="container mb-12">
      <div class="footer_bottom lg:pt-8 lg:pb-5 lg:border-y lg:border-solid lg:border-white">
        <!-- <div [innerHTML]="apiResponseC?.body"></div> -->
        <!-- hardcoded footer -->
        <div class="lg:flex lg:flex-row lg:gap-12">
          <ul *ngFor="let column of footerMenu;" class="lg:w-1/4">
            <li *ngFor="let item of column;let i=index;"
                class="footer-heading relative text-white mb-4 lg:mb-6 border-b pointer border-solid border-white lg:border-0 cursor-pointer">
              <button type="button" class="flex w-full justify-between px-0 py-[11px] lg:pt-[9px] lg:pb-0 hover:bg-transparent hover:opacity-75 focus:opacity-75
lg:cursor-default lg:hover:opacity-100 lg:focus:opacity-100 lg:text-[25px] lg:font-caslon text-left m-0 lg:mb-[5px] tracking-wider normal-case lg:uppercase"
                      (click)="openMobileSubmenu(item.id)" tabindex="0">
                {{item.title}}

                <img src="assets/img/arrow-down--blue.svg"
                     class="lg:display-none"
                     [class]="item.id===openedSubmenu ? 'rotate-180' : ''"
                     [alt]="item.id===openedSubmenu ? 'close submenu' : 'open submenu'">
              </button>

              <ul class="absolute lg:static top-full w-full max-h-[230px] lg:max-h-none
                z-10 overflow-scroll lg:overflow-visible px-2 pt-4 lg:p-0 bg-white lg:bg-transparent"
                  [class]="item.id===openedSubmenu ? 'block' : 'display-none lg:block'">
                <li class="text-[20px] leading-[23px] font-caslon leading-none mb-[14px] lg:display-none uppercase">{{item.title}}</li>
                <li *ngFor="let link of item.links" class="mb-[9px]">
                  <div *ngIf="link.route;then route else href"></div>
                  <ng-template #route>
                    <a (click)="navigateTo(link.route)" class="text-primary lg:text-white">{{link.title}}</a>
                  </ng-template>
                  <ng-template #href>
                    <a [href]="link.url" class="text-primary lg:text-white">{{link.title}}</a>
                  </ng-template>
                </li>
              </ul>
            </li>
          </ul>
        </div>
         <!-- hardcoded footer END -->
      </div>
    </div>

    <div class="container flex flex-col lg:flex-row justify-between lg:gap-6 xl:gap-[74px] lg:mb-9">
      <a routerLink="/" class="mb-6 mx-auto lg:m-0">
        <img src="assets/components/images/logo-white.svg" loading="lazy" alt="The Ritz-Carlton Yacht Collection" class="w-[190px] lg:w-[252px]" />
      </a>

      <div class="flex flex-col gap-4 lg:flex-row lg:gap-6 xl:gap-11 lg:flex-grow lg:justify-center">
        <a (click)="navigateTo('/request-brochure')" class="button-secondary button-secondary--light lg:w-full lg:max-w-[200px]">View Brochure</a>
        <a (click)="navigateTo('/request-call')" class="button-secondary button-secondary--light lg:w-full lg:max-w-[200px]">Request  a call</a>
        <a (click)="navigateTo('/request-quote')" class="button-secondary button-secondary--light lg:w-full lg:max-w-[200px]">Request a quote</a>
      </div>

      <ul class="flex gap-9 lg:gap-6 xl:gap-6 mt-6 mb-9 items-center justify-center lg:m-0">
        <li class="flex items-center">
          <a target="_blank" id="ft_facebook" href="https://www.facebook.com/ritzcarltonyachtcollection/" title="The Ritz-Carlton Yacht Collection Facebook Page">
            <img src="assets/img/fb.svg" loading="lazy" alt="The Ritz-Carlton Yacht Collection Facebook Page" />
          </a>
        </li>
        <li class="flex items-center">
          <a target="_blank" id="ft_instagram" href="https://instagram.com/ritzcarltonyachtcollection" title="The Ritz-Carlton Yacht collection Instagram Page">
            <img src="assets/img/instagram.svg" loading="lazy" alt="The Ritz-Carlton Yacht collection Instagram Page" />
          </a>
        </li>
        <li class="flex items-center">
          <a target="_blank" id="ft_twitter" href="https://twitter.com/ritzcarltonyc" title="The Ritz-Carlton Yacht Collection Twitter Page" >
            <img src="assets/img/x.svg" loading="lazy" alt="The Ritz-Carlton Yacht Collection Twitter Page" />
          </a>
        </li>
        <li class="flex items-center">
          <a target="_blank" id="ft_youtube" href="https://www.youtube.com/channel/UCSyujJlh7ayn9X0AAPnxQ2g" title="The Ritz-Carlton Yacht Collection Youtube Channel" alt="The Ritz-Carlton Yacht Collection Youtube Channel">
            <img src="assets/img/youtube.svg" loading="lazy" alt="The Ritz-Carlton Yacht Collection Youtube Channel" />
          </a>
        </li>
      </ul>
    </div>

    <div class="container text-white text-center flex flex-col gap-3 mb-3 lg:flex-row lg:flex-wrap lg:justify-between">
      <div class="lg:flex lg:gap-1">
        <div>©{{currentYear}} The Ritz-Carlton Yacht Collection.</div>
        <div>All Rights Reserved.</div>
      </div>

      <div class="flex justify-center gap-6">
        <a href="/" class="text-white underline hover:no-underline focus:no-underline">Your privacy rights</a>

        <div class="ifpLogoCookieConsent">
          <span *ngIf="gdpr_cookie">
            <a *ngIf="gdpr_cookie=='ACCEPT'" (click)="accept_cookie_Consent('DECLINE')" title="Withdraw cookie use consent">Withdraw cookie use consent</a>
            <a *ngIf="gdpr_cookie=='DECLINE'" (click)="accept_cookie_Consent('ACCEPT')" title="Consent to cookie use">Consent to cookie use</a>
          </span>
          <span>
            <a href="javascript:void(0)" onclick="Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')" wq>Cookie Preferences</a>
          </span>
          <span *ngIf="userLocationDetailsStatus && userLocationDetails?.localizationRegionType == 'malta'">
            <img [src]="apiResponseC?.field_insolvency_logo" [alt]="apiResponseC?.field_insolvency_logo_alt_conten"
              [title]="apiResponseC?.field_insolvency_logo_title" />
          </span>
        </div>
      </div>
    </div>

    <div class="footer-legal container text-[13px] lg:m-auto">
      <div [innerHTML]="apiResponseC?.field_disclaimer_text"></div>
    </div>
  </footer>

  <div *ngIf="ENVIRONMENT?.clickToChatVisibility" class="rcyc-clickToChat">
    <div *ngIf="call_centre_region == 'malta'" id="rcyc-clickToChatMalta"></div>
    <div *ngIf="call_centre_region != 'malta'" id="rcyc-clickToChatAll"></div>
  </div>
</div>