import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  userDetail: any;

  travelAgentMatchStatus = false;
  guestMatchStatus = false;
  travelAgentMatch: any = [];
  guestMatch: any = [];

  GLoginUrlMatch: any = [];
  GLoginUrlMatchStatus = false;
  TALoginUrlMatch: any = [];
  TALoginUrlMatchStatus = false;

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (localStorage.getItem('loggedUserDetails') !== null) {
      if (localStorage.getItem('urlToRoutAfterLogin') !== null) {
        let urlToRout = localStorage.getItem('urlToRoutAfterLogin');
        localStorage.removeItem('urlToRoutAfterLogin');
        this.router.navigate([urlToRout]);
      }
    } else {
      localStorage.setItem('urlToRoutAfterLogin', window.location.pathname);
    }

    this.travelAgentMatch = state.url.match('/travel-agent/');
    if (this.travelAgentMatch && this.travelAgentMatch.length) {
      this.travelAgentMatchStatus = true;
    }

    this.guestMatch = state.url.match('/guest/');
    if (this.guestMatch && this.guestMatch.length) {
      this.guestMatchStatus = true;
    }

    this.GLoginUrlMatch = state.url.match('/login/');
    if (this.GLoginUrlMatch && this.GLoginUrlMatch.length) {
      this.GLoginUrlMatchStatus = true;
    }

    this.TALoginUrlMatch = state.url.match('/travel-agent/login/');
    if (this.TALoginUrlMatch && this.TALoginUrlMatch.length) {
      this.TALoginUrlMatchStatus = true;
    }

    if (localStorage.getItem('loggedUserDetails')) {

      this.userDetail = JSON.parse(localStorage.getItem('loggedUserDetails'));
      if (this.userDetail) {

        switch (this.userDetail.type) {
          case 'TA':

            const guestMatch = state.url.match('/guest/');
            if (guestMatch && guestMatch.length) {

              this.router.navigate(['/travel-agent/dashboard']);
            } else {

              return true;
            }

            break;
          default:

            const travelAgentMatch = state.url.match('/travel-agent/');
            if (travelAgentMatch && travelAgentMatch.length) {

              this.router.navigate(['/guest/dashboard']);
            } else {

              return true;
            }
            break;

        }
      } else {

        if ((!this.GLoginUrlMatchStatus) || (!this.TALoginUrlMatchStatus)) {
          if (this.travelAgentMatchStatus) {

            this.router.navigate(['/travel-agent/login']);

          } else {

            this.router.navigate(['/guest/login']);
          }
        } else {
          this.setWireTransferUrlCall(state);
          this.router.navigate(['/login']);
        }

      }
    } else {
      let redirectUrl = state.url;
      if(redirectUrl.match('/downloads/edoc/')) {
        localStorage.setItem('redirectUrl', state.url);
      }
      if ((!this.GLoginUrlMatchStatus) || (!this.TALoginUrlMatchStatus)) {
        if (this.travelAgentMatchStatus) {

          this.router.navigate(['/travel-agent/login']);
        } else {
          this.setWireTransferUrlCall(state);
          this.router.navigate(['/login']);
        }
      } else {
        this.setWireTransferUrlCall(state);
        this.router.navigate(['/login']);
      }
    }


  }
  setWireTransferUrlCall(state) {
    if (state.url.includes('delay/wire-form')) {
      const urlData = {
        'status': true,
        'url': state.url
      };
      const stringifiedUrlData = JSON.stringify(urlData);
      localStorage.setItem('redirectToWireFrame', stringifiedUrlData);
    }
  }

}
