import { Component, Input, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { NguCarousel } from "@ngu/carousel";

@Component({
  selector: 'app-rcyc-image-slider',
  templateUrl: './rcyc-image-slider.component.html',
  styleUrls: ['./rcyc-image-slider.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class RcycImageSliderComponent implements OnInit {
  @Input() data: any;
  @Input() imageScrollPosition: string;
  @Input() variant: any = '5/4';
  @Input() parent: string = 'none';
  // @ts-ignore
  @ViewChild('imageSlider') imageSlider: NguCarousel;

  public carouselSettings: any;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.positionImageScroll();
  }

  // For the map/photo view on find a voyage
  ngOnChanges() {
    this.positionImageScroll();
  }

  ngOnInit(): void {
    this.carouselSettings = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: 0,
      point: {
        visible: false
      },
      load: 3,
      touch: this.parent !== 'slider',
      loop: true,
    };
    this.positionImageScroll();
  }

  moveTo(slide) {
    this.imageSlider?.moveTo(slide);
  }

  positionImageScroll() {
    if (this.imageScrollPosition === 'photo') {
      this.moveTo(1);
    } else {
      this.moveTo(0)
    }
  }
}
