<swal #shoreExcursionsSwal (beforeOpen)="beforeOpen($event)" (open)="onOpen($event)" [showConfirmButton]="false"
  [showCancelButton]="false" [showCloseButton]="true" customClass="viewBrocSwtOuter" [allowOutsideClick]="false"
  [animation]="false" (close)="onClose($event)" [reverseButtons]="true">
  <ng-container *swalPartial>
    <div class="p-6 lg:p-12">
      <!-- Header -->
      <section class="section-2 lg:sticky lg:top-[-10px] z-10 overflow-hidden lg:pb-[15px] bg-white">
        <div class="absolute left-0 bottom-[15px] w-full h-full shadow-header display-none lg:block"></div>
        <span class="text-[14px] uppercase">Shore Experiences</span>
        <div class="lg:flex justify-between mb-5 mt-6">
          <h1 class="font-[400] text-[29px] lg:text-[49px] !font-caslon lg:w-1/2" [innerHTML]="excursionDetail?.field_tile_title">
            </h1>
          <div class="lg:w-1/2 text-[16px]" [innerHTML]="excursionDetail?.field_com_description"></div>
        </div>
        <!-- Excursions -->
        <div *ngIf="portListReady" class="w-full left-0 top-full background-footer backdrop-blur-effect z-10 overflow-auto relative lg:px-6 flex justify-between">
          <button (click)="scrollTabs('left')" (keyup.enter)="scrollTabs('left')"
            class="unset-button text-primary hover:text-primary text-[13px] flex items-center left-arrow hidden">
            <img src="assets/img/arrow_left_small.svg" alt="">
          </button>
          <div class="tabs-container overflow-hidden flex-1 overflow-x-auto whitespace-nowrap">
            <ul class="flex lg:container lg:justify-between gap-4 py-[10px] lg:py-4 flex flex-nowrap">
              <div *ngFor="let eachPort of portList">
                <li class="m-0 whitespace-nowrap text-primary uppercase border-solid border-b border-primary">
                  <button [class.activeTab]="activeTab=== eachPort.portCode" (click)="excursionsClick(eachPort)"
                    class="text-[16px] unset-button hover:text-primary focus:text-primary whitespace-nowrap text-primary uppercase justify-center flex w-full hover:no-underline border-solid border-b-[3px] border-transparent hover:border-primary focus:border-primary">
                    {{eachPort.portName}}
                  </button>
                </li>
              </div>
            </ul>
          </div>
          <button (click)="scrollTabs('right')" (keyup.enter)="scrollTabs('right')"
            class="unset-button text-primary hover:text-primary text-[13px] flex items-center right-arrow">
            <img src="assets/img/arrow_right_small.svg" alt="">
          </button>
        </div>
      </section>
      <div class="flex flex-col gap-8 lg:px-6 lg:mx-6 mt-8 lg:overflow-auto">
        <div *ngFor="let item of excursionBytype; let i=index;">
          <div class="flex flex-col lg:flex-row">
            <div class="w-full bg-light-blue-2 h-auto lg:w-[344px] aspect-[5/4]">
              <img *ngIf="item.field_thumbnail" [src]="item.field_thumbnail" class="fit-image" alt="">
            </div>
            <div class="bg-light-blue-2 w-full lg:flex lg:p-[32px] p-4">
              <div class="lg:w-9/12 lg:mr-[32px] mb-4 lg:mb-0">
                <div class="text-sm uppercase mb-[16px] tracking-[1px]">
                  {{item.portName}}
                </div>
                <h2 class="text-[23px] leading-[26px] lg:leading-normal font-adobe-caslon-pro tracking-[1px]">
                  <span [innerHTML]="item.tourName"></span>
                </h2>
                <div class="text-[16px]" [innerHTML]="item.summaryDesc"></div>
              </div>
              <div class="lg:w-3/12">
                <div class="min-w-48 flex flex-col gap-2 justify-start uppercase">
                  <span class="text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="item.tourCatCd" [innerHTML]="item.tourCatCd"> </span>
                  <span class="splitLasth4 text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="item?.activityLvl == 'Easy'">
                    Mild Activity
                  </span>
                  <span class="splitLasth4 text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="item?.activityLvl == 'Medium'">
                    Moderate Activity
                  </span>
                  <span class="splitLasth4 text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="item?.activityLvl == 'Hard'">
                    Strenuous Activity
                  </span>
                  <span class="text-[16px] font-normal tracking-[1.2px] leading-[26.4px] font-['proxima-nova']" *ngIf="item.durationHrs" [innerHTML]="item.durationHrs + ' hours'"> </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</swal>