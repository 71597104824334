import { Component, OnInit, ElementRef, HostListener} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { RcycCommonService } from "../../rcyc-services/rcyc-common/rcyc-common.service";
import { HttpClient } from "@angular/common/http";
import { RcycRequestBrochureService } from "../rcyc-request-brochure/rcyc-request-brochure.service";
import { Router, ActivatedRoute } from "@angular/router";
import { RcycFormTokenService } from "../../rcyc-services/rcyc-form-token/rcyc-form-token.service";
import { RcycHomeService } from "../rcyc-home/rcyc-home.service";
import { ValidatePhoneNumber, PhoneDataFormat, formatPhoneList, deepPhoneConfiguration } from "../../rcyc-share-components/rcyc-phonenumber-validator/rcyc-phonenumber-validator";
import { AppConfig } from "../../rcyc-config/config";
import { CookieService } from "ngx-cookie-service";
import { getMaskedCode } from "../../../utils/phone-number-validation";
import { RcycHiddenSalesforceFormService } from "../../rcyc-services/rcyc-salesforce/rcyc-hidden-salesforce-form.service";
import { SalesForceFormData } from "../../rcyc-services/rcyc-salesforce/salesforce-form-data.interface";
declare let ga: Function;

@Component({
  selector: "app-rcyc-request-brochure-asia",
  templateUrl: "./rcyc-request-brochure-asia.component.html",
  styleUrls: ["./rcyc-request-brochure-asia.component.css"],
})
export class RcycRequestBrochureAsiaComponent implements OnInit {
  apiUrlMetatag: string;
  userArea: any;
  userAreaDetails: any;
  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  lead_gen_form: any;
  phoneDataArray: PhoneDataFormat[];
  formttedPhoneList: any = {};
  userCountryDetails: PhoneDataFormat;
  apiresponseB: any;
  apiresponseC: any;
  apiresponseD: any;
  apiresponseE: any;
  flagCodeClickStatus: boolean = false;
  formSubmitFailStatus: boolean = false;
  countrySearch: string;
  eBrochureFormToken: any;
  apiUrl_Ebrochure_Submit: any;
  eBrochureFormResponse: any;
  phNumReqStatusDynamic: boolean = false;
  apiUrl_F: string;
  permanentGeoLocation: any;
  zipcodeReqStatusDynamic = false;
  countryListArray: any;
  zipcodeMaxLength: number = 10;
  tokenMessage: any;
  eBrochureFormStatus = false;

  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlPath: any;
  isRequestEBrochureStatus = false;
  specialCountryInputClickStatus = false;
  specialCountryInputCloseBtnStatus = false;
  isSpecialCountrySelect = false;
  selectCountryCode: string;
  mask = [];
  showPhoneopt = false;
  showTermUS = false;
  tokenErrorMsg: any = this.rcycFormTokenService.tokenErrorMsg;

  specialOfferFormErrorMsg: string;
  showPhoneOptRequiredText = false;
  drupalErrorMessages: any;
  sessionExpiredMessages: any;
  geoApiResponse: any = {};
  yachtIds: any;
  voyageListArray: any = [];
  selectedVoyageId: any;
  distinctRegions: string[] = [];
  filteredVoyageDates: any;
  selectedRegion: string = "Asia";
  selectedDates: string[] = [];
  distinctMonthsAndYears: any = [];
  isDropdownOpen = false;
  keepDropdownOpen: boolean = false;
  preferredDateCount: any = null;
  preferredDateLimitExceed: boolean = false;
  submitLoading: boolean = false;
  salesForceImplementation: boolean = AppConfig.salesForceImplementation;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private rcycCommonService: RcycCommonService,
    private http: HttpClient,
    private requestBrochureService: RcycRequestBrochureService,
    private router: Router,
    private rcycFormTokenService: RcycFormTokenService,
    private rcycHomeService: RcycHomeService,
    private activatedRoute: ActivatedRoute,
    private cookies: CookieService,
    private elementRef: ElementRef,
    private rcycHiddenSalesforceFormService: RcycHiddenSalesforceFormService
  ) {
    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlPath = this.currentUrlPathAsArray[0].path;
    if (this.currentUrlPath == "request-asia-ebrochure") {
      this.isRequestEBrochureStatus = true;
    }
    this.reqBrochureConstructorFn();
    this.getUserAreaCode();
  }

  reqBrochureConstructorFn() {
    this.ebrochureFormToken();

    if (this.isRequestEBrochureStatus) {
      // Changing subheader A value
      this.rcycCommonService.changeSubHeaderStatus(true);

      // Call get Metatag function
      this.apiUrlMetatag = "/api/v1/meta/requestbrochureasia?_format=json";
      this.rcycCommonService.getMetaTags(this.apiUrlMetatag);
    }
  }
  ngOnInit() {
    this.getYachtId();
    this.brochureContent();
    this.lead_gen_form = this.formBuilder.group({
      firstname: [
        "",
        [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ-.'_ ]*")],
      ],
      lastname: [
        "",
        [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ-.'_ ]*")],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$"
          ),
        ],
      ],
      country: ["", Validators.required],
      countryCode: ["US", Validators.required],
      zipcode: [""],
      phone: [""],
      selectedRegion: [""],
      preferredVoyageDate: [""],
      isAgreeToReceiveMail: [false],
      isAgreeToContact: [false],
      dialCode: [""],
    });

    this.http.get("./assets/select_country.json").subscribe((data) => {
      this.countryListArray = data;
    });
    this.http
      .get("./assets/rescoNumberFormatted.json")
      .subscribe((phoneDataArray: PhoneDataFormat[]) => {
        this.phoneDataArray = phoneDataArray;
        this.formttedPhoneList = formatPhoneList(phoneDataArray);
        this.deepPhoneConfigurations();
      });
    this.formValueChangeCapture();
    this.getDrupalErrorMessages();

    // To clear the selected dates initially and on clear
    this.selectedDates = [];
    this.preferredDateLimitExceed = false;
  }
  deepPhoneConfigurations() {
    let res: {
      formttedPhoneList: any;
      userCountryDetails: PhoneDataFormat;
      phoneDataArray: PhoneDataFormat[];
    } = deepPhoneConfiguration(this.phoneDataArray, this.formttedPhoneList);
    this.formttedPhoneList = res.formttedPhoneList;
    this.userCountryDetails = res.userCountryDetails;
    this.phoneDataArray = res.phoneDataArray;
    this.permanentGeoLocation = Object.assign({}, this.userCountryDetails);
    this.lead_gen_form
      .get("countryCode")
      .setValue(this.userCountryDetails.code);
    this.lead_gen_form
      .get("dialCode")
      .setValue(this.userCountryDetails.dial_code);
    this.mask = getMaskedCode(res.userCountryDetails);
  }

  /**
   * Function for value  change of form
   */
  formValueChangeCapture() {
    this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
    this.lead_gen_form.get("phone").updateValueAndValidity();
    if (!this.isRequestEBrochureStatus) {
      this.lead_gen_form.valueChanges.subscribe((val) => {
        this.specialOfferFormErrorMsg = "";
        this.formSubmitFailStatus = false;
      });
      this.lead_gen_form.get("country").valueChanges.subscribe((val) => {
        // Editing country field
        this.selectCountryCode = "";
        this.specialCountryInputClickStatus = true;
        this.specialCountryInputCloseBtnStatus = true;
        this.isSpecialCountrySelect = false;
      });
    } else {
      this.lead_gen_form.valueChanges.subscribe((val) => {
        this.formSubmitFailStatus = false;
      });
      this.lead_gen_form.get("country").valueChanges.subscribe((val) => {
        this.countryValueChangeFn(val);
      });
    }
  }

  /**
   * Format  phone looping for internal use
   * @param e
   */
  onClickedOutside(e: Event) {
    this.flagCodeClickStatus = false;
  }

  /**
   * Function for select country click
   * @param item selected country
   */
  selectCountryClick(item) {
    this.lead_gen_form.get("phone").setValue("");
    this.lead_gen_form.get("countryCode").setValue(item.code);
    this.lead_gen_form.get("dialCode").setValue(item.dial_code);
    this.userCountryDetails.dial_code = item.dial_code;
    this.userCountryDetails.code = item.code;
    this.userCountryDetails.flagUrl = item.flagUrl;
    this.userCountryDetails.ph_length = Number(item.ph_length);
    this.userCountryDetails.defaultFormat = item.defaultFormat;
    this.userCountryDetails.defaultPhoneNumber = item.defaultPhoneNumber;
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
    this.mask = getMaskedCode(item);
  }

  /**
   * Function to get Area code
   */
  getUserAreaCode() {
    // Subscriber to get User Area
    this.rcycCommonService.userAreaCode.subscribe((userAreaDetails) => {
      if (userAreaDetails.length) {
        this.userAreaDetails = userAreaDetails[0];
        this.userArea = this.userAreaDetails.field_area_code;
      }
    });
  }

  /**
   * Get the ilma yacht code from session storage or drupal to get elastic ilma voyages
   */
  getYachtId() {
    if (sessionStorage.getItem("yachtcode")) {
      const yachtCodeFromSession: any = JSON.parse(
        sessionStorage.getItem("yachtcode")
      );
      if (yachtCodeFromSession) {
        this.yachtIds = Object.values(yachtCodeFromSession).map(Number);
      }
      this.getVoyageData();
    } else {
      this.getYachtIdFromDrupal();
    }
  }

  /**
   * To get the yacht code from drupal
   */
  getYachtIdFromDrupal() {
    const apiUrl = "/api/v1/yachtcode?_format=json";
    this.requestBrochureService.getDrupal(apiUrl).subscribe(
      (res: any = []) => {
        if (res.length) {
          let yachtcode = {
            evrima: res[0].field_evrima_code,
            ilma: res[0].field_ilma_code,
            luminara: res[0].field_luminara_code,
          };
          sessionStorage.setItem("yachtcode", JSON.stringify(yachtcode));
          // Extract all yacht IDs and store them in this.yachtIds array
          this.yachtIds = Object.values(yachtcode).map(Number);
          this.getVoyageData();
        }
      },
      (err) => {}
    );
  }

  /**
   * Get departure date and region data from cache
   */
  getVoyageData() {
    let resetRegionAndDepDatesData: boolean = false;
    let cruiseRegionsAndDepartureDatesData = JSON.parse(
      sessionStorage.getItem("CruiseRegionsAndDepartureDatesData")
    );
    if (cruiseRegionsAndDepartureDatesData) {
      if (
        this.rcycCommonService.getDateDifference(
          cruiseRegionsAndDepartureDatesData.expireDate
        )
      ) {
        this.voyageListArray = cruiseRegionsAndDepartureDatesData.data;
        // Call the function to filter the voyage dates by region Asia which is hardcoded in next line since this is asia specific Brochure
        this.filterVoyageDatesByRegion(this.selectedRegion);
        resetRegionAndDepDatesData = false;
      } else {
        resetRegionAndDepDatesData = true;
      }
    } else {
      resetRegionAndDepDatesData = true;
    }

    if (resetRegionAndDepDatesData) {
      // Reset cache and fetch data from API
      sessionStorage.removeItem("CruiseRegionsAndDepartureDatesData");
      this.getVoyageDataFromAPI();
    }
  }

  /**
   *  Get Iventory from Elatic API
   */
  getVoyageDataFromAPI() {
    let geoCountryCode;
    if (!this.geoApiResponse.country_code) {
      geoCountryCode = "US";
    } else {
      geoCountryCode = this.geoApiResponse.country_code;
    }
    let apiUrl_C = "/api/voyageListWithBasicData";
    const param1 = {
      countryCode: geoCountryCode,
      yachtIds: this.yachtIds,
    };

    this.requestBrochureService
      .postElastic(param1, apiUrl_C)
      .subscribe((response: any) => {
        if (response) {
          /**
           * Set region and departure date data cache
           */
          let todayTime: any = new Date();
          sessionStorage.setItem(
            "CruiseRegionsAndDepartureDatesData",
            JSON.stringify({
              data: response.data.voyages,
              expireDate: todayTime,
            })
          );
          this.getVoyageData();
        }
      });
  }

  /**
   * Funtion to filter voyage by selected region
   * @param selectedRegion Asia Region hardcoded dor asia specific brochure
   */
  filterVoyageDatesByRegion(selectedRegion: string) {
    let datesToFilter = this.voyageListArray;
    if (selectedRegion) {
      this.selectedDates = [];
      datesToFilter = datesToFilter.filter(
        (voyage) => voyage.voyageRegionExpansion === selectedRegion
      );
    }
    const uniqueMonthsAndYears: any = [
      ...new Set(
        datesToFilter.map((voyage) => {
          const date = new Date(voyage.voyageStartDate);
          return `${date.getFullYear()}-${date.getMonth()}`;
        })
      ),
    ];

    this.distinctMonthsAndYears = uniqueMonthsAndYears
      .sort((a, b) => {
        const [yearA, monthA] = a.split("-");
        const [yearB, monthB] = b.split("-");
        return Number(yearA) - Number(yearB) || Number(monthA) - Number(monthB);
      })
      .map((monthAndYear) => {
        const [year, month] = monthAndYear.split("-");
        return `${this.getMonthName(Number(month))} ${year}`;
      });
  }

  /**
   * Get the name of the month for filterVoyageDatesByRegion
   * @param monthIndex Number of month in 12
   * @returns Name of month
   */
  getMonthName(monthIndex: number): string {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  }

  /**
   * To toggle the dropdown to select preferred voyage dates
   */
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  /**
   * Get the details of preferred voyage dates selected
   * @param event Click event
   * @param monthAndYear Voyage dates which are selected
   */
  toggleDateCheckbox(event: any, monthAndYear: string) {
    if (event.target.checked) {
      this.selectedDates.push(monthAndYear);
    } else {
      const index = this.selectedDates.indexOf(monthAndYear);
      if (index !== -1) {
        this.selectedDates.splice(index, 1);
      }
    }
    this.preferredDateCount = this.selectedDates.length;
    this.preferredDateLimitExceed = this.preferredDateCount > 5 ? true : false;
  }

  /**
   * To display the first element of the voyage dates selected
   * @param selectedDates Voyage dates preferred
   * @returns the first element of voyage dates array
   */
  getFirstSelectedDate(selectedDates: string[]): string {
    if (selectedDates.length > 0) {
      return selectedDates[0];
    }
    return "Select Preferred Voyage Date";
  }

  /**
   * To clear the voyages selected
   */
  clearDropdownOptions() {
    this.selectedDates = []; // Clear selectedVoyagesDetails array
    this.keepDropdownOpen = true;
    this.preferredDateLimitExceed = false;
  }

  /**
   * To set the focus in and out of preferred voyageDates select box
   * @param event Click event
   */
  @HostListener("document:click", ["$event"])
  onClick(event: any) {
    const targetElement = event.target as HTMLElement;
    const dropdownContainer = this.elementRef.nativeElement.querySelector(
      ".multiselect-container"
    );

    const cancelClasses = ["clear-button", "optCB", "opt"];
    this.keepDropdownOpen = cancelClasses.some((className) =>
      targetElement.classList.contains(className)
    );
    if (this.keepDropdownOpen) {
      this.isDropdownOpen = true;
    }
    if (!dropdownContainer.contains(targetElement)) {
      if (!this.keepDropdownOpen) {
        this.isDropdownOpen = false;
      }
    }
  }

  /**
   * Function to call all apis in Request Brochure
   */
  brochureContent() {
    if (this.isRequestEBrochureStatus) {
      /****************** Section A********************/
      const apiUrl_A = "/api/v1/requestbrochureasia/header/a?_format=json";
      this.requestBrochureService.requestBrochureDrupalGet(apiUrl_A).subscribe(
        (response: any = []) => {
          if (response.length) {
            // Changing subheader A value
            this.rcycCommonService.changeSubHeaderDataArray_A(response[0]);
          }
        },
        (err) => {
          console.log("err  apiUrl_A");
          console.log(err);
        }
      );
    }
    /****************** Section B********************/
    let apiUrl_B = "/api/v1/requestbrochureasia/content/b?_format=json";
    this.requestBrochureService.requestBrochureDrupalGet(apiUrl_B).subscribe(
      (response: any) => {
        if (response && response.length) {
          this.apiresponseB = response[0];
        }
      },
      (err) => {
        console.log("err  apiUrl_B");
        console.log(err);
      }
    );

    /****************** Section C********************/
    let apiUrl_C = "/api/v1/requestbrochureasia/header/c?_format=json";
    this.requestBrochureService.requestBrochureDrupalGet(apiUrl_C).subscribe(
      (response: any) => {
        if (response && response.length) {
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {
            this.apiresponseC = res[0];
          }
        }
      },
      (err) => {
        console.log("err  apiUrl_C");
        console.log(err);
      }
    );

    /****************** Section D********************/
    let apiUrl_D = "/api/v1/requestbrochureasia/content/d?_format=json";
    this.requestBrochureService.requestBrochureDrupalGet(apiUrl_D).subscribe(
      (response: any) => {
        if (response && response.length) {
          const resArray = [];
          for (const index in response) {
            const factor = response[index];
            if (factor.field_image) {
              factor.field_image = this.IMG_BASE_URL + factor.field_image;

              const imgTitle = this.rcycCommonService.imageNameAltSplit(
                factor.field_image
              );

              factor.altTextBrochureImg = factor.title + " " + imgTitle;
              factor.titleTextBrochureImg = factor.title + " " + imgTitle;
            } else {
              factor.altTextBrochureImg = factor.title;
              factor.titleTextBrochureImg = factor.title;
            }
            resArray.push(factor);
            if (parseInt(index, 10) + 1 === response.length) {
              this.apiresponseD = response;
            }
          }
        }
      },
      (err) => {
        console.log("err  apiUrl_D");
        console.log(err);
      }
    );

    /****************** Section E********************/
    let apiUrl_E = "/api/v1/requestbrochureasia/terms?_format=json";
    this.requestBrochureService.requestBrochureDrupalGet(apiUrl_E).subscribe(
      (response: any) => {
        if (response && response.length) {
          this.apiresponseE = response[0];
          let message = this.apiresponseE.field_term2
            .replace("<p>", "")
            .replace("</p>", "");
          let newMessage = `<p> ${message}<span>*</span></p>`;
          this.apiresponseE["field_term2_required"] = newMessage;
        }
      },
      (err) => {
        console.log("err  apiUrl_E");
        console.log(err);
      }
    );
  }

  /**
   * Function to Submit form in Request Brochure
   */
  leadGenSubmitApiCall() {
    this.submitLoading = true;
    this.apiUrl_Ebrochure_Submit = "/secureleadapi/e-brochure";
    let dial_code: string = this.userCountryDetails.dial_code;
    this.eBrochureFormStatus = false;

    let phoneNumber;
    if (!this.lead_gen_form.value.phone.replace(/ /g, "")) {
      phoneNumber = "";
    } else {
      phoneNumber =
        dial_code + this.lead_gen_form.value.phone.replace(/ /g, "");
    }
    const requestData = {
      area: this.userArea ? this.userArea : "",
      email: this.lead_gen_form.value.email.trim(),
      firstName: this.lead_gen_form.value.firstname.trim(),
      lastName: this.lead_gen_form.value.lastname.trim(),
      phoneNumber: phoneNumber,
      zipCode: this.lead_gen_form.value.zipcode.trim(),
      emailOpt: this.lead_gen_form.value.isAgreeToReceiveMail,
      phoneOpt: this.lead_gen_form.value.isAgreeToContact,
      utmSource: this.cookies.get("utm_source"),
      utmMedium: this.cookies.get("utm_medium"),
      utmCampaign: this.cookies.get("utm_campaign"),
      internalSource: this.cookies.get("int_source"),
      internalCampaign: this.cookies.get("int_campaign"),
      folId: this.cookies.get("fol_num"),
    };
    if (!this.isRequestEBrochureStatus) {
      requestData["countryOrRegion"] = this.selectCountryCode;
    } else {
      requestData["countryOrRegion"] = this.lead_gen_form.value.country
        ? this.lead_gen_form.value.country
        : "";
      requestData["voyageRegion"] = this.selectedRegion;
      requestData["voyageDate"] = this.selectedDates ? this.selectedDates : [];
    }
    if (requestData["countryOrRegion"] == "US") {
      if (this.lead_gen_form.value.phone) {
        requestData["phoneOpt"] = true;
      } else {
        requestData["phoneOpt"] = false;
      }
    }

    /****************** Section Ebrochure Submission********************/
    this.requestBrochureService
      .brochureFormData(
        this.apiUrl_Ebrochure_Submit,
        requestData,
        this.eBrochureFormToken
      )
      .subscribe(
        (response) => {
          if (!this.salesForceImplementation) {
            // Change The value of Flag (salesForceImplementation) in env to execute handling of post LeadGenForm submission to resco - and Discontinue W2L salesforce submit

            this.submitLoading = false;
            this.eBrochureFormResponse = response;
            let leadId =
              this.eBrochureFormResponse.data &&
              this.eBrochureFormResponse.data.leadId
                ? this.eBrochureFormResponse.data.leadId
                : "";
            if (
              this.eBrochureFormResponse &&
              this.eBrochureFormResponse.code === 200
            ) {
              this.formSubmitFailStatus = false;
              this.router.navigate(["/asia-pacific-brochure"], {
                queryParams: { success: "rab", lead_id: leadId },
              });
              ga("create", "UA-114428670-1", "auto");
              ga("require", "ecommerce", "ecommerce.js");
              ga("ecommerce:addItem", {
                id: leadId,
                name: "rab",
              });
              ga("ecommerce:send");
              ga("send", "pageview");
            } else if (
              this.eBrochureFormResponse &&
              (this.eBrochureFormResponse.code === 500 ||
                this.eBrochureFormResponse.code === 403 ||
                this.eBrochureFormResponse.code === 401 ||
                this.eBrochureFormResponse.code === 400)
            ) {
              if (this.eBrochureFormResponse.status == 407) {
                const resp = this.eBrochureFormResponse;
                this.eBrochureFormStatus = true;
                this.tokenMessage = this.rcycFormTokenService.tokenErrorMsg;
                this.sessionExpiredMessages =
                  this.drupalErrorMessages.field_form_session_expired;
              }
              this.reqBrochureConstructorFn();
              this.formSubmitFailStatus = true;
              document.querySelector(".page-title").scrollIntoView();
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );

    if (this.salesForceImplementation) {
      // Salesforce Web to Lead - W2L START

      const salesForceRequestData: SalesForceFormData = {
        lead_source: "Website",
        Lead_Type__c: "Request eBrochure",
        first_name: this.lead_gen_form.value.firstname.trim(),
        last_name: this.lead_gen_form.value.lastname.trim(),
        phone: phoneNumber,
        email: this.lead_gen_form.value.email.trim(),
        zip: this.lead_gen_form.value.zipcode.trim(),
        area: this.userArea ? this.userArea : "",
        Phone_Opt_In__c:
          this.lead_gen_form.value.isAgreeToContact == true
            ? "Yes"
            : "No Response",
        Email_Opt_In__c:
          this.lead_gen_form.value.isAgreeToReceiveMail == true
            ? "yes"
            : "No Response",
        UTM_Source__c: this.cookies.get("utm_source"),
        UTM_Medium__c: this.cookies.get("utm_medium"),
        UTM_Campaign__c: this.cookies.get("utm_campaign"),
        INT_Source__c: this.cookies.get("int_source"),
        INT_Campaign__c: this.cookies.get("int_campaign"),
        GCLID__c: this.cookies.get('gclid'),
        FBCLID__c: this.cookies.get('fbcild'),
      };
      if (!this.isRequestEBrochureStatus) {
        salesForceRequestData["country_code"] = this.selectCountryCode;
      } else {
        salesForceRequestData["country_code"] = this.lead_gen_form.value.country
          ? this.lead_gen_form.value.country
          : "";
        salesForceRequestData["Preferred_Voyage_Region__c"] =
          this.selectedRegion;

        let selectedDateForSalesForce = this.selectedDates
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
          .join(" , \n");
        salesForceRequestData["Preferred_Voyage_Date__c"] =
          selectedDateForSalesForce;
      }
      if (
        salesForceRequestData["country_code"] == "US" ||
        salesForceRequestData["country_code"] == "United States"
      ) {
        if (this.lead_gen_form.value.phone) {
          salesForceRequestData["Phone_Opt_In__c"] = "Yes";
        } else {
          salesForceRequestData["Phone_Opt_In__c"] = "No Response";
        }
      }

      this.rcycHiddenSalesforceFormService.generateFormW2L(salesForceRequestData, 'rab', true); 

      // Salesforce Web to Lead - W2L END
    }
  }

  /**
   * Funtion for creating token for ebrochure
   */
  ebrochureFormToken() {
    this.rcycFormTokenService.formTokenService().subscribe(
      (response: any) => {
        if (response && response.code === 200 && response.data.logInfo) {
          this.eBrochureFormToken = response.data.logInfo;
        }
      },
      (err) => {
        console.log("err  formTokenService E-BROCHURE");
        console.log(err);
      }
    );
  }

  /**
   * Function to get Area code on Country Select
   * @param cntrycode
   */
  getAreaCodeOnCountrySelect(cntrycode) {
    if (cntrycode) {
      this.apiUrl_F = "/api/v1/countryphone/" + cntrycode + "?_format=json";
      this.rcycHomeService.home(this.apiUrl_F).subscribe(
        (response: any) => {
          this.rcycCommonService.userAreaDetails(response);
        },
        (err) => {
          console.log("Error in Getting Area", err);
        }
      );
    }
  }

  /**
   * Function for zipcode format
   * @param input
   */
  checkZipcodeFormat(input) {
    let countryCode = this.lead_gen_form.get("country").value;
    if (!this.isRequestEBrochureStatus) {
      const countryName = this.lead_gen_form.get("country").value;
      countryCode = this.countryListArray.filter(
        (p) => p.name === countryName
      )[0].code;
    }
    if (countryCode && countryCode.toUpperCase() === "US") {
      if (isNaN(input.data)) {
        input.srcElement.value = input.srcElement.value.replace(input.data, "");
      }
      const position = input.target.selectionStart;
      let value = input.srcElement.value;
      value = value.replace(/-/g, "");
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), "-", value.slice(5)].join(
          ""
        );
      }
      this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      input.target.selectionStart = position > 5 ? position + 1 : position;
      input.target.selectionEnd = position > 5 ? position + 1 : position;
    } else if (countryCode && countryCode.toUpperCase() === "CA") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 3) {
        input.srcElement.value = [value.slice(0, 3), " ", value.slice(3)].join(
          ""
        );
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 7) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 7));
      }
    } else if (countryCode && countryCode.toUpperCase() === "AF") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 4) {
        input.srcElement.value = [value.slice(0, 4), " ", value.slice(4)].join(
          ""
        );
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 7) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 7));
      }
    } else if (countryCode && countryCode.toUpperCase() === "PK") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), " ", value.slice(5)].join(
          ""
        );
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 7) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 7));
      }
    } else if (countryCode && countryCode.toUpperCase() === "SD") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 3) {
        input.srcElement.value = [value.slice(0, 3), value.slice(3)].join("");
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 7) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 7));
      }
    } else if (countryCode && countryCode.toUpperCase() === "ET") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 4) {
        input.srcElement.value = [value.slice(0, 4), " ", value.slice(4)].join(
          ""
        );
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 7) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 7));
      }
    } else if (countryCode && countryCode.toUpperCase() === "IR") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), "", value.slice(5)].join(
          ""
        );
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 12) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 12));
      }
    } else if (countryCode && countryCode.toUpperCase() === "NG") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), value.slice(5)].join("");
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 12) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 12));
      }
    } else if (countryCode && countryCode.toUpperCase() === "YE") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), value.slice(5)].join("");
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 12) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 12));
      }
    } else if (countryCode && countryCode.toUpperCase() === "IQ") {
      if (input.data && input.data == input.data.toLowerCase()) {
        input.srcElement.value = input.srcElement.value.replace(
          input.data,
          input.data.toUpperCase()
        );
      }
      let value = input.srcElement.value;
      value = value.replace(" ", "");
      if (value.length > 5) {
        input.srcElement.value = [value.slice(0, 5), value.slice(5)].join("");
        this.lead_gen_form.get("zipcode").setValue(input.srcElement.value);
      }
      if (this.lead_gen_form.get("zipcode").value.length > 12) {
        this.lead_gen_form
          .get("zipcode")
          .setValue(this.lead_gen_form.get("zipcode").value.substring(0, 12));
      }
    } else {
      let value = input.srcElement.value.replace(/  /g, " ");
      this.lead_gen_form.get("zipcode").setValue(value.replace(/--/g, "-"));
    }
  }

  specialCountryLiClick(item) {
    this.selectCountryClick(item);
    this.lead_gen_form.get("country").setValue(item.name);
    this.specialCountryInputClickStatus = false;
    this.specialCountryInputCloseBtnStatus = false;
    this.isSpecialCountrySelect = true;
    this.countryValueChangeFn(this.lead_gen_form.get("country").value);
  }

  specialCountryInputCloseClick() {
    this.lead_gen_form.get("country").setValue("");
    this.specialCountryInputCloseBtnStatus = false;
  }

  countryValueChangeFn(val) {
    if (!this.isRequestEBrochureStatus) {
      if (val) {
        val = this.countryListArray.filter((p) => p.name === val)[0].code;
        this.selectCountryCode = val;
      }
    }
    this.lead_gen_form.get("zipcode").setValue("");
    this.zipcodeReqStatusDynamic = false;
    let zipCodeValidation: any;
    this.zipcodeMaxLength = 10;
    if (val) {
      this.getAreaCodeOnCountrySelect(val);
      this.userCountryDetails = Object.assign({}, this.formttedPhoneList[val]);
      this.userCountryDetails.ph_length = Number(
        this.formttedPhoneList[val].ph_length
      );
      this.lead_gen_form.get("phone").setValue("");
      this.lead_gen_form.get("countryCode").setValue(val);
      this.lead_gen_form
        .get("dialCode")
        .setValue(this.formttedPhoneList[val].dial_code);
    } else {
      this.getAreaCodeOnCountrySelect(this.permanentGeoLocation.code);
      this.userCountryDetails = Object.assign({}, this.permanentGeoLocation);
      this.userCountryDetails.ph_length = Number(
        this.permanentGeoLocation.ph_length
      );
    }
    if (val === "US") {
      zipCodeValidation = /^[0-9]{5}(?:-[0-9]{4})?$/;
      this.lead_gen_form.get("isAgreeToContact").setValue(false);
      this.lead_gen_form.get("isAgreeToReceiveMail").setValue(true);
      this.zipcodeReqStatusDynamic = true;
      this.showPhoneopt = false;
      this.showTermUS = true;
      this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
      this.lead_gen_form.get("phone").updateValueAndValidity();
    } else {
      this.showPhoneopt = true;
      this.showTermUS = false;
      if (val === "CA") {
        this.zipcodeMaxLength = 7;
        zipCodeValidation = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else if (val === "AF") {
        this.zipcodeMaxLength = 5;
        zipCodeValidation =
          /^(1[0-9]|2[0-9]|3[0-9]|4[0-3])(0[1-9]|[1-9][0-9])$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else if (val === "PK") {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9][0-9]|[1-8][0-9]|9[0-8])([0-9]{3})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else if (val === "SD") {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else if (val === "ET") {
        this.zipcodeMaxLength = 4;
        zipCodeValidation = /^([1-9]{1})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else if (val === "IR") {
        this.zipcodeMaxLength = 10;
        zipCodeValidation = /\b(?!(\d)\1{3})[13-9]{4}[1346-9][0-9]{5}\b/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else if (val === "NG") {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else if (val === "YE") {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else if (val === "IQ") {
        this.zipcodeMaxLength = 5;
        zipCodeValidation = /^([1-9]{2})([0-9]{1})([0-9]{2})$/;
        this.zipcodeReqStatusDynamic = true;
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      } else {
        this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
        this.lead_gen_form.get("phone").updateValueAndValidity();
      }
      this.lead_gen_form.get("isAgreeToContact").setValue(false);
      this.lead_gen_form.get("isAgreeToReceiveMail").setValue(false);
    }
    if (zipCodeValidation) {
      this.lead_gen_form
        .get("zipcode")
        .setValidators([
          Validators.required,
          Validators.pattern(zipCodeValidation),
        ]);
      this.lead_gen_form.get("zipcode").updateValueAndValidity();
    } else {
      this.lead_gen_form.get("zipcode").setValidators(null);
      this.lead_gen_form.get("zipcode").updateValueAndValidity();
    }
    this.phoneValueChangeFn();
  }

  leadGenSubmit() {
    this.specialOfferFormErrorMsg = "";
    let honeyField = <HTMLInputElement>document.getElementById("thirdNameId");
    if (this.isRequestEBrochureStatus && !honeyField.value) {
      this.leadGenSubmitApiCall();
    } else {
      if (
        this.lead_gen_form.controls.firstname.invalid ||
        this.lead_gen_form.controls.lastname.invalid ||
        this.lead_gen_form.controls.country.invalid ||
        this.lead_gen_form.controls.email.invalid ||
        this.lead_gen_form.controls.zipcode.invalid ||
        (this.lead_gen_form.controls.phone &&
          this.lead_gen_form.controls.phone.errors &&
          (this.lead_gen_form.controls.phone.errors.phoneIncorrect == true ||
            (this.lead_gen_form.controls.phone.errors.phoneIncorrect != false &&
              this.phNumReqStatusDynamic))) ||
        !this.userArea ||
        !this.isSpecialCountrySelect
      ) {
        this.specialOfferFormErrorMsg = "Please fill all mandatory fields";
        document.querySelector(".page-title").scrollIntoView();
      } else {
        this.leadGenSubmitApiCall();
      }
    }
  }

  phoneValueChangeFn() {
    if (this.lead_gen_form.value.phone) {
      if (
        this.lead_gen_form.value.country !== "US" &&
        !this.lead_gen_form.value.isAgreeToContact
      ) {
        this.showPhoneOptRequiredText = true;
      }
    } else {
      this.showPhoneOptRequiredText = false;
    }
  }

  phoneOptValueChanged() {
    if (this.lead_gen_form.value.isAgreeToContact) {
      this.lead_gen_form
        .get("phone")
        .setValidators([Validators.required, ValidatePhoneNumber]);
      this.lead_gen_form.get("phone").updateValueAndValidity();
      this.showPhoneOptRequiredText = false;
      this.phNumReqStatusDynamic = true;
    } else {
      this.lead_gen_form.get("phone").setValidators([ValidatePhoneNumber]);
      this.lead_gen_form.get("phone").updateValueAndValidity();
      this.phNumReqStatusDynamic = false;
      if (this.lead_gen_form.value.phone) {
        this.showPhoneOptRequiredText = true;
      }
    }
  }

  /**
   * Funtion to get the drupal error messages
   */
  getDrupalErrorMessages() {
    const apiUrl = "/api/v1/errormessages?_format=json";
    this.requestBrochureService.requestBrochureDrupalGet(apiUrl).subscribe(
      (response: any) => {
        if (response && response.length) {
          this.drupalErrorMessages = response[0];
        }
      },
      (err) => {
        console.log("Drupal Call Api error");
        console.log(err);
      }
    );
  }
}