<swal #guestPreContractSwal (beforeOpen)="beforeOpen($event)" [showConfirmButton]="false" [showCancelButton]="false" [showCloseButton]="true" 
  customClass="guestPreContractSwal" [allowOutsideClick]="true" [animation]="false" (close)="onClose($event)" [reverseButtons]="true">
  <ng-container *swalPartial>
    <div id="rcyc-page-preContractPage" class="bodyContentOuter">
    
    <!-- Section 1 -->
    <section *ngIf="apiResponseCStatus" class="section-1">			
      <div class="l-container-wrapper">	
        <div class="l-container">	
            <h2 [title]="apiResponseC?.title | convertHtml" class="page-title" [innerHtml]="apiResponseC?.title"></h2>
            <p class="titleCenter" [title]="apiResponseC?.field_sub_header" [innerHtml]="apiResponseC?.field_sub_header"></p>
          <div class="scrollItem">
            <div class="row is-12 scrollInsideDiv">	
              <p [innerHTML]="apiResponseC?.field_summery | safeHtml"></p>
            </div>
            <div class="row is-12 scrollInsideDiv" [innerHTML]="apiResponseD?.field_term1 | safeHtml"></div>
          </div>
          <div class="buttonDiv">
            <input id="acknowledgeId" class="button" title="Acknowledge" type="button" value="Acknowledge" (click)="acknowledgeClick()" />
          </div>
        </div>
      </div>
    </section> 
    </div>
  </ng-container>
</swal>
  