import { Component, Input, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { Router } from '@angular/router';

@Component({
  selector: 'app-rcyc-slider',
  templateUrl: './rcyc-slider.component.html',
  styleUrls: ['./rcyc-slider.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class RcycSliderComponent implements OnInit {
  @Input() id: any;
  @Input() data: any;
  @Input() variant: any;
  @Input() additionalCostInfo: any;
  @Input() pageName: any;
  @Input() isMobile: boolean;
  @Input() currencyCode: any;
  showSliderControl: boolean = null;
  @Output() openModal = new EventEmitter<any>();

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  carouselId: string
  sliderConfig: any;
  nrOfPages: number = null;
  page: number = null;
  constructor(private changeDetector: ChangeDetectorRef,
    private router: Router) { }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  clearCompareValue(url: string) {
    if (this.pageName === 'destinations') {
      this.router.navigate(['/' + url]);
    }
  }

  getSlideData(item, index) {
    return {
      id: index,
      image: {
        src: item.field_image,
        title: item.field_article_title,
        alt: item.field_article_title,
      },
      eyebrow: item.field_tile_eyebrow,
      title: item.field_tile_title,
      text: item.field_tile_details,
      cta: {
        title: item.field_tile_cta_title,
        url: item.field_tile_cta_url,
      }
    }
  }

  getEditorialData(item, index) {
    return {
      ...item,
      heading: item.heading ? item.heading : `${index < 9 ? '0' : ''}${index + 1}`,
      image: {
        src: item.image?.src ? item.image?.src : item.field_image,
        title: item.field_article_title,
        alt: item.field_article_title,
      },
      eyebrow: item.field_tab_section,
      title: item.field_article_title,
      url: item.field_url_key
    }
  }

  experienceData(item, index) {
    return {
      ...item,
      heading: null,
      image: {
        src: item.image?.src ? item.image?.src : item.field_image,
        title: item.field_article_title,
        alt: item.field_article_title,
      },
      eyebrow: item.field_tab_section,
      title: item.field_article_title,
      url: item.field_url_key
    }
  }

  hubData(item, index) {
    return {
      ...item,
      heading: null,
      image: {
        src: item.image?.src ? item.image?.src : item.field_image,
        title: item.field_article_title,
        alt: item.field_article_title,
      },
      eyebrow: item.field_tab_section,
      title: item.field_article_title,
      url: item.field_url_key,
      description: item.field_summery
    }
  }

  ngOnInit(): void {
    this.nrOfPages = null;
    setTimeout(() => {
      this.getSliderPosition({ slick: { currentSlide: 0 } });
    }, 1000);

    this.carouselId = 'carousel-' + this.id + '-' + this.variant;

    const bar = {
      dots: true,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      focusOnSelect: true,
      mobileFirst: true,
      infinite: false,
      speed: 1000,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: true,
            variableWidth: true,
          }
        }
      ]
    }
    const experience = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      adaptiveHeight: true,
      respondTo: 'slider',
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 4,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          variableWidth: true,
          infinite: true,
          settings: {
            slidesToScroll: 4,
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 600,
          infinite: true,
          variableWidth: true,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 340,
          infinite: true,
          variableWidth: true,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
          }
        }
      ]
    }
    const editorial = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      infinite: false,
      slidesToScroll: 2,
      slidesToShow: 2,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          variableWidth: true,
          infinite: true,
          settings: {
            slidesToScroll: 4,
            slidesToShow: 4,
          }
        }
      ]
    }
    const full_image = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            // slidesToScroll: 2,
            slidesToShow: 3
          }
        }
      ]
    }

    const hub = {
      dots: true,
      arrows: false,
      mobileFirst: true,
      slidesToScroll: 2,
      slidesToShow: 2,
      appendDots: ('.' + this.carouselId + '-dots'),
      responsive: [
        {
          breakpoint: 1024,
          variableWidth: true,
          infinite: true,
          settings: {
            slidesToScroll: 4,
            slidesToShow: 4,
          }
        }
      ]
    }

    // Map by variant
    this.sliderConfig = {
      barSmall: { ...bar, ...{ infinite: true } },
      bar: bar,
      thumbnail: bar,
      featured: bar,
      editorial: editorial,
      experience: experience,
      full_image: full_image,
      shore_excursion: bar,
      hub: hub
    }

    this.showSliderControl = this.nrOfPages > 1;
  }

  getSliderPosition(e) {
    // Workaround for the case with multiple slides per 'page'
    let slidesToShow = 1;
    if (this.variant === 'editorial' || this.variant === 'hub') {
      if (window.innerWidth < 769) {
        slidesToShow = 2;
      } else {
        slidesToShow = 4;
      }
    }
    if (this.variant === 'experience') {
      if (window.innerWidth < 769) {
        slidesToShow = 1;
      } else {
        slidesToShow = 4;
      }
    }
    if (this.variant === 'full_image') {
      if (window.innerWidth < 1025) {
        slidesToShow = 1;
      } else {
        slidesToShow = 3
      }
    }
    this.page = Math.ceil((e.slick.currentSlide ? e.slick.currentSlide : 0) / slidesToShow + 1)
    this.nrOfPages = Math.ceil(this.data.items.length / slidesToShow);
    this.showSliderControl = this.nrOfPages > 1;
  }

  onSlideClick(item: any) {
    if (this.data?.topContent?.sliderType === 'hub') {
      this.router.navigate(['/editorial-hub-details/'+item.field_url_key]);
    }else{
      this.openModal.emit(item);
    }
  }
}
