import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { Router } from '@angular/router';
import { RcycVoyagesService } from '../../rcyc-voyages/rcyc-voyages.service';
import { RcycInternalApisService } from '../../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';

@Component({
  selector: 'app-rcyc-shore-excursions',
  templateUrl: './rcyc-shore-excursions.component.html',
  styleUrls: ['../rcyc-full-img-tile.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class RcycShoreExcursionsComponent implements OnInit {
  @Input() excursionDetail: any;
  @ViewChild('shoreExcursionsSwal', { static: true }) shoreExcursionsSwal: SwalComponent;
  currentUrlPath: any;
  currentUrlPathAsArray: any;
  currentVoyagecode: string;
  portList = [];
  apiResponseExcursion: any;
  apiResponseA: any = [];
  excursionType: string;
  storeRoom: string;
  excursionBytype: any;
  activeTab: any;
  isOverFlowing: boolean;
  isAtStart: boolean;
  isAtEnd: boolean;

  constructor(
    private router: Router,
    private rcycVoyagesService: RcycVoyagesService,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycCommonService: RcycCommonService,) { }


  ngOnInit() {
    this.currentUrlPath = this.router.url;
    this.currentUrlPathAsArray = this.currentUrlPath.split('/');
    this.currentUrlPathAsArray.shift();
    this.currentVoyagecode = this.currentUrlPathAsArray[1].split('-').pop();
    this.screenSize()
  }

  @HostListener('window:resize', ['$event'])
  screenSize() {
    this.updateScrollButton()
  }

  modalOpen(title: any) {    
    this.excursionType = title;
    this.checktourCategory()
    this.getVoyageItineray();
    this.shoreExcursionsSwal.show();
  }
  // method to call just before opening the modal
  beforeOpen(event) {
    event.modalElement.parentElement.setAttribute('id', 'shoreSwalId');
  }
  // method to call while opening the modal
  onOpen(event) {
  }

  onClose(event) {
    // this.animationStatus = false;
  }

  scrollTabs(direction: string): void {
    let id = document?.querySelector('#shoreSwalId') as HTMLElement;
    let container = id?.querySelector('.tabs-container') as HTMLElement;
    const scrollAmount = 200;

    if (direction === 'left') {
      container.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      })
    } else {
      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      })
    }
    setTimeout(()=>{
      this.updateScrollButton()
    },500)
  }

  IOSRedraw(): void{
    let id = document?.querySelector('#shoreSwalId') as HTMLElement;
    let container = id?.querySelector('.tabs-container') as HTMLElement;
    const currentScroll = container?.scrollLeft;

    container.scrollLeft = currentScroll + 1;
    container.scrollLeft = currentScroll;
  }

  updateScrollButton(): void{
    let id = document?.querySelector('#shoreSwalId') as HTMLElement;
    let container = id?.querySelector('.tabs-container') as HTMLElement;
    let leftArrow = id?.querySelector('.left-arrow') as HTMLElement;
    let rightArrow = id?.querySelector('.right-arrow') as HTMLElement;

     this.isOverFlowing = container?.scrollWidth > container?.clientWidth;

     this.isAtStart = Math.floor(container?.scrollLeft) <= 0;
     this.isAtEnd = Math.ceil(container?.scrollLeft + container?.clientWidth) >= container?.scrollWidth;

     requestAnimationFrame(()=>{
      if(this.isOverFlowing && !this.isAtStart){
        leftArrow?.classList?.remove('hidden');
      }else{
        leftArrow?.classList?.add('hidden');
      }
  
      if(this.isOverFlowing&&!this.isAtEnd){
        rightArrow?.classList?.remove('hidden');
      }else{
        rightArrow?.classList?.add('hidden');
      }
     })
  }

  getVoyageItineray() {
    this.portList =[]
    this.rcycVoyagesService.excursionPortdate(this.currentVoyagecode).subscribe(
      (response: any) => {
        if (response && response.code === 200) {
          response.data.excursions.map((item, i)=>{
            let portDetail = {
              portName: item.portName,
              portCode: item.portCode,
              date: item.date
            }
            this.excursionsClick(portDetail)
          })
        }
      },
      err => { }
    );
  }
  excursionsClick(eachPort: any) {
    this.activeTab = eachPort.portCode;
    const searchdata: any = {
      date: eachPort.date,
      portCode: eachPort.portCode,
      voyageCode: this.currentVoyagecode
    };
    this.rcycVoyagesService.excursionMoreData(searchdata).subscribe(
      (response: any) => {
        if (response && response.code === 200) {
          this.apiResponseExcursion = response?.data?.excursions
          this.excursionBytype = [];
          this.excursionMapping(eachPort);
          this.excursionsImage(eachPort);
        }
      },
      err => { }
    );
  }
  checktourCategory() {
    if (this.excursionType === 'PRE-DESIGNED PRIVATE') {
      this.storeRoom = 'SHOREX_PP'
    } else if (this.excursionType === 'SELECT GROUP EXCURSION') {
      this.storeRoom = 'SHOREX_SG'
    }
  }
  excursionMapping(port) {
    for (let eachExcusrion of this.apiResponseExcursion) {
      if (eachExcusrion.storeRoomCd === this.storeRoom) {
        this.excursionBytype.push(eachExcusrion)
        if(!this.portList?.includes(port)){
          this.portList.push(port)
          this.portList?.map((item,i)=>{
            if(i==0){
              this.excursionsClick(item)
            }
          })
        }
      }
    }
    setTimeout(()=>{
      this.IOSRedraw();
      this.updateScrollButton()
    },1000)
    return this.excursionBytype
  }
  /* ==========================================================
Api to get images of excursions
============================================================= */
  excursionsImage(item) {
    const apiUrlA = '/api/v1/excursions/port/' + item.portCode + '?_format=json';
    this.rcycInternalApisService.getDrupal(apiUrlA).subscribe(
      response => {
        const apiResponse: any = response;
        if (apiResponse && apiResponse.length) {
          const res = Object.keys(response).map(function (key) {
            return response[key];
          });

          if (res.length) {
            this.apiResponseA = res;
            this.resThumbnailData();
          }
        }
      },
      err => {
        console.log('err  apiUrlA');
        console.log(err);
      }
    );
  }
  resThumbnailData() {
    let excursionMore = this.rcycCommonService.setImages(this.apiResponseA, this.apiResponseExcursion);
  }
}