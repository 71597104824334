import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';
import {Observable} from 'rxjs';
@Injectable()
export class RcycFormTokenService {

  ELASTIC_API_BASE_URL_EBROCHURE: any = AppConfig.ELASTIC_API_BASE_URL_EBROCHURE;
  tokenErrorMsg: string = 'Session expired, please refresh this page and try again.';
  genErrorMsg: string = 'Something went wrong, Try again';
  noResponseMsg: string = 'Submission not compeleted due to network problem';

  apiUrl: any;
  constructor(

    private http: HttpClient,
  ) {

  }

  /* ======================================================================================================================================
    Funtion Api call for creating token for form
  * ======================================================================================================================================*/
  formTokenService() {

    const timeStamp = new Date().getTime();
    this.apiUrl = '/api/logInfo' + '?tsp=' + timeStamp;
    const url = this.ELASTIC_API_BASE_URL_EBROCHURE + this.apiUrl;
    return this.http.get(url);
  }

  // service for creating modal content
  modalService(page) {
    let title, modalContent, routerUrl;
    switch (page) {
      case 'request-call':
        title = 'request call!..';
        modalContent = 'something went wrong in request call';
        routerUrl = 'request-call';
      break;

      case 'request-quote':
        title = 'request quote!..';
        modalContent = 'something went wrong in request quote';
        routerUrl = 'request-quote';
      break;

      case 'request-brochure':
        title = 'request brochure!..';
        modalContent = 'something went wrong in request brochure';
        routerUrl = 'request-brochure';
      break;

      default:
        title = '';
        modalContent = '';
        routerUrl = '';
      break;
    }
    const modalData = {title, modalContent, routerUrl};
    return modalData;
  }

}
