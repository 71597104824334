import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-rcyc-offer-card',
  templateUrl: './rcyc-offer.component.html',
})

export class RcycOfferComponent implements OnInit {
  @Input() data: any
  @Input() variant: any
  @Output() clearCompareValueEmitter = new EventEmitter<string>();

  detailsUrl: any
  highlight: any
  imagesReady: any = false;

  constructor() { }

  ngOnInit(): void {
    let voyageEmbarkPort_new;
    let voyageDisembarkPort_new;
    if (this.data?.voyageEmbarkPort) {
      voyageEmbarkPort_new = this.data.voyageEmbarkPort
        .replace(/[^a-zA-Z-]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
    }
    if (this.data?.voyageDisembarkPort) {
      voyageDisembarkPort_new = this.data.voyageDisembarkPort
        .replace(/[^a-zA-Z-]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
    }

    this.detailsUrl = '/' + this.data?.voyageUrlPath + '/' + voyageEmbarkPort_new + '-to-' + voyageDisembarkPort_new + '-' + this.data?.voyageId
  }

  clearCompareValue(url: string) {
    this.clearCompareValueEmitter.emit(url);
  }
}
