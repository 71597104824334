import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';
@Injectable()
export class RcycRequestQuoteService {

  API_BASE_URL: any =   AppConfig.API_BASE_URL;
  ELASTIC_API_BASE_URL: any =   AppConfig.ELASTIC_API_BASE_URL;
  ELASTIC_API_BASE_URL_EBROCHURE: any = AppConfig.ELASTIC_API_BASE_URL_EBROCHURE;

  constructor(

    private http: HttpClient
  ) {

  }

  invalidVoyageDetails(voyageCode){
    const url = this.ELASTIC_API_BASE_URL + "/api/voyageStatus";
    let body: any = {};
    if (voyageCode) {
      body = { voyageCode: voyageCode };
    }
    return this.http.post(url, body);
  }

}
