<div id="rcyc-page-simplePage" class="bodyContentOuter">

  <p class="troubleViewing" *ngIf="apiResponse_ebrochure_status && (eBrochureSubscriptionCode === 'zmags'
  || eBrochureSubscriptionCode === 'ebrochure')">Trouble Viewing? <a
			[href]="apiResponse_ebrochure?.field_trouble_viewing_link" target="_blank">Please
			click here</a>
  </p>

  <!-- Section 1 -->
  <section *ngIf="apiResponse_common_status" class="section-1">
    <div class="l-container-wrapper">
      <div class="l-container">

        <!-- <div class="row is-12" *ngIf="!apiResponse_common_status">
					No data found
				</div> -->
        <div class="row is-12" id='{{rcycCurrentPage}}'>
          <!-- <h4 class="sub-title">Lorem Ipsum Doler Triman</h4> -->
          <h2 *ngIf="apiResponse_common?.title" [title]="apiResponse_common?.title | convertHtml" class="page-title"
            [innerHtml]="apiResponse_common?.title"></h2>
          <h4 class="isContractTypeSubHeader" *ngIf="isContractType&&!preContractFormView" [innerHTML]="subHeaderText"></h4>
          <p [innerHTML]="subHeaderText" class="preContractFormView_subHeaderText" *ngIf="preContractFormView"></p>
          <div [ngClass]="{scrollItem: isContractType}">
            <div [ngClass]="{scrollInsideDiv: isContractType}" class="simplePageContent">
              <p *ngIf="apiResponse_common?.field_summery" [innerHTML]="apiResponse_common?.field_summery | safeHtml"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Section 2 -->
  <section *ngIf="apiResponseEBrochureKeyStatus" class="section-2 eBrochureLoader">
    <div id="flashcontent"></div>
  </section>
  <!-- Section 2 -->

  <!-- Section 3 -->
  <section *ngIf="!preContractFormView" class="section-3">
    <div id="FlippingBookContent"></div>
    <p class="flippingBookViewMore" *ngIf="apiResponse_ebrochure_status && (eBrochureSubscriptionCode === 'flippingbook')"><a
      [href]="apiResponse_ebrochure?.field_trouble_viewing_link" target="_blank">View More</a>
    </p>
    <!-- <div id="myViewerContent" style="position:fixed; top:0px; left:0px; height:100%; width:100%;"></div> -->
  </section>
  <!-- Section 3 -->

  <!-- Section 4 -->

  <section *ngIf="preContractFormView" class="section-acknowledgeForm">
    <div class="l-container-wrapper">
      <div class="l-container" id='{{rcycCurrentPage}}'>

        <div class="acknowledgeForm outer-div-form l-container-wrapper" id="acknowledgeFormId">
          <div class="row is-8-4 inner-div-form l-container">

            <div class="alert acknowledgeFormMessge" *ngIf="messageclassType"
              [ngClass]="{'alert-success': messageclassType=='success','alert-error': messageclassType=='error'}">
              <span [innerHtml]="statusMessage"></span>
            </div>

            <!-- <div class="column is-8 formMainForm">

                    <div class="row is-4-8">

                      <div class="inner-div-form-filed column is-4">
                        <div class="column is-12 form_fields">
                          <label class="basic-form_label form_label basic-form_label-required">Booking Number</label>
                        </div>
                        <div class="column is-12 form_fields">
                          <input [(ngModel)]="bookingId" id="bookingNumberId" class="basic-form_input basic-form_input-half"
                            type="text" maxlength="15" (input)="buttonStatus($event)" (focusout)='focusOutFunction($event)' placeholder="Booking Number">
                            <span *ngIf="!bookingIdReqValue" class="error" [innerHTML]='apiResponseGError?.field_booking_id_required'> </span>
                        </div>
                      </div>

                      <div class="inner-div-form-filed column is-8">
                        <div class="column is-12 form_fields">
                          <label class="basic-form_label form_label basic-form_label-required">Name </label>
                        </div>
                        <div class="column is-12 form_fields">
                          <input [(ngModel)]="confirmerName" id="confirmerName" class="basic-form_input basic-form_input-half"
                            type="text" pattern="[a-zA-Z0-9 ]*" (input)="buttonStatus($event)" (focusout)='focusOutFunction($event)' placeholder="Name">
                            <span *ngIf="!confirmerNameReqValue" class="error" [innerHTML]='apiResponseGError?.field_conf_name_required'> </span>
                        </div>
                      </div>
                    </div>

                    <div class="buttonDiv row is-8-4">
                      <div class="column is-8 acknowledgeButtonChild">
                        <div class="row is-6-6">
                          <div class="column is-6">
                              <input *ngIf="!acknowledgeButton" [disabled]="disableButton" id="acknowledgeId" class="button submitButton" title="Acknowledge" type="submit"
                              value="Acknowledge" (click)="acknowledgeClick()"  />
                              <div class="selectGuest" *ngIf="acknowledgeButton">
                                <div class="row is-12 btnLoader">
                                  <img src="assets/components/images/loading.gif" class="formLoader">
                                </div>
                              </div>
                          </div>
                          <div class="column is-6">
                            <input   id="acknowledgeId" class="button cancelbutton" title="Acknowledge" type="submit"
                            value="Cancel" (click)="cancelOption()" />



                          </div>
                        </div>
                      </div>
                    </div>

            </div> -->
             <!-- Form Main-->

            <div class="column is-9 formMainForm">
              <form id="euPreContractFormId" name="euPreContractForm" [formGroup]="euPreContractForm" novalidate autocomplete="false">
                <div class="row is-4-8">

                  <div class="inner-div-form-filed column is-4">
                    <div class="column is-12 form_fields">
                      <label class="basic-form_label form_label basic-form_label-required">Booking Number</label>
                    </div>
                    <div class="column is-12 form_fields">
                      <input min='1' id="bookingNumberId" class="basic-form_input basic-form_input-half"
                        type="number" maxlength="15" formControlName="bookingNumber"  placeholder="Booking Number">
                        <!-- <span *ngIf="!bookingIdReqValue" class="error" [innerHTML]='apiResponseGError?.field_booking_id_required'> </span> -->
                        <p class="errorptag" *ngIf="euPreContractForm.controls.bookingNumber.invalid && (euPreContractForm.controls.bookingNumber.dirty || euPreContractForm.controls.bookingNumber.touched)">
                          <span *ngIf="euPreContractForm.controls.bookingNumber.errors.required"
                          class="error" [innerHTML]='apiResponseGError?.field_booking_id_required'></span>
                          <!-- <span *ngIf="!euPreContractForm.controls.bookingNumber.errors.required&&euPreContractForm.controls.bookingNumber.errors.pattern"
                          class="error">Please enter a valid bookingNumber </span> -->
                        </p>
                    </div>
                  </div>

                  <div class="inner-div-form-filed column is-8">
                    <div class="column is-12 form_fields">
                      <label class="basic-form_label form_label basic-form_label-required">Name </label>
                    </div>
                    <div class="column is-12 form_fields">
                      <input id="confirmerName" class="basic-form_input basic-form_input-half"
                        type="text" formControlName="guestName" placeholder="Name">
                        <!-- <span *ngIf="!confirmerNameReqValue" class="error" [innerHTML]='apiResponseGError?.field_conf_name_required'> </span>  -->
                        <p class="errorptag" *ngIf="euPreContractForm.controls.guestName.invalid && (euPreContractForm.controls.guestName.dirty || euPreContractForm.controls.guestName.touched)">
                          <span *ngIf="euPreContractForm.controls.guestName.errors.required"
                          class="error" [innerHTML]='apiResponseGError?.field_conf_name_required'></span>
                          <span *ngIf="!euPreContractForm.controls.guestName.errors.required&&euPreContractForm.controls.guestName.errors.pattern"
                          class="error">Please enter a valid name </span>
                        </p>
                    </div>
                  </div>
                </div>

                <!-- <div class="buttonDiv row"> -->
                  <div class="row is-6-6 buttonSection">
                    <!-- <div class="row is-6-6"> -->
                      <div class="column is-6 acknowledgeBtnOuter">
                          <input *ngIf="!acknowledgeButton" [disabled]="euPreContractForm.controls.bookingNumber.invalid||euPreContractForm.controls.guestName.invalid"
                          id="acknowledgeId" class="button submitButton" title="Acknowledge" type="submit"
                          value="Acknowledge" (click)="acknowledgeClick()"  />
                          <div class="selectGuest" *ngIf="acknowledgeButton">
                            <div class="row is-12 btnLoader">
                              <img src="assets/components/images/loading.gif" class="formLoader">
                            </div>
                          </div>
                      </div>
                      <div class="column is-6 cancelBtnOuter">
                        <input   id="acknowledgeCancelId" class="button cancelbutton" title="Acknowledge" type="submit"
                        value="Cancel" (click)="cancelOption()" />
                      </div>
                    <!-- </div> -->
                  </div>
                <!-- </div> -->

              </form>
            </div>
             <!-- Form Main-->
            <div class="column is-3">
            </div>
          </div>

        </div>
      </div>


      </div>
    <!-- </div> -->
  </section>
  <!-- Section 4 -->

</div>
