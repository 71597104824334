import { Component, OnInit, ViewEncapsulation, Input, AfterViewInit, ViewChild, HostListener, ElementRef, Renderer2 } from '@angular/core';

import { RcycCommonService } from '../../../rcyc-services/rcyc-common/rcyc-common.service';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
// import { RcycCommonLoginService } from '../../../rcyc-services/rcyc-login/rcyc-login.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import * as EmailValidator from 'email-validator';
import { RcycFormTokenService } from '../../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
// import { RcycLoginService } from './rcyc-login.service';
import { AppConfig } from '../../../rcyc-config/config';
import { networkInterfaces } from 'os';
import { RcycInternalApisService } from '../../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycCommonLogoutService } from '../../../rcyc-services/rcyc-logout/rcyc-logout.service';
import { SharedServiceService } from '../../../rcyc-services/rcyc-shared/shared-service.service';

@Component({
  selector: 'app-rcyc-login',
  templateUrl: './rcyc-login.component.html',
  styleUrls: ['./rcyc-login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RcycLoginComponent implements OnInit, AfterViewInit {

  IMG_BASE_URL: any = AppConfig.IMG_BASE_URL;
  currentComponentName: any;
  guestLogin: any;
  emailValidationMsg: any;
  emailErr = false;
  emailValidStatus = false;
  passwordValidationMsg: any;
  pwdErr = false;
  pwdValidStatus = false;
  formSubmitErrorMsg: any;
  guestLoginFormTokenTemp: any;
  guestLoginFormToken: any;
  formSubmitFailStatus = false;
  emailVerifiedStatus = false;
  apiUrl_GuestLogin_Submit: any;
  guestLoginResponse: any;
  loginError: any = '';
  apiUrl_C: any;
  apiresponseCTemp: any;
  apiresponseC: any;
  apiresponseCStatus = false;
  nameOfCurrentUser: any;
  animationStatus = true;
  rememberMeCB: any;
  guestEmailFirstSec: any;
  passemails: any = '';
  loadApiStatus = false;
  currentSessionStatus = false;
  accountLockedInfo: any;
  accountLockedInfoStatus = false;
  wrongUsernamePasswordInfo: any;
  wrongUsernamePasswordInfoStatus = false;
  firstOpenStatus = false;

  apiUrlGError: any;
  apiResponseGErrorTemp: any;
  apiResponseGError: any;
  apiResponseGErrorStatus = false;

  @Input() registrationToLoginPass: any;
  @ViewChild('guestLoginSwal', { static: true }) guestLoginSwal: SwalComponent;
  constructor(

    private formBuilder: UntypedFormBuilder,
    private rcycCommonService: RcycCommonService,
    // private http: HttpClient,
    // private rcycLoginService: RcycLoginService,
    // private _elementRef: ElementRef,
    // private render: Renderer2,
    private rcycFormTokenService: RcycFormTokenService,
    private router: Router,
    // private rcycCommonLoginService: RcycCommonLoginService,
    private rcycInternalApisService: RcycInternalApisService,
    private rcycCommonLogoutService: RcycCommonLogoutService,
    private _SharedServiceService: SharedServiceService,
  ) {

  }

  ngOnInit() {
    // call all functions in constructor
    this.rcycGuestLoginConstructor();
    this.rcycGuestLoginOnInit();

  }
  ngAfterViewInit() {

  }

  // Function to get Account Locked Error Message form Drupal

  // callAccountLockedDrupalApi() {
  //   const apiUrl = '/api/v1/signin/header/l?_format=json';
  //   this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
  //     res => {
  //       const temp: any = res;
  //       if (temp) {
  //         this.accountLockedInfo = temp;
  //         this.accountLockedInfoStatus = true;
  //       }
  //     },
  //     err => {
  //     }
  //   );

  // }

  // Function to get Wrong Username and Password Message form Drupal

  // callWrongUsernamePasswordApi() {
  //   const apiUrl = '/api/v1/signin/header/b?_format=json';
  //   this.rcycInternalApisService.getDrupal(apiUrl).subscribe(
  //     res => {
  //       const temp: any = res;
  //       if (temp) {
  //         this.wrongUsernamePasswordInfo = temp[0];
  //         this.wrongUsernamePasswordInfoStatus = true;
  //       }
  //     },
  //     err => {
  //     }
  //   );
  // }

  // method to call just before opening the modal
  beforeOpen(event) {

    // To add ad id to parent html tag
    event.modalElement.parentElement.setAttribute('id', 'guestLoginSwalId');
    if (this.emailVerifiedStatus) {
      if (document.querySelector('.swal2-header')) {

        const swalHeadElem = <HTMLElement>document.querySelector('.swal2-header');
        swalHeadElem.style.background = '#468847';

        document.querySelector('.swal2-header').innerHTML += 'Your email has been successfully verified and your account is active now!';
      }
      /*if (document.querySelector('.guestLoginSwtOuter')) {

        const loginSwtOuter = <HTMLElement>document.querySelector('.guestLoginSwtOuter');
        if (window.matchMedia('(max-width: 1200px)').matches) {
          loginSwtOuter.style.width = 'auto';
          loginSwtOuter.style.maxWidth = '800px';
          loginSwtOuter.style.marginBottom = '15px';
        }
      }*/
    }
    this.animationStatus = true;
  }

  // method to call while opening the modal
  onOpen(event) {

    this.firstOpenStatus = true;
    this.formSubmitFailStatus = false;
    this.checkRememberMe();

    if (this.emailVerifiedStatus) {
      if (document.querySelector('#rcyc-page-guest-login .section-1 .leftSection')) {

        const leftSection = <HTMLElement>document.querySelector('#rcyc-page-guest-login .section-1 .leftSection');
        leftSection.style.width = '100%';

        if (window.matchMedia('(max-width: 1200px)').matches) {
          leftSection.style.padding = '0px 12%';
          /*} else if (window.matchMedia('(max-width: 560px)').matches) {
            leftSection.style.padding = '0px 10%';*/
        } else {
          leftSection.style.padding = '0px 25%';
        }

      }
    }
    this.animationStatus = true;
    if (document.querySelector('.swal2-container.swal2-shown')) {
      const swalBack = <HTMLElement>document.querySelector('.swal2-container.swal2-shown');
      /*swalBack.style.backgroundColor = '#1c1c1c';
      swalBack.style.opacity = '0.9';*/
    }

  }

  onClose(event) {

    this.animationStatus = false;
  }

  // method to open modal
  modalOpen() {
    this.rcycCommonService.GLoginStatus('set');
    this.guestLoginSwal.show();
  }

  // constructor function
  rcycGuestLoginConstructor() {

    this.currentComponentName = 'RcycLoginComponent';
    // change changeCurrentComponent
    this.rcycCommonService.changeCurrentComponent(this.currentComponentName);
    this.rcycCommonService.currentSessionStatus.subscribe(status => this.currentSessionStatus = status);

    /****************** Section Global error messages ********************/

    // this.apiUrlGError = '/api/v1/errormessages?_format=json';

    // this.rcycInternalApisService.getDrupal(this.apiUrlGError).subscribe(
    //   response => {

    //     this.apiResponseGErrorTemp = response;
    //     if (this.apiResponseGErrorTemp && this.apiResponseGErrorTemp.length) {
    //       const res = Object.keys(response).map(function (key) {
    //         return response[key];
    //       });

    //       if (res.length) {

    //         this.apiResponseGError = res[0];
    //         this.apiResponseGErrorStatus = true;

    //       }
    //     }
    //   },
    //   err => {
    //     console.log('err  apiUrlGError');
    //     console.log(err);
    //   }
    // );
    this.getErrorCommonMsgs();

    // this.callAccountLockedDrupalApi();
    // this.callWrongUsernamePasswordApi();
  }

  /****************** Section Global error show function ********************/

  getErrorCommonMsgs() {
    this._SharedServiceService.getErrorCommonMsg().subscribe(res => {
      this.apiResponseGError = res;
      if (this.apiResponseGError) {
        this.apiResponseGErrorStatus = true
      }
    });
  }

  // login function
  rcycGuestLoginOnInit() {

    // Function to call all api contents

    this.loginContent();
    this.formSubmitValidation();

  }

  /* =====================================================
     Function to validate form
    ======================================================== */
  formSubmitValidation() {

    // const emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    // this.emailErr = false;


    this.guestLogin = this.formBuilder.group({

      email: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [''],
    });

    // this.rcycCommonService.loginEmail.subscribe(
    //   (message) => {
    //     this.passemails = message;
    //     this.guestLogin.patchValue({ 'email': message });

    //   }
    // );
    // this.rcycCommonService.GLoginEmail('');

    // emailAddress
    this.guestLogin.get('email').valueChanges.subscribe((val) => {

      let emailDomainCharacters;
      if (val) {
        emailDomainCharacters = val.slice(val.lastIndexOf('.') + 1);
      }
      if (!this.guestLogin.controls.email.valid) {
        if (!this.firstOpenStatus) {
          if (this.apiResponseGErrorStatus) {
            this.emailValidationMsg = this.apiResponseGError.field_email_required;
          }
          this.emailErr = true;
          this.emailValidStatus = false;
        } else {
          this.emailErr = false;
          this.firstOpenStatus = false;
        }
      } else if (!EmailValidator.validate(val)) {
        if (this.apiResponseGErrorStatus) {
          this.emailValidationMsg = this.apiResponseGError.field_email_invalid;
        }
        this.emailErr = true;
        this.emailValidStatus = false;
      } else {
        const guestEmail = val.trim();
        this.guestEmailFirstSec = guestEmail.split('@')[0];
        this.emailErr = false;
        this.emailValidStatus = true;
      }

    });

    // password
    this.guestLogin.get('password').valueChanges.subscribe((val) => {
      this.checkPassword(val, 0);
    });

  }

  checkPassword(val, position) {
    let err;
    let errStatus;
    let errMsg;
    let validItem;
    switch (position) {
      case 0:
        validItem = this.guestLogin.controls.password.valid;
        break;
    }
    // const test12 = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,20}$/;
    if (!val) {
      errMsg = this.apiResponseGError.field_password_required;
      err = true;
      errStatus = false;
    } else if (!val.trim().length || !validItem) {
      errMsg = this.apiResponseGError.field_password_invalid_1;
      err = true;
      errStatus = false;
      // } else if (val.indexOf(this.guestEmailFirstSec) !== -1) {
      //   errMsg = '';
      //   err = true;
      //   errStatus = false;
      // } else if (!test12.test(val.trim())) {
      //   errMsg =
      //     '' +
      //     '';
      //   err = true;
      //   errStatus = false;
      // } else if (val.trim().toUpperCase().replace(/[^A-Za-z]/g, '').indexOf('PASSWORD') !== -1) {
      //   errMsg = '';
      //   err = true;
      //   errStatus = false;
      // } else if (test12.test(val.trim())) {
      //   err = false;
      //   errStatus = true;
      // }
    } else {
      err = false;
      errStatus = true;
    }
    switch (position) {
      case 0:
        // this.pwdErr = err;
        this.pwdValidStatus = errStatus;
        this.passwordValidationMsg = errMsg;
        break;
    }
  }

  /* =====================================================
   click on signin
  ======================================================== */
  guestLoginSubmit() {
    this.loadApiStatus = true;
    this.formSubmitFailStatus = false;
    this.currentSessionStatus = false;
    this.formSubmitErrorMsg = '';
    this.apiUrl_GuestLogin_Submit = '/api/login';
    this.guestLogin.value.email = this.RemoveStartSpace(this.guestLogin.value.email);
    this.guestLogin.value.email = this.RemoveExtraSpace(this.guestLogin.value.email);
    this.guestLogin.value.password = this.RemoveStartSpace(this.guestLogin.value.password);
    this.guestLogin.value.password = this.RemoveExtraSpace(this.guestLogin.value.password);

    const data = {

      'userName': this.guestLogin.value.email,
      'password': this.guestLogin.value.password,
    };

    this.rcycInternalApisService.postReservationForm(this.apiUrl_GuestLogin_Submit, data).subscribe(
      response => {
        this.loadApiStatus = false;
        this.guestLoginResponse = response;
        const res = this.guestLoginResponse;

        this.rcycCommonService.setLoggedInStatus(false);
        if (res && res.code === 200) {
          this.formSubmitFailStatus = false;
          // this.nameOfCurrentUser = res.data.forename + ' ' + res.data.surname;
          this.nameOfCurrentUser = res.data.forename;
          // if (!localStorage.getItem('loggedUserDetails')) {
            let loginDatas;
            if (res.data.folioType === 'guest') {
              loginDatas = {
                'guestName': this.nameOfCurrentUser,
                'type': 'G',
                'guestSurName': res.data.surname,
                'jwtToken': res.data.token,
                'dateTime': new Date().toISOString(),
                'email': this.guestLogin.value.email,
                'backToMainSiteStatus': true,
              };
            } else if (res.data.folioType === 'agent') {
              loginDatas = {
                'TAName': this.nameOfCurrentUser,
                'type': 'TA',
                'guestSurName': res.data.surname,
                'guestName': res.data.forename,
                'jwtToken': res.data.token,
                'dateTime': new Date().toISOString(),
                'email': this.guestLogin.value.email,
                'backToMainSiteStatus': true,
              };
            }

          const stringifiedLoginData = JSON.stringify(loginDatas);

          localStorage.setItem('loggedUserDetails', stringifiedLoginData);
          this.rcycCommonService.setLoggedInStatus(true);

          // }

          if (localStorage.getItem('rememberMeData')) {
            const rememberData = {
              'userName': this.guestLogin.value.email
            };
            localStorage.setItem('rememberMeData', JSON.stringify(rememberData));
          }

          // change changeCurrentUserName
          this.rcycCommonService.changeCurrentUser(this.nameOfCurrentUser);

          this.guestLoginSwal.nativeSwal.close();
          if (localStorage.getItem('redirectToWireFrame')) {
            let urlDetails = JSON.parse(localStorage.getItem('redirectToWireFrame'));
            if (urlDetails.status) {
              localStorage.removeItem('redirectToWireFrame');
              this.router.navigate([urlDetails.url]);
              return true;
            }
          }
          if (loginDatas.type === 'TA') {
            this.router.navigate(['/travel-agent/dashboard']);
          } else if (loginDatas.type === 'G') {
            this.router.navigate(['/guest/dashboard']);
          }
        } else {
          if (res) {
            switch (res.errcode) {
              case 401:

                if (this.apiResponseGErrorStatus) {
                  this.formSubmitErrorMsg = this.apiResponseGError.field_guest_incorrect_login_erro;
                }
                this.formSubmitFailStatus = true;
                break;
              case 1002:

                if (this.apiResponseGErrorStatus) {
                  this.formSubmitErrorMsg = this.apiResponseGError.field_account_lock_error;
                }
                this.formSubmitFailStatus = true;
                break;
              case 1001:

                if (this.apiResponseGErrorStatus) {
                  this.formSubmitErrorMsg = this.apiResponseGError.field_account_locked;
                }
                this.formSubmitFailStatus = true;
                break;
              /*case 408:
              if (this.apiResponseGErrorStatus) {
                if (this.apiResponseGError.field_user_not_found) {
                  this.formSubmitErrorMsg = this.apiResponseGError.field_user_not_found;
                }
              }
              this.formSubmitFailStatus = true;
              break;*/
              default:
                if (this.apiResponseGErrorStatus) {
                  this.formSubmitErrorMsg = this.apiResponseGError.field_login_submission_other_err;
                }
                this.formSubmitFailStatus = true;
                break;
            }
          } else {
            if (this.apiResponseGErrorStatus) {
              this.formSubmitErrorMsg = this.apiResponseGError.field_login_submission_other_err;
            }
            this.formSubmitFailStatus = true;
          }

        }

      },
      err => {
        console.log('err Guest Login Service');
        console.log(err);
        if (this.apiResponseGErrorStatus) {
          this.formSubmitErrorMsg = this.apiResponseGError.field_login_submission_other_err;
        }
        this.formSubmitFailStatus = true;
      }
    );



  }

  /* =====================================================
   click on join
  ======================================================== */
  guestJoinSubmit() {
    this.guestLoginSwal.nativeSwal.close();
  }

  /* ==================================================================
   Funtion for creating token for Guest login
 * =====================================================================*/
  guestLoginFormTokenFn() {

    this.rcycFormTokenService.formTokenService().subscribe(
      response => {

        this.guestLoginFormTokenTemp = response;
        const res = this.guestLoginFormTokenTemp;

        if (res && res.code === 200 && res.data.logInfo) {

          this.guestLoginFormToken = res.data.logInfo;

        } else {

          switch (res.errcode) {

            default:
              this.formSubmitFailStatus = true;
              break;
          }
        }

      },
      err => {

        this.formSubmitFailStatus = true;
        console.log('err  formTokenService Guest Registration');
        console.log(err);
      }
    );
  }

  // remove extra whitespace from form fields
  RemoveExtraSpace(value) {

    return value.replace(/\s+/g, ' ');
  }

  // remove starting whitespace from form fields
  RemoveStartSpace(str) {

    return str.replace(/^\s+/g, '');
  }

  /* ==================================================================
   Funtion for call drupal Api
 * =====================================================================*/
  loginContent() {

    /****************** Section C ********************/
    this.apiUrl_C = '/api/v1/guestregistration/content/c?_format=json';
    this.rcycInternalApisService.getDrupal(this.apiUrl_C).subscribe(
      response => {

        this.apiresponseCTemp = response;
        if (this.apiresponseCTemp && this.apiresponseCTemp.length) {

          const res = Object.keys(response).map(function (key) {
            return response[key];
          });
          if (res.length) {

            this.apiresponseC = res[0];
            if (this.apiresponseC && this.apiresponseC.field_image) {

              this.apiresponseC.field_image = this.IMG_BASE_URL + this.apiresponseC.field_image;
            }

            this.apiresponseCStatus = true;
          }

        }
      },
      err => {
        console.log('err  apiUrl_C');
        console.log(err);
      }
    );
  }
  /* =====================================================
   click on forgot password
  ======================================================== */
  forgotPwdClick() {
    this.guestLoginSwal.nativeSwal.close();
  }


  /* =====================================================
   Function to verify email after signup
  ======================================================== */

  rememberMe(event) {

    this.rememberMeCB = !this.rememberMeCB;

    // Store and remove data when remember me
    if (this.rememberMeCB) {
      const rememberMeData = {
        'userName': this.guestLogin.value.email,
        // 'password': this.guestLogin.value.password
      };
      const stringifiedLoginData = JSON.stringify(rememberMeData);

      localStorage.setItem('rememberMeData', stringifiedLoginData);

    } else {
      const rememberMeData = localStorage.getItem('rememberMeData');
      if (rememberMeData) {

        localStorage.removeItem('rememberMeData');
      }
    }

  }

  /* =====================================================
   Function to get Credential for remember me
  ======================================================== */
  checkRememberMe() {

    this.formSubmitErrorMsg = '';
    const rememberMeData = localStorage.getItem('rememberMeData');

    if (this.registrationToLoginPass && this.registrationToLoginPass.email) {

      // this.passemails = this.registrationToLoginPass.email;
      this.guestLogin.patchValue({ 'email': this.registrationToLoginPass.email });
      // this.emailErr = false;
    } else {
      if (rememberMeData) {
        this.rememberMeCB = true;
        const parsedRememberMeData = JSON.parse(rememberMeData);

        if (parsedRememberMeData.userName) {

          this.guestLogin.patchValue({ 'email': parsedRememberMeData.userName });
          this.guestLogin.controls['password'].reset();

        } else {
          this.guestLogin.controls['email'].reset();
          this.guestLogin.controls['password'].reset();
        }

      } else {
        this.guestLogin.controls['email'].reset();
        this.guestLogin.controls['password'].reset();
      }
    }

  }

}
