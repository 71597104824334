<div id="gdpr_modal" class="gdpr_modal">

  <!-- Modal content -->
  <div class="gdpr_modal-content">
     
    <p class="modal_content">
      The Ritz-Carlton Yacht Collection uses cookies on this website to personalize your experience, as well as to share information with our advertising and analytics partners. <a target="_blank" routerLink="/legal/eu-privacy-policy">Please review our privacy policy</a> to learn more about our use and sharing of personal data, and choose to accept or decline the use of cookies which may transmit personal data. By continuing to use this site, you agree to the use of additional cookies that do not collect or store personal data.
    </p>
        
    
    <div class="btn_sec">
      <button class="accept" (click)="accept_gdpr()">
        Accept
  </button>

  <button class="decline" (click)="decline_gdpr()">
        Decline
  </button>
    </div>
   
  </div>

</div>
