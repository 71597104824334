import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../rcyc-config/config';

@Injectable()
export class RcycRequestBrochureRedesignService {

    ELASTIC_API_BASE_URL: any = AppConfig.ELASTIC_API_BASE_URL;
    ELASTIC_API_BASE_URL_EBROCHURE: any = AppConfig.ELASTIC_API_BASE_URL_EBROCHURE;
    API_BASE_URL: any = AppConfig.API_BASE_URL;
    constructor(
        private http: HttpClient
    ) {

    }

    getHeadingData() {
        const url = this.API_BASE_URL + '/api/v1/requestbrochure/content/a?_format=json';
        return this.http.get(url);
    }

    getDrupalDataFor5050(URL) {
        const APIURL = this.API_BASE_URL + URL;
        return this.http.get(APIURL);
    }

}

