import { Injectable, NgZone } from '@angular/core';
import { RcycCommonLogoutService } from '../rcyc-logout/rcyc-logout.service';
import { RcycCommonService } from '../rcyc-common/rcyc-common.service';
import { Subject ,  Observable } from 'rxjs';

const MINUTES_UNITL_AUTO_LOGOUT = 15 // in Minutes 15
const CHECK_INTERVALL = 1000 // in ms
const STORE_KEY = 'lastAction';

@Injectable()
export class AutoLogoutService {
  userLoggenIn: boolean;
  currentUser: boolean;
  public autoLoginStatus = new Subject<boolean>();
  autoLoginStatusT = this.autoLoginStatus.asObservable();
  constructor(
    private ngZone: NgZone,
    private logAuth:RcycCommonLogoutService,
    private commonService:RcycCommonService,
  ) {
    this.commonService.loginStatus.subscribe(
      (status)=>{
        this.userLoggenIn = status;
        // console.log(status,'log status');
        if (localStorage.getItem('loggedUserDetails')){
          this.currentUser=true;
        }else{
          this.currentUser=false;
        }
      }
    )
    this.check();
    this.initListener();
    this.initInterval();
  }
  get lastAction() {
    return Number(localStorage.getItem(STORE_KEY));
  }
  set lastAction(value) {
    localStorage.setItem(STORE_KEY, String(value));
  }
  initListener() {
    this.reset()
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('keyup', () => this.reset());
      document.body.addEventListener('contextmenu', () => this.reset());
      document.body.addEventListener('mousemove', () => this.reset());
      document.body.addEventListener('touchstart', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    })
  }

  reset() {
    this.lastAction = Date.now();
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    //Need implementation here for auto log out check
    this.ngZone.run(() => {
      if (isTimeout && this.currentUser) {
        this.autoLoginStatus.next(true);
        this.logAuth.autoLogout();
      }
      else{
        this.autoLoginStatus.next(false);
      }
    });
  }
}
