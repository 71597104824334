<a *ngIf="variant!='hub'" (click)="onSlideClick(data.url)" (mouseover)="onHover()"
  [class]="cssImageScale ? 'group hover:text-medium-blue pb-1 hover:no-underline':'group hover:text-medium-blue pb-1 !no-underline'"
  (mouseout)="onBlur()" (click)="clearCompareValue(data)" (keyup.enter)="clearCompareValue(data?.url)">
  <div class="font-caslon lg:mb-1 text-primary group-hover:text-medium-blue flex items-center" *ngIf="data.heading"
    [ngClass]="{
        'text-[29px] lg:text-[39px]': variant === 'experience',
        'text-[26px] lg:text-[39px]': variant !== 'experience',
      }">
    {{data.heading}}
    <img *ngIf="data.depPosition === 'Anchored'" class="ml-3 -mt-[8px] lg:-mt-[18px]" src="/assets/img/anchor.svg"
      alt="">
  </div>
  <div [ngClass]="{
        'flex lg:block': variant === 'experience',
      }">
    <div class="relative overflow-hidden" *ngIf="data.image?.src" [ngClass]="{
           'aspect-square w-[94px] lg:w-full mb-auto lg:mb-7 mr-4 lg:mr-0': variant === 'experience',
           'aspect-[1/1] mb-7': variant !== 'experience',
         }">
      <img [src]="data.image.src"
        [class]="cssImageScale ? '!w-auto transition fill-image transition-all duration-[500ms] fill-image-hover' : '!w-auto transition fill-image transition-all duration-[500ms]'"
        [title]="data.image.title | convertHtml" [alt]="data.image.alt | convertHtml">
    </div>
    <div>
      <div *ngIf="data.eyebrow && topcontent && topcontent !== 'voyage itinerary'"
        class="uppercase text-sm mb-2 lg:mb-4 text-primary group-hover:text-medium-blue">
        {{data.eyebrow}}
      </div>
      <div *ngIf="data.title"
        [class]="cssImageScale ? 'h5 text-primary group-hover:text-medium-blue group-hover:underline' : 'h5 text-primary'"
        [ngClass]="{
           '-mt-[6px] lg:mt-0 lg:mb-4 uppercase': variant === 'experience',
           'mb-4': variant !== 'experience',
           'group-hover:text-[30px] transition-all duration-[500ms]':!isMobile,
         }">
        {{ data.title | convertHtml }}
      </div>
      <div *ngIf="data.text" [innerHTML]="data.text"
        class="flex flex-col gap-1 lg:gap-2 mb-4 lg:mb-7 group-hover:text-medium-blue group-hover:[&>p]:text-medium-blue">
      </div>
      <div *ngIf="data.date || data.departure || data.arival" class="pt-3">
        <div *ngIf='data.date' class="uppercase mb-2 lg:mb-4 text-primary group-hover:text-medium-blue" [ngClass]="{
               'text-base lg:text-sm': variant === 'experience',
               'text-[16px]': variant !== 'experience',
             }">
          <span *ngIf="data?.arrivalTime">
            {{ data?.arrivalTime | date:"fullDate":"UTC" }}
          </span>
          <span *ngIf="!data?.arrivalTime">
            {{ data?.departureTime | date:"fullDate":"UTC" }}
          </span>
        </div>
        <div *ngIf='data.arrivalTime && ( (data?.arrivalTime | date:"HH:mm":"UTC") !== "00:00")'
          class="uppercase mb-2 lg:mb-4 text-primary group-hover:text-medium-blue" [ngClass]="{
               'text-base lg:text-sm': variant === 'experience',
               'text-[16px]': variant !== 'experience',
             }">
          ARRIVAL: {{ data?.arrivalTime | date:"HH:mm":"UTC" }}
        </div>
        <div *ngIf='data.departureTime && ( (data?.departureTime | date:"HH:mm":"UTC") !== "00:00")'
          class="uppercase mb-2 lg:mb-4 text-primary group-hover:text-medium-blue" [ngClass]="{
               'text-base lg:text-sm': variant === 'experience',
               'text-[16px]': variant !== 'experience',
             }">
          DEPARTURE: {{ data?.departureTime | date:"HH:mm":"UTC" }}
        </div>
      </div>
    </div>
  </div>
</a>

<a *ngIf="variant=='hub'" class="group hover:text-medium-blue hover:no-underline pb-1">
  <div>
    <div class="relative overflow-hidden" *ngIf="data.image?.src" [ngClass]="{
           'aspect-square w-[94px] lg:w-full mb-auto lg:mb-7 mr-4 lg:mr-0': variant === 'experience',
           'aspect-[1/1] mb-7': variant !== 'experience',
         }">
      <img [src]="data.image.src" class="!w-auto group-hover:scale-[1.1] transition fit-image"
        [title]="data.image.title | convertHtml" [alt]="data.image.alt | convertHtml">
    </div>

    <div>
      <div *ngIf="data.url"
        class="font-caslon text-[20px] lg:text-[25px] leading-[23px] lg:leading-[28px] uppercase text-sm mb-2 lg:mb-4 text-primary group-hover:text-medium-blue">
        {{data.url}}
      </div>
      <div *ngIf="data.description" [innerHTML]="data.description">
      </div>
    </div>
  </div>
</a>