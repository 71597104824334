import { Component, OnInit, ViewEncapsulation, ViewChild, Input, ElementRef } from '@angular/core';
import { RcycCommonService } from '../../rcyc-services/rcyc-common/rcyc-common.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RcycRequestCallService } from './rcyc-request-call.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as EmailValidator from 'email-validator';
import { RcycFormTokenService } from '../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { RcycErrorModalComponent } from '../../rcyc-modals/rcyc-error-modal/rcyc-error.component';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { RcycHomeService } from '../rcyc-home/rcyc-home.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { ValidatePhoneNumber,PhoneDataFormat,deepPhoneConfiguration,formatPhoneList } from '../../rcyc-share-components/rcyc-phonenumber-validator/rcyc-phonenumber-validator';
// import {CookiesService} from "@ngx-utils/cookies";
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-rcyc-request-call-bform',
  templateUrl: './rcyc-request-call-bform.component.html',
  styleUrls: ['./rcyc-request-call-bform.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class RcycRequestCallComponentBform implements OnInit {



  @ViewChild('errorModal') errorModal: RcycErrorModalComponent;
  @Input() specialOfferExpandFlag;
  userAreaDetails: any;
  userArea: any;
  lead_gen_form: UntypedFormGroup;
  phNumReqStatusDynamic: boolean;
  phoneDataArray: PhoneDataFormat[];
  formttedPhoneList : any={};
  userCountryDetails: PhoneDataFormat;
  requestCallFormToken: any;
  apiResponseA: any;
  apiResponseB: any;
  apiResponseC: any;
  apiUrlMetatag: any;
  flagCodeClickStatus: boolean;
  countrySearch: string;
  requestCallFormResponse: Object;
  formSubmitFailStatus: boolean;
  permanentGeoLocation: any;
  zipcodeReqStatusDynamic = false;
  countryListArray: any = [];

  tokenErrorMsg: any = this.rcycFormTokenService.tokenErrorMsg;
  apiUrlD: any;
  apiResponseDTemp: any = [];
  maxLength:number=10;
  zipcodeMaxLength: number=10;

  snapshotUrl: any;
  currentUrlPathAsArray: any;
  currentUrlPath: any;
  isRequestCallStatus=false;
  specialCountryInputClickStatus=false;
  specialCountryInputCloseBtnStatus=false;
  isSpecialCountrySelect=false;
  selectCountryCode:string;
  mask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  specialOfferFormErrorMsg:string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private rcycCommonService: RcycCommonService,
    private http: HttpClient,
    private rcycRequestCallService: RcycRequestCallService,
    private router: Router,
    private rcycFormTokenService: RcycFormTokenService,
    private scrollToService: ScrollToService,
    private rcycHomeService: RcycHomeService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private cookies: CookieService,
    private rcycInternalApisService: RcycInternalApisService,
  ) {

    this.snapshotUrl = this.activatedRoute.snapshot as any;
    this.currentUrlPathAsArray = this.snapshotUrl._urlSegment.segments;
    this.currentUrlPath = this.currentUrlPathAsArray[0].path;
    if(this.currentUrlPath == 'request-call' || 'request-call-bform') {
      this.isRequestCallStatus = true;
    }
    
  }
  ngOnInit() {
    this.reqCallConstructorFn();
    this.createFormRequestCall();
    this.getUserAreaCode();   
    this.rcycRequestACallContent();  
    const userData = JSON.parse(localStorage.getItem('loggedUserDetails'));
    if (userData && userData.jwtToken) {
      this.getMyProfileDetails();
    }
  }
  getUserAreaCode() {
    // Subscriber to get User Area
    this.rcycCommonService.userAreaCode.subscribe((userAreaDetails) => {
      if (userAreaDetails.length) {
        this.userAreaDetails = userAreaDetails[0];
        this.userArea = this.userAreaDetails.field_area_code;        
      }
    });
  }
  createFormRequestCall(){
    this.lead_gen_form = this.formBuilder.group({
        firstname: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        lastname: ['', [Validators.required,Validators.pattern('[a-zA-ZÀ-ÿ-.\'_ ]*')]],
        email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-.]{2,})+$')]],
        country: ['', Validators.required],
        countryCode: ['US', Validators.required],
        zipcode: [''],
        phone: ['', Validators.required],
        comment: [''],
        isAgreeToReceiveMail: [false],
        isAgreeToContact: [false],
        dialCode: ['']
    });
    this.formValueChangeCapture();
    this.http.get('./assets/select_country.json').subscribe((data) => {
      this.countryListArray =  data;
    });
    this.http.get('./assets/rescoNumberFormatted.json').subscribe((phoneDataArray: PhoneDataFormat[]) => {
      this.phoneDataArray = phoneDataArray;
      this.formttedPhoneList = formatPhoneList(phoneDataArray);
      this.deepPhoneConfigurations();           
    });
    
    
  }
   /* ===================================================
  Function for value  change of form
  =======================================================*/
  formValueChangeCapture() {   
    this.lead_gen_form.get('phone').setValidators([Validators.required, ValidatePhoneNumber]); 
    this.lead_gen_form.get('phone').updateValueAndValidity();
    if(!this.isRequestCallStatus) {
      this.lead_gen_form.valueChanges.subscribe((val) => {
        this.specialOfferFormErrorMsg='';
        this.formSubmitFailStatus = false;
      });
      this.lead_gen_form.get('country').valueChanges.subscribe((val) => {

        // Editing country field
        this.selectCountryCode='';
        this.specialCountryInputClickStatus=true;
        this.specialCountryInputCloseBtnStatus=true
        this.isSpecialCountrySelect=false;
      });     
    } else {
      this.lead_gen_form.valueChanges.subscribe((val) => {        
        this.formSubmitFailStatus = false;
      });
      this.lead_gen_form.get('country').valueChanges.subscribe((val) => {
        this.countryValueChangeFn(val);
      });
    }
  }

  deepPhoneConfigurations() {
    let res:{formttedPhoneList:any,userCountryDetails: PhoneDataFormat,phoneDataArray:PhoneDataFormat[]} = deepPhoneConfiguration(this.phoneDataArray,this.formttedPhoneList);
      this.formttedPhoneList=res.formttedPhoneList;
      this.userCountryDetails=res.userCountryDetails;
      this.phoneDataArray = res.phoneDataArray;
      this.permanentGeoLocation=Object.assign({},this.userCountryDetails);
      this.lead_gen_form.get('countryCode').setValue(this.userCountryDetails.code);
      this.lead_gen_form.get('dialCode').setValue(this.userCountryDetails.dial_code);
  }

  /* ===================================================
  Function for select country click
  =======================================================*/
  selectCountryClick(item) {
    this.lead_gen_form.get('phone').setValue('');
    this.lead_gen_form.get('countryCode').setValue(item.code);
    this.lead_gen_form.get('dialCode').setValue(item.dial_code);
    // this.getAreaCodeOnCountrySelect(item.code);
    this.userCountryDetails.dial_code = item.dial_code;
    this.userCountryDetails.code = item.code;
    this.userCountryDetails.flagUrl = item.flagUrl;
    this.userCountryDetails.ph_length = +item.ph_length + 5;
    this.userCountryDetails.defaultFormat = item.defaultFormat;
    this.userCountryDetails.defaultPhoneNumber = item.defaultPhoneNumber;
    this.flagCodeClickStatus = false;
    this.countrySearch = "";
  }

  onClickedOutside(e: Event) {
    this.flagCodeClickStatus = false;
  }

  /* ===================================================================
    Functions to call all functions in request call
  ====================================================================== */
  reqCallConstructorFn() {
    // Changing subheader A value
    if (this.specialOfferExpandFlag) {

    } else {
      if(this.isRequestCallStatus) {
        // this.rcycCommonService.changeSubHeaderDataArray_A({});
        this.rcycCommonService.changeSubHeaderStatus(true);
        // Call get Metatag function
        this.apiUrlMetatag = '/api/v1/meta/requestcall?_format=json';
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);        
      }
    }

    // Function for Call form token generation
    this.requestCallFormTokenFn();

  }
  // get formCall() {
  //   return this.lead_gen_form.controls
  // }

  
 
 
  /* =======================================================================================================================================
    Function to call all  Request a Call APIs
  ======================================================================================================================================= */

  rcycRequestACallContent() {

    
    if (this.specialOfferExpandFlag) {

    } else {
      if(this.isRequestCallStatus) {
        /****************** Section Metatag********************/
        this.rcycCommonService.getMetaTags(this.apiUrlMetatag);
        
        /****************** Section A********************/
        let apiUrlA = '/api/v1/requestcall/header/a?_format=json';
        this.rcycRequestCallService.requestACallDrupal(apiUrlA).subscribe(
          (response:any) => {
            if (response && response.length) {   
                this.apiResponseA = response[0];
                // Changing subheader A value
                this.rcycCommonService.changeSubHeaderDataArray_A(this.apiResponseA);            
              
            } else {
              // Changing subheader A value
              this.rcycCommonService.changeSubHeaderDataArray_A({});
            }

          },
          err => {
            console.log('Section A Request Call Api A error');
            console.log(err);
          }
        );
      }
    }

    /****************** Section B********************/
    let apiUrlB = '/api/v1/requestcall/content/b?_format=json';
    this.rcycRequestCallService.requestACallDrupal(apiUrlB).subscribe(
      (response:any) => {         
          if (response && response.length) {
            this.apiResponseB = response[0];
          }  
      },
      err => {
        console.log('Section B Request Call Api B error');
        console.log(err);
      }
    );

    /****************** Section C********************/
    let apiUrlC = '/api/v1/requestcall/terms?_format=json';
    this.rcycRequestCallService.requestACallDrupal(apiUrlC).subscribe(
      (response:any) => {
        if (response && response.length) {
          // var res = Object.values(response);
            this.apiResponseC = response[0];
            // this.apiResponseCStatus = true;
        }
      },
      err => {
        console.log('Section C Request Call Api C error');
        console.log(err);
      }
    );
  }
  /* ======================================================================================================================================
    Funtion for creating token for request Call
  * ======================================================================================================================================*/
  requestCallFormTokenFn() {

    this.rcycFormTokenService.formTokenService().subscribe(
      (response:any) => {
        if (response && response.code === 200 && response.data.logInfo) {
          this.requestCallFormToken = response.data.logInfo;
        }
      },
      err => {
        console.log('err  formTokenService Request Call');
        console.log(err);
      }
    );
  }

  // method to display modal
  shownErrorModal() {

    const modalContents = this.rcycFormTokenService.modalService('request-call');
    this.errorModal.modalTitle  = modalContents.title;
    this.errorModal.modalContent  = modalContents.modalContent;
    this.errorModal.routerUrl = modalContents.routerUrl;
    this.errorModal.modalShow();
  }
   /* =========================================================================================================================================
    Function to Submit form in Request Brochure
  ======================================================================================================================================= */
  leadGenSubmitApiCall() {
    let apiUrl_requestCall_Submit = '/secureleadapi/req-call';
    // let phoneNumber = this.userCountryDetails.dial_code + this.lead_gen_form.value.phone.replace(/ /g, "");
    // if(!this.lead_gen_form.value.phone.replace(/ /g, "")) {
    //   phoneNumber = "";
    // }  
    let dial_code:string = this.userCountryDetails.dial_code;

    // if(dial_code.indexOf('+1') !== -1) {
    //   dial_code= '+1';
    // }
    let phoneNumber;
    if(!this.lead_gen_form.value.phone.replace(/ /g, "")) {
      phoneNumber="";
    } else {
      phoneNumber = dial_code+this.lead_gen_form.value.phone.replace(/ /g, "");
    }
    
    let requestData:any={};
    requestData = {
      // 'country': this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '',
      'area': (this.userArea) ? this.userArea : '',
      'email': this.lead_gen_form.value.email.trim(),
      'firstname': this.lead_gen_form.value.firstname.trim(),
      'lastname': this.lead_gen_form.value.lastname.trim(),
      'phone': phoneNumber,
      'zipcode': this.lead_gen_form.value.zipcode.trim(),
      'emailOpt': this.lead_gen_form.value.isAgreeToReceiveMail,
      'phoneOpt': this.lead_gen_form.value.isAgreeToContact,
      'comment': this.lead_gen_form.value.comment.trim(),
      'type': 'call',
      'utmSource': this.cookies.get('utm_source'),
      'utmMedium': this.cookies.get('utm_medium'),
      'utmCampaign': this.cookies.get('utm_campaign'),
      'internalSource': this.cookies.get('int_source'),
      'internalCampaign': this.cookies.get('int_campaign'),
      'folId': this.cookies.get('fol_num')
    };
    if(!this.isRequestCallStatus) {
      requestData['comment'] = '';
      requestData['country'] = this.selectCountryCode;     
    } else {
      requestData['comment'] = this.lead_gen_form.value.comment? this.lead_gen_form.value.comment.trim(): '';
      requestData['country'] = this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '';
    }

    // const data = {
    //   'googleId': (this.requestCallFormToken) ? this.requestCallFormToken : '',
    //   'country': this.lead_gen_form.value.country ? this.lead_gen_form.value.country : '',
    //   'area': (this.userArea) ? this.userArea : '',
    //   'email': this.lead_gen_form.value.email.trim(),
    //   'firstname': this.lead_gen_form.value.firstname.trim(),
    //   'lastname': this.lead_gen_form.value.lastname.trim(),
    //   'phone': phoneNumber,
    //   'zipcode': this.lead_gen_form.value.zipcode.trim(),
    //   'emailOpt': this.lead_gen_form.value.isAgreeToReceiveMail,
    //   'phoneOpt': this.lead_gen_form.value.isAgreeToContact,
    //   'comment': this.lead_gen_form.value.comment.trim(),
    //   'type': 'call',
    // };
       /****************** Section RequestQuote Submission********************/
    this.rcycRequestCallService.requestCallFormData(apiUrl_requestCall_Submit, requestData, this.requestCallFormToken).subscribe(
      (response:any) => {
        if (response && response.code === 200) {
          this.formSubmitFailStatus = false;
          this.router.navigate(['/complete'], { queryParams: { success: 'rac' } });
        } else if ( response && ( response.code === 403 || response.code === 401 || response.code === 400 || response.code === 500 ) ) {

          this.formSubmitFailStatus = true;
          this.reqCallConstructorFn();
          this.getUserAreaCode(); 
         // window.scrollTo(0, 0);
         document.querySelector('.page-title').scrollIntoView();
        }
      }, err => {

        console.log(err);
    });
  }
  /* ==================================================================================================================
    Function to get Area code on Country Select
  =====================================================================================================================*/
  getAreaCodeOnCountrySelect(cntrycode) {
    // console.log(cntrycode, 'ewtwwe');
    if (cntrycode) {
      let apiUrlD = '/api/v1/countryphone/' + cntrycode + '?_format=json';
      this.rcycHomeService.home(apiUrlD).subscribe(
        (response:any=[]) => {
          this.rcycCommonService.userAreaDetails(response);          
          this.getUserAreaCode(); 
        },
        err => {
          console.log('Error in Getting Area', err);
        }
      );
    }
  }


  /* ===================================================
     Function for zipcode format
   =======================================================*/
   checkZipcodeFormat(input) {

    let countryCode=this.lead_gen_form.get('country').value;
    if(!this.isRequestCallStatus) {
      const countryName=this.lead_gen_form.get('country').value;
      countryCode=this.countryListArray.filter(p => p.name === countryName)[0].code;
    }
    if(countryCode&&countryCode.toUpperCase()==="US") {

      if(isNaN(input.data)) {
        input.srcElement.value=input.srcElement.value.replace(input.data,'');
      }
      const position=input.target.selectionStart;
      let value=input.srcElement.value;
      value=value.replace(/-/g,'');
      if(value.length>5)  {
        input.srcElement.value=[value.slice(0, 5), '-', value.slice(5)].join('');
      }
      this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      input.target.selectionStart=(position>5)?position+1:position;
      input.target.selectionEnd=(position>5)?position+1:position;
    } else if(countryCode&&countryCode.toUpperCase()==="CA") {

      if (input.data && input.data == input.data.toLowerCase()){
        input.srcElement.value=input.srcElement.value.replace(input.data,input.data.toUpperCase());
      }
      let value=input.srcElement.value;
      value=value.replace(' ','');
      if(value.length>3)  {
        input.srcElement.value=[value.slice(0, 3), ' ', value.slice(3)].join('');
        this.lead_gen_form.get('zipcode').setValue(input.srcElement.value);
      }
      if(this.lead_gen_form.get('zipcode').value.length>7){
        this.lead_gen_form.get('zipcode').setValue(this.lead_gen_form.get('zipcode').value.substring(0,7));
      }
    } else{
      
      let value= input.srcElement.value.replace(/  /g, ' ');
      this.lead_gen_form.get('zipcode').setValue(value.replace(/--/g, '-'));
    }
  }

  inputIconCloseClick(item) {
    // switch(item) {
    //   case 'firstname':
      this.lead_gen_form.get(item).setValue('');
    //   break;

    // }
  }

  specialCountryLiClick(item) {
    this.selectCountryClick(item);
    this.lead_gen_form.get('country').setValue(item.name);
    this.specialCountryInputClickStatus=false;
    this.specialCountryInputCloseBtnStatus=false;
    this.isSpecialCountrySelect=true;
    this.countryValueChangeFn(item.name);
  }
  specialCountryInputCloseClick() {
    this.lead_gen_form.get('country').setValue('');
    this.specialCountryInputCloseBtnStatus=false;
    this.countryValueChangeFn(this.lead_gen_form.get('country').value);
  }

  countryValueChangeFn(val) {

      if(!this.isRequestCallStatus) {      
        if (val) {
          val=this.countryListArray.filter(p => p.name === val)[0].code;
          this.selectCountryCode=val;
        }            
      }
      this.zipcodeReqStatusDynamic = false;
      let zipCodeValidation: any;
      this.zipcodeMaxLength=10;
      if(val)
      {
        this.getAreaCodeOnCountrySelect(val);
        this.userCountryDetails=Object.assign({},this.formttedPhoneList[val]);
        this.userCountryDetails.ph_length = Number(this.formttedPhoneList[val].ph_length) + 5
        // this.lead_gen_form.get('phone').setValue('');
        this.lead_gen_form.get('countryCode').setValue(val);
        this.lead_gen_form.get('dialCode').setValue(this.formttedPhoneList[val].dial_code);
      }
      else{
        this.getAreaCodeOnCountrySelect(this.permanentGeoLocation.code);
        this.userCountryDetails = Object.assign({},this.permanentGeoLocation);
        this.userCountryDetails.ph_length = Number(this.permanentGeoLocation.ph_length);
      }
      if (val === 'US') {
        zipCodeValidation = /^[0-9]{5}(?:-[0-9]{4})?$/;
        this.lead_gen_form.get('isAgreeToContact').setValue(true);
        this.lead_gen_form.get('isAgreeToReceiveMail').setValue(true);
        this.zipcodeReqStatusDynamic = true;
        // this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber,Validators.required]); 
        // this.lead_gen_form.get('phone').updateValueAndValidity(); 
      } else {
        if (val === 'CA') {
          this.zipcodeMaxLength=7;
          zipCodeValidation = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
          this.zipcodeReqStatusDynamic = true;
          // this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber,Validators.required]); 
          // this.lead_gen_form.get('phone').updateValueAndValidity(); 
        }
        // } else {
        //   this.lead_gen_form.get('phone').setValidators([ValidatePhoneNumber]); 
        //   this.lead_gen_form.get('phone').updateValueAndValidity(); 
        // }
        this.lead_gen_form.get('isAgreeToContact').setValue(false);
        this.lead_gen_form.get('isAgreeToReceiveMail').setValue(false);
      }       
      if (zipCodeValidation) {
        this.lead_gen_form.get('zipcode').setValidators([Validators.required, Validators.pattern(zipCodeValidation)]);
        this.lead_gen_form.get('zipcode').updateValueAndValidity();
      } else {
        this.lead_gen_form.get('zipcode').setValidators(null);
        this.lead_gen_form.get('zipcode').updateValueAndValidity();
      }
  }

  // Lead Gen Btn Submit
  leadGenSubmit() {
    this.specialOfferFormErrorMsg='';
    if(this.isRequestCallStatus) {
      this.leadGenSubmitApiCall();
    } else {      
      if(
        this.lead_gen_form.controls.firstname.invalid||
        this.lead_gen_form.controls.lastname.invalid||
        this.lead_gen_form.controls.country.invalid||
        this.lead_gen_form.controls.zipcode.invalid||
        this.lead_gen_form.controls.email.invalid||
        (
          this.lead_gen_form.controls.phone&&this.lead_gen_form.controls.phone.errors&&
          this.lead_gen_form.controls.phone.errors.phoneIncorrect!=false
        )||
        !this.isSpecialCountrySelect||
        !this.userArea
        ) {          
          this.specialOfferFormErrorMsg='Please fill all mandatory fields';
          // setTimeout(() => {
          //   const invalidControl = this.elementRef.nativeElement.querySelector('.error-borderBtm');           
          //   if (invalidControl) {            
          //     invalidControl.focus();
          //   }
          // }, 500);
          document.querySelector('.page-title').scrollIntoView();
      } else {        
        this.leadGenSubmitApiCall();
      }
    }
   
  }
  getMyProfileDetails() {
    const param = {
    };
    const apiUrl = '/secureapi/getMyProfileDetails';
    this.rcycInternalApisService.postReservationFormWithAuth(apiUrl, param).subscribe(
      res  => {
        const data: any = res;
        this.lead_gen_form.patchValue({
          'firstname' : data.data.forename,
          'lastname' : data.data.surname,
          'country': data.data.country,
          'zipcode' : data.data.postalCode,
          'email' : data.data.email1
        });
        if (data.data.phone1) {
          let phoneWOdialcode=data.data.phone1.replace(this.userCountryDetails.dial_code, '');
          this.lead_gen_form.patchValue({ 'phone': phoneWOdialcode });     

          if(this.lead_gen_form.controls.phone.errors.getPhoneCountryCode) {             
            let getPhoneCountryCode=this.lead_gen_form.controls.phone.errors.getPhoneCountryCode;
            this.lead_gen_form.get('countryCode').setValue(getPhoneCountryCode);
            this.lead_gen_form.get('dialCode').setValue(this.formttedPhoneList[getPhoneCountryCode].dial_code);
            this.userCountryDetails.dial_code = this.formttedPhoneList[getPhoneCountryCode].dial_code;
            this.userCountryDetails.code = getPhoneCountryCode;
            this.userCountryDetails.flagUrl= this.formttedPhoneList[getPhoneCountryCode].flagUrl;
            this.userCountryDetails.defaultFormat = this.formttedPhoneList[getPhoneCountryCode].defaultFormat;
            this.userCountryDetails.defaultPhoneNumber = this.formttedPhoneList[getPhoneCountryCode].defaultPhoneNumber;
            let phoneWOdialcodeSecond=data.data.phone1.replace(this.formttedPhoneList[getPhoneCountryCode].dial_code, '');
            this.lead_gen_form.patchValue({ 'phone': phoneWOdialcodeSecond });  
          }
        }
      },
      err=>{
        console.log(err)
      })
    }

}
