import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { RcycHiddenSalesforceFormService } from '../../rcyc-services/rcyc-salesforce/rcyc-hidden-salesforce-form.service';
import { RcycInternalApisService } from '../../rcyc-services/rcyc-internal-apis/rcyc-internal-apis.service';
import { RcycFormTokenService } from '../../rcyc-services/rcyc-form-token/rcyc-form-token.service';
import { AppConfig } from '../../rcyc-config/config';
import { RcycCommonService } from "../../rcyc-services/rcyc-common/rcyc-common.service";

declare let ga: Function;

@Injectable({
    providedIn: 'root'
})
export class RcycBookingConfigService {
    private bookingConfigSharedData: any;
    private salesForceImplementation: boolean = AppConfig.salesForceImplementation;

    constructor(
        private rcycInternalApisService: RcycInternalApisService,
        private rcycHiddenSalesforceFormService: RcycHiddenSalesforceFormService,
        private rcycFormTokenService: RcycFormTokenService,
        private cookies: CookieService,
        private rcycCommonService: RcycCommonService,
        private router: Router,
    ) { }

    public getBookingConfigData() {
        return this.bookingConfigSharedData;
    }

    public setBookingConfigData(data) {
        this.bookingConfigSharedData = { ...this.bookingConfigSharedData, ...data };
    }

    /* =========================================================================================================================================
       Function to Submit form in Request Brochure
     ======================================================================================================================================= */
    private leadGenSubmitApiCall(quoteConfigSubmitFormToken) {
        const preferredContacts = (this.bookingConfigSharedData?.preferredContacts && this.bookingConfigSharedData?.preferredContacts.length > 0) ? this.bookingConfigSharedData?.preferredContacts.join(';') : '';
        const tripAddOnsRequested = (this.bookingConfigSharedData?.tripAddOnsRequested && this.bookingConfigSharedData?.tripAddOnsRequested.length > 0) ? this.bookingConfigSharedData?.tripAddOnsRequested.join(';') : '';
        this.bookingConfigSharedData.Yacht_Name__c = this.bookingConfigSharedData?.Yacht_Name__c?.toLowerCase();
        const yachtName = this.bookingConfigSharedData?.Yacht_Name__c?.charAt(0).toUpperCase() + this.bookingConfigSharedData?.Yacht_Name__c?.slice(1);
        const guestCount = (this.bookingConfigSharedData?.adult + this.bookingConfigSharedData?.child);

        let child1AgeBelowThree: boolean = false;
        let child2AgeBelowThree: boolean = false;
        if (this.bookingConfigSharedData?.child && this.bookingConfigSharedData?.child > 0 && this.bookingConfigSharedData?.childrensAgeDetails && this.bookingConfigSharedData?.childrensAgeDetails.length > 0) {
            if (this.bookingConfigSharedData?.child >= 1) {
                child1AgeBelowThree = this.bookingConfigSharedData?.childrensAgeDetails[0].ageUnderThreeYear;
            }
            if (this.bookingConfigSharedData?.child === 2) {
                child2AgeBelowThree = this.bookingConfigSharedData?.childrensAgeDetails[1].ageUnderThreeYear;
            }
        }

        let areaCode: string = null;
        if (this.bookingConfigSharedData?.area === 'US') {
            areaCode = 'US United States';
        } else if (this.bookingConfigSharedData?.area === 'EU') {
            areaCode = 'EU European Union';
        } else if (this.bookingConfigSharedData?.area === 'ASIA') {
            areaCode = 'ASIA Asia Pacific';
        }

        const requestData = {
            'firstname': this.bookingConfigSharedData?.first_name,
            'Middle_Name__c': this.bookingConfigSharedData?.middle_name,
            'lastname': this.bookingConfigSharedData?.last_name,
            'email': this.bookingConfigSharedData?.email,
            'phone': this.bookingConfigSharedData?.phone,
            'dialcode': this.bookingConfigSharedData?.dialCode,
            'zip': this.bookingConfigSharedData?.zip,
            'area': areaCode,
            'country_code': this.bookingConfigSharedData?.country_code,
            'Phone_Opt_In__c': this.bookingConfigSharedData?.Phone_Opt_In__c,
            'Email_Opt_In__c': this.bookingConfigSharedData?.Email_Opt_In__c,
            'preferredContact': preferredContacts,
            'specialOccasion': this.bookingConfigSharedData?.Special_Occasion,
            'tripAddOnsRequested': tripAddOnsRequested,
            'yacht': yachtName,
            'route': this.bookingConfigSharedData?.Voyage_Name__c,
            'departure': this.bookingConfigSharedData?.Voyage_Date__c,
            'duration': this.bookingConfigSharedData?.noOfVoyageNights,
            'accommodation': this.bookingConfigSharedData?.selectedSuite,
            'guests': guestCount,
            'pricePerGuest': this.bookingConfigSharedData?.pricePerGuest,
            'bonvoyMember': this.bookingConfigSharedData?.isBonvoyMember,
            'totalFare': this.bookingConfigSharedData?.finalBookingPrice,
            'voyageID': this.bookingConfigSharedData?.Voyage__c,
            'additionalNotes': this.bookingConfigSharedData?.Comments__c,
            'child1Age': child1AgeBelowThree,
            'child2Age': child2AgeBelowThree,
            'Accessibility_Room__c': this.bookingConfigSharedData?.Accessibility_Room__c,
        };

        /****************** Section RequestQuote Submission********************/
        const apiUrlQuoteConfigSubmit = '/secureleadapi/quote-configurator';
        this.rcycInternalApisService.postSecureEbrochureForm(apiUrlQuoteConfigSubmit, requestData, quoteConfigSubmitFormToken).subscribe(response => {
            if (!this.salesForceImplementation) {
                // Change The value of Flag (salesForceImplementation) in env to execute handling of post LeadGenForm submission to resco - and Discontinue W2L salesforce submit
                const res: any = response;
                if (res && res.code === 200) {
                    let leadId = (res.data && res.data.leadId) ? res.data.leadId : '';
                    ga('create', 'UA-114428670-1', 'auto');
                    ga('require', 'ecommerce', 'ecommerce.js');
                    ga('ecommerce:addItem', {
                        'id': leadId,
                        'name': 'quoteconfigurator'
                    });
                    ga('ecommerce:send');
                    ga('send', 'pageview')
                }
            }
        }, err => {
            console.log(err);
        });

        if (this.salesForceImplementation) {
            // Salesforce Web to Lead - W2L START
            const salesForceRequestData = {
                'Lead_Type__c': this.bookingConfigSharedData?.Lead_Type__c,
                'first_name': this.bookingConfigSharedData?.first_name,
                'Middle_Name__c': this.bookingConfigSharedData?.middle_name,
                'last_name': this.bookingConfigSharedData?.last_name,
                'email': this.bookingConfigSharedData?.email,
                'phone': this.bookingConfigSharedData?.phone,
                'dialcode': this.bookingConfigSharedData?.dialCode,
                'zip': this.bookingConfigSharedData?.zip,
                'Area__c': areaCode,
                'country_code': this.bookingConfigSharedData?.country_code,
                'Voyage_Name__c': this.bookingConfigSharedData?.Voyage_Name__c,
                'Voyage_Date__c': this.bookingConfigSharedData?.Voyage_Date__c,
                'Voyage_ID__c': this.bookingConfigSharedData?.Voyage__c,
                'Voyage__c': this.bookingConfigSharedData?.Voyage__c,
                'Yacht_Name__c': yachtName,
                'Yacht__c': yachtName,
                'Route__c': this.bookingConfigSharedData?.Voyage_Name__c,
                'Departure_Date__c': this.bookingConfigSharedData?.Voyage_Date__c,
                'Phone_Opt_In__c': this.bookingConfigSharedData?.Phone_Opt_In__c,
                'Email_Opt_In__c': this.bookingConfigSharedData?.Email_Opt_In__c,
                'Preferred_Contact__c': preferredContacts,
                'Special_Occasion__c': this.bookingConfigSharedData?.Special_Occasion,
                'Trip_Add_Ons_Requested__c': tripAddOnsRequested,
                'Duration__c': this.bookingConfigSharedData?.noOfVoyageNights,
                'Accommodation__c': this.bookingConfigSharedData?.selectedSuite,
                'Guests__c': guestCount,
                'Price_per_Guest__c': this.bookingConfigSharedData?.pricePerGuest,
                'Bonvoy_Member__c': this.bookingConfigSharedData?.isBonvoyMember,
                'Total_Fare__c': this.bookingConfigSharedData?.finalBookingPrice,
                'Additional_Notes__c': this.bookingConfigSharedData?.Comments__c,
                'Child_Age__c': child1AgeBelowThree,
                'Child_Age_2__c': child2AgeBelowThree,
                'X3_and_Under__c': child1AgeBelowThree,
                'X2nd_Child_under_3__c': child2AgeBelowThree,
                'Accessibility_Room__c': this.bookingConfigSharedData?.Accessibility_Room__c,
                'UTM_Source__c': this.cookies.get('utm_source'),
                'UTM_Medium__c': this.cookies.get('utm_medium'),
                'UTM_Campaign__c': this.cookies.get('utm_campaign'),
                'INT_Source__c': this.cookies.get('int_source'),
                'INT_Campaign__c': this.cookies.get('int_campaign'),
                'GCLID__c': this.cookies.get('gclid'),
                'FBCLID__c': this.cookies.get('fbcild'),
            }
            // Salesforce Web to Lead - W2L END
            this.rcycHiddenSalesforceFormService.generateFormW2L(salesForceRequestData, null, null, this.bookingConfigSharedData?.bookingConfigRedirectURL);
        }
    }

    public submitLeadToSalesforce() {
        this.rcycFormTokenService.formTokenService().subscribe((res: any) => {
            if (res && res.code === 200 && res.data.logInfo) {
                this.leadGenSubmitApiCall(res.data.logInfo);
            }
        }, err => {
            this.leadGenSubmitApiCall(null);
            console.log(err);
        });
    }

    /**
     * Navigating user back on closing flow
     */
    closeBookingConfigFlow() {
        let bookingConfigRedirectURL = this.rcycCommonService.getSessionData('backToLaunchPath');
        if (bookingConfigRedirectURL) {
            this.router.navigate([bookingConfigRedirectURL]);
        } else {
            this.router.navigate(['/']);
        }
    }

    // Data Layer for Booking Configurator
    intiateBookingConfigDataLayer(step_name: string, step_number: number, voyage, adult?: number, child?: number, suite?: string, addons?: string) {
        const scriptGtag = document.createElement('script');
        scriptGtag.innerHTML =
            `window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        event: 'configurator_progress', 
        step_name: '`+ step_name + `', 
        step_number: '`+ step_number + `', 
        voyage_region: '`+ voyage.voyageRegion + `', 
        voyage_duration: '`+ voyage.nights + `', 
        voyage_price: '`+ voyage.startingPrice + " " + voyage.feeCurrency + `', 
        departure_port: '`+ voyage.voyageEmbarkPort + `',
        arrival_port: '`+ voyage.voyageDisembarkPort + `',
        departure_date: '`+ voyage.voyageStartDate + `',
        voyage_yacht: '`+ voyage.yachtName + `',
        voyage_name: '`+ voyage.voyageName + `',
        voyage_code: '`+ voyage.voyageId + `',
        adults_number: '`+ adult + `',
        children_number: '`+ child + `',
        suite_category: '`+ suite + `',
        addons: '`+ addons + `'
    })`
        document.getElementsByTagName('head')[0].appendChild(scriptGtag);
    }
    calculateTotalPrice(priceToConsider: number, adultsCount: number, childrensAgeDetails: any[]): number {
        let adultChildCount = 0;
        let adultTotalPrice = 0;
        let childTotalPrice = 0;
        let childCount = 0;
        if (adultsCount > 0) {
            if (childrensAgeDetails?.length > 0) {
                adultChildCount = childrensAgeDetails.filter(item => !item.ageUnderThreeYear).length;
                childCount = childrensAgeDetails.filter(item => item.ageUnderThreeYear).length;
            }

            if ((adultsCount + adultChildCount + childCount) <= 2 || (adultsCount + adultChildCount) === 1 && childCount === 0) {
                adultTotalPrice = (2 * priceToConsider);
            } else if ((adultsCount + adultChildCount) === 1 && childCount == 2) {
                adultTotalPrice = (2 * priceToConsider);
                childTotalPrice = (priceToConsider * 1 / 2);
            } else {
                adultTotalPrice = ((adultsCount + adultChildCount) * priceToConsider);
                childTotalPrice = childCount * (priceToConsider * 1 / 2)
            }
            return (adultTotalPrice + childTotalPrice);
        }
    }

}